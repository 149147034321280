(function(){
'use strict';

CFPQuestionsViewFactory.$inject = ["$filter", "$http", "scAnalytics", "scFroalaOptions", "CHAR_LIMITS"];
function CFPQuestionsViewFactory($filter, $http, scAnalytics, scFroalaOptions, CHAR_LIMITS) {
  return function CFPQuestionsView(parentScope) {
    var $scope = parentScope.$new();
    var MAX_ATTEMPTS = 1;
    var RETRY_DELAY_MS = 500;
    var attemptCount = 0;

    /* ---------------------------------------------------------------------- *
     * Fetch questions
     * ---------------------------------------------------------------------- */
    function fetchQuestions() {
      $http.get('/frs-api/campaign/' + $scope.campaign.current.id + '/questions').then(function (response) {
        var campaignQuestions = response.data.data;

        _.forEach(campaignQuestions, function (question) {
          if (question.location === 'page_creation') {
            $scope.list.questions.push(question);
          }
        });

        $scope.list.questions = $filter('orderBy')($scope.list.questions, 'weight', false);
        $scope.ui.questionsLoading = false;

        if ($scope.ui.currentView === 'indiv.wait') {
          if ($scope.list.questions.length) {
            $scope.flow.to('indiv.questions');
          } else {
            $scope.flow.end(); // returns null, submits fundraiser
          }
        }
      }).catch(function (err) {
        err.message += '| Questions could not be loaded';
        bugsnagClient.notify(err);
        if (attemptCount < MAX_ATTEMPTS) {
          setTimeout(fetchQuestions, RETRY_DELAY_MS);
        } else {
          $scope.ui.questionsLoading = false;
        }
        attemptCount += 1;
      });
    }

    fetchQuestions();

    /* ---------------------------------------------------------------------- *
     * Clean up after our naughty datepicker
     * ---------------------------------------------------------------------- */

    $scope.isDisabled = function () {
      var datesFilled = true;
      if (!_.get($scope, 'list.questions.length')) {
        return undefined;
      }

      _.forEach($scope.list.questions, function (question) {
        if (question.type === 'date' && question.is_required === true && question.is_deleted === false && !$scope.list.answers['qid_' + question.id]) {
          datesFilled = false;
        }
      });
      return $scope.signup_questions.$invalid || !datesFilled;
    };

    $scope.eventBeacon = function () {
      scAnalytics.eventBeacon({
        category: 'frs ' + $scope.campaign.current.type,
        action: 'triggers',
        label: 'Complete Custom Questions'
      });
    };

    $scope.froalaOptions = scFroalaOptions({ type: 'textarea' }, {
      charCounterCount: true,
      charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_CUSTOM_QUESTION
    });

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.questions',
      templateUrl: $scope.flow.template('questions'),
      maxWidth: 460,
      context: $scope,
      eventBeacon: $scope.eventBeacon
    };
  };
}

angular.module('classy').factory('CFPQuestionsView', CFPQuestionsViewFactory);
})();