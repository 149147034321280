(function(){
'use strict';

/**
 * @ngDoc service
 * @name scFundraisingPagesService
 *
 * @description
 * Stores an object full o' fundraisers
 */

scFundraisingPagesService.$inject = ["$q", "$http", "scFundraisingPageModel", "scFundraisingPageDemo", "scMembersService", "scBlocksService"];
function scFundraisingPagesService($q, $http, scFundraisingPageModel, scFundraisingPageDemo, scMembersService, scBlocksService) {
  var _this = this;

  this.fundraisers = {};
  this.userFundraisers = {};

  this.comments = null;
  this.active = null;

  this.add = function (fundraiserData) {
    this.fundraisers[fundraiserData.id] = new scFundraisingPageModel(fundraiserData);
    var ownerId = _.get(this.fundraisers[fundraiserData.id], 'current.member_id');
    var userId = _.get(scMembersService, 'you.current.id');

    if (ownerId == userId) {
      this.userFundraisers[fundraiserData.id] = this.fundraisers[fundraiserData.id];
    }
  };

  this.get = function (id) {
    return _.get(_this, 'fundraisers.' + id, false);
  };

  this.setCommentsNumber = function (commentsNumber) {
    _this.comments = commentsNumber;
  };

  this.getPagePhoto = function (page) {
    var fundraiserBlockId = scBlocksService.getIdByType('fundraiser');
    var fundraiserBlock = scBlocksService.get(fundraiserBlockId);
    var regExp = /[/\w%.-]+\.(png|gif|apng|jpg|jpeg|webp|avif|svg)/i;

    var img = void 0;
    if (_.get(page, 'logo.cdn_url')) {
      img = page.logo.cdn_url;
    } else if (_.get(page, 'fundraiser_profile_image')) {
      // ClassyQL field for profile image
      img = page.fundraiser_profile_image;
    } else if (_.get(page, 'logo.assetId')) {
      img = _.get(page, 'logo.src');
    }

    if (!_.isNull(img) && !_.isUndefined(img)) {
      return img;
    } else if (fundraiserBlock.current.defaultFundraiserLogo && fundraiserBlock.current.defaultFundraiserLogo.src) {
      img = fundraiserBlock.current.defaultFundraiserLogo.src;
    }

    if (!img || typeof img !== 'string' || !regExp.test(img)) {
      img = '/static/global/images/higher_contrast_individual_default_image.png';
    }

    return img;
  };

  // this and .get should probably be combined
  this.fetch = function (pageId, forceUpdate) {
    var _this2 = this;

    if (_.get(this, 'fundraisers.' + pageId, false) && !forceUpdate) {
      return $q.resolve(this.fundraisers[pageId]);
    }

    return this.fetchData(pageId).then(function (page) {
      _this2.add(page.data);
      return _this2.fundraisers[pageId];
    });
  };

  this.fetchData = function (id, withAnswers, withDesignation, withCommitment) {
    var addedWith = withAnswers ? ',answers' : '';
    return $http({
      method: 'GET',
      url: '/frs-api/fundraising-pages/' + id + '?with=supporter' + addedWith,
      params: {
        withDesignation: withDesignation,
        withCommitment: withCommitment
      }
    });
  };

  this.getCurrentMemberPage = function () {
    if (!_.isEmpty(this.userFundraisers)) {
      var memberPageId = _.keys(this.userFundraisers)[0];
      return this.userFundraisers[memberPageId];
    }
    return false;
  };

  this.getSpecificMemberPage = function (id) {
    if (!_.isEmpty(this.userFundraisers)) {
      return this.userFundraisers[id];
    }
    return false;
  };

  this.useExample = function () {
    this.active = new scFundraisingPageModel(scFundraisingPageDemo(), true);
    return this.active;
  };

  this.setActive = function (id) {
    this.active = this.get(id);
  };

  this.init = function (fundraiser) {
    this.add(fundraiser);
    this.setActive(fundraiser.id);
  };

  this.sync = function (id, isLoad) {
    var _this3 = this;

    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        forceSync = _ref.forceSync,
        withAnswers = _ref.withAnswers,
        withDesignation = _ref.withDesignation,
        withCommitment = _ref.withCommitment;

    if (isLoad && SC.fundraiser) {
      this.init(SC.fundraiser);
      return $q.resolve(this.active.current);
    } else if (_.get(this, 'active.current.id') == id && !forceSync) {
      this.active.refreshData();
      return $q.resolve(this.active.current);
    }

    return this.fetchData(id, withAnswers, withDesignation, withCommitment).then(function (fundraiser) {
      _this3.init(fundraiser.data);
      return fundraiser.data;
    });
  };
}

angular.module('classy').service('scFundraisingPagesService', scFundraisingPagesService);
})();