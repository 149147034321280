(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingAnalytics', ["$log", "scAnalytics", function ($log, scAnalytics) {
  var _this = this;

  this.campaign = null;

  this.init = function (campaign) {
    _this.campaign = campaign;
  };

  this.track = function (egEvent, data) {
    if (!_this.campaign) {
      $log.debug('[EmbeddedGivingAnalytics:track] no campaign present');
      return;
    }

    if (!egEvent) {
      $log.debug('[EmbeddedGivingAnalytics:track] no EG event present');
      return;
    }

    var egEnum = _this.campaign.getEmbeddedGiving();
    var name = null;
    switch (egEnum) {
      case 'modal':
        name = egEvent.modal;
        break;
      case 'inline':
        name = egEvent.inline;
        break;
      case 'nested':
        // deprecated but keeping for reverse compatibility
        name = egEvent.inline;
        break;
      default:
        $log.warn('[EmbeddedGivingAnalytics:track] default case');
        return;
    }

    $log.debug('[EmbeddedGivingAnalytics:track] track: ', name);
    scAnalytics.track(name, data);
  };
}]);
})();