(function(){
'use strict';

function scCopyright() {
  return {
    scope: {
      termsLink: '@',
      privacyLink: '@'
    },
    templateUrl: 'global/directives/scCopyright/scCopyright',

    link: function link(scope) {
      if (!scope.termsLink) {
        scope.termsLink = 'https://www.classy.org/terms';
      }

      if (!scope.privacyLink) {
        scope.privacyLink = 'https://www.classy.org/terms/privacy';
      }

      scope.year = new Date().getFullYear();
      if (window.airgap && window.airgap.getRegimes().has('CPRA')) {
        var cpraButton = document.getElementById('cpra_button');
        cpraButton.onclick = function () {
          window.transcend.showConsentManager({ viewState: 'DoNotSellExplainer' });
        };
        cpraButton.style.display = 'inline';
      }
    }
  };
}

angular.module('classy').directive('scCopyright', scCopyright);
})();