(function(){
'use strict';

/**
 * @kind factory
 * @name scGetBlockTemplate
 *
 * @description
 * Uses the current responsive breakpoint as
 * well as block settings to determine which
 * template to render for a block.
 */
angular.module('classy').factory('scGetBlockTemplate', ["$templateCache", "scBlockTemplateBreakpoint", "EmbeddedGiving", function ($templateCache, scBlockTemplateBreakpoint, EmbeddedGiving) {
  return function scGetBlockTemplate(block, responsiveSettings) {
    var mobileTemplates = _.isUndefined(responsiveSettings) ? null : responsiveSettings;
    var layout = '';

    if (!_.isUndefined(block.layout)) {
      layout = block.layout + '-';
    }

    var currentBreakpoint = scBlockTemplateBreakpoint(mobileTemplates);
    var template = void 0;

    // If mobileTemplates is false, only use the desktop template
    if (!mobileTemplates) {
      template = 'desktop';

      // If mobileTemplates is an object, use templates defined in the object for each
      // breakpoint. For unlisted breakpoints, use desktop template.
    } else if (_.isObject(mobileTemplates)) {
      if (!currentBreakpoint || _.isUndefined(mobileTemplates[currentBreakpoint])) {
        template = 'desktop';
      } else {
        template = mobileTemplates[currentBreakpoint];
      }

      // If mobileTemplates is true, use templates corresponding to each breakpoint.
    } else {
      template = currentBreakpoint || 'desktop';
    }

    // We should use frs-donation-block for organizations that have Classy Pay, and
    // sc-donation-block for organizations that do not have Classy Pay.
    function getTemplateUrl(type, lay, tem) {
      if (type === 'donation') {
        // feed up EmbeddedGiving root view if isEnabled
        if (EmbeddedGiving.isEnabled) {
          return EmbeddedGiving.root;
        } else {
          return 'global/objects/scBlock/types/frs-donation/template';
        }
      }
      return 'global/objects/scBlock/types/' + type + '/' + lay + tem;
    }

    var templateUrl = getTemplateUrl(block.type, layout, template);

    /*
     * Check if template exists, if not, serve the default template
     */
    var tpl = $templateCache.get(templateUrl);

    if (_.isUndefined(tpl) && mobileTemplates && !_.isUndefined(block.layout)) {
      templateUrl = 'global/objects/scBlock/types/' + block.type + '/' + layout + 'desktop';
    }

    var result = $templateCache.get(templateUrl);
    return result;
  };
}]);
})();