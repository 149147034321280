(function(){
'use strict';

/**
 * @kind factory
 * @name scImgAsset
 * @guide ./scImgAsset.md
 *
 * @description
 * A standard constructor for an image asset (a raster image file stored in the
 * API or defined locally.
 */

scImgAssetFactory.$inject = ["$q", "$http", "Upload", "scImgAssetClientUpload", "scImgAssetExpressUpload", "scImgAssetProcess", "$log", "$window"];
function scImgAssetFactory($q, $http, Upload, scImgAssetClientUpload, scImgAssetExpressUpload, scImgAssetProcess, $log, $window) {
  var NULL_SRC = '/static/global/images/null.png';

  var ASSET_DEFINITION_DEFAULTS = {
    title: null,
    alt: null,
    size: null,
    content_type: null,
    type: null,
    created_by: null,
    created_at: null,
    sizes: {
      full: NULL_SRC
    }
  };

  function scImgAsset(assetId, origin, data, options) {
    var _this = this;

    // Provided Properties

    this.assetId = assetId;

    this.origin = origin;

    this.contentType = null;

    this.role = null;

    this.createdBy = null;

    this.createdAt = null;

    this.title = null;

    this.alt = null;

    this.weight = null;

    this.width = null;

    this.height = null;

    this.sizes = {
      full: null
    };

    // Status and Promises

    this.deferred = {};

    this.promises = {};

    // Image Src Status

    this.src = NULL_SRC;

    this.deferred.src = $q.defer();

    this.promises.src = this.deferred.src.promise;

    // Load and Cache Status

    this.downloads = [];

    this.loaded = false;

    this.deferred.load = $q.defer();

    this.promises.load = this.deferred.load.promise;

    this.cache = {};

    this.on('load', function () {
      _this.loaded = true;
    });

    // Save Status

    this.saved = false;

    this.uploadProgress = 0;

    this.deferred.save = $q.defer();

    this.promises.save = this.deferred.save.promise;

    this.on('save', function () {
      _this.saved = true;
    });

    // Init

    if (Modernizr.filereader && data instanceof Blob) {
      this.fileInit(data);
    } else if (_.isFunction(data.then)) {
      this.deferredInit(data, options);
    } else {
      this.objInit(data);
    }
  }

  // Reconstitute a static or previously uploaded file.

  scImgAsset.prototype.objInit = function (objData) {
    var data = _.defaultsDeep(_.cloneDeep(objData), ASSET_DEFINITION_DEFAULTS);
    this.title = data.title;
    this.alt = data.alt;
    this.weight = data.size;
    this.contentType = data.content_type;
    this.role = data.type;
    this.createdBy = data.created_by;
    this.createdAt = data.created_at;
    this.sizes = data.sizes;
    this.src = _.get(data, 'sizes.full', '');
    this.uploadProgress = 100;
    this.deferred.src.resolve();
    this.deferred.save.resolve();
  };

  // Upload a file and retrieve image data.

  scImgAsset.prototype.fileInit = function (file) {
    var upload = $window.Modernizr.filereader ? scImgAssetClientUpload : scImgAssetExpressUpload;

    function uploadSuccess(data) {
      this.assetId = data.id;
      this.contentType = data.content_type;
      this.role = data.type;
      this.createdBy = data.created_by;
      this.createdAt = data.created_at;
      this.sizes.full = data.cdn_url;
      if (this.src === NULL_SRC) {
        this.src = data.cdn_url;
        this.deferred.src.resolve();
      }

      this.deferred.save.resolve();
    }

    function uploadError(reason) {
      $log.log(reason);
    }

    function uploadProgress(notification) {
      if (_.get(notification, 'progress')) {
        this.uploadProgress = notification.progress;
      }
    }

    this.title = file.name.replace(/.(jpg|jpeg|gif|png)$/, '');
    this.alt = file.name.replace(/.(jpg|jpeg|gif|png)$/, '');
    this.weight = parseInt(file.size, 10);

    upload(file).then(uploadSuccess.bind(this), uploadError.bind(this), uploadProgress.bind(this));
    this.buildPreview(file);
  };

  // Wait for a promise to resolve to initialize asset.

  scImgAsset.prototype.deferredInit = function (promise, sizes) {
    if (_.isPlainObject(sizes)) {
      _.merge(this.sizes, sizes);
    }

    function promiseSuccess(response) {
      if (response instanceof Blob) {
        this.fileInit(response);
      } else {
        this.assetId = response.id;
        this.origin = 'upload';
        this.objInit(response);
      }
    }

    function promiseError(reason) {
      $log.log(reason);
    }

    promise.then(promiseSuccess.bind(this), promiseError);
  };

  // Generate preview.

  scImgAsset.prototype.buildPreview = function (file) {
    function registerPreviewSrc(dataUrl) {
      if (this.src === NULL_SRC) {
        this.sizes.full = dataUrl;
        this.src = dataUrl;
        this.deferred.src.resolve();
      }
    }

    if (!$window.Modernizr.filereader) {
      return;
    }

    Upload.dataUrl(file).then(registerPreviewSrc.bind(this));
  };

  // Get load/save promise, or enqueue a callback.

  scImgAsset.prototype.on = function (property, callback) {
    if (_.indexOf(['load', 'src', 'save'], property) < 0) {
      $log.error('Invalid property ' + property + 'passed to scImgAsset.on!');
      return $q.defer().promise;
    }

    if (_.isFunction(callback)) {
      this.promises[property].then(callback);
    }

    return this.promises[property];
  };

  // get asset src

  scImgAsset.prototype.getSrc = function () {
    var _this2 = this;

    var deferred = $q.defer();

    if (this.src) {
      var src = this.src;
      if (this.origin == 'static') {
        // sharing links need the full url
        src = document.location.origin + this.src;
      }

      deferred.resolve(src);
    } else {
      $http.get('/frs-api/asset/' + this.assetId).then(function (data) {
        _this2.src = _.get(data, 'data.cdn_url', false);
        deferred.resolve(_this2.src);
      }, function () {
        deferred.reject('could not retrieve a src');
      });
    }

    return deferred.promise;
  };

  // create a thumbnail

  scImgAsset.prototype.createThumbnail = function (assetId, reqWidth, width, height) {
    return scImgAssetProcess(assetId, [{
      operation: 'scale',
      args: {
        width: parseInt(reqWidth, 10),
        height: parseInt(reqWidth / width * height, 10)
      }
    }]);
  };

  // get a thumbail's src by width, as a promise
  scImgAsset.prototype.getThumbnailSrc = function (width) {
    var _this3 = this;

    var deferred = $q.defer();

    var reqWidth = parseInt(width, 10);
    var key = reqWidth.toString();

    this.getDimensions().then(function (dimensions) {
      if (_this3.sizes[key]) {
        deferred.resolve(_this3.sizes[key]);
      } else {
        _this3.createThumbnail(_this3.assetId, reqWidth, dimensions.width, dimensions.height).then(function (thumbnailImage) {
          deferred.resolve(thumbnailImage.data.cdn_url);
        }, function (error) {
          deferred.reject(error);
        });
      }
    });

    return deferred.promise;
  };

  // Return a thumbnail URL, for use in an ngsrc.

  scImgAsset.prototype.tn = function (width) {
    var _this4 = this;

    var reqWidth = parseInt(width, 10);
    var key = reqWidth.toString();

    var queueThumbnail = function queueThumbnail() {
      if (_this4.origin !== 'upload') {
        return;
      }

      _this4.sizes[key] = 'PENDING';
      $q.all([_this4.getDimensions(), // Need width and height
      _this4.promises.save] // Need assetId
      ).then(processThumbnail.bind(_this4)).then(registerThumbnail.bind(_this4), disableThumbnail.bind(_this4));
    };

    var bestEligibleUrl = function bestEligibleUrl() {
      var bestUrl = _this4.src;
      var bestWidth = 1000000;
      _.forEach(_this4.sizes, function (tnUrl, tnWidth) {
        if (tnWidth !== 'full' && // this.src is preferred
        tnUrl !== 'PENDING' && // Don't set to pending
        tnUrl !== 'FAILURE' && // Don't set to failure
        parseInt(tnWidth, 10) >= reqWidth && // Covers requested area
        parseInt(tnWidth, 10) < bestWidth) {
          // Smaller than current
          bestUrl = tnUrl;
          bestWidth = tnWidth;
        }
      });

      return bestUrl;
    };

    function processThumbnail() {
      return this.createThumbnail(this.assetId, reqWidth, this.width, this.height);
    }

    function registerThumbnail(response) {
      this.sizes[key] = _.get(response, 'data.cdn_url', 'FAILURE');
    }

    function disableThumbnail() {
      this.sizes[key] = 'FAILURE';
    }

    var currentUrl = _.get(this, ['sizes', key], false);

    switch (currentUrl) {
      case false:
        // Thumbnail does not yet exist; queue up
        queueThumbnail();

        // Thumbnail is being created or was a failure; return smallest
        // eligible existing thumbnail.
        return bestEligibleUrl();

      case 'PENDING':
      case 'FAILURE':
        // Thumbnail is being created or was a failure; return smallest
        // eligible existing thumbnail.
        return bestEligibleUrl();

      default:
        // Thumbnail exists
        return currentUrl;
    }
  };

  // Get dimensions, downloading image to get them if necessary.

  scImgAsset.prototype.getDimensions = function () {
    var _this5 = this;

    var dimensions = $q.defer();

    var resolveDimensions = function resolveDimensions() {
      dimensions.resolve({
        width: _this5.width,
        height: _this5.height
      });
    };

    if (this.width && this.height) {
      resolveDimensions();
    } else {
      this.download('full', false).then(resolveDimensions);
    }

    return dimensions.promise;
  };

  // Download image.

  scImgAsset.prototype.download = function (sizeValue, cacheValue) {
    var _this6 = this;

    var imgEl = void 0;
    var downloaded = $q.defer();

    var size = _.isUndefined(sizeValue) ? 'full' : sizeValue.toString();
    var cache = _.isUndefined(cacheValue) ? true : !!cacheValue;

    function handleLoad() {
      if (cache) {
        this.cache[size] = imgEl;
      }

      if (size === 'full') {
        this.width = imgEl.width;
        this.height = imgEl.height;
        this.deferred.load.resolve();
      }

      downloaded.resolve();
    }

    if (size in this.downloads) {
      return this.downloads[size];
    }

    this.downloads[size] = downloaded.promise;

    imgEl = document.createElement('img');
    imgEl.onload = handleLoad.bind(this);

    this.on('src', function () {
      imgEl.src = _.get(_this6.sizes, size, _this6.sizes.full);
    });

    return downloaded.promise;
  };

  return scImgAsset;
}

angular.module('classy').factory('scImgAsset', scImgAssetFactory);
})();