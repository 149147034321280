(function(){
'use strict';

scCustomBlock.$inject = ["scBlock"];
function scCustomBlock(scBlock) {
  return scBlock({
    link: function link() {}
  });
}

angular.module('classy').directive('scCustomBlock', scCustomBlock);
})();