(function(){
'use strict';

// TODO: move
angular.module('classy').controller('EmbeddedGivingPayCtrl', ["$log", "$scope", "$timeout", "EmbeddedGivingPay", "EmbeddedGivingPayUtil", function ($log, $scope, $timeout, EmbeddedGivingPay, EmbeddedGivingPayUtil) {
  $scope.dataId = '';
  $scope.ActivePaymentMethod = null;
  $scope.ActivePaymentMethods = {};
  $scope.VisiblePaymentMethods = {};

  $scope.egPay = {
    isInitializing: true,
    hideBillingAddressAutocomplete: false
  };

  $scope.setActivePaymentMethod = function (pm) {
    $scope.ActivePaymentMethod = pm;
  };

  $scope.setPaymentMethods = function (pms) {
    $scope.ActivePaymentMethods = EmbeddedGivingPayUtil.getValidPaymentMethods(pms);
    $scope.VisiblePaymentMethods = EmbeddedGivingPayUtil.getPaymentMethodsByStatus($scope.ActivePaymentMethods, 'ready');
  };

  // TODO refactor this, rn its a quick solution for aug 18
  $scope.syncPaymentMethods = function (statuses) {
    EmbeddedGivingPay.syncPaymentMethodStatuses(statuses);
  };

  $scope.goBackDonationConfirmation = function () {
    $scope.hideBillingAddressAutocomplete = false;
    $scope.goDonationPaymentMethods();
  };

  /* Billing Address stuff (previously "EmbeddedGivingDonationAddressCtrl")
     - Used to determine which billing address input to show
     - Only 1 of these 3 "is" functions will return.
  */
  $scope.isFullAddress = function () {
    return $scope.eg.isRequiredFullAddress || $scope.block.billing_address_autocomplete && $scope.egPay.hideBillingAddressAutocomplete;
  };

  $scope.isAutocomplete = function () {
    return !$scope.isFullAddress() && $scope.block.billing_address_autocomplete && !$scope.egPay.hideBillingAddressAutocomplete;
  };

  $scope.isZipcode = function () {
    return !$scope.isFullAddress() && !$scope.isAutocomplete();
  };

  $scope.useFullAddress = function () {
    $scope.egPay.hideBillingAddressAutocomplete = true;
  };

  $scope.handleAutocomplete = function (response) {
    $timeout(function () {
      // Update billing fields and switch to manual entry so the donor can "check their work".
      _.merge($scope.MODEL, {
        billing_address1: response.address,
        billing_address2: response.subpremise,
        billing_postal_code: response.postalCode,
        billing_city: response.city,
        billing_state: response.state,
        billing_country: response.country
      });

      $scope.egPay.hideBillingAddressAutocomplete = true;
    });
  };

  $scope.isValidDonationAddress = function () {
    var form = $scope.FORM;

    if ($scope.isFullAddress()) {
      if (!form.billing_country || !form.billing_country.$valid) {
        $log.debug('[isValidDonationAddress] billing_country not valid');
        return false;
      }

      if (!form.billing_address1 || !form.billing_address1.$valid) {
        $log.debug('[isValidDonationAddress] billing_address1 not valid');
        return false;
      }

      if (!form.billing_city || !form.billing_city.$valid) {
        $log.debug('[isValidDonationAddress] billing_city not valid');
        return false;
      }

      // optional sometimes...
      if (!form.billing_state || !form.billing_state.$valid) {
        $log.debug('[isValidDonationAddress] billing_state not valid');
        return false;
      }
    }

    if (!form.billing_postal_code || !form.billing_postal_code.$valid) {
      $log.debug('[isValidDonationAddress] billing_postal_code not valid');
      return false;
    }

    if ($scope.eg.isBillingAddressAutocompleteEnabled && !form.billing_address1) {
      $log.debug('[isValidDonationAddress] isBillingAddressAutocompleteEnabled not valid');
      return false;
    }

    if ($scope.eg.isBillingAddressAutocompleteEnabled && form.billing_city && !form.billing_city.$valid) {
      $log.debug('[isValidDonationAddress] isBillingAddressAutocompleteEnabled not valid');
      return false;
    }

    return true;
  };

  $scope.isValidDonationPayment = function () {
    var form = $scope.FORM || {};

    if (!$scope.eg.isValidPayment) {
      return false;
    }

    if (form.billing_postal_code && !form.billing_postal_code.$valid) {
      $log.debug('[isValidDonationAddress] billing_postal_code not valid');
      return false;
    }

    // Should we isolate all ACH within single conditional?
    if (form.routingNumber && !form.routingNumber.$valid) {
      return false;
    }

    // [CL-12420] For some reason, when accountNumber is $pristine it is $valid. Probably tokenizer related
    if (form.accountNumber && (form.accountNumber.$pristine || !form.accountNumber.$valid)) {
      return false;
    }

    // If company name visible, make sure it is filled
    if ($scope.eg.showIsCompanyCard() && _.get($scope.META, 'showCompanyName')) {
      if (!form.company_name.$valid) {
        return false;
      }
    }

    // Removed this (conditional) validation requirement
    // See: https://classydev.atlassian.net/browse/CL-38468
    // if ($scope.eg.showAchTerms()) {
    //   // verify it is set to true
    //   if (!_.get($scope.MODEL, 'payment.ach.achTermsAccepted')) {
    //     return false;
    //   }
    // }

    if (!$scope.isValidDonationAddress()) {
      return false;
    }

    return true;
  };

  $scope.processKeydownEventPay = function (event) {
    var keyCode = event && event.keyCode;
    if (keyCode === 13) {
      if ($scope.META.showPaymentDetails && !$scope.eg.isCheckoutOut && $scope.isValidDonationPayment()) {
        event.stopPropagation();
        event.preventDefault();

        $timeout(function () {
          $scope.goDonationConfirmation();
        });
      }
    }
  };

  $scope.$watchCollection('MODEL.payment', function (newVal) {
    // TODO: filter out valid payment methods, ignore if no updates, update to paymentmethods
    $scope.syncPaymentMethods(newVal);
  });

  $scope.$watchCollection('META.paymentMethod', function (newVal) {
    switch (newVal) {
      case 'card':
        $scope.dataId = 'paymentMethod-card-continue';
        break;
      case 'ach':
        $scope.dataId = 'paymentMethod-ach-continue';
        break;
      default:
        $scope.dataId = 'paymentMethod-continue';
        break;
    }
  });

  EmbeddedGivingPay.init().then(function () {
    $scope.egPay.isInitializing = false;
  });

  $scope.handleBillingAddressKeyDown = function (event) {
    var keyCode = event && event.keyCode;
    if (keyCode === 13) {
      event.preventDefault();
    }
  };
}]);
})();