(function(){
'use strict';

/**
 * @name capitalize
 * @kind filter
 */

function capitalize() {
  return function (input) {
    if (input !== null && typeof input !== 'undefined') {
      var adjustedString = input.toString().toLowerCase();
      return adjustedString.substring(0, 1).toUpperCase() + adjustedString.substring(1);
    }
    return '';
  };
}

angular.module('classy').filter('capitalize', capitalize);
})();