(function(){
'use strict';

// this service extends the built-in angular logger service,
// automatically redacting sensitive information
angular.module('classy').provider('$log', ["$logProvider", function ($logProvider) {
  // $logProvider here is the built-in angular logger
  this.$get = ["$injector", "redact", function ($injector, redact) {
    // invoke the original provider.
    var $log = $injector.invoke($logProvider.$get);

    ['log', 'debug', 'info', 'warn', 'error'].forEach(function (type) {
      var oldMethod = $log[type];
      $log[type] = function () {
        // convert arguments to proper array before redacting and forwarding
        oldMethod.apply(this, redact(Array.prototype.slice.call(arguments)));
      };

      // this allows angular's mocked log service to continue to work
      $log[type].logs = [];
    });

    return $log;
  }];
}]);
})();