(function(){
'use strict';

/**
 * @kind factory
 * @name scThemeDefaults
 *
 * @description
 * Aggregates the default theme settings
 * from each theme type.
 */

scThemeDefaults.$inject = ["scCrowdfundingThemeDefaults", "scP2PThemeDefaults", "scRegWFundThemeDefaults", "scDonationThemeDefaults", "scTicketedThemeDefaults"];
function scThemeDefaults(scCrowdfundingThemeDefaults, scP2PThemeDefaults, scRegWFundThemeDefaults, scDonationThemeDefaults, scTicketedThemeDefaults) {
  return function (type) {
    var themeDefaults = {};

    switch (type) {
      case 'crowdfunding':
        themeDefaults = scCrowdfundingThemeDefaults;
        break;

      case 'p2p':
        themeDefaults = scP2PThemeDefaults;
        break;

      case 'reg_w_fund':
        themeDefaults = scRegWFundThemeDefaults;
        break;

      case 'donation':
        themeDefaults = scDonationThemeDefaults;
        break;

      case 'ticketed':
        themeDefaults = scTicketedThemeDefaults;
        break;

      default:
        themeDefaults = {};
        break;
    }

    return themeDefaults;
  };
}

angular.module('classy').factory('scThemeDefaults', scThemeDefaults);
})();