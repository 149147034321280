(function(){
'use strict';

scPristineLoad.$inject = ["$timeout"];
function scPristineLoad($timeout) {
  return {
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element) {
      var ctrls = element.find('[ng-model], [data-ng-model]');
      ctrls.each(function (ctrl) {
        var ctrlModel = angular.element(ctrls[ctrl]).controller('ngModel');
        if (!_.isUndefined(ctrlModel)) {
          ctrlModel.$pristine = false;
        }
      });

      $timeout(function () {
        ctrls.each(function (ctrl) {
          var ctrlModel = angular.element(ctrls[ctrl]).controller('ngModel');
          if (!_.isUndefined(ctrlModel)) {
            ctrlModel.$setPristine();
          }
        });
      });
    }
  };
}

angular.module('classy').directive('scPristineLoad', scPristineLoad);
})();