(function(){
'use strict';

/**
 * Creates a readable description for a feed item.
 * @callback scFeedItemAmount
 * @param {FeedItem} - The feed item
 * @returns {string} - The currency string
 */

scFeedItemAmount.$inject = ["$filter", "scCampaignsService"];
function scFeedItemAmount($filter, scCampaignsService) {
  return generateDonationAmount;
  /**
   * Creates a string for the donated amount for the activity feed
   * @param {linkable_value} type - From the API either `$10.00` (legacy) or `10.00 USD`
   * @returns {string}
   */
  function generateDonationAmount(feedItem) {
    var hideAmount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (hideAmount) {
      return '';
    }

    if (feedItem.linkable && !feedItem.linkable.amount) {
      if (feedItem.linkable.is_donor_covered_fee) {
        return $filter('scFormatCurrency')(feedItem.linkable.raw_initial_gross_amount, feedItem.linkable.raw_currency_code, 2);
      }

      /*                                                            *\
        FRS-6270: fancy multicurrency + (legacy) FoT stuff here
        basically if the donation was given in the same currency as
        was charged, we display the charged_total_gross_amount which
        includes the FoT; for different currencies we do no such
        thing, at least in the short term.
         Also, linkable.amount is a sign of soft_credits, which
        aren't applied to the same logic as donations
         Fallback to the old value of linkable_value.
      \*                                                            */
      if (feedItem.linkable.charged_currency_code === feedItem.linkable.raw_currency_code) {
        return $filter('scFormatCurrency')(feedItem.linkable.charged_total_gross_amount, feedItem.linkable.charged_currency_code, 2);
      }
      return $filter('scFormatCurrency')(feedItem.linkable.raw_total_gross_amount, feedItem.linkable.raw_currency_code, 2);
    }

    var linkableValue = feedItem.linkable_value;

    var campaignCurrency = _.get(scCampaignsService, 'active.current.currency_code', 'USD');
    var donationCurrency = _.get(linkableValue.split(' '), '[1]', campaignCurrency);
    var donationAmount = parseFloat(linkableValue.split(' ')[0].replace(/[$,]/g, ''));
    var donationText = $filter('scFormatCurrency')(donationAmount, donationCurrency, 2);

    return donationText;
  }
}

angular.module('classy').factory('scFeedItemAmount', scFeedItemAmount);
})();