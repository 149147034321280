(function(){
'use strict';

angular.module('classy').constant('frsDonationDefaults', {
  title: 'Donation',
  type: 'donation',
  loaded: true,
  headline: '<span style="font-size: 40px">You\'re making a difference</span>',
  description: '',
  questions: [],
  'recurring-level': '1',
  'recurring-type': 'default',
  'donation-levels': [{
    amount: 500
  }, {
    amount: 100
  }, {
    amount: 50
  }, {
    amount: 25
  }],
  'donation-default': null,

  // 'use-impact-donation-levels': true,
  'submit-button-text': 'Give now!',

  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: true,
    preset: 'coverfixed'
  },

  unsortable: true,
  recurring_donation_experiment: false
});
})();