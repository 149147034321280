(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

// Note: the best way to generate data for use by this component is to use the ChartistDataFactory!
angular.module('classy').component('chartist', {
  template: '<div class="ct-chart ct-double-octave"></div>',
  bindings: {
    chartData: '<',
    options: '<',
    chartColor: '<'
  },
  controller: function () {
    ChartistController.$inject = ["$element"];
    function ChartistController($element) {
      _classCallCheck(this, ChartistController);

      this.element = angular.element($element[0]).find('.ct-chart')[0];
      this.drawStyleBound = this.drawStyle.bind(this);
      this.chart = this.createChart(this.element, this.chartData, this.options, this.drawStyleBound);
    }

    _createClass(ChartistController, [{
      key: 'createChart',
      value: function createChart(element, data, options, drawCb) {
        var chart = new Chartist.Line(element, data, options);
        chart.on('draw', drawCb);
        return chart;
      }
    }, {
      key: 'drawStyle',
      value: function drawStyle(context) {
        if (context.type === 'line' || context.type === 'point') {
          context.element.attr({
            style: 'stroke:' + this.chartColor
          });
        }

        if (context.type === 'area') {
          context.element.attr({
            style: 'fill:' + this.chartColor
          });
        }

        if (this.options.withPointLabels) {
          this.addPointLabel(context);
        }
      }
    }, {
      key: 'addPointLabel',
      value: function addPointLabel(context) {
        if (context.type === 'point') {
          context.group.elem('text', _.merge({ style: 'text-anchor: middle' }, this.pointLabelPosition(context)), 'ct-label').text(this.pointLabelTransform(context.value.y));
        }
      }
    }, {
      key: 'pointLabelPosition',
      value: function pointLabelPosition(_ref) {
        var x = _ref.x,
            y = _ref.y;

        return {
          x: x + this.options.label.xOffset,
          y: y + this.options.label.yOffset
        };
      }
    }, {
      key: 'pointLabelTransform',
      value: function pointLabelTransform(value) {
        // Don't show labels on values unless they are greater than zero
        if (value > 0) {
          return this.options.axisY.labelInterpolationFnc(value);
        }

        return '';
      }
    }, {
      key: '$onChanges',
      value: function $onChanges(_ref2) {
        var _ref2$chartData = _ref2.chartData;
        _ref2$chartData = _ref2$chartData === undefined ? {} : _ref2$chartData;
        var newData = _ref2$chartData.currentValue,
            _ref2$options = _ref2.options;
        _ref2$options = _ref2$options === undefined ? {} : _ref2$options;
        var newOptions = _ref2$options.currentValue;

        if (newData) {
          // save data if it has been updated
          this.chartData = newData;
        }

        if (newOptions) {
          // save options if they have been updated
          this.options = newOptions;
        }

        if (newData || newOptions) {
          // only update the chart if there our options or data have changed
          this.chart.update(this.chartData, this.options);
        }
      }
    }, {
      key: '$onDestroy',
      value: function $onDestroy() {
        this.chart.off('draw');
        this.chart.detach();
      }
    }]);

    return ChartistController;
  }()
});
})();