(function(){
'use strict';

scFundraisersBlockSettings.$inject = ["scFroalaOptions"];
function scFundraisersBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/fundraisers/settings',
    controller: ['$scope', function ($scope) {
      $scope.froalaOptionsHeader = scFroalaOptions({ type: 'bare-headline' });
      $scope.froalaOptions = scFroalaOptions({ type: 'bare-paragraph' });

      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Headline'
      });

      $scope.showHeadline = !$scope.block.hideHeadline;

      $scope.$watch('showHeadline', function (newVal) {
        $scope.block.hideHeadline = !newVal;
      });
    }],

    link: function link() {}
  };
}

angular.module('classy').directive('scFundraisersBlockSettings', scFundraisersBlockSettings);
})();