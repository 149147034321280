(function(){
'use strict';

/*
 *               CryptoGivingCheckoutCtrl    <siblings>    CryptoGivingToggleCtrl
 *              /            |            \
 *  ...DonationCtrl     ...ContactCtrl    ...WalletCtrl (children of ...CheckoutCtrl)
 *
 * TODO:
 *  - move $scope.cg to higher service?
 *  - scAnalytics tracking model?
 *  - lazy fetch deposit address (last view)
 *  - kick back to step on fail transition to 3? how to handle?
 */
angular.module('classy').controller('CryptoGivingCheckoutCtrl', ["$scope", "scAnalytics", "CG_EVENTS", "EmbeddedGivingColors", "EmbeddedGivingUtil", "CryptoGiving", "CryptoGivingDAL", "CryptoGivingPaths", "CryptoGivingUtil", "CryptoGivingCampaignUtil", function ($scope, scAnalytics, CG_EVENTS, EmbeddedGivingColors, EmbeddedGivingUtil, CryptoGiving, CryptoGivingDAL, CryptoGivingPaths, CryptoGivingUtil, CryptoGivingCampaignUtil) {
  $scope.CryptoGiving = CryptoGiving;
  $scope.CryptoGivingPaths = CryptoGivingPaths;
  $scope.CryptoGivingUtil = CryptoGivingUtil;

  CryptoGivingCampaignUtil.init($scope.campaign);

  $scope.cg = {
    // Campaign properties
    primaryColor: EmbeddedGivingColors.getPrimary($scope.theme),
    backgroundColor: EmbeddedGivingColors.getAccent($scope.theme),
    logoUrl: function logoUrl() {
      return EmbeddedGivingUtil.getLogoUrl($scope.page, $scope.theme);
    },

    donationAmounts: CryptoGivingCampaignUtil.donationAmounts,
    // Meta info checkout process
    checkoutStep: 'donation',
    isVisibleMoreOptions: false,
    // checkout related
    // donation amt??
    isAmountCustom: false, // true = using custom amount, false = using preset amount (4 big amount buttons)
    isAmountUSD: true, // is the custom amount input field set to USD or amount of coins?
    amount: undefined, // amount of coins
    amountDraft: undefined, // used for custom amount input
    amountConverted: undefined // converted value to USD
  };

  $scope.goCheckoutDonation = function () {
    $scope.setActiveCheckoutStep('donation');
    scAnalytics.track(CG_EVENTS.donation, CryptoGivingUtil.getAnalyticsEventData());
  };

  $scope.goCheckoutContact = function () {
    $scope.setActiveCheckoutStep('contact');
    scAnalytics.track(CG_EVENTS.contact, CryptoGivingUtil.getAnalyticsEventData());
  };

  $scope.goCheckoutWallet = function () {
    // Thread lock checkout flow
    if (!$scope.cg.isCheckoutOut) {
      $scope.cg.isCheckingOut = true;
      scAnalytics.track(CG_EVENTS.wallet, CryptoGivingUtil.getAnalyticsEventData());
      var donationAmount = $scope.cg.isAmountUSD ? $scope.ActiveCoin.convertUSD($scope.cg.amount) : $scope.cg.amount;

      var payload = {
        symbol: $scope.ActiveCoin.symbol,
        amount: +donationAmount,
        member_first_name: $scope.META.member_first_name,
        member_last_name: $scope.META.member_last_name,
        member_email_address: $scope.MODEL.member_email_address,
        billing_postal_code: $scope.MODEL.billing_postal_code,
        opt_in: $scope.MODEL.opt_in,
        opt_in_wording: $scope.MODEL.opt_in_wording,
        hide_amount: !!$scope.MODEL.hide_amount, // BOOL cast
        is_anonymous: !!$scope.MODEL.is_anonymous
      };

      if ($scope.cg.hasProgramDesignations) {
        payload.designation_id = $scope.MODEL.designation_id;
      }

      CryptoGivingDAL.checkout(payload).then(function () {
        $scope.setActiveCheckoutStep('wallet');
      }).finally(function () {
        $scope.cg.isCheckingOut = false;
      });
    }
  };

  $scope.setActiveCoin = function (symbol) {
    $scope.ActiveCoin = CryptoGiving.getCoin(symbol);
    CryptoGivingDAL.getMarketData($scope.ActiveCoin); // TODO leverage coin.$$isFethcingMarketData...
  };

  $scope.setActiveCheckoutStep = function (step) {
    $scope.cg.checkoutStep = step;
  };

  $scope.toggleVisibilityMoreOptions = function () {
    $scope.cg.isVisibleMoreOptions = !$scope.cg.isVisibleMoreOptions;
  };

  // Lazy init (defaults to bitcoin)
  $scope.setActiveCoin('BTC');
}]).controller('CryptoGivingCheckoutDonationCtrl', ["$sce", "$scope", "$timeout", "CG_COIN_DIGITS", function ($sce, $scope, $timeout, CG_COIN_DIGITS) {
  $scope.getHeadlineTrustedHtml = function () {
    return $sce.trustAsHtml($scope.block.headline);
  };

  $scope.isValidCheckoutDonation = function () {
    if (!$scope.ActiveCoin) {
      return false;
    }

    if (!$scope.cg.amount || $scope.cg.amount <= 0) {
      return false;
    }

    return true;
  };

  // @param 'isPreset'
  // - [true] if amount is set from one of the 4 main buttons
  // - [false] if amount is custom
  // - used for conditional styles
  $scope.setActiveAmount = function (amount, isPreset) {
    var numAmount = +amount; // cast

    if (numAmount > 0) {
      $scope.cg.isAmountCustom = !isPreset;

      // put first to avoid flicker
      if (isPreset) {
        $scope.cg.isAmountUSD = true;
        $scope.cg.amountDraft = undefined;
      }

      // round appropriately when custom
      if ($scope.cg.isAmountCustom) {
        if ($scope.cg.isAmountUSD) {
          numAmount = Math.round(numAmount * 100) / 100; // $XX.YZ
        } else {
          numAmount = Math.round(numAmount * CG_COIN_DIGITS) / CG_COIN_DIGITS;
        }
        $scope.cg.amountDraft = numAmount;
      }

      $scope.cg.amount = numAmount;
    }
  };

  $scope.setActiveAmountBlur = function (amount) {
    var numAmount = +amount; // cast

    if (numAmount <= 0) {
      $scope.cg.amountDraft = undefined;
      $scope.cg.amount = undefined;
    } else {
      $scope.setActiveAmount(amount);
    }
  };

  $scope.toggleAmountUSD = function () {
    // only allow when amount is custom (not one of the 4 preset amounts)
    if ($scope.cg.isAmountCustom) {
      $scope.cg.isAmountUSD = !$scope.cg.isAmountUSD;

      // if amount is not in USD then convert USD amount to the coin amount
      if (!$scope.cg.isAmountUSD) {
        var convertedAmount = $scope.ActiveCoin.convertUSD($scope.cg.amountDraft);
        $scope.cg.amountDraft = convertedAmount;
        $scope.cg.amount = convertedAmount;
      } else {
        var _convertedAmount = $scope.ActiveCoin.convertCoinRaw($scope.cg.amountDraft);
        $scope.cg.amountDraft = _convertedAmount;
        $scope.cg.amount = _convertedAmount;
      }
    }

    // TODO if not custom, set custom and focus input field?
  };

  // [START: Program Designation]
  _.extend($scope.CONSTANTS, {
    // TODO?
    SELECT_DESIGNATION_REQUIRED_ERROR_TEXT: 'Please select a designation from the list.'
  });
  // $scope.CONSTANTS.SELECT_DESIGNATION_REQUIRED_ERROR_TEXT = `Please select a designation from the list.`;

  // lazy fetch designations IFF campaign has count
  if ($scope.campaign.current.designations_count > 0) {
    $scope.designationOptions = {
      check: {
        enabled: false
      },
      containerClass: 'full',
      fieldName: 'designation',
      sublabel: 'What would you like your donation to support?',
      valueKey: 'id',
      labelKey: 'name'
    };

    $scope.campaign.getDesignations().then(function (res) {
      var data = res.data || {};
      data = data.data; // nested unwrap
      $scope.list.designations = data;
      var hasDesignations = $scope.list.designations && $scope.list.designations.length;
      if (hasDesignations) {
        $timeout(function () {
          $scope.MODEL.designation = $scope.list.designations[0];
          $scope.cg.hasProgramDesignations = hasDesignations > 1; // always 1 is_default
        });
      }
    });
  }
  // [STOP: Program Designation]
}]).controller('CryptoGivingCheckoutContactCtrl', ["$scope", function ($scope) {
  /*
   * Checks contact input fields and verifies required values are present AND valid
   * $scope.MODEL (i.e. the data for /checkout) and $scope.META house these properties.
   *
   * This consists of 3 required fields (for /checkout):
   * 1) member_first_name (i.e. $scope.META.member_first_name)
   * 2) member_last_name (i.e. $scope.META.member_last_name)
   * 3) member_email_address (i.e. $scope.MODEL.member_email_address)
   * 4) billing_postal_code (i.e. $scope.MODEL.member_email_address)
   *
   * NOTE: could've short-handed this code into 1 line but opted not too for readability and extendability
   */
  $scope.isValidCryptoContact = function () {
    var form = $scope.FORM;

    if (!form.member_first_name.$valid) {
      return false;
    }

    if (!form.member_last_name.$valid) {
      return false;
    }

    if (!form.member_email_address.$valid) {
      return false;
    }

    // TODO verify zip code
    if (!$scope.MODEL.billing_postal_code || !$scope.MODEL.billing_postal_code.length) {
      return false;
    }

    return true;
  };
}]).controller('CryptoGivingCheckoutWalletCtrl', ["$scope", function ($scope) {
  if (!$scope.ActiveCoin) {
    $scope.goCheckoutDonation();
  }

  // https://www.codegrepper.com/code-examples/javascript/copy+string+to+clipboard+javascript
  $scope.copyDepositAddress = function () {
    var elem = document.createElement('textarea');
    elem.value = $scope.ActiveCoin.depositAddress;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
  };
}]).controller('CryptoGivingToggleCtrl', ["$scope", "CryptoGiving", "EmbeddedGivingColors", function ($scope, CryptoGiving, EmbeddedGivingColors) {
  $scope.CryptoGiving = CryptoGiving;

  $scope.cg = {
    primaryColor: EmbeddedGivingColors.getPrimary($scope.theme),
    backgroundColor: EmbeddedGivingColors.getAccent($scope.theme)
  };

  $scope.showCryptoGiving = function () {
    CryptoGiving.show();
  };

  $scope.hideCryptoGiving = function () {
    CryptoGiving.hide();
  };
}]);
})();