(function(){
'use strict';

scValidityReporterFactory.$inject = ["$timeout", "scValidityModel"];
function scValidityReporterFactory($timeout, scValidityModel) {
  return {
    priority: 2,
    require: {
      ngModelCtrl: '?ngModel',
      formCtrl: '?form',
      parentFormCtrl: '^^?form'
    },
    bindToController: {
      scValidityModel: '<',
      scValidityReporter: '<'
    },
    controllerAs: 'scValidityReporterCtrl',
    controller: ["$scope", "$element", function controller($scope, $element) {
      this.$onInit = function () {
        var ngModelCtrl = this.ngModelCtrl;
        var formCtrl = this.formCtrl;
        var parentFormCtrl = this.parentFormCtrl;

        /* ------------------------------------------------------------------ *
         * Find root form
         *
         * The scValidityModel is placed on the root form so that other
         * controls and subforms can discover and share it.
         *
         * This allows a form with reporting controls to automatically set up
         * and wire its own scValidityModel.
         * ------------------------------------------------------------------ */

        var rootForm = parentFormCtrl || formCtrl;

        while (rootForm && rootForm.$$parentForm) {
          rootForm = rootForm.$$parentForm;
        }

        /* ------------------------------------------------------------------ *
         * Find alias
         *
         * This will serve as the label for this control or subform for
         * purposes of scValidity. Defaults to the control's $name.
         * ------------------------------------------------------------------ */

        var alias = this.scValidityReporter || (ngModelCtrl ? ngModelCtrl.$name : formCtrl.$name);

        /* ------------------------------------------------------------------ *
         * Find or create validity manager
         *
         * If an scValidityModel is provided, that model is used.
         *
         * Otherwise, we search for a model on the root form, in case one was
         * implicitly created by another control.
         *
         * If there isn't one, this must be the first control wired up, so we
         * generate a new scValidityModel.
         *
         * In any event, we place the model on both our control and the root
         * form so other controls and subforms can find it.
         * ------------------------------------------------------------------ */

        var validityModel = this.scValidityModel;

        if (!validityModel) validityModel = rootForm.$scValidityModel;

        if (!validityModel) validityModel = new scValidityModel();

        if (ngModelCtrl) {
          ngModelCtrl.$scValidityModel = validityModel;
        } else {
          formCtrl.$scValidityModel = validityModel;
        }

        rootForm.$scValidityModel = validityModel;
        this.scValidityModel = rootForm.$scValidityModel;

        /* ------------------------------------------------------------------ *
         * Find parent alias, if applicable
         *
         * If this control or subform's parent form (or any ancestor form) is
         * also a reporter, we need to inform scValidityModel of the
         * relationship so that validation can bubble up through the tree.
         *
         * Because scValidity is set up in the controller, any parent forms
         * should already have their scValidity wired up by this point.
         * ------------------------------------------------------------------ */

        var parentAlias = null;
        var parentCtrl = parentFormCtrl;

        while (parentCtrl) {
          if (parentCtrl.$scValidityReporter && parentCtrl.$scValidityModel === validityModel) {
            parentAlias = parentCtrl.$scValidityReporter.alias;
            break;
          }
          parentCtrl = parentCtrl.$$parentForm;
        }

        /* ------------------------------------------------------------------ *
         * Register form
         *
         * If this reporter is a form or subform, we're only interested in
         * wrapping the setSubmitted and setPristine methods, so we can
         * update nested controls' $ignore property). We canskip the rest of
         * the focus validity wiring.
         * ------------------------------------------------------------------ */

        if (!ngModelCtrl) {
          formCtrl.$scValidityReporter = validityModel.register('form', alias, parentAlias);

          this.scValidityReporter = formCtrl.$scValidityReporter;

          formCtrl.$setPristine = _.wrap(formCtrl.$setPristine, function ($setPristine) {
            $setPristine.apply(formCtrl);
            formCtrl.$scValidityReporter.setSubmitState(false);
          });

          formCtrl.$setSubmitted = _.wrap(formCtrl.$setSubmitted, function ($setSubmitted) {
            $setSubmitted.apply(formCtrl);
            formCtrl.$scValidityReporter.setSubmitState(true);
          });

          // That's all folks!
          return;
        }

        /* ------------------------------------------------------------------ *
         * Register control
         *
         * If this reporter is a control, we need to register and then
         * continue on to define when its errors should be ignored.
         * ------------------------------------------------------------------ */

        ngModelCtrl.$scValidityReporter = validityModel.register('field', alias, parentAlias);

        this.scValidityReporter = ngModelCtrl.$scValidityReporter;

        /* ------------------------------------------------------------------ *
         * $syncValidity()
         *
         * Sends the current $pending, $error, and $ignore state to the
         * reporter.
         * ------------------------------------------------------------------ */

        ngModelCtrl.$syncValidity = function () {
          ngModelCtrl.$scValidityReporter.update(ngModelCtrl.$error, ngModelCtrl.$pending, ngModelCtrl.$ignore);
        };

        /* ------------------------------------------------------------------ *
         * Validate when form $submitted property changes, as this may affect
         * value of $ignore.
         * ------------------------------------------------------------------ */

        // eslint-disable-next-line no-unused-vars
        $scope.$on('scValidity.submitted', function (e, formAlias, submitted) {
          ngModelCtrl.$ignore = !submitted;
          ngModelCtrl.$syncValidity();
        });

        /* ------------------------------------------------------------------ *
         * Wrap $setValidity
         *
         * Whenever a validity value changes, update the reporter.
         * ------------------------------------------------------------------ */

        ngModelCtrl.$setValidity = _.wrap(ngModelCtrl.$setValidity, function ($setValidity) {
          for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            args[_key - 1] = arguments[_key];
          }

          $setValidity.apply(ngModelCtrl, args);
          ngModelCtrl.$syncValidity();
        });
      };

      this.$postLink = function () {
        var _this = this;

        var ngModelCtrl = this.ngModelCtrl;
        var parentFormCtrl = this.parentFormCtrl;

        if (ngModelCtrl) {
          /* ------------------------------------------------------------------ *
           * $ignore
           *
           * Passed to the reporter on update. If true, the reporter will
           * register the field as valid, even if errors exist.
           * ------------------------------------------------------------------ */
          ngModelCtrl.$ignore = true;

          /* ------------------------------------------------------------------ *
           * ignoreIf
           *
           * Utility for setting $ignore. If the parent form is in its
           * submitted state, we ignore our ignorance.
           * ------------------------------------------------------------------ */
          var ignoreIf = function ignoreIf(ignore) {
            if (parentFormCtrl && parentFormCtrl.$submitted) {
              ngModelCtrl.$ignore = false;
            } else {
              ngModelCtrl.$ignore = !!ignore;
            }
          };

          /* ------------------------------------------------------------------ *
           * $focus()
           *
           * On focus (or a corresponding event for a custom form control), set
           * $ignore to true if the control has not yet been interacted with,
           * or is currently valid. Immediately check validity to get an
           * updated state (with current $ignore value) to the reporter.
           * ------------------------------------------------------------------ */
          ngModelCtrl.$focus = function () {
            ignoreIf(ngModelCtrl.$pristine || ngModelCtrl.$untouched || ngModelCtrl.$valid);
            ngModelCtrl.$validate();
          };

          /* ------------------------------------------------------------------ *
           * $blur()
           *
           * On blur (or a corresponding event for a custom form control), set
           * $ignore to false unless the control still has not been interacted
           * with. Immediately check validity to get an updated state (with
           * current $ignore value) to the reporter.
           * ------------------------------------------------------------------ */
          ngModelCtrl.$blur = function () {
            ignoreIf(ngModelCtrl.$pristine);
            ngModelCtrl.$syncValidity();
          };
        }

        /* -------------------------------------------------------------------- *
         * Wire up focus and blur handlers.
         * -------------------------------------------------------------------- */
        $element.on('focus', function () {
          return $timeout(function () {
            return _this.ngModelCtrl.$focus();
          });
        });
        $element.on('blur', function () {
          return $timeout(function () {
            return _this.ngModelCtrl.$blur();
          });
        });
      };

      this.$onDestroy = function () {
        this.scValidityModel.deregister(this.scValidityReporter);
      };
    }]
  };
}

angular.module('classy').directive('scValidityReporter', scValidityReporterFactory);
})();