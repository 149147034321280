(function(){
'use strict';

/**
 * @kind service
 * @name scUserAgent
 *
 * @description
 * helper methods for parsing the ua, relying on ua-parser.js
 */

function scUserAgent() {
  var mobileOS = ['Android', 'Bada', 'Windows [Phone/Mobile]', 'iOS', 'BlackBerry', 'MeeGo', 'Palm', 'Symbian', 'WebOS', 'Sailfish'];
  var ieOrEdge = ['Edge', 'IE'];

  return {
    ua: {},

    isUnsupportedBrowser: function isUnsupportedBrowser() {
      var ua = this.getUa();
      return _.get(ua, 'browser.name') === 'IE' && parseInt(_.get(ua, 'browser.major', 0), 10) <= 11;
    },
    getUa: function getUa() {
      if (!_.isEmpty(ua)) {
        return ua;
      }
      var uaParser = new UAParser();
      var ua = uaParser.getResult();
      this.ua = ua;
      return ua;
    },
    isIE: function isIE() {
      var ua = this.getUa();
      return ieOrEdge.indexOf(ua.browser.name) > -1;
    },
    isMobile: function isMobile() {
      var ua = this.getUa();
      return ua.device.type === 'mobile' || mobileOS.indexOf(ua.os.name) > -1;
    },
    isPrerender: function isPrerender() {
      /*
        // eslint-disable-next-line max-len
        Mozilla/5.0 (Unknown; Linux x86_64) AppleWebKit/538.1 (KHTML, like Gecko) PhantomJS/2.1.1 Safari/538.1 Prerender (+https://github.com/prerender/prerender)
        See https://prerender.io/documentation/faq
         OR
         // eslint-disable-next-line max-len
        Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/61.0.3159.5 Safari/537.36 Prerender (+https://github.com/prerender/prerender)
        See https://prerender.io/documentation/chrome-server
       */
      var ua = this.getUa();
      return ua.ua.includes('Prerender') && (
      // Our UA libraries do not properly parse HeadlessChrome, so check the raw string for it
      ua.browser.name === 'PhantomJS' || ua.ua.includes('HeadlessChrome'));
    },
    isGooglebot: function isGooglebot() {
      /*
        Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)
      */
      return this.getUa().ua.includes('Googlebot');
    },
    isBot: function isBot() {
      return this.isPrerender() || this.isGooglebot();
    },
    isIOSx: function isIOSx() {
      // returns if useragent is iOS and version 10.0 +
      var ua = this.getUa();
      return ua.os.name === 'iOS' && parseFloat(ua.os.version) >= 10.0;
    },
    isIOS11: function isIOS11() {
      // returns if useragent is iOS and version 11.0 +
      var ua = this.getUa();
      return ua.os.name === 'iOS' && parseFloat(ua.os.version) >= 11.0;
    }
  };
}

angular.module('classy').service('scUserAgent', scUserAgent);
})();