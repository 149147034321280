(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scRegistrationModel
 *
 */

scTransactionModel.$inject = ["scObjectModel", "scCloneDeep"];
function scTransactionModel(scObjectModel, scCloneDeep) {
  function TransactionModel(data, demo) {
    scObjectModel.call(this);

    this.isNew = !demo && !data;
    this.isDemo = demo || !data;

    this.current = scCloneDeep(data || {});
    this.defaults = scCloneDeep({});
    this.isExample = false;
    this.saved = scCloneDeep(data || {});
    this.type = 'transactions';
  }

  var model = TransactionModel;

  // Constructor
  model.prototype = _.create(scObjectModel.prototype, {
    constructor: TransactionModel
  });

  // Methods
  model.prototype.create = create;

  return TransactionModel;

  /* ---------------------------------------------------------------- *
   * Implementation
   * ---------------------------------------------------------------- */

  function create() {
    // TODO
  }
}

angular.module('classy').factory('scTransactionModel', scTransactionModel);
})();