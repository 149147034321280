(function(){
'use strict';

scMetaTags.$inject = ["$http", "$q", "$timeout", "scCampaignsService", "scBlocksService", "scFundraisingTeamsService", "scFundraisingPagesService", "scOrganizationsService"];
var defaultCampaignDescription = 'Help us reach our goal! Your support will make a meaningful difference. Every contribution brings us closer to success. Join us today and be part of something impactful.';

function scMetaTags($http, $q, $timeout, scCampaignsService, scBlocksService, scFundraisingTeamsService, scFundraisingPagesService, scOrganizationsService) {
  var obj = {};
  var appealSetData = {};

  obj.init = function () {
    var deferred = $q.defer();

    var campaign = void 0;

    if (typeof scCampaignsService.active === 'undefined' && typeof SC.campaign !== 'undefined') {
      scCampaignsService.add(SC.campaign);
      scCampaignsService.setActive(SC.campaign.id);
      campaign = scCampaignsService.active;
    } else {
      campaign = scCampaignsService.active;
    }

    var blankAppealSet = {
      id: null,
      campaign_id: campaign.current.id,
      facebook_asset_id: null,
      facebook_image_url: null,
      facebook_text: null,
      facebook_video_url: null,
      sms_text: null,
      twitter_text: null,
      email_subject: null,
      email_body: null
    };

    function generateAppealSet(appealSet) {
      var appealDeferred = $q.defer();

      if (appealSet.id) {
        // we are good
        appealDeferred.resolve(appealSet);
      } else {
        appealDeferred.resolve(blankAppealSet);
      }

      return appealDeferred.promise;
    }

    /*
     * Set a timeout and store the promise in order to be cancelled later if
     * the data does not arrive within 2s */
    var timeoutPromise = $timeout(function () {
      deferred.resolve(blankAppealSet);
    }, 2000);

    var appealSet = _.get(scCampaignsService, 'active.current.appealSet');

    // If the appealSet is an empty/defined object, we've already tried to retrieve it
    // if appealSet is UNDEFINED, then we HAVEN'T retrieved it, and must make an API call.
    if (appealSet) {
      deferred.resolve(appealSet);
    } else {
      $http({
        method: 'GET',
        url: '/frs-api/campaign/' + campaign.current.id + '/appeal-set'
      }).then(function (_ref) {
        var data = _ref.data;

        deferred.resolve(generateAppealSet(data));

        $timeout.cancel(timeoutPromise);
      });
    }

    return deferred.promise;
  };

  obj.setAppealSetData = function (data) {
    appealSetData = data;
  };

  obj.getAppealSetData = function () {
    return appealSetData;
  };

  obj.getDescription = function () {
    var aboutBlock = scBlocksService.getBlockByType('about'),
        aboutContent = aboutBlock ? aboutBlock.current.content : defaultCampaignDescription;
    var strippedContent = aboutContent.replace(/<\/p>/g, '\n\n').replace(/<[^>]+>/g, '');

    return strippedContent;
  };

  obj.getEntityDescription = function (entity) {
    var desc = void 0;

    if (entity === 'team') {
      desc = _.get(scFundraisingTeamsService, 'active.current.description');
    } else {
      desc = _.get(scFundraisingPagesService, 'active.current.intro_text');
    }

    var campDesc = _.get(scCampaignsService, 'active.current.default_' + entity + '_appeal');

    var def = 'A fundraising ' + entity + ' benefiting ' + scOrganizationsService.active.current.name;

    return (desc || campDesc || def).replace(/<.*?>/g, '');
  };

  return obj;
}

angular.module('classy').factory('scMetaTags', scMetaTags);
})();