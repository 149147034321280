(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scRegistrationModel
 *
 */

scFeedItemModel.$inject = ["scObjectModel", "scCloneDeep"];
function scFeedItemModel(scObjectModel, scCloneDeep) {
  function FeedItemModel(data, demo) {
    scObjectModel.call(this);

    this.isNew = !demo && !data;
    this.isDemo = demo || !data;

    this.current = scCloneDeep(data || {});
    this.defaults = scCloneDeep({});
    this.isExample = false;
    this.saved = scCloneDeep(data || {});
    this.type = 'feed-items';
  }

  var model = FeedItemModel;

  // Constructor
  model.prototype = _.create(scObjectModel.prototype, {
    constructor: FeedItemModel
  });

  // Methods
  model.prototype.create = create;

  return FeedItemModel;

  /* ---------------------------------------------------------------- *
   * Implementation
   * ---------------------------------------------------------------- */

  function create() {
    // TODO
  }
}

angular.module('classy').factory('scFeedItemModel', scFeedItemModel);
})();