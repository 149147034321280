(function(){
'use strict';

angular.module('classy').component('imageListField', {
  templateUrl: 'global/components/fields/imageList/template',

  bindings: {
    model: '=',
    config: '<?',
    customNextBtn: '<?',
    customCancelBtn: '<?',
    required: '<?',
    formName: '<?',
    buttonLock: '<?',
    mode: '<?',
    imgLimit: '<?',
    sortable: '<?',
    video: '<?',
    errorMessages: '<?',
    lockField: '<?'
  },

  controller: ["$attrs", "scCampaignsTemplateService", "$stateParams", "accessibilityService", function ImageListFieldCtrl($attrs, scCampaignsTemplateService, $stateParams, accessibilityService) {
    var _this = this;

    this.remove = function () {
      this.model = null;
    };

    var DEFAULTS = {
      config: {
        hideActionButtons: false,
        label: 'Upload an image',
        imgOptions: null,
        mmOptions: {
          // Media Manager props that determine the configs for the React Image Editor.
          // reactEditorOptions are merged as options passed to mediaManagerEditor
          // reactEditorOptions only applies if useReactEditor is set to true.
          useReactEditor: false,
          reactEditorOptions: {}
        }
      },
      customNextBtn: {
        action: null,
        label: null
      },
      customCancelBtn: {
        action: null,
        label: null
      },
      errorMessages: {
        required: 'This field is required.'
      },
      required: false,
      video: false,
      sortable: false,
      mode: 'single',
      imgLimit: 1
    };

    this.onKeyDown = function (event) {
      if (event && accessibilityService.isValidKeyBoardEvent(event)) {
        event.preventDefault();
        if (_this.customCancelBtn.action && !_this.model) _this.customCancelBtn.action();else _this.remove();
      }
    };

    this.onkeydownCustomNextBtn = function (event) {
      if (event && accessibilityService.isValidKeyBoardEvent(event)) {
        _this.customNextBtn.action();
      }
    };

    this.$onInit = function () {
      if (typeof _this.lockField === 'undefined' && SC.campaign && SC.campaign.campaignTemplateData) {
        _this.lockField = false;
        var getLockedField = function getLockedField(type) {
          var lockedBlock = scCampaignsTemplateService.getBlockByType(type);
          if (lockedBlock) {
            var fieldName = $attrs.model.split('.').splice(-2)[0];
            if ($attrs.model.includes('image')) {
              if (lockedBlock.item_attributes && lockedBlock.item_attributes[fieldName] && lockedBlock.item_attributes[fieldName].image) {
                _this.lockField = lockedBlock.item_attributes[fieldName].image.locked;
              }
            }
          }
        };
        var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
        if (blockObj) {
          getLockedField(blockObj.type);
        } else if ($stateParams.step === 'landing') {
          var lockHeroBlock = scCampaignsTemplateService.getBlockByType('crowdfunding');
          if (lockHeroBlock) {
            var fieldName = $attrs.model.split('.')[2];
            if (_.includes(['headlineImage', 'background'], fieldName)) {
              if (lockHeroBlock.item_attributes && lockHeroBlock.item_attributes[fieldName] && lockHeroBlock.item_attributes[fieldName].image) {
                _this.lockField = lockHeroBlock.item_attributes[fieldName].image.locked;
              }
            }
          }
        }
      }
      _.defaultsDeep(_this, DEFAULTS);
    };
  }]
});
})();