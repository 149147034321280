(function(){
'use strict';

scActivityBlockSettings.$inject = ["scFroalaOptions"];
function scActivityBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/activity/settings',
    controller: ['$scope', function ($scope) {
      $scope.froalaOptions = scFroalaOptions({ type: 'bare-headline' });

      $scope.aboutOptions = scFroalaOptions({ type: 'inline-with-video' }, {
        ariaLabel: 'Content'
      });

      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Headline'
      });

      $scope.showHeadline = !$scope.block.hideHeadline;

      $scope.$watch('showHeadline', function (newVal) {
        $scope.block.hideHeadline = !newVal;
      });
    }],

    link: function link() {}
  };
}

angular.module('classy').directive('scActivityBlockSettings', scActivityBlockSettings);
})();