(function(){
'use strict';

/**
 * @kind directive
 * @name scResponsiveVideo
 *
 * @description
 * Sizes videos to browser width but not larger than video dimensions. Resizes on window resize.
 */

scResponsiveVideo.$inject = ["$window", "$timeout"];
function scResponsiveVideo($window, $timeout) {
  // eslint-disable-next-line no-unused-vars
  return function (scope, element) {
    var $element = void 0,
        $allVideos = void 0;

    $timeout(function () {
      $element = element;

      $allVideos = $element.find("iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com']");

      // Figure out and save aspect ratio for each video
      $allVideos.each(function () {
        var vidHeight = _.parseInt(this.height || $(this)[0].style.height);
        var vidWidth = _.parseInt(this.width || $(this)[0].style.width);
        var aspectRatio = vidHeight && vidWidth ? vidHeight / vidWidth : 0.5625;

        $(this).data('aspectRatio', aspectRatio).data('origWidth', vidWidth);
      });

      resize();
    });

    function resize() {
      if (!_.isEmpty($allVideos)) {
        var containerWidth = $element.width();
        // Resize all videos according to their own aspect ratio
        $allVideos.each(function () {
          var $video = $(this);

          if (this.width > containerWidth || $video.data('origWidth') > containerWidth) {
            $video
            // and remove the hard coded width/height
            .prop('height', false).prop('width', false);

            $video.width(containerWidth).height(containerWidth * $video.data('aspectRatio'));
          } else if (!this.width) {
            if ($video.data('origWidth')) {
              $video.width($video.data('origWidth')).height($video.data('origWidth') * $video.data('aspectRatio'));
            } else {
              $video.width(containerWidth).height(containerWidth * $video.data('aspectRatio'));
            }
          }
        });
      }
    }

    var resizeDebounce = _.debounce(resize, 50);
    angular.element($window).on('resize orientationchange', resizeDebounce);
  };
}

angular.module('classy').directive('scResponsiveVideo', scResponsiveVideo);
})();