(function(){
'use strict';

angular.module('classy').component('dateDropdownField', {
  template: '\n    <form-label ng-if="$ctrl.label"\n      required="$ctrl.required"\n      _spec="label">{{::$ctrl.label}}</form-label>\n\n    <date-dropdown-control\n      ng-model="modelWatched"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      ng-required="$ctrl.required"\n      sc-validity-reporter\n      sc-validity-classes\n      name="{{::$ctrl.name}}"></date-dropdown-control>\n\n    <form-error\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n  ',
  bindings: {
    model: '=',
    name: '<',
    label: '<',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    format: '<?'
  },
  controller: ["$scope", function DateDropdownFieldCtrl($scope) {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.',
        dayRequired: 'Please enter a complete date.',
        monthRequired: 'Please enter a complete date.',
        yearRequired: 'Please enter a complete date.'
      },
      getterSetter: false,
      required: false
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
      this.format = this.format || 'MM/DD/YYYY';
    };

    $scope.$watch(angular.bind(this, function () {
      return this.model();
    }), function (value) {
      // ALWAYS be defensive with $watchers. this.model() can return undefined
      // and moment.utc will throw an error when passed an undefined value
      if (value) {
        // Why would we not use the format setup via bindings??? (from hunter)
        //
        // Answer from Tony: When I switched to use `this.format` it was being passed in as YYYY-MM-DD
        // which the date picker did not like at all. It only appears to work like this.
        $scope.modelWatched = value === 'Invalid date' ? '' : moment.utc(value).format('MM/DD/YYYY');
      }
    });

    $scope.$watch('modelWatched', function (value) {
      // ALWAYS be defensive with $watchers.
      if (value) {
        // Why would we not use the format setup via bindings??? (from hunter, see above)
        //
        // Answer from Tony: see answer in watcher above ------^
        _this.model(moment(value, 'MM/DD/YYYY').format(_this.format));
      }
    });
  }]
});
})();