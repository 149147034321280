(function(){
'use strict';

onIOSBlur.$inject = ["$window"];
function onIOSBlur($window) {
  var iOS = $window.navigator.userAgent.match(/(iPad|iPhone|iPod)/g) && !$window.MSStream;

  return function (element, cb) {
    if (!iOS) return _.noop;

    function handler($e) {
      if (!angular.element($e.target).closest(element).length) {
        cb();
      }
    }

    angular.element($window).on('touchstart focusin', handler);

    return function () {
      return angular.element($window).off('touchstart focusin', handler);
    };
  };
}

angular.module('classy').factory('onIOSBlur', onIOSBlur);
})();