(function(){
'use strict';

angular.module('classy').component('registrationEntityTeamDetails', {
  template: '\n      <team-details-modal\n      title="\'Create your team\'"\n      primary-color="$ctrl.primaryColor"\n      campaign-id="$ctrl.campaignId"\n      team-name-model="$ctrl.model.team_name"\n      team-goal-model="$ctrl.model.team_goal"\n      teams-can-set-goal="$ctrl.teamsCanSetGoal"\n      teams-can-set-appeal="$ctrl.teamsCanSetAppeal"\n      team-description-model="$ctrl.model.team_description"\n      include-vanity-url="false"\n      on-next="$ctrl.goToQuestions()">\n      </team-details-modal>\n    ',
  bindings: {
    model: '<',
    goToQuestions: '&',
    campaignId: '<',
    primaryColor: '<',
    teamsCanSetGoal: '=?',
    teamsCanSetAppeal: '=?'
  }
});
})();