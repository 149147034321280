(function(){
'use strict';

/**
 * @name scFormError
 * @kind directive
 *
 * @description
 * Listens for error events $broadcasted by scBroadcastFormErrors and modifies
 * its host element in response (by showing/hiding or adding/removing the
 * sc-form-error class).
 */

scFormError.$inject = ["scRequestAnimationFrame"];
function scFormError(scRequestAnimationFrame) {
  'use strict';

  var EXPRESSION_REGEX = /^(show|hide|highlight) if (.*?) fails\s?(.*?)?$/;

  return function (scope, element, attrs) {
    function showAction(isValid) {
      scRequestAnimationFrame(function () {
        return isValid ? element.hide() : element.show();
      });
    }

    function hideAction(isValid) {
      scRequestAnimationFrame(function () {
        return isValid ? element.show() : element.hide();
      });
    }

    function highlightAction(isValid) {
      scRequestAnimationFrame(function () {
        return isValid ? element.removeClass('sc-form-error') : element.addClass('sc-form-error');
      });
    }

    var expression = attrs.scFormError;
    var args = EXPRESSION_REGEX.exec(expression);

    if (!args) {
      return;
    }

    var action = args[1];
    var controls = args[2];
    var test = args[3];

    if (controls.match(/\[.*\]/)) {
      controls = controls.replace(/[[\]\s]/g, '');
      controls = controls.split(',');
    } else {
      controls = [controls];
    }

    var atomicValidity = {};
    _.forEach(controls, function (control) {
      atomicValidity[control] = true;
    });

    var performAction = _.noop;

    switch (action) {
      case 'show':
        element.hide();
        performAction = showAction;
        break;
      case 'hide':
        performAction = hideAction;
        break;
      case 'highlight':
        performAction = highlightAction;
        break;
      default:
      // do nothing
    }

    /**
     * @name aggregate
     * @kind function
     * @parent scFormError
     *
     * @description
     * Aggregates the validity of individual control/validator pairs into an
     * overall validity value that gets fed to performAction.
     *
     * Aggregate validity is TRUE if EVERY control/validator pair is valid.
     * Aggregate validity is FALSE if ANY control/validator pair is invalid.
     */
    function aggregate(validity) {
      return Object.keys(validity).every(function (key) {
        return validity[key];
      });
    }

    function isFormInput(el) {
      // get form inputs by avoiding error spans and input labels
      return el.localName !== 'span' && el.localName !== 'label';
    }

    _.forEach(controls, function (control) {
      // set initial label values to false by default
      if (isFormInput(element[0])) {
        attrs.$set('aria-invalid', false);
      }

      // eslint-disable-next-line no-unused-vars
      scope.$on('VALIDATION_CHANGE:' + control, function ($event, validator, isValid) {
        // 'required' === 'required' or undefined === undefined
        if (test === validator) {
          atomicValidity[control] = isValid;
          performAction(aggregate(atomicValidity));
        }

        // set aria-invalid label when the validity of the element changes
        if (isFormInput(element[0])) {
          attrs.$set('aria-invalid', !isValid);
        }
      });
    });
  };
}

angular.module('classy').directive('scFormError', scFormError);
})();