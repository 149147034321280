(function(){
'use strict';

accessibilityService.$inject = ["$timeout"];
function accessibilityService($timeout) {
  'use strict';

  var CONSTANTS = {
    ENTER_KEY: 13,
    SPACE_KEY: 32,
    ESC_KEY: 27,
    TAB_KEY: 9,
    KEYDOWN_EVENT: 'keydown',
    ELEMENTS_TO_FOCUS: 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
  };

  return {
    CONSTANTS: CONSTANTS,
    isValidKeyBoardEvent: isValidKeyBoardEvent,
    handleModalKeyDownEvent: handleModalKeyDownEvent,
    initiateModalFocus: initiateModalFocus,
    initiatePageFocus: initiatePageFocus
  };

  /**
   * Used to verify that a keyboard event originated either  from a space or enter key.
   * This is useful when we need to add ng-keydown to a button that already has ng-click
   * @param {Object} event The event that triggered the keydown event
   * @returns {boolean}
   */
  function isValidKeyBoardEvent(event) {
    var isValid = false;
    // If the user pressed a key to select one of the "buttons", and it wasn't the Enter key, let's return.
    // We only want the user to select a level using the Enter key.
    // The event parameter is only defined on a keydown event.
    if (event.keyCode === CONSTANTS.ENTER_KEY || event.keyCode === CONSTANTS.SPACE_KEY) {
      // The default action for clicking a spacebar is to scroll the page down.
      // We want to prevent this so that users with accessibility needs can click on the element correctly.
      // this has no negative effect for the enter key so it's safe to prevent default that as well.
      event.preventDefault();
      isValid = true;
    }

    return isValid;
  }

  /**
   * Event handler used by the scFlowModalService to manage keyboard based events
   * primarily used to make sure a user's tab stays within the bounds of the modal
   * @param {Object} flowModal The instance of the flowModal service
   * @param {Object} event The event that triggered the keydown event
   * @returns {void}
   */
  function handleModalKeyDownEvent(flowModal, event) {
    function handleBackwardTab() {
      if (document.activeElement === flowModal.firstFocusableEl) {
        event.preventDefault();
        flowModal.lastFocusableEl.focus();
      }
    }
    function handleForwardTab() {
      if (document.activeElement === flowModal.lastFocusableEl) {
        event.preventDefault();
        flowModal.firstFocusableEl.focus();
      }
    }

    switch (event.keyCode) {
      case CONSTANTS.TAB_KEY:
        // If there is only one focusable element in the modal, there is no need to handle tabbing events
        if (flowModal.focusableEls.length === 1) {
          event.preventDefault();
          break;
        }
        if (event.shiftKey) {
          handleBackwardTab();
        } else {
          handleForwardTab();
        }
        break;
      case CONSTANTS.ESC_KEY:
        flowModal.close();
        break;
      default:
        break;
    }
  }

  /**
   * Used to set up the initial focus on a modal created by the scFlowModalService
   * @param {Object} flowModal The instance of the flowModal service
   * @param {Object} modalOverlay The overlay for the modal
   * @returns {void}
   */
  function initiateModalFocus(flowModal, $modalOverlay) {
    if (flowModal && flowModal.activeView) {
      // find all focusable elements that are children of the overlay
      flowModal.focusableEls = $modalOverlay.find(CONSTANTS.ELEMENTS_TO_FOCUS);

      // store a reference to the first and last elements, this will allow us to control tabbing within the modal
      flowModal.firstFocusableEl = flowModal.focusableEls[0];
      flowModal.lastFocusableEl = flowModal.focusableEls[flowModal.focusableEls.length - 1];

      // first remove any previous keydown event handlers to avoid duplicates on same element
      $modalOverlay.off(CONSTANTS.KEYDOWN_EVENT);
      $modalOverlay.on(CONSTANTS.KEYDOWN_EVENT, function (event) {
        handleModalKeyDownEvent(flowModal, event);
      });

      // focus on the first element after the next digest cycle
      $timeout(function () {
        flowModal.focusableEls[0].focus();
      }, 0);
    }
  }

  /**
   * Used to set up the initial focus on a page
   * Takes in parent element css selector as a string and then finds all focusable child elements.
   * @param {String} selector Css selector of parent element.
   * @returns {void}
   */
  function initiatePageFocus(selector) {
    $timeout(function () {
      var focusableEls = angular.element(selector).find(CONSTANTS.ELEMENTS_TO_FOCUS);
      if (focusableEls[0] && focusableEls[0].focus) {
        focusableEls[0].focus();
      }
    }, 0);
  }
}

angular.module('classy').factory('accessibilityService', accessibilityService);
})();