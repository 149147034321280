(function(){
'use strict';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function CQAnswerModelFactory() {
  return function CQAnswerModel(id, questions, answers) {
    _classCallCheck(this, CQAnswerModel);

    var originalAnswers = angular.copy(answers);

    return function () {
      var mode = arguments.length ? 'write' : 'read';
      var newVal = arguments.length <= 0 ? undefined : arguments[0];

      // If passed a tag, convert to question_id
      var questionId = id;
      if (!/^\d+$/.test(id)) {
        questionId = _.find(questions, { tag: id }).id;
      }

      var answerObj = _.find(answers, { question_id: questionId });
      // Keeps tracks of any new answer objects we're created to determine if
      // we're creating a new answer or updating an existing one
      var answerInOriginalObject = _.find(originalAnswers, { question_id: questionId });

      /* ---------------------------------------------------------------- *
       * Write mode
       * ---------------------------------------------------------------- */

      if (mode === 'write') {
        // Quirk of custom questions: if the answer object is in the
        // answers array, it must be answered. Updating this to let empty
        // strings to to pass through for FRS-7544 because we need want to keep
        // track of these updated answers so that we can remove the answer
        // properly with the DELETE /answers/:id endpoint
        if (!newVal && newVal !== 0 && _.isEmpty(answerInOriginalObject)) {
          _.remove(answers, { question_id: questionId });
          return undefined;
        }

        // If the answer object does not yet exist, create it.
        if (!answerObj) {
          answerObj = { question_id: questionId };
          answers.push(answerObj);
        }

        // Finally, define the answer.
        answerObj.answer = newVal;
        return answerObj.answer;
      }

      /* ---------------------------------------------------------------- *
       * Read mode
       * ---------------------------------------------------------------- */

      if (mode === 'read') {
        // If answer object does not exist, don't create it.
        if (!answerObj) {
          return undefined;
        }

        if (_.get(answerObj, 'question.type', false) === 'integer') {
          return parseInt(answerObj.answer, 10);
        }
        return answerObj.answer;
      }

      return undefined;
    };
  };
}

angular.module('classy').factory('CQAnswerModel', CQAnswerModelFactory);
})();