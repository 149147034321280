(function(){
'use strict';

scCustomBlockSettings.$inject = ["$http", "$log", "scBanner", "$stateParams", "scCampaignsTemplateService"];
function scCustomBlockSettings($http, $log, scBanner, $stateParams, scCampaignsTemplateService) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/custom/settings',
    controller: ['$scope', function ($scope) {
      /* -------------------------------------------------------------------- *
       * Config
       * -------------------------------------------------------------------- */

      $scope.config = {
        bgMediaManagerOptions: {
          title: 'Select a Custom Section Background',
          image: {
            guide: {
              title: 'Custom Section Background',
              message: "You're not tied to a color. Add a background image.",
              size: '1280 x 720 pixels'
            }
          }
        },

        htmlEditorOptions: {
          useWrapMode: true,
          theme: 'monokai',
          mode: 'html',
          showGutter: false,
          onLoad: function onLoad(editor) {
            editor.getSession().setOptions({
              tabSize: 2,
              useSoftTabs: true
            });
          }
        },

        cssEditorOptions: {
          useWrapMode: true,
          theme: 'monokai',
          mode: 'css',
          showGutter: false,
          onLoad: function onLoad(editor) {
            editor.getSession().setOptions({
              tabSize: 2,
              useSoftTabs: true
            });
          }
        }
      };

      $scope.disableCustomBlock = false;
      $scope.isCustomMarkupDisabled = false;
      $scope.isCustomBgDisabled = false;
      if (SC.campaign && SC.campaign.campaignTemplateData) {
        $scope.disableCustomBlock = scCampaignsTemplateService.getAddContentToPageBlock($stateParams.blockId);
        if ($scope.block.type === 'custom') {
          var activeBlock = null;
          var foundBlockPayload = scCampaignsTemplateService.getBlockComponent($stateParams.blockId);
          activeBlock = scCampaignsTemplateService.getActiveBlock(foundBlockPayload);
          $scope.isCustomMarkupDisabled = _.get(activeBlock, 'item_attributes.html.locked', false);
          $scope.isCustomBgDisabled = _.get(activeBlock, 'item_attributes.background.locked', false);
        }
      }

      /* -------------------------------------------------------------------- *
       * State
       * -------------------------------------------------------------------- */

      $scope.state = {
        activeTab: 'html',
        processingHtml: false,
        processingCss: true
      };

      $scope.sanitizeHtml = function () {
        $scope.processingHtml = true;
        $http.post('/frs-api/sanitize/html', {
          content: $scope.block.unsafeHtml
        }).then(function (result) {
          $scope.block.html = result.data.html;
        }).catch(function (err) {
          $log.error(err);
          scBanner('Sorry, your HTML could not be processed. Please check your syntax and try again.');
        }).finally(function () {
          $scope.state.processingHtml = false;
        });
      };

      $scope.sanitizeCss = function () {
        $scope.processingCss = true;
        $http.post('/frs-api/sanitize/css', {
          content: $scope.block.unsafeCss,
          scope: '#block-' + $scope.block.id
        }).then(function (result) {
          $scope.block.css = result.data.css;
        }).catch(function (err) {
          $log.error(err);
          scBanner('Sorry, your CSS could not be processed. Please check your syntax and try again.');
        }).finally(function () {
          $scope.state.processingCss = false;
        });
      };

      /* -------------------------------------------------------------------- *
       * API
       * -------------------------------------------------------------------- */

      $scope.tab = function (dest) {
        $scope.state.activeTab = dest;
      };
    }]
  };
}

angular.module('classy').directive('scCustomBlockSettings', scCustomBlockSettings);
})();