(function(){
'use strict';

angular.module('classy').constant('ticketTerms', {
  tickets: {
    singular: 'ticket',
    plural: 'tickets',
    cta: 'Get Tickets'
  },
  registrations: {
    singular: 'registration',
    plural: 'registrations',
    cta: 'Register'
  },
  entries: {
    singular: 'entry',
    plural: 'entries',
    cta: 'Get Entries'
  }
});
})();