(function(){
'use strict';

angular.module('classy').constant('ANALYTICS_EVENTS', {
  REGISTRATION_CREATE_BEGIN: 'registration/create/begin',
  REGISTRATION_CREATE_COMPLETE: 'registration/create/complete',
  DONATION_CREATE_BEGIN: 'donation/create/begin',
  DONATION_CREATE_COMPLETE: 'donation/create/complete',
  RECURRING_DONATION_CREATE_COMPLETE: 'recurring-donation/create/complete',
  SUPPORTER_LOADED: 'supporter_loaded',
  TRANSACTION: 'transaction',
  CAMPAIGN_UPDATED: 'campaign/updated',
  FUNDRAISING_PAGE_CREATE_BEGIN: 'fundraising-page/create/begin',
  FUNDRAISING_PAGE_CREATE_COMPLETE: 'fundraising-page/create/complete',
  DIGITAL_WALLETS_LOADED: 'dw_loaded'
});
})();