(function(){
'use strict';

/**
 * @kind directive
 * @name scCtxBindHtml
 *
 * @description
 * One way bind meant to render HTML in block templates. Trusts implicitly.
 * One-time-binds in public contexts, establishes a watch in other contexts.
 *
 * Inherits and inspects the scope of its environment.
 */

scCtxBindHtml.$inject = ["$sce"];
function scCtxBindHtml($sce) {
  return function (scope, element, attrs) {
    var prop = attrs.scCtxBindHtml;
    function write() {
      element.html($sce.parseAs('html', prop)(scope));
    }
    if (scope.public) {
      write();
    } else {
      scope.$watch(prop, write);
    }
  };
}

angular.module('classy').directive('scCtxBindHtml', scCtxBindHtml);
})();