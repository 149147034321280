(function(){
'use strict';

angular.module('classy').constant('scP2PThemeDefaults', {
  type: 'p2p',
  start: {
    date: new Date(),
    time: new Date()
  },
  styles: {
    headerLogo: null,
    primaryColor: 'rgba(66, 92, 205, 1)',
    footerBgColor: 'rgba(26, 26, 26, 1)',
    aboutBgColor: 'rgba(255, 255, 255, 1)',
    donateButtonColor: 'rgba(230, 232, 239,1)',
    aboutContentColor: 'rgba(26,26,26,1)',
    headlineColor: 'rgba(255,255,255,1)',
    background: {
      color: 'rgba(17, 17, 17, 1)',
      image: null,
      position: 'top center',
      size: 'cover',
      repeat: 'no-repeat',
      imageOpacity: 100,
      colorOpacity: 100,
      blur: 0
    },
    scss: null,
    css: null
  },
  pages: {
    // Landing Page
    landing: {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['crowdfunding', 'about', 'impact', 'fundraisers', 'activity'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    },

    // Team Page
    team: {
      block_sections: [{
        name: 'header',
        position: 'top',
        blocks: ['header']
      }, {
        name: 'body',
        blocks: ['team']
      }, {
        name: 'footer',
        position: 'bottom',
        blocks: ['footer']
      }]
    },

    // Fundraiser Page
    fundraiser: {
      block_sections: [{
        name: 'header',
        position: 'top',
        blocks: ['header']
      }, {
        name: 'body',
        blocks: ['fundraiser']
      }, {
        name: 'footer',
        position: 'bottom',
        blocks: ['footer']
      }]
    },

    // Donation Page
    donation: {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['donation'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    },

    // Thank You Page
    'thank-you': {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['thank-you'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    }
  }
});
})();