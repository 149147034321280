(function(){
'use strict';

/**
 * @kind service
 * @name scFacebookSDKService
 *
 * @description
 * Initialize the Facebook login by setting it's app id
 */

scFacebookSDKService.$inject = ["$q", "$window"];
function scFacebookSDKService($q, $window) {
  var appId = null;

  this.initFacebook = function (fbId) {
    if (!fbId) return $q.reject();else appId = fbId.toString();

    var apiLoaded = $q.defer();

    $window.fbAsyncInit = function () {
      FB.init({
        appId: appId,
        version: 'v2.5',
        xfbml: false
      });
      apiLoaded.resolve(FB);
    };

    (function (d, s, id) {
      if (d.getElementById(id)) {
        document.head.removeChild(document.getElementById('facebook-jssdk'));
        window.FB = null;
      }
      var js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      d.getElementsByTagName('head')[0].appendChild(js);
    })(document, 'script', 'facebook-jssdk');

    return apiLoaded.promise;
  };
}

angular.module('classy').service('scFacebookSDKService', scFacebookSDKService);
})();