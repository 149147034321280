(function(){
'use strict';

angular.module('classy').component('toggle', {
  template: '\n    <span>\n      <input id="toggle_{{$ctrl.scName}}"\n        name="toggle_{{$ctrl.scName}}"\n        class="form_toggle"\n        type="checkbox"\n        ng-checked="$ctrl.checked"\n        ng-click="$ctrl.toggleSelectionClick()"\n        ng-disabled="$ctrl.disabled">\n      <label for="toggle_{{$ctrl.scName}}"\n        class="form_toggle-label"\n        ng-style="{\'cursor\': $ctrl.disabled ? \'not-allowed\' : \'default\' }"\n        tabindex="0" ng-keydown="!$ctrl.disabled && $ctrl.onKeyDown($event)"\n      ></label>\n    </span>\n  ',
  bindings: {
    initialValue: '<',
    disabled: '<',
    scName: '<',
    toggleClick: '&'
  },

  controller: ["accessibilityService", function toggleComponentCtrl(accessibilityService) {
    this.$onInit = function () {
      this.checked = !!this.initialValue;
    };

    this.toggleSelectionClick = function () {
      this.checked = !this.checked;
      this.toggleClick({ checked: this.checked });
    };

    this.onKeyDown = function (event) {
      if (event && accessibilityService.isValidKeyBoardEvent(event)) this.toggleSelectionClick();
    };
  }]
});
})();