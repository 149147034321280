(function(){
'use strict';

/**
 * @kind factory
 * @name scCloneDeep
 *
 * @description
 * Wrapper for _.cloneDeep with exceptions for custom types like scImg, which
 * must retain their prototype chain when cloned.
 */

scCloneDeepFactory.$inject = ["scImg", "scImgAssetsService"];
function scCloneDeepFactory(scImg, scImgAssetsService) {
  function scCloneDeep(obj) {
    return _.cloneDeepWith(obj, function (value, key) {
      if (key === 'mongo_queries_log' || key === 'queries_log') {
        return value;
      }

      // Replace __media object definitions from Mongo with scImg objects.
      if (scImg.isDefinition(value)) {
        return new scImg(value);
      } else if (_.isObject(value) && !_.isUndefined(value.cdn_url)) {
        var asset = scImgAssetsService.hydrate(value);
        return new scImg(asset);
      } else if (scImg.isInstance(value)) {
        // Copy scImg objects, maintaining correct prototype chain.
        return value.clone();
      }

      // More customizers here ...
      return undefined;
    });
  }

  return scCloneDeep;
}

angular.module('classy').factory('scCloneDeep', scCloneDeepFactory);
})();