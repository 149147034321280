(function(){
'use strict';

angular.module('classy').component('currencyField', {
  template: '\n      <form-label required="$ctrl.required" ng-if="$ctrl.label" _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n      <form-sub-label ng-if="$ctrl.subLabel" _spec="subLabel">\n        {{:: $ctrl.subLabel }}\n      </form-sub-label>\n      <div class="form__text-input__outer" data-ng-class="{ \'admin-body__lock-field\': $ctrl.lockField }">\n        <div ng-if="$ctrl.lockField"\n        data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n        data-sc-tooltip-position="center"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        fixed-tooltip="true"\n        data-sc-tooltip="\'This field is locked at the template level.\'"\n        class="admin-body__tooltip-element"\n      ></div>\n      <input class="form__text-input--dark"\n      aria-label="{{ $ctrl.ariaLabel ? $ctrl.ariaLabel:\'Enter goal amount\'}}"\n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      ng-required="$ctrl.required"\n      sc-currency\n      sc-validity-reporter\n      sc-validity-classes\n      data-sc-focus="$ctrl.focusWhen"\n      symbol="{{$ctrl.symbol}}"\n      round-currency="$ctrl.roundCurrency"\n      ng-disabled="$ctrl.disableFlag"\n      allow-null="$ctrl.allowNull"\n      max="$ctrl.max"\n      min="$ctrl.min"\n      type="text"\n      name="{{:: $ctrl.name }}"\n      placeholder="{{:: $ctrl.placeholder }}"\n      ng-focus="$ctrl.onFocus = true"\n      ng-blur="$ctrl.onFocus = false"\n      qa-id="{{:: $ctrl.scQaId.singleLineTextInput }}"\n      _spec="input" />\n      </div>\n      <div aria-live="polite">\n        <form-error\n        name="$ctrl.name"\n        messages="$ctrl.errorMessages"\n        _spec="errors"></form-error>\n      </div>\n    ',
  bindings: {
    model: '=',
    disableFlag: '=?',
    name: '<',
    currencyCode: '<?',
    label: '<',
    subLabel: '<?',
    placeholder: '<',
    errorMessages: '<?',
    getterSetter: '<?',
    roundCurrency: '<?',
    max: '<?',
    min: '<?',
    allowNull: '<?',
    required: '<?',
    selectOn: '<?',
    onFocus: '=?',
    focusWhen: '=?',
    ariaLabel: '@?',
    lockField: '<?'
  },
  controller: ["$element", "$scope", "scCurrencyService", "scQaId", "scCampaignsTemplateService", "$stateParams", "$attrs", "$filter", function CurrencyFieldCtrl($element, $scope, scCurrencyService, scQaId, scCampaignsTemplateService, $stateParams, $attrs, $filter) {
    var _this = this;

    function DEFAULTS() {
      return {
        disabled: false,
        errorMessages: {
          min: 'Please enter a number equal to or greater than ' + $filter('number')(this.min) + '.',
          max: 'Please enter a number equal to or less than ' + $filter('number')(this.max) + '.',
          required: 'Please enter an amount.',
          parse: 'Please enter a valid currency amount.'
        },
        getterSetter: false,
        required: false,
        roundCurrency: true,
        allowNull: false
      };
    }

    this.scQaId = scQaId;

    this.$onInit = function () {
      if (typeof _this.lockField === 'undefined' && SC.campaign && SC.campaign.campaignTemplateData) {
        _this.lockField = false;
        if ($stateParams.step === 'fundraiser' && $attrs.model === 'campaign.current.default_page_goal') {
          _this.lockField = scCampaignsTemplateService.getCampaignLock('default_page_goal');
        } else if ($stateParams.step === 'team' && $attrs.model === 'campaign.current.default_team_goal') {
          _this.lockField = scCampaignsTemplateService.getCampaignLock('default_team_goal');
        }

        // Setup to detect and set locked fields
        var isCampaignRawGoalLocked = _.get(SC.campaign.campaignTemplateData, 'components.campaign.component_items[0].item_attributes.raw_goal.locked', false);
        if (isCampaignRawGoalLocked && $attrs.model === 'campaign.current.goal') {
          _this.disableFlag = true;
          _this.lockField = true;
        }
        if (isCampaignRawGoalLocked && $attrs.model === 'campaign.current.raw_goal') {
          _this.disableFlag = true;
          _this.lockField = true;
        }
      }
      _.defaultsDeep(_this, DEFAULTS.call(_this));
      _this.setupSelectListener();

      if (_this.currencyCode) {
        _this.symbol = scCurrencyService.getCurrencySymbol(_this.currencyCode);
      }
    };

    this.setupSelectListener = function () {
      if (_this.selectOn) {
        $scope.$on(_this.selectOn, function () {
          $element.find('input').focus();
          $element.find('input').select();
        });
      }
    };
  }]
});
})();