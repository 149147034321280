(function(){
'use strict';

angular.module('classy').component('panelSectionBanner', {
  template: '\n      <header data-ng-class="::$ctrl.className">\n        <h2 ng-if= "!$ctrl.pageHeadline" class="panel__section-headline" data-ng-transclude="headline"></h2>\n        <h1 ng-if= "$ctrl.pageHeadline" class="panel__section-headline" data-ng-transclude="headline"></h1>\n        <p class="panel__section-subheading" data-ng-transclude="subheader"></p>\n      </header>\n    ',
  bindings: {
    theme: '<?',
    pageHeadline: '<?'
  },
  transclude: {
    headline: 'cptHeadline',
    subheader: '?cptSubheader'
  },
  controller: function controller() {
    this.$onInit = function () {
      var className = 'panel__section-banner';
      if (this.theme) className += '--' + this.theme;
      this.className = className;
    };
  }
});
})();