(function(){
'use strict';

frsDonationFooterCtrl.$inject = ["$scope", "$window", "scCampaignsService", "scOrganizationsService", "cpConstants"];
function frsDonationFooterCtrl($scope, $window, scCampaignsService, scOrganizationsService, cpConstants) {
  var PAYMENT_METHODS = cpConstants.PAYMENT_METHODS;

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    creditee: function creditee() {
      return $scope.META.creditee;
    },
    achTerms: function achTerms() {
      return $scope.API.getPaymentMethod() === PAYMENT_METHODS.ACH;
    },
    feeOnTop: function feeOnTop() {
      return $scope.API.showFeeOnTopAndTerms();
    },
    terms: function terms() {
      return $scope.API.showFeeOnTopAndTerms();
    }
  };

  $scope.META.showTaxComplianceLanguage = $scope.META.taxComplianceLanguage && $scope.META.taxComplianceLanguage !== '' && !scCampaignsService.active.multiCurrencyDisabled();

  var achPaymentMethod = $window.SC.processors ? $window.SC.processors.ACH : false;
  $scope.META.stripeACHEnabled = achPaymentMethod && achPaymentMethod.name === 'STRIPE';

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    logoSrc: function logoSrc() {
      if ($scope.META.creditee.logo) {
        return $scope.META.creditee.logo.sizes.full;
      } else if (_.isUndefined($scope.META.creditee.team_lead_id)) {
        return '/static/global/images/individual_default_image.png';
      }
      return '/static/global/images/team_default_image.png';
    },
    terms: function terms() {
      var orgData = scOrganizationsService.active.current;

      var orgPrivacyPolicyUrl = orgData.privacy_policy_url;
      var orgUrl = orgData.url;

      if (orgPrivacyPolicyUrl) {
        return 'By donating, you agree to our <a href=' + orgPrivacyPolicyUrl + ' target="_blank">Privacy Policy</a> and\n          <a href="https://www.classy.org/terms" target="_blank">Classy&apos;s Terms</a> and\n          <a href="https://www.classy.org/terms/privacy/" target="_blank">Privacy Policy</a>. Classy facilitates your\n          donations.';
      } else if (orgUrl) {
        return 'By donating, you agree to our <a href=' + orgUrl + ' target="_blank">Privacy Policy</a> and\n          <a href="https://www.classy.org/terms" target="_blank">Classy&apos;s Terms</a> and\n          <a href="https://www.classy.org/terms/privacy/" target="_blank">Privacy Policy</a>. Classy facilitates your\n          donations.';
      } else {
        return 'By donating, you agree to <a href="https://www.classy.org/terms" target="_blank">Classy&apos;s Terms</a> and\n          <a href="https://www.classy.org/terms/privacy/" target="_blank">Privacy Policy</a>. Classy facilitates your\n          donations.';
      }
    }
  };
}

angular.module('classy').controller('frsDonationFooterCtrl', frsDonationFooterCtrl);
})();