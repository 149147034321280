(function(){
'use strict';

angular.module('classy').component('modalFrame', {
  template: '<div class="{{:: $ctrl.className }}" _spec="modalFrame" data-ng-transclude></div>',
  transclude: true,
  bindings: {
    theme: '<?'
  },
  controller: function controller() {
    var _this = this;

    this.$onInit = function () {
      _this.className = 'modal__frame';
      if (_this.theme) {
        _this.className += '--' + _this.theme;
      }
    };
  }
});
})();