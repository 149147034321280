(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scRegistrationModel
 *
 */

scRecurringDonationPlanModel.$inject = ["scObjectModel", "scCloneDeep"];
function scRecurringDonationPlanModel(scObjectModel, scCloneDeep) {
  function RecurringDonationPlanModel(data, demo) {
    scObjectModel.call(this);

    this.isNew = !demo && !data;
    this.isDemo = demo || !data;

    this.current = scCloneDeep(data || {});
    this.defaults = scCloneDeep({});
    this.isExample = false;
    this.saved = scCloneDeep(data || {});
    this.type = 'recurring-donation-plans';
  }

  var model = RecurringDonationPlanModel;

  // Constructor
  model.prototype = _.create(scObjectModel.prototype, {
    constructor: RecurringDonationPlanModel
  });

  // Methods
  model.prototype.create = create;

  return RecurringDonationPlanModel;

  /* ---------------------------------------------------------------- *
   * Implementation
   * ---------------------------------------------------------------- */

  function create() {}
  // TODO


  // function getTransactions() {
  //   return $http.get('/frs-api/recurring-donation-plans/' + this.current.id + '/transactions');
  // }
}

angular.module('classy').factory('scRecurringDonationPlanModel', scRecurringDonationPlanModel);
})();