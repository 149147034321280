(function(){
'use strict';

angular.module('classy').constant('scProgressCircleBlockDefaults', {
  title: 'Progress Circle',
  type: 'progress-circle',
  disable: false,
  headline: "We're fundraising for the cause to make a difference!",
  subheadline: 'You can help fundraise for the cause once you register. Or just make a donation.',
  hideHeadline: false,
  hideSubheadline: false,
  headlineColor: 'rgb(26,26,26)',
  progressBarColor: null,
  progressBarBackgroundColor: 'rgba(0, 0, 0, 0.08)',
  hideGoal: false,
  displayProgress: 'dollars',
  displayRegisterButton: true,
  displayDonateButton: true,

  background: {
    color: 'rgba(248, 248, 248, 1)', // light gray
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  showButtons: true,

  registerButtonStyle: {
    preset: 'rounded',
    'border-radius': '4px',
    color: null
  },

  registerButtonColor: null,

  registerButtonText: 'Register',

  donateButtonStyle: {
    preset: 'rounded',
    'border-radius': '4px',
    color: null
  },

  donateButtonColor: null,

  donateButtonText: 'Donate',

  mobileTemplates: false,
  remove: false
});
})();