(function(){
'use strict';

angular.module('classy').component('summaryAmount', {
  template: '<p class="donation-summary-amount">\n                <span class="sr-only">your currently selected donation amount is </span>\n                <span class="donation-summary-amount-label" aria-hidden="true" ng-if="!$ctrl.preventRecurringFrequency">{{$ctrl.frequencyText | capitalize}} donation</span>\n                <span class="donation-summary-amount-label" aria-hidden="true" ng-if="$ctrl.preventRecurringFrequency">Amount:</span>\n                <span class="donation-summary-amount-amount" data-ng-class="{ processing: $ctrl.feeprocessing }">\n\n                    {{ ($ctrl.amount || 0) | scFormatCurrency:$ctrl.currency:2 }}<span ng-if="$ctrl.preventRecurringFrequency">{{ $ctrl.frequencyText }}</span>\n\n                    <span class="donation-summary-amount-currency">{{$ctrl.currency}}</span>\n\n                    <span class="donation-summary-amount-processing">\n                    <span class="donation-summary-amount-processing-spinner"></span>\n                    </span>\n\n                </span>\n                <span class="donation-summary-amount-date-text" ng-if="!$ctrl.preventRecurringFrequency && $ctrl.recurUntil">Ending </span>\n                <span class="donation-summary-amount-date" ng-if="!$ctrl.preventRecurringFrequency">{{$ctrl.recurUntil | date:\'MM/dd/yyyy\'}}</span>\n\n                <span class="donation-summary-amount-charged-amount" ng-if="$ctrl.taxCompliance">\n                    You will be charged {{ $ctrl.estimatedCharge.amount | scFormatCurrency:$ctrl.estimatedCharge.currency:2:\'code\' }}{{ $ctrl.frequencyText\n                    }}\n                </span>\n            </p>',
  bindings: {
    amount: '<',
    feeprocessing: '<',
    estimatedcharge: '<',
    recurUntil: '<',
    frequency: '<',
    currency: '<',
    taxcompliance: '<'
  },
  controller: ["scOrganizationsService", function controller(scOrganizationsService) {
    var _this = this;

    this.preventRecurringFrequency = scOrganizationsService.active.preventRecurringFrequencies();

    this.$onChanges = function () {
      if (_this.preventRecurringFrequency) {
        _this.frequencyText = _this.frequency === 'recurring' ? '/month' : '';
      } else {
        _this.frequencyText = _this.frequency === 'semi-annually' ? 'semi-annual' : _this.frequency;
      }
    };
  }]
});
})();