(function(){
'use strict';

scDateTime.$inject = ["mySqlDateTime"];
function scDateTime(mySqlDateTime) {
  return {
    scope: {
      scDateTime: '=',
      model: '=',
      useMoment: '<?'
    },
    link: function link(scope) {
      var firstSet = false;
      scope.$watch('model', function (newVal) {
        if (typeof newVal !== 'undefined' && newVal !== null && firstSet == false) {
          var newDate = void 0;
          if (!scope.useMoment) {
            // if we don't useMoment, go with the original behavior
            newDate = mySqlDateTime.toJs(newVal);
          } else {
            // otherwise we're gonna wanna use moment and mutate it into a js Date
            newDate = moment(newVal).toDate();
          }

          scope.scDateTime = {
            date: newDate,
            time: newDate
          };

          firstSet = true;
        }
      });

      scope.$watch('scDateTime', function (newVal) {
        if (newVal && newVal.date && newVal.time) {
          if (scope.useMoment) {
            // if we use moment, just format the new date into context-less dating
            // like 2018-02-28 02:00:00
            scope.model = moment(newVal.date).format('YYYY-MM-DD HH:mm:ss');
          } else {
            // if not, then just go with the default behavior of contextualizing it
            // into local time
            var shared = new Date();
            var hour = newVal.time.getHours(),
                minute = newVal.time.getMinutes(),
                seconds = newVal.time.getSeconds(),
                year = newVal.date.getFullYear(),
                month = newVal.date.getMonth(),
                day = newVal.date.getDate();

            shared.setFullYear(year);
            shared.setMonth(month);
            shared.setDate(day);
            shared.setHours(hour);
            shared.setMinutes(minute);
            shared.setSeconds(seconds);
            scope.model = mySqlDateTime.toMysql(shared);
          }
        } else if (newVal && newVal.date) {
          if (scope.useMoment) {
            // if we use moment, just format the new date into context-less dating
            // like 2018-02-28 02:00:00
            scope.model = moment(newVal.date).format('YYYY-MM-DD HH:mm:ss');
          } else {
            // else just do the default behavior with localized time
            var single = new Date();
            var _hour = newVal.date.getHours(),
                _minute = newVal.date.getMinutes(),
                _seconds = newVal.date.getSeconds(),
                _year = newVal.date.getFullYear(),
                _month = newVal.date.getMonth(),
                _day = newVal.date.getDate();

            single.setFullYear(_year);
            single.setMonth(_month);
            single.setDate(_day);
            single.setHours(_hour);
            single.setMinutes(_minute);
            single.setSeconds(_seconds);

            scope.model = mySqlDateTime.toMysql(single);
          }
        }
      }, true);
    }
  };
}

angular.module('classy').directive('scDateTime', scDateTime);
})();