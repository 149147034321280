(function(){
'use strict';

function scValidityMessageFactory() {
  return {
    priority: 1,
    bindToController: {
      alias: '<scValidityMessage',
      messages: '<'
    },
    controllerAs: 'scValidityMessageCtrl',
    controller: ["$scope", "$element", function controller($scope, $element) {
      this.$postLink = function () {
        var _this = this;

        // eslint-disable-next-line no-unused-vars
        $scope.$on('scValidity.update', function (event, alias, data) {
          // Limit to events for the targeted field.
          if (alias !== _this.alias) {
            return;
          }

          // If field is valid or pending, hide message.
          if (data.uiValid || !_.isEmpty(data.uiPending)) {
            $element.hide();
            return;
          }

          // Otherwise, find and display an appropriate error message, if one
          // has been provided that matches the current errors. At the moment
          // there is no sorting or weighting of messages; the last one that
          // matches wins.

          var activeMessage = '';

          _.forEach(_this.messages, function (message, key) {
            if (data.uiError[key]) {
              activeMessage = message;
            }
          });

          if (activeMessage) {
            $element.text(activeMessage).show();
          } else {
            $element.text('').hide();
          }
        });
      };
    }]
  };
}

angular.module('classy').directive('scValidityMessage', scValidityMessageFactory);
})();