(function(){
'use strict';

createAdwords.$inject = ["$q", "$window", "ANALYTICS_EVENTS", "scAnalyticsServiceSettings", "scUserAgent", "scAnalyticsUtils"];
function createAdwords($q, $window, ANALYTICS_EVENTS, scAnalyticsServiceSettings, scUserAgent, scAnalyticsUtils) {
  var adwordsService = { name: 'google_adwords' };
  var debugLog = scAnalyticsUtils.debugLog;
  var REGISTRATION_CREATE_BEGIN = ANALYTICS_EVENTS.REGISTRATION_CREATE_BEGIN,
      REGISTRATION_CREATE_COMPLETE = ANALYTICS_EVENTS.REGISTRATION_CREATE_COMPLETE;

  /* Interface Methods
  ========================================================= */

  adwordsService.recordPageView = function () {};
  adwordsService.registerSuperProperties = function () {};
  adwordsService.identifyMember = function () {};
  adwordsService.eventBeacon = function () {};

  adwordsService.track = function (event, options) {
    if ($window.google_trackConversion) {
      $window.google_trackConversion(parseAdwordsEvent(event, options));
    }
  };

  adwordsService.loaded = function () {
    if (SC.hasAdwords) {
      return $q(function (resolve) {
        SC.onAdwordsLoaded(function () {
          resolve();
        });
      });
    }
    return $q.reject();
  };

  return Object.assign(adwordsService, {
    _parseAdwordsEvent: parseAdwordsEvent
  });

  /* Private Methods
  ========================================================= */
  function parseAdwordsEvent(event, options) {
    if (scUserAgent.isBot()) return undefined;

    var campaignAdwords = scAnalyticsServiceSettings.getByName('campaign', 'google_adwords');

    // look for campaign adwords situation
    var conversionLabel = _.get(campaignAdwords, ['tracked_events', event], undefined);
    var conversionId = _.get(campaignAdwords, ['parameters', 'conversion_id'], false);

    var conversionValue = parseFloat(_.get(options, 'amount', 0));
    var orderId = _.get(options, 'id', null);
    var numItems = _.get(options, 'items.length', 0) + (_.get(options, 'donation_amount', null) ? 1 : 0);

    // if undefined look for org adwords situation
    if (_.isUndefined(conversionLabel)) {
      var orgAdwords = scAnalyticsServiceSettings.getByName('organization', 'google_adwords');
      conversionLabel = _.get(orgAdwords, ['tracked_events', event], undefined);
      conversionId = _.get(orgAdwords, ['parameters', 'conversion_id'], false);
    }

    if (!conversionId || !conversionLabel) {
      return undefined;
    }

    debugLog('adwords', event + ' action called with id ' + conversionId + ' and label ' + conversionLabel + ', value of ' + conversionValue);

    var conversionData = {
      google_conversion_id: conversionId,
      google_conversion_language: 'en',
      google_conversion_format: '3',
      google_conversion_color: 'ffffff',
      google_conversion_label: conversionLabel,
      google_conversion_value: conversionValue
    };

    if (orderId) {
      conversionData.google_conversion_order_id = orderId;
    }

    if ([REGISTRATION_CREATE_BEGIN, REGISTRATION_CREATE_COMPLETE].includes(event)) {
      conversionData.google_custom_params = { items: numItems };
    }

    return conversionData;
  }
}

angular.module('classy').factory('adwordsService', createAdwords);
})();