(function(){
'use strict';

angular.module('classy').constant('scConstants', {
  // Router Params
  urlParams: {
    utmCodes: 'utm_source' + '&utm_medium' + '&utm_term' + '&utm_content' + '&utm_campaign' + '&c_src' + '&c_src2' + '&_ga' +
    /**
     * TODO: These were specifically added for TSA in FRS-8860.
     * Please look for alternatives (wildcard params) under FRS-8562.
     */
    '&d_bu' + '&d_site' + '&d_adgroup' + '&d_creative' + '&d_tactic' + '&d_placement' + '&d_vert' + '&d_campaign' + '&gclid' + '&eid' + '&emid' + '&cid' + '&cmid' + '&pid' + '&pmid' + '&s_kwcid' + '&lightbox' + '&cause' + '&id_type' + '&u_id' + '&sfmc_id' +
    /**
     * The below params are required by the password reset flow.
     * With the values of that parametes, the auth service displays a modal with the password reset form and handle the flow actions.
     */
    '&sso_method' + '&source' + '&member_id' + '&passwod_reset_code' + '&campaign_id' + '&client_id' + '&org_id' + '&response_type' + '&scope'
  }
});
})();