(function(){
'use strict';

scDonationFeedItem.$inject = ["$q", "$log", "$state", "$http", "$rootScope", "scFeedItemText", "scFundraisingTeamsService", "scFundraisingPagesService", "scMembersService", "scFeedItemAmount"];
function scDonationFeedItem($q, $log, $state, $http, $rootScope, scFeedItemText, scFundraisingTeamsService, scFundraisingPagesService, scMembersService, scFeedItemAmount) {
  return {
    scope: {
      donationFeedItem: '=scDonationFeedItem',
      theme: '=',
      block: '=',
      me: '='
    },
    templateUrl: 'global/directives/scDonationFeedItems/template',
    link: function DonationFeedItemController(scope) {
      var model = _.get(scFundraisingPagesService.active || scFundraisingTeamsService.active, 'current', null);

      // Scope Properties
      scope.commentForm = { body: '' };
      scope.feedItemOpen = false;

      // Scope Methods
      scope.isTeam = isTeam();
      scope.closeFeedItem = closeFeedItem;
      scope.getActionText = scFeedItemText;
      scope.goToActivity = goToActivity;
      scope.markThanked = markThanked;
      scope.openFeedItem = openFeedItem;
      scope.submitComment = submitComment;
      scope.me = scMembersService.active.current;
      scope.getFeedItemAmount = scFeedItemAmount;
      scope.getMemberFirstInitial = scMembersService.getMemberFirstInitial;

      function isTeam() {
        if ($state.current.name.indexOf('frs.team') > -1) {
          return true;
        }
        return false;
      }

      function markThanked() {
        var ackPromise = createAcknowledgement();

        ackPromise.then(function () {
          scope.feedItemOpen = false;

          var fundraiserData = {
            first_name: scope.donationFeedItem.member_name
          };

          $rootScope.$broadcast('sc-donor-thanked', {
            options: scope.donationFeedItem,
            notification: {
              msg: 'Donor marked as thanked!',
              link_text: 'Undo',
              fundraisers: [fundraiserData]
            }
          });
        });
      }

      function goToActivity() {
        if ($state.current.name.indexOf('fundraiser') > -1) {
          $state.go('frs.fundraiser.index');
        } else if ($state.current.name.indexOf('team') > -1) {
          $state.go('frs.team.index');
        }
      }

      scope.goToPage = function () {
        if (scFundraisingPagesService.active) {
          $state.go('frs.fundraiser.index');
        } else if (scFundraisingTeamsService.active) {
          $state.go('frs.team.index');
        } else {
          $log.error('Error loading fundraiser info.');
        }
      };

      /**
       * Action of submitting comment,
       * this will generate a comment on the feed item
       * as well as create an acknowledgement for the donation.
       */
      function submitComment() {
        var commentPromise = createComment();
        var ackPromise = createAcknowledgement();

        $q.all([commentPromise, ackPromise]).then(function () {
          scope.feedItemOpen = false;

          var fundraiserData = {
            first_name: scope.donationFeedItem.member_name
          };

          // TODO: update comment so clicking 'View Message' will show comment
          $rootScope.$broadcast('sc-donor-thanked', {
            options: scope.donationFeedItem,
            notification: {
              msg: 'Donor thanked!',
              link: scope.goToPage,
              fundraisers: [fundraiserData]
            }
          });
        });
      }

      function createComment() {
        return $http.post('/frs-api/feed-items/' + scope.donationFeedItem.id + '/comments', {
          body: scope.commentForm.body
        }).then(function (response) {
          scope.donationFeedItem.fundraiserComment = response.data;

          return response.data;
        }).catch(function () {
          banner('Sorry, your comment could not be posted. Please try again.');
        });
      }

      function createAcknowledgement() {
        return $http.post('/frs-api/transaction/' + scope.donationFeedItem.linkable_id + '/acknowledgements', {
          fundraising_entity_id: model.id,
          fundraising_entity_type: scope.isTeam ? 'fundraising_team' : 'fundraising_page'
        }).then(function (response) {
          scope.donationFeedItem.acknowledgement = response.data;

          return response.data;
        }).catch(function () {
          banner('Sorry, your acknowledgement could not be posted. Please try again.');
        });
      }

      function closeFeedItem() {
        scope.feedItemOpen = false;
      }

      function openFeedItem() {
        scope.feedItemOpen = true;
        scope.focusCommentInput = true;
        scope.commentForm.body = '';
      }

      function banner(str, error) {
        $rootScope.SC.status.banner = {
          type: error || 'error',
          msg: str
        };
      }
    }
  };
}

angular.module('classy').directive('scDonationFeedItem', scDonationFeedItem);
})();