(function(){
'use strict';

scPagingButtonsDirective.$inject = ["scPagingButtonsAPI"];
function scPagingButtonsDirective(scPagingButtonsAPI) {
  return {
    scope: {
      api: '=scPagingButtons'
    },

    // eslint-disable-next-line no-unused-vars
    templateUrl: function templateUrl(tElemnt, tAttrs) {
      return tAttrs.templateUrl || 'global/directives/scPagingButtons/template';
    },
    controller: ["$scope", function controller($scope) {
      /* -------------------------------------------------------------------- *
       * API; shared with whatever service is fetching data
       * -------------------------------------------------------------------- */

      $scope.api = $scope.api || new scPagingButtonsAPI();

      /* -------------------------------------------------------------------- *
       * UI state
       * -------------------------------------------------------------------- */

      $scope.state = {
        loading: false
      };

      /* -------------------------------------------------------------------- *
       * Constructed properties
       * -------------------------------------------------------------------- */

      $scope.get = {
        summary: function summary() {
          // let currentPage = $scope.api.currentPage;
          // let totalPages = $scope.api.totalPages;
          // let perPage = $scope.api.perPage;
          // let totalResults = $scope.api.totalResults;
          // if ($scope.api.perPage) {
          //   let firstResult = (perPage * (currentPage - 1)) + 1;
          //   let lastResult = Math.min(perPage * currentPage, totalResults);
          //   return firstResult + ' - ' + lastResult + ' of ' + totalResults;
          // } else {
          return 'Page ' + $scope.api.currentPage + ' of ' + $scope.api.totalPages;
          // }
        },
        disabled: function disabled(page) {
          if ($scope.state.loading) {
            return true;
          }
          if (page < 1) {
            return true;
          }
          if (page > $scope.api.totalPages) {
            return true;
          }
          if (page === $scope.api.currentPage) {
            return true;
          }
          return false;
        },
        fivePageRange: function fivePageRange() {
          // 5 or less - return them all
          if ($scope.api.pageRange.length <= 5) {
            return $scope.api.pageRange;
          } else if ($scope.api.currentPage < 3) {
            // First or second page; return first 5
            return $scope.api.pageRange.slice(0, 5);
          } else if ($scope.api.totalPages - $scope.api.currentPage < 2) {
            // Last or second to last page; return last 5
            return $scope.api.pageRange.slice(-5);
          }

          // Return current page and 2 to either side (index is off by 1)

          var start = $scope.api.currentPage - 3;
          var end = $scope.api.currentPage + 2;
          return $scope.api.pageRange.slice(start, end);
        },
        ellipsis: function ellipsis() {
          if ($scope.api.pageRange.length <= 6) {
            return false;
          } else if ($scope.api.totalPages - $scope.api.currentPage <= 3) {
            return false;
          }
          return true;
        },
        tail: function tail() {
          if ($scope.api.pageRange.length <= 5) {
            return false;
          } else if ($scope.api.totalPages - $scope.api.currentPage <= 2) {
            return false;
          }
          return true;
        },
        itemsSummary: function itemsSummary() {
          var totalResults = $scope.api.totalResults;
          if (totalResults === 0) {
            return '0 of 0';
          }
          var perPage = $scope.api.perPage;
          var start = ($scope.api.currentPage - 1) * perPage + 1;
          var delta = perPage;

          if (totalResults < perPage) {
            delta = totalResults % perPage;
          }

          if (delta > perPage) {
            delta = totalResults % delta;
          }

          var end = start + delta - 1;

          if (end > totalResults) {
            end = totalResults % end;
          }
          return start + ' - ' + end + ' of ' + totalResults;
        }
      };

      /* -------------------------------------------------------------------- *
       * ViewModel API
       * -------------------------------------------------------------------- */

      $scope.prev = function () {
        var page = $scope.api.currentPage - 1;
        $scope.api.pageSelected(page);
      };

      $scope.next = function () {
        var page = $scope.api.currentPage + 1;
        $scope.api.pageSelected(page);
      };

      $scope.select = function (page) {
        $scope.api.pageSelected(page);
      };

      $scope.first = function () {
        $scope.api.pageSelected(1);
      };

      $scope.last = function () {
        $scope.api.pageSelected($scope.api.totalPages);
      };

      /* -------------------------------------------------------------------- *
       * Init
       * -------------------------------------------------------------------- */

      $scope.api.onFetchStart(function () {
        $scope.state.loading = true;
      });

      $scope.api.onFetchComplete(function () {
        $scope.state.loading = false;
      });
    }]
  };
}

angular.module('classy').directive('scPagingButtons', scPagingButtonsDirective);
})();