(function(){
'use strict';

createBugsnagClient.$inject = ["$window", "$log"];
function createBugsnagClient($window, $log) {
  if (!$window.bugsnagClient) return createDevClient();

  return $window.bugsnagClient;

  function createDevClient() {
    return {
      notify: function notify(error, options) {
        $log.info('DEV INFO: the following error to be reported to BugSnag in prod or prod-like environments:', error, options);
      }
    };
  }
}

angular.module('classy').factory('bugsnagClient', createBugsnagClient);
})();