(function(){
'use strict';

whitelistedPropertyService.$inject = ["whitelistedProperties"];
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * @name whitelistedPropertyService
 * @kind service
 *
 * @description
 * Gets whitelisted properties that can be added to the Google Tag Manager dataLayer
 */

function whitelistedPropertyService(whitelistedProperties) {
  var DONATION_WHITELIST = whitelistedProperties.DONATION_WHITELIST,
      ZIPCODE_BLACKLIST = whitelistedProperties.ZIPCODE_BLACKLIST,
      CART_WHITELIST = whitelistedProperties.CART_WHITELIST,
      CART_ITEM_WHITELIST = whitelistedProperties.CART_ITEM_WHITELIST,
      CART_REGISTRANT_WHITELIST = whitelistedProperties.CART_REGISTRANT_WHITELIST;

  // The "public" function that should be getting called by other components/services etc

  this.getSanitizedData = function (type, data) {
    if (type === 'donation' || type === 'transaction') {
      // if the transaction has items, then it is a registration cart
      return data.items ? getCartData(data) : getDonationData(data);
    }

    var whitelist = whitelistedProperties[type.toUpperCase() + '_WHITELIST'];

    // if there is no whitelist found then we are treating it as if we do not
    // need to sanitize the data, so we just return the original object
    if (!whitelist) return data;

    var propTransformations = {
      goal: 'normalized_goal',
      currency_code: 'normalized_currency_code'
    };

    return sanitize(data, whitelist, propTransformations);
  };

  /* Private Methods
  ========================================================= */
  function getDonationData(transaction) {
    return Object.assign(sanitize(transaction, DONATION_WHITELIST), {
      type: 'donation',

      // Return 'monthly' for 'recurring' to avoid making a data migration
      // once we start to support other frequencies
      frequency: transaction.frequency.toLowerCase() === 'recurring' ? 'monthly' : transaction.frequency
    }, filterZipCode(transaction.billing_country, transaction.billing_postal_code));
  }

  function getCartData(transaction) {
    var cartData = Object.assign(sanitizeCart(transaction), {
      type: 'cart'
    });

    // Return 'monthly' for 'recurring' to avoid making a data migration
    // once we start to support other frequencies
    if (cartData.donation_frequency) {
      cartData.donation_frequency = cartData.donation_frequency.toLowerCase() === 'recurring' ? 'monthly' : cartData.donation_frequency;
    }

    return cartData;
  }

  /**
   * sanitizeCart
   *
   * loops through the cart items the their respective registrants and santizes
   * each object individuall. Creates a copy of the original cart object to prevent
   * mutation. Finally runs it through the normal sanitize funtion to remove any
   * unwanted top level cart properties
   *
   * @param cart {object} - the full cart object
   * @return {{} & any}
   */
  function sanitizeCart(cart) {
    var cartCopy = Object.assign({}, cart);
    if (cartCopy.items) {
      cartCopy.items = cartCopy.items.map(function (item) {
        if (item.registrants) {
          item.registrants = item.registrants.map(function (registrant) {
            return sanitize(registrant, CART_REGISTRANT_WHITELIST);
          });
        }
        return sanitize(item, CART_ITEM_WHITELIST);
      });
    }
    return sanitize(cartCopy, CART_WHITELIST);
  }

  /**
   * sanitize
   *
   * takes an object from an analytics event and filters out any keys that are
   * are not included in the passed whitelist. If any keys need to be changed
   * you can pass an optional transform object which will replace any keys with
   * their mapped values, e.g.  { 'currency': 'normalized_currency' }
   *
   * @param data {object} - the data being sanitized
   * @param whitelist {array} - the whitelist of keys allowed
   * @param transform {object} - object of key transformations
   */
  function sanitize(data, whitelist) {
    var transform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    return whitelist.reduce(function (sanitizedData, key) {
      return data[key] !== undefined ? Object.assign(sanitizedData, _defineProperty({}, transform[key] || key, data[key])) : sanitizedData;
    }, {});
  }

  /**
   * filterZipCode
   *
   * country code and a postal code and checks it against a blacklist
   * of zipcodes for nations with less that 20k individuals (where having a
   * person's zipcode is condidered identifiable information). If this zip
   * is not in the blacklist, it will strip the last three digits. Zip codes
   * are removed entirely for GB
   *
   * @param country {string} - the country code
   * @param postalCode {string} - the zip code in question
   * @return {*} - object with transformed, or removed zipcode
   */
  function filterZipCode(country, postalCode) {
    // GB is the country code for the UK we're sending; UK has stricter laws for
    // what data counts as PII for users
    if (country !== 'GB' && postalCode) {
      var sanitizedPostalCode = country === 'US' && ZIPCODE_BLACKLIST.includes(postalCode.substr(0, 3)) ? '000' : postalCode.substr(0, 3);
      return { billing_postal_code: sanitizedPostalCode };
    }
    return {};
  }

  // export private methods for testing purposes
  /* eslint-disable no-underscore-dangle */
  Object.assign(this, {
    _getDonationData: getDonationData,
    _getCartData: getCartData,
    _sanitize: sanitize,
    _filterZipCode: filterZipCode,
    _sanitizeCart: sanitizeCart
  });
  /* eslint-enable no-underscore-dangle */
}

angular.module('classy').service('whitelistedPropertyService', whitelistedPropertyService);
})();