(function(){
'use strict';

function mySqlDateTime() {
  return {
    toMysql: function toMysql(date) {
      if (date) {
        var mysqlDate = date.getUTCFullYear() + '-' + ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' + ('00' + date.getUTCDate()).slice(-2) + ' ' + ('00' + date.getUTCHours()).slice(-2) + ':' + ('00' + date.getUTCMinutes()).slice(-2) + ':' + ('00' + date.getUTCSeconds()).slice(-2);

        return mysqlDate;
      }

      return undefined;
    },
    toJs: function toJs(date) {
      if (date) {
        var utc = date.split('T').length > 1;
        if (utc) {
          var m = moment(date);
          return m.toDate();
        }
        var t = date.split(/[- :]/),
            jsDate = new Date();

        jsDate.setUTCFullYear(t[0]);
        jsDate.setUTCMonth(t[1] - 1);
        jsDate.setUTCDate(t[2]);
        // In case the date is something like 2017-10-03 this will prevent Invalid Date errors
        jsDate.setUTCHours(t[3] || 0);
        jsDate.setUTCMinutes(t[4] || 0);
        jsDate.setUTCSeconds(t[5] || 0);

        return jsDate;
      }

      return undefined;
    },
    convertUtcToTimezone: function convertUtcToTimezone(dateString, timezone) {
      var utc = dateString.split('T').length > 1;

      if (utc) {
        // will this really work if we are trying to hit a timezone not the local one?
        var m = moment(dateString);

        if (timezone) {
          var localTime = new Date(m);

          // offset of timezone from local time
          var selectedTzOffset = moment.tz.zone(timezone).utcOffset(localTime.getTime());
          var localOffset = localTime.getTimezoneOffset();
          var offset = -(selectedTzOffset - localOffset);

          return moment(dateString).add(offset, 'minutes').toDate();
        }

        return m.toDate();
      }

      var clientTime = moment(dateString);
      var newTime = moment(dateString);
      var tzOffset = moment.tz.zone(timezone || moment.tz.guess()).utcOffset(clientTime.format('x'));
      var difference = -tzOffset;
      var localish = newTime.add(difference, 'minutes');

      return localish.toDate();
    },
    convertClientTimetoTimezone: function convertClientTimetoTimezone(dateString, timezone) {
      var clientTime = new Date(dateString);
      var tz = timezone || moment.tz.guess();
      var utc = moment().utc(dateString).format();
      var localish = moment(utc).tz(tz);
      var ts = clientTime.getTime();
      var tzOffset = moment.tz.zone(tz).utcOffset(ts);
      var clientOffset = clientTime.getTimezoneOffset();
      var difference = clientOffset - tzOffset;

      // Add the client time offset from the timezone you're selecting
      localish = localish.add(difference, 'minutes');

      // Add the client time offset from UTC
      return moment(localish).add(clientOffset, 'minutes').toDate();
    },
    convertTimezoneToUtc: function convertTimezoneToUtc(dateString, timezone, dateFormat) {
      var tz = timezone || moment.tz.guess();
      var clientTime = new Date(dateString);
      var ts = clientTime.getTime();

      // offset of timezone from UTC
      var tzOffset = moment.tz.zone(tz).utcOffset(ts);

      // offset of client from timezone
      var clientOffset = clientTime.getTimezoneOffset();
      var difference = tzOffset - clientOffset;
      var utc = moment(ts).add(difference, 'minutes');

      if (dateFormat == 'YYYY-MM-DD HH:mm:ss') {
        // In simple date format, we need to add the client offset manually
        // since we don't have a timezone identifier (i.e. +0700)
        var simpleDateTime = moment(utc).add(clientOffset, 'minutes');
        return simpleDateTime.format(dateFormat);
      }

      return dateFormat ? utc.format(dateFormat) : utc.format();
    }
  };
}

angular.module('classy').factory('mySqlDateTime', mySqlDateTime);
})();