(function(){
'use strict';

angular.module('classy').component('cpPaymentMethodControl', {
  template: '\n      <div class="cp-payment-method">\n        <div\n          class="cp-payment-method__selected"\n          type="button"\n        >\n\n          <div class="cp-payment-method__selected-option">\n\n            <div class="cp-payment-method__selected-figure">\n              <i class="cp-payment-method__selected-icon {{ $ctrl.getConfigAttr(\'icon\') }}"></i>\n            </div>\n\n            <div class="cp-payment-method__selected-content">\n\n              <div>\n                <span class="cp-payment-method__selected-title">\n                  {{ $ctrl.getConfigAttr(\'title\') }}\n                </span>\n                \n                <span class="cp-payment-method__selected-info">\n                  {{ $ctrl.getConfigAttr(\'selectedText\') }}\n                </span>\n              </div>\n\n            </div>\n          </div>\n        </div>\n      </div>\n    ',
  bindings: {
    freeTransact: '<?',
    paymentMethod: '<'
  },
  controller: function cpPaymentMethodControlCtrl() {
    this.config = {
      CC: {
        icon: 'ci ci-credit-card',
        title: 'Credit card',
        selectedText: "You're paying with a credit card",
        optionText: 'Pay with your card'
      },
      ACH: {
        icon: 'ci ci-bank',
        title: 'Link a bank',
        selectedText: "You're paying via eCheck",
        optionText: 'Pay via eCheck'
      },
      PayPal: {
        icon: 'ci ci-paypal',
        title: 'PayPal',
        selectedText: "You're paying via PayPal",
        optionText: 'Sign in to your account'
      },
      free: {
        icon: 'ci ci-support_real-people',
        title: 'Free transaction',
        selectedText: 'This transaction is 100% free of charge',
        optionText: 'Free transaction'
      }
    };

    this.getConfigAttr = function (attr) {
      if (this.freeTransact) return this.config.free[attr];
      return _.get(this.config, [this.paymentMethod, attr], this.config.CC[attr]);
    };
  }
});
})();