(function(){
'use strict';

angular.module('classy').controller('EmbeddedGivingDonationCtrl', ["$scope", "$timeout", function ($scope, $timeout) {
  $timeout(function () {
    $scope.FORM.$setPristine();
  });

  // verify this doesnt brick preview..
  // hmm kinda wack.. not sure if we wanna do here because people tab + enter across the amount btns
  $scope.processKeydownEventDonationRoot = function (event) {
    var keyCode = event && event.keyCode;
    if (keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();

      if ($scope.getDonationAmountRaw() > 1) {
        if ($scope.shouldShowRecurringNudge()) {
          $scope.goDonationRecurringNudge();
        } else {
          $scope.goDonationOptions();
        }
      }
    }
  };
}]).controller('EmbeddedGivingDonationOptionsCtrl', ["$scope", function ($scope) {
  $scope.processKeydownEventDonationOptions = function (event) {
    var keyCode = event && event.keyCode;
    if (keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();

      var focusedElement = document.activeElement;
      if (focusedElement) {
        if (focusedElement.id === 'eg-btn-donation-options') {
          // Only go to the next step if the "Continue" button is focused
          $scope.goDonationContact();
        } else {
          // Simulate a click on the child element with data-ng-click
          var clickableElement = focusedElement.querySelector('[data-ng-click]');
          if (clickableElement) {
            clickableElement.click();
          } else {
            focusedElement.click();
          }
        }
      }
    }
  };
}]).controller('EmbeddedGivingDonationRecurringNudgeCtrl', ["$scope", function ($scope) {
  $scope.$on('recurring-nudge-event', function (_event, data) {
    switch (data) {
      case 'accept':
        // set model?
        $scope.goDonationOptions();
        break;
      case 'reject':
        $scope.goDonationOptions();
        break;
      case 'back':
        $scope.goDonationRoot();
        break;
      default:
    }
  });
}]).controller('EmbeddedGivingDonationContactCtrl', ["$scope", function ($scope) {
  // const btnDonationContact = $('#eg-btn-donation-contact');
  $scope.isPhoneNumberRequired = __getIsPhoneNumberRequired();
  $scope.isAnonymousDonationEnabled = $scope.campaign.current && !$scope.campaign.current.hide_anonymous_donations;

  $scope.showCustomQuestions = function () {
    var questions = $scope.campaign.current && $scope.campaign.current.questions;

    if (!_.isEmpty(questions)) {
      var hasCustomQuestions = !!questions.custom.length;
      var hasOptionalQuestions = !!questions.optional.filter(function (optionalQuestion) {
        return optionalQuestion.tag !== 'homephone';
      }).length;

      return hasCustomQuestions || hasOptionalQuestions;
    }

    return false;
  };

  /*
   * Checks the Contact view's input fields and verifies that the required values are present AND
   * valid. $scope.MODEL and $scope.META house these properties.
   *
   * This consists of THREE REQUIRED FIELDS:
   * 1) member_first_name (i.e. $scope.MODEL.member_first_name)
   * 2) member_last_name (i.e. $scope.MODEL.member_last_name)
   * 3) member_email_address (i.e. $scope.META.member_email_address)
   *
   * NOTE: could've short-handed this code into 1 line but opted not too for readability
   */
  $scope.isValidContact = function () {
    var form = $scope.FORM;

    if (!form) {
      return false;
    }

    if (!form.member_first_name || !form.member_first_name.$valid) {
      return false;
    }
    if (!form.member_last_name || !form.member_last_name.$valid) {
      return false;
    }

    if (!form.member_email_address || !form.member_email_address.$valid) {
      return false;
    }

    if ($scope.isPhoneNumberRequired && (!form.member_phone || !form.member_phone.$valid)) {
      return false;
    }

    return true;
  };

  $scope.isValidOptionalQuestions = function () {
    var questions = $scope.campaign.current.questions;
    // if no questions, than valid
    if (!questions || !questions.optional) {
      return true;
    }

    var isValid = questions.optional.filter(function (optionalQuestion) {
      return optionalQuestion.tag !== 'homephone';
    }).reduce(function (acc, question) {
      if (!acc) return acc;
      var field = $scope.FORM[question.id];
      return field && field.$valid;
    }, true);

    return isValid;
  };

  $scope.isValidCustomQuestions = function () {
    var questions = $scope.campaign.current.questions;
    // if no questions, than valid
    if (!questions || !questions.custom) {
      return true;
    }

    var isValid = questions.custom.reduce(function (acc, question) {
      if (!acc) return acc;
      var field = $scope.FORM['cq_' + question.id];
      return field && field.$valid;
    }, true);

    return isValid;
  };

  $scope.isValid = function () {
    return $scope.isValidContact() && $scope.isValidOptionalQuestions() && $scope.isValidCustomQuestions();
  };

  $scope.processKeydownEventDonationContact = function (event) {
    var keyCode = event && event.keyCode;
    if (keyCode === 13) {
      event.stopPropagation(); // only stop when valid?
      event.preventDefault();

      if ($scope.isValid()) {
        $scope.goDonationPaymentMethods();
      }
    }
  };

  /**
   * Helper fn to find out if member_phone is required based on the "homephone" option question
   * TODO: move to services
   */
  function __getIsPhoneNumberRequired() {
    var isRequired = false;
    var questions = $scope.campaign.current && $scope.campaign.current.questions;

    if (!_.isEmpty(questions) && questions.optional) {
      questions.optional.forEach(function (optionalQuestion) {
        if (optionalQuestion.tag === 'homephone' && optionalQuestion.is_required) {
          isRequired = true;
        }
      });
    }

    return isRequired;
  }
}]).controller('EmbeddedGivingDonationDoubleTheDonationCtrl', ["$scope", "$window", function ($scope, $window) {
  if ($scope.eg.dtd.isEnabled) {
    $window.doublethedonation.plugin.load_streamlined_input();
  }

  $scope.eg.dtd.toggle = function () {
    $scope.eg.dtd.isActive = !$scope.eg.dtd.isActive;
  };

  /**
   * Hacky: Grab the company name from a dtd plugin hidden input because dtd doesn't
   * give it to us via its API. The company name is needed by Classy downstream services.
   */
  $scope.$on('$destroy', function () {
    var dtdCompanyNameInput = document.getElementsByName('doubledonation_company_name');
    if (dtdCompanyNameInput.length) {
      $scope.eg.dtd.companyName = dtdCompanyNameInput[0].value;
    }
  });
}]).controller('EmbeddedGivingDonationCommentCtrl', ["$scope", function ($scope) {
  $scope.egDonationComment = {
    show: $scope.campaign.current && !$scope.campaign.current.hide_donation_comments,
    changes: $scope.MODEL.comment,
    preview: function preview() {
      var previewLength = 45;
      var comment = $scope.MODEL.comment;

      if (!comment) {
        return '';
      }

      var preview = comment.split('<p>').join('').split('</p>').join('').substring(0, previewLength);

      return preview.length >= previewLength ? preview + '...' : preview;
    },
    isDrawerOpen: false,
    openDrawer: function openDrawer() {
      $scope.egDonationComment.isDrawerOpen = true;
    },
    closeDrawer: function closeDrawer() {
      $scope.egDonationComment.isDrawerOpen = false;
    },
    cancelChanges: function cancelChanges() {
      $scope.egDonationComment.closeDrawer();
      $scope.egDonationComment.changes = $scope.MODEL.comment; // reset changes
    },
    saveChanges: function saveChanges() {
      $scope.egDonationComment.closeDrawer();
      $scope.MODEL.comment = $scope.egDonationComment.changes; // persit changes
    }
  };
}]).controller('EmbeddedGivingDonationConfirmationCtrl', ["$scope", function ($scope) {
  $scope.getConfirmationTermsUrl = function () {
    var org = SC.organization;
    if (!org) return false;
    // prioritize privacy policy url first
    if (org.privacy_policy_url) {
      return org.privacy_policy_url;
    }
    // then org.url as fallback
    if (org.url) {
      return org.url;
    }
    return false;
  };

  $scope.processKeydownEventDonationConfirmation = function (event) {
    var keyCode = event && event.keyCode;
    if (keyCode === 13) {
      event.stopPropagation(); // only stop when valid?
      event.preventDefault();
      $scope.embeddedGivingCheckout();
    }
  };
}]);
})();