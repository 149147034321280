(function(){
'use strict';

angular.module('classy').constant('scTextBlockDefaults', {
  title: 'Text',
  type: 'text',
  headline: 'Headline',
  hideHeadline: false,
  headlineColor: 'rgb(255,255,255)',

  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  mobileTemplates: false,
  content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec finibus finibus elit in ullamcorper.</p>',

  remove: true
});
})();