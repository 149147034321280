(function(){
'use strict';

scFlowModalService.$inject = ["$q", "$rootScope", "$state", "$timeout", "$window", "scFlowModalGroup", "scFlowModalView", "scFlowModalDimensions", "scFlowModalElements", "scRequestAnimationFrame", "accessibilityService"];
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/**
 * @name scFlowModalService
 * @kind service
 * @package FlowModal
 *
 * @guide ./FlowModal.guide.md
 *
 * @description
 * scFlowModalService is the main controller for the flowModal.
 *
 * It is available on $rootScope as $rootScope.flowModal (or, in templates, as
 * $root.flowModal).
 */

function scFlowModalService($q, $rootScope, $state, $timeout, $window, scFlowModalGroup, scFlowModalView, scFlowModalDimensions, scFlowModalElements, scRequestAnimationFrame, accessibilityService) {
  var flowModal = this;

  var dim = scFlowModalDimensions;

  var el = scFlowModalElements;

  /* ---------------------------------------------------------------- *
   * State
   * ---------------------------------------------------------------- */

  /**
   * @name deferred
   * @kind property
   * @type Deferred
   * @parent scFlowModalService
   *
   * @description
   * A $q deferred instance representing this modal session. The deferred
   * is generated when the modal is opened, and resolved or rejected
   * (potentially with a payload) when the modal is closed.
   */
  this.deferred = null;

  /**
   * @name groupDefs
   * @kind property
   * @type object
   * @parent scFlowModalService
   *
   * @description
   * Collection of group definitions, indexed by name.
   */
  this.groupDefs = {};

  /**
   * @name activeGroup
   * @kind property
   * @type scFlowModalGroup
   * @parent scFlowModalService
   *
   * @description
   * The currently displayed group.
   */
  this.activeGroup = null;

  /**
   * @name activeView
   * @kind property
   * @type scFlowModalView
   * @parent scFlowModalService
   *
   * @description
   * The currently displayed view.
   */
  this.activeView = null;

  /**
   * @name defaultContext
   * @kind property
   * @type Scope
   * @parent scFlowModalService
   *
   * @description
   * The scope against which to compile views, if a view definition or its
   * group have not defined one.
   */
  this.defaultContext = $rootScope;

  /**
   * @name debounceReLayout
   * @kind property
   * @type Promise
   * @parent scFlowModalService
   *
   * @description
   * A $timeout promise, used to batch relayouts when resizing the window.
   * Relayouts are called no more than every 100ms.
   */
  this.debounceReLayout = _.noop;

  /**
   * @name viewportPhase
   * @kind property
   * @type string
   * @parent scFlowModalService
   *
   * @description
   * Describes whether and how the viewport is being tweened.
   *
   * | Phase | Description |
   * | ----- | ----------- |
   * | static | The viewport is not being tweened. |
   * | enter | The viewport is tweening to enter the screen (a TweenMax.from animation) |
   * | change | The viewport is tweening to accommodate a new view (a TweenMax.to animation) |
   */
  this.viewportPhase = 'static';

  /**
   * @name openHandlers
   * @kind property
   * @type array
   * @parent scFlowModalService
   *
   * @description
   * Registered callbacks that execute each time the flowModal is opened
   * at the global level, not for use on individual flowModal instances. Used for
   * things like scrolling to the top of the page on specific devices
   */
  this.openHandlers = [];

  /**
   * @name ui
   * @kind property
   * @type object
   * @parent scFlowModalService
   *
   * @description
   * UI-related flowModal boolean properties.
   *
   * | Property | Description | Default |
   * | -------- | ----------- | ------- |
   * | visible | True if the modal is active. | false |
   * | closable | True if the modal can be closed by the user.
   *    (The modal can always be closed programmatically.) | true |
   * | blur | True if the background should be blurred when the modal is open,
   *    which can degrade performance. | false |
   */
  this.ui = {
    visible: false,
    closable: true,
    closeButtonStyles: {},
    closeOnClickOut: true,
    onClose: null,
    blur: false,
    shadow: true,
    matte: '#ffffff',
    overlay: 'rgba(0,0,0,0.4)',
    transitionWhitelist: [],
    returnState: '',
    returnParams: {},
    spinner: false
  };

  /**
   * @name delayedPull
   * @kind property
   * @type bool
   * @parent scFlowModalService
   *
   * @description
   * Used to trigger a delayed update if content resized legitimately, before tween transitions
   */

  this.delayedPull = false;

  /* ---------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------- */

  /**
   * @name register
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * Registers a group of frame definitions under a name.
   *
   * @param {string} name The name for this group.
   * @param {Scope} context A default scope against which to compile this
   * group's views.
   * @param {array} viewDefs An array of view definitions.
   */
  this.register = function (name, context, viewDefs) {
    this.groupDefs[name] = {
      views: viewDefs,
      context: context
    };
    context.$on('$destroy', function () {
      delete flowModal.groupDefs[name];
    });

    return flowModal;
  };

  /**
   * @name open
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * Opens the modal with the given group loaded.
   *
   * @param {string|array|object} group The group to load upon opening. If
   * a string, this should be the name given to the group when registered.
   * If an array, it should be a group of view definitions. If an object,
   * it should be a single view definition.
   * @param {object} options Object with the following properties:
   *
   * | Property | Type | Default | Description |
   * | -------- | ---- | ------- | ----------- |
   * | animate | boolean | false | If true, modal will animate in from the bottom of the screen.
   *    Can get janky with heavy modals. |
   * | startingView | number/string | 0 | The view to activate upon load. |
   * | elementToFocusOnClose | string | undefined | the class/id/element to focus on after closing (example: '.class_name', '#id_value'). Will use jquery to find element on close |
   */
  this.open = function (group) {
    var _this = this;

    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var animate = options.animate || false;
    var startingView = options.startingView || 0;

    flowModal.elementToFocusOnClose = options.elementToFocusOnClose;

    if (!flowModal.ui.visible) {
      flowModal.ui.returnState = $state.current.name;
      flowModal.ui.returnParams = $state.params;
    }

    _.forEach(['blur', 'closable', 'closeButtonStyles', 'closeOnClickOut', 'onClose', 'matte', 'overlay', 'shadow', 'transitionWhitelist', 'returnState', 'returnParams', 'spinner'], function (uiProp) {
      if (!_.isUndefined(options[uiProp])) {
        flowModal.ui[uiProp] = options[uiProp];
      }
    });

    if (flowModal.ui.spinner) {
      el.$views.addClass('view-loading');
    }

    var name = void 0,
        viewDefs = void 0,
        context = void 0;

    switch (typeof group === 'undefined' ? 'undefined' : _typeof(group)) {
      case 'string':
        name = group;
        viewDefs = this.groupDefs[group].views;
        context = this.groupDefs[group].context;
        break;
      case 'array':
        viewDefs = group;
        context = this.defaultContext;
        break;
      case 'object':
        viewDefs = [group];
        context = this.defaultContext;
        break;
      default:
      // do nothing
    }

    angular.element('body').css('overflow', 'hidden');

    // Create group; get sizes
    var newGroup = new scFlowModalGroup(viewDefs, context, name);

    // Layout new group
    newGroup.layout().then(function () {
      // If already open, merge views into existing group and transition
      // to the beginning of the next flow
      if (flowModal.ui.visible) {
        flowModal.activeGroup.merge(newGroup);

        flowModal.to(startingView);
      } else {
        // Otherwise, launch the modal
        flowModal.activeGroup = newGroup;

        flowModal.getView(startingView).then(function (view) {
          // Activate the view
          flowModal.activeView = view;

          // if this view has an eventbeacon function, use it
          if (view.eventBeacon) {
            view.eventBeacon();
          }

          // Mount the first view
          flowModal.load(flowModal.activeView);

          // Assign correct dimensions
          flowModal.update();

          // Flag Angular to display the modal on next $digest
          flowModal.ui.visible = true;

          // Animate the modal in when document is ready.
          angular.element(document).ready(function () {
            flowModal.enter(animate);
          });
        });
      }
    });

    // Start the session.
    this.deferred = $q.defer();

    // scFlowModalService.open() has been used in countless components without registering
    // a .catch() to handle the promise rejection during userClose. In order to suppress the
    // unhandled rejection warning without breaking any code, we override the native catch logic
    // on the promise so that it sets a flag which tells us whether it has been caught or not
    // we can check this flag when choosing to reject the promise later, e.g. in this.close()
    this.deferred.caught = false;
    this.deferred.promise.catch = function () {
      var _deferred$promise$cat;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      _this.deferred.caught = true;
      return (_deferred$promise$cat = _this.deferred.promise.catch).bind.apply(_deferred$promise$cat, [_this.deferred.promise].concat(args));
    };

    // call the handlers that were registered with scFlowModalService.onOpen()
    // which get called every time the modal is opened.
    this.openHandlers.forEach(function (handler) {
      return handler();
    });

    return this.deferred.promise;
  };

  /**
   * @name close
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * Closes the modal.
   */
  this.close = function (success, payload, evt) {
    if (evt && evt.type !== 'click' && !accessibilityService.isValidKeyBoardEvent(evt)) return;
    if (evt) evt.preventDefault();

    if (!flowModal.deferred) {
      return;
    }

    var closePromise = void 0;

    // Prevent page from scrolling to the input below on pop-up close CL-14898
    /* const modalElement = document.querySelector('.activity_comment-input');
    if (modalElement) modalElement.focus(); */

    angular.element('body').css('overflow', '');
    angular.element('body').removeClass('hide-overflow');

    if (flowModal.ui.onClose === 'return') {
      flowModal.ui.transitionWhitelist.push(flowModal.ui.returnState);
      $state.go(flowModal.ui.returnState, flowModal.ui.returnParams);
    } else if (_.isFunction(flowModal.ui.onClose)) {
      closePromise = flowModal.ui.onClose();
    }

    // is not a promise
    if (!closePromise || !_.isFunction(closePromise.then)) {
      closePromise = $q.resolve();
    }

    closePromise.then(function () {
      flowModal.ui.blur = false;
      flowModal.ui.closable = true;
      flowModal.ui.closeOnClickOut = true;
      flowModal.ui.closeButtonStyles = {};
      flowModal.ui.onClose = null;
      flowModal.ui.matte = '#ffffff';
      flowModal.ui.overlay = 'rgba(0,0,0,0.4)';
      flowModal.ui.shadow = true;
      flowModal.ui.transitionWhitelist = [];
      flowModal.ui.returnState = '';
      flowModal.ui.returnParams = {};
      flowModal.ui.spinner = false;

      flowModal.ui.visible = false;

      flowModal.unload(flowModal.activeView);
      flowModal.activeView = null;
      flowModal.activeGroup = null;

      if (_.isUndefined(success) ? true : !!success) {
        flowModal.deferred.resolve(payload);
      } else if (flowModal.deferred.caught) {
        flowModal.deferred.reject(payload);
      }

      if (flowModal.elementToFocusOnClose) {
        $(flowModal.elementToFocusOnClose).focus();
      }
    }, function () {});
  };

  /**
   * @name clearFocus
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * clears element to focus.
   */
  this.clearFocus = function () {
    if (flowModal.elementToFocusOnClose) {
      flowModal.elementToFocusOnClose = null;
    }
  };

  /**
   * @name userClose
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * Closes the modal if the modal is user-closable.
   */
  this.userClose = function () {
    if (!flowModal.ui.closable) {
      return;
    }
    flowModal.close(false, { closeByUser: true });
  };

  /**
   * @name adjust
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * Re-measures the active view and animates the viewport to match.
   */
  this.adjust = function (ease, duration) {
    if (!flowModal.ui.visible) {
      return;
    }

    flowModal.activeView.getDimensions();
    flowModal.update(ease, duration);
  };

  /**
   * @name to
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * Transitions to another view.
   *
   * @param {number|string} key The view to transition to. If a number,
   * represents the view's index in the group's views array. If a string,
   * represents an ID that must have been defined with the view.
   * @param {string} transition The transition to use. Can be one of:
   *
   * * slideLeft
   * * slideRight
   * * slideUp
   * * slideDown
   * * fade
   * * flipX
   * * flipY
   *
   * @param {number} duration The transition speed in milliseconds.
   * @param {object} ease A GSAP ease to use for the transition.
   */
  this.to = function (key, transition, duration, ease, keyboardEvent) {
    if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;

    var exitView = this.activeView;

    this.getView(key).then(function (enterView) {
      flowModal.activeView = enterView;

      // if this view has an eventbeacon function, use it
      if (enterView.eventBeacon) {
        enterView.eventBeacon();
      }

      var animationDuration = duration || enterView.defaults.duration;

      // For testing
      // duration = 10000;

      TweenMax.killTweensOf(el.$viewport[0]);
      TweenMax.killTweensOf(enterView.$frame[0]);
      TweenMax.killTweensOf(exitView.$frame[0]);

      flowModal.load(enterView);

      $rootScope.$broadcast('flowModal.transitionStart');

      flowModal.viewportPhase = 'change';
      TweenMax.to(el.$viewport[0], animationDuration / 1000, {
        transform: 'translateY(' + enterView.dim.frame.top + 'px) translateX(' + enterView.dim.frame.left + 'px)',
        width: enterView.dim.frame.width,
        height: enterView.dim.frame.height,
        ease: Power4.easeInOut,
        onComplete: function onComplete() {
          if (flowModal.viewportPhase === 'change') {
            flowModal.viewportPhase = 'static';
          }
        }
      });

      var tweenConfig = flowModal.getTweenConfig(enterView, exitView, transition || null, ease || null);

      TweenMax.from(enterView.$frame[0], animationDuration / 1000, tweenConfig.enterFrom);
      TweenMax.to(exitView.$frame[0], animationDuration / 1000, tweenConfig.exitTo);
    });
  };

  this.whitelist = function (state) {
    flowModal.ui.transitionWhitelist.push(state);
  };

  /**
   * @name getView
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * An internal utility that fetches a view based on a key.
   *
   * @param {number|string} key The view key. If a number,
   * represents the view's index in the active group's views array. If a
   * string, represents an ID that must have been defined with the view.
   */
  this.getView = function (key) {
    var deferred = $q.defer();

    if (_.isNumber(key)) {
      deferred.resolve(flowModal.activeGroup.views[key]);
    } else if (_.isString(key)) {
      for (var i = 0; i < flowModal.activeGroup.views.length; i += 1) {
        var view = flowModal.activeGroup.views[i];
        if (view.id === key) {
          deferred.resolve(view);
        }
      }
    } else if (key instanceof scFlowModalView) {
      deferred.resolve(key);
    } else if (_.isPlainObject(key)) {
      var _view = new scFlowModalView(key, this.defaultContext);
      $timeout(function () {
        _view.stage();
        _view.getDimensions();
        _view.unstage();
        deferred.resolve(_view);
      });
    }

    return deferred.promise;
  };

  /**
   * @name load
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * An internal utility that appends a view's DOM content to the viewport and resets
   * its transform properties to prepare for a new transition.
   *
   * @param {scFlowModalView} view The view to append.
   */
  this.load = function (view) {
    view.$frame.appendTo(el.$views);
    TweenMax.set(view.$frame[0], {
      clearProps: 'transform, opacity, rotation'
    });
    view.loaded = true;
    $rootScope.$broadcast('flowModal.load');
  };

  /**
   * @name unload
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * An internal utility that detaches a view's DOM content from the viewport.
   *
   * @param {scFlowModalView} view The view to append.
   */
  this.unload = function (view) {
    // If flipping through routes quickly you may complete an animation
    // after the view has been destroyed.
    if (!view) {
      return;
    }

    view.$frame.detach();
    view.loaded = false;
  };

  /**
   * @name update
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * An internal utility that resizes the viewport and its active view,
   * e.g. when the window is resized.
   *
   * @param {object} ease A GSAP ease to use for the transition.
   * @param {number} duration A transition speed in milliseconds.
   */
  this.update = function (ease, duration) {
    var easeFn = ease || window.Linear.easeOut;
    var animationDuration = duration ? duration / 1000 : 0;
    TweenMax.to(el.$viewport[0], animationDuration, {
      transform: 'translateY(' + flowModal.activeView.dim.frame.top + 'px) translateX(' + flowModal.activeView.dim.frame.left + 'px)',
      width: flowModal.activeView.dim.frame.width,
      height: flowModal.activeView.dim.frame.height,
      ease: easeFn
    });
    TweenMax.to(flowModal.activeView, animationDuration, {
      width: flowModal.activeView.dim.frame.width,
      height: flowModal.activeView.dim.frame.height,
      ease: easeFn
    });
  };

  /**
   * @name enter
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * An internal utility that "flies in" the modal viewport and close button.
   *
   * @param {boolean} animate Whether to animate the modal entrance.
   */
  this.enter = function (animate) {
    flowModal.viewportPhase = 'enter';
    var duration = animate ? 0.6 : 0;
    TweenMax.from(el.$viewport[0], duration, {
      transform: 'translateY(' + dim.window.height + 'px) translateX(' + flowModal.activeView.dim.frame.left + 'px)',
      ease: Elastic.easeOut.config(1, 1),
      onComplete: function onComplete() {
        if (flowModal.viewportPhase === 'enter') {
          flowModal.viewportPhase = 'static';
          if (flowModal.delayedPull) {
            flowModal.delayedPull = false;
            flowModal.update();
          }
          accessibilityService.initiateModalFocus(flowModal, el.$overlay);
        }
      }
    });
    // [FRS-2167] No cartwheeling!
    // if (animate && flowModal.ui.closable) {
    //   TweenMax.from(el.$close[0], 0.8, {
    //     transform: 'rotateZ(-270deg)',
    //     ease: Elastic.easeOut.config(1, 1),
    //     delay: 0.2
    //   });
    // }
  };

  /**
   * @name exit
   * @kind method
   * @parent scFlowModalService
   * @deprecated
   *
   * @description
   * An internal utility that "flies out" the modal viewport.
   */
  this.exit = function () {
    /*
    TweenMax.to( flowModal.$viewport[0], 0.3, {
      transform: 'translateX(' + flowModal.window.height + 'px)'
    });
    */
  };

  /**
   * @name getTweenConfig
   * @kind method
   * @parent scFlowModalService
   *
   * @description
   * Translates a transition slug into a set of variables for GSAP.
   *
   * @param {scFlowModalView} enterView The view being transitioned to.
   * @param {scFlowModalView} exitView The view being transitioned from.
   * @param {string} transition The transition to be calculated.
   * @param {object} ease A GSAP ease to use for the transition.
   *
   */
  this.getTweenConfig = function (enterView, exitView, transition, ease) {
    var easeFn = ease || enterView.ease || enterView.defaults.ease;

    var enterFrom = void 0,
        exitTo = void 0;

    switch (transition || enterView.transition || enterView.defaults.transition) {
      case 'slideLeft':
        enterFrom = {
          transform: 'translateX(' + exitView.dim.frame.width + 'px)'
        };
        exitTo = {
          transform: 'translateX(-' + exitView.dim.frame.width + 'px)'
        };
        break;

      case 'slideRight':
        enterFrom = {
          transform: 'translateX(-' + enterView.dim.frame.width + 'px)'
        };
        exitTo = {
          transform: 'translateX(' + enterView.dim.frame.width + 'px)'
        };
        break;

      case 'slideDown':
        enterFrom = {
          transform: 'translateY(-' + exitView.dim.frame.height + 'px)'
        };
        exitTo = {
          transform: 'translateY(' + enterView.dim.frame.height + 'px)'
        };
        break;

      case 'slideUp':
        enterFrom = {
          transform: 'translateY(' + exitView.dim.frame.height + 'px)'
        };
        exitTo = {
          transform: 'translateY(-' + enterView.dim.frame.height + 'px)'
        };
        break;

      case 'fade':
        enterFrom = {
          opacity: 0
        };
        exitTo = {
          opacity: 0
        };
        break;

      case 'flipX':
        enterFrom = {
          rotationX: '180_ccw'
        };
        exitTo = {
          rotationX: '180_cw'
        };
        break;

      case 'flipY':
        enterFrom = {
          rotationY: '180_cw'
        };
        exitTo = {
          rotationY: '180_cw'
        };
        break;

      default:
      // do nothing
    }

    enterFrom.ease = easeFn;
    exitTo.ease = easeFn;
    exitTo.onComplete = function () {
      flowModal.unload(exitView);
      $rootScope.$broadcast('flowModal.transitionEnd');
    };

    return {
      enterFrom: enterFrom,
      exitTo: exitTo
    };
  };

  /* ---------------------------------------------------------------- *
   * Watches
   * ---------------------------------------------------------------- */

  angular.element($window).on('resize orientationchange', function () {
    dim.update();
  });

  angular.element('body').on('mousedown touchstart', function (e) {
    if (e.type === 'touchstart') {
      angular.element('body').off('mousedown');
    }

    // Ignore events within the modal.
    if (angular.element(e.target).closest(el.$viewport).length) {
      return;
    }

    // Prevent touchscreens from propagating touch event twice. (mobile)
    if (e.type === 'touchstart') {
      angular.element('body').off('mousedown');
    }

    // Ignore events with detached targets.
    // E.g.: you click a button in a modal. That button performs an action
    // that changes state, which triggers an ng-if, and the button is
    // detached from the DOM. The button no longer has the modal as its
    // parent, and without this check, would trigger the modal to close!
    if (!angular.element(e.target).closest('body').length) {
      return;
    }

    if (flowModal.ui.visible && flowModal.ui.closeOnClickOut) {
      $rootScope.$apply(function () {
        flowModal.userClose(false);
      });
    }
  });

  angular.element('body').on('keydown', function (e) {
    if (e.keyCode === 27 && flowModal.ui.visible) {
      $timeout(function () {
        flowModal.userClose(false);
      });
    }
  });

  // eslint-disable-next-line no-unused-vars
  $rootScope.$on('$stateChangeStart', function (e, toState) {
    if (flowModal.ui.visible && flowModal.ui.transitionWhitelist.indexOf(toState.name) === -1) {
      flowModal.close(false);
    }
  });

  // Set focus on first focusable element when modal has finished transitioning to a new view
  $rootScope.$on('flowModal.transitionEnd', function () {
    accessibilityService.initiateModalFocus(flowModal, el.$overlay);
  });

  $rootScope.$on('flowModal.load', function () {
    function checkDimensions() {
      if (flowModal.ui.visible) {
        var activeView = flowModal.activeView;
        var cachedHeight = activeView.dim.frame.height;
        var cachedWidth = activeView.dim.frame.width;
        var cachedLeft = activeView.dim.frame.left;
        var cachedTop = activeView.dim.frame.top;
        activeView.getDimensions();
        if (cachedHeight !== activeView.dim.frame.height || cachedWidth !== activeView.dim.frame.width) {
          // If this is mid-transition (which is likely, as the content may
          // have not finished loading when we got our initial dimensions),
          // update the destination of the active tween.
          if (TweenMax.isTweening(el.$viewport[0]) && flowModal.viewportPhase === 'change') {
            var tween = TweenMax.getTweensOf(el.$viewport[0])[0];
            tween.updateTo({
              css: {
                transform: 'translateY(' + activeView.dim.frame.top + 'px) translateX(' + activeView.dim.frame.left + 'px)',
                width: activeView.dim.frame.width,
                height: activeView.dim.frame.height
              }
            });
            // Otherwise, update the dimensions with minimal animation.
          } else if (flowModal.viewportPhase === 'static') {
            flowModal.update();
          } else if (flowModal.viewportPhase === 'enter') {
            flowModal.delayedPull = true;
          }
        }

        /**
         * Custom Input Disable for CL-7341
         */

        if (document.getElementById('endDate')) {
          document.getElementById('endDate').readOnly = true;
        }

        if (flowModal.viewportPhase === 'static' && (cachedLeft !== activeView.dim.frame.left || cachedTop !== activeView.dim.frame.top)) {
          flowModal.update();
        }
      }
      scRequestAnimationFrame(checkDimensions);
    }

    scRequestAnimationFrame(checkDimensions);
  });

  $rootScope.$on('flowModal.viewReady', function () {
    el.$views.removeClass('view-loading');
  });

  /* ---------------------------------------------------------------- *
   * Event Callbacks
   * ---------------------------------------------------------------- */

  this.onOpen = function (callback) {
    if (typeof callback !== 'function') throw new Error('opOpen handler must be a function');
    this.openHandlers.push(callback);
  };

  /* ---------------------------------------------------------------- *
   * External control
   * ---------------------------------------------------------------- */

  $rootScope.flowModal = flowModal;
}

angular.module('classy').service('scFlowModalService', scFlowModalService);
})();