(function(){
'use strict';

scFillerText.$inject = ["$timeout", "scUserAgent"];
function scFillerText($timeout, scUserAgent) {
  return {
    scope: {
      parentContainer: '=?parentContainer',
      titleContainer: '=?titleContainer',
      mode: '=?scFillerText',
      offset: '=?fillerOffset'
    },

    link: function link(scope, element) {
      var fontRatio = 0;
      var fontBase = 0;
      var fontSubBase = 0;
      var allTexts = [];
      var savedFontSizes = [];

      // exit if we don't have the required params
      if (!scope.titleContainer) {
        return;
      }

      // by default, the attached element is the parent container
      if (!scope.parentContainer) {
        scope.parentContainer = element;
      }

      // sometimes you need offsets cuz pages heights are weird
      if (!scope.offset) {
        scope.offset = 0;
      }

      // custom font ratios for each page/case/etc
      if (!_.isEmpty(scope.mode)) {
        fontBase = 0;
        fontRatio = 0;
        if (Modernizr.mq('(max-width: 568px)')) {
          fontBase = scope.mode.fontBase || 0;
          fontRatio = scope.mode.fontRatio || 0;
        }
      } else {
        fontBase = 55;
        fontSubBase = 30;
        if (Modernizr.mq('(max-width: 1024px)')) {
          fontRatio = 40;
        } else {
          fontRatio = 0;
        }
      }

      /*  theDiv
       *    |
       *    el
       *    |
       *   child
       */
      // gets all the text elements inside our target div
      function getAllTexts(theDiv) {
        // if there are childNodes in this div
        if (theDiv.childNodes) {
          _.forEach(theDiv.childNodes, function (el) {
            // if this child node has children
            // then for each of these grandchildren, if the grandchildren do not have
            // a style of their own, inherit some styles from their parent (the Div's child)
            if (!_.isEmpty(el.childNodes)) {
              if (el.style.fontFamily) {
                _.forEach(el.childNodes, function (child) {
                  if (!_.get(child, 'style.fontFamily', false)) {
                    if (!child.style) {
                      child.style = {};
                    }
                    child.style.fontFamily = el.style.fontFamily;
                  }
                });
              }
              if (el.style.fontSize) {
                _.forEach(el.childNodes, function (child) {
                  if (!_.get(child, 'style.fontSize', false)) {
                    if (!child.style) {
                      child.style = {};
                    }
                    child.style.fontSize = el.style.fontSize;
                  }
                });
              }
            }

            // find any grandchildren nodes that are textnodes
            var textNode = _.find(el.childNodes, function (child) {
              return child.nodeName === '#text';
            });

            var thinkDifferentMode = _.find(el.childNodes, function (child) {
              return child.nodeName === 'BR';
            }) && scUserAgent.isIOSx();

            // if there are any grandchildren textnodes OR it IS A break nodes
            // OR if it gets special iOS 10+ treatment >:(
            // FRS-6719: iOS 10.0+ gets special treatment because while most browsers
            // will accept that <br> elements can have a font-size, iOS 10.0+ safari
            // 100% will inherit the parent's font-size and ignore the br's styles
            // which was a problem with the solution of making parent font-sizes 0,
            // which in itself was originally a solution to a problem where for some browsers,
            // the parent font-size + line-height combination was making things weird
            if (textNode || _.get(el, 'nodeName', '') === 'BR' || thinkDifferentMode) {
              // if this element does not have a fontSize give it some fontsize thing
              if (!_.get(el, 'style.fontSize', false)) {
                if (el.className == 'subheading') {
                  el.style.fontSize = Math.floor(fontSubBase * fontRatio / fontBase).toString() + 'px';
                } else {
                  el.style.fontSize = fontRatio.toString() + 'px';
                  el.style.lineHeight = '1.2';
                }
              }

              // if there are nodes that are not textnodes keep at it recurisvely
              // this is because textnodes are considered the stopping point for a node tree in
              // this context
              var otherNodes = _.find(el.childNodes, function (child) {
                return child.nodeName != '#text';
              });

              if (otherNodes) {
                getAllTexts(el);
              }

              // add dis to the alltexts array
              allTexts.push(el);
            } else if (el) {
              // if there are no grandchildren text nodes, then just consider this
              // a parent and throw it through a loop
              if (!_.get(el, 'style', false)) {
                el.style = {};
              }
              el.style.lineHeight = '1.2';
              // if it's a list item, give it the same font size
              // as its children to keep the bullet point from disappearing
              // or being waeee too big
              if (_.get(el, 'nodeName', '') === 'LI') {
                el.style.fontSize = fontRatio.toString() + 'px';
              } else {
                el.style.fontSize = '0';
              }
              getAllTexts(el);
            }
          });
        }

        return allTexts;
      }

      // saves the fonts just in case
      function saveFonts(texts) {
        if (_.isEmpty(savedFontSizes)) {
          _.forEach(texts, function (el) {
            if (el.style.fontSize) {
              savedFontSizes.push(el.style.fontSize);
            } else {
              savedFontSizes.push(window.getComputedStyle(el, null).getPropertyValue('font-size'));
            }
          });
        }

        return savedFontSizes;
      }

      // don't do anything if fontRatio is zero
      if (fontRatio) {
        $timeout(function () {
          var cfHeader = element[0].querySelector(scope.titleContainer);
          var cfParent = document.querySelector(scope.parentContainer);
          var origFontSizes = void 0;
          var textElements = void 0;

          if (cfHeader) {
            textElements = getAllTexts(cfHeader);
            origFontSizes = saveFonts(textElements);

            _.forEach(textElements, function (el, index) {
              var fontSize = origFontSizes[index] || el.style.fontSize;
              var fontSizeInteger = parseInt(fontSize, 10);
              var relativeFontSize = fontSizeInteger * fontRatio / fontBase;
              var roundedFontSize = Math.floor(relativeFontSize);
              el.style.fontSize = roundedFontSize + 'px';
            });
          }

          if (cfParent) {
            // this for some reason is the right ratio due to a height sizing problem
            var realSize = void 0;
            var wideLoad = false;
            var parentSize = cfParent.clientHeight;
            var adjust = true;
            var counter = 1;
            $timeout(function () {
              realSize = parseInt(window.getComputedStyle(cfHeader, null).getPropertyValue('height'), 10) - scope.offset;
            }).then(function () {
              function shrinkSize(el) {
                var fontSize = parseInt(el.style.fontSize, 10);
                el.style.fontSize = Math.floor(fontSize - Math.pow(0.4, counter) * fontSize) + 'px';
              }

              function raiseSize(el) {
                var fontSize = parseInt(el.style.fontSize, 10);
                el.style.fontSize = Math.floor(fontSize + Math.pow(0.4, counter) * fontSize) + 'px';
                if (el.offsetWidth > 0.9 * window.innerWidth) {
                  wideLoad = true;
                }
              }

              while (adjust) {
                origFontSizes = saveFonts(textElements);

                if (realSize > parentSize) {
                  _.forEach(textElements, shrinkSize);
                } else if (realSize < 0.9 * parentSize && !wideLoad) {
                  _.forEach(textElements, raiseSize);
                } else {
                  adjust = false;
                }
                if (counter > 3) {
                  adjust = false;
                }
                realSize = parseInt(window.getComputedStyle(cfHeader, null).getPropertyValue('height'), 10) - scope.offset;
                counter += 0.5;
              }
            });
          }
        });
      }
    }
  };
}

angular.module('classy').directive('scFillerText', scFillerText);
})();