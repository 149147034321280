(function(){
'use strict';

/*
 * 'CryptoGivingUtil' is an inbox for misc functionality that does not have a home yet
 */
angular.module('classy').service('CryptoGivingUtil', function () {
  this.getAnalyticsEventData = function () {
    var analyticsData = {
      organizationId: SC.organization.id,
      campaignType: SC.campaign.type,
      campaignId: SC.campaign.id
    };

    return analyticsData;
  };
});
})();