(function(){
'use strict';

/**
 * @kind directive
 * @name scMirrorModel
 * @requires ngModel
 *
 * @description
 * Establishes one-way binding from this element's ngModel to another value.
 *
 * WARNING: Requires ngModel, even though directive marks it as optional.
 *
 * Used in:
 * application/views/scripts/checkout/donation.phtml
 *
 * @param {expression} ngMirrorModel The value to replace with this element's
 * model value when it changes.
 */

scMirrorModel.$inject = ["$parse", "$timeout"];
function scMirrorModel($parse, $timeout) {
  'use strict';

  return {
    requires: '?ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs) {
      scope.parsed = false;

      $timeout(function () {
        scope.$watch(attrs.ngModel, function (value) {
          if (attrs.scMirrorModel !== 'false') {
            scope.parsed = true;
            $parse(attrs.scMirrorModel).assign(scope, value);
          }
        });
      });
    }
  };
}

angular.module('classy').directive('scMirrorModel', scMirrorModel);
})();