(function(){
'use strict';

scFeaturedBlock.$inject = ["scBlock"];
function scFeaturedBlock(scBlock) {
  return scBlock({
    link: function link() {}
  });
}

angular.module('classy').directive('scFeaturedBlock', scFeaturedBlock);
})();