(function(){
'use strict';

angular.module('classy').constant('scFooterBlockDefaults', {
  title: 'Footer',
  type: 'footer',
  disable: false,
  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },
  top: {
    text: {
      color: 'rgb(255,255,255)'
    }
  },
  bottom: {
    text: {
      color: 'rgb(255,255,255)'
    }
  },
  poweredByClassy: true,
  backToTop: true,
  remove: false,
  unsortable: true
});
})();