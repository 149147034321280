(function(){
'use strict';

angular.module('classy').component('registrationEntityBrowse', {
  template: '\n      <browse-teams-modal\n      title="\'Select a team to join\'"\n      on-select="$ctrl.selectTeam($team)"\n      go-to-create="$ctrl.goToCreate()"\n      campaign-id="$ctrl.campaignId"\n      primary-color="$ctrl.primaryColor">\n      </browse-teams-modal>\n    ',
  bindings: {
    model: '<',
    joinTeam: '&',
    goToCreate: '&',
    campaignId: '<',
    primaryColor: '<'
  },
  controller: function controller() {
    var _this = this;

    this.selectTeam = function ($team) {
      _this.model.team_id = $team.id;
      _this.model.team_answers = null;
      _this.joinTeam({ team: $team });
    };
  }
});
})();