(function(){
'use strict';

angular.module('classy').constant('cpConstants', {
  PAYMENT_METHODS: {
    ACH: 'ACH',
    PAYPAL: 'PayPal',
    STRIPE: 'Stripe',
    CC: 'CC',
    DW: 'DW',
    PAYPAL_COMMERCE: 'PayPal_Commerce',
    VENMO: 'Venmo',
    CRYPTO: 'Crypto'
  },
  STATUS: {
    INACTIVE: 'inactive',
    PENDING: 'pending',
    READY: 'ready',
    SUCCESS: 'success',
    FAILURE: 'failure',
    OPEN: 'open'
  },
  EVENTS: {
    ACH_SUCCESS: 'ACH_SUCCESS',
    ACH_CANCEL: 'ACH_CANCEL',
    ACH_FAILURE: 'ACH_FAILURE',
    PAYPAL_SUCCESS: 'PAYPAL_SUCCESS',
    PAYPAL_CANCEL: 'PAYPAL_CANCEL',
    PAYPAL_FAILURE: 'PAYPAL_FAILURE',
    STRIPE_SUCCESS: 'STRIPE_SUCCESS',
    STRIPE_CANCEL: 'STRIPE_CANCEL',
    STRIPE_FAILURE: 'STRIPE_FAILURE'
  }
});
})();