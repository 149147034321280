(function(){
'use strict';

angular.module('classy').controller('EmbeddedGivingFAQCtrl', ["$scope", function ($scope) {
  $scope.ActiveFAQs = []; // TODO @hvnt
  $scope.ActiveFAQ = null;

  $scope.setActiveFAQs = function (faqs) {
    $scope.ActiveFAQs = faqs;
    // if 4 or less automatically open first
    if ($scope.ActiveFAQs && $scope.ActiveFAQs.length >= 1 && $scope.ActiveFAQs.length <= 4) {
      $scope.ActiveFAQ = $scope.ActiveFAQs[0];
    }
  };

  $scope.setActiveFAQ = function (faq) {
    // if selected faq is already Active, set Active to null (close faq answer)
    if ($scope.ActiveFAQ && $scope.ActiveFAQ.id === faq.id) {
      $scope.ActiveFAQ = null;
    } else {
      $scope.ActiveFAQ = faq;
    }
  };

  // init FAQs (this _.get should be encapsulated in business logic... not ctrl logic)
  $scope.setActiveFAQs(_.get($scope, 'campaign.current.faqs.data'));
}]);
})();