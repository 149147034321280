(function(){
'use strict';

angular.module('classy').constant('RECURRING_LEVELS', {
  ONE_TIME_DEFAULT: '1',
  RECURRING_DEFAULT: '2',
  RECURRING_FIXED_AMOUNT: '3',
  RECURRING_ONLY: '4',
  ONE_TIME_ONLY: '5',
  ONE_TIME_FIXED_AMOUNT: '6'
});
})();