(function(){
'use strict';

angular.module('classy').component('selectPaginationField', {
  template: '\n      <div class="form_field {{ $ctrl.fieldOptions.containerClass || \'full\' }}">\n        <label class="form__label" _spec=\'label\'>\n\n          <i class="form_label-icon-ok fa fa-check-circle"\n          data-ng-if="$ctrl.fieldOptions.check.enabled"\n          data-ng-show="{{ $ctrl.fieldOptions.check.condition }}"></i>\n\n          {{:: $ctrl.fieldOptions.label }}\n\n          <span class="form_label-required" data-ng-if="$ctrl.fieldOptions.required"></span>\n        </label>\n\n        <p class="form__sublabel--shifted"\n          _spec=\'sublabel\'\n          ng-if="$ctrl.fieldOptions.sublabel">\n          {{:: $ctrl.fieldOptions.sublabel }}\n        </p>\n        \n        <dropdown-control\n          ng-model="$ctrl.model"\n          selected="$ctrl.selected"\n          options="$ctrl.options"\n          name="{{:: $ctrl.fieldOptions.fieldName}}"\n          _spec=\'input\'></dropdown-control>\n      </div>\n    ',
  bindings: {
    fieldOptions: '=',
    model: '=',
    selected: '=',
    form: '<',
    offset: '<?',
    paginationFunction: '&'
  },
  controller: ["$element", "$log", function selectPaginationFieldCtrl($element, $log) {
    this.$onInit = function () {
      var _this = this;

      this.options = [];
      this.currentPage = 0;
      this.paginate = false;
      // edge cases and race conditions where the selected is not found
      // in the given options list and would show blank
      if (this.selected) {
        this.selectedCopy = Object.assign({}, this.selected);
      }

      if (!this.offset) {
        this.offset = 0;
      }

      this.currentPage += 1;

      this.paginationFunction({ page: this.currentPage }).then(function (resp) {
        _this.lastPage = resp.last_page;
        _this.getPage(resp);

        _this.dropdownElement = angular.element($element.find('.form__dropdown-dropdown')[0]);

        _this.dropdownElement.on('scroll', function () {
          _this.handlePagination();
        });
      });
    };

    this.getPage = function (pageData) {
      var valueKey = _.get(this.fieldOptions, 'valueKey', 'value');
      var labelKey = _.get(this.fieldOptions, 'labelKey', 'label');
      var newOpts = _.map(pageData.data, function (option) {
        return {
          value: option[valueKey],
          label: option[labelKey]
        };
      });

      this.options = this.options.concat(newOpts);
      this.paginate = this.currentPage < this.lastPage;
    };

    this.handlePagination = function () {
      var _this2 = this;

      if (!this.paginate) {
        return;
      }

      // how many pixels you can scroll + viewable height === total content height
      // means you've made it to the bottom
      if (this.dropdownElement[0].scrollTop + this.dropdownElement[0].clientHeight >= this.dropdownElement[0].scrollHeight - this.offset) {
        this.paginate = false;
        this.currentPage += 1;

        this.paginationFunction({ page: this.currentPage }).then(function (resp) {
          _this2.getPage(resp);
          return resp;
        }).catch(function (err) {
          $log.error(err);
        });
      }
    };

    this.$postLink = function () {
      // other half of dealing with the edge cases and race conditions
      if (this.selectedCopy) {
        this.selected = Object.assign({}, this.selectedCopy);
      }
    };
  }]
});
})();