(function(){
'use strict';

/**
 * @kind service
 * @name scImgAssetsService
 *
 * @description
 * Collection of image assets available to the app.
 */

scImgAssetsService.$inject = ["$q", "scImgAsset", "scImgAssetProcess"];
function scImgAssetsService($q, scImgAsset, scImgAssetProcess) {
  var _this = this;

  var tempId = 0;

  // Temp ID management
  this.getTempId = function () {
    tempId += 1;
    return tempId + '_TEMP';
  };

  // Instantiated scImgAssets
  this.registry = {
    upload: {},
    static: {}
  };

  // Asset creation
  this.createAsset = function (id, origin, def, options) {
    return new scImgAsset(id, origin, def, options);
  };

  // Register an asset
  this.register = function (asset, assetId, origin) {
    if (!asset || !assetId || !origin) {
      return false;
    }

    if (_.has(_this.registry, [origin, assetId])) {
      return null;
    }

    _this.registry[origin][assetId] = asset;
    return true;
  };

  this.hydrate = function (assetDef) {
    var asset = _.get(_this.registry, ['upload', assetDef.id]);
    if (asset) {
      return asset;
    }

    asset = _this.createAsset(assetDef.id, 'upload', assetDef);
    _this.register(asset, assetDef.id, 'upload');
    return _.get(_this.registry, ['upload', assetDef.id]);
  };

  // Retrieve an asset
  this.get = function (assetId, origin) {
    return _.get(_this.registry, [origin, assetId], _this.NULL_ASSET);
  };

  // Build an asset
  this.build = function (data, options) {
    var id = _this.getTempId();
    var asset = _this.createAsset(id, 'upload', data, options);
    _this.register(asset, id, 'upload');
    asset.on('save', function () {
      _this.register(asset, asset.assetId, 'upload');
    });

    return asset;
  };

  // Transform an asset
  this.transform = function (asset, transformQueue, preview) {
    var childAssetData = $q.defer();
    var childAsset = void 0;
    asset.on('save', function () {
      scImgAssetProcess(asset.assetId, transformQueue).then(function (response) {
        childAssetData.resolve(response.data);
        childAsset.deferred.src.resolve();
      }, function () {
        childAssetData.reject();
        childAsset.deferred.src.reject();
      });
    });

    childAsset = _this.build(childAssetData.promise);
    childAsset.src = preview;
    childAsset.sizes.full = preview;

    return childAsset;
  };

  // Initialize: convert provided asset definitions into scImgAssets
  this.init = function (assetDefs) {
    // Uploaded assets: raw info straight from database
    _.forEach(assetDefs, function (def) {
      var asset = _this.createAsset(def.id, 'upload', def);
      _this.register(asset, def.id, 'upload');
    });
  };

  // "Null" asset, subbed in when an asset is requested and not found.
  this.NULL_ASSET = this.createAsset('NULL_ASSET', 'static', {});
}

angular.module('classy').service('scImgAssetsService', scImgAssetsService);
})();