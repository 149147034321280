(function(){
'use strict';

/**
 * @name orgContactService
 * @kind service
 *
 * @description
 * Gets the org phone number and email address
 */

orgContactService.$inject = ["$q", "scCampaignsService", "scTaxEntitiesService", "scOrganizationsService"];
function orgContactService($q, scCampaignsService, scTaxEntitiesService, scOrganizationsService) {
  this.getPhoneNumber = function () {
    if (scCampaignsService.active.multiCurrencyDisabled()) {
      return $q.resolve(scCampaignsService.active.current.contact_phone);
    }

    var entityPromise = !_.isEmpty(scTaxEntitiesService.currentTaxableEntity) ? $q.resolve(scTaxEntitiesService.currentTaxableEntity) : scTaxEntitiesService.getDefaultTaxEntity(scCampaignsService.active.current);

    // if the campaign doesn't have a phone number and the tax entity either doesn't exist or
    // doesn't have a phone number, return false. Otherwise, if the tax entity exists and has a
    // phone number, return that string
    return entityPromise.then(function (_ref) {
      var number = _ref.phone_number;
      return number || false;
    }).catch(function () {
      return false;
    });
  };

  this.getContactEmail = function () {
    if (scCampaignsService.active.current.contact_email) {
      return $q.resolve(scCampaignsService.active.current.contact_email);
    }

    var entityPromise = !_.isEmpty(scTaxEntitiesService.currentTaxableEntity) ? $q.resolve(scTaxEntitiesService.currentTaxableEntity) : scTaxEntitiesService.getDefaultTaxEntity(scCampaignsService.active.current);

    return entityPromise.then(function (_ref2) {
      var email = _ref2.email_address;
      return email || scOrganizationsService.active.current.email_address || '';
    }).catch(function () {
      return scOrganizationsService.active.current.email_address || '';
    });
  };

  this.getContactPhoneNumber = function () {
    if (scCampaignsService.active.current.contact_phone) {
      return $q.resolve(scCampaignsService.active.current.contact_phone);
    } else {
      return $q.resolve(null);
    }
  };

  this.getContact = function () {
    return $q.all([this.getPhoneNumber(), this.getContactEmail()]);
  };
}

angular.module('classy').service('orgContactService', orgContactService);
})();