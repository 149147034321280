(function(){
'use strict';

function RGBFactory() {
  function hex(rgb) {
    var color = tinycolor(rgb);
    return color.getAlpha() == 1 ? color.toHexString() : color.toHex8String();
  }

  function RGB(hexcode) {
    this.hex = hexcode;

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexcode);

    if (result) {
      this.r = parseInt(result[1], 16);
      this.g = parseInt(result[2], 16);
      this.b = parseInt(result[3], 16);
    }
  }

  RGB.prototype.rgbString = function () {
    return this.r + ',' + this.g + ',' + this.b;
  };

  function pad(num, totalChars) {
    var padding = '0';
    var result = '' + num;
    while (result.length < totalChars) {
      result = padding + result;
    }

    return result;
  }

  function changeColor(color, ratio, darker) {
    var replacedColor = color
    // Trim trailing/leading whitespace
    .replace(/^\s*|\s*$/, '')
    // Expand three-digit hex
    .replace(/^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i, '#$1$1$2$2$3$3');

    // Calculate ratio
    var difference = Math.round(ratio * 256) * (darker ? -1 : 1),

    // Determine if input is RGB(A)
    rgb = replacedColor.match(new RegExp('^rgba?\\(\\s*' + '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' + '\\s*,\\s*' + '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' + '\\s*,\\s*' + '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' + '(?:\\s*,\\s*' + '(0|1|0?\\.\\d+))?' + '\\s*\\)$', 'i')),
        alpha = !!rgb && rgb[4] !== null ? rgb[4] : null,

    // Convert hex to decimal
    decimal = rgb ? [rgb[1], rgb[2], rgb[3]] : replacedColor.replace(/^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i,
    // eslint-disable-next-line no-unused-vars
    function (_, first, second, third) {
      return parseInt(first, 16) + ',' + parseInt(second, 16) + ',' + parseInt(third, 16);
    }).split(/,/);

    // Return RGB(A)
    return rgb ? 'rgb' + (alpha !== null ? 'a' : '') + '(' + Math[darker ? 'max' : 'min'](parseInt(decimal[0], 10) + difference, darker ? 0 : 255) + ', ' + Math[darker ? 'max' : 'min'](parseInt(decimal[1], 10) + difference, darker ? 0 : 255) + ', ' + Math[darker ? 'max' : 'min'](parseInt(decimal[2], 10) + difference, darker ? 0 : 255) + (alpha !== null ? ', ' + alpha : '') + ')' : // Return hex
    ['#', pad(Math[darker ? 'max' : 'min'](parseInt(decimal[0], 10) + difference, darker ? 0 : 255).toString(16), 2), pad(Math[darker ? 'max' : 'min'](parseInt(decimal[1], 10) + difference, darker ? 0 : 255).toString(16), 2), pad(Math[darker ? 'max' : 'min'](parseInt(decimal[2], 10) + difference, darker ? 0 : 255).toString(16), 2)].join('');
  }

  RGB.prototype.lighterColor = function (ratio) {
    return changeColor(this.rgbString(), ratio, false);
  };

  RGB.prototype.darkerColor = function (ratio) {
    return changeColor(this.rgbString(), ratio, true);
  };

  RGB.prototype.toHex = function (rgb) {
    return hex(rgb);
  };

  return RGB;
}

angular.module('classy').factory('RGB', RGBFactory);
})();