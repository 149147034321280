(function(){
'use strict';

function scAutofocus() {
  return {
    require: ['ngModel', '^form'],
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ctrls) {
      var ngModelCtrl = ctrls[0];
      var formCtrl = ctrls[1];
      var autofocusIndex = _.parseInt(attrs.scAutofocus);

      function $autofocus(dest) {
        var destField = dest;
        if (_.isString(dest)) {
          destField = formCtrl.$autofocusFields[dest];
        }
        if (_.isNumber(destField)) {
          var $el = angular.element('[sc-autofocus=' + destField + ']');
          $el.focus();
          if ($el.is('input')) {
            var len = $el.val().length;
            $el[0].setSelectionRange(len, len);
          }
        }
      }

      formCtrl.$autofocus = $autofocus;
      formCtrl.$autofocusFields = formCtrl.$autofocusFields || {};
      formCtrl.$autofocusFields[ngModelCtrl.$name] = autofocusIndex;

      ngModelCtrl.$viewChangeListeners.push(function () {
        if (ngModelCtrl.$valid && ngModelCtrl.$dirty) {
          formCtrl.$autofocus(autofocusIndex + 1);
        }
      });
    }
  };
}

angular.module('classy').directive('scAutofocus', scAutofocus);
})();