(function(){
'use strict';

/**
 * @kind factory
 * @name scField
 *
 * @description
 * Shared factory for all sc{X}Field directives. Transforms the directive
 * definition object of a directive in the following ways:
 *
 * * Coerces scope to true
 * * Places the passed object on the new child scope at $scope.options
 * * Fetches template automatically based on name
 * * Interpolates the template against the options object prior to compilation
 */

scFieldFactory.$inject = ["$timeout", "decorateDirective", "scQaId"];
function scFieldFactory($timeout, decorateDirective, scQaId) {
  function scField(name, defObj) {
    return decorateDirective(defObj, {
      scope: true,
      precompile: {
        context: name,
        contextName: 'options',
        templateUrl: 'global/directives/scField/' + name + '/template'
      },
      link: {
        post: function post(scope, element) {
          $timeout(function () {
            var formCtrl = element.closest('form').controller('form');
            var controls = element.find('[ng-model], [data-ng-model]');
            controls.each(function () {
              var control = angular.element(this).controller('ngModel');
              if (!_.isUndefined(formCtrl)) {
                formCtrl.$addControl(control);
              }
            });
          });
          if (SC.campaign && SC.campaign.campaignTemplateData) {
            if (name === 'scImgListField') {
              var componentItems = _.get(SC.campaign.campaignTemplateData, 'components.block.component_items', []);

              if (componentItems.length) {
                var teamPage = componentItems.find(function (item) {
                  return item.value.type === 'team';
                });
                if (teamPage) {
                  scope.lockBgImgField = teamPage.item_attributes.background.locked;
                }
              }
            }
          }

          scope._ = _;
          scope.scQaId = scQaId;
        }
      }
    });
  }

  return scField;
}

angular.module('classy').factory('scField', scFieldFactory);
})();