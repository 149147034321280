(function(){
'use strict';

/**
 * @ngDoc directive
 * @name scSvgImage
 *
 * @description
 * Bind the fill of an svg object to the model.
 */

scSvgImage.$inject = ["$location", "$q", "scImg", "scSvgUtils"];
function scSvgImage($location, $q, scImg, scSvgUtils) {
  return function (scope, element, attrs) {
    var UNIQID = _.uniqueId();

    var model = attrs.scSvgImage;
    var resizeMode = attrs.mode;
    var alignX = attrs.alignX || 'left';
    var alignY = attrs.alignY || 'middle';
    var width = attrs.width;
    var height = attrs.height;
    var containerDim = {
      width: width,
      height: height
    };

    var canvas = Chartist.Svg(element[0]);
    var group = Chartist.Svg(element.closest('svg')[0]).elem('defs', {}, '', true).elem('g', { id: 'IMAGE_' + UNIQID });

    scope.$watch(function () {
      if (!scImg.isInstance(_.get(scope, model))) {
        return null;
      }
      return _.get(scope, model).src;
    }, function (newVal) {
      if (newVal === null) {
        renderLoop([]);
      } else {
        renderLoop([_.get(scope, model)]);
      }
    });

    function renderLoop(d) {
      // set the <use> tag so that it's 'linked' to the SVG 'g' element we added to defs
      // This will link the two elements, so any changes which occur to the 'g' element will happen
      // in our canvas. In this way, we update the contents of the 'g' element to point to the image
      // we wish to use, and then the <use> tag (which is placed in the proper location in the
      // markup) is updated.
      canvas.attr({
        'xlink:href': $location.absUrl() + '#IMAGE_' + UNIQID
      });

      // save any existing image tags, need to do this before adding in any new items
      var oldImages = group.querySelectorAll('.sc-svg-image-' + UNIQID);

      // add any new images to the page, if any
      d.map(formatImage).forEach(group.append.bind(group));

      if (oldImages) {
        // remove the old images so that we only have the new one(s)
        oldImages.svgElements.forEach(function (svgImg) {
          svgImg.remove();
        });
      }
    }

    function formatImage(scImageInstanceData) {
      // Create a new svg image and add the proper class for selection later
      var svgImg = Chartist.Svg('image', {}, 'sc-svg-image-' + UNIQID);

      // Listen to the image data callback to ensure that the image data has been saved, then
      // use the available data to generate the proper attributes for the svgImg.
      $q.all([scImageInstanceData.getDimensions(), scImageInstanceData.on('save')]).then(function () {
        var imgDim = {
          width: scImageInstanceData.asset.width,
          height: scImageInstanceData.asset.height
        };

        var resized = scSvgUtils.resizeRelative(imgDim, containerDim, resizeMode, alignX, alignY);

        svgImg.attr({
          'xlink:href': scImageInstanceData.src,
          width: resized.width,
          height: resized.height,
          x: resized.x,
          y: resized.y
        });
      });

      // Waiting on the image data is asynchronous, but we're going to return this now so that the
      // element can immediately be appended into the DOM. It won't have anything to show until the
      // async operation completes, but this solves some potential race conditions in renderLoop
      // being called (and removing all svg images) before all of the async code has finished
      // executing
      return svgImg;
    }
  };
}

angular.module('classy').directive('scSvgImage', scSvgImage);
})();