(function(){
'use strict';

angular.module('classy').component('teamLeaderboard', {
  template: '\n\n    <div class="team-leaderboard__container">\n      <img class="team-leaderboard__loading"\n      ng-if="!$ctrl.leaders.length"\n      sc-cdn-src="/static/global/images/preloader.gif" />\n\n      <div class="team-leaderboard">\n        <div data-ng-repeat="(key, value) in $ctrl.leaders track by $index"\n          data-sc-supporter-leader="value"\n          data-leaderboard="true"\n          data-theme="theme"\n          data-bc="team-block"\n          data-mobile="isMobile"\n          placeholder="value.placeholder"\n          data-img-size="$ctrl.leaderImgSize">\n        </div>\n      </div>\n    </div>\n  ',
  bindings: {
    team: '=',
    styleClass: '<?',
    demo: '<?',
    updateTotalMembers: '&?'
  },

  controller: ["$window", "$q", "$http", "teamBlockDemoData", function TeamLeaderboardCtrl($window, $q, $http, teamBlockDemoData) {
    var _this = this;

    this.$onInit = function () {
      if (Modernizr.prefixed('matchMedia', window)) {
        _this.isMobile = Modernizr.mq('(max-width: 568px)');
      } else {
        _this.isMobile = angular.element($window).width() <= 568;
      }

      _this.leaderImgSize = _this.isMobile ? 60 : 108;

      // Promise that will resolve once we have the total number of fundraising
      // pages which belong to this team. We need this resolved before we
      // attempt to fetch the "Most Active" and "Weekly Winner" page
      _this.pageCountPromise = $q.defer();
      _this.totalMembers = 0;

      _this.leaders = [];
      _this.generateLeaders();
    };

    /*
     * Generates the leaderboard array.
     */
    this.generateLeaders = function () {
      if (_this.demo) {
        _this.leaders = teamBlockDemoData.leaders;
        return;
      }

      // Get MOST RAISED & MOST DONATIONS
      _this.getTopPages();

      _this.getWeeklyWinner();
      _this.getMostActive();
    };

    /* ---------------------------------------------------------------- *
     * Most Raised Fundraiser & Most Donations Fundraiser
     * ---------------------------------------------------------------- */
    this.getTopPages = function () {
      var topRaisedMeta = {
        title: 'Most Raised',
        type: 'fundraiser',
        measure: '$',
        metric: 'Raised'
      };

      var topDonationsMeta = {
        title: 'Most Donors',
        type: 'fundraiser',
        measure: '',
        metric: 'Donors'
      };

      $http.get('frs-api/fundraising-teams/' + _this.team.id + '/top-pages').then(function (response) {
        var data = response.data;
        var total = data.total,
            _data$data = data.data,
            mostRaised = _data$data.mostRaised,
            mostDonations = _data$data.mostDonations;

        // call the parent controller's function to update its scope.totalMembers variable

        _this.totalMembers = total;

        if (_this.updateTotalMembers) {
          _this.updateTotalMembers({ totalMembers: total });
        }
        _this.pageCountPromise.resolve();

        // Get page with most donations.
        var numDonations = mostDonations.transactions_count;
        var topDonations = Object.assign(mostDonations, { placeholder: !numDonations });

        _this.leaders.push(Object.assign({ logo: { cdn_url: topDonations.fundraiser_profile_image } }, topDonations, topDonationsMeta));

        // Get page with most fundraised.
        var amountRaised = mostRaised.total_raised_raw;
        var topRaised = Object.assign(mostRaised, { placeholder: !amountRaised });

        _this.leaders.push(Object.assign({ logo: { cdn_url: topRaised.fundraiser_profile_image } }, topRaised, topRaisedMeta));
      }).catch(function () {
        _this.pageCountPromise.resolve();

        // Set placeholders in the leaders list
        if (!_this.totalMembers) {
          _this.leaders.push(Object.assign({}, topRaisedMeta));
          _this.leaders.push(Object.assign({}, topDonationsMeta));
        }
      });
    };

    /* ---------------------------------------------------------------- *
     * Most Active Fundraiser
     * ---------------------------------------------------------------- */
    this.getMostActive = function () {
      var mostActive = {
        title: 'Most Activity',
        type: 'fundraiser',
        measure: '',
        placeholder: true
      };

      $http.get('/frs-api/fundraising-teams/' + _this.team.id + '/most-active-page').then(function (_ref) {
        var data = _ref.data;

        if (data && !_.isEmpty(data.mostActive)) {
          var metric = data.mostActive.total_comments === 1 ? 'Comment' : 'Comments';
          Object.assign(mostActive, data.mostActive, {
            placeholder: false,
            metric: metric,
            logo: { cdn_url: data.mostActive.logo_url }
          });
        }

        _this.leaders.push(mostActive);
      }).catch(function () {
        _this.leaders.push({
          title: 'Most Activity',
          type: 'fundraiser',
          measure: '',
          metric: 'Comments',
          placeholder: true
        });
      });
    };

    /* ---------------------------------------------------------------- *
     * Weekly Winner
     * ---------------------------------------------------------------- */
    this.getWeeklyWinner = function () {
      $http.get('/frs-api/fundraising-teams/' + _this.team.id + '/top-page/week').then(function (_ref2) {
        var data = _ref2.data;

        var weeklyWinner = {
          title: 'Weekly Winner',
          type: 'fundraiser',
          measure: '$',
          metric: 'raised this week',
          placeholder: true
        };

        if (data && !_.isEmpty(data.topPage)) {
          Object.assign(weeklyWinner, data.topPage, {
            placeholder: false,
            weekly_raised: data.topPage.total_raised_in_interval,
            logo: { cdn_url: data.topPage.logo_url }
          });
        }

        _this.leaders.push(weeklyWinner);
      }).catch(function () {
        _this.leaders.push({
          title: 'Weekly Winner',
          type: 'fundraiser',
          measure: '$',
          metric: 'raised this week',
          placeholder: true
        });
      });
    };
  }]
});
})();