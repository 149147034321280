(function(){
'use strict';

angular.module('classy').component('currencyFieldRf', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      ng-if="$ctrl.label"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n      <input class="form__text-input--dark"\n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      ng-required="$ctrl.required"\n      sc-currency\n      sc-validity-reporter\n      sc-validity-classes\n      symbol="{{$ctrl.symbol}}"\n      round-currency="$ctrl.roundCurrency"\n      ng-disabled="$ctrl.disableFlag"\n      max="$ctrl.max"\n      min="$ctrl.min"\n      type="string"\n      inputmode="numeric"\n      autocomplete="off"\n      name="{{:: $ctrl.name }}"\n      placeholder="{{:: $ctrl.placeholder }}"\n      ng-change="$ctrl.onChange()"\n      ng-blur="$ctrl.onFocus = false"\n      ng-style="$ctrl.selected ? $ctrl.changeInputStyle() : $ctrl.defaultStyle()"\n      qa-id="{{:: $ctrl.scQaId.singleLineTextInput }}"\n      aria-label="{{:: $ctrl.currencyAriaLabel }}"\n      _spec="input" />\n      <div style="position: absolute;\n      width: 100%;\n      left: 0;\n      text-align: left;" aria-live="polite">\n        <form-error\n        name="$ctrl.name"\n        messages="$ctrl.errorMessages"\n        _spec="errors"></form-error>\n      </div>\n    ',
  bindings: {
    model: '=',
    disableFlag: '=?',
    name: '<',
    currencyCode: '<?',
    label: '<',
    placeholder: '<',
    errorMessages: '<?',
    getterSetter: '<?',
    roundCurrency: '<?',
    currencyAriaLabel: '@?',
    modalBindValue: '=?',
    selected: '<?'
  },
  require: {
    form: '^form'
  },
  controller: ["$element", "$scope", "$filter", "scCurrencyService", "scQaId", "scThemesService", function CurrencyFieldCtrl($element, $scope, $filter, scCurrencyService, scQaId, scThemesService) {
    var _this = this;

    function DEFAULTS() {
      return {
        disabled: false,
        min: 1,
        max: 10000000,
        errorMessages: {
          min: 'Please enter an amount greater than or equal to 1.',
          max: 'Please enter an amount smaller than or equal to 10,000,000.',
          required: 'Please enter an amount.',
          parse: 'Please enter a valid currency amount.'
        },
        getterSetter: false,
        required: false,
        roundCurrency: true
      };
    }

    this.scQaId = scQaId;

    this.$onInit = function () {
      if (_this.modalBindValue > 0) {
        setTimeout(function () {
          _this.form.amount.$setValidity('min', true);
          _this.form.amount.$setValidity('max', true);
        }, 50);
      }
      _.defaultsDeep(_this, DEFAULTS.call(_this));
      _this.setupSelectListener();

      if (_this.currencyCode) {
        _this.symbol = scCurrencyService.getCurrencySymbol(_this.currencyCode);
      }
    };

    this.setupSelectListener = function () {
      if (_this.selectOn) {
        $scope.$on(_this.selectOn, function () {
          $element.find('input').focus();
          $element.find('input').select();
        });
      }
    };
    this.onChange = function () {
      if (_this.model > 0) {
        _this.modalBindValue = _this.model;
        _this.form.amount.$setValidity('required', true);
        _this.form.amount.$setValidity('max', true);
        _this.form.amount.$setValidity('min', true);
      } else if (!_this.model) {
        _this.form.amount.$setValidity('required', false);
      }
    };

    $scope.$on('selectedAmountChange', function (_evt, convertedAmount) {
      _this.model = convertedAmount > 0 ? convertedAmount : '';
    });

    this.defaultStyle = function () {
      return {
        'border-color': $filter('adjustColorOpacity')(scThemesService.active.current.styles.primaryColor, 0.12)
      };
    };

    this.changeInputStyle = function () {
      var styles = {
        'border-color': scThemesService.active.current.styles.primaryColor
      };
      return styles;
    };
  }]
});
})();