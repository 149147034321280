(function(){
'use strict';

angular.module('classy').component('textareaField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      ng-if="$ctrl.label"\n      _spec="label">        \n        {{:: $ctrl.label }}      \n      </form-label>\n\n      <div ng-if="$ctrl.lockField"\n        data-sc-tooltip-class="admin-campaign-tooltip"\n        data-sc-tooltip-position="center"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        data-sc-tooltip="\'This field is locked at the template level\'"\n        class="admin-body__tooltip-element locked-field-overlay"\n      ></div>\n\n      <div class="form__text-input__outer">\n        <textarea class="form__text-area"\n          ng-model="$ctrl.model"\n          ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n          ng-required="$ctrl.required"\n          sc-validity-reporter\n          sc-validity-classes\n          maxlength="{{ $ctrl.maxlength }}"\n          ng-maxlength="{{ $ctrl.maxlength }}"\n          ng-disabled="$ctrl.disabled"\n          name="{{:: $ctrl.name }}"\n          placeholder="{{:: $ctrl.placeholder }}"\n          qa-id="{{:: $ctrl.scQaId.textAreaField }}"\n          _spec="input"\n          aria-label="{{:: $ctrl.name |replaceUnderscoreWithBlank}} field">\n        </textarea>\n\n        <span class="form__text-area__counter" ng-if="$ctrl.maxlength">\n          <span data-ng-bind="$ctrl.inputLength()"></span>/{{::$ctrl.maxlength}}\n        </span>\n      </div>\n\n      <p class="form__helper" ng-if="$ctrl.description"\n      ng-bind="::$ctrl.description"></p>\n\n      <form-error\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    errorMessages: '<?',
    getterSetter: '<?',
    description: '<?',
    placeholder: '<?',
    required: '<?',
    disabled: '<?',
    maxlength: '<?',
    lockField: '<?'
  },
  controller: ["scQaId", "$attrs", function TextareaFieldCtrl(scQaId, $attrs) {
    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.',
        maxlength: 'Please limit your answer to ' + $attrs.maxlength + ' characters or less'
      },
      getterSetter: false,
      placeholder: '',
      maxlength: false,
      required: false
    };

    this.scQaId = scQaId;
    // This allows the character counter to work as expected with global/services/CQAnswerModel.js
    // which uses an overly complicated function as the model (and still work with ordinary strings)
    this.inputLength = function () {
      var model = this.model instanceof Function ? this.model() : this.model;
      return model ? model.length : 0;
    };
    if (typeof this.lockField === 'undefined' && SC.campaign && SC.campaign.campaignTemplateData) {
      var isLocationBlockLocked = _.get(SC.campaign.campaignTemplateData, 'components.campaign.component_items[0].item_attributes.location_details.locked', false);
      if (isLocationBlockLocked && $attrs.model === 'locationBlock.current.content') {
        this.disabled = 'true';
        this.lockField = true;
      }
    }

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };
  }]
});
})();