(function(){
'use strict';

angular.module('classy').directive('scAllowHtml', function () {
  return function (scope, element, attr) {
    scope.$watch(attr.scAllowHtml, function () {
      element.html(scope.$eval(attr.scAllowHtml));
    });
  };
});
})();