(function(){
'use strict';

scBrandingService.$inject = ["$q", "RGB", "scImg", "scThemesService", "scFundraisingTeamsService", "scFundraisingPagesService", "scBlocksService", "dedicationSettingsService", "scVideoSvc", "scMetaTags"];
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function scBrandingService($q, RGB, scImg, scThemesService, scFundraisingTeamsService, scFundraisingPagesService, scBlocksService, dedicationSettingsService, scVideoSvc, scMetaTags) {
  var branding = {};

  branding.getPrimaryColor = function () {
    var deferred = $q.defer();

    var theme = scThemesService.active.current;
    var primaryColor = theme.styles.primaryColor;
    var primaryHex = new RGB().toHex(primaryColor);

    deferred.resolve(primaryHex);
    return deferred.promise;
  };

  branding.getLogo = function () {
    // returns an scImgAsset
    var deferred = $q.defer();

    var headerBlock = scBlocksService.getBlockByType('header').current;
    var logo = headerBlock.logo;
    if (headerBlock.logo) {
      deferred.resolve(logo);
    } else {
      deferred.reject('no logo found');
    }

    return deferred.promise;
  };

  branding.getEcardSrc = function (id) {
    var deferred = $q.defer();

    dedicationSettingsService.getEcards(id).then(function (response) {
      var ecard = _.get(response, 'data.data[0].image_url', false);
      if (ecard) {
        deferred.resolve(ecard);
      } else {
        deferred.reject('no suitable ecard found');
      }
    }, function () {
      deferred.reject();
    });

    return deferred.promise;
  };

  branding.getLogoSrc = function () {
    return this.getLogo().then(function (logo) {
      if (logo.assetId) {
        return logo.getSrc().then(function (data) {
          return _.get(data, 'data.cdn_url', false);
        });
      }

      return $q.reject();
    });
  };

  branding.getFeaturedImage = function () {
    var deferred = $q.defer();
    var featuredImg = this.getShareableImages({ count: 1 });
    if (featuredImg[0]) {
      deferred.resolve(featuredImg[0]);
    } else {
      deferred.reject('no suitable featured image found');
    }

    return deferred.promise;
  };

  function pluckImages(input, parentKey) {
    var imagesFound = [];

    if ((typeof input === 'undefined' ? 'undefined' : _typeof(input)) == 'object' && _.keys(input).length > 0) {
      if (input && input.assetId) {
        input.propertyOf = parentKey;
        imagesFound = _.union(imagesFound, [input]);
      } else if (input) {
        _.each(input, function (value, key) {
          imagesFound = _.union(imagesFound, pluckImages(value, parentKey + '.' + key));
        });
      }
    }

    return imagesFound;
  }

  branding.getAllImages = function () {
    var blocks = scBlocksService.blocks;
    var images = [];

    _.each(blocks, function (value) {
      var current = value.current;
      var blockImages = pluckImages(current, current.type);
      images = _.union(images, blockImages);
    });

    var campaignShareImage = scMetaTags.getAppealSetData();
    var socialImage = {};

    if (campaignShareImage.facebook_asset_id > 0) {
      socialImage = scImg(campaignShareImage.facebook_asset_id);
    } else if (campaignShareImage.facebook_image_url) {
      socialImage = scImg.fromURL(campaignShareImage.facebook_image_url, 'Facebook');
    }

    if (!_.isEmpty(socialImage)) {
      socialImage.propertyOf = 'campaign.socialShareImage';
      images = _.union(images, [socialImage]);
    }

    return images;
  };

  branding.getShareableImages = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var type = _.get(options, 'type', 'campaign');
    var count = _.get(options, 'count', 4);
    var exclude = _.get(options, 'exclude', 0);

    // shareable rules:
    // CAMPAIGNS:
    // 1. no campaign or footer logos
    // 2. crowdfunding block feature images first
    // 3. crowdfunding block background images
    // 4. impact block impact level images
    // 5. rest of the background images

    // TEAMS:
    // 1. team logo
    // 2. campaign default team logo
    // 3. team header background image
    // 4. campaign selected image
    // 5. campaign crowdfunding block image

    // FUNDRAISERS
    // 1. fundraiser logo
    // 2. campaign default fundraiser logo
    // 3. fundraiser header background image
    // 4. campaign selected image
    // 5. campaign crowdfunding block image

    var allImages = this.getAllImages();
    allImages = _.filter(allImages, function (image) {
      return image.propertyOf.indexOf('.logo') < 0;
    });

    allImages = _.sortBy(allImages, function (image) {
      // rank the images by probablility of being intended for sharing
      var parent = image.propertyOf;
      var score = 0;

      if (parent.indexOf('fundraiser.defaultFundraiserLogo') > -1) {
        score += 21;
      }

      if (parent.indexOf('fundraiser.background') > -1) {
        score += 19;
      }

      if (parent.indexOf('team.defaultTeamLogo') > -1) {
        score += 18;
      }

      if (parent.indexOf('team.background') > -1) {
        score += 16;
      }

      if (parent.indexOf('campaign.socialShareImage') > -1) {
        score += 15;
      }

      if (parent.indexOf('crowdfunding.featuredImg') > -1) {
        score += 10;
      }

      if (parent.indexOf('crowdfunding.background') > -1) {
        score += 4;
      }

      if (parent.indexOf('impact.impactLevels') > -1) {
        score += 3;
      }

      if (parent.indexOf('donation.background') > -1) {
        score += 2;
      }

      if (parent.indexOf('background') > -1) {
        score += 1;
      }

      return -score;
    });

    if (exclude) {
      allImages = _.filter(allImages, function (image) {
        return image.assetId != exclude;
      });
    }

    if (type == 'campaign') {
      allImages = _.filter(allImages, function (image) {
        return image.propertyOf.indexOf('fundraiser') < 0 && image.propertyOf.indexOf('team') < 0;
      });
    } else if (type == 'team') {
      allImages = _.filter(allImages, function (image) {
        return image.propertyOf.indexOf('fundraiser') < 0;
      });

      var teamLogoAsset = _.get(scFundraisingTeamsService, 'active.current.logo.asset', false);
      if (teamLogoAsset) {
        var teamLogo = [scImg.instantiate(teamLogoAsset)];
        allImages = teamLogo.concat(allImages);
      }
    } else if (type == 'fundraiser') {
      allImages = _.filter(allImages, function (image) {
        return image.propertyOf.indexOf('team') < 0;
      });

      var frLogoAsset = _.get(scFundraisingPagesService, 'active.current.logo.asset', false);
      if (frLogoAsset) {
        var fundraiserLogo = [scImg.instantiate(frLogoAsset)];
        allImages = fundraiserLogo.concat(allImages);
      }
    } else if (type == 'donation') {
      allImages = _.filter(allImages, function (image) {
        return image.propertyOf.indexOf('donation') > -1;
      });
    }

    allImages = _.slice(allImages, 0, count);
    return allImages;
  };

  branding.getShareImage = function (pageType) {
    var image = '';
    var firstChoice = branding.getShareableImages({ type: pageType })[0];
    var sizes = _.get(firstChoice, 'asset.sizes', {});

    if (!_.isEmpty(sizes)) {
      _.forEach(sizes, function (src, size) {
        if (size >= 500) {
          image = src;
          return false;
        }

        image = firstChoice.src;
        return undefined;
      });
    }

    return image;
  };

  branding.getSharableMedia = function () {
    // todo: possibly could be refatored in favor of getShareableImages,
    // right now it will autopick a video if an image is not found
    var deferred = $q.defer();

    var crowdfundingBlock = _.get(scBlocksService.getBlockByType('crowdfunding'), 'current', false);

    var sharableMedia = { type: false, src: false };

    function setMedia(type, src) {
      sharableMedia.type = type && src ? type : false;
      sharableMedia.src = src;

      deferred.resolve(sharableMedia);
    }

    if (crowdfundingBlock) {
      var videoUrl = scVideoSvc.getVideoUrl(crowdfundingBlock.featuredVideo);
      if (videoUrl) {
        setMedia('video', videoUrl);
      } else {
        // try to find an image
        this.getFeaturedImage().then(function (response) {
          if (response.assetId) {
            var sizes = _.get(response, 'asset.sizes', {});

            if (!_.isEmpty(sizes)) {
              _.forEach(sizes, function (src, size) {
                if (size >= 500) {
                  setMedia('image', src);
                  return false;
                }

                return true;
              });
            }

            deferred.resolve(sharableMedia);
          } else {
            deferred.reject(sharableMedia);
          }
        }, function () {
          deferred.reject(sharableMedia);
        });
      }
    } else {
      deferred.reject(sharableMedia);
    }

    return deferred.promise;
  };

  return branding;
}

angular.module('classy').factory('scBrandingService', scBrandingService);
})();