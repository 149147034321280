(function(){
'use strict';

angular.module('classy').component('colorPickerField', {
  template: '\n      <div class="{{$ctrl.styleClass}}">\n        <label class="form_label"\n        ng-if="$ctrl.label"\n        _spec="label">{{::$ctrl.label }}</label>\n\n        <p class="form_helper">{{::$ctrl.description}}</p>\n        <div data-ng-class="{ \'admin-body__lock-field\': $ctrl.lockField }"> \n          <div ng-if="$ctrl.lockField" data-ng-style="$ctrl.colorPickerTootipStyle"\n          data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n          data-sc-tooltip-position="center"\n          data-sc-tooltip-position-tip="bottom center"\n          show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n          fixed-tooltip="true"\n          data-sc-tooltip="\'This field is locked at the template level.\'"\n          class="form_togglegroup-toggle admin-body__tooltip-element"></div>\n          \n          <div class="form_colorpicker"\n          data-sc-spectrum="$ctrl.model"\n          data-fallback-color="$ctrl.fallback"\n          data-swatch-size="{{ ::$ctrl.swatchSize }}"\n          data-footer-bg-color="$ctrl.footerBgColor"\n          data-about-bg-color="$ctrl.aboutBgColor"  \n          data-location-bg-color="$ctrl.locationBgColor"\n          data-remove-button="$ctrl.removeButton"></div>\n        </div>\n      </div>\n    ',

  bindings: {
    model: '=',
    fallback: '<?',
    swatchSize: '<?',
    label: '<?',
    description: '<?',
    required: '<?',
    styleClass: '<?',
    removeButton: '<?',
    lockField: '<?',
    footerBgColor: '=?',
    aboutBgColor: '=?',
    locationBgColor: '=?'
  },

  controller: ["$attrs", "$stateParams", "scCampaignsTemplateService", function ColorPickerFieldCtrl($attrs, $stateParams, scCampaignsTemplateService) {
    var DEFAULTS = {
      fallback: 'black',
      swatchSize: 25,
      errorMessages: {
        required: 'This field is required.'
      },
      required: false
    };

    this.$onInit = function () {
      var _this = this;

      _.defaultsDeep(this, DEFAULTS);
      if (this.styleClass) {
        this.styleClass += '-colorpicker';
      } else {
        this.styleClass = 'form_field color-picker';
      }

      this.colorPickerTootipStyle = {
        top: '-20%',
        width: '18%',
        right: 0,
        height: '60px'
      };

      this.getLockedField = function (type) {
        if (typeof _this.lockField !== 'undefined') return _this.lockField;
        var lockedBlock = scCampaignsTemplateService.getBlockByType(type);
        var fieldName = $attrs.model.split('.').splice(-1)[0];
        var lock = void 0;
        if ($attrs.model.includes('block.background.color')) {
          lock = lockedBlock.item_attributes.background.color && lockedBlock.item_attributes.background.color.locked;
        } else if ($attrs.model.includes('top.text.color')) {
          lock = lockedBlock.item_attributes.top.text.color && lockedBlock.item_attributes.top.text.color.locked;
        } else {
          lock = lockedBlock.item_attributes[fieldName] && lockedBlock.item_attributes[fieldName].locked;
        }
        return lock;
      };

      if (SC.campaign && SC.campaign.campaignTemplateData) {
        var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
        if (blockObj) {
          this.lockField = this.getLockedField(blockObj.type);
        }

        if ($attrs.model === 'theme.styles.background.color') {
          var lockHeroBlock = scCampaignsTemplateService.getBlockByType('crowdfunding');
          this.lockField = _.get(lockHeroBlock, 'item_attributes.background.image.locked', false);
        }

        if ($attrs.model === 'theme.current.styles.primaryColor') {
          this.lockField = _.get(SC.campaign.campaignTemplateData, 'components.theme.component_items[0].item_attributes.styles.primaryColor.locked', false);
        }
      }
    };
  }]
});
})();