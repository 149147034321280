(function(){
'use strict';

/**
 * @ngDoc service
 * @name scFundraisingPageDemo
 *
 * @description
 * Demo data for the scFundraisingPageModel.
 */
angular.module('classy').factory('scFundraisingPageDemo', ['scOrganizationsService', 'scCampaignsService', function (scOrganizationsService, scCampaignsService) {
  return function () {
    return {
      // These values are null so they can be overridden by the campaign
      // defaults in the setup/design phase
      goal: null,
      raw_goal: scCampaignsService.active.current.default_page_goal,
      intro_text: null,
      logo: null,
      cover_photo: null,

      // Everything else can be customized as marketing sees fit
      alias: "Fundraiser's Name",
      average_donation: 15,
      campaign_id: scCampaignsService.active.current.id,
      designation_id: 1,
      ended_at: moment().add(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
      team: {
        description: '',
        goal: 3000,
        raw_goal: 3000,
        id: 1,
        name: "Team's Name",
        campaign: scCampaignsService.active.current.id,
        total_raised: 450,
        raw_currency_code: scCampaignsService.active.current.raw_currency_code || scOrganizationsService.active.current.currency
      },
      fundraising_team_id: 7,
      id: 1,
      largest_donation: 0,
      member_email_text: 'Aliquam malesuada suscipit lacus et convallis. Ut egestas lobortis varius. Phasellus quis hendrerit mauris. Curabitur varius erat euismod tortor congue porta. Praesent at consequat mi. Nullam velit orci, consectetur sed finibus non, blandit sed dui. Aliquam non metus semper metus maximus dapibus at in nulla. In mi augue, aliquet vitae diam eu, sodales egestas nulla. Nunc ex dui, congue eget enim vitae, vulputate consequat metus. Vestibulum lacinia et ante sit amet ullamcorper. Maecenas fermentum rutrum neque, quis molestie ante congue vitae. Vivamus faucibus, elit eget eleifend pretium, eros elit dictum tellus, quis tempor leo dui a erat. Integer at ipsum eget urna commodo porttitor id ac mi. Nunc lacinia accumsan ultrices. Duis cursus nunc eget interdum volutpat. Donec scelerisque nibh ut lorem ullamcorper viverra.',
      member_id: 1,
      organization_id: scOrganizationsService.active.current.id,
      percent_to_goal: 50,
      raw_currency_code: scCampaignsService.active.current.raw_currency_code || scOrganizationsService.active.current.currency,
      started_at: moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'),
      status: 'active',
      thankyou_email_text: 'Quisque tincidunt, justo in efficitur sollicitudin, neque sem tincidunt turpis, ac semper mi augue quis mi. Ut ac fringilla lorem. Integer eu est eget massa malesuada vehicula. Sed eu placerat justo. Vestibulum et diam in turpis vulputate ornare. Phasellus nec nisi a est commodo elementum ac id urna. Aenean auctor sem non mauris convallis euismod. Nullam ex odio, pharetra vel mi sollicitudin, vehicula blandit ligula. Integer mauris eros, lacinia at turpis at, posuere vulputate libero. Proin ultrices consequat sem eu bibendum. Integer nec odio molestie, blandit velit a, mattis metus. Pellentesque mauris orci, imperdiet id augue eu, tempus posuere lacus. Vivamus in facilisis quam. Vivamus tempor, massa vitae consectetur consectetur, nunc erat scelerisque lorem, ut dictum magna dolor ac lorem.',
      title: "Fundraiser's Name",
      total_donations: 20,
      total_donors: 18,
      total_raised: 300
    };
  };
}]);
})();