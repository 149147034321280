(function(){
'use strict';

ClassyPayFormAPIFactory.$inject = ["$q", "$rootScope", "$timeout", "$window", "$http", "cpAPI", "bugsnagClient"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PAYMENT_METHODS = {
  PLAID: 'ACH',
  PAYPAL: 'PayPal',
  STRIPE: 'Stripe',
  TOKENEX: 'tokenex'
};

var STATUS = {
  PENDING: 'pending',
  READY: 'ready',
  SUCCESS: 'success',
  FAILURE: 'failure',
  HIDDEN: 'hidden'
};

var EVENTS = {
  PLAID_SUCCESS: 'PLAID_SUCCESS',
  PLAID_CANCEL: 'PLAID_CANCEL',
  PLAID_FAILURE: 'PLAID_FAILURE',
  PAYPAL_SUCCESS: 'PAYPAL_SUCCESS',
  PAYPAL_CANCEL: 'PAYPAL_CANCEL',
  PAYPAL_FAILURE: 'PAYPAL_FAILURE',
  STRIPE_SUCCESS: 'STRIPE_SUCCESS',
  STRIPE_CANCEL: 'STRIPE_CANCEL',
  STRIPE_FAILURE: 'STRIPE_FAILURE'
};

function ClassyPayFormAPIFactory($q, $rootScope, $timeout, $window, $http, cpAPI, bugsnagClient) {
  var ClassyPayFormAPI = function () {
    function ClassyPayFormAPI() {
      var _this = this;

      _classCallCheck(this, ClassyPayFormAPI);

      this.element = null;
      this.callbacks = {};

      this.paypal = {
        status: STATUS.PENDING,
        nonce: null
      };

      this.stripe = {
        status: STATUS.PENDING,
        publishableKey: ''
      };

      this.plaid = {
        status: STATUS.PENDING,
        linkToken: null
      };

      this.STATUS = STATUS;
      this.PAYMENT_METHODS = PAYMENT_METHODS;
      this.EVENTS = EVENTS;

      this.clientUserId = (SC.member.id || SC.heapId).toString(); // Converting id to string as required

      this.getProcessors().then(function (processors) {
        _this.processors = processors;
        if (_this.processors[PAYMENT_METHODS.STRIPE]) _this.setupStripe();
        if (_this.processors[PAYMENT_METHODS.PAYPAL]) _this.setupPaypal();
        if (_this.processors[PAYMENT_METHODS.PLAID]) _this.setupPlaid();
      });
    }

    /* Setup
    ========================================================= */


    _createClass(ClassyPayFormAPI, [{
      key: 'setupStripe',
      value: function setupStripe() {
        var _this2 = this;

        cpAPI.getStripeData().then(function () {
          var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
              publishableKey = _ref.publishableKey;

          _this2.stripe.publishableKey = publishableKey;
          _this2.stripe.status = STATUS.READY;
        }).catch(bugsnagClient.notify);
      }
    }, {
      key: 'setupPaypal',
      value: function setupPaypal() {
        var _this3 = this;

        cpAPI.getBraintreeToken().then(function (token) {
          braintree.setup(token, 'custom', {
            paypal: {
              container: 'btPaypal',
              onCancelled: function onCancelled() {
                _this3.paypal.status = STATUS.READY;
                _this3.paypal.nonce = null;
                _this3.emit(EVENTS.PAYPAL_CANCEL);
              }
            },
            onPaymentMethodReceived: function onPaymentMethodReceived(params) {
              _this3.reloadTokenexIframe();
              _this3.paypal.status = STATUS.SUCCESS;
              _this3.paypal.nonce = params.nonce;
              _this3.emit(EVENTS.PAYPAL_SUCCESS, params);
            }
          });
          _this3.paypal.status = STATUS.READY;
        });
      }
    }, {
      key: 'setupPlaid',
      value: function setupPlaid() {
        var _this4 = this;

        cpAPI.getPlaidToken(this.clientUserId).then(function (_ref2) {
          var linkToken = _ref2.linkToken;

          _this4.plaid.linkToken = linkToken;
          _this4.plaid.status = STATUS.READY;
        }).catch(bugsnagClient.notify);
      }

      /* Event Management
      ========================================================= */

    }, {
      key: 'on',
      value: function on(event, cb) {
        var cbs = _.get(this.callbacks, event, []);
        cbs.push(cb);
        _.set(this.callbacks, event, cbs);
      }
    }, {
      key: 'once',
      value: function once(event, cb) {
        cb.once = true;
        this.on(event, cb);
      }
    }, {
      key: 'emit',
      value: function emit(event) {
        var _this5 = this;

        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        console.log(event); // eslint-disable-line no-console
        $timeout(function () {
          var cbs = _.get(_this5.callbacks, event, []);
          _.forEach(cbs, function (cb) {
            return cb.apply(undefined, args);
          });
          _.remove(cbs, 'once');
        });
      }

      /* Helpers
      ========================================================= */

    }, {
      key: 'getProcessors',
      value: function getProcessors() {
        if ($window.SC.processors) return $q.resolve($window.SC.processors);
        return $http.get('/frs-api/pay/processors?appId=' + cpAPI.getApplicationId()).then(function (response) {
          return response.data;
        });
      }
    }, {
      key: 'reloadTokenexIframe',
      value: function reloadTokenexIframe() {
        $rootScope.$broadcast('tokenizer', 'reset');
      }
    }, {
      key: 'openPlaidDialog',
      value: function openPlaidDialog() {
        var linkToken = this.plaid.linkToken;


        Plaid.create({
          product: ['auth'],
          clientName: 'Classy Pay',
          selectAccount: true,
          token: linkToken,
          onSuccess: this.onPlaidSuccess.bind(this),
          onExit: this.onPlaidCancel.bind(this)
        }).open();
      }
    }, {
      key: 'onPlaidSuccess',
      value: function onPlaidSuccess(publicToken, metadata) {
        this.reloadTokenexIframe();
        this.emit(EVENTS.PLAID_SUCCESS, {
          publicToken: publicToken,
          accountId: metadata.account_id,
          accountName: metadata.account.name
        });

        this.plaid.status = STATUS.SUCCESS;
      }
    }, {
      key: 'onPlaidCancel',
      value: function onPlaidCancel() {
        this.emit(EVENTS.PLAID_CANCEL);
        this.plaid.status = STATUS.READY;
      }
    }]);

    return ClassyPayFormAPI;
  }();

  ClassyPayFormAPI.STATUS = STATUS;
  ClassyPayFormAPI.PAYMENT_METHODS = PAYMENT_METHODS;
  ClassyPayFormAPI.EVENTS = EVENTS;
  return ClassyPayFormAPI;
}

angular.module('classy').factory('ClassyPayFormAPI', ClassyPayFormAPIFactory);
})();