(function(){
'use strict';

angular.module('classy').constant('scFundraiserBlockDefaults', {
  allowCommenting: true,
  title: 'Fundraiser',
  type: 'fundraiser',
  textColor: 'rgb(44,52,59)',
  defaultFundraiserLogo: null,
  pagesCanSetCoverPhoto: true,
  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  showFundraiserTeam: true,
  showCampaign: true,

  /* Join Button */
  donateButtonColor: null,
  donateButtonStyle: {
    preset: 'rounded',
    'border-radius': '4px',
    color: 'white'
  },

  /* Sidebar */
  showLeaderboard: true,
  showHeadline: true,
  showSubTeams: true,
  showProgressBar: true,

  unsortable: false
});
})();