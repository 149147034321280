(function(){
'use strict';

angular.module('classy').component('checkboxField', {
  template: '\n    <div style="position:relative">\n      <form-label\n      required="$ctrl.required"\n      ng-if="$ctrl.label"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <div ng-if="$ctrl.lockField"\n        data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n        data-sc-tooltip-position="center"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        fixed-tooltip="true"\n        data-sc-tooltip="\'This field is locked at the template level.\'"\n        class="admin-body__tooltip-element"\n      ></div>\n\n      <checkbox-control\n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      ng-required="$ctrl.required"\n      flipped="$ctrl.flipped"\n      sc-validity-reporter\n      sc-validity-classes\n      name="{{:: $ctrl.name }}"\n      _spec="input"\n      data-disabled="$ctrl.disableFlag">\n        {{:: $ctrl.checkboxLabel }}\n      </checkbox-control>\n\n      <form-error\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    </div>\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    disableFlag: '<',
    checkboxLabel: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    flipped: '<?',
    lockField: '<?'
  },
  controller: function CheckboxFieldCtrl() {
    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      required: false
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };
  }
});
})();