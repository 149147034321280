(function(){
'use strict';

angular.module('classy').component('registrationEntitySwitch', {
  template: '\n      <entity-switch-modal\n      title="\'How would you like to register?\'"\n      entities="$ctrl.entities">\n      </entity-switch-modal>\n    ',
  bindings: {
    goToCheckout: '&',
    goToBrowse: '&',
    goToCreate: '&',
    disableIndividualFundraising: '&',
    disableTeamJoin: '&',
    disableTeamCreate: '&'
  },
  controller: ["scCampaignsService", "scCurrencyService", "scAnalytics", function controller(scCampaignsService, scCurrencyService, scAnalytics) {
    this.$onInit = function () {
      var _this = this;

      this.entities = [{
        message: 'As an individual',
        icon: 'user-outline',
        hide: this.disableIndividualFundraising(),
        onSelect: function onSelect() {
          _this.goToCheckout();
        }
      }, {
        message: 'Join a team',
        icon: 'join-team',
        hide: this.disableTeamJoin(),
        onSelect: function onSelect() {
          _this.goToBrowse();
          _this.beginCheckout();
        }
      }, {
        message: 'Create a team',
        icon: 'create-team',
        hide: this.disableTeamCreate(),
        onSelect: function onSelect() {
          _this.goToCreate();
          _this.beginCheckout();
        }
      }];
    };

    this.beginCheckout = function () {
      // Begin Checkout Event
      var currency = scCurrencyService.getEntityCampaignCurrency(scCampaignsService.active.current);
      scAnalytics.eventBeacon({
        label: 'Begin Checkout',
        action: 'checkout',
        currency: currency,
        items: [{
          item_id: scCampaignsService.active.current.id,
          item_name: scCampaignsService.active.current.name,
          item_category: scCampaignsService.active.current.type,
          currency: currency
        }]
      });
    };
  }]
});
})();