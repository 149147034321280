(function(){
'use strict';

/**
 * @kind directive
 * @name scTextBoxField
 *
 * @description
 * Displays a simple textarea, much better than froala if you don't
 * like your content wrapped in a <p>. Supports the options as intput type text.
 *
 * @param {string} label Field label
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {string} model textarea model
 * @param {string} description Descriptive helper text
 * @param {string} charCounter max character length of the field,
 *  and whether to show the character counter.
 * @param {boolean} smallLabel If true, reduces font size of label
 */

scTextBoxField.$inject = ["scField"];
function scTextBoxField(scField) {
  return scField('scTextBoxField', {
    link: function link(scope) {
      scope.ui = {
        inputCounterClass: {
          'has-counter': !!scope.options.characterCounter
        },
        divCounterClass: {
          'form_char-counter': !!scope.options.characterCounter
        }
      };
    }
  });
}

angular.module('classy').directive('scTextBoxField', scTextBoxField);
})();