(function(){
'use strict';

angular.module('classy').component('mapMarkerPicker', {
  template: '\n      <input\n        type="text"\n        name="map-search"\n        model="$ctrl.search"\n        value="{{:: $ctrl.search }}"\n        placeholder="Search for a location"\n        ng-class="{\'map-field-disable\': !$ctrl.markerEnabled}"\n        class="form__text-input--dark picker__autocomplete"\n        ng-disabled="$ctrl.lockField"></input>\n      <div ng-class="{\'map-field-disable\': !$ctrl.markerEnabled}"\n        class="map-marker-picker"></div>\n      <div ng-if="$ctrl.lockField"\n        data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n        data-sc-tooltip-position="center"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        fixed-tooltip="true"\n        data-sc-tooltip="\'This field is locked at the template level.\'"\n        class="map-tooltip admin-body__tooltip-element"></div>\n      <checkbox-control\n        ng-click="$ctrl.disableMarker()"\n        ng-model="$ctrl.markerEnabled"\n        disabled="$ctrl.lockField">\n        Use custom marker</checkbox-control>\n    ',
  bindings: {
    model: '=',
    zoom: '='
  },
  controller: ["$scope", "$element", "$attrs", "$stateParams", "scCampaignsTemplateService", "loadGoogleMapsAPI", function mapMarkerPicker($scope, $element, $attrs, $stateParams, scCampaignsTemplateService, loadGoogleMapsAPI) {
    this.lockField = false;

    this.getLockedField = function (type) {
      var lockedBlock = scCampaignsTemplateService.getBlockByType(type);
      return lockedBlock && $attrs.model.includes('customMarker') && lockedBlock.item_attributes.map.markerEnabled && lockedBlock.item_attributes.map.markerEnabled.locked;
    };

    if (SC.campaign.campaignTemplateData) {
      var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
      if (blockObj) {
        this.lockField = this.getLockedField(blockObj.type);
      }
    }

    this.coordinatesRegex = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/);

    var prevZoom = this.zoom;

    // used for janky two way binding between this map's native zoom and our
    // block's zoom setting
    this.$doCheck = function () {
      if (this.map && this.zoom != null && this.zoom !== prevZoom) {
        prevZoom = this.zoom;
        if (this.map.getZoom() !== this.zoom) {
          this.map.setZoom(this.zoom);
        }
      }
    };

    this.$onInit = function () {
      var _this = this;

      // shows toggle state, also for Init it shows whether we have custom coordinates or not
      if (!this.model.lat || !this.model.lng) {
        this.markerEnabled = false;
        this.search = '';
      } else {
        this.markerEnabled = true;
        this.search = this.model.lat + ', ' + this.model.lng;
      }

      // if on init, we have a custom marker already, then we should provide the same zoom
      // level as the location block to start off with, and fall back to the same fallback
      // if we start with no custom marker, provide a zoomed out view of the US
      if (this.markerEnabled) {
        this.defaultZoom = this.zoom || 12;
      } else {
        this.defaultZoom = 2;
      }

      loadGoogleMapsAPI.then(function () {
        _this.init();
      });
    };

    this.init = function () {
      var _this2 = this;

      this.mapElement = $element.find('.map-marker-picker')[0];
      this.autocompleteElement = $element.find('.picker__autocomplete')[0];

      // preventing any odd errors we might get from having no map element
      this.autocomplete = new google.maps.places.SearchBox(this.autocompleteElement);
      var mapOptions = this.getOptions();

      this.map = new google.maps.Map(this.mapElement, mapOptions);

      // if we do not have all or part of the custom marker coordinates,
      // we should just use an easy default value
      if (!this.markerEnabled) {
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({
          address: 'US'
        }, function (result) {
          _this2.initialLocation(result[0].geometry.location);
        });
      } else {
        // if we have a set of coordinates, then initialize there
        this.initialLocation({
          lat: this.model.lat,
          lng: this.model.lng
        });
      }
    };

    // first time map and marker locations
    this.initialLocation = function (loc) {
      this.map.setCenter(loc);
      // on Init, only throw up a marker if we have custom coordinates
      if (this.markerEnabled) {
        this.adjustMarker(loc);
      }
      this.map.addListener('click', this.markerHandler.bind(this));
      this.autocomplete.addListener('places_changed', this.autocompleteHandler.bind(this));
      this.map.addListener('zoom_changed', this.zoomHandler.bind(this));
    };

    // this is used every time the marker is moved one way or another, via search
    // or via clicking. This moves the position of the marker (instead of making new ones)
    // and hides it if the custom marker setting is toggled off
    this.adjustMarker = function (pos) {
      var _this3 = this;

      if (!this.marker) {
        this.marker = new google.maps.Marker({
          position: pos,
          map: this.map
        });
      } else {
        if (!this.marker.getVisible()) {
          this.marker.setVisible(true);
        }
        this.marker.setPosition(pos);
      }

      // this is needed in order to get the preview pane to trigger a digest
      // probably because the listener it is attached to counts as async from gmaps
      $scope.$evalAsync(function () {
        _this3.model = {
          lat: _this3.marker.getPosition().lat(),
          lng: _this3.marker.getPosition().lng()
        };
      });
    };

    // this disable function is used to hide the marker if off, as well as
    // reset the coordinates to null if toggled off
    this.disableMarker = function () {
      var _this4 = this;

      if (!this.markerEnabled) {
        this.marker.setVisible(false);
        $scope.$evalAsync(function () {
          _this4.model = {
            lat: null,
            lng: null
          };
        });
      }
    };

    this.zoomHandler = function () {
      // other half of janky two way binding between block zoom and map native zoom
      this.zoom = this.map.getZoom();
    };

    // click = marker
    this.markerHandler = function (e) {
      if (this.markerEnabled) {
        this.adjustMarker(e.latLng);
      }
    };

    // this is how we handle the autocomplete searcher
    this.autocompleteHandler = function () {
      var inputStr = angular.element(this.autocompleteElement).val();

      // if the search string matches the regex for "set of coordinates"
      if (this.coordinatesRegex.test(inputStr)) {
        // we can pretty much just parse it and just set the center + marker to there
        var coords = inputStr.split(/\s*,\s*/);
        var lat = parseFloat(coords[0]),
            lng = parseFloat(coords[1]);
        this.map.setCenter({ lat: lat, lng: lng });
        this.adjustMarker({ lat: lat, lng: lng });
      } else {
        this.places = this.autocomplete.getPlaces();
        this.place = this.places[0];

        if (this.places.length && this.places[0].geometry) {
          // otherwise we're gonna go ahead and display the returned set of coordinates
          if (this.place.geometry.viewport) {
            // fits the bounds of the map to the viewport
            this.map.fitBounds(this.place.geometry.viewport);
          } else {
            // centers the map on the new location
            this.map.setCenter(this.place.geometry.location);

            // 17 is a nice default zoom after you search something new
            this.map.setZoom(17);
          }
          // moves the marker
          this.adjustMarker(this.place.geometry.location);
        }
      }
    };

    this.getOptions = function () {
      return {
        zoom: this.defaultZoom,
        gestureHandline: 'cooperative',
        draggable: true,
        disableDefaultUI: true,
        styles: [{
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{
            color: '#e9e9e9'
          }, {
            lightness: 17
          }]
        }, {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{
            color: '#f5f5f5'
          }, {
            lightness: 20
          }]
        }, {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 17
          }]
        }, {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 29
          }, {
            weight: 0.2
          }]
        }, {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 18
          }]
        }, {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{
            color: '#ffffff'
          }, {
            lightness: 16
          }]
        }, {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{
            color: '#f5f5f5'
          }, {
            lightness: 21
          }]
        }, {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{
            color: '#dedede'
          }, {
            lightness: 21
          }]
        }, {
          elementType: 'labels.text.stroke',
          stylers: [{
            visibility: 'on'
          }, {
            color: '#ffffff'
          }, {
            lightness: 16
          }]
        }, {
          elementType: 'labels.text.fill',
          stylers: [{
            saturation: 36
          }, {
            color: '#333333'
          }, {
            lightness: 40
          }]
        }, {
          elementType: 'labels.icon',
          stylers: [{
            visibility: 'off'
          }]
        }, {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{
            color: '#f2f2f2'
          }, {
            lightness: 19
          }]
        }, {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{
            color: '#fefefe'
          }, {
            lightness: 20
          }]
        }, {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{
            color: '#fefefe'
          }, {
            lightness: 17
          }, {
            weight: 1.2
          }]
        }]
      };
    };
  }]
});
})();