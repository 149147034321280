(function(){
'use strict';

function friendlyShortNumber() {
  return function (number, fractionSize) {
    if (number === null) return null;
    if (number === 0) return '0';

    var fraction = !fractionSize || fractionSize < 0 ? 1 : fractionSize;

    var abs = Math.abs(Math.round(number));
    var rounder = Math.pow(10, fraction);
    var isNegative = number < 0;
    var key = '';
    var powers = [{ key: 'Q', value: Math.pow(10, 15) }, { key: 'T', value: Math.pow(10, 12) }, { key: 'B', value: Math.pow(10, 9) }, { key: 'M', value: Math.pow(10, 6) }, { key: 'K', value: 1000 }];

    for (var i = 0; i < powers.length; i += 1) {
      var reduced = abs / powers[i].value;

      reduced = Math.round(reduced * rounder) / rounder;

      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }

    return (isNegative ? '-' : '') + abs + key;
  };
}

angular.module('classy').filter('friendlyShortNumber', friendlyShortNumber);
})();