(function(){
'use strict';

angular.module('classy').component('fundraisingMinimumDisclaimer', {
  template: '\n    <div class="fr-minimum-disclaimer"\n      ng-if="$ctrl.featureEnabled"\n      ng-class="{\'fr-minimum-disclaimer__small-version\': $ctrl.smallVersion}"\n      ng-style="{\'background-color\': $ctrl.backgroundColor || \'none\', \'padding-left\': $ctrl.leftOffsetPx || 0}">\n      <div class="fr-minimum-disclaimer__ticket-icon" ng-if="!$ctrl.smallVersion">\n        <i class="ci ci-receipt-stub"></i>\n      </div>\n      <div class="fr-minimum-disclaimer__row">\n        <span ng-if="$ctrl.smallVersion" class="fr-minimum-disclaimer__text">\n          {{$ctrl.minimumValue | currency :\'$\':0}} fundraising commitment due on {{$ctrl.commitmentDeadline | date : \'shortDate\'}}\n        </span>\n        <span ng-if="!$ctrl.smallVersion" class="fr-minimum-disclaimer__text">\n          Each attendee commits to raising {{$ctrl.minimumValue | currency :\'$\':0}} by\n          {{$ctrl.commitmentDeadline | date : \'longDate\'}}. If the amount isn\'t reached, attendees agree to cover the remaining amount.\n        </span>\n        <i class="fa fa-question-circle fr-minimum-disclaimer__tooltip-icon"\n           sc-tooltip\n           sc-tooltip-class="tooltip-dark"\n           sc-tooltip-position-tip="{{ $ctrl.tooltipPosition }}">\n          If attendees do not meet their fundraising commitments, we charge the remaining amount to the payment method attendees provide. If you have any questions, contact our organization.\n        </i>\n      </div>\n    </div>\n  ',
  bindings: {
    minimumValue: '<',
    minimumDeadline: '<',
    smallVersion: '<',
    tooltipRelativePosition: '<',
    leftOffsetPx: '<',
    backgroundColor: '<'
  },
  controller: ["scCampaignsService", function fundraisingMinimumDisclaimerComponent(scCampaignsService) {
    this.$onInit = function () {
      if (typeof this.minimumDeadline === 'string') {
        this.commitmentDeadline = new Date(this.minimumDeadline.replace(' ', 'T'));
      } else if (this.minimumDeadline instanceof Date) {
        this.commitmentDeadline = this.minimumDeadline;
      }

      // were doing the check for feature enablement here so we don't have to change everywhere that uses this component
      this.featureEnabled = _.get(scCampaignsService, 'active.current.type') === 'reg_w_fund';
      this.tooltipPosition = this.tooltipRelativePosition || 'top center';
    };
  }]
});
})();