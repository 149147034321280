(function(){
'use strict';

/**
 * @kind directive
 * @name scDonationSettingsField
 *
 * @description
 * Displays the donation settings module, including amount settings, prefilled amount settings,
 * and the default donation type.
 *
 * @param {string} label Field label
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {expression} levels Array of donation levels from the parent scope
 * @param {expression} levelDefault Default donation level from the parent scope
 * @param {expression} useImpactLevels Boolean indicating whether to use
 *    impact donation levels to prefill 'levels'
 *
 * @param {object} givingMode Object describing the giving mode dropdown:
 *
 * | Property | Type | Description |
 * |--|--|--|
 * | model | expression | Model for the giving mode dropdown. |
 *
 * @param {object} multipleAmounts Object describing properties if a givingMode with
 *    multiple amounts is selected:
 *
 * | Property | Type | Description |
 * |--|--|--|
 * | fieldName | string | 'name' attribute applied to every amount input. |
 * | errors | array | Error definitions for the multiple amounts section.
 *    See errors documentation for more. |
 *
 * @param {object} singleAmount  Object describing properties if givingMode
 *    with single amount (i.e. option 3) is selected:
 *
 * | Property | Type | Description |
 * |--|--|--|
 * | label | string | Label text for the single amount input. |
 * | fieldName | string | 'name' attribute applied to every amount input. |
 * | errors | array | Error definitions for the multiple amounts section.
 *    See errors documentation for more. |
 */

scDonationSettingsField.$inject = ["scField", "scSubscriptionPlan", "scOrganizationsService", "scCurrencyService", "scCampaignsService", "globalExperimentSwitch", "scBlocksService", "scCampaignsTemplateService", "$stateParams"];
function scDonationSettingsField(scField, scSubscriptionPlan, scOrganizationsService, scCurrencyService, scCampaignsService, globalExperimentSwitch, scBlocksService, scCampaignsTemplateService, $stateParams) {
  return scField('scDonationSettingsField', {
    link: function link(scope) {
      scope.levelErrors = function (fieldName, length) {
        var name = void 0;
        for (var i = 0; i < length; i += 1) {
          name = fieldName + '_' + i;
          if (scope.showFormError && scope.showFormError(name)) {
            return true;
          }
        }

        return false;
      };

      var campaign = scCampaignsService.active.current;

      // Here we check if the recurring experiment should be running on a global level,
      // then check whether to show the experiment toggle based on the donation setting,
      // campaign type, and org payment provider
      var DonationBlock = scBlocksService.getBlockByType('donation');
      scope.hasPay = scOrganizationsService.hasPay();
      scope.showRecurringExperiment = function () {
        return globalExperimentSwitch.RECURRING_EXPERIMENT && scOrganizationsService.hasPay() && (parseInt(DonationBlock.current['recurring-level'], 10) == 1 || parseInt(DonationBlock.current['recurring-level'], 10) == 2);
      };

      scope.experimentDescription = 'We\'re optimizing a new feature to increase monthly donations. You can opt-out below, or <a target="_blank" href="https://support.classy.org/customer/portal/articles/2892855-details-around-classy%E2%80%99s-recurring-optimization-initiative">learn more here</a>.';
      scope.lockField = false;
      scope.customStyle = '';
      if (SC.campaign.campaignTemplateData && $stateParams.step === 'donation') {
        var lockDonationBlock = scCampaignsTemplateService.getBlockByType('donation');
        if (lockDonationBlock && scope.options.givingMode.model === "DonationBlock.current['recurring-level']") {
          scope.lockField = lockDonationBlock.item_attributes['recurring-level'].locked;
          scope.customStyle = '30%';
        }
      }
      var organization = scOrganizationsService.active.current;
      scope.currency = scCurrencyService.getCurrencySymbol(campaign.raw_currency_code);
      scope.freeOrTrialPlan = scSubscriptionPlan.isFreeOrTrialPlan(organization);
    }
  });
}

angular.module('classy').directive('scDonationSettingsField', scDonationSettingsField);
})();