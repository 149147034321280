(function(){
'use strict';

angular.module('classy').constant('scHtmlBlockDefaults', {
  title: 'Html',
  type: 'html',

  mobileTemplates: false,
  content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et nibh elit. Nunc non sagittis tortor. Quisque cursus posuere velit, non pharetra risus mattis non. Mauris sit amet tortor vitae lorem ullamcorper hendrerit. Cras venenatis, felis ac scelerisque pulvinar, dolor dolor elementum tellus, eget congue sem mauris pharetra purus. Integer cursus viverra ante dictum dictum. Morbi sollicitudin massa sapien, vulputate egestas nisl aliquam eu. Quisque aliquet iaculis molestie. Suspendisse sed aliquet tellus, id porttitor nisl.</p>' + '<p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur vitae neque gravida, porta arcu vel, iaculis quam. Integer a nisl id massa efficitur interdum ac et ipsum. Praesent et scelerisque orci. Sed in egestas neque. Maecenas dui metus, posuere in tempor ac, tempus et urna. Suspendisse porta, turpis eu cursus ullamcorper, dui nulla iaculis massa, eget sodales nisl ligula nec dui.</p>',

  remove: true
});
})();