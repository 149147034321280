(function(){
'use strict';

/**
 * @kind directive
 * @name scDesignerScrollingPanes
 * @scope
 *
 * @description
 * Use this if you dont want to be able to scroll past a certai
 *
 * Element will not pass top of its container.
 * Element will have 'fixed' class if container top is offscreen.
 * Element will have 'absolute' class when container is moving offscreen so
 * that it remains within its container.
 * Element takes the width of sibling with class 'fix-neat_placeholder'.
 */

scDesignerScrollingPanes.$inject = ["$window", "$timeout"];
function scDesignerScrollingPanes($window, $timeout) {
  return {
    scope: {
      scrollTarget: '@',
      scrollClassToggle: '@',
      scrollClassTarget: '@'
    },
    controller: ['$scope', function ($scope) {
      var $el = $($scope.scrollTarget);
      var classToToggle = $scope.scrollClassToggle;

      function elmYPosition(eID) {
        var elm = document.getElementById(eID);
        var y = elm ? elm.offsetTop + 50 : 0;
        return y;
      }

      function animateScrollbar(elementId) {
        var stopPosY = elmYPosition(elementId);
        var $settingsForm = angular.element('.edit-design_aside');

        if (clicked) {
          $settingsForm.animate({
            scrollTop: stopPosY
          }, 300, function () {
            scrolling = false;
          });
        }

        clicked = false;
      }

      var scrolling = false;
      var clicked = void 0;

      this.scrollTo = function (elementId) {
        if (!scrolling) {
          scrolling = true;
          clicked = true;

          $timeout(function () {
            if (!$($scope.scrollClassTarget).hasClass(classToToggle)) {
              var elementOffset = $el.offset().top;

              $('body, html').animate({ scrollTop: elementOffset }, 200, function () {
                animateScrollbar(elementId);
                $($scope.scrollClassTarget).addClass(classToToggle);
              });
            } else {
              animateScrollbar(elementId);
            }
          }, 100);
        }
      };
    }],
    link: function link(scope) {
      /* ------------------------------------------------------------------ *
       * Class toggleage
       * ------------------------------------------------------------------ */

      var $el = $(scope.scrollTarget);
      var $target = $(scope.scrollClassTarget);
      var classToToggle = scope.scrollClassToggle;

      var scrollEvent = _.debounce(function () {
        // These values need to be rounded because for some combinations of screen resolution,
        // operating system, and browser, the values were coming back with huge amounts of precision,
        // (scroll positions down to 10+ decimal places) and not properly doing comparisons.
        // See https://classydev.atlassian.net/browse/FRS-7339
        var windowScroll = Math.round($(window).scrollTop());
        var elementOffset = Math.round($el.offset().top);

        // we want to fix in place if we've scrolled too far or if the class has been applied.
        // the class could be applied by this directive or by campaignEditDesignContentBlocksCtrl,
        // which would do so if the user clicked on one of the menu options. Clicking a menu
        // option opens a menu and scrolls the main view down to show that element, and needs
        // the fixed positioning.
        if (windowScroll >= elementOffset) {
          if (!$target.hasClass(classToToggle)) {
            $target.addClass(classToToggle);
          }
        } else if ($target.hasClass(classToToggle)) {
          // since we're far enough down the page and the target has the required class, scrolling
          // the window should now just scroll us to the top
          $target.removeClass(classToToggle);

          $('.edit-design_aside, .edit-design_aside-page-content, .edit-design_preview-wrapper, .edit-design_preview').scrollTop(0);

          $target.removeClass(classToToggle);
        }
      }, 10);

      /* ------------------------------------------------------------------ *
       * Animation cycle
       * ------------------------------------------------------------------ */

      angular.element($window).bind('scroll', scrollEvent);
      angular.element($window).on('resize', scrollEvent);

      scope.$on('$destroy', function () {
        $target.removeClass(classToToggle);
        angular.element($window).off('scroll', scrollEvent);
        angular.element($window).off('resize', scrollEvent);
      });
    }
  };
}

angular.module('classy').directive('scDesignerScrollingPanes', scDesignerScrollingPanes);
})();