(function(){
'use strict';

/**
 * @name camelCaseToProper
 * @kind filter
 */

function camelCaseToProper() {
  return function (input) {
    return input.charAt(0).toUpperCase() + input.substr(1).replace(/[A-Z]/g, ' $&');
  };
}

angular.module('classy').filter('camelCaseToProper', camelCaseToProper);
})();