(function(){
'use strict';

/**
 * Gate
 *
 * Role Based Access Control - Authorization Component
 *
 * Supply the component with the permissions required along with the scope of the permissions, and it will provide the user with the experience they are
 * supposed to be able to see.
 *
 * How to  use:
 *
 * 1. Component parameters
 *
 *    permission - render something based on a user permission(s) on the Member model, object or array
 *      entity - the cerberus entity, e.g. 'transaction'
 *      action - the cerberus action, e.g. 'update'
 *
 *    scopes (optional) - the cerberus scopes to authorize against, e.g. organization: [123], campaign: [456]
 *      if not provided, the default scopes argument is {campaign: [ campaign.current.id ], organization: [ campaign.current.organization_id ]}
 *
 *    match (optional) - determines how many permissions must resolve with a true result for the resource to be shown
 *      if not provided, the default match argument is "all"
 *
 * 2. Content
 *
 *    Provide content for authorized and unauthorized state wrapped in named ng-transclude tags, as
 *    shown below. If you just want to hide the content when the member is not authorized, simply
 *    omit the unauthorized tag. The loading tag is optional as well, but should be used when
 *    there is a good chance the request will not be timely.
 *
 * <gate data-permissions="{action: 'publish', entity: 'campaign'}" data-scopes="{campaign: [ campaign.current.id ], organization: [ campaign.current.organization_id ]}">
 *
 *   <authorized>
 *     <span>You are authorized</span>
 *   </authorized>
 *
 *   <unauthorized>
 *     <span>You are not authorized</span>
 *   </unauthorized>
 *
 *   <loading>
 *     <span>optional content to show while the request is loading</span>
 *   </loading>
 *
 * </gate>
 *
 *  NOTE: if you only want to show something when the user is authorized, you do not need to include
 *  it inside an <authorized> tag, you can just put the content directly into the component and it
 *  will be handled appropriately, e.g.
 *
 *  <gate data-permissions="{action: 'deactivate', entity: 'campaign'}" data-scopes="{campaign: [ campaign.current.id ]}">
 *    <button>Deactivate</button>
 *  </gate>
 *
 * To match multiple permissions, an array of permissions objects can be passed. Used the "match" parameter to inform the component
 * whether all permissions should be true or if some should be true before showing authorized content
 *
 *  <gate data-match="any" data-permissions="[{action: 'publish', entity: 'campaign'}, {action: 'unpublish', entity: 'campaign'}]">
 *    <span>You are authorized</span>
 *  </gate>
 *
 *
 */
angular.module('classy').component('gate', {
  template: '\n      <span\n        ng-if="$ctrl.showDebugInfo && !$ctrl.loading"\n        data-sc-tooltip="$ctrl.debugInfo"\n      >\n        {{$ctrl.authorized ? \'\u2705\' : \'\uD83D\uDE48\'}}\n      </span>\n      <ng-transclude ng-if="$ctrl.authorized"></ng-transclude>\n      <ng-transclude ng-if="$ctrl.loading" ng-transclude-slot="loading"></ng-transclude>\n      <ng-transclude ng-if="$ctrl.authorized && !$ctrl.loading" ng-transclude-slot="authorized"></ng-transclude>\n      <ng-transclude ng-if="!$ctrl.authorized && !$ctrl.loading" ng-transclude-slot="unauthorized"></ng-transclude>\n    ',
  transclude: {
    authorized: '?authorized',
    unauthorized: '?unauthorized',
    loading: '?loading'
  },
  bindings: {
    permissions: '<',
    scopes: '<',
    match: '@'
  },
  controller: ["scAuthorizeService", "$scope", function controller(scAuthorizeService, $scope) {
    var _this = this;

    // the authorized flag always starts as false, so that we never give a glimpse of what an
    // authorized user may have access to. Loading initializes to true (event when not used)
    this.authorized = false;
    this.loading = true;
    this.showDebugInfo = SC.env !== 'production' && localStorage && localStorage.getItem('classydebug');

    this.$onInit = function () {
      if (!_this.permissions) {
        throw new Error('You must provide a permissions param');
      }

      // default matching parameter
      if (!_this.match) {
        _this.match = 'all';
      }

      _this.debugInfo = '\n        Scopes: ' + _this.scopes + '\n\n        Permissions: ' + JSON.stringify(_this.permissions) + '\n\n        Match: ' + _this.match + '\n      ';

      return scAuthorizeService.isAuthorized(_this.permissions, _this.scopes).then(function (authorized) {
        // Depending on the 'match' option, we authorize the member if they received true for
        // 'any' or the permissions supplied, or 'all' of them
        if (_this.match === 'any') {
          _this.authorized = authorized.some(function (perm) {
            return perm.result;
          });
        }
        if (_this.match === 'all') {
          _this.authorized = authorized.every(function (perm) {
            return perm.result;
          });
        }
      }).catch(function (error) {
        console.log('==============================================\n'); // eslint-disable-line no-console
        var outputError = JSON.stringify({
          type: error.name || null,
          message: error.message || null,
          statusCode: error.statusCode || null,
          stack: error.stack.replace(/\n/g, ' ') || null
        });
        console.log(outputError); // eslint-disable-line no-console
        console.log('==============================================\n'); // eslint-disable-line no-console
      }).finally(function () {
        _this.loading = false;
        $scope.$digest();
      });
    };
  }]
});
})();