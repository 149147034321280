(function(){
'use strict';

function scParseGACookieService() {
  this.getUTMCampaign = function (cookie) {
    var refCookie = this.breakCookie(cookie, '__utmz=', ';');
    var utmCampaign = this.breakCookie(refCookie, '__utmccn=', '|');
    return utmCampaign;
  };

  this.breakCookie = function (biscuit, start, end) {
    var startIndex = biscuit.indexOf(start);

    // if the start index is not found, the cookie fragment does not exist
    if (startIndex < 0) {
      return 'none';
    }

    var assignIndex = start.indexOf('=') + 1;

    // find the end point of the cookie fragment
    var endIndex = biscuit.indexOf(end, startIndex);

    // if it does not exist, then it must be at the end of the cookie fragment
    if (endIndex < 0) {
      endIndex = biscuit.length;
    }

    return decodeURIComponent(biscuit.substring(startIndex + assignIndex, endIndex));
  };
}

angular.module('classy').service('scParseGACookieService', scParseGACookieService);
})();