(function(){
'use strict';

scImpactBlock.$inject = ["$window", "scFroalaOptions", "scBlock", "scCampaignsService", "scCampaignsTemplateService", "scSwitchFlowPickerService", "scAnalytics", "countryBlockService"];
function scImpactBlock($window, scFroalaOptions, scBlock, scCampaignsService, scCampaignsTemplateService, scSwitchFlowPickerService, scAnalytics, countryBlockService) {
  var locks = scCampaignsTemplateService.getLocks({
    impact: { headlineColor: { locked: false } }
  });

  return scBlock({
    link: function link(scope) {
      scope.headlineColor = locks.impact.headlineColor;

      scope.froalaOptionsPreview = scFroalaOptions({
        type: 'edit-inline'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'Impact Headline',
        toolbarButtons: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle']
      });

      var campaignType = scCampaignsService.active.current.type;

      scope.public = scope.$parent.public;

      scope.hasFundraising = scCampaignsService.active.HAS_FUNDRAISING;

      scope.handleImpactLink = function (level) {
        countryBlockService.checkOrganizationRestrictions(function () {
          return scope.impactLink(level);
        });
      };

      scope.impactLink = function (level) {
        var previewLink = scope.demo ? '&preview=true' : '';

        scAnalytics.eventBeacon({
          category: 'frs ' + campaignType,
          action: 'click',
          label: 'Fundraise CTA'
        });

        switch (scope.block.impactType) {
          case 'fundraise':
            scSwitchFlowPickerService.fundraiseSwitch({ goal: level.amount });
            break;
          default:
            $window.location = '/give/' + scope.campaign.id + '?amount=' + level.amount + previewLink + '#!/donation/checkout';
            break;
        }
      };
    }
  });
}

angular.module('classy').directive('scImpactBlock', scImpactBlock);
})();