(function(){
'use strict';

/*
 * TODO: Add documentation on valid scMediaManager options.
 * scope.options = {
 *    useReactEditor: bool, toggles the React Image Editor
 *    reactEditorMode: 'full' | 'basic',  determines the React Image Editor mode
 * }
 *
 */
scMediaManager.$inject = ["$animate", "$parse", "$q", "$timeout", "decorateDirective", "scImg", "scFlowModalService", "scImgAssetsService", "accessibilityService"];
function scMediaManager($animate, $parse, $q, $timeout, decorateDirective, scImg, scFlowModalService, scImgAssetsService, accessibilityService) {
  function postLink(scope, element) {
    $animate.enabled(element, false);

    /* -------------------------------------------------------------------- *
     * Model; shared by all subcontrollers
     * -------------------------------------------------------------------- */

    scope.model = {
      images: [],
      video: '',
      use: null
    };

    // We may be coming back to the media manager with a previously staged
    // image, in this case we don't want to erase it.
    scope.staged = scope.staged || {
      images: [],
      video: '',
      use: null
    };

    // Store the pixabaySrc in order to set the "selected" style class on selected pixabay image.
    // This is ONLY set when we stage the image in scope.stage.
    scope.pixabaySrc = '';

    scope.ui = {
      view: 'image',
      image: {
        source: 'upload',
        qty: 1,
        uploaded: [],
        search: {
          collection: 'all',
          query: '',
          querySuffix: '',
          page: 1,
          results: [],
          processing: false,
          debouncing: false,
          pending: false
        },
        minWidth: 2000,
        minHeight: 2000
      },
      reactEditorVisible: scope.options.useReactEditor,
      video: {
        source: 'link'
      }
    };

    scope.el = {
      main: element
    };

    if (_.get(scope.options, 'userAccess', false)) {
      scope.userAccess = $parse(scope.options.userAccess)(scope);
    } else {
      scope.userAccess = null;
    }

    /* -------------------------------------------------------------------- *
     * API: General
     * -------------------------------------------------------------------- */

    scope.customAction = function () {
      var action = _.get(scope.options, 'buttons.custom.action');
      if (action) {
        $parse(action)(scope);
      }
    };

    scope.cancelMediaManager = function () {
      var cancelAction = _.get(scope.options, 'buttons.cancel.action');
      if (cancelAction) {
        $parse(cancelAction)(scope);
      }
    };

    scope.stage = function (_ref) {
      var image = _ref.image,
          _ref$libraryLoaded = _ref.libraryLoaded,
          libraryLoaded = _ref$libraryLoaded === undefined ? false : _ref$libraryLoaded,
          keyboardEvent = _ref.keyboardEvent;

      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;

      if (libraryLoaded) {
        var $submitButton = $('[data-submit-button]');
        scope.pixabaySrc = image.previewURL;
        scope.disableContinue = true;

        scope.deferredImageData(image).then(function (response) {
          scope.disableContinue = false;

          var blobbedImage = new scImg(response);
          scope.staged.images = [blobbedImage];

          // focus on submit button for easier keyboard navigation. Timeout allows event queue to clear and ensure button is enabled first
          $timeout(function () {
            $submitButton.focus();
          });
        });
      } else {
        scope.staged.images = [image];
      }
    };

    scope.clear = function () {
      scope.el.$input.val('');
      scope.ui.image.uploaded = [];
      scope.staged.images = [];
      scope.model.images = [];
    };

    scope.buildSaveText = function () {
      if (scope.staged.images.length && !scope.ui.reactEditorVisible) {
        return 'Insert';
      }

      return 'Continue';
    };

    scope.submit = function () {
      var action = _.get(scope.options, 'buttons.submit.action', false);

      // Setup staged images
      if (scope.staged.images.length) {
        var img = scope.staged.images[0];
        scope.model.images = [img];
      }

      var useCropper = _.get(scope.options, 'image.crop', scope.options.useReactEditor);

      // Check if we're allowing this image to be cropped
      // if (scope.model.images.length && _.get(scope.options, 'image.crop', false)) {
      if (scope.model.images.length && useCropper) {
        scope.cropImage({
          $images: scope.model.images,
          $video: scope.model.video,
          $use: scope.ui.view
        });
      } else if (action) {
        // Check if user passed in an override submit action
        $parse(action)(scope, {
          $images: scope.model.images,
          $video: scope.model.video,
          $use: scope.ui.view
        });
      } else {
        // Otherwise return and close
        scFlowModalService.close(true, {
          images: scope.model.images,
          video: scope.model.video,
          use: scope.ui.view
        });
      }
    };

    /* ------------------------------------------------------------
     * CROP utilities
     * ------------------------------------------------------------ */
    scope.cropImage = function (media) {
      if (scope.options.useReactEditor) {
        scope.openReactEditor();
      } else {
        scope.openScCropper(media);
      }
    };

    // Use the appv2 cropper
    scope.openScCropper = function (media) {
      var images = media.$images;

      scope.cropDeferred = new CropPromise();

      var img = images[0];

      scope.cropImageConfig = {
        width: 250,
        height: 250,
        title: 'Crop your image',
        blockClass: 'fundraising-page-logo-crop',
        image: null
      };

      var userCropConfig = _.get(scope.options, 'image.crop', false);
      if (userCropConfig && _.isObject(userCropConfig)) {
        _.merge(scope.cropImageConfig, userCropConfig);
      }

      scFlowModalService.to({
        template: "<div data-sc-crop-modal='cropImageConfig'></div>",
        maxWidth: 415,
        context: scope
      });

      img.download().then(function () {
        scope.originalImage = img;
        scope.cropImageConfig.image = img.cache.full;
      });
    };

    scope.reactEditorCancel = function () {
      scFlowModalService.to({
        template: "<div data-sc-media-manager='options'></div>",
        maxWidth: 980,
        context: scope
      }, 'slideRight');
    };

    scope.openReactEditor = function () {
      scFlowModalService.to({
        template: '\n            <media-manager-editor\n              data-src="model.images[0].src"\n              data-aspect-ratio="options.reactEditorOptions.aspectRatio"\n              data-height="options.reactEditorOptions.height"\n              data-visible="ui.reactEditorVisible"\n              data-mode="options.reactEditorOptions.mode"\n              data-handle-upload="handleReactUpload({ imgFile, imageData })"\n              data-on-cancel="reactEditorCancel()"\n              data-hero-full-height="options.reactEditorOptions.heroFullHeight"\n              data-max-file-size="options.reactEditorOptions.maxFileSize"\n              data-file-name="model.images[0].asset.title"\n              data-output-max-width="options.reactEditorOptions.outputMaxWidth"\n            />\n          ',
        context: scope,
        transitionTo: 'slideRight'
      });
    };

    scope.handleReactUpload = function (_ref2) {
      var imgFile = _ref2.imgFile,
          imageData = _ref2.imageData;
      var position = imageData.position,
          canvasData = imageData.canvasData;

      var cropData = { position: position, canvasData: canvasData };
      var builtImg = new scImg(imgFile);
      builtImg.cropData = cropData;
      onCropped(builtImg, cropData);
    };

    scope.$on('scCropCancel', function () {
      scope.cropImageConfig.image = null;

      // go back to previous view
      var view = _.get(scope.options, 'image.crop.cancel', false);
      if (view) {
        scFlowModalService.to(view, 'slideRight');
      } else {
        scFlowModalService.to({
          template: "<div data-sc-media-manager='options'></div>",
          maxWidth: 980,
          context: scope
        }, 'slideRight');
      }
    });

    // eslint-disable-next-line no-unused-vars
    scope.$on('scCropSave', function (e, cropData) {
      var childAsset = scImgAssetsService.transform(scope.originalImage.asset, cropData.transformQueue, cropData.preview);

      var overrides = _.cloneDeep(scope.originalImage);
      var childImage = scImg.instantiate(childAsset, overrides);
      scope.cropDeferred.resolve(childImage);
    });

    function CropPromise() {
      var deferred = $q.defer();
      deferred.promise.then(onCropped);
      return deferred;
    }

    function onCropped(croppedImg, cropData) {
      var imgArray = [];
      imgArray.push(croppedImg);

      var cropDataArray = [];
      cropDataArray.push(cropData);

      // Check if user has passed in an override submit action
      // Usually this just goes to the next view.
      // if scMediaManager was loaded via launchMediaManager, this will be set from there
      // in order to integrate media manager into an existing flow (if there is one)
      var action = _.get(scope.options, 'buttons.submit.action');
      if (action) {
        $parse(action)(scope, {
          $images: imgArray,
          $video: scope.model.video,
          $use: scope.ui.view,
          $cropData: cropDataArray
        });
      } else {
        scFlowModalService.close(true, {
          images: imgArray,
          video: scope.model.video,
          cropData: cropDataArray
        });
      }
    }

    scope.deferredImageData = function (listing) {
      var deferred = $q.defer();

      var url = scope.getUrlFromListing(listing);
      var xhr = new XMLHttpRequest();
      xhr.open('get', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.response) {
          xhr.response.name = scope.ui.image.search.query || 'Downloaded Image';
          deferred.resolve(xhr.response);
        } else {
          deferred.reject();
        }
      };

      xhr.send();
      return deferred.promise;
    };

    scope.getUrlFromListing = function (listing) {
      var isPortrait = listing.imageHeight > listing.imageWidth,
          isLandscape = !isPortrait,
          aspect = isPortrait ? listing.imageWidth / listing.imageHeight : listing.imageHeight / listing.imageWidth,
          sizes = {
        preview: {
          height: isPortrait ? 150 : 150 * aspect,
          width: isLandscape ? 150 : 150 * aspect,
          url: listing.previewURL
        },
        webformat: {
          height: isPortrait ? 640 : 640 * aspect,
          width: isLandscape ? 640 : 640 * aspect,
          url: listing.webformatURL
        },
        large: {
          height: isPortrait ? 1280 : 1280 * aspect,
          width: isLandscape ? 1280 : 1280 * aspect,
          url: listing.largeImageURL
        },
        hd: {
          height: isPortrait ? 1920 : 1920 * aspect,
          width: isLandscape ? 1920 : 1920 * aspect,
          url: listing.fullHDURL
        }
      },
          minWidth = scope.ui.image.minWidth,
          minHeight = scope.ui.image.minHeight;

      var bestUrl = sizes.hd.url,
          bestWidth = sizes.hd.width,
          bestHeight = sizes.hd.height;

      _.forEach(sizes, function (size) {
        if (size.width > minWidth && size.width < bestWidth && size.height > minHeight && size.height < bestHeight) {
          bestUrl = size.url;
          bestWidth = size.width;
          bestHeight = size.height;
        }
      });

      return bestUrl;
    };

    scope.getFilename = function (image) {
      if (scImg.isInstance(image)) {
        return image.title || 'Uploaded Image';
      }
      return 'Downloaded Image';
    };

    /* -------------------------------------------------------------------- *
     * Init
     * -------------------------------------------------------------------- */

    if (_.get(scope.options, 'image.model') && !scope.model.images.length) {
      var imgModel = $parse(scope.options.image.model)(scope);
      if (_.isArray(imgModel)) {
        scope.model.images = imgModel;
      } else if (imgModel) {
        scope.model.images = [imgModel];
      } else {
        scope.model.images = [];
      }
    }

    if (_.get(scope.options, 'video.model')) {
      scope.model.video = $parse(scope.options.video.model)(scope);
    }

    if (_.get(scope.options, 'use.model')) {
      scope.model.use = $parse(scope.options.use.model)(scope);
    }

    if (_.get(scope.options, 'image.minWidth')) {
      scope.ui.image.minWidth = scope.options.image.minWidth;
    }

    if (_.get(scope.options, 'image.minHeight')) {
      scope.ui.image.minHeight = scope.options.image.minHeight;
    }

    if (scope.model.images.length && !scope.staged.images.length) {
      scope.stage({ image: scope.model.images[0], libraryLoaded: false });
    }
  }

  return decorateDirective({
    link: postLink
  }, {
    precompile: {
      context: 'scMediaManager',
      contextName: 'options',
      templateUrl: 'global/media/scMediaManager/template'
    }
  });
}

angular.module('classy').directive('scMediaManager', scMediaManager);
})();