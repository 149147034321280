(function(){
'use strict';

angular.module('classy').component('cpStripeWidget', {
  template: '\n    <div id="card-element" data-tracked-element="donation-page_checkout_stripe-input"></div>\n    <div\n      id="card-errors"\n      class="{{::$ctrl.cardErrorClassName}}" role="alert"\n    ></div>\n  ',
  bindings: {
    cardErrorClassName: '@',
    inputClassName: '@',
    sourceData: '<',
    model: '<',
    hidePostal: '<',
    currency: '<'
  },
  controller: ["$rootScope", "scStripeService", "scFeeOnTopService", "bugsnagClient", function CpStripeWidgetCtrl($rootScope, scStripeService, scFeeOnTopService, bugsnagClient) {
    var DEFAULTS = {
      sourceData: {}
    };

    this.$onChanges = function () {
      var _this = this;

      /* Setup defaults and properties
      ========================================================= */
      _.defaultsDeep(this, DEFAULTS);
      this.$errorMessageElement = $('#card-errors');
      this.$errorMessageElement.text('').hide();
      this.cardElement = document.getElementById('card-element');

      var classes = { base: this.inputClassName };
      var style = {
        base: { fontFamily: "'Mulish', sans-serif", fontSize: '16px', lineHeight: '20px' }
      };
      var fonts = [{ cssSrc: 'https://fonts.googleapis.com/css?family=Mulish' }];

      /* Set parent api bindings
      ========================================================= */
      this.model.getSource = this.getSource.bind(this);
      this.model.onError = this.onError.bind(this);

      /* Init stripe instance, elements, etc.
      ========================================================= */
      scStripeService.init().then(function () {
        _this.stripe = scStripeService.stripe;
        _this.elements = _this.stripe.elements({ fonts: fonts });
        _this.card = _this.elements.create('card', {
          style: style,
          classes: classes,
          hidePostalCode: _this.hidePostal
        });
        _this.card.mount(_this.cardElement);
        _this.card.addEventListener('change', _this.onChange.bind(_this));
      });
    };

    /**
     * getSource
     *
     * Calls the stripe api method createSource on the stripe card element, converting the
     * raw card details into a stripe source object that can be charged by the pay api.
     *
     * Todo: add capability to accept a type param, to decide which stripe element to pull
     * the source from as we deepen our breadth of payment options
     * @return Promise - resolves to the source object
     */
    this.getSource = function () {
      var _this2 = this;

      return this.stripe.createSource(this.card, this.sourceData).then(function (result) {
        if (result.error) return _this2.onError(result.error);

        var source = result.source;


        if (!source) {
          return _this2.onError(new Error('source object missing in stripe response'));
        }

        _this2.model.source = source;

        return source;
      });
    };

    this.onError = function (error) {
      if (error.type === 'validation_error') {
        this.$errorMessageElement.text(error.message).show();
      } else if (error.type) {
        bugsnagClient.notify(new Error('Stripe Error: ' + error.type + ' - ' + error.message));
      } else {
        bugsnagClient.notify(error);
      }
      throw new Error(error.message);
    };

    this.onChange = function (event) {
      this.$errorMessageElement.text('').hide();
      if (event.error) {
        this.$errorMessageElement.text(event.error.message).show();
        return;
      }
      var previousIsAmexValue = scFeeOnTopService.isAmex;
      var hasAmex = false;
      if (event.brand === 'amex' || event.brand === 'american_express') {
        hasAmex = true;
        $rootScope.$broadcast('donationPage:recalculateFees');
        $rootScope.$broadcast('cart:setIsAmex', hasAmex);
      }
      scFeeOnTopService.setAmex(hasAmex);

      if (previousIsAmexValue !== scFeeOnTopService.isAmex) {
        $rootScope.$broadcast('donationPage:recalculateFees');
        $rootScope.$broadcast('cart:setIsAmex', hasAmex);
      }
    };
  }]
});
})();