(function(){
'use strict';

scLikesButton.$inject = ["$http", "$log", "scMembersService"];
function scLikesButton($http, $log, scMembersService) {
  return {
    replace: true,
    scope: {
      likeable: '=scLikesButton',
      showCount: '=?',
      endpointPrefix: '=?'
    },
    templateUrl: 'global/directives/interaction/scLikesButton/template',
    link: function link(scope) {
      var endpoint = scope.endpointPrefix || getEndpoint(scope.likeable);

      var pending = false;

      scope.toggleLike = function () {
        var memberId = _.get(scMembersService, 'active.current.id', false),
            myLike = scope.likeable.myLike;

        if (!memberId) {
          return;
        }

        if (pending) {
          return;
        }

        pending = true;

        if (myLike) {
          // Give user instant feedback and reverse it later if it fails
          scope.likeable.myLike = null;
          scope.likeable.likes_count -= 1;

          $http({
            method: 'DELETE',
            url: '/frs-api/likes/' + myLike.id,
            body: {
              member_id: memberId
            }
          }).then(_.noop, function (err) {
            // Reverse optimistic changes
            scope.likeable.myLike = myLike;
            scope.likeable.likes_count += 1;
            $log.error(err);
          }).finally(function () {
            pending = false;
          });
        } else {
          // Give user instant feedback and reverse it later if it fails
          scope.likeable.myLike = {};
          scope.likeable.likes_count += 1;

          $http({
            method: 'POST',
            url: '/frs-api/' + endpoint + '/' + scope.likeable.id + '/likes',
            body: {
              member_id: memberId
            }
          }).then(function (response) {
            // Replace placeholder myLike with the real one so we have the ID
            // in case the user wants to unlike it
            scope.likeable.myLike = response.data;
          }, function (err) {
            // Reverse optimistic changes
            scope.likeable.myLike = null;
            scope.likeable.likes_count -= 1;
            $log.error(err);
          }).finally(function () {
            pending = false;
          });
        }
      };

      function getEndpoint() {
        // Kinda hacky test ... will do for now
        return scope.likeable.visibility ? 'posts' : 'comments';
      }
    }
  };
}

angular.module('classy').directive('scLikesButton', scLikesButton);
})();