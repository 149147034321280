(function(){
'use strict';

angular.module('classy').component('pronounField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <select-field\n        aria-label="Please select a pronoun"\n        name="$ctrl.name"\n        class-prop="form__select--light"\n        options="$ctrl.pronounOptions"\n        model="$ctrl.model"\n        placeholder="\'Select a choice\'"\n        required="$ctrl.required"\n        getter-setter="$ctrl.getterSetter"\n      ></select-field>\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    placeholder: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    options: '<?'
  },
  controller: function InputFieldCtrl() {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      required: false,
      label: 'Pronouns',
      options: []
    };

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);

      _this.pronounOptions = _this.options.map(function (option) {
        return {
          label: option,
          value: option
        };
      });
    };
  }
});
})();