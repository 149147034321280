(function(){
'use strict';

function paypalControlDirective() {
  // eslint-disable-next-line no-unused-vars
  return function (scope, element) {
    element.attr('id', 'btPaypal');
  };
}

angular.module('classy').directive('paypalControl', paypalControlDirective);
})();