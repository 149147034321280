(function(){
'use strict';

/**
 * @kind factory
 * @name scBlockDefaults
 *
 * @description
 * Aggregates the default block settings
 * from each block type.
 */

scCampaignDefaultBlocks.$inject = ["scCrowdfundingCampaignDefaultBlocks", "scP2PCampaignDefaultBlocks", "scRegWFundCampaignDefaultBlocks", "scDonationCampaignDefaultBlocks", "scTicketedEventDefaultBlocks"];
function scCampaignDefaultBlocks(scCrowdfundingCampaignDefaultBlocks, scP2PCampaignDefaultBlocks, scRegWFundCampaignDefaultBlocks, scDonationCampaignDefaultBlocks, scTicketedEventDefaultBlocks) {
  return function (type) {
    var blockDefaults = {};

    switch (type) {
      case 'crowdfunding':
        blockDefaults = scCrowdfundingCampaignDefaultBlocks;
        break;

      case 'p2p':
        blockDefaults = scP2PCampaignDefaultBlocks;
        break;

      case 'reg_w_fund':
        blockDefaults = scRegWFundCampaignDefaultBlocks;
        break;

      case 'donation':
        blockDefaults = scDonationCampaignDefaultBlocks;
        break;

      case 'ticketed':
        blockDefaults = scTicketedEventDefaultBlocks;
        break;

      default:
        blockDefaults = {};
        break;
    }

    return blockDefaults;
  };
}

angular.module('classy').factory('scCampaignDefaultBlocks', scCampaignDefaultBlocks);
})();