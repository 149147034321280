(function(){
'use strict';

mobileMenuAPI.$inject = ["$rootScope", "mobileMenuGroups", "scCampaignsService", "scFundraisingPagesService", "scFundraisingTeamsService", "scMembersService"];
function mobileMenuAPI($rootScope, mobileMenuGroups, scCampaignsService, scFundraisingPagesService, scFundraisingTeamsService, scMembersService) {
  var _this = this;

  this.init = function ($body) {
    /* -------------------------------------------------------------------- *
     * Setup state
     * -------------------------------------------------------------------- */

    _this.$body = $body || angular.element('body');
    _this.active = false;
    _this.loggedIn = !!scMembersService.active.current.id;
    _this.campaignType = scCampaignsService.active.current.type;
    _this.campaignId = scCampaignsService.active.current.id;
    _this.menu = _this.buildMenu(_this.loggedIn, _this.campaignType);
    _this.refEl = null;

    /* -------------------------------------------------------------------- *
     * Setup watches
     * -------------------------------------------------------------------- */

    // Rebuild menu when campaign type changes

    $rootScope.$watch(function () {
      return _.get(scCampaignsService, 'active.current.id');
    }, function (newVal, oldVal) {
      if (newVal === oldVal) return;
      _this.campaignId = newVal;
      _this.campaignType = scCampaignsService.active.current.type;
      _this.menu = _this.buildMenu(_this.loggedIn, _this.campaignType);
    });

    // Rebuild menu when member ID/login status changes

    $rootScope.$watch(function () {
      return _.get(scMembersService, 'active.current.id');
    }, function (newVal, oldVal) {
      if (newVal === oldVal) return;
      _this.loggedIn = !!newVal;
      _this.menu = _this.buildMenu(_this.loggedIn, _this.campaignType);
    });

    // Rebuild menu when user fundraiser collection changes

    $rootScope.$watchCollection(function () {
      return _.get(scFundraisingPagesService, 'userFundraisers');
    }, function (newVal, oldVal) {
      if (newVal === oldVal) return;
      _this.menu = _this.buildMenu(_this.loggedIn, _this.campaignType);
    });

    // Rebuild menu when user team collection changes

    $rootScope.$watchCollection(function () {
      return _.get(scFundraisingTeamsService, 'userTeams');
    }, function (newVal, oldVal) {
      if (newVal === oldVal) return;
      _this.menu = _this.buildMenu(_this.loggedIn, _this.campaignType);
    });

    // Return API object

    return _this;
  };

  /* -------------------------------------------------------------------- *
   * API
   * -------------------------------------------------------------------- */

  // Generate menu sections

  this.buildMenu = function () {
    var sectionsWithActions = [mobileMenuGroups.header(this), mobileMenuGroups.campaign(this), mobileMenuGroups.fundraisingPages(this), mobileMenuGroups.fundraisingTeams(this), mobileMenuGroups.share(this)].filter(function (val) {
      return val && !_.isEmpty(val);
    });

    return sectionsWithActions;
  };

  // set the element that triggered menu open
  this.setRef = function (ref) {
    if (ref && ref !== _this.refEl) {
      _this.refEl = ref;
    }
  };

  // Open the menu

  this.open = function (ref) {
    // set reference element to refocus on close
    _this.setRef(ref.currentTarget);
    _this.active = true;
    _this.$body.css({ overflow: 'hidden' });
  };

  // Close the menu

  this.close = function () {
    var refocus = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

    // refocus on the menu trigger element
    // only triggered if passed "true" argument
    if (_this.refEl && refocus) {
      _this.refEl.focus();
    }
    _this.$body.css({ overflow: 'auto' });
    _this.active = false;
  };
}

angular.module('classy').service('mobileMenuAPI', mobileMenuAPI);
})();