(function(){
'use strict';

angular.module('classy').value('registrationRoutes', {
  CAMPAIGN_ROUTE: 'frs.landing.name.campaign',
  BASE_ROUTE: 'frs.landing.name.campaign.registration',
  TICKETS_ROUTE: 'frs.landing.name.campaign.registration.new.tickets',
  NEW_ATTENDEE_ROUTE: 'frs.landing.name.campaign.registration.existing.attendee.new',
  ATTENDEE_ROUTE: 'frs.landing.name.campaign.registration.existing.attendee.existing',
  CAPTAIN_ROUTE: 'frs.landing.name.campaign.registration.existing.captain',
  DONATION_ROUTE: 'frs.landing.name.campaign.registration.existing.donation',
  PAYMENT_ROUTE: 'frs.landing.name.campaign.registration.existing.payment',
  RECEIPT_ROUTE: 'frs.landing.name.campaign.registration.existing.receipt'
});
})();