(function(){
'use strict';

/**
 * @kind directive
 * @name scHeroResize
 *
 * @description
 * Keeps Hero section the size of viewport and resizes other elements to correct ratios
 * within that
 */

scHeroResize.$inject = ["$window", "$timeout"];
function scHeroResize($window, $timeout) {
  return function (scope, element) {
    var w = angular.element($window),
        navigationHeight = 115,
        textPadding = 90;

    var demoHeight = void 0;

    scope.publicFacing = scope.$parent.public;

    scope.$watch(function () {
      return {
        h: w.height(),
        w: w.width()
      };
    }, function (newValue) {
      scope.windowHeight = newValue.h;
      scope.windowWidth = newValue.w;

      updateDimensions();
    }, true);

    w.bind('scHeroResize', function () {
      scope.$apply();
    });

    function updateDimensions() {
      var crowdfundingWidth = angular.element(element).width();

      /**
       * Keep fixed aspect ratio on preview page
       */
      if (!scope.publicFacing) {
        demoHeight = crowdfundingWidth * (10 / 16) - navigationHeight;
        element.height(demoHeight);
      }

      var minHeight = demoHeight || scope.windowHeight - navigationHeight,
          mediaHeight = element.find('.crowdfunding_featured').outerHeight(),
          crowdfundingHeight = element.find('.crowdfunding_header-title').height() + textPadding + mediaHeight,
          $crowdfundingHeaderArea = element.find('.crowdfunding_header');

      var newCrowdfundingHeight = crowdfundingHeight > minHeight ? crowdfundingHeight : minHeight;
      element.height(newCrowdfundingHeight);

      /**
       * Set the text area height
       */
      var headerHeight = element.height() - mediaHeight;
      $crowdfundingHeaderArea.outerHeight(headerHeight);
    }

    /**
     * If any media changes, update crowdfunding spacing
     */
    scope.$parent.$watchGroup(['block.useFeatured', 'block.featuredImage', 'block.featuredVideo', 'block.featuredType'], function () {
      $timeout(function () {
        updateDimensions();
      }, 100);
    });
  };
}

angular.module('classy').directive('scHeroResize', scHeroResize);
})();