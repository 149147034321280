(function(){
'use strict';

frsDonationGiftaidCtrl.$inject = ["$scope", "$filter"];
function frsDonationGiftaidCtrl($scope, $filter) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Functions
   * ---------------------------------------------------------------------- */

  $scope.showGiftAid = function () {
    return $scope.MODEL.payment.raw_currency_code === 'GBP';
  };

  $scope.giftAidValue = function () {
    return $filter('scFormatCurrency')($scope.MODEL.items[0].raw_final_price * 1.25 || 25, $scope.MODEL.payment.raw_currency_code, 2);
  };

  $scope.giftValue = function () {
    return $filter('scFormatCurrency')($scope.MODEL.items[0].raw_final_price || 20, $scope.MODEL.payment.raw_currency_code, 2);
  };

  $scope.giftAidSelected = function () {
    return $scope.MODEL.is_gift_aid;
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  _.merge($scope.MODEL, {
    is_gift_aid: false
  });
}

angular.module('classy').controller('frsDonationGiftaidCtrl', frsDonationGiftaidCtrl);
})();