(function(){
'use strict';

angular.module('classy').component('intelligentAskToggle', {
  templateUrl: 'global/components/intelligent-ask/toggle/global.components.intelligent-ask.toggle', // same folder as this
  bindings: {
    isIaaActive: '<',
    isRecurringAvailable: '<',
    isOneTimeAvailable: '<',
    onChange: '<'
  },
  controller: ["$log", "$scope", "$timeout", "scCampaignsService", function controller($log, $scope, $timeout, scCampaignsService) {
    var _this = this;

    var self = this;
    this.campaign = scCampaignsService.active;

    $scope.oneTime = scCampaignsService.active.hasIntelligentAskOneTime() && self.isOneTimeAvailable;
    $scope.recurring = scCampaignsService.active.hasIntelligentAskRecurring() && self.isRecurringAvailable;
    $scope.iaa = $scope.oneTime || $scope.recurring;
    $log.log('[intelligentAskToggle] init [oneTime, recurring]: ', $scope.oneTime, $scope.recurring);

    function handleChange(_ref) {
      var oneTime = _ref.oneTime,
          recurring = _ref.recurring;

      // Turn iaa off if the available options are all off
      $scope.iaa = oneTime || recurring;
      self.isIaaActive = $scope.iaa;

      $timeout(function () {
        self.onChange({ oneTime: oneTime, recurring: recurring });
      });
    }

    self.$onChanges = function (changes) {
      if (changes.isOneTimeAvailable) {
        $scope.oneTime = scCampaignsService.active.hasIntelligentAskOneTime() && changes.isOneTimeAvailable.currentValue;
      }

      if (changes.isRecurringAvailable) {
        $scope.recurring = scCampaignsService.active.hasIntelligentAskRecurring() && changes.isRecurringAvailable.currentValue;
      }

      handleChange({ oneTime: $scope.oneTime, recurring: $scope.recurring });
    };

    $scope.onIAAChange = function (iaa) {
      if (iaa) {
        // Change one-time and recurring values to false if intelligent ask amounts are disabled
        $scope.oneTime = false;
        $scope.recurring = false;
      } else {
        $scope.oneTime = true;
        $scope.recurring = true;
      }

      $timeout(function () {
        _this.onChange({ oneTime: $scope.oneTime, recurring: $scope.recurring });
      });
    };

    $scope.onOneTimeClick = function (oneTime) {
      $scope.oneTime = !oneTime;
      $timeout(handleChange({ oneTime: $scope.oneTime, recurring: $scope.recurring }));
    };

    $scope.onRecurringClick = function (recurring) {
      $scope.recurring = !recurring;
      $timeout(handleChange({ oneTime: $scope.oneTime, recurring: $scope.recurring }));
    };
  }]
});
})();