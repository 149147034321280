(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scRegistrationModel
 *
 */

scSupporterModel.$inject = ["scObjectModel", "scCloneDeep"];
function scSupporterModel(scObjectModel, scCloneDeep) {
  function SupporterModel() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var demo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    scObjectModel.call(this);

    this.isNew = !demo && _.isEmpty(data);
    this.isDemo = demo || _.isEmpty(data);

    this.current = scCloneDeep(data);
    this.defaults = {};
    this.isExample = false;
    this.saved = scCloneDeep(data);
    this.type = 'supporters';
  }

  // Defines SupporterMOdel to inherit from scObjectModel
  SupporterModel.prototype = _.create(scObjectModel.prototype, {
    constructor: SupporterModel
  });

  return SupporterModel;
}

angular.module('classy').factory('scSupporterModel', scSupporterModel);
})();