(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scFundraisingTeamDemo
 *
 * @description
 * Fundraising team demo data.
 */

scFundraisingTeamDemoService.$inject = ["scCampaignsService"];
function scFundraisingTeamDemoService(scCampaignsService) {
  return function scFundraisingTeamDemo() {
    return {
      // These values are null so they can be overridden by the campaign
      // defaults in the setup/design phase
      goal: null,
      raw_goal: scCampaignsService.active.current.default_team_goal,
      description: null,
      logo: null,
      cover_photo: null,
      percent_to_goal: 20,

      // Everything else can be customized as marketing sees fit
      name: "Team's Name",
      campaign: scCampaignsService.active.current.id,
      total_donations: 20,
      total_donors: 18,
      total_raised: 300,
      team_policy: {
        allow_subteams: false
      },
      members: {
        0: {
          title: 'Team Captain',
          type: 'fundraiser',
          alias: 'Smith McSmithy',
          id: 123
        },
        1: {
          title: 'Fundraiser',
          type: 'fundraiser',
          alias: 'Janelle Jo',
          id: 123
        }
      }
    };
  };
}

angular.module('classy').service('scFundraisingTeamDemo', scFundraisingTeamDemoService);
})();