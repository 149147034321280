(function(){
'use strict';

/**
 * @name scTrustedHtml
 * @kind filter
 */

scTrustedHtml.$inject = ["$sce"];
function scTrustedHtml($sce) {
  return function (input) {
    return $sce.trustAsHtml(input);
  };
}

angular.module('classy').filter('scTrustedHtml', scTrustedHtml);
})();