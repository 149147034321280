(function(){
'use strict';

angular.module('classy').constant('scAboutBlockDefaults', {
  title: 'About',
  type: 'about',
  disable: false,
  headline: 'About the Campaign',
  hideHeadline: false,
  headlineColor: 'rgb(26,26,26,1)',

  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  mobileTemplates: false,
  content: "<p style='font-size: 22px'>We're addressing an important issue that affects many people. Our mission is to spread awareness and raise money  for solutions we have identified.</p><p>We created this campaign for everyone to contribute at various impact levels.  Be sure to review the ways you can make an impact and please share this page with your family and friends.</p><p>Your involvement is critical to our success and solving this issue. Thank you again for your help.</p>",

  remove: false
});
})();