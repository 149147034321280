(function(){
'use strict';

scRequestAnimationFrame.$inject = ["$window"];
function scRequestAnimationFrame($window) {
  return $window.requestAnimationFrame || $window.webkitRequestAnimationFrame || $window.mozRequestAnimationFrame || function (callback) {
    $window.setTimeout(callback, 1000 / 60);
  };
}

angular.module('classy').factory('scRequestAnimationFrame', scRequestAnimationFrame);
})();