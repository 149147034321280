(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy').service('registrationNavigator', ["$filter", "$log", "$q", "$rootScope", "$state", "$window", "registrationRoutes", "scCampaignsService", "scCartService", "scFlowModalService", "scThemesService", "ipCookie", "SC_CART_COOKIE", "scValidityService", "scGetQueryParams", function ($filter, $log, $q, $rootScope, $state, $window, registrationRoutes, scCampaignsService, scCartService, scFlowModalService, scThemesService, ipCookie, SC_CART_COOKIE, scValidityService, scGetQueryParams) {
  var CAMPAIGN_ROUTE = registrationRoutes.CAMPAIGN_ROUTE,
      BASE_ROUTE = registrationRoutes.BASE_ROUTE,
      TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE,
      ATTENDEE_ROUTE = registrationRoutes.ATTENDEE_ROUTE,
      NEW_ATTENDEE_ROUTE = registrationRoutes.NEW_ATTENDEE_ROUTE,
      CAPTAIN_ROUTE = registrationRoutes.CAPTAIN_ROUTE,
      DONATION_ROUTE = registrationRoutes.DONATION_ROUTE,
      PAYMENT_ROUTE = registrationRoutes.PAYMENT_ROUTE,
      RECEIPT_ROUTE = registrationRoutes.RECEIPT_ROUTE;

  var RegistrationNavigator = function () {
    function RegistrationNavigator() {
      _classCallCheck(this, RegistrationNavigator);

      this.lastState = null;
      this.processing = false;
      this.callbacks = {
        beforeChange: [],
        change: []
      };
    }

    /** -------------------------------------------------------------------- *
     * The RegistrationNavigator's event emitter works the same way as
     * UI Router v1's state change handlers.
     *
     * The action following the event will wait until all callbacks have been
     * processed. If a callback returns boolean false (and only that value),
     * the action will be canceled.
     *
     * If the callback returns a promise, that promise will be fulfilled
     * before the action is taken. If the promise is rejected, the action
     * will be canceled.
     *
     * This allows the individual controllers to intercept and cancel
     * state changes, regardless of who calls for the state change.
     * -------------------------------------------------------------------- */

    /** -------------------------------------------------------------------- *
     * on()
     *
     * Registers a callback and returns a deregistration function.
     * -------------------------------------------------------------------- */

    _createClass(RegistrationNavigator, [{
      key: 'on',
      value: function on(event, callback) {
        var _this = this;

        this.callbacks[event].push(callback);
        return function () {
          _.remove(_this.callbacks[event], function (cb) {
            return cb === callback;
          });
        };
      }

      /** -------------------------------------------------------------------- *
       * emit()
       *
       * Fires the callbacks for an event.
       * -------------------------------------------------------------------- */

    }, {
      key: 'emit',
      value: function emit(event) {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return this.processCallback(event, 0, args);
      }

      /** -------------------------------------------------------------------- *
       * processCallback()
       *
       * Fires the callback stored at position idx with given args. If the
       * callback does not exist, the chain has finished and is resolved.
       *
       * If the result is exactly false, the chain is rejected. Otherwise,
       * processCallback is called recursively with the next index.
       * -------------------------------------------------------------------- */

    }, {
      key: 'processCallback',
      value: function processCallback(event, idx, args) {
        var _this2 = this;

        var response = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        var cb = _.get(this, ['callbacks', event, idx]);
        if (!cb) {
          return $q.resolve(response);
        }
        return $q.resolve(cb.apply(this, args)).then(function (cbResponse) {
          if (cbResponse === false) {
            return $q.reject();
          }
          return _this2.processCallback(event, idx + 1, args, cbResponse);
        });
      }

      /** -------------------------------------------------------------------- *
       * getStandardParams()
       *
       * Normally you can call $state.go with only the relevant or changing
       * state params. However, registrationNavigator handles some front-end
       * redirects that occur before there are existing params to fall back
       * to. For that reason, all transitions get the full suite of params.
       * -------------------------------------------------------------------- */

    }, {
      key: 'getStandardParams',
      value: function getStandardParams() {
        var cartData = scCartService.active.current;
        var campaignData = scCampaignsService.active.current;
        return {
          cartId: cartData.id,
          campaignId: campaignData.id,
          eventName: $filter('scSlugify')(campaignData.name).toLowerCase(),
          eventType: 'event',
          typeSlug: 'e'
        };
      }

      /** -------------------------------------------------------------------- *
       * routeBefore()
       *
       * Returns the state name and any special params for the route that
       * comes before the given route in the checkout.
       * This is called on by isActive(), which is called on any given route
       * passed through as a param. Keep in mind, we  are not always going to
       * be checking routeBefore() on the current state.
       * -------------------------------------------------------------------- */

    }, {
      key: 'routeBefore',
      value: function routeBefore(route) {
        var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var cart = scCartService.active;
        var donationEnabled = _.get(scThemesService.active.getEventStyles(), 'ticketedEvent.additionalDonation', true);
        var HAS_FUNDRAISING = scCampaignsService.active.HAS_FUNDRAISING;

        switch (route) {
          case CAMPAIGN_ROUTE:
            {
              return {
                route: CAMPAIGN_ROUTE
              };
            }

          case TICKETS_ROUTE:
            {
              return {
                route: CAMPAIGN_ROUTE
              };
            }

          case NEW_ATTENDEE_ROUTE:
            {
              // If the cart does not have items it must have been halted during the transition
              // from the tickets route to the new attendee route, thus the previous route remains
              // the tickets route.
              if (_.isEmpty(cart.current.items)) {
                return {
                  route: TICKETS_ROUTE
                };
              }

              var currItemId = _.parseInt(params.cartItemId);
              var currItemIdx = _.findIndex(cart.current.items, { id: currItemId });
              var currItem = cart.current.items[currItemIdx];

              var withQuestions = cart.itemsWithQuestions(cart.current.items);
              var currItemQuestionsIdx = _.findIndex(withQuestions, { id: currItemId });

              // If we're not on the first attendee form of the current cart item (multiattendee),
              // route to the previous attendee form of the current cart item.
              if (currItem.entries > 1 && currItem.registrants.length > 0) {
                var prevReg = _.get(currItem, ['registrants', currItem.registrants.length - 1]);

                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: currItemId,
                    attendeeId: prevReg.id
                  }
                };
              } else if (currItemIdx > 0 && HAS_FUNDRAISING) {
                // Go to FIRST attendee form of previous cart item
                var prevItem = cart.current.items[currItemIdx - 1];
                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: prevItem.id,
                    attendeeId: prevItem.registrants[0].id
                  }
                };
              } else if (currItemQuestionsIdx > 0 && !HAS_FUNDRAISING) {
                var _prevItem = withQuestions[currItemQuestionsIdx - 1];
                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: _prevItem.id,
                    attendeeId: _prevItem.registrants[0].id
                  }
                };
              }

              // If we're on first attendee form of the first cart item, previous
              // route must be tickets.
              return {
                route: TICKETS_ROUTE
              };
            }

          case ATTENDEE_ROUTE:
            {
              var attendeeId = _.parseInt(params.attendeeId);
              var _currItemId = _.parseInt(params.cartItemId);
              var _currItemIdx = _.findIndex(cart.current.items, { id: _currItemId });
              var _currItem = cart.current.items[_currItemIdx];
              var attendeeIdx = _.findIndex(_currItem.registrants, { id: attendeeId });
              var firstAttendee = attendeeId == _.get(cart, 'current.items[0].registrants[0].id') || _currItemIdx == 0 && !_currItem.registrants.length;

              var questionItems = cart.itemsWithQuestions(cart.current.items);
              var firstQuestionAttendee = attendeeId == _.get(questionItems[0], 'registrants[0].id') || questionItems.indexOf(_currItem) === 0 && !_currItem.registrants.length;

              // Prev route is TICKETS if:
              //  - checked route is the first attendee of the first item
              // OR if ticketed event type and this is the first attendee with questions
              if (firstAttendee || !HAS_FUNDRAISING && firstQuestionAttendee) {
                return {
                  route: TICKETS_ROUTE
                };
              }

              // Obtain previous attendee route on same cart item
              if (attendeeId && attendeeIdx > 0) {
                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: _currItemId,
                    attendeeId: _currItem.registrants[attendeeIdx - 1].id
                  }
                };
              }

              // case: RWF
              if (HAS_FUNDRAISING) {
                // Obtain last attendee route on previous cart item
                var _prevItem2 = cart.current.items[_currItemIdx - 1];
                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: _prevItem2.id,
                    attendeeId: _.last(_prevItem2.registrants).id
                  }
                };
              }

              // case: ticketed
              var prevQuestionItem = cart.previousItemWithQuestions(cart.current.items, _currItem);
              return {
                route: ATTENDEE_ROUTE,
                params: {
                  cartItemId: prevQuestionItem.id,
                  attendeeId: _.last(prevQuestionItem.registrants).id
                }
              };
            }

          case CAPTAIN_ROUTE:
            {
              var lastItemIdx = cart.current.items.length - 1;
              var lastItem = cart.current.items[lastItemIdx];
              return {
                route: ATTENDEE_ROUTE,
                params: {
                  cartItemId: lastItem.id,
                  attendeeId: _.last(lastItem.registrants).id
                }
              };
            }

          case DONATION_ROUTE:
            {
              // for the ticketed event type
              if (!HAS_FUNDRAISING) {
                var _lastItemIdx = cart.lastItemWithQuestionsIdx(cart.current.items);

                if (_lastItemIdx > -1) {
                  var _lastItem = cart.current.items[_lastItemIdx];
                  // go to the latest attendee with questions
                  return {
                    route: ATTENDEE_ROUTE,
                    params: {
                      cartItemId: _lastItem.id,
                      attendeeId: _.last(_lastItem.registrants).id
                    }
                  };
                }
                // if there are no attendees with questions, go to campaign route
                return {
                  route: TICKETS_ROUTE
                };
              } else if (!cart.current.team_name || cart.soloEntry) {
                var _lastItemIdx2 = cart.current.items.length - 1;
                var _lastItem2 = cart.current.items[_lastItemIdx2];
                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: _lastItem2.id,
                    attendeeId: _.last(_lastItem2.registrants).id
                  }
                };
              }
              return {
                route: CAPTAIN_ROUTE
              };
            }

          case PAYMENT_ROUTE:
            {
              // When donation step disabled, go to the last attendee of the last cart item.
              if (!donationEnabled && HAS_FUNDRAISING) {
                var lastCartItem = _.last(cart.current.items);
                var lastAttendee = _.last(lastCartItem.registrants);
                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: lastCartItem.id,
                    attendeeId: lastAttendee.id
                  }
                };
              }

              // case: ticketed events
              if (!HAS_FUNDRAISING && !donationEnabled) {
                var _questionItems = cart.itemsWithQuestions(cart.current.items);

                // if there are any items with questions, go to the last attendee
                if (_questionItems.length) {
                  var lastQuestionItem = _.last(cart.itemsWithQuestions(_questionItems));
                  var _lastAttendee = _.last(lastQuestionItem.registrants);
                  return {
                    route: ATTENDEE_ROUTE,
                    params: {
                      cartItemId: lastQuestionItem.id,
                      attendeeId: _lastAttendee.id
                    }
                  };
                }

                // otherwise we're going back to ticket select and basically starting over
                return {
                  route: TICKETS_ROUTE
                };
              }

              return {
                route: DONATION_ROUTE
              };
            }

          case RECEIPT_ROUTE:
            {
              return {
                route: PAYMENT_ROUTE
              };
            }

          default:
            return undefined;
          // switch should be exhaustive
        }
      }

      /** -------------------------------------------------------------------- *
       * routeAfter()
       *
       * Returns the state name and any special params for the route that
       * comes after the given route in the checkout.
       * -------------------------------------------------------------------- */

    }, {
      key: 'routeAfter',
      value: function routeAfter(route) {
        var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var cart = scCartService.active;
        var donationEnabled = _.get(scThemesService.active.getEventStyles(), 'ticketedEvent.additionalDonation', true);

        switch (route) {
          case TICKETS_ROUTE:
            {
              return {
                route: NEW_ATTENDEE_ROUTE,
                params: {
                  cartItemId: _.get(cart, ['current', 'items', 0, 'id']),
                  attendeeIndex: 0
                }
              };
            }

          case NEW_ATTENDEE_ROUTE:
            {
              var currItemId = _.parseInt(params.cartItemId);
              var currItemIdx = _.findIndex(cart.stagedItems, { id: currItemId });
              var currItem = cart.current.items[currItemIdx];

              var nextItem = cart.stagedItems[currItemIdx + 1];
              var isRemoved = _.findIndex(cart.current.items, { id: currItemId }) < 0;
              var stagedItem = cart.stagedItems[currItemIdx];

              var savedAttendees = _.get(currItem, 'registrants.length', 0);
              var totalAttendees = _.get(stagedItem, 'registrants.length', 1);

              // Multiple attendees & more attendees to fill, go to next attendee
              if (!isRemoved && totalAttendees > 1 && savedAttendees !== totalAttendees - 1) {
                return {
                  route: NEW_ATTENDEE_ROUTE,
                  params: {
                    cartItemId: stagedItem.id
                  }
                };
              } else if (nextItem) {
                // Multiple attendees & on last attendee form, or single attendees
                return {
                  route: NEW_ATTENDEE_ROUTE,
                  params: {
                    cartId: cart.current.id,
                    cartItemId: nextItem.id
                  }
                };
              } else if (cart.current.team_name && !cart.soloEntry) {
                return {
                  route: CAPTAIN_ROUTE
                };
              } else if (!donationEnabled) {
                return {
                  route: PAYMENT_ROUTE
                };
              }

              return {
                route: DONATION_ROUTE
              };
            }

          case ATTENDEE_ROUTE:
            {
              var _currItemId2 = _.parseInt(params.cartItemId);
              var _currItemIdx2 = _.findIndex(cart.stagedItems, { id: _currItemId2 });
              var _nextItem = cart.stagedItems[_currItemIdx2 + 1];

              var _currItem2 = cart.stagedItems[_currItemIdx2];
              var _totalAttendees = _.get(_currItem2, 'registrants.length', 1);

              var currRegIdx = _.findIndex(_currItem2.registrants, function (item) {
                return item.id == $state.params.attendeeId;
              });
              var nextReg = _.get(_currItem2, ['registrants', currRegIdx + 1], false);

              // If current registration allows multiple attendees,
              // route to next attendee form.
              if (_totalAttendees > 1 && nextReg.id) {
                return {
                  route: ATTENDEE_ROUTE,
                  params: {
                    cartItemId: _currItemId2,
                    attendeeId: nextReg.id
                  }
                };
                // if this is a multiple registration and next reg doesn't exist yet and
                // this current reg index isn't the last in the item, make the next new reg
              } else if (_totalAttendees > 1 && !nextReg.id && currRegIdx != _totalAttendees - 1) {
                return {
                  route: NEW_ATTENDEE_ROUTE,
                  params: {
                    cartItemId: _currItemId2
                  }
                };
                // RWF specific
                // this is the last reg in this item and there is another item, make a new item
              } else if (currRegIdx == _totalAttendees - 1 && _nextItem) {
                return {
                  route: NEW_ATTENDEE_ROUTE,
                  params: {
                    cartItemId: _nextItem.id
                  }
                };
                // RWF specific
                // we are creating a new team and the cart has multiple attendees, go to captain
              } else if (cart.current.team_name && !cart.soloEntry) {
                return {
                  route: CAPTAIN_ROUTE
                };
                // if donation is not enabled, go straight to payment
              } else if (!donationEnabled) {
                return {
                  route: PAYMENT_ROUTE
                };
              }
              // finally, go to donation
              return {
                route: DONATION_ROUTE
              };
            }

          case CAPTAIN_ROUTE:
            {
              if (!donationEnabled) {
                return {
                  route: PAYMENT_ROUTE
                };
              }
              return {
                route: DONATION_ROUTE
              };
            }

          case DONATION_ROUTE:
            {
              return {
                route: PAYMENT_ROUTE
              };
            }

          case PAYMENT_ROUTE:
            {
              return {
                route: RECEIPT_ROUTE,
                params: {
                  verify: _.get(cart, 'metadata.verify')
                }
              };
            }

          default:
            // switch should be exhaustive
            return undefined;
        }
      }

      /** -------------------------------------------------------------------- *
       * isFinished()
       *
       * Returns true if the given state has been finished.
       * -------------------------------------------------------------------- */

    }, {
      key: 'isFinished',
      value: function isFinished(route) {
        var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var cart = scCartService.active;

        switch (route) {
          case CAMPAIGN_ROUTE:
            {
              return true;
            }

          case TICKETS_ROUTE:
            {
              return cart.isReserved;
            }

          case NEW_ATTENDEE_ROUTE:
            {
              var cartItemId = _.parseInt(params.cartItemId);
              var item = _.find(cart.current.items, { id: cartItemId });
              // this state is only finished if registrants.length is equivalent to num entries needed
              return item.registrants.length == item.entries;
            }

          case ATTENDEE_ROUTE:
            {
              var _cartItemId = _.parseInt(params.cartItemId);
              var _item = _.find(cart.current.items, { id: _cartItemId });
              return !!_item.registrants.length;
            }

          case CAPTAIN_ROUTE:
            {
              return cart.soloEntry || !cart.current.team_name || cart.current.team_lead_id;
            }

          case DONATION_ROUTE:
            {
              return true;
            }

          case PAYMENT_ROUTE:
            {
              return cart.isComplete;
            }

          default:
            // switch should be exhaustive
            return undefined;
        }
      }

      /** -------------------------------------------------------------------- *
       * isActive()
       *
       * Returns true if the given state may be presented as "available" to
       * the user.
       *
       * Note that this returns true even if the current form is in an
       * invalid state, or has unsaved changes. In this case other routes may
       * still be "available" to the user to navigate to, they will just be
       * prompted to discard changes first. (That interaction is handled by
       * the individual views.)
       * -------------------------------------------------------------------- */

    }, {
      key: 'isActive',
      value: function isActive(route) {
        var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var cart = scCartService.active;
        var prev = this.routeBefore(route, params);
        if (this.processing) {
          // Can't access if processing.
          return false;
        } else if (!this.isFinished(prev.route, prev.params)) {
          // Can't access if the previous step is not yet finished.
          return false;
        } else if (cart.isErrored && route !== RECEIPT_ROUTE && route !== PAYMENT_ROUTE) {
          // Can't go back once the cart has errored.
          return false;
        }
        return true;
      }

      /** -------------------------------------------------------------------- *
       * prev()
       *
       * Go back to the previous view in the checkout.
       * -------------------------------------------------------------------- */

    }, {
      key: 'prev',
      value: function prev() {
        var route = $state.current.name;
        var params = {
          cartItemId: _.parseInt($state.params.cartItemId) || null,
          attendeeId: _.parseInt($state.params.attendeeId) || null
        };
        var prev = this.routeBefore(route, params);
        var force = _.get(prev, 'params.force', false);
        this.go(prev.route, prev.params, force);
      }

      /** -------------------------------------------------------------------- *
       * next()
       *
       * Go forward to the next view in the checkout.
       * -------------------------------------------------------------------- */

    }, {
      key: 'next',
      value: function next() {
        var cartItemId = _.parseInt($state.params.cartItemId);
        var routeParams = cartItemId ? { cartItemId: cartItemId } : {};

        var _routeAfter = this.routeAfter($state.current.name, routeParams),
            route = _routeAfter.route,
            params = _routeAfter.params;

        this.go(route, params);
      }

      /** -------------------------------------------------------------------- *
       * evacuate()
       *
       * If the user is viewing the provided cart item, force a transition to
       * the next state.
       * -------------------------------------------------------------------- */

    }, {
      key: 'evacuate',
      value: function evacuate(evacItemId) {
        var route = $state.current.name;
        var cartItemId = _.parseInt($state.params.cartItemId);
        if (cartItemId === evacItemId) {
          var params = { cartItemId: cartItemId };
          var next = this.routeAfter(route, params);
          _.remove(scCartService.active.stagedItems, { id: evacItemId });
          return this.go(next.route, next.params, true);
        }
        _.remove(scCartService.active.stagedItems, { id: evacItemId });
        return $q.resolve();
      }

      /** -------------------------------------------------------------------- *
       * setLastState()
       *
       * If the user is navigating away from the checkout flow, save the
       * last state info so they can return to the same view when returning
       * to checkout via register button or header links.
       * -------------------------------------------------------------------- */

    }, {
      key: 'setLastState',
      value: function setLastState() {
        var cart = scCartService.active;
        var cookieName = SC_CART_COOKIE + '_' + cart.current.campaign_id;
        var params = $state.params;
        var route = $state.current.name;
        var cartData = {
          id: cart.current.id,
          metadata: cart.metadata,
          lastState: {
            route: route,
            params: params
          }
        };

        var validityModel = scValidityService.get('registration');
        var attendeeValidity = _.get(validityModel.get('attendeeForm'), 'valid');

        // when navigating from completed new attendee form,
        // save the attendee via regAttendee's onBeforeChange() before proceeding
        if ($state.is(NEW_ATTENDEE_ROUTE) && attendeeValidity) {
          this.emit('beforeChange', $state.current.name, $state.params, null, null).then(function () {
            var cartItemIdx = _.findIndex(cart.current.items, {
              id: _.parseInt($state.params.cartItemId)
            });

            route = ATTENDEE_ROUTE;
            params.attendeeId = _.last(cart.current.items[cartItemIdx].registrants).id;

            cartData = {
              id: cart.current.id,
              metadata: cart.metadata,
              lastState: {
                route: route,
                params: params
              }
            };

            ipCookie(cookieName, cartData, {
              expires: cart.timeRemaining,
              expirationUnit: 'milliseconds',
              path: '/'
            });
          });
        } else {
          ipCookie(cookieName, cartData, {
            expires: cart.timeRemaining,
            expirationUnit: 'milliseconds',
            path: '/'
          });
        }
      }
    }, {
      key: 'goToLastState',
      value: function goToLastState() {
        var cart = scCartService.active;
        var cookieName = SC_CART_COOKIE + '_' + cart.current.campaign_id;
        var lastState = ipCookie(cookieName).lastState;

        if (_.get(lastState, 'route')) {
          $state.go(lastState.route, lastState.params);
        } else {
          // If we cannot find the last state, or if it was never set,
          // default to going to the first attendee form for the existing cart.
          this.go(ATTENDEE_ROUTE, {
            attendeeId: _.get(cart, 'current.items[0].registrants[0].id'),
            cartId: cart.current.id,
            campaignId: scCampaignsService.active.current.id,
            cartItemId: cart.current.items[0].id
          }, true);
        }
      }

      /** -------------------------------------------------------------------- *
       * go()
       *
       * Wrapper for $state.go that fills in missing param data and runs
       * callbacks to the change event before transitioning.
       * -------------------------------------------------------------------- */

    }, {
      key: 'go',
      value: function go(toRoute) {
        var _this3 = this;

        var toParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var force = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        this.stateChangeActive = true;

        var fromRoute = $state.current.name;
        var fromParams = $state.current.params;

        if (force) {
          $state.go(toRoute, toParams);
          this.stateChangeActive = false;
          return $q.resolve();
        }

        return this.runBeforeChangeCallbacks(fromRoute, fromParams, toRoute, toParams).then(function (dest) {
          if (!_this3.isActive(dest.route, dest.params)) {
            $log.info('Aborting transition, target route is inactive');
            return $q.reject();
          }
          return _this3.runChangeCallbacks(fromRoute, fromParams, dest.route, dest.params);
        }).then(function (dest) {
          _this3.processing = true;
          dest.params = _.merge(_this3.getStandardParams(), dest.params);

          // When moving between attendee forms, we need to reload state
          // in order to refresh the models.
          var reload = false;
          if (fromRoute.indexOf('attendee') >= 0 && dest.route.indexOf('attendee') >= 0) {
            reload = true;
          }
          $state.go(dest.route, dest.params, { reload: reload });
        }).finally(function () {
          _this3.processing = false;
          _this3.stateChangeActive = false;
        });
      }
    }, {
      key: 'runBeforeChangeCallbacks',
      value: function runBeforeChangeCallbacks(fromRoute, fromParams, toRoute, toParams) {
        return this.emit('beforeChange', fromRoute, fromParams, toRoute, toParams).then(function (dest) {
          // Check for user-provided redirect
          if (_.isObject(dest) && dest.route) {
            return dest;
          }

          return {
            route: toRoute,
            params: toParams
          };
        });
      }
    }, {
      key: 'runChangeCallbacks',
      value: function runChangeCallbacks(fromRoute, fromParams, toRoute, toParams) {
        return this.emit('change', fromRoute, fromParams, toRoute, toParams).then(function (dest) {
          // Check for user-provided redirect
          if (_.isObject(dest) && dest.route) {
            return dest;
          }
          return {
            route: toRoute,
            params: toParams
          };
        });
      }

      /** -------------------------------------------------------------------- *
       * validate()
       *
       * Redirect the user based on cart status. Called by UI Router on each
       * state change within or into the registration flow.
       * -------------------------------------------------------------------- */

    }, {
      key: 'validate',
      value: function validate(route, params) {
        var _this4 = this;

        $log.info('Validating state: ' + route);

        var cart = scCartService.active;
        var firstItemId = _.get(cart, 'current.items[0].id', null);
        var HAS_FUNDRAISING = scCampaignsService.active.HAS_FUNDRAISING;

        if (cart.isErrored && route !== PAYMENT_ROUTE) {
          $log.info('Cart is in error state and cannot be edited; redirecting to PAYMENT_ROUTE.');
          $state.go(PAYMENT_ROUTE, params);
          return $q.reject();
        }

        if (cart.isComplete && route !== RECEIPT_ROUTE) {
          $log.info('Cart is complete; starting over.');
          scCartService.create().then(function () {
            return $state.go(TICKETS_ROUTE, params);
          });
          return $q.reject();
        }

        if (!cart.isReserved && route !== TICKETS_ROUTE) {
          $log.info('Cart is not reserved; redirecting to CAMPAIGN_ROUTE');
          $state.go(CAMPAIGN_ROUTE, params);
          return $q.reject();
        }

        switch (route) {
          case TICKETS_ROUTE:
            {
              if (cart.isReserved) {
                var currItemId = parseInt($state.params.cartItemId, 10);
                var itemsWithQuestions = cart.itemsWithQuestions(cart.current.items);
                var firstItemIdWithQuestions = _.get(itemsWithQuestions, [0, 'id'], false);
                var onFirstNewAttendeeForm = $state.is(NEW_ATTENDEE_ROUTE) && !_.get(cart, 'stagedItems[0].registrants[0].id', false);
                var onFirstExistingAttendeeForm = $state.is(ATTENDEE_ROUTE) && _.get($state, 'params.attendeeId') == cart.current.items[0].registrants[0].id;
                var onFirstItemWithQuestions = currItemId === firstItemIdWithQuestions;
                // In RwF, if going back to select-tickets screen from the first attendee form in cart,
                // let the user know this will cause them to lose their cart items.
                //
                // In Ticketed, use the same behavior if going back from the last cart item with
                // questions.
                if ((currItemId === firstItemId || !HAS_FUNDRAISING && onFirstItemWithQuestions) && (onFirstNewAttendeeForm || onFirstExistingAttendeeForm)) {
                  this.confirmExit(function (reroute) {
                    // User has decided to stay on the page.
                    if (!reroute) {
                      return $q.resolve(false);
                    }

                    if (scCampaignsService.active.current.team_membership_policy == 'required') {
                      $state.go(CAMPAIGN_ROUTE);
                      return $q.resolve(true);
                    }

                    var currLocation = document.location.origin;
                    var eventSlug = $window.getSlug(scCampaignsService.active.current.name);
                    var ticketsURL = currLocation + '/event/' + eventSlug + '/e' + scCartService.active.current.campaign_id + '/register/new/select-tickets';
                    /* To persist URL params in case of start over functionality */
                    _this4.urlParams = scGetQueryParams();
                    if (_this4.urlParams.promo) {
                      ticketsURL += '?promo=' + _this4.urlParams.promo;
                    }
                    // Since we have already prompted the user with the confirmExit modal,
                    // deregister the unload event that was added in registrationCtrl
                    // to prevent user from being prompted twice (1. appv2 modal and 2. browser)
                    // Changing window location prevents unwanted locations from being pushed
                    // the the browser history on certain state changes triggered by browser
                    // back button.
                    angular.element($window).off('beforeunload');
                    $window.location.href = ticketsURL;

                    return $q.resolve(true);
                  });

                  // If the user lands on select-tickets but already has a cart reserved, redirect
                  // to the appropriate attendee route. In RwF, this is just the first item. In
                  // Ticketed, it's the first item with questions.
                } else {
                  var redirectItemId = HAS_FUNDRAISING ? firstItemId : firstItemIdWithQuestions;
                  if (redirectItemId === false) {
                    // redirectItemId will ONLY be false if this is a TICKETED event and
                    // there are no tickets with questions
                    // just go to donation route, it'll figure out whether to go to payment or not
                    $state.go(DONATION_ROUTE, _.merge(params, {
                      cartId: cart.current.id
                    }));
                  } else {
                    var isNew = _.find(cart.current.items, { id: redirectItemId }).registrants.length === 0;
                    var attendeeId = _.get(_.find(cart.current.items, { id: redirectItemId }), 'registrants[0].id');
                    $log.info('Cart is reserved; redirecting to ' + (isNew ? 'NEW_ATTENDEE_ROUTE' : 'ATTENDEE_ROUTE') + ' with id ' + redirectItemId);
                    $state.go(isNew ? NEW_ATTENDEE_ROUTE : ATTENDEE_ROUTE, _.merge(params, {
                      cartId: cart.current.id,
                      cartItemId: redirectItemId,
                      attendeeId: attendeeId
                    }));
                  }
                  return $q.reject();
                }
                return $q.reject();

                // check TEAM MEMBERSHIP POLICY before proceeding
              } else if (scCampaignsService.active.current.team_membership_policy == 'required') {
                var campaign = scCampaignsService.active.current;
                var localStorageTeam = JSON.parse(window.localStorage.getItem('reg-cart-team'));

                // if there is no team being passed through when a team is required,
                // kick back to START FUNDRAISING route.
                if (!params.team_name && (!params.team_id || !localStorageTeam)) {
                  $state.go('frs.landing.name.campaign.start-fundraising', {
                    campaignId: campaign.id,
                    eventName: params.eventName,
                    eventType: 'event',
                    typeSlug: 'e'
                  });
                  return $q.reject();

                  // if we've already chosen a team to join and are simply refreshing the page,
                  // we can proceed. just make sure to restore the...
                  //   - team id on cart because this is required to preserve team for registration
                  //   - team name on cart for UI purposes
                } else if (localStorageTeam) {
                  cart.current.team_id = localStorageTeam.id;
                  cart.current.team_name = localStorageTeam.name;
                }
              }
              break;
            }

          case NEW_ATTENDEE_ROUTE:
            {
              scCartService.syncStagedItems();

              var _currItemId3 = parseInt(params.cartItemId, 10);
              var currItem = _.find(cart.current.items, { id: _currItemId3 });
              var stagedItems = cart.stagedItems;
              var _itemsWithQuestions = cart.itemsWithQuestions(stagedItems);

              // If the cartItemId in the URL doesn't match an item in the cart, immediately push
              // the user to another area of the cart. In RWF, this can just be the first item.
              // In ticketed, this needs to be either the first item with questions or the donation
              // route.
              if (!currItem && HAS_FUNDRAISING) {
                $state.go(ATTENDEE_ROUTE, _.merge(params, {
                  cartId: cart.current.id,
                  cartItemId: firstItemId
                }));
                return $q.reject();
              } else if (!currItem && !HAS_FUNDRAISING && _itemsWithQuestions.length) {
                $state.go(ATTENDEE_ROUTE, _.merge(params, {
                  cartId: cart.current.id,
                  cartItemId: _itemsWithQuestions[0]
                }));
                return $q.reject();
              } else if (!currItem && !HAS_FUNDRAISING && !_itemsWithQuestions.length) {
                $state.go(DONATION_ROUTE, _.merge(params, {
                  cartId: cart.current.id
                }));
                return $q.reject();
              }

              // TICKETED:
              // If the current item has questions, we'll show the user the form for that item's
              // first registrant. Otherwise we'll skip the item entirely. What we skip to depends
              // on whether any subsequent items have questions, so we'll answer that question here.
              //
              // nextItemWithQuestions is false if if no subsequent cart items have questions.
              var itemHasQuestions = cart.itemHasQuestions(currItem);
              var nextItemWithQuestions = cart.nextItemWithQuestions(stagedItems, currItem);

              // In RwF, all staged items need a fist_name, last_name, and email_address, so we will
              // resolve the new attendee route.
              // In Ticketed, we only resolve the new attendee route if there are custom questions
              // for the cart item.
              if (HAS_FUNDRAISING || itemHasQuestions) {
                var itemCompleted = _.get(currItem, 'registrants.length', 0) == _.get(currItem, 'entries', 1);
                // If the number of registrants on this item is equal to the number of entries,
                // load up the existing attendee route instead of loading the new attendee route
                if (itemCompleted) {
                  var firstAttendeeId = _.get(currItem, 'registrants[0].id');
                  $state.go(ATTENDEE_ROUTE, _.merge(params, { attendeeId: firstAttendeeId }));
                  return $q.reject();
                }

                // Otherwise, the correct route is to validate the new attendee page
                return $q.resolve(true);

                // If there are no questions for the requested cart item, try to go to the next cart
                // item that DOES have questions.
              } else if (!itemHasQuestions && nextItemWithQuestions) {
                $state.go(NEW_ATTENDEE_ROUTE, _.merge(params, {
                  cartId: cart.current.id,
                  cartItemId: nextItemWithQuestions.id
                }));
                return $q.reject();

                // If no subsequent cart items have questions, move on to the donation route.
              } else if (!nextItemWithQuestions) {
                $state.go(DONATION_ROUTE, _.merge(params, {
                  cartId: cart.current.id
                }));
                return $q.reject();
              }
              break;
            }

          case ATTENDEE_ROUTE:
            {
              // When hitting attendee route directly, initialize stagedItems first.
              scCartService.syncStagedItems();
              if (!_.find(cart.current.items, { id: _.parseInt(params.cartItemId) })) {
                $state.go(ATTENDEE_ROUTE, _.merge(params, {
                  cartId: cart.current.id,
                  cartItemId: firstItemId
                }));
                return $q.reject();
              }
              if (!this.isActive(ATTENDEE_ROUTE, params)) {
                $state.go(ATTENDEE_ROUTE, _.merge(params, {
                  cartId: cart.current.id,
                  cartItemId: firstItemId
                }));
                return $q.reject();
              }
              // if specific attendee form is not provided, route to last saved attendee
              if (!params.attendeeId) {
                var _currItem3 = _.find(cart.current.items, function (item) {
                  return item.id === _.parseInt(params.cartItemId, 10);
                });
                var lastReg = _.last(_currItem3.registrants);
                // if no attendee was ever saved, route to a new attendee form for the cart item.
                if (!lastReg) {
                  $state.go(NEW_ATTENDEE_ROUTE, params);
                  return $q.reject();
                }
                $state.go(ATTENDEE_ROUTE, _.merge(params, {
                  cartId: cart.current.id,
                  cartItemId: params.cartItemId,
                  attendeeId: lastReg.id
                }));

                return $q.reject();
              }
              break;
            }

          case CAPTAIN_ROUTE:
            {
              // When hitting captain route directly, initialize stagedItems first.
              scCartService.syncStagedItems();

              // if we aren't absolutely finisehd with every registration, we aren't ready
              if (!_.every(cart.current.items, function (item) {
                return _this4.isFinished(NEW_ATTENDEE_ROUTE, { cartItemId: item.id });
              })) {
                $state.go(ATTENDEE_ROUTE, _.merge(params, {
                  cartId: cart.current.id,
                  cartItemId: firstItemId
                }));
                return $q.reject();
              }

              // if we aren't creating a new team for this or there is only one registrant
              if (!cart.current.team_name || cart.soloEntry) {
                $state.go(DONATION_ROUTE, _.merge(params, {
                  cartId: cart.current.id
                }));
                return $q.reject();
              }
              break;
            }

          case DONATION_ROUTE:
            {
              var settings = scThemesService.active.getEventStyles();

              // SKIP additional donation route if campaign has turned off this step
              if (!_.get(settings, 'ticketedEvent.additionalDonation', true)) {
                $state.go(PAYMENT_ROUTE, params);
                return $q.reject();
              }

              if (cart.isReserved) {
                scCartService.syncStagedItems();
                return $q.resolve(true);
              }
              break;
            }

          case PAYMENT_ROUTE:
            {
              if (cart.isReserved) {
                scCartService.syncStagedItems();
                if (cart.current.team_name && !cart.current.team_lead_id && cart.soloEntry) {
                  cart.current.team_lead_id = cart.current.items[0].registrants[0].id;
                  return cart.saveCart();
                }
                return $q.resolve(true);
              }
              break;
            }

          default:
            {
              if (!cart.isReserved) {
                $log.info('Cart is not reserved; redirecting to TICKETS_ROUTE');
                $state.go(TICKETS_ROUTE, params);
                return $q.reject();
              }
            }
        }
        return $q.resolve(true);
      }

      /** -------------------------------------------------------------------- *
       * validateExit()
       *
       * Returns true if the user may exit.
       * -------------------------------------------------------------------- */

    }, {
      key: 'validateExit',
      value: function validateExit() {
        var cart = scCartService.active;

        // Don't allow user to exit checkout if current form is invalid
        var validityModel = scValidityService.get('registration');
        if (($state.is(ATTENDEE_ROUTE) || $state.is(NEW_ATTENDEE_ROUTE)) && cart.isReserved) {
          return _.get(validityModel.get('attendeeForm'), 'valid');
        }

        return !$state.includes(BASE_ROUTE) || $state.is(RECEIPT_ROUTE) || cart.isReserved || cart.isPristine;
      }

      /** -------------------------------------------------------------------- *
       * confirmExit()
       *
       * Display confirmation modal.
       * -------------------------------------------------------------------- */

    }, {
      key: 'confirmExit',
      value: function confirmExit(onExit) {
        var _this5 = this;

        var modalScope = $rootScope.$new();

        modalScope.isReserved = scCartService.active.isReserved;

        modalScope.handleExit = function () {
          scCartService.create().then(function () {
            scFlowModalService.close();
            onExit(true);
          });
        };

        modalScope.handleCancel = function () {
          scFlowModalService.close();
          onExit(false);
        };

        modalScope.primaryColor = scThemesService.active.current.styles.primaryColor;

        var modalOptions = {
          animate: false,
          onClose: function onClose() {
            modalScope.$destroy();
            _this5.modalOpen = false;
          }
        };

        var modalTemplate = '\n            <exit-cart-modal\n              is-reserved="isReserved"\n              exit-action="handleExit()"\n              stay-action="handleCancel()"\n              primary-color="primaryColor">\n            </exit-cart-modal>\n          ';

        if (!this.modalOpen) {
          this.modalOpen = true;
          scFlowModalService.open({
            id: 'rwf_exit_confirmation',
            context: modalScope,
            template: modalTemplate
          }, modalOptions);
        }
      }

      /** -------------------------------------------------------------------- *
       * confirmUnload()
       *
       * Conditionally display native confirmation prompt, where supported,
       * if user attempts to exit the checkout by clicking on an external
       * link.
       *
       * This is a last-ditch attempt to prevent the user from inadvertently
       * abandoning their cart. iOS does not support unload handlers at all,
       * while most modern browsers will display a generic confirmation
       * prompt and ignore the message. These are known and to my knowledge
       * unavoidable limitations.
       * -------------------------------------------------------------------- */

    }, {
      key: 'confirmUnload',
      value: function confirmUnload(e) {
        var MESSAGE = '\n          Your cart is not yet reserved! Are you sure you\'d like to leave?\n        ';
        e.returnValue = MESSAGE;
        return MESSAGE;
      }
    }]);

    return RegistrationNavigator;
  }();

  return new RegistrationNavigator();
}]);
})();