(function(){
'use strict';

donationShippingCtrl.$inject = ["$scope"];
function donationShippingCtrl($scope) {
  /**
   * Copy payment to shipping info
   */
  $scope.copyToPayment = function (checked) {
    if (checked) {
      $scope.Donation.payment.address = $scope.Donation.shipping.address;
      $scope.Donation.payment.address2 = $scope.Donation.shipping.address2;
      $scope.Donation.payment.city = $scope.Donation.shipping.city;
      $scope.Donation.payment.state = $scope.Donation.shipping.state;
      $scope.Donation.payment.zip = $scope.Donation.shipping.zip;
    } else {
      $scope.Donation.payment.address = SC.donation.payment.address ? SC.donation.payment.address : '';
      $scope.Donation.payment.address2 = SC.donation.payment.address2 ? SC.donation.payment.address2 : '';
      $scope.Donation.payment.city = SC.donation.payment.city ? SC.donation.payment.city : '';
      $scope.Donation.payment.state = SC.donation.payment.state ? SC.donation.payment.state : '';
      $scope.Donation.payment.zip = SC.donation.payment.zip ? SC.donation.payment.zip : '';
    }
  };
}

angular.module('classy').controller('donationShippingCtrl', donationShippingCtrl);
})();