(function(){
'use strict';

angular.module('classy').component('dateDropdownControl', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n    <ng-form>\n      <div class="column-md-third">\n        <select\n        class="form__select--dark"\n        ng-model="$ctrl.monthValue"\n        ng-change="$ctrl.populateDays(); $ctrl.updateModel()"\n        ng-options="month.value as month.label for month in $ctrl.monthOptions"\n        aria-label="Please select a month">\n          <option value="" selected>Month</option>\n        </select>\n      </div>\n\n      <div class="column-md-third">\n        <select\n        class="form__select--dark"\n        ng-model="$ctrl.dayValue"\n        ng-change="$ctrl.updateModel()"\n        ng-options="day.value as day.label for day in $ctrl.dayOptions"\n        aria-label="Please select a day">\n          <option value="" selected>Day</option>\n        </select>\n      </div>\n\n      <div class="column-md-third">\n        <select\n        class="form__select--dark"\n        ng-model="$ctrl.yearValue"\n        ng-change="$ctrl.populateDays(); $ctrl.updateModel()"\n        ng-options="year.value as year.label for year in $ctrl.yearOptions"\n        aria-label="Please select a year">\n          <option value="" selected>Year</option>\n        </select>\n      </div>\n\n    </ng-form>\n    ',
  controller: function DropdownControlCtrl() {
    var _this = this;

    this.monthOptions = [];
    this.dayOptions = [];
    this.yearOptions = [];

    /* -------------------------------------------------------------------- *
     * Lifecycle
     * -------------------------------------------------------------------- */

    this.$onInit = function () {
      this.allEntered = false;
      this.populateMonths();
      this.populateYears();
      this.populateDays();
    };

    this.$postLink = function () {
      _this.setupNgModel();
    };

    /* -------------------------------------------------------------------- *
     * ngModel Integration
     * -------------------------------------------------------------------- */

    this.setupNgModel = function () {
      var _this2 = this;

      this.ngModelCtrl.$validators.monthRequired = function (modelValue, viewValue) {
        var value = modelValue || viewValue;
        if (!value || value instanceof Date) return true;
        var month = value.split('/')[0];
        return (/\d{2}/.test(month)
        );
      };

      this.ngModelCtrl.$validators.dayRequired = function (modelValue, viewValue) {
        var value = modelValue || viewValue;
        if (!value || value instanceof Date) return true;
        var day = value.split('/')[1];
        return (/\d{2}/.test(day)
        );
      };

      this.ngModelCtrl.$validators.yearRequired = function (modelValue, viewValue) {
        var value = modelValue || viewValue;
        if (!value || value instanceof Date) return true;
        var year = value.split('/')[2];
        return (/\d{4}/.test(year)
        );
      };

      this.ngModelCtrl.$render = function () {
        if (!_this2.ngModelCtrl.$modelValue) return;
        var saved = moment(_this2.ngModelCtrl.$modelValue);
        _this2.monthValue = saved.format('MM');
        _this2.dayValue = saved.format('DD');
        _this2.yearValue = saved.format('YYYY');
      };
    };

    /* -------------------------------------------------------------------- *
     * Options
     * -------------------------------------------------------------------- */

    this.populateMonths = function () {
      Array.prototype.push.apply(this.monthOptions, [{
        label: 'January',
        value: '01'
      }, {
        label: 'February',
        value: '02'
      }, {
        label: 'March',
        value: '03'
      }, {
        label: 'April',
        value: '04'
      }, {
        label: 'May',
        value: '05'
      }, {
        label: 'June',
        value: '06'
      }, {
        label: 'July',
        value: '07'
      }, {
        label: 'August',
        value: '08'
      }, {
        label: 'September',
        value: '09'
      }, {
        label: 'October',
        value: '10'
      }, {
        label: 'November',
        value: '11'
      }, {
        label: 'December',
        value: '12'
      }]);
    };

    this.populateYears = function () {
      var year = _.parseInt(moment().format('YYYY'));
      var firstYear = year - 120;
      while (year >= firstYear) {
        this.yearOptions.push({
          label: year.toString(),
          value: year.toString()
        });
        year -= 1;
      }
    };

    this.populateDays = function () {
      this.dayOptions.length = 0;
      var year = _.parseInt(this.yearValue);
      var month = _.parseInt(this.monthValue);
      var dim = void 0;
      if (month && year) {
        dim = moment().year(year).month(month - 1).daysInMonth();
      } else if (month) {
        dim = moment().month(month - 1).daysInMonth();
      } else {
        dim = 31;
      }
      for (var i = 1; i <= dim; i += 1) {
        var day = ('0' + i).substr(-2);
        this.dayOptions.push({
          label: day,
          value: day
        });
      }
      if (!_.find(this.dayOptions, { value: this.dayValue })) {
        this.dayValue = null;
      }
    };

    /* -------------------------------------------------------------------- *
     * Validate and update
     * -------------------------------------------------------------------- */

    this.updateModel = function () {
      _this.ngModelCtrl.$setTouched();
      if (_this.monthValue || _this.dayValue || _this.yearValue) {
        _this.ngModelCtrl.$setDirty();
      }
      if (_this.monthValue && _this.dayValue && _this.yearValue) {
        _this.allEntered = true;
      }
      if (_this.allEntered && _this.ngModelCtrl.$blur) {
        _this.ngModelCtrl.$blur();
      }
      var value = (_this.monthValue || '') + '/' + (_this.dayValue || '') + '/' + (_this.yearValue || '');
      if (value === '//') {
        _this.ngModelCtrl.$setViewValue(null);
      } else {
        _this.ngModelCtrl.$setViewValue(value);
      }
    };
  }
});
})();