(function(){
'use strict';

/**
 * @kind directive
 * @name scCharLimit
 *
 * @description
 * Converts a select dropdown value to a numeric value in the model
 *
 * Used in:
 * - appv2/event/common/event-types/crowdfunding/impact/index.ng.html
 *
 */

function scConvertToNumber() {
  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function (val) {
        return parseInt(val, 10);
      });

      ngModel.$formatters.push(function (val) {
        return '' + val;
      });
    }
  };
}

angular.module('classy').directive('scConvertToNumber', scConvertToNumber);
})();