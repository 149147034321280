(function(){
'use strict';

loadTwitterAPIFactory.$inject = ["$window"];
function loadTwitterAPIFactory($window) {
  function loadTwitterAPI() {
    $window.twttr = function (d, s, id) {
      var fjs = d.getElementsByTagName(s)[0],
          t = window.twttr || {};
      if (d.getElementById(id)) return t;
      var js = d.createElement(s);
      js.id = id;
      js.src = 'https://platform.twitter.com/widgets.js';
      js.async = 'async';
      js.defer = 'defer';
      fjs.parentNode.insertBefore(js, fjs);

      // eslint-disable-next-line no-underscore-dangle
      t._e = [];
      t.ready = function (f) {
        // eslint-disable-next-line no-underscore-dangle
        t._e.push(f);
      };

      return t;
    }(document, 'script', 'twitter-wjs');
  }

  return loadTwitterAPI;
}

angular.module('classy').service('loadTwitterAPI', loadTwitterAPIFactory);
})();