(function(){
'use strict';

/**
 * @ngDoc service
 * @name scFundraisingPageDefaults
 *
 * @description
 * Default data for the scFundraisingPageModel.
 */

function scFundraisingPageDefaults() {
  this.alias = '';
  this.goal = 0;
  this.intro_text = '';
  this.logo = null;
  this.cover_photo = null;
}

angular.module('classy').service('scFundraisingPageDefaults', scFundraisingPageDefaults);
})();