(function(){
'use strict';

scDonationCampaignDefaultBlocks.$inject = ["scBlockModel"];
angular.module('classy').factory('scDonationCampaignDefaultBlocks', scDonationCampaignDefaultBlocks);

function scDonationCampaignDefaultBlocks(scBlockModel) {
  return {
    header: new scBlockModel({ type: 'header' }),
    donation: new scBlockModel({
      type: 'donation',
      background: {
        preset: 'coverfixed'
      }
    }),
    thankYou: new scBlockModel({
      type: 'thank-you',
      background: {
        preset: 'coverfixed'
      }
    }),
    footer: new scBlockModel({ type: 'footer' })
  };
}
})();