(function(){
'use strict';

angular.module('classy').component('checkboxControl', {
  transclude: true,
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n      <button class="{{:: $ctrl.baseClass }}"\n      ng-class="{ checked: $ctrl.isChecked(), disabled: $ctrl.disabled }"\n      ng-click="$ctrl.toggle()"\n      ng-disabled="$ctrl.disabled || $ctrl.lockField"\n      aria-label="{{:: $ctrl.ariaLabel }}"\n      type="button" role="checkbox"\n      _spec="button"\n      aria-checked="{{$ctrl.isChecked()}}">\n        <div ng-if="$ctrl.lockField"\n            data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n            data-sc-tooltip-position="right"\n            data-sc-tooltip-position-tip="bottom center"\n            show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n            fixed-tooltip="true"\n            data-sc-tooltip="\'This field is locked at the template level.\'"\n            class="tooltip-position admin-body__tooltip-element"\n        ></div>\n\n        <span class="{{:: $ctrl.baseClass }}-input"\n        _spec="checkbox"></span>\n\n        <span class="{{:: $ctrl.baseClass }}-label"\n        qa-id="{{:: $ctrl.scQaId.checkboxField }}"\n        ng-transclude\n        _spec="label"></span>\n\n      </button>\n    ',
  bindings: {
    baseClass: '<?',
    flipped: '<?',
    disabled: '<?',
    checked: '<?',
    ariaLabel: '@'
  },
  controller: ["scQaId", "scCampaignsTemplateService", "$attrs", "$stateParams", function CheckboxControlCtrl(scQaId, scCampaignsTemplateService, $attrs, $stateParams) {
    var _this = this;

    this.scQaId = scQaId;

    this.lockfield = false;

    this.getLockedField = function (type) {
      var lockedBlock = scCampaignsTemplateService.getBlockByType(type);
      var fieldName = $attrs.ngModel.split('.').splice(-1)[0];
      return lockedBlock && lockedBlock.item_attributes[fieldName] && lockedBlock.item_attributes[fieldName].locked;
    };

    if (SC.campaign.campaignTemplateData) {
      var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
      if (blockObj) {
        this.lockField = this.getLockedField(blockObj.type);
      }
    }

    this.$onInit = function () {
      _this.ngModelCtrl.$isEmpty = _this.isEmpty;
      _this.baseClass = _this.baseClass || 'form__checkbox-control';
      _this.ariaLabel = _this.ariaLabel || '';

      if (_this.checked) {
        _this.toggle();
      }
    };

    this.toggle = function () {
      var status = !_this.ngModelCtrl.$modelValue;
      _this.ngModelCtrl.$setViewValue(status);
      _this.ngModelCtrl.$setTouched();
      _this.ngModelCtrl.$setDirty();
    };

    this.isChecked = function () {
      if (_this.flipped) {
        return !_this.ngModelCtrl.$modelValue;
      }
      return !!_this.ngModelCtrl.$modelValue;
    };

    this.isEmpty = function (value) {
      return !value;
    };
  }]
});
})();