(function(){
'use strict';

DonationService.$inject = ["$log", "$http", "$timeout", "$state", "$rootScope", "$window", "scTransientsService", "scAnalytics", "scGetQueryParams", "getParamByName"];
function DonationService($log, $http, $timeout, $state, $rootScope, $window, scTransientsService, scAnalytics, scGetQueryParams, getParamByName) {
  'use strict';

  /* Factory Methods */

  return {
    processDonation: processDonation,
    processWePay: processWePay,
    processAuthorize: processAuthorize,
    ccType: ccType
  };

  /*
    Process Donation
     Options:
    returnUrl - redirect url passed via url param
  */
  function processDonation() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var donationForm = {};

    angular.copy(SC.donation, donationForm);

    options.block = options.block || {};

    if (options && options.pci) {
      donationForm.payment.card.num = {};
    }

    // Disable dedication if hidden
    if (!donationForm.dedication.dedicate) {
      donationForm.dedication = {};
    }

    $http.post('/checkout/donation-submit', donationForm).then(function (data) {
      var tid = getParamByName('oid', data.data.urlRedirect),
          verifyStr = getParamByName('verify', data.data.urlRedirect);

      if (data.data.status == 'SUCCESS') {
        $log.log('Process Donation Success', data);

        options.block.redirecting = true;
        options.block.submitDisabled = true;

        if (options.returnUrl) {
          var conversionData = {
            amount: SC.donation.amount,
            transactionId: SC.transactionId
          };
          if (_.parseInt(SC.donation.recurring) === 1) {
            scAnalytics.track('recurring-donation/create/complete', conversionData);
          } else {
            scAnalytics.track('donation/create/complete', conversionData);
          }

          $timeout(function () {
            $log.log('returnUrl:', options.returnUrl);
            window.location.href = options.returnUrl;
          }, 2000);
        } else if (data.data.urlRedirect.indexOf('paypal.com/') > 0) {
          // Redirect if PayPal
          window.location.href = data.data.urlRedirect;
        } else {
          $log.log('urlRedirect:', data.data.urlRedirect);

          $http({
            method: 'GET',
            url: data.data.urlRedirect + '&respond=1'
          }).then(function (response) {
            if (!SC.preview) {
              var _conversionData = {
                amount: SC.donation.amount,
                transactionId: tid
              };

              if (_.parseInt(SC.donation.recurring) === 1) {
                scAnalytics.track('recurring-donation/create/complete', _conversionData);
              } else {
                scAnalytics.track('donation/create/complete', _conversionData);
              }
            }
            if (_.startsWith(_.get(response, 'data[0]', ''), 'http')) {
              $window.location.href = response.data[0];
            } else {
              SC.transactionId = tid;

              scTransientsService.set('memberEmail', donationForm.email);

              $state.go('frs.donation.thankYou', {
                tid: tid,
                verify: verifyStr,
                preview: SC.preview
              });
            }
          }).catch(function (response) {
            options.block.processing = false;
            $rootScope.SC.status.banner = {
              type: 'error',
              msg: 'Error: ' + response.data,
              timeout: false
            };
          });
        }
      } else if (data.data.status == 'FAILURE') {
        $log.log('Process Donation Failure', data);

        options.block.processing = false;

        // strip HTML encoding from error (still needed on ClassyApp)
        if (data.data.error) {
          var regex = /(<([^>]+)>)/gi;
          data.data.error = data.data.error.replace(regex, '');
        }

        $rootScope.SC.status.banner = {
          type: 'error',
          msg: 'Error: ' + data.data.error,
          timeout: false
        };
      }
    }).catch(function (data) {
      $log.error('Process Donation Error', data.data);
      options.block.processing = false;

      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Error: ' + data.data.error,
        timeout: false
      };
    });
  }

  function ccType(ccNum) {
    var cardType = void 0;

    if (ccNum.match(/^5[1-5][0-9]{14}/g)) {
      cardType = 'Mastercard';
    } else if (ccNum.match(/^4[0-9]{12}([0-9]{3})?/g)) {
      cardType = 'Visa';
    } else if (ccNum.match(/^3[47][0-9]{13}/g)) {
      cardType = 'Amex';
    } else if (ccNum.match(/^6011[0-9]{12}/g)) {
      cardType = 'Discover';
    } else {
      cardType = 'Unknown';
    }

    return cardType;
  }

  /*
   * Process WePay
   */
  function processWePay(options) {
    var addressObj = {
      address1: SC.donation.payment.address,
      city: SC.donation.payment.city,
      country: SC.donation.payment.country
    };

    if (SC.donation.payment.country == 'US') {
      addressObj.state = SC.donation.payment.state;
      addressObj.zip = SC.donation.payment.zip;
    } else if (SC.donation.payment.country == 'CA') {
      addressObj.region = SC.donation.payment.province;
      addressObj.postcode = SC.donation.payment.zip;
    } else {
      addressObj.postcode = SC.donation.payment.zip;
    }

    // Set card type
    var cardNumber = SC.donation.payment.card.num;
    SC.donation.payment.cardType = ccType('' + cardNumber);
    SC.donation.payment.card.lastFour = cardNumber.substr(cardNumber.length - 4, cardNumber.length);

    var card = {
      client_id: SC.env == 'production' ? '166684' : '43386',
      user_name: SC.donation.name.first + ' ' + SC.donation.name.last,
      email: SC.donation.email,
      cc_number: SC.donation.payment.card.num,
      cvv: SC.donation.payment.card.cvv,
      expiration_month: SC.donation.payment.card.expiration.month,
      expiration_year: SC.donation.payment.card.expiration.year,
      address: addressObj
    };

    $log.log('WePay Get CC Token > ', addressObj, card);

    var urlParam = scGetQueryParams();

    if (typeof WePay == 'undefined' || urlParam.timeout === '1') {
      // If WePay has not loaded, send call to backend without PCI compliance
      processDonation(options);

      return true;
    }

    var response = WePay.credit_card.create(card, function (data) {
      if (data.error) {
        $log.error('WePay CC Token Error > ', data);

        // Send call to backend
        processDonation(options);
      } else {
        $log.log('WePay CC Token Success > ', data);
        SC.donation.creditCardId = data.credit_card_id;

        // Normal PCI-compliant call
        options.pci = true;
        processDonation(options);
      }
    });

    if (response.error) {
      $log.error('WePay CC Token Error > ', response);

      // Send call to backend
      processDonation(options);
    }

    return undefined;
  }

  /*
   * Process Authorize payments
   */
  function processAuthorize(cardNumber, cvv) {
    var cc = SC.donation.payment.card.num;

    SC.donation.payment.card.lastFour = cc.substr(cc.length - 4, cc.length);

    $log.log('Get Authorize Fingerprint >', SC.donation);

    $http.post('/checkout/auth-dpm-create', SC.donation).then(function (data) {
      $log.log('Authorize Fingerprint Success', data.data);

      if (data.data.status_code == 'SUCCESS') {
        var $authForm = $('<form></form>');
        $('body').append($authForm);
        $authForm.replaceWith(data.data.auth_net_form);
        $authForm = $('#authNetDpmForm');

        $authForm.find('#x_card_num').val(cardNumber);
        $authForm.find('#x_card_code').val(cvv);
        $authForm.submit();
      }
    }).catch(function (data) {
      $log.error('Authorize Fingerprint Error', data.data);
    });
  }
}

angular.module('classy').factory('DonationService', DonationService);
})();