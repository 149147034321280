(function(){
'use strict';

scResourceSearch.$inject = ["$animate", "$log", "$q", "$timeout", "$injector", "scQaId"];
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @kind directive
 * @name scResourceSearch
 *
 * @description
 * Provides a typeahead search field for a resource of your choosing.
 *
 * @param {Definition|Array<Definition>|SearchModes} scResourceSearch - This provides the
 *   information for what to search. The schema for a Definition is as follows:
 *   {
 *     service: string - the resource service to provide results.
 *        This service must extend scResourceSearchModel (ex: scCampaignSearchModel)
 *
 *     fields: Array<string> - the fields in the resource to search on.
 *        Look at the documentation for the particular service for what fields
 *        are available. Note: only include fields that are type string. The one exception is
 *        that this directive supports 'id' (a resource's primary key).
 *
 *     queryParams: Object - Addition query parameters to attach to the http calls
 *        (e.g. {aggregates: true, with: 'member,dedication', filter: 'status=active'})
 *   }
 *   If you provide an array of Definitions, the typeahead results will be
 *     a union of the results from each service.
 *   Lastly, you can provide mutiple different search options, SearchModes, which will
 *      provide the user a dropdown beside the input to decide which mode to search through.
 *   The schema is as follows:
 *   {
 *     [Label_Name1]: Definition|Array<Definition>,
 *     [Label_Name2]: Definition|Array<Definition>,
 *     ...
 *   }
 * @param {resource} ngModel The selected resource.
 * @param {string?} resourceType The type of the selected resource,
 *    look in subclasses of scResourceSearchModel for options (e.g 'fundraising-pages', 'campaigns')
 * @param {any?} nullValue What to revert to on cancel or clear
 * @param {boolean?} selectInitial Display the beginning model as the search box selection
 * @param {any?} idOnly Set ngModel to resource Id, not object
 * @param {Object?} context Scope for search service to search under (e.g. {'campaign_id': 1234})
 * @param {expression?} onFocus Expression to call in parent scope on focus
 * @param {expression?} onSelect Expression to call in parent scope on selection:
 *    on-select="fn($selection, $type)"
 * @param {expression?} onClear Expression to call in parent scope on clear
 * @param {Array<string>?} include Array of UI features. Options are 'image' and 'description'
 * @param {boolean?} admin True if this is the admin area
 * @param {boolean?} required Defaults to false. If the input is required
 * @param {boolean?} showDropdownWithSelection Defaults to false.
 *    Show the dropdown regardless of something is selected or not.
 * @param {string?} bc CSS class stem
 */

function scResourceSearch($animate, $log, $q, $timeout, $injector, scQaId) {
  'use strict';

  return {
    require: ['ngModel', '^?form'],
    templateUrl: 'global/directives/form/scResourceSearch/template',
    link: linkFn,
    scope: {
      scResourceSearch: '&',
      resourceType: '=?',
      context: '&?',
      nullValue: '=?',
      selectInitial: '=?',
      idOnly: '=?',
      onFocus: '&?',
      onSelect: '&?',
      onClear: '&?',
      include: '&?',
      admin: '&?',
      bc: '@',
      showDropdownWithSelection: '=?',
      resourceFilter: '&?'
    }
  };

  // eslint-disable-next-line no-unused-vars
  function linkFn(scope, element, attrs, ctrls) {
    var SearchMode = getSearchMode();
    var AvailableFeatures = ['image', 'description'];

    var ngModelCtrl = ctrls[0];
    var formCtrl = ctrls[1];

    var admin = _.isUndefined(scope.admin) ? false : scope.admin();
    var bc = scope.bc || 'resource-search';
    var features = buildFeatures();
    var modes = buildSearchModes();
    var KEYS = {
      enter: 13,
      space: 32,
      up: 38,
      down: 40
    };

    scope.activeMode = modes[0];
    scope.admin = admin;
    scope.bc = bc;
    scope.disabled = false;
    scope.features = features;
    scope.modes = modes;
    scope.scQaId = scQaId;
    scope.results = [];

    scope.clearSelection = clearSelection;
    scope.getSearchPlaceholder = getSearchPlaceholder;
    scope.isFetchingResults = isFetchingResults;
    scope.isResultsEmpty = isResultsEmpty;
    scope.onInputFocus = onInputFocus;
    scope.selectResource = selectResource;
    scope.showDropdown = showDropdown;
    scope.showResults = showResults;
    scope.getActiveDescendentId = getActiveDescendentId;
    scope.onSearchBoxKeydown = onSearchBoxKeydown;
    scope.onResultItemKeydown = onResultItemKeydown;

    if (_.isUndefined(scope.selectInitial)) {
      scope.selectInitial = true;
    }

    init();

    function init() {
      $animate.enabled(element, false);

      if (formCtrl) {
        formCtrl.$removeControl(formCtrl.scResourceSearchQuery);
      }

      scope.$watch('query', handleQuery);

      scope.$watch('activeMode', function (newVal, oldVal) {
        if (newVal !== oldVal) {
          resetUI();
        }
      });

      element.find('input').on('focus', onInputFocus);

      angular.element('body').on('click', onInputBlur);

      scope.$on('$destroy', function () {
        angular.element('body').off('click', onInputBlur);
      });
    }

    /**
     * Create the features object, defining what to show in the results dropdown
     * @return {[field: string]: boolean} hash of what features to show
     */
    function buildFeatures() {
      if (_.isUndefined(scope.include)) {
        return {
          image: true,
          description: false
        };
      }
      var userSetting = scope.include();
      var featureSet = {};
      _.forEach(AvailableFeatures, function (feature) {
        featureSet[feature] = userSetting.indexOf(feature) > -1;
      });
      return featureSet;
    }

    /**
     * Builds the different search mode types.
     * @return {Array<Mode>} The modes
     */
    function buildSearchModes() {
      var modeSet = [];
      var config = scope.scResourceSearch();

      if (_.isPlainObject(config) && !config.service) {
        // multiple modes
        _.forEach(config, function (definition, label) {
          modeSet.push(new SearchMode(label, definition));
        });
      } else {
        // single mode
        modeSet.push(new SearchMode(null, config));
      }

      return modeSet;
    }

    /**
     * @returns {string} The placeholder to display in the search bar
     */
    function getSearchPlaceholder() {
      var resources = _(scope.activeMode.services).map(function (service) {
        return service.getReadableTypeName();
      }).uniq().value();

      if (resources.length == 1) {
        return 'search ' + resources[0];
      }
      var last = resources.pop();
      return 'search ' + resources.join(', ') + ' and ' + last;
    }

    /**
     * Format and check that query is searchable; if so, fetch results
     * @param  {string} prop     The search term
     * @param  {string} previous The previous search term
     */
    function handleQuery(prop, previous) {
      var query = prop && prop.trim();
      var prev = previous && previous.trim();
      var mode = scope.activeMode;

      if (query === prev) {
        return;
      }

      // If query is empty, return to blank state.
      if (!query) {
        scope.results = [];
        scope.resultsFor = null;
        $timeout.cancel(scope.debounce);
        scope.isFetching = false;
        return;
      }

      // Activate fetching state immediately, before the debounce.
      scope.isFetching = true;

      // Cancel previous debounce.
      $timeout.cancel(scope.debounce);

      // Set new debounce.
      scope.debounce = $timeout(function () {
        fetchResults(query, mode);
      }, 300);
    }

    /**
     * Retrieve the search results and set to scope.results
     * @param  {string} query The search term
     * @param  {Mode} mode    The Mode to search with
     */
    function fetchResults(query, mode) {
      var promises = _.map(mode.services, function (service) {
        return service.search(query, scope.context && scope.context());
      });

      $q.all(promises).then(function (results) {
        // The user has searched for something else.
        // These results are no longer relevant.
        if (query !== scope.query.trim()) {
          return;
        }

        // The user has already selected something else.
        // These results are no longer relevant.
        if (scope.selection) {
          return;
        }

        // The user has changed modes.
        // These results are no longer relevant.
        if (mode !== scope.activeMode) {
          return;
        }

        scope.results = _.flattenDeep(results);
        scope.results = _.uniq(scope.results, 'id');

        // Removing duplicates from team captain
        scope.results = scope.results.filter(function (captain, index, self) {
          return index === self.findIndex(function (_capitan) {
            return _capitan.id === captain.id && _capitan.title === captain.title;
          });
        });

        if (scope.resourceFilter && typeof scope.resourceFilter === 'function') {
          scope.results = scope.resourceFilter({ results: scope.results });
        }
        scope.resultsFor = query;
      }).catch(function (err) {
        $log.warn(err);
      }).finally(function () {
        scope.isFetching = false;
      });
    }

    /**
     * Select a resource
     * @param  {resource} result
     */
    function selectResource(result) {
      var type = result._service.getType(); // eslint-disable-line no-underscore-dangle

      if (_.isFunction(scope.onSelect)) {
        scope.onSelect({ $selection: result, $type: type });
      }

      ngModelCtrl.$setViewValue(result);
      scope.resourceType = type;
      setSelectedResource(result);
      scope.focusRemoveButton = true;
    }

    /**
     * Set scope.selection to the chosen resource and clear the query
     * @param {resource} resource
     */
    function setSelectedResource(resource) {
      scope.selection = resource;
      scope.query = '';
    }

    /**
     * Clear the currently selected resource
     */
    function clearSelection() {
      if (_.isFunction(scope.onClear)) {
        scope.onClear({ $removed: scope.selection });
      }
      scope.focusSearchBox = true;
      resetUI();
    }

    /**
     * Clear the input field
     */
    function resetUI() {
      deselectResource();
      scope.query = '';
    }

    /**
     * Set ngModel to nullValue and null out scope.selection
     */
    function deselectResource() {
      ngModelCtrl.$setViewValue(scope.nullValue);
      scope.selection = null;
    }

    /* -------------------------------------------------------------------- *
     * DOM Event Listeners
     * -------------------------------------------------------------------- */

    function onInputFocus() {
      $timeout(function () {
        scope.isBlurred = false;
      });
    }

    function onInputBlur(event) {
      $timeout(function () {
        var e = event.originalEvent;
        if (angular.element(e.target).closest(element).length) {
          return;
        }

        scope.isBlurred = true;
        if (!scope.selection) {
          scope.query = '';
        }
      });
    }

    function onSearchBoxKeydown($event) {
      if (scope.results && scope.results.length) {
        if ($event.keyCode === KEYS.enter || $event.keyCode === KEYS.down) {
          $event.preventDefault();
          $('#search-result-' + scope.results[0].id).focus();
        } else if ($event.keyCode === KEYS.up) {
          $event.preventDefault();
          $('#search-result-' + scope.results[scope.results.length - 1].id).focus();
        }
      }
    }

    function onResultItemKeydown($event, item) {
      if (scope.results && scope.results.length) {
        var currentIndex = scope.results.findIndex(function (obj) {
          return obj === item;
        });

        if ($event.keyCode === KEYS.down) {
          $event.preventDefault();
          var nextIndex = currentIndex + 1;
          if (nextIndex > scope.results.length - 1) nextIndex = 0;
          var idToFocus = scope.results[nextIndex].id;
          $('#search-result-' + idToFocus).focus();
        } else if ($event.keyCode === KEYS.up) {
          $event.preventDefault();
          var previousIndex = currentIndex - 1;
          if (previousIndex < 0) previousIndex = scope.results.length - 1;
          var _idToFocus = scope.results[previousIndex].id;
          $('#search-result-' + _idToFocus).focus();
        }
      }
    }

    /* ------------------------------------------------------------------ *
     * Display flags
     * ------------------------------------------------------------------ */

    function showDropdown() {
      return (!scope.selection || scope.showDropdownWithSelection) && modes.length > 1;
    }

    function showResults() {
      return scope.query && !scope.isBlurred && !scope.selection && scope.results.length;
    }

    function isFetchingResults() {
      return scope.query && !scope.isBlurred && !scope.selection && !scope.results.length && scope.isFetching;
    }

    function isResultsEmpty() {
      return scope.query && !scope.isBlurred && !scope.selection && scope.resultsFor === scope.query && !scope.results.length && !scope.isFetching;
    }

    function getActiveDescendentId() {
      var activeDescendentId = 'search-result';
      if (scope.isResultsEmpty()) {
        return activeDescendentId + '--empty';
      } else if (scope.selection) {
        return activeDescendentId + '-' + scope.selection.id;
      } else if (!scope.query) {
        return null;
      }
      return activeDescendentId + '-container';
    }

    /**
     * Converts ngModel to id if idOnly is true
     * @param  {resource|id} viewValue
     * @return {resource|id}
     */
    ngModelCtrl.$parsers.push(function (viewValue) {
      var val = scope.idOnly ? _.get(viewValue, 'id', null) : viewValue;
      return val;
    });

    /**
     * Retrieves the resource if ngModel is an id and sets it to scope.selection
     * @param  {resource|id}
     * @return {null}
     */
    ngModelCtrl.$formatters.push(function (modelValue) {
      scope.disabled = true;

      var getResource = void 0;
      if (!modelValue) {
        getResource = $q.resolve(null);
      } else {
        var registeredServices = _(modes).map(function (mode) {
          return mode.services;
        }).flatten().value();
        var searchService = _.find(registeredServices, function (service) {
          return service.getType() == scope.resourceType;
        });

        // Edge case: on donation checkout we put the team as the selected
        // attributee when the url is give/tID/donation/checkout (donate button on team page),
        // but we don't allow Fundraising teams to be searchable, thus it's search service is not
        // registered. For this edge case, we manually load it.
        if (!searchService && scope.resourceType == 'fundraising-teams') {
          searchService = new ($injector.get('scFundraisingTeamSearchModel'))();
        }

        // Attempt to find the correct active mode. Will set the activeMode to the first mode
        // with the related search model, otherwise do nothing (default is the first mode)
        _.find(modes, function (mode) {
          var matchingService = _.find(mode.services, function (service) {
            if (service.getType() == scope.resourceType) {
              return true;
            }

            return undefined;
          });

          if (matchingService) {
            scope.activeMode = mode;
          }
        });

        if (!scope.idOnly) {
          // eslint-disable-next-line no-underscore-dangle
          if (!modelValue._service) {
            modelValue._service = searchService; // eslint-disable-line no-underscore-dangle
          }
          getResource = $q.resolve(modelValue);
        } else {
          // modelValue is an id
          getResource = !searchService ? $q.resolve(null) : searchService.getResourceById(modelValue).then(function (resource) {
            resource._service = searchService; // eslint-disable-line no-underscore-dangle
            return resource;
          });
        }
      }

      getResource.then(function (resolved) {
        if (scope.selectInitial) {
          setSelectedResource(resolved);
        }
      }).catch(function (err) {
        $log.error(err);
        setSelectedResource(null);
      }).finally(function () {
        scope.disabled = false;
      });

      return null;
    });
  }

  /**
   * label: string
   * services: Array<? extends scResourceSearchModel>
   */
  function getSearchMode() {
    function generateServices(definitions) {
      return _.map(definitions, function (definition) {
        var searchService = $injector.get(definition.service);

        return new searchService(definition.fields, definition.queryParams);
      });
    }

    return (
      /**
       * Constructor
       * @param  {string|null} label Human readable name for this search mode
       * @param  {Definition|Array<Definition>} definitions
       */
      function SearchMode(label, definitions) {
        _classCallCheck(this, SearchMode);

        this.label = label;
        this.services = generateServices(_.isArray(definitions) ? definitions : [definitions]);
      }
    );
  }
}

angular.module('classy').directive('scResourceSearch', scResourceSearch);
})();