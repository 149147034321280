(function(){
'use strict';

angular.module('classy').component('incompleteFormAlertModal', {
  template: '\n      <div class="incomplete-form-alert-modal">\n        <modal-title>Are you sure?</modal-title>\n        <modal-subtitle>You will lose the information on this page if you leave.</modal-subtitle>\n        <div class="incomplete-form-alert-modal__btns">\n          <div class="incomplete-form-alert-modal__btn--leave"\n          ng-click="$ctrl.leave()">Exit</div>\n\n          <div class="incomplete-form-alert-modal__btn--confirm"\n          ng-click="$ctrl.onClose()">Stay on page</div>\n        </div>\n      </div>\n    ',
  bindings: {
    cart: '=',
    leave: '&',
    onClose: '&?'
  },
  controller: ["scFlowModalService", function controller(scFlowModalService) {
    var DEFAULTS = {
      onClose: function onClose() {
        scFlowModalService.close();
      }
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };
  }]
});
})();