(function(){
'use strict';

/**
 * @kind directive
 * @name scSubcheckboxField
 *
 * @description
 * Displays a checkbox. Often used within another sc{X}Field directive.
 *
 * @param {string} label Field label
 * @param {expression} model Checkbox model
 * @param {constant expression} trueVal Value of the model when checkbox is checked
 * @param {constant expression} falseVal Value of the model when checkbox is unchecked
 * @param {expression} change Click callback
 * @param {expression} keydown On Keydown callback
 */

scSubcheckboxField.$inject = ["scField"];
function scSubcheckboxField(scField) {
  return scField('scSubcheckboxField');
}

angular.module('classy').directive('scSubcheckboxField', scSubcheckboxField);
})();