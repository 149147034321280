(function(){
'use strict';

/**
 * @kind service
 * @name scFundraiserEmailService
 *
 * @description
 * Stores a post object
 */
scFundraiserDashboardEmailService.$inject = ["scOrganizationsService", "scCampaignsService", "scFundraisingTeamsService"];
angular.module('classy').service('scFundraiserDashboardEmailService', scFundraiserDashboardEmailService);

function scFundraiserDashboardEmailService(scOrganizationsService, scCampaignsService, scFundraisingTeamsService) {
  this.message = {};
  var message = void 0;

  var orgName = scOrganizationsService.active.current.name,
      campaignName = scCampaignsService.active.current.name;

  var getDefaultAppealCopy = function getDefaultAppealCopy(fundraiser) {
    if (fundraiser) {
      message = 'Family and Friends,<p></p>Please help me support ' + orgName + ' by making a donation through my fundraising page for ' + campaignName + '. Even a small donation will help me achieve my goal! The process is fast, easy, and secure. Thanks so much for your support.';
    } else {
      message = 'Family and Friends,<p></p>Please help me support ' + orgName + ' by making a donation through my team\'s fundraising page for ' + campaignName + '. Even a small donation will help me achieve my goal! The process is fast, easy, and secure. Thanks so much for your support.';
    }

    return message;
  };

  var getDefaultThankYouCopy = function getDefaultThankYouCopy(fundraiser) {
    if (fundraiser) {
      message = 'Thank you for donating to ' + orgName + ' through my fundraising page for ' + campaignName + '! Feel free to check back on my fundraising progress as I attempt to reach my goal!';
    } else {
      message = 'Thank you for donating to ' + orgName + ' through my team\'s fundraising page for ' + campaignName + '! Feel free to check back on my fundraising progress as I attempt to reach my goal!';
    }

    return message;
  };

  var getDefaultInviteCopy = function getDefaultInviteCopy() {
    var teamName = scFundraisingTeamsService.active.current.name;
    message = 'You should join our team, ' + teamName + '!<br/><br/>I\'m fundraising for ' + campaignName + '! You should join us and work together to support ' + orgName + ' and a truly awesome cause.<br/><br/>Let\'s make it happen!';

    return message;
  };

  this.getDefaultMessageCopy = function (type, fundraiser) {
    if (type == 'appeal') {
      this.message = getDefaultAppealCopy(fundraiser);
    } else if (type == 'invite') {
      this.message = getDefaultInviteCopy();
    } else {
      this.message = getDefaultThankYouCopy(fundraiser);
    }

    return this.message;
  };
}
})();