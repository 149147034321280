(function(){
'use strict';

CFPJoinTeamViewFactory.$inject = ["$state", "$timeout", "scFlowModalService", "scThemesService", "scFundraisingPagesService", "scFundraisingTeamsService"];
function CFPJoinTeamViewFactory($state, $timeout, scFlowModalService, scThemesService, scFundraisingPagesService, scFundraisingTeamsService) {
  return function CFPJoinTeamView(parentScope) {
    var $scope = parentScope.$new();

    // sometimes a 'new team' exists on the parent scope. if it doesn't,
    // just fall back to joining the current active team.
    $scope.newTeam = _.get($scope, 'newTeam', _.get(scFundraisingTeamsService, 'active.current', {}));

    $scope.primaryColor = _.get($scope, 'ui.primaryColor', scThemesService.active.current.styles.primaryColor);

    /* ---------------------------------------------------------------------- *
     * Get and sync member photo
     * ---------------------------------------------------------------------- */

    $scope.state = {};

    $scope.confirmJoin = function (teamId) {
      var frPage = $scope.fundraisingPage ? scFundraisingPagesService.getSpecificMemberPage($scope.fundraisingPage.id) : scFundraisingPagesService.getCurrentMemberPage();
      $scope.loading = true;

      frPage.joinTeam(teamId).then(function () {
        $scope.loading = false;

        frPage.current.fundraising_team_id = teamId;
        scFundraisingPagesService.add(frPage.current);
        scFlowModalService.whitelist('frs.team.index');

        $scope.flow.to('indiv.join-team.success', 'slideLeft');

        $state.go('frs.team.index', { fundraisingTeamId: teamId });
        var deregister = $scope.$on('$stateChangeSuccess', function () {
          $timeout(function () {
            scFlowModalService.close(true);
            deregister();
          }, 3000);
        });
      });
    };

    $scope.flow.views.push({
      id: 'indiv.join-team.success',
      templateUrl: $scope.flow.template('join-team/success'),
      maxWidth: 396,
      duration: 1000,
      context: $scope
    });

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.join-team',
      templateUrl: $scope.flow.template('join-team'),
      maxWidth: 385,
      context: $scope
    };
  };
}

angular.module('classy').factory('CFPJoinTeamView', CFPJoinTeamViewFactory);
})();