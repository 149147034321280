(function(){
'use strict';

scReceiptServiceFactory.$inject = ["$q", "scReceiptModel"];
function scReceiptServiceFactory($q, scReceiptModel) {
  this.active = null;

  this.load = function (cartId, verify, total) {
    delete this.active;
    this.active = new scReceiptModel(cartId, verify, total);
    return this.active;
  };

  /**
   * Polling service to check whether registrantsLoading is still true.
   * Used in registrationReceipt component to populate scFundraisingPagesService
   * with new fundraising pages.
   */
  this.checkRegistrants = function () {
    if (this.active.registrantsLoading) {
      return this.active.pollingRegistrantsPromise;
    }
    return $q.resolve();
  };

  this.clear = function () {
    delete this.active;
    this.active = {};
  };
}

angular.module('classy').service('scReceiptService', scReceiptServiceFactory);
})();