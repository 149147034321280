(function(){
'use strict';

angular.module('classy').component('registrationsList', {
  bindings: {
    registrations: '=',
    showTitle: '=?',
    showDetails: '=?',
    showPrice: '=?'
  },
  template: '\n    <div class="registrations-list">\n      <div class="registrations-list__item"\n        ng-if="$ctrl.showTitle || $ctrl.showDetails || $ctrl.showPrice"\n        ng-repeat="registration in $ctrl.registrations">\n\n        <div class="registrations-list__row">\n          <p class="registrations-list__item-name"\n             data-ng-bind="registration.name"\n             ng-if="$ctrl.showTitle"></p>\n\n          <span class="registrations-list__item-price"\n            data-ng-bind="registration.price === 0 ? \'Free\' : (registration.price | currency :\'$\':2)"\n            ng-if="$ctrl.showPrice"></span>\n        </div>\n\n        <fundraising-minimum-disclaimer ng-if="registration.commitment"\n          minimum-value="registration.commitment.value"\n          minimum-deadline="registration.commitment.deadline"></fundraising-minimum-disclaimer>\n\n        <p class="registrations-list__item-details"\n           data-ng-bind="registration.description"\n           ng-if="$ctrl.showDetails"></p>\n      </div>\n    </div>\n  ',

  controller: function controller() {
    var DEFAULTS = {
      showTitle: true,
      showDetails: true,
      showPrice: true
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };
  }
});
})();