(function(){
'use strict';

/**
 * scFroalaOptions
 *
 * PLEASE READ BEFORE TRYING TO COMPREHEND THIS MADNESS!
 *
 * Example usage: $scope.editor = scFroalaOptions({ type: 'bare-paragraph' });
 *
 * This file sets up the Froala editors to be uniform across the application, provided that it is
 * used every time an editor is instantiated. There are serveral base editor settings, here are
 * their names and a brief description of where they should be used.
 *
 * 1. noButtons
 *
 *    Used for titles, headlines, and anywhere you need the most limited functionality. This will
 *    disallow the user from pasting in images, styled text, markup, etc.
 *
 * 2. buttonsWithAsset
 *
 *    Fully functional editor. Used for blocks that allow markup, colors, lists, and images.
 *
 * 3. threeButtons
 *
 *    Allows very basic styling. Bold, underline, and italics. This is used for titles and things
 *    that require very little customization. No colors allowed, assumes default color theme.
 *
 * 4. buttonsWithVideoAndAsset
 *
 *     Fully functional editor. Used for blocks that allow markup, colors, lists, images,
 *     and videos.
 *
 * 5. inlineButtons
 *
 *    Fully functional text editor, without images and videos. Essentially the same as
 *    buttonsWithAsset minus the asset part
 *
 * 6. colorsOnly
 *
 *    Pretty much describes itself.
 *
 * 7. inlineButtonsPara
 *
 *    Same as inlineButtons but with paragraph styling
 *
 * 8. mergeTagButtons
 *
 *    Basic styling with allowed insertion of merge tags. No color, images or videos.
 *
 * 9. onlyMergeTag
 *
 *    only show the merge tags, without Bold, underline, and italics.
 *
 * @param $timeout
 * @param scImgAssetFroalaUpload - util function for taking images that are pasted or dropped into
 * the froala editor. It will upload them to our own cdn, overriding Froalas default functionality
 * which is to upload them to their own temporary cdn.
 * @return {function(options, overrides)}
 */

scFroalaOptions.$inject = ["$timeout", "scImgAssetFroalaUpload", "CHAR_LIMITS"];
function scFroalaOptions($timeout, scImgAssetFroalaUpload, CHAR_LIMITS) {
  var froalaDefaults = {
    fontFamily: {
      'Mulish,Arial,Helvetica,sans-serif': 'Mulish',
      'Arial,Helvetica,sans-serif': 'Arial',
      'Georgia,serif': 'Georgia',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      'Times New Roman,Times,serif': 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana'
    },
    imageStyles: {
      'fr-rounded': 'Rounded'
    },
    fontFamilyDefaultSelect: 'Mulish',
    zIndex: 1,
    toolbarBottom: true,
    enableShortcuts: ['bold', 'italic', 'underline', 'undo', 'redo'],
    toolbarVisibleWithoutSelection: true,
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove']
  };

  var noButtons = {
    toolbarButtons: [],
    toolbarButtonsMD: [],
    toolbarButtonsSM: [],
    toolbarButtonsXS: [],
    pastePlain: true,
    imagePaste: false,
    imageUpload: false,
    events: getEditorEvents({ images: false, plainText: true })
  };

  var buttonsWithAsset = {
    toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'formatOL', 'formatUL', 'scImgAssetFroalaUpload'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'insertLink', 'formatOL', 'formatUL', 'scImgAssetFroalaUpload'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'insertLink', 'formatOL', 'formatUL', 'scImgAssetFroalaUpload'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'insertLink', 'formatOL', 'formatUL', 'scImgAssetFroalaUpload'],
    imageEditButtons: ['imageDisplay', 'imageSize', 'imageRemove'],
    imageResize: true,
    events: getEditorEvents({ images: true, plainText: false })
  };

  var threeButtons = {
    toolbarButtons: ['bold', 'italic', 'underline'],
    toolbarButtonsMD: ['bold', 'italic', 'underline'],
    toolbarButtonsSM: ['bold', 'italic', 'underline'],
    toolbarButtonsXS: ['bold', 'italic', 'underline'],
    imagePaste: false,
    imageUpload: false,
    events: getEditorEvents({ images: false, plainText: true })
  };

  var buttonsWithVideoAndAsset = {
    toolbarButtons: ['bold', 'italic', 'underline', 'fontSize', 'scImgAssetFroalaUpload', 'insertVideo', 'insertLink', 'formatOL', 'formatUL'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'fontSize', 'scImgAssetFroalaUpload', 'insertVideo', 'insertLink', 'formatOL', 'formatUL'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'fontSize', 'scImgAssetFroalaUpload', 'insertVideo', 'insertLink', 'formatOL', 'formatUL'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'scImgAssetFroalaUpload', 'insertVideo', 'insertLink', 'formatOL', 'formatUL'],
    imageEditButtons: ['imageDisplay', 'imageSize', 'imageRemove'],
    imageResize: true,
    events: getEditorEvents({ images: true, plainText: false })
  };

  var inlineButtons = {
    toolbarButtons: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color'],
    imagePaste: false,
    imageUpload: false,
    events: getEditorEvents({ images: false, plainText: false })
  };

  var colorsOnly = {
    toolbarButtons: ['color'],
    toolbarButtonsMD: ['color'],
    toolbarButtonsSM: ['color'],
    toolbarButtonsXS: ['color'],
    imagePaste: false,
    imageUpload: false,
    events: getEditorEvents({ images: false, plainText: false })
  };

  var inlineButtonsPara = {
    toolbarButtons: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color', 'paragraphStyle'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color', 'paragraphStyle'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color', 'paragraphStyle'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', 'color', 'paragraphStyle'],
    imagePaste: false,
    imageUpload: false,
    events: getEditorEvents({ images: false, plainText: false })
  };

  var mergeTagButtons = {
    toolbarButtons: ['bold', 'italic', 'underline', 'fontSize', 'insertLink', '|', 'mergeTagInsert'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'fontSize', 'insertLink', '|', 'mergeTagInsert'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'fontSize', 'insertLink', '|', 'mergeTagInsert'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontSize', 'insertLink', '|', 'mergeTagInsert'],
    imagePaste: false,
    imageUpload: false,
    events: getEditorEvents({ images: false, plainText: true })
  };

  var onlyMergeTag = {
    toolbarButtons: ['mergeTagInsert'],
    toolbarButtonsMD: ['mergeTagInsert'],
    toolbarButtonsSM: ['mergeTagInsert'],
    toolbarButtonsXS: ['mergeTagInsert'],
    imagePaste: false,
    imageUpload: false,
    events: getEditorEvents({ images: false, plainText: false })
  };

  // Public API
  var froalaOptions = {
    'inline-simple': _.defaults({
      theme: 'gray',
      charCounterCount: false,
      toolbarSticky: false,
      linkEditButtons: ['linkEdit', 'linkRemove'],
      linkInsertButtons: [],
      linkText: false
    }, buttonsWithAsset, froalaDefaults),

    'inline-no-tags': _.defaults({
      theme: 'gray',
      charCounterCount: false,
      toolbarSticky: false,
      linkEditButtons: ['linkEdit', 'linkRemove'],
      linkInsertButtons: [],
      linkText: false,
      htmlAllowedTags: [],
      htmlRemoveTags: ['p']
    }, buttonsWithAsset, froalaDefaults),

    'inline-header': _.defaults({
      theme: 'gray',
      charCounterCount: true,
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 300,
      charCounterMax: 180,
      inlineMode: false,
      pasteDeniedTags: ['img']
    }, threeButtons, froalaDefaults),

    'inline-with-video': _.defaults({
      theme: 'gray',
      charCounterCount: false,
      toolbarSticky: false,
      videoInsertButtons: ['videoBack', '|', 'videoByURL'],
      videoEditButtons: ['videoAlign', 'videoSize', 'videoRemove'],
      linkEditButtons: ['linkEdit', 'linkRemove'],
      linkInsertButtons: [],
      linkText: false
    }, buttonsWithVideoAndAsset, froalaDefaults),

    'inline-title': _.defaults({
      theme: 'gray',
      charCounterCount: true,
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 300,
      charCounterMax: 110,
      inlineMode: false,
      pasteDeniedTags: ['img']
    }, threeButtons, froalaDefaults),

    'inline-just-colors': _.defaults({
      toolbarInline: true,
      charCounterCount: false,
      toolbarBottom: false,
      toolbarVisibleWithoutSelection: false
    }, colorsOnly, froalaDefaults),

    'edit-inline': _.defaults({
      toolbarInline: true,
      charCounterCount: false,
      toolbarBottom: false,
      fontSizeSelection: true,
      fontSize: ['10', '11', '12', '14', '18', '24', '30', '36', '44', '48', '60', '72', '96', '106']
    }, inlineButtons, froalaDefaults),

    'edit-inline-with-events': _.defaults({
      toolbarInline: true,
      charCounterCount: false,
      toolbarBottom: false,
      fontSizeSelection: true,
      fontSize: ['10', '11', '12', '14', '18', '24', '30', '36', '44', '48', '60', '72', '96', '106'],
      // the text section offers the ability to alter the text color on a super granular
      // level. This creates issues where li bullet points may appear to be missing or
      // may not be the desired color. This contentChanged handler will match bullet points
      // to the color of their child text. See https://classydev.atlassian.net/browse/FRS-7760
      // for more detail.
      events: getEditorEvents({ applyListColor: true })
    }, inlineButtons, froalaDefaults),

    'edit-inline-header': _.defaults({
      toolbarInline: true,
      charCounterCount: false,
      toolbarBottom: false,
      fontSizeSelection: true,
      fontSize: ['10', '11', '12', '14', '18', '24', '30', '36', '44', '48', '60', '72', '96', '106'],
      paragraphStyles: {
        outlined: 'Outline'
      }
    }, inlineButtonsPara, froalaDefaults),

    'edit-inline-subheader': _.defaults({
      toolbarInline: true,
      charCounterCount: false,
      toolbarBottom: false,
      fontSizeSelection: true,
      fontSize: ['10', '11', '12', '14', '18', '24', '30', '36', '48', '60', '72'],
      fontSizeDefaultSelection: '72'
    }, buttonsWithVideoAndAsset, froalaDefaults),

    'edit-inline-about': _.defaults({
      toolbarInline: true,
      charCounterCount: false,
      toolbarBottom: false,
      fontSizeSelection: true,
      fontSize: ['10', '11', '12', '14', '18', '24', '30', '36', '48', '60', '72'],
      fontSizeDefaultSelection: '72',

      // the about section offers the ability to alter the text color on a super granular
      // level. This creates issues where li bullet points may appear to be missing or
      // may not be the desired color. This contentChanged handler will match bullet points
      // to the color of their child text. See https://classydev.atlassian.net/browse/FRS-7760
      // for more detail.
      events: getEditorEvents({ applyListColor: true })
    }, inlineButtons, froalaDefaults),

    'edit-inline-about-title': _.defaults({
      toolbarInline: true,
      charCounterCount: false,
      toolbarBottom: false,
      fontSizeSelection: true,
      fontSize: ['10', '11', '12', '14', '18', '24', '30', '36', '48', '60', '72'],
      fontSizeDefaultSelection: '72',
      pasteDeniedTags: ['img']
    }, inlineButtons, froalaDefaults),

    'edit-inline-title': _.defaults({
      zIndex: 2,
      heightMin: 55,
      heightMax: 100,
      multiLine: false,
      // The API allows up to 255 char, but <p></p> wraps
      // the field in Froala
      charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_POST_TITLE,
      toolbarInline: false,
      toolbarBottom: false,
      toolbarSticky: false,
      useFrTag: true,
      theme: 'paragraph',
      editorClass: 'update-title',
      pastePlain: true,
      placeholderText: "Enter your update's title here..."
    }, noButtons, froalaDefaults),

    'edit-inline-title-mobile': _.defaults({
      zIndex: 2,
      heightMin: 55,
      heightMax: 100,
      multiLine: false,
      // The API allows up to 255 char, but <p></p> wraps
      // the field in Froala
      charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_POST_TITLE,
      toolbarInline: false,
      toolbarBottom: false,
      toolbarSticky: false,
      useFrTag: true,
      theme: 'paragraph',
      editorClass: 'update-title fr-classy-mobile',
      pastePlain: true,
      placeholderText: "Enter your update's title here..."
    }, noButtons, froalaDefaults),

    'edit-inline-simple': _.defaults({
      toolbarInline: true,
      toolbarBottom: false,
      charCounterCount: false,
      toolbarVisibleWithoutSelection: false,
      pasteDeniedTags: ['img']
    }, threeButtons, froalaDefaults),

    'edit-inline-plaintext': _.defaults({
      toolbarInline: true,
      toolbarBottom: false,
      charCounterCount: false,
      htmlAllowedTags: ['strong', 'em', 'u'],
      multiLine: false,
      pasteDeniedTags: ['img']
    }, threeButtons, froalaDefaults),

    'merge-tags': _.defaults({
      keepFormatOnDelete: true,
      theme: 'merge-tag',
      charCounterCount: false,
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 300,
      fontFamily: { 'Arial,Helvetica,sans-serif': 'Arial' },
      fontSizeSelection: false,
      fontSize: ['12', '14', '16', '18', '20', '24', '30', '36'],
      enter: $.FroalaEditor.ENTER_BR
    }, mergeTagButtons, froalaDefaults),

    'only-merge-tag': _.defaults({
      keepFormatOnDelete: true,
      theme: 'merge-tag',
      charCounterCount: false,
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 300,
      fontFamily: { 'Arial,Helvetica,sans-serif': 'Arial' },
      fontSizeSelection: false,
      fontSize: ['12', '14', '16', '18', '20', '24', '30', '36'],
      enter: $.FroalaEditor.ENTER_BR
    }, onlyMergeTag, froalaDefaults),

    'update-paragraph': _.defaults({
      toolbarSticky: false,
      theme: 'paragraph',
      editorClass: 'update-paragraph',
      placeholderText: 'Begin your story here...',
      charCounterCount: false,
      videoInsertButtons: ['videoBack', '|', 'videoByURL'],
      videoEditButtons: ['videoAlign', 'videoSize', 'videoRemove']
    }, buttonsWithVideoAndAsset, froalaDefaults),

    /* PLAIN TEXT EDITORS
    ========================================================= */
    'bare-headline': _.defaults({
      theme: 'gray-bare',
      charCounterCount: false,
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 150,
      pastePlain: true
    }, noButtons, froalaDefaults),

    'bare-headline-max-length': _.defaults({
      theme: 'gray-bare',
      charCounterCount: true,
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 150,
      charCounterMax: 255,
      inlineMode: false
    }, noButtons, froalaDefaults),

    'bare-headline-100': _.defaults({
      theme: 'gray-bare',
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 300,
      charCounterMax: 100
    }, noButtons, froalaDefaults),

    'bare-headline-200': _.defaults({
      theme: 'gray-bare',
      toolbarSticky: false,
      heightMax: 75,
      charCounterMax: 200,
      editorClass: 'fundraiser-headline'
    }, noButtons, froalaDefaults),

    'bare-headline-400': _.defaults({
      theme: 'gray-bare',
      toolbarSticky: false,
      heightMin: 70,
      heightMax: 300,
      charCounterMax: 400
    }, noButtons, froalaDefaults),

    'bare-paragraph': _.defaults({
      theme: 'gray-bare',
      charCounterCount: false,
      toolbarSticky: false,
      linkEditButtons: ['linkEdit', 'linkRemove'],
      linkInsertButtons: [],
      linkText: false
    }, noButtons, froalaDefaults),

    paragraph: _.defaults({
      theme: 'gray-bare',
      charCounterCount: false,
      toolbarSticky: false,
      linkEditButtons: ['linkEdit', 'linkRemove'],
      linkInsertButtons: [],
      editorClass: 'bordered-paragraph',
      linkText: false
    }, noButtons, froalaDefaults),

    'update-paragraph-mobile': _.defaults({
      toolbarSticky: false,
      theme: 'paragraph',
      editorClass: 'update-paragraph fr-classy-mobile',
      placeholderText: 'Begin your story here...',
      charCounterCount: false,
      toolbarBottom: false
    }, buttonsWithVideoAndAsset, froalaDefaults),

    textarea: _.defaults({
      theme: 'textarea',
      charCounterCount: false,
      toolbarSticky: false,
      linkEditButtons: [],
      linkInsertButtons: [],
      linkText: false
    }, noButtons, froalaDefaults),

    'textarea-no-break': _.defaults({
      theme: 'textarea',
      multiLine: false,
      toolbarSticky: false,
      linkEditButtons: [],
      linkInsertButtons: [],
      linkText: false,
      enableShortcuts: ['undo', 'redo']
    }, noButtons, froalaDefaults),

    'textarea-counter': _.defaults({
      theme: 'textarea',
      toolbarSticky: false,
      linkEditButtons: [],
      linkInsertButtons: [],
      linkText: false,
      enableShortcuts: ['undo', 'redo'],
      events: getEditorEvents({ noHTML: true })
    }, noButtons, froalaDefaults),

    'single-line-counter': _.defaults({
      theme: 'singleline',
      toolbarSticky: false,
      linkEditButtons: [],
      linkInsertButtons: [],
      linkText: false,
      enableShortcuts: ['undo', 'redo'],
      enter: $.FroalaEditor.ENTER_BR,
      events: getEditorEvents({ plainText: true, singleLine: true, noHTML: true })
    }, noButtons, froalaDefaults)
  };

  return function (options) {
    var overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var typeOptions = froalaOptions[options.type];

    return _.defaults(overrides, typeOptions);
  };

  /* misc utils / handlers
  ========================================================= */

  /**
   * getEditorEvents
   *
   * abstraction for some repitive code above that simplified which types of events
   * each editor needs
   *
   * @param opts - options for the event
   * @return {Object} - the events object
   */
  function getEditorEvents(_ref) {
    var _ref$images = _ref.images,
        images = _ref$images === undefined ? false : _ref$images,
        _ref$plainText = _ref.plainText,
        plainText = _ref$plainText === undefined ? false : _ref$plainText,
        _ref$applyListColor = _ref.applyListColor,
        applyListColor = _ref$applyListColor === undefined ? false : _ref$applyListColor,
        _ref$singleLine = _ref.singleLine,
        singleLine = _ref$singleLine === undefined ? false : _ref$singleLine,
        _ref$noHTML = _ref.noHTML,
        noHTML = _ref$noHTML === undefined ? false : _ref$noHTML;

    var events = {
      'froalaEditor.html.set': function froalaEditorHtmlSet(_e, editor) {
        if (editor.opts.ariaLabel) {
          $(editor.el).attr('aria-label', editor.opts.ariaLabel);
        }
        if (editor.opts.charCounterCount) {
          editor.events.trigger('contentChanged');
        }
      },
      // Allow unused parameters:
      // eslint-disable-next-line no-unused-vars
      'froalaEditor.html.get': function froalaEditorHtmlGet(_e, editor, currentText) {
        if (noHTML) {
          var doc = new DOMParser().parseFromString(currentText, 'text/html');
          var newText = doc.documentElement.textContent;

          // eslint-disable-next-line no-undef
          var sanitizedText = filterXSS(newText);

          return sanitizedText;
        }

        return currentText;
      },
      'froalaEditor.initialized': function froalaEditorInitialized(_e, editor) {
        editor.events.trigger('charCounter.update');
        attachDropHandlers(_e, editor, { plainText: plainText, images: images, singleLine: singleLine });
      }
    };

    events['froalaEditor.drop'] = function (_e, editor) {
      $timeout(function () {
        editor.events.trigger('contentChanged');
      });
    };

    if (applyListColor) {
      events['froalaEditor.contentChanged'] = matchLiToChildColor;
    }

    return events;
  }

  /**
   * getDataTransfer
   *
   * during a drag and drop event there will be an originalEvent present, however,
   * during a clipboard paste event there is a clipboardData object of the same type
   * (DataTransfer) which also will contain the files and/or text if it was copied
   * these events can be handled the same way
   *
   * @param event
   * @return {DataTransfer}
   */
  function getDataTransfer(dropEvent) {
    return (dropEvent.originalEvent || {}).dataTransfer || dropEvent.clipboardData;
  }

  /**
   * sanitizeDrop
   *
   * strip out the tags of a copy/pasted block of code and put the results in the
   * correct location within the froala editor. Some of this madness comes from:
   * https://www.froala.com/wysiwyg-editor/examples/drop-content
   *
   * @param $e - jQuery paste event
   * @param editor = the Froala editor instance
   * @return {boolean}
   */
  function sanitizeDrop($e, editor) {
    var stripTags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var singleLine = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    var dataTransfer = getDataTransfer($e);
    var charCountMax = (editor.opts || {}).charCounterMax || Infinity;
    var text = dataTransfer.getData('text');

    // sometimes the content of the data transfer is an empty string that may represent
    // a blob containing an image or similar asset we don't want handled by froala. If
    // the value is falsey, we just ignore it, as it may result in undesired consequences
    // such as the image being uploaded to froala's temporary CDN.
    if (!text) return false;

    // if the text is a single line, we want to replace the newlines or carriage returns
    // with a space so that the text is not broken up into multiple lines
    if (singleLine) {
      var currentText = editor.html.get();
      var currentLength = currentText.length;

      // Calculate the number of characters that can be added to the editor
      var charsLeft = charCountMax - currentLength;

      text = text.replace(/(\r\n|\n|\r)/gm, '');

      // If the text is longer than the number of characters left, we want to trim it
      // to the number of characters left
      if (text.length > charsLeft) {
        text = text.substr(0, charsLeft);
      }

      var fullText = currentText + text;

      editor.html.set(fullText, true);

      return false;
    }

    // if there are no tags in the drop, we want the editor to handle it
    // so we return true here in order to tell the froala editor to continue
    // doing it's thang
    if (!text.match(/<.*>/)) return true;

    // insert the new text into the html, at the cursor position
    editor.html.insert(text);

    // stip the tags and trim the entire container to fit within the charcount bounds
    // if there are any tags inside the text. Otherwise we want default functionality
    // as it preserves the cursor position
    if (stripTags) {
      var html = editor.html.get();
      var div = document.createElement('div');
      div.innerHTML = html;
      text = div.textContent || div.innerText || '';
      editor.html.set(text.substr(0, charCountMax));
    }

    editor.undo.saveStep();
    $e.preventDefault();
    $e.stopPropagation();

    $timeout(function () {
      editor.events.trigger('contentChanged');
    });

    return false;
  }

  /**
   * handleDrop
   *
   * when an image is dragged from a finder or explorer window we need
   * to make sure that the image goes through the normal plugin flow for
   * scImgAssetFroalaUpload, so that we can save it to S3 and use our own
   * cdn link in the markup.
   * See https://www.froala.com/wysiwyg-editor/examples/drop-content for
   * documentation information
   *
   * @param {object} dropEvent - the actual event of the drop
   * @returns {boolean} - conclude event handler
   */
  function handleDrop(dropEvent, editor) {
    var dataTransfer = getDataTransfer(dropEvent);

    if (dataTransfer && dataTransfer.files && dataTransfer.files.length) {
      var img = dataTransfer.files[0];

      // if the file is an image, we will send it through the plugin
      // flow, so it can be parsed and uploaded correctly
      if (img && img.type && img.type.indexOf('image') !== -1) {
        dropEvent.preventDefault();
        dropEvent.stopPropagation();
        scImgAssetFroalaUpload(editor).handleImageDrop(img);
        return false;
      }
    }

    // if we are allowing images, chances are we do not wanna strip out the html
    // tags if any of them are pasted around the image, so we pass the false flag
    // to sanitizeDrop
    return sanitizeDrop(dropEvent, editor, false);
  }

  /**
   * attachDropHandlers
   *
   * util function for abstraction of drop handler attachment, takes the default
   * parameters for a froala event handler so it can be used like so:
   * 'froalaEditor.initialized': attachDropHandlers
   *
   * @param {object} _e - unused event
   * @param {object} editor - editor being dropped in
   */
  function attachDropHandlers(_e, editor) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : { images: false, plainText: false, singleLine: false };

    if (options.images) {
      editor.events.on('drop', function (event) {
        return handleDrop(event, editor);
      }, true);
      editor.events.on('paste.before', function (event) {
        return handleDrop(event, editor);
      }, true);
    } else if (options.plainText) {
      editor.events.on('paste.before', function (event) {
        return sanitizeDrop(event, editor, true, options.singleLine);
      }, true);
    } else {
      editor.events.on('paste.before', function (event) {
        return sanitizeDrop(event, editor, false, options.singleLine);
      }, true);
    }
  }

  /**
   * matchLiToChildColor
   *
   * specifically used for Froala contentChanged handlers so that li
   * elements can assume the color of their child spans rather than
   * inheriting it from a parent further up the dom
   *
   * @param {object} _e - unused event arg
   * @param {object} editor - the editor that changed content
   * @returns {boolean} - conclude event handler
   */
  function matchLiToChildColor(_e, editor) {
    if (editor && editor.$el) {
      editor.$el.find('li').each(function (_index, element) {
        var $li = $(element);
        var currentColor = $li.css('color');
        var contentColor = $li.find('span').eq(0).css('color');

        // Froala sets inline styles on nested spans rather than at the li. This
        // makes the li inherit it's color from a parent, which could be much higher
        // up the dom tree. In order to match the li bullets to the context's color
        // we pull it from their first child. So that the color flow seems natural.
        // See https://classydev.atlassian.net/browse/FRS-7760 for more detail.
        if (contentColor !== currentColor) {
          $li.css('color', contentColor);
        }
      });
    }
  }
}

angular.module('classy').factory('scFroalaOptions', scFroalaOptions);
})();