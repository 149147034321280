(function(){
'use strict';

// object keys within this array will have their values redacted
var redactKeys = ['cc', 'password', 'cvv', 'cardnumber', 'authorization'];

// matches underscores, dashes, periods, and whitespace
var normalizeRegExp = new RegExp(/\s|-|\.|_/, 'g');

// redacts object values with any redactKeys listed above
var redact = function redact(_) {
  return function (data) {
    if (!_.isPlainObject(data) && !Array.isArray(data)) return data;

    return _.cloneDeepWith(data, function (value, key) {
      // check to see if value looks like a JSON string
      // and attempt to parse it if so. An exception just
      // means that the string wasn't JSON, so continue flow
      if (typeof value === 'string' && value.charAt(0) === '{') {
        try {
          return redact(_)(JSON.parse(value));
        } catch (e) {}
      }

      if (typeof key === 'string' && !_.isObject(value)) {
        var keyNormalized = key.toLowerCase().replace(normalizeRegExp, '');
        return redactKeys.includes(keyNormalized) ? '[redacted]' : value;
      }

      return undefined;
    });
  };
};

var redactFactory = function redactFactory() {
  'use strict';

  return redact(_);
};

// export function safely for consumption in express layer
// or prepare it as an angular module depending on whether
// we are in a node context
if (typeof module !== 'undefined' && module.exports) {
  module.exports = redact(require('lodash'));
} else {
  angular.module('classy').factory('redact', redactFactory);
}
})();