(function(){
'use strict';

/**
 * @kind directive
 * @name scCreditCardValidation
 *
 * @description
 * Tests a credit card number against the Luhn Algorithm
 *
 *
 */

function scCreditCardValidation() {
  return {
    restrict: 'A',
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attributes, ngModel) {
      var luhnChk = function luhnChk(val) {
        // accept only digits, dashes or spaces
        if (/[^0-9-\s]+/.test(val)) return false;

        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0,
            nDigit = 0,
            bEven = false;

        var value = val.replace(/\D/g, '');

        for (var n = value.length - 1; n >= 0; n -= 1) {
          var cDigit = value.charAt(n);
          nDigit = parseInt(cDigit, 10);

          if (bEven) {
            nDigit *= 2;
            if (nDigit > 9) nDigit -= 9;
          }

          nCheck += nDigit;
          bEven = !bEven;
        }

        return nCheck % 10 == 0;
      };

      function luhnCheck(value) {
        ngModel.$setValidity('sc-credit-card-validation', luhnChk(value));
        return value;
      }

      ngModel.$parsers.push(luhnCheck);
      ngModel.$formatters.push(luhnCheck);
    }
  };
}

angular.module('classy').directive('scCreditCardValidation', scCreditCardValidation);
})();