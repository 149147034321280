(function(){
'use strict';

frSwitchFlow.$inject = ["$http", "$rootScope", "$timeout", "$q", "scCampaignsService", "scFlowModalService", "scThemesService", "scFundraisingPagesService", "scCurrencyService", "scFundraisingTeamsService", "scMembersService", "createFundraisingPageFlow", "createFundraisingTeamFlow", "scAnalytics", "scQaId", "scProgressDataFactory"];
function frSwitchFlow($http, $rootScope, $timeout, $q, scCampaignsService, scFlowModalService, scThemesService, scFundraisingPagesService, scCurrencyService, scFundraisingTeamsService, scMembersService, createFundraisingPageFlow, createFundraisingTeamFlow, scAnalytics, scQaId, scProgressDataFactory) {
  'use strict';

  function fundraiseSwitchFlow() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var $scope = $rootScope.$new(),
        views = [],
        tplPath = 'global/flows/fundraise-switch/views',
        campaign = scCampaignsService.active.current;

    $scope.campaign = campaign;
    $scope.scQaId = scQaId;

    /* -------------------------------------------------------------------- *
     * UI
     * -------------------------------------------------------------------- */

    $scope.ui = {
      primaryColor: _.get(scThemesService, 'active.current.styles.primaryColor', '#36bff7'),
      switching: false,
      disableIndividual: campaign.team_membership_policy == 'required',
      disableCreateTeam: campaign.allow_team_fundraising == false || campaign.team_membership_policy == 'prohibited',
      disableJoinTeam: campaign.team_membership_policy == 'prohibited'
    };

    _.merge($scope.ui, options);

    /* -------------------------------------------------------------------- *
     * General Navigation
     * -------------------------------------------------------------------- */

    $scope.createIndiv = function () {
      if ($scope.ui.switching) {
        return;
      }
      $scope.ui.switching = true;
      createFundraisingPageFlow(options);
      $scope.beginCheckout();
    };

    $scope.browseTeams = function () {
      $scope.ui.browsing = true;
      scFlowModalService.to('browse-teams', 'slideLeft');

      $scope.beginCheckout();
    };

    $scope.createTeam = function () {
      if ($scope.ui.switching) {
        return;
      }
      $scope.ui.switching = true;
      createFundraisingTeamFlow(options);

      $scope.beginCheckout();
    };

    $scope.beginCheckout = function () {
      // Begin Checkout Event
      var currency = scCurrencyService.getEntityCampaignCurrency(campaign);
      scAnalytics.eventBeacon({
        label: 'Begin Checkout',
        action: 'checkout',
        currency: currency,
        items: [{
          item_id: campaign.id,
          item_name: campaign.name,
          item_category: campaign.type,
          currency: currency
        }]
      });
    };

    $scope.getTeamImage = scFundraisingTeamsService.getTeamPhoto;

    $scope.joinTeam = function (team) {
      if ($scope.ui.switching) {
        return;
      }

      $scope.ui.switching = true;

      var currentUserId = _.get(scMembersService, 'active.current.id', false);

      // IF user is not logged in, launch create flow, which launches the SSO flow in this case.
      // Note: yes, we check scMembersService.active.current.id in there too, so it's duplicate
      // logic, but since it's an inexpensive check on the id and the create flow is launched
      // from other parts of the app, so we're gonna refrain from touching that.
      if (!currentUserId) {
        createFundraisingPageFlow(Object.assign({}, options, { teamId: team.id }));
      } else {
        // IF user is logged in, check for any existing pages the user might have for this campaign
        var userPages = Object.values(scFundraisingPagesService.userFundraisers) || [];

        // IF: user has ONE page, check if we need to transfer teams or simply join desired team.
        var pageToTransfer = null;
        if (!campaign.allow_duplicate_fundraisers && userPages.length) {
          // If we don't allow duplicate fundraisers but there are multiple pages somehow,
          // select the most recently created page. Last index means higher id #, which means
          // most recently created. This shouldn't happen often... but just in case...
          pageToTransfer = userPages[userPages.length - 1];

          $scope.teamName = team.name;
          $scope.teamId = team.id;
          $scope.newTeam = team;
          $scope.fundraisingPage = pageToTransfer.current;
          $scope.currentTeam = _.get(scFundraisingTeamsService, 'teams[' + pageToTransfer.current.fundraising_team_id + '].current', {});
          createFundraisingPageFlow(Object.assign({ startingView: 'indiv.join-team' }, options), $scope);
        } else if (campaign.allow_duplicate_fundraisers && userPages.length) {
          // ELSE IF: if multiple fundraising pages are allowed, redirect to the multiple pages
          // view with the team info that view will need.
          createFundraisingPageFlow(Object.assign({ startingView: 'indiv.multiple-pages', teamId: team.id }, options), $scope);
        } else if (!userPages.length) {
          // ELSE: member has no pre-existing fundraising pages, redirect to create page on team.
          createFundraisingPageFlow(Object.assign({}, options, { teamId: team.id }), $scope);
        }
      }
    };

    $scope.teamSearchOptions = [{
      label: 'Team',
      value: 'team'
    }, {
      label: 'Team Captain',
      value: 'team-captain'
    }];

    $scope.selectedOption = 'team';

    $scope.$watch('selectedOption', function () {
      $scope.clearTeamQuery();
    });

    /* -------------------------------------------------------------------- *
     * VIEWS : Mode
     * -------------------------------------------------------------------- */

    var individualFundraisingOnly = $scope.ui.disableJoinTeam && $scope.ui.disableCreateTeam;

    if (individualFundraisingOnly) {
      $scope.createIndiv();
    } else {
      var joinOnly = $scope.ui.disableCreateTeam && $scope.ui.disableIndividual;
      if (!_.get(options, 'browseTeams', false) && !joinOnly) {
        views.push({
          id: 'mode',
          templateUrl: tplPath + '/mode',
          maxWidth: 640
        });
      }

      /* -------------------------------------------------------------------- *
       * VIEWS : Browse Teams
       * -------------------------------------------------------------------- */

      views.push({
        id: 'browse-teams',
        templateUrl: tplPath + '/browse-teams',
        maxWidth: 550,
        eventBeacon: function eventBeacon() {
          scAnalytics.eventBeacon({
            category: 'frs ' + $scope.campaign.type,
            action: 'triggers',
            label: 'Join a Team'
          });
        }
      });
    }

    _.merge($scope.ui, {
      totalTeams: 0,
      totalPages: 0,
      currentPage: 1,
      nextPageUrl: null,
      prevPageUrl: null,
      teamsLoading: false
    });

    $scope.teams = [];

    $scope.clearTeamQuery = function () {
      $scope.teamQuery = { queryString: '' };
      $scope.captainFirstNameQuery = { queryString: '' };
      $scope.captainLastNameQuery = { queryString: '' };
    };

    $scope.clearTeamQuery();
    $scope.filter = {};

    $scope.$watch('teamQuery.queryString', function (newVal) {
      if (newVal !== '' && newVal != undefined) {
        $scope.filter = {
          name: [{
            operand: '*=',
            value: newVal
          }]
        };
      } else {
        $scope.filter = {};
      }
    });

    $scope.$watchGroup(['captainFirstNameQuery.queryString', 'captainLastNameQuery.queryString'], function (newVals) {
      if (newVals[0] || newVals[1]) {
        $scope.filter = {};
        if (newVals[0]) {
          $scope.filter['primary_captain.first_name'] = [{
            operand: '*=',
            value: newVals[0]
          }];
        }
        if (newVals[1]) {
          $scope.filter['primary_captain.last_name'] = [{
            operand: '*=',
            value: newVals[1]
          }];
        }
      } else {
        $scope.filter = {};
      }
    });

    $scope.getTeamsEndpoint = '/campaign/' + campaign.id + '/joinable-teams';

    $scope.$watch('teams', function (teams) {
      if (!_.isEmpty(teams)) {
        _.forEach(teams, function (team) {
          getTeamCaptain(team).then(function (response) {
            team.captain_name = response;
          });
        });
      }
    });

    $scope.getTeamProgress = function (team) {
      return Math.round(scProgressDataFactory.getEntityProgress(team).percentToGoal * 100);
    };
    $scope.getTeamGoal = function (team) {
      return scProgressDataFactory.getEntityProgress(team).goal;
    };

    var getTeamCaptain = function getTeamCaptain(team) {
      var deferred = $q.defer();
      var teamCaptainName = '';

      $http({
        url: '/frs-api/fundraising-teams/' + team.id + '/fundraising-pages',
        method: 'GET',
        params: {
          filter: 'team_role=primary-captain'
        }
      }).then(function (response) {
        if (response.data.data.length) {
          var teamCaptain = response.data.data[0];
          teamCaptainName = teamCaptain.alias || teamCaptain.title;
        } else {
          teamCaptainName = 'No current captain';
        }
        deferred.resolve(teamCaptainName);
      });
      return deferred.promise;
    };

    /* -------------------------------------------------------------------- *
     * Registration and Launch
     * -------------------------------------------------------------------- */

    if (!_.isEmpty(views)) {
      scFlowModalService.register('fundraiseSwitchFlow', $scope, views);

      $timeout(function () {
        scFlowModalService.open('fundraiseSwitchFlow', {
          animate: options.animate !== false,
          closable: true,
          transitionWhitelist: ['frs.landing.name.campaign.create-fundraising-page', 'frs.team.index.create-fundraising-page', 'frs.landing.name.campaign.create-fundraising-team'],
          elementToFocusOnClose: '.p2p-crowdfunding_button.join'
        });

        scAnalytics.eventBeacon({
          category: 'frs ' + $scope.campaign.type,
          action: 'pops up',
          label: 'Fundraiser Sign Up Modal'
        });
      });
    }
    return $scope;
  }

  return fundraiseSwitchFlow;
}

angular.module('classy').factory('fundraiseSwitchFlow', frSwitchFlow);
})();