(function(){
'use strict';

getParamByName.$inject = ["$location"];
function getParamByName($location) {
  return function (name) {
    var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : $location.search;

    var fixedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');

    var regex = new RegExp('[\\?&]' + fixedName + '=([^&#]*)'),
        results = regex.exec(location);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };
}

angular.module('classy').factory('getParamByName', getParamByName);
})();