(function(){
'use strict';

function orderObjectByDate() {
  return function (input, attribute) {
    if (!angular.isObject(input)) return input;

    var array = Object.keys(input).map(function (key) {
      return input[key];
    });

    array.sort(function (a, b) {
      var firstDate = a[attribute].replace(/-/g, '/');
      var secondDate = b[attribute].replace(/-/g, '/');
      return Math.abs(new Date(firstDate) - new Date(secondDate));
    });

    return array;
  };
}

angular.module('classy').filter('orderObjectByDate', orderObjectByDate);
})();