(function(){
'use strict';

angular.module('classy').constant('teamBlockDemoData', {
  topThreeTeams: [{
    id: 1,
    overallRanking: 1,
    name: "Team's Name",
    total_raised: 300
  }, {
    id: 2,
    overallRanking: 2,
    name: 'Another Team',
    total_raised: 100
  }, {
    id: 3,
    overallRanking: 3,
    name: 'Team Three',
    total_raised: 56
  }],
  leaders: [{
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 990,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468146,
    member_id: 1403661,
    organization_id: 20688,
    designation_id: null,
    title: 'Weekly Winner',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Bob Robertson',
    type: 'fundraiser',
    measure: '$',
    metric: 'raised this week',
    weekly_raised: 725,
    total_comments: 12
  }, {
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 2990,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468146,
    member_id: 1403661,
    organization_id: 20688,
    designation_id: null,
    title: 'Most Raised',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Rick Dickens',
    type: 'fundraiser',
    measure: '$',
    metric: 'Raised',
    weekly_raised: 210,
    total_comments: 4
  }, {
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 52,
    total_donors: 52,
    total_raised: 990,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468146,
    member_id: 1403661,
    organization_id: 20688,
    designation_id: null,
    title: 'Most Donors',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Alice Allison',
    type: 'fundraiser',
    measure: '$',
    metric: 'Donors',
    weekly_raised: 100,
    total_comments: 6
  }, {
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 990,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468146,
    member_id: 1403661,
    organization_id: 20688,
    designation_id: null,
    title: 'Most Activity',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Liz Elizabeths',
    type: 'fundraiser',
    measure: '$',
    metric: 'Comments',
    weekly_raised: 10,
    total_comments: 74
  }]
});
})();