(function(){
'use strict';

// TODO:
// - create ExitIntentModal factory
// - potentially create "ExitIntent" service to house 1..m modals + other "exit intent" features/products
// - clean up logs
// - clean up init declarations
// -- move isMobile to a service or exit intent service (is there one somewhere in appv2?)
angular.module('classy').component('exitIntentModal', {
  templateUrl: 'global/components/exit-intent-modal/global.components.exit-intent-modal',
  bindings: {
    blockerId: '@' // Element id. Elem id is in view, blocks modal from opening
  },
  controller: ["$log", "$timeout", "$window", "$document", "scThemesService", function controller($log, $timeout, $window, $document, scThemesService) {
    var _this = this;

    var self = this;
    var TIMEOUT_COOLDOWN = 30000; // 30 seconds
    var TIMEOUT_DESKTOP = 3000;
    var SCROLL_SPEED_THRESHOLD = -15;

    var cooldownPromise = null;
    var mouseLeavePromise = null;
    var mouseLeaveTimestamp = null;

    // Think of 'is' as this component's state
    this.is = {
      open: false, // Is the modal currently open?
      opening: false, // Is it in the process of opening? For animation
      hot: false, // Is 30s cooldown complete?
      desktop: false, // Is desktop device?
      beenOpened: false, // Can be opened once, has it?
      desktopTriggerable: false, // Meets desktop trigger conditions?
      mobileTriggerable: false, // Meets mobile trigger conditions?
      blocked: false, // Is the modal currently blocked because the "blocker" is visible?
      prevBlocked: false // Was it blocking on the last "canOpenModal" attempt?
    };

    this.$onInit = function () {
      _this.primaryColor = _.get(scThemesService, 'active.current.styles.primaryColor') || '#425CCD';
      _this.is.open = false;
      _this.is.opening = false;
      _this.is.desktop = !isMobile();

      start();
    };

    // Starts a new "session" (Note: $onInit calls this)
    function start() {
      $log.info('[exitIntentModal:start] new session starting...');
      cooldownPromise = null;
      mouseLeavePromise = null;
      mouseLeaveTimestamp = null;

      self.is.hot = false;
      self.is.desktopTriggerable = false;
      self.is.mobileTriggerable = false;
      self.is.blocked = false;
      self.is.prevBlocked = false;

      // Subscribe to events that reset cooldown
      $document.on('keydown', __handleKeydown);
      $document.on('click', __handleClick);
      $document.on('touchstart', __handleTouchstart);
      // Start cooldown
      startCooldown();

      // Subscribe to device-specific trigger events
      if (self.is.desktop) {
        $document.on('mouseenter', __handleMouseEnter);
        $document.on('mouseleave', __handleMouseLeave);
      } else {
        $document.on('scroll', __handleScroll);
      }
    }

    // Cooldown countdown must be complete before modal can be shown
    function startCooldown() {
      self.is.hot = false;

      cooldownPromise = $timeout(function () {
        $log.info('[exitIntentModal:startCooldown] 30s cooldown DONE');
        self.is.hot = true;
        openModal();
      }, TIMEOUT_COOLDOWN);
    }

    function openModal() {
      if (canOpenModal()) {
        // Execute $apply next digest cycle
        $timeout(function () {
          // Remove event handlers right before modal is opened
          destroyEventHandlers();
          // Open modal and prevent from being opened again
          self.is.open = true;
          self.is.opening = true;
          self.is.beenOpened = true;
        });

        $timeout(function () {
          self.is.opening = false;
        }, 150); // For transition animations
      } else {
        $log.info('[exitIntentModal:openModal] cannot show');
      }
    }

    function canOpenModal() {
      // Don't open if currently open
      if (self.is.open) {
        $log.info('[exitIntentModal:canOpenModal] NO, modal is open now');
        return false;
      }
      // Can only be opened once
      if (self.is.beenOpened) {
        $log.info('[exitIntentModal:canOpenModal] NO, modal already opened');
        return false;
      }
      // Cooldown must be complete (30 seconds)
      if (!self.is.hot) {
        $log.info('[exitIntentModal:canOpenModal] NO, cooldown is active');
        return false;
      }
      // On desktop the desktop trigger conditions must be met
      if (self.is.desktop && !self.is.desktopTriggerable) {
        $log.info('[exitIntentModal:canOpenModal] NO, desktop trigger conditions not met');
        return false;
      }
      // On mobile the mobile trigger conditions must be met
      if (!self.is.desktop && !self.is.mobileTriggerable) {
        $log.info('[exitIntentModal:canOpenModal] NO, mobile trigger conditions not met');
        return false;
      }
      // If blocker then must not be visible
      self.is.blocked = isBlockerVisible(); // TODO not "technically" visible - but more meets conditions Product went with
      // If blocker not visible but was just previously visible, then "reset" by calling start()
      if (self.is.prevBlocked && !self.is.blocked) {
        $log.info('[exitIntentModal:canOpenModal] NO, was just previously blocked');
        start();
        return false;
      }
      // Regardless of blocked now or not, set prev
      self.is.prevBlocked = self.is.blocked;
      if (self.is.blocked) {
        $log.info('[exitIntentModal:canOpenModal] NO, blocked');
        start();
        return false;
      }

      return true;
    }

    this.closeModal = function () {
      $timeout(function () {
        self.is.open = false;
      });
    };

    function enableTriggerable() {
      destroyDeviceTriggerEventHandlers();

      if (self.is.desktop) {
        self.is.desktopTriggerable = true;
      } else {
        self.is.mobileTriggerable = true;
      }
    }

    // Copied from https://gist.github.com/rabidsheep/6d21783968d7441fe745f52f5f9311ca
    function isMobile() {
      var ua = $window.navigator.userAgent;
      return (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) || /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
      );
    }

    // Copied from https://gist.github.com/rabidsheep/6d21783968d7441fe745f52f5f9311ca
    // scroll up = negative integer, scroll down = positive integer
    var getScrollSpeed = function () {
      var lastPos = void 0,
          newPos = void 0,
          timer = void 0,
          delta = void 0;
      var delay = 50; // in "ms" (higher means lower fidelity)

      function clear() {
        lastPos = null;
        delta = 0;
      }
      clear();

      return function () {
        newPos = window.scrollY;

        if (lastPos != null) {
          delta = newPos - lastPos;
        }
        lastPos = newPos;

        clearTimeout(timer);
        timer = setTimeout(clear, delay);

        return delta;
      };
    }();

    function isBlockerVisible() {
      if (!self.blockerId) {
        return false;
      }
      var blockerEl = document.getElementById(self.blockerId);
      if (!blockerEl) {
        $log.info('[exitIntentModal:isBlockerVisible] not found blockerId: ', self.blockerId);
        return false;
      }

      // Don't show if blockerEl is in the view OR has been scrolled past and thus out of view
      var blockerBounds = blockerEl.getBoundingClientRect();
      var isVisible = blockerBounds.top <= (window.innerHeight || document.documentElement.clientHeight);
      return isVisible;

      // Below sets isVisible = true if entire blocker is in view
      // (I am saving it here in case we change AC again...)
      // const height = window.innerHeight || document.documentElement.clientHeight; // not all browsers support window.innerHeight
      // const width = window.innerWidth || document.documentElement.clientWidth; // ""
      // const isVisible =
      // blockerBounds.top >= 0 &&
      // blockerBounds.left >= 0 &&
      // blockerBounds.right <= width &&
      // blockerBounds.bottom <= height;
    }

    /* Event Handlers */
    function __handleCooldownEvent() {
      if (cooldownPromise) {
        $timeout.cancel(cooldownPromise);
        cooldownPromise = null;
        startCooldown();
      }
    }

    function __handleKeydown() {
      $log.info('[exitIntentModal:__handleKeydown] resetting cooldown');
      __handleCooldownEvent();
    }

    function __handleClick() {
      $log.info('[exitIntentModal:__handleMouseClick] resetting cooldown');
      __handleCooldownEvent();
    }

    function __handleTouchstart() {
      $log.info('[exitIntentModal:__handleTouchstart] resetting cooldown');
      __handleCooldownEvent();
    }

    function __handleMouseEnter() {
      var timestamp = +new Date();
      $log.info('[exitIntentModal:__handleMouseEnter] timestamp diff (3s threshold): ', timestamp - mouseLeaveTimestamp);

      if (timestamp - mouseLeaveTimestamp <= TIMEOUT_DESKTOP) {
        if (mouseLeavePromise) {
          $log.info('[exitIntentModal:__handleMouseLeave] cancelling mouseleave promise');
          $timeout.cancel(mouseLeavePromise);
          mouseLeaveTimestamp = null;
          mouseLeavePromise = null;
        }
      }
    }

    function __handleMouseLeave(event) {
      var yPos = event && event.originalEvent && event.originalEvent.y;
      // Mouse leave must be out the "top"
      if (yPos && yPos <= 0) {
        $log.info('[exitIntentModal:__handleMouseLeave] TOP');
        mouseLeaveTimestamp = +new Date();

        if (!mouseLeavePromise) {
          mouseLeavePromise = $timeout(function () {
            $log.info('[exitIntentModal:__handleMouseLeave] desktop trigger TRUE');
            enableTriggerable();
            openModal();
          }, TIMEOUT_DESKTOP);
        }
      } else {
        $log.info('[exitIntentModal:__handleMouseLeave] not TOP');
      }
    }

    function __handleScroll() {
      var scrollSpeed = getScrollSpeed();
      $log.info('[exitIntentModal:__handleScroll] scroll speed: ', scrollSpeed);

      if (scrollSpeed < SCROLL_SPEED_THRESHOLD) {
        $log.info('[exitIntentModal:__handleMouseLeave] mobile trigger TRUE');
        enableTriggerable();
        openModal();
      }
    }

    /* Destroyers */
    this.$onDestroy = function () {
      destroyEventHandlers();
    };

    function destroyEventHandlers() {
      // Cooldown reset event(s)
      $document.off('keydown', __handleKeydown);
      $document.off('click', __handleClick);
      $document.off('touchstart', __handleTouchstart);

      destroyDeviceTriggerEventHandlers();
    }

    function destroyDeviceTriggerEventHandlers() {
      // Desktop trigger event(s)
      $document.off('mouseenter', __handleMouseEnter);
      $document.off('mouseleave', __handleMouseLeave);

      // Mobile trigger event(s)
      $document.off('scroll', __handleScroll);
    }
  }]
});
})();