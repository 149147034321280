(function(){
'use strict';

/**
 * @kind directive
 * @name scSelectProvinces
 * @requires ngModel
 *
 * @description
 * Renders a dropdown populated with Canadian provinces.
 *
 * WARNING: ngModel must be present on the original element, or else Angular
 * will not compile the select options!
 *
 * Used in:
 * application/views/scripts/checkout/donation.phtml
 *
 * @todo Isolate scope or namespace provinces property; right now this
 * directive will overwrite a scope property with the name 'provinces'.
 */

scSelectProvinces.$inject = ["CA_PROVINCE_LIST"];
function scSelectProvinces(CA_PROVINCE_LIST) {
  return {
    replace: true,

    template: '<select ng-options="province.value as province.label for province in provinces">' + '<option value="">Please Select</option></select>',

    link: function link(scope) {
      scope.provinces = _.cloneDeep(CA_PROVINCE_LIST);
    }
  };
}

angular.module('classy').directive('scSelectProvinces', scSelectProvinces);
})();