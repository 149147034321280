(function(){
'use strict';

angular.module('classy').constant('scGlobalCssBlockDefaults', {
  title: 'Global Css',
  type: 'global-css',

  mobileTemplates: false,

  remove: true
});
})();