(function(){
'use strict';

/* Program Designation */
angular.module('classy').service('EmbeddedGivingRecurringNudge', ["$stateParams", function ($stateParams) {
  this.isEnabled = function () {
    return !!$stateParams.egrn;
  };

  this.isShowable = function (frequency, amtCurrent, amtMax, amtMin) {
    if (!frequency || frequency !== 'one-time') {
      return false;
    }

    if (amtCurrent <= amtMin) {
      return false;
    }

    if (amtCurrent >= amtMax) {
      return false;
    }

    return true;
  };

  /* https://classydev.atlassian.net/browse/CL-18426
   * Divide the selected one-time amount by 4th and
   * show the Whole number value (rounding up to closest whole number) */
  this.getNudgeAmount = function (oneTimeAmount) {
    return Math.round(oneTimeAmount / 4);
  };

  // For now, exclusive to 'monthly'
  this.getNudgeFrequency = function () {
    return 'monthly';
  };
}]).component('egRecurringNudge', {
  templateUrl: 'global/embedded-giving/components/recurring-nudge/eg.components.recurring-nudge', // same folder as this
  bindings: {
    colorPrimary: '=',
    colorBackground: '=',
    currency: '=',
    currentAmount: '=',
    currentFrequency: '=',
    parentModel: '<'
  },
  controller: ["$log", "$rootScope", "EmbeddedGivingRecurringNudge", function controller($log, $rootScope, EmbeddedGivingRecurringNudge) {
    var _this = this;

    this.nudgeIcon = '/static/global/images/embedded-giving/embedded-giving-recurring-nudge.svg';

    this.$onInit = function () {
      _this.parentModel = _this.parentModel || {};

      if (_.isEmpty(_this.parentModel)) {
        $log.error('[egRecurringNudge:$onInit] ERR - no parent model');
        return _this;
      }

      if (!_this.colorPrimary || !_this.colorBackground) {
        $log.error('[egRecurringNudge:$onInit] ERR - primary and background colors required');
        return _this;
      }

      // Do i even need to set these values here?
      _this.currentAmount = _this.currentAmount || 100; // default?
      _this.currentFrequency = _this.parentModel.frequency || 'one-time';

      _this.nudgeAmount = EmbeddedGivingRecurringNudge.getNudgeAmount(_this.currentAmount);
      _this.nudgeFrequency = EmbeddedGivingRecurringNudge.getNudgeFrequency();

      return _this;
    };

    this.acceptNudge = function () {
      // TODO how does donation details set amount??
      _this.parentModel.frequency = _this.nudgeFrequency; // TODO setter on parent model??
      _this.parentModel.items[0].raw_final_price = _this.nudgeAmount; // TODO setter on parent model??

      $rootScope.$broadcast('recurring-nudge-event', 'accept');
    };

    // Whatever includes recurring nudge dictates how this event should be handled.
    this.rejectNudge = function () {
      $rootScope.$broadcast('recurring-nudge-event', 'reject');
    };

    this.goBack = function () {
      $rootScope.$broadcast('recurring-nudge-event', 'back');
    };
  }]
});
})();