(function(){
'use strict';

angular.module('classy').constant('CG_COINS', [{
  symbol: 'ETH',
  name: 'Ethereum'
}, {
  symbol: 'BTC',
  name: 'Bitcoin'
}, {
  symbol: 'USDC',
  name: 'USDC'
}]).constant('CG_COIN_DIGITS', 100000000).constant('CG_DONATION_AMOUNTS', [{ amount: 500, display_on_page: true }, { amount: 100, display_on_page: true }, { amount: 50, display_on_page: true }, { amount: 25, display_on_page: true }]).constant('CG_EVENTS', {
  donation: 'crypto-giving:root',
  contact: 'crypto-giving:contact',
  wallet: 'crypto-giving:deposit'
}).constant('CG_STATUSES', {
  disabled: 'disabled',
  enabled: 'enabled'
});
})();