(function(){
'use strict';

angular.module('classy').component('formColumn', {
  template: '<ng-transclude></ng-transclude>',
  transclude: true,
  bindings: {
    quarters: '<?'
  },
  controller: ["$element", function FormColumnController($element) {
    this.$onInit = function () {
      this.setupClassName();
    };

    this.$onChanges = function () {
      this.setupClassName();
    };

    this.setupClassName = function () {
      switch (this.quarters) {
        case 1:
          $element[0].className = 'form-table__column--one';
          break;
        case 2:
          $element[0].className = 'form-table__column--two';
          break;
        case 3:
          $element[0].className = 'form-table__column--three';
          break;
        case 4:
        default:
          $element[0].className = 'form-table__column';
      }
    };
  }]
});
})();