(function(){
'use strict';

scGlobalCssBlockSettings.$inject = ["scThemesService", "scQaId", "$stateParams", "scCampaignsTemplateService"];
function scGlobalCssBlockSettings(scThemesService, scQaId, $stateParams, scCampaignsTemplateService) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/global-css/settings',
    controller: ['$scope', function ($scope) {
      $scope.cssEditorOptions = {
        useWrapMode: true,
        theme: 'monokai',
        mode: 'css'
      };

      $scope.theme = scThemesService.active.current;
      $scope.scQaId = scQaId;
      $scope.disableGlobalCssBlock = false;
      $scope.disableGlobalSassBlock = false;
      if (SC.campaign && SC.campaign.campaignTemplateData) {
        $scope.disableGlobalCssBlock = scCampaignsTemplateService.getAddContentToPageBlock($stateParams.blockId);
        $scope.disableGlobalSassBlock = _.get(SC.campaign.campaignTemplateData, 'components.theme.component_items[0].item_attributes.styles.css.locked', false);
      }
    }],

    link: function link() {}
  };
}

angular.module('classy').directive('scGlobalCssBlockSettings', scGlobalCssBlockSettings);
})();