(function(){
'use strict';

angular.module('classy').component('panelSectionSeal', {
  template: '\n      <span class="panel__section-seal">\n        <i data-ng-class="::$ctrl.className"></i>\n      </span>\n    ',
  bindings: {
    icon: '<'
  },
  transclude: true,
  controller: function controller() {
    this.$onInit = function () {
      this.className = this.icon + ' panel__section-seal-icon';
    };
  }
});
})();