(function(){
'use strict';

/**
 * @name scFlowModalElements
 * @kind value
 * @package FlowModal
 *
 * @guide ./FlowModal.guide.md
 *
 * @description
 * A service that stores element references for use by scFlowModalService.
 */
angular.module('classy').value('scFlowModalElements', {
  /**
   * @name $viewport
   * @parent scFlowModalElements
   * @kind property
   * @type jQuery
   *
   * @description
   * The viewport element, which defines the size of the current modal "view"
   * and is animated to match the size of incoming views.
   */
  $viewport: null,

  /**
   * @name $views
   * @parent scFlowModalElements
   * @kind property
   * @type jQuery
   *
   * @description
   * The views element, which is a child of and fits snugly inside the
   * viewport element and hides all overflow.
   */
  $views: null,

  /**
   * @name $close
   * @parent scFlowModalElements
   * @kind property
   * @type jQuery
   *
   * @description
   * The modal close icon, which is a sibling of the views element but sits
   * outside the dimensions of the viewport.
   */
  $close: null,

  /**
   * @name $staging
   * @parent scFlowModalElements
   * @kind property
   * @type jQuery
   *
   * @description
   * The staging div is the same dimensions as the current window and sits
   * just below it. It is used to size new views and determine whether or
   * not they need to be scrollable.
   */
  $staging: null,

  /**
   * @name $overlay
   * @parent scFlowModalElements
   * @kind property
   * @type jQuery
   *
   * @description
   * The overlay element. The modal itself is a child of the overlay.
   */
  $overlay: null
});
})();