(function(){
'use strict';

/**
 * @name scRetainFocusValidity
 * @kind directive
 * @requires ngModel
 *
 * @description
 * Adds a $validOnFocus flag to an ngModelController which is true if the
 * control _is currently focused and was valid at the time it was focused._
 * (The flag is false if the control was invalid when focused, and null if the
 * control is not currently focused.)
 *
 * This is helpful because it allows us to show error messages based on the
 * user's intent. If a user returns to a valid field to edit it, chances are
 * they understand the validation requirements of the field and are simply
 * revising their answer, in which case flickering error messages may be
 * frustrating. Checking if the field is $validOnFocus allows us to hide
 * messages in this case and show them (if necessary) on blur.
 *
 * On the other hand, if a user returns to a field they had previously left in
 * an invalid state, there is a better chance they are confused about the
 * requirements and more assertive (keystroke-by-keystroke) error messages
 * would be more helpful.
 */

scRetainFocusValidity.$inject = ["$timeout"];
function scRetainFocusValidity($timeout) {
  'use strict';

  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$focus = function () {
        $timeout(function () {
          ngModelCtrl.$validOnFocus = ngModelCtrl.$valid || ngModelCtrl.$pristine || ngModelCtrl.$untouched;
        });
      };

      ngModelCtrl.$blur = function () {
        $timeout(function () {
          ngModelCtrl.$validOnFocus = null;
        });
      };

      element.on('focus', ngModelCtrl.$focus);
      element.on('blur', ngModelCtrl.$blur);
    }
  };
}

angular.module('classy').directive('scRetainFocusValidity', scRetainFocusValidity);
})();