(function(){
'use strict';

angular.module('classy').component('phoneField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <div ng-if="$ctrl.hasToolTip" style="height: 50%;"\n      data-sc-tooltip-class="admin-campaign-tooltip"\n      data-sc-tooltip-position="center"\n      data-sc-tooltip-position-tip="bottom center"\n      show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n      data-sc-tooltip="\'This field is locked at the template level\'"\n      class="admin-body__tooltip-element"\n      class="admin-body__tooltip-element locked-field-overlay"\n      ></div>\n\n      <input class="form__text-input--dark"\n      aria-label="{{:: $ctrl.label }}"\n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      ng-disabled="$ctrl.disabled"\n      ng-required="$ctrl.required"\n      sc-validate-phone\n      sc-validity-reporter\n      sc-validity-classes\n      type="text"\n      name="{{:: $ctrl.name }}"\n      placeholder="{{::$ctrl.placeholder}}"\n      qa-id="{{:: scQaId.singleLineTextInput }}"\n      _spec="input" />\n\n      <form-error\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    ',
  bindings: {
    model: '=',
    errorMessages: '<?',
    getterSetter: '<?',
    label: '<?',
    name: '<?',
    placeholder: '<?',
    required: '<?'
  },
  controller: ["scQaId", "$attrs", function PhoneFieldCtrl(scQaId, $attrs) {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.',
        phoneNumber: 'Please enter a phone number with area code.'
      },
      getterSetter: false,
      label: 'Phone',
      name: 'phone',
      placeholder: 'Phone',
      required: false
    };

    this.scQaId = scQaId;

    if (SC.campaign && SC.campaign.campaignTemplateData) {
      if (SC.campaign.campaignTemplateData && $attrs.model === 'campaign.current.contact_phone') {
        var isCampaignContactPhoneLocked = _.get(SC.campaign.campaignTemplateData, 'components.campaign.component_items[0].item_attributes.contact_phone.locked', false);
        if (isCampaignContactPhoneLocked) {
          this.disabled = true;
          this.hasToolTip = true;
        }
      }
    }

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);
    };
  }]
});
})();