(function(){
'use strict';

angular.module('classy').component('genderField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <select-field\n        name="$ctrl.name"\n        aria-label="gender select"\n        class-prop="form__select--light"\n        options="$ctrl.genderOptions"\n        model="$ctrl.model"\n        placeholder="\'Select a gender\'"\n        getter-setter="$ctrl.getterSetter"\n        required="$ctrl.required"\n      ></select-field>\n\n      <!-- hidden input for final model -->\n      <input class="form__dropdown--hidden"\n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      sc-validity-reporter\n      ng-required="$ctrl.required"\n      type="text"\n      name="{{:: $ctrl.name }}">\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    placeholder: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    options: '<?'
  },
  controller: function GenderFieldCtrl() {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      required: false,
      options: []
    };

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);

      _this.genderOptions = _this.options.map(function (option) {
        return {
          label: option,
          value: option
        };
      });
    };
  }
});
})();