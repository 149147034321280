(function(){
'use strict';

/**
 * @kind factory
 * @name scInlineStyles
 *
 * @description
 * Generates inline styles
 */

function scInlineStyles() {
  return {
    /*
     * Background: image, position
     * @param  {object} image from scImg toJSON
     * @param  {object} styleObj
     * @param  {object} demo
     * @return {object}       for ngStyle
     */
    background: function background(options) {
      if (options.image) {
        var styles = {
          'background-image': 'url(' + options.image.src + ')',
          'background-position': 'center top'
        };

        switch (options.image.options.style) {
          case 'tile':
            styles['background-repeat'] = 'repeat';
            break;

          case 'center':
            styles['background-repeat'] = 'no-repeat';
            break;

          case 'stretch':
            styles['background-repeat'] = 'no-repeat';
            styles['background-size'] = 'cover';
            break;

          case 'coverfixed':
            styles['background-size'] = 'cover';
            if (!options.demo) {
              styles['background-attachment'] = 'fixed';
            }
            break;

          default:
          // do nothing
        }

        styles = _.merge(styles, options.styleObj);

        return styles;
      }

      return undefined;
    }
  };
}

angular.module('classy').factory('scInlineStyles', scInlineStyles);
})();