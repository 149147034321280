(function(){
'use strict';

/**
 * @kind directive
 * @name scTokenEx
 * @requires ngModel
 *
 * @description
 * Renders a credit card number input field using a TokenEx iframe.
 */

scTokenEx.$inject = ["$http", "$log"];
function scTokenEx($http, $log) {
  'use strict';

  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element) {
      $http({
        method: 'GET',
        url: '/frs-api/checkout/token-ex'
      }).then(function (response) {
        element.html(response.data);
      }).catch(function () {
        $log.error('Failed to load TokenEx iframe.');
      });
    }
  };
}

angular.module('classy').directive('scTokenEx', scTokenEx);
})();