(function(){
'use strict';

function scBackgroundImageSpin() {
  'use strict';

  return {
    scope: {
      customSpinnerOpts: '=?',
      page: '='
    },
    link: function link(scope, element) {
      // regex check to see if the image contains null
      var hasNull = /null/;
      scope.$watch('page.bgImg.src', function (newValue) {
        if (hasNull.test(newValue)) {
          displaySpinner(true);
        } else {
          displaySpinner(false);
        }
      });

      // default spinner settings
      var spinjsOpts = {
        lines: 12, // The number of lines to draw
        length: 14, // The length of each line
        width: 7, // The line thickness
        radius: 20, // The radius of the inner circle
        scale: 1, // Scales overall size of the spinner
        corners: 1, // Corner roundness (0..1)
        color: '#000', // #rgb or #rrggbb or array of colors
        opacity: 0.25, // Opacity of the lines
        rotate: 0, // The rotation offset
        direction: 1, // 1: clockwise, -1: counterclockwise
        speed: 1, // Rounds per second
        trail: 60, // Afterglow percentage
        fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        className: 'spinner-two', // The CSS class to assign to the spinner
        top: '50%', // Top position relative to parent
        left: '50%', // Left position relative to parent
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        position: 'absolute' // Element positioning
      };

      // extend spinjs settings with custom spinner options if defined
      var elementId = element[0].getAttribute('id'),
          customSpinnerOpts = scope.customSpinnerOpts,
          opts = scope.customSpinnerOpts ? _.extend(spinjsOpts, customSpinnerOpts) : spinjsOpts,
          target = document.getElementById(elementId),
          spinner = new Spinner(opts).spin(target);

      function displaySpinner() {
        var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (show) {
          spinner.spin(target);
        } else {
          $('.spinner-two').fadeOut(function () {
            spinner.stop();
          });
        }
      }
    }
  };
}

angular.module('classy').directive('scBackgroundImageSpin', scBackgroundImageSpin);
})();