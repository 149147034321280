(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingColors', ["$log", "$filter", function ($log, $filter) {
  var _this = this;

  var defaultColor = 'rgba(0,0,0,1.0)'; // default to black
  var opacity = '.12';
  this.theme = null;
  this.primary = null;
  this.accent = null;
  this.accentOpaque = null;

  this.init = function (theme) {
    if (!theme) {
      $log.warn('[EmbeddedGivingColors:init] no theme present');
      return;
    }

    this.theme = theme;
    this.primary = this.getPrimary();
    this.accent = this.getAccent();
    this.accentOpaque = this.getAccentOpaque();
  };

  this.getPrimary = function (theme) {
    var t = theme || _this.theme;
    if (!t) {
      $log.warn('[EmbeddedGivingColors:getPrimary] no theme present');
      return defaultColor;
    }
    var color = _.get(_this.theme, 'current.styles.primaryColor');
    if (!color) {
      color = defaultColor;
    }

    return color;
  };

  /* Primary color can be in 2 diff formats: 'rgba' or 'rgb' (string)
   * Background color is calculated by changing opacity of primary color to .12 */
  this.getAccent = function (theme) {
    var t = theme || _this.theme;
    if (!t) {
      $log.warn('[EmbeddedGivingColors:getAccent] no theme present');
      return defaultColor;
    }

    var primaryColor = _this.getPrimary(t);
    var accentColor = 'rgba(0,0,0,' + opacity + ')';

    if (primaryColor) {
      // if format is rgba, then 4 params present, need to replace last ('a')
      if (primaryColor.indexOf('rgba') !== -1) {
        var colorTokens = primaryColor.split(',');
        colorTokens[colorTokens.length - 1] = opacity + ')';
        accentColor = colorTokens.join(',');
      } else if (primaryColor.indexOf('rgb') !== -1) {
        // else check if rgb is present.
        // if is but no 4th param, then we add 4th param (instead of replace)
        var _colorTokens = primaryColor.split(')');
        _colorTokens[_colorTokens.length - 1] = opacity + ')';
        accentColor = _colorTokens.join(',');
      }
    }

    return accentColor;
  };

  this.getAccentOpaque = function (theme) {
    var t = theme || _this.theme;
    if (!t) {
      $log.warn('[EmbeddedGivingColors:getAccentOpaque] no theme present');
      return defaultColor;
    }

    var primaryColor = _this.getPrimary(t);
    if (!primaryColor.match) {
      return primaryColor;
    }

    // TODO: shocking, but this adjustColorOpacity thing has a bug. it adds a random single quote
    // just fixed here for EG, but should look elsewhere eventually
    return $filter('adjustColorOpacity')(primaryColor, opacity).split("'").join('');
  };
}]);
})();