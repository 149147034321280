(function(){
'use strict';

angular.module('classy').constant('scTeamBlockDefaults', {
  title: 'Team',
  type: 'team',
  textColor: 'rgb(44,52,59)',
  defaultTeamLogo: null,
  teamsCanSetCoverPhoto: true,

  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  thumbnail: {
    preset: 'circle',
    'border-radius': '50px'
  },

  /* Donate Button */
  donateButtonColor: null,
  donateButtonStyle: {
    preset: 'rectangular',
    'border-radius': '4px',
    color: 'white'
  },

  /* Join Button */
  joinButtonColor: null,
  joinButtonStyle: {
    preset: 'rectangular',
    'border-radius': '4px'
  },

  /* Sidebar */
  showProgressBar: true,
  showTeamRank: true,
  allowCommenting: true,
  showLeaderboard: true,
  showTeamCaptain: true,
  showTeamMembers: true,
  showHeadline: true,
  showCampaign: true,
  showSubTeams: true,

  unsortable: false
});
})();