(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingPayDAL', ["$http", "$q", "$window", "cpAPI", "cpConstants", "PaymentMethod", "PaymentProcessor", function ($http, $q, $window, cpAPI, cpConstants, PaymentMethod, PaymentProcessor) {
  var _this = this;

  var self = this;
  this.paymentMethods = {};
  this.paymentMethodsArr = [];
  this.paymentProcessors = {};
  this.paymentProcessorsArr = [];

  // TODO diff between processors and payment methods?
  // TODO disabled processors?

  function setPaymentMethods() {
    var methods = {};
    var methodsArr = [];

    _.forEach(cpConstants.PAYMENT_METHODS, function (value, key) {
      var newMethod = new PaymentMethod(_.extend({}, { key: key, name: value }));
      methods[key] = newMethod;
      methodsArr.push(newMethod);
    });

    self.paymentMethods = methods;
    self.paymentMethodsArr = methodsArr;
    return self.paymentMethods;
  }

  function setPaymentProcessors(rawProcessors) {
    var processors = {};
    var processorsArr = [];

    _.forEach(rawProcessors, function (value, key) {
      var newProcessor = new PaymentProcessor(_.extend({}, value, { key: key }));
      processors[key] = newProcessor;
      processorsArr.push(newProcessor);
    });

    self.paymentProcessors = processors;
    self.paymentProcessorsArr = processorsArr;
    return self.paymentProcessors;
  }

  this.getPaymentMethods = function () {
    return _this.paymentMethods;
  };
  this.getPaymentMethodByKey = function (key) {
    return _this.paymentMethods && _this.paymentMethods[key];
  };

  this.getPaymentProcessors = function () {
    if (_this.paymentProcessors) {
      return $q.when(_this.paymentProcessors);
    } else {
      return _this.fetchPaymentProcessors();
    }
  };

  // 'fetch' implies returning a promise
  this.fetchPaymentProcessors = function () {
    var path = '/frs-api/pay/processors?appId=' + cpAPI.getApplicationId();

    return $http.get(path).then(function (response) {
      var rawProcessors = response.data || {};
      return setPaymentProcessors(rawProcessors);
    });
  };

  // init block
  (function () {
    setPaymentProcessors($window.SC.processors);
    setPaymentMethods();
  })();
}]);
})();