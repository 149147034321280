(function(){
'use strict';

scOrganizationsTemplateService.$inject = ["$http"];
function scOrganizationsTemplateService($http) {
  return {
    get: function get(organizationId, query) {
      return $http({
        method: 'GET',
        url: '/frs-api/organizations/' + organizationId + '/campaign-templates?filter=source_campaign_id=' + query.source_campaign_id,
        params: query,
        loadingScope: true
      });
    }
  };
}

angular.module('classy').service('scOrganizationsTemplateService', scOrganizationsTemplateService);
})();