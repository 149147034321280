(function(){
'use strict';

scValidityReporterProtoFactory.$inject = ["$rootScope"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function scValidityReporterProtoFactory($rootScope) {
  return function () {
    function scValidityReporterProto(alias, type) {
      _classCallCheck(this, scValidityReporterProto);

      this.alias = alias;
      this.type = type;
      this.parent = null;
      this.children = [];
      this.valid = true;
      this.invalid = false;
      this.error = {};
      this.pending = {};
      this.uiValid = true;
      this.uiInvalid = false;
      this.uiError = {};
      this.uiPending = {};
      this.callbacks = {};
    }

    _createClass(scValidityReporterProto, [{
      key: 'addChild',
      value: function addChild(child) {
        this.children.push(child);
        this.emit('child', child);
      }
    }, {
      key: 'setParent',
      value: function setParent(parent) {
        this.parent = parent;
        this.emit('parent', parent);
      }
    }, {
      key: 'removeChild',
      value: function removeChild(child) {
        _.remove(this.children, function (registered) {
          return registered === child;
        });
      }
    }, {
      key: 'on',
      value: function on(event, cb) {
        if (!this.callbacks[event]) {
          this.callbacks[event] = [];
        }
        this.callbacks[event].push(cb);
      }
    }, {
      key: 'emit',
      value: function emit(event, data) {
        var _this = this;

        _.forEach(this.callbacks[event], function (cb) {
          return cb(_this.alias, data, _this);
        });
        $rootScope.$broadcast('scValidity.' + event, this.alias, data, this);
      }
    }, {
      key: 'emitUpdate',
      value: function emitUpdate() {
        this.emit('update', {
          type: this.type,
          valid: this.valid,
          invalid: this.invalid,
          error: this.error,
          pending: this.pending,
          uiValid: this.uiValid,
          uiInvalid: this.uiInvalid,
          uiError: this.uiError,
          uiPending: this.uiPending
        });
      }
    }]);

    return scValidityReporterProto;
  }();
}

angular.module('classy').factory('scValidityReporterProto', scValidityReporterProtoFactory);
})();