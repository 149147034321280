(function(){
'use strict';

scCrowdfundingCampaignDefaultBlocks.$inject = ["scBlockModel"];
angular.module('classy').factory('scCrowdfundingCampaignDefaultBlocks', scCrowdfundingCampaignDefaultBlocks);

function scCrowdfundingCampaignDefaultBlocks(scBlockModel) {
  return {
    header: new scBlockModel({ type: 'header' }),
    crowdfunding: new scBlockModel({
      type: 'crowdfunding',
      donateButtonText: 'Donate Now'
    }),
    about: new scBlockModel({ type: 'about' }),
    donation: new scBlockModel({
      type: 'donation',
      background: {
        preset: 'coverfixed'
      }
    }),
    thankYou: new scBlockModel({
      type: 'thank-you',
      background: {
        preset: 'coverfixed'
      }
    }),
    impact: new scBlockModel({ type: 'impact' }),
    activity: new scBlockModel({ type: 'activity' }),
    footer: new scBlockModel({ type: 'footer' }),

    optional: {
      header: 1,
      impact: 1,
      text: -1,
      images: 0,
      footer: 2
    }
  };
}
})();