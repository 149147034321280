(function(){
'use strict';

angular.module('classy').provider('intlTelInput', function () {
  this.$get = ['$q', '$window', function ($q, $window) {
    var apiLoaded = $q.defer();

    var loadScript = function loadScript(url, _type, callback) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = callback;
      document.body.appendChild(script);
    };

    var loadStyles = function loadStyles(url) {
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      document.head.appendChild(link);
    };

    // Load the required scripts and CSS
    loadStyles('https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/css/intlTelInput.css');
    loadScript('https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/intlTelInput.min.js', 'text/javascript', function () {
      apiLoaded.resolve($window.intlTelInput);
    });

    return apiLoaded.promise;
  }];
});
})();