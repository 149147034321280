(function(){
'use strict';

function scFallbackSrc() {
  // good candidate for removal
  return {
    scope: {
      scFallbackSrc: '@'
    },
    link: function link(scope, element, attrs) {
      if (!attrs.ngSrc || typeof attrs.ngSrc === 'undefined') {
        element.attr('src', scope.scFallbackSrc);
      }
    }
  };
}

angular.module('classy').directive('scFallbackSrc', scFallbackSrc);
})();