(function(){
'use strict';

/**
 * @name scCustomFields
 * @kind directive
 * @requires SC.customQ, datepicker-popup
 *
 * @description
 * Renders a custom question along with label and error messaging.
 *
 * Custom donation field types:
 *
 * 1. Input field ("string")
 * 2. Text area ("text")
 * 3. Datepicker ("date")
 * 4. Yes/No radios ("yes_no")
 * 5. Number field ("integer")
 * 6. Dollar field ("currency")
 * 7. Select ("enum")
 * 8. Waiver ("boolean")
 *
 * Used in:
 * application/views/scripts/checkout/donation.phtml
 * application/views/scripts/npo/offline-registration.phtml
 * application/views/scripts/reports/edit-donation.phtml
 * application/views/scripts/reports/fundraising-page.phtml
 * public/scripts/admin/reports/edit-donation.js
 *
 * @param {expression} scCustomFields The object containing information about
 *        this question.
 * @param {expression} modelPrefix Prepended to the question's ID to create its
 *        scope property.
 * @param {expression} modelSuffix Appended to the question's ID to create its
 *        scope property.
 * @param {expression} formName The name of the form this question is a part of.
 * @param {expression} nameSuffix A suffix to add to the form name, which
 *        always consists of 'cq_' + question.id + nameSuffix.
 */

scCustomFields.$inject = ["$parse", "$compile", "$filter", "scQaId", "scOrganizationsService"];
function scCustomFields($parse, $compile, $filter, scQaId, scOrganizationsService) {
  'use strict';

  return {
    replace: true,

    link: function link(scope, element, attrs) {
      var labelClass = attrs.labelClass ? $parse(attrs.labelClass)(scope) : 'donation';

      var question = $parse(attrs.scCustomFields)(scope),
          $label = angular.element('<label class="' + labelClass + '_form-field-label"></label>'),
          modelPrefix = $parse(attrs.modelPrefix)(scope) || '',
          modelSuffix = $parse(attrs.modelSuffix)(scope) || '',
          formName = $parse(attrs.formName)(scope),
          nameSuffix = $parse(attrs.nameSuffix)(scope) || '';

      if (question.tag == 'text_opt_in' && !scOrganizationsService.active.current.mgive_settings) {
        return;
      }

      var $field = void 0,
          $field2 = void 0;

      // Open date
      scope.openFlags = [];
      scope.openDate = function ($event, index) {
        $event.preventDefault();
        scope.openFlags[index] = true;
      };

      // Label
      $label.addClass('sc-custom-question-label');

      if (question.type == 'boolean' && question.tag !== 'text_opt_in') {
        $label.text('Event Waiver');
        $label.addClass('waiver-label');
      } else {
        $label.text(question.label);
      }

      if (question.is_required) {
        $label.addClass('required');
      }

      var originalLabelText = $label.text();

      // Construct question
      switch (question.type) {
        case 'string':
          $field = angular.element('<input qa-id="' + scQaId.singleLineTextInput + '" />');
          $field.attr({
            type: 'text',
            class: 'form_text-input desktop-full',
            maxlength: '255'
          });
          break;

        case 'text':
          $field = angular.element('<textarea qa-id="' + scQaId.textAreaField + '"></textarea>');
          if (formName === 'signup_questions' || formName === 'create_fundraising_page_questions' || formName === 'create_team_questions') {
            $field.attr({
              'data-froala': 'froalaOptions'
            });
          }
          $field.attr({
            class: 'form_text-area desktop-full',
            maxlength: '255'
          });
          break;

        case 'date':
          $field = angular.element('<input qa-id="' + scQaId.dateField + '" />');

          if (question.tag == 'birthdate') {
            $field.attr({
              'data-sc-birthday': '' + modelPrefix + question.id + modelSuffix,
              name: 'cq_' + question.id + nameSuffix
            });
          } else {
            $field.attr({
              class: 'datepicker form_text-input desktop-third mobile-ls-half',
              'data-uib-datepicker-popup': 'MM/dd/yyyy',
              'data-is-open': 'visible',
              'data-ng-click': 'visible = true',
              type: 'text',
              'show-weeks': false,
              'ng-model': modelPrefix + question.id + modelSuffix,
              name: 'cq_' + question.id + nameSuffix,
              role: 'combobox',
              placeholder: 'Enter date in the format: MM/DD/YYYY'
            });
          }

          $label.text(originalLabelText + ' (MM/DD/YYYY)');

          break;

        case 'yes_no':
          $field = angular.element('<input />');
          $field.attr({
            type: 'radio',
            value: 'yes',
            id: 'radio-custom-' + question.id + '-y',
            class: 'form_radio-input'
          });
          $field2 = angular.element('<input />');
          $field2.attr({
            type: 'radio',
            value: 'no',
            id: 'radio-custom-' + question.id + '-n',
            class: 'form_radio-input',
            name: 'cq_' + question.id + nameSuffix,
            'ng-model': modelPrefix + question.id + modelSuffix
          });
          break;

        case 'integer':
          $field = angular.element('<input qa-id="' + scQaId.singleLineTextInput + '" />');
          $field.attr({
            type: 'number',
            value: $parse(modelPrefix + question.id + modelSuffix)(scope),
            class: 'form_text-input desktop-full',
            'sc-numeric': true
          });
          break;

        case 'currency':
          $field = angular.element('<input qa-id="' + scQaId.singleLineTextInput + '" />');
          $field.attr({
            type: 'number',
            value: $parse(modelPrefix + question.id + modelSuffix)(scope),
            class: 'dollar form_text-input desktop-half',
            'sc-dollar': true
          });
          break;

        case 'enum':
          $field = angular.element('<select qa-id="' + scQaId.selectField + '"></select>');
          $field.attr({
            class: 'form_select desktop-third mobile-ls-full'
          });
          $field.html('<option value="">Please Select</option>');

          question.enum_options.forEach(function (_ref) {
            var label = _ref.label;

            var $option = angular.element('<option></option>');
            $option.text(label).val(label);
            $field.append($option);
          });

          break;

        case 'boolean':
          $field = angular.element('\n          <input class="form_checkbox-input" aria-describedby="checkbox-customq-' + question.id + '__sublabel" />');
          $field.attr({
            type: 'checkbox',
            'ng-checked': modelPrefix + question.id + modelSuffix + '== 1',
            id: 'checkbox-customq-' + question.id,
            'ng-model': modelPrefix + question.id + modelSuffix
          });
          break;

        default:
        // do nothing
      }

      if (question.tag === 'state') {
        $field = angular.element('<div data-sc-select-states></div>');
        $field.attr({
          class: 'form_select desktop-third'
        });
      }

      //  swap only applies to organizations with country block active.
      var countryBlockRestriction = _.get(scOrganizationsService, 'active.current.restricted_country_information');

      if (question.tag === 'country' && countryBlockRestriction) {
        $field = angular.element('<div data-sc-select-countries></div>');
        $field.attr({
          class: 'form_select desktop-third'
        });
      }

      if (!$field.attr('id')) {
        $field.attr('id', 'custom_field_{{ $index }}');
        $label.attr('for', 'custom_field_{{ $index }}');
      }

      // Add shared attributes, required flag, error messaging

      $field.attr({
        name: 'cq_' + question.id + nameSuffix
        // 'ng-model': modelPrefix + question.id + modelSuffix
      });

      // Do not set ngModel for datepicker fields,
      // already set through datepicker attr
      if (question.type != 'date') {
        $field.attr({ 'ng-model': modelPrefix + question.id + modelSuffix });
      }

      var $error = angular.element('<div></div>');
      var $errorSpan = angular.element('<span></span>');

      $error.attr({
        'ng-show': 'showFormError(\'cq_' + question.id + nameSuffix + '\')',
        class: 'form_error',
        role: 'alert'
      });

      if (question.is_required) {
        // Required fields
        $field.attr('required', 'true');

        if (question.type == 'yes_no') {
          $field2.attr('required', 'true');
        }

        $errorSpan = angular.element('<span></span>');
        $errorSpan.attr({
          'ng-show': formName + '.cq_' + question.id + nameSuffix + '.$error.required',
          class: 'sc-custom-question-error-required'
        });
        if (question.type === 'currency') {
          $errorSpan.text('Please enter a dollar amount.');
        } else {
          $errorSpan.text('The field ' + question.label + ' is required.');
        }
        $error.append($errorSpan);
      }

      if (question.type == 'integer') {
        // Number
        $errorSpan = angular.element('<span></span>');
        $errorSpan.attr({
          'ng-show': formName + '.cq_' + question.id + nameSuffix + '.$error.numeric',
          class: 'sc-custom-question-error-numeric'
        });

        $errorSpan.text('Please enter numbers only.');
        $error.append($errorSpan);
      } else if (question.type == 'currency') {
        // Dollar
        $errorSpan = angular.element('<span></span>');
        $errorSpan.attr({
          'ng-show': formName + '.cq_' + question.id + nameSuffix + '.$error.dollar',
          class: 'sc-custom-question-error-dollar'
        });

        $errorSpan.text('Please enter a valid dollar amount.');
        $error.append($errorSpan);
      }

      element.after($compile($error)(scope));

      // Append to DOM
      if (question.tag !== 'text_opt_in') {
        element.append($label);
      }

      $label.wrap('<div class="' + labelClass + '_form-table sc-custom-question-table"></div>');

      if (question.type == 'boolean' && question.tag !== 'text_opt_in') {
        var $waiver = angular.element('<span></span>');
        element.append($waiver);
        $waiver.attr({
          class: 'waiver desktop-full'
        }).html($filter('nl2br')(question.label));
      }

      element.append($field);

      if (question.type == 'date') {
        // Date
        $field.wrap('<span class="dateField"></span>');
        $errorSpan.attr({
          'ng-show': formName + '.cq_' + question.id + nameSuffix + '.$error.date',
          class: 'sc-custom-question-error-date'
        });

        $errorSpan.text('Please enter a valid date.');
        $error.append($errorSpan);
      } else if (question.type == 'yes_no') {
        // Yes or No
        $field.wrap('<div role="radiogroup" aria-labelledby="custom_label_{{ $index }}" class="' + labelClass + '_form-field-thin"></div>');
        $label.attr('id', 'custom_label_{{ $index }}');
        $field.after($field2);
        $field.after('<label for="radio-custom-' + question.id + '-y" class="form_radio-label" qa-id="' + scQaId.radioField + '"> Yes</label>');
        $field2.after('<label for="radio-custom-' + question.id + '-n" class="form_radio-label" qa-id="' + scQaId.radioField + '"> No</label>');
      } else if (question.type == 'currency') {
        // Dollar
        $field.wrap('<div class="dollarAmtField"></div>');
      } else if (question.tag == 'text_opt_in') {
        // mGive
        $field.wrap('<div class="form_field-input desktop-full waiver-checkbox"></div>').after('\n          <label\n            for="checkbox-customq-' + question.id + '"\n            class="form_checkbox-label"\n            qa-id="' + scQaId.checkboxField + '">\n            Receive text-message updates and info on your cell phone\n          </label>\n          <div id="checkbox-customq-' + question.id + '__sublabel" class="form__sublabel cq__checkbox-sublabel">' + scOrganizationsService.active.current.mgive_settings.messaging + '</div>');
      } else if (question.type == 'boolean') {
        // Waiver
        $field.wrap('<div class="form_field-input desktop-full waiver-checkbox"></div>').after('\n          <label\n            for="checkbox-customq-' + question.id + '"\n            class="form_checkbox-label"\n            qa-id="' + scQaId.checkboxField + '">\n            I Accept the Waiver Agreement\n          </label>');
      } else if (question.tag === 'country' && countryBlockRestriction) {
        //  Country Block Dropdown
        $field.after('<div class="form__helper">\n            ' + countryBlockRestriction.drop_down_text + '\n          </div>');
      }

      $compile(element.contents())(scope);
    }
  };
}

angular.module('classy').directive('scCustomFields', scCustomFields);
})();