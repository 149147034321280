(function(){
'use strict';

/**
 * @kind service
 * @name scSocialShareLinks
 *
 * @description
 * Generates social share links for campaign
 */

scSocialShareLinks.$inject = ["$rootScope", "$q", "scCampaignsService", "scUserAgent", "scMetaTags", "scPagesService", "scOrganizationsService", "scFacebook", "scMembersService", "CHANNEL"];
function scSocialShareLinks($rootScope, $q, scCampaignsService, scUserAgent, scMetaTags, scPagesService, scOrganizationsService, scFacebook, scMembersService, CHANNEL) {
  var _this = this;

  var $scope = $rootScope.$new();
  $scope.links = {};

  var campaign = scCampaignsService.active.current,
      campaignName = campaign.name,
      MAX_SMS_LENGTH = 160;

  var isEmbeddedGiving = function isEmbeddedGiving() {
    if (typeof scCampaignsService.active.hasEmbeddedGiving() === 'function') {
      return scCampaignsService.active.hasEmbeddedGiving();
    }
    return false;
  };

  /*
   * Check user agent for correct SMS syntax
   */
  var smsSyntax = '?';
  var doubleEncode = true;
  var ua = scUserAgent.getUa();
  if (ua.os.name == 'iOS') {
    if (parseFloat(ua.os.version) >= 8) {
      smsSyntax = '&';
    } else {
      smsSyntax = ';';
    }
  }

  if (ua.os.name == 'iOS' && parseFloat(ua.os.version) >= 10 || ua.os.name == 'Android' && parseFloat(ua.os.version) >= 6) {
    doubleEncode = false;
  }

  this.generateText = function () {
    var pageUrl = scPagesService.getShareableUrl(scPagesService.currentPageType()),
        type = scPagesService.currentPageType(),
        supporterType = type === 'team' || type === 'fundraiser';
    var pageName = supporterType ? scPagesService.getPageOwner() : campaignName;
    var filteredName = doubleEncode ? pageName : pageName.replace('&', 'and');
    var orgName = scOrganizationsService.active.current.name;

    var pageTypes = {
      supporter: {
        getDefaultAppeal: function getDefaultAppeal() {
          var you = scMembersService.you;
          var supporterPageType = type === 'fundraiser' ? 'fundraising' : 'team fundraising';

          var nameEndsWithS = filteredName.toLowerCase().slice(-1) === 's';
          var possessiveChar = nameEndsWithS ? "'" : "'s";

          var appeal = you.current.id ? 'Check out my ' + supporterPageType + ' page' : 'Check out ' + filteredName + possessiveChar + ' ' + supporterPageType + ' page';
          return appeal;
        },
        twitterText: function twitterText() {
          return pageTypes.supporter.getDefaultAppeal() + ' - ' + pageUrl;
        },
        smsMessage: function smsMessage() {
          var message = pageTypes.supporter.getDefaultAppeal() + '\n' + pageUrl;
          if (message.length > MAX_SMS_LENGTH) {
            message = filteredName + '\n' + pageUrl;
          }
          return message;
        },
        emailSubject: function emailSubject() {
          return 'Help fundraise for ' + orgName;
        },
        emailBody: function emailBody() {
          return pageTypes.supporter.getDefaultAppeal() + ' for ' + orgName + ' - ' + pageUrl;
        },
        fbMessage: function fbMessage() {
          return pageTypes.supporter.getDefaultAppeal() + ' for ' + orgName;
        }
      },

      campaign: {
        getDefaultAppeal: function getDefaultAppeal() {
          var campaignContent = scMetaTags.getDescription();
          return campaignContent;
        },
        twitterText: function twitterText() {
          var shortenedUrl = document.location.origin + '/campaign/c' + campaign.id;
          if (isEmbeddedGiving) {
            shortenedUrl = pageUrl;
          }
          var tweet = filteredName + ' ' + shortenedUrl;
          return tweet;
        },
        smsMessage: function smsMessage() {
          var message = filteredName + '\n' + pageUrl;
          if (message.length > MAX_SMS_LENGTH) {
            var shortenedUrl = document.location.origin + '/campaign/c' + campaign.id;
            message = filteredName + '\n' + shortenedUrl;
          }
          return message;
        },
        emailSubject: function emailSubject() {
          return pageName;
        },
        emailBody: function emailBody() {
          return pageTypes.campaign.getDefaultAppeal() + ' ' + pageUrl;
        },
        fbMessage: function fbMessage() {
          var campaignDescription = pageTypes.campaign.getDefaultAppeal();
          var campaignContent = campaignDescription.length > 300 ? campaignDescription.substring(0, 297) + '...' : campaignDescription;
          return campaignContent || null;
        }
      }
    };

    var currentPage = supporterType ? 'supporter' : 'campaign';
    var socialData = pageTypes[currentPage];

    var socialText = {
      twitter_text: socialData.twitterText(),
      email_subject: socialData.emailSubject(),
      email_body: socialData.emailBody(),
      sms_text: socialData.smsMessage(),
      facebook_text: socialData.fbMessage()
    };

    return socialText;
  };

  var socialLinks = void 0;

  function setSocialLinkData(data) {
    $scope.links = data;
    socialLinks = data;
  }

  function getSocialLinkData() {
    return socialLinks;
  }

  /* ------------------------------------------------------------------ *
   * Get Social Share Data
   * ------------------------------------------------------------------ */

  var previousUrl = scPagesService.getShareableUrl();

  var getTwitterLink = function getTwitterLink(tweet) {
    var t = encodeURIComponent(tweet);
    return 'https://twitter.com/intent/tweet?&text=' + t;
  };

  var getEmailLink = function getEmailLink(subject, body) {
    var s = encodeURIComponent(subject),
        b = encodeURIComponent(body);
    return 'mailto:?body=' + b + '&subject=' + s;
  };

  var getSmsLink = function getSmsLink(message) {
    // double encoding is only useful for iOS < 10
    var m = doubleEncode ? encodeURIComponent(encodeURIComponent(message)) : encodeURIComponent(message);
    return 'sms:' + smsSyntax + 'body=' + m;
  };

  var createSocialLinks = function createSocialLinks(data) {
    var defaultAppealData = _this.generateText();
    var pageType = scPagesService.currentPageType();

    // _.defaults counts null properties as having a value so we're picking
    // only the properties that have a value
    var cleanedDefaultData = _.pickBy(defaultAppealData, _.identity);
    var cleanedSavedData = _.pickBy(data, _.identity);

    // If on campaign page we use the appeal data from API first, then fill
    // in gaps with default data we generate with generateText(). If on a
    // fundraiser or team page we default to the default fundraiser text first.
    var socialText = pageType == 'fundraiser' || pageType == 'team' ? _.defaults(cleanedDefaultData, cleanedSavedData) : _.defaults(cleanedSavedData, cleanedDefaultData);

    $scope.links = {
      twitter_link: getTwitterLink(socialText.twitter_text),
      sms_link: getSmsLink(socialText.sms_text),
      email_link: getEmailLink(socialText.email_subject, socialText.email_body),
      facebook_share: function facebook_share() {
        var message = socialText.facebook_text;
        var pageUrl = scPagesService.getShareableUrl();
        scFacebook.share(pageUrl, message);
      }
    };

    setSocialLinkData($scope.links);

    return $scope.links;
  };

  this.getInstagramLink = function () {
    if (scCampaignsService && scCampaignsService.active && scCampaignsService.active.getCampaignChannel) {
      return scCampaignsService.active.getCampaignChannel(CHANNEL.FRAPI).then(function (channelData) {
        var currentMetaSharingStatus = _.get(channelData, '[0].status', false);
        var externalFundraiserId = _.get(channelData, '[0].metadata.external_fundraiser_id');
        $scope.links.instagram_link = null;

        if (currentMetaSharingStatus && externalFundraiserId) {
          $scope.links.instagram_link = 'https://www.instagram.com/linking/fundraiser?fundraiser_id=' + externalFundraiserId + '&source_name=SHARE_LINK_FROM_EXTERNAL_FUNDRAISER';
        }

        return $scope.links;
      }, function (_err) {
        return $scope.links;
      });
    }

    return $scope.links;
  };

  this.getSocialData = function () {
    // If you already fetched social links before on the current page send those
    if (getSocialLinkData() && previousUrl == scPagesService.getShareableUrl()) {
      return $q.resolve(getSocialLinkData());
    }

    var appealSet = scMetaTags.getAppealSetData();
    if (!_.isEmpty(appealSet)) {
      return $q.resolve(createSocialLinks(appealSet));
    }

    // Fail safe in case the init() on page load failed
    return scMetaTags.init().then(function (response) {
      return createSocialLinks(response);
    });
  };
}

angular.module('classy').service('scSocialShareLinks', scSocialShareLinks);
})();