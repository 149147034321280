(function(){
'use strict';

/* Campaign Whitelisting

 * @params
 * - [required] campaign {}; 2-way binding
 * - width ""; e.g. 50%, 100px (defaults to 100%)

 * Questions:
 * - Should we have advice where error message is or underneath? e.g. remind them they have unsaved changes
 */
angular.module('classy').component('egCampaignWhitelisting', {
  templateUrl: 'global/embedded-giving/components/campaign-whitelisting/eg.components.campaign-whitelisting', // same folder as this
  bindings: {
    campaign: '=',
    width: '@'
  },
  controller: ["$log", function controller($log) {
    var _this = this;

    var self = this;
    this.draftUrl = '';
    this.urls = [];
    this.activeError = null;
    this.errors = {
      // "1" is valid
      2: {
        message: 'Please enter a URL to embed this campaign'
      },
      3: {
        message: 'Please make sure you enter a valid URL with https'
      },
      4: {
        message: 'Please ensure the website URL begins with "https://" for a secure connection'
      }
    };

    this.$onInit = function () {
      if (!_this.campaign) {
        $log.error('[egCampaignWhtelisting]: campaign model is required');
      }

      __syncUrls();
      // Optional style configurations
      _this.width = _this.width || '100%';
    };

    /* [START] Private Functions */
    function __syncUrls() {
      var currentCampaign = self.campaign.current;
      self.urls = currentCampaign.whitelist_url ? currentCampaign.whitelist_url.split(' ') : [];
    }

    function __isValidUrl(str) {
      var url = void 0;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url;
    }

    function __isValidProtocol(str) {
      var url = void 0;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url && url.protocol === 'https:';
    }

    function __isSubdomain(input) {
      var url = input;
      // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
      url = url.replace(new RegExp(/^\s+/), ''); // START
      url = url.replace(new RegExp(/\s+$/), ''); // END

      // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
      url = url.replace(new RegExp(/\\/g), '/');

      // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
      url = url.replace(new RegExp(/^http:\/\/|^https:\/\/|^ftp:\/\//i), '');

      // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
      url = url.replace(new RegExp(/\/(.*)/), '');

      // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
      if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
        url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), '');

        // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
      } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
        url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), '');
      }

      // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
      var subDomain = !!url.match(new RegExp(/\./g));

      return subDomain;
    }

    function __getBaseDomain(urlStr) {
      var url = new URL(urlStr);

      return __isSubdomain(urlStr) ? url.hostname.split('.').splice(1).join('.') : url.hostname;
    }

    /* [START] Public Functions */
    /*
     * Returns INT:
     *  1  VALID URL
     *  2  Url string length is less than 2
     *  3  Not valid url
     *  4  Not valid protocol (https currently)
     */
    this.isValidUrl = function (url) {
      var candidate = url || _this.draftUrl;
      var errorKey = 1; // default to valid

      if (!candidate || !candidate.length) {
        errorKey = 2;
        // I commented this line out because I hate showing an error when the input field is blank
        // this.setActiveErrorDebounce(this.errors[errorKey]);
        _this.activeError = null; // in fact, clear the error if blank
      } else if (!__isValidUrl(candidate)) {
        errorKey = 3;
        _this.setActiveErrorDebounce(_this.errors[errorKey]);
      } else if (!__isValidProtocol(candidate)) {
        errorKey = 4;
        _this.setActiveErrorDebounce(_this.errors[errorKey]);
      } else {
        // this.setActiveErrorDebounce(null);
        _this.activeError = null; // set immediately (no debounce)
      }

      return errorKey;
    };

    this.setActiveErrorDebounce = _.debounce(function (error) {
      self.activeError = error;
    }, 50);

    this.addUrl = function (event) {
      var candidate = '' + _this.draftUrl;

      if (candidate.indexOf('https://') === -1 && candidate.indexOf('http://') === -1) {
        candidate = 'https://' + candidate;
      }

      var isValidUrl = _this.isValidUrl(candidate);

      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      if (_this.campaign && isValidUrl === 1) {
        var regex = /^(https?):\/\/[^/]+/;
        var match = candidate.match(regex);

        if (!match) {
          return;
        }

        var urlOrigin = match[0];
        var urls = [urlOrigin];

        var baseDomain = __getBaseDomain(urlOrigin);

        if (__isSubdomain(urlOrigin)) {
          urls.push('https://' + baseDomain);
        } else {
          urls.push('https://www.' + baseDomain);
        }

        urls.forEach(function (urlItem) {
          _this.campaign.whitelistUrl(urlItem);
        });

        _this.draftUrl = ''; // only do if valid?
        __syncUrls();
      }
    };

    this.removeUrl = function (url) {
      if (_this.campaign) {
        _this.campaign.removeWhitelistedUrl(url);
        __syncUrls();
      }
    };
  }]
});
})();