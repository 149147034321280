(function(){
'use strict';

angular.module('classy').component('dropdownControl', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n    <div class="form__dropdown" ng-keydown="$ctrl.keyDown($event)">\n\n      <!-- Faux Select -->\n      <button class="form__dropdown-selected"\n      role="combobox"\n      ng-click="$ctrl.toggleDropdown()"\n      ng-style="$ctrl.dropdownStyle()"\n      type="button"\n      _spec="selected"\n      tabindex="0"\n      aria-label="{{$ctrl.ariaLabel}}"\n      aria-expanded="{{$ctrl.dropdownVisible}}">\n        {{ $ctrl.selected.label || $ctrl.blankOption }}\n      </button>\n\n      <!-- Dropdown -->\n      <div class="form__dropdown-dropdown"\n      ng-show="$ctrl.dropdownVisible"\n      _spec="dropdown">\n\n        <div class="form__dropdown-option--blank"\n          ng-if="$ctrl.blankOption">\n          {{ $ctrl.blankOption }}\n        </div>\n\n        <button class="form__dropdown-option"\n        ng-repeat="option in $ctrl.options"\n        ng-click="$ctrl.selectOption(option)"\n        type="button"\n        role="option"\n        id="{{ option.value }}__option"\n        _spec="option">\n          {{ option.label }}\n        </button>\n  </div>\n\n    </div>\n    ',
  bindings: {
    options: '<',
    blankOption: '<?',
    width: '<?',
    selected: '=?',
    ariaLabel: '@?'
  },
  controller: ["$element", "$timeout", "$window", function DropdownControlCtrl($element, $timeout, $window) {
    var _this = this;

    var KEYS = {
      up: 38,
      down: 40,
      zero: 48,
      zed: 90,
      esc: 27
    };

    var SEARCH_TIME = 500;

    this.timer = null;
    this.searchKeys = '';

    this.$onInit = function () {
      this.dropdownVisible = false;
      this.ngModelCtrl.$render = this.$render;
    };

    this.$postLink = function () {
      angular.element($window).on('click', this.handleClickOutside);
    };

    this.$onDestroy = function () {
      angular.element($window).off('click', this.handleClickOutside);
    };

    // Retain "this" reference
    this.handleClickOutside = function ($e) {
      if (!angular.element($e.target).closest($element).length) {
        $timeout(function () {
          _this.dropdownVisible = false;
        });
      }
    };

    this.$render = function () {
      _this.selected = _.find(_this.options, { value: _this.ngModelCtrl.$modelValue });
    };

    this.toggleDropdown = function () {
      var _this2 = this;

      this.stopTimer();
      this.dropdownVisible = !this.dropdownVisible;
      if (this.dropdownVisible && this.selected) {
        $timeout(function () {
          angular.element('#' + _this2.selected.value + '__option').focus();
        });
      }
    };

    this.selectOption = function (option) {
      this.selected = option;
      this.ngModelCtrl.$setViewValue(option.value);
      this.dropdownVisible = false;
    };

    this.dropdownStyle = function () {
      if (_this.width) {
        return {
          width: _this.width
        };
      }
      return {};
    };

    this.keyDown = function ($event) {
      var _this3 = this;

      // arrow key func only in open dropdown
      if (this.dropdownVisible) {
        // arrow key up
        if ($event.keyCode === KEYS.up) {
          $event.preventDefault();
          this.stopTimer();
          angular.element(document.activeElement).prev().focus();
        } else if ($event.keyCode === KEYS.down) {
          // arrow key down
          $event.preventDefault();
          this.stopTimer();
          if (angular.element(document.activeElement)[0] == $element.find('.form__dropdown-selected')[0]) {
            $element.find('.form__dropdown-option--blank').next().focus();
          } else {
            angular.element(document.activeElement).next().focus();
          }
        } else if ($event.keyCode === KEYS.esc) {
          /* ESCAPE key pressed */
          $event.preventDefault();
          this.stopTimer();
          this.toggleDropdown();
        }
      } else if ($event.keyCode === KEYS.down) {
        $event.preventDefault();
        this.stopTimer();
        this.toggleDropdown();
      }

      // handle typed search
      if ($event.keyCode <= KEYS.zed && $event.keyCode >= KEYS.zero) {
        this.resetTimer();
        this.searchKeys += String.fromCharCode($event.keyCode).toUpperCase();
        var opt = _.find(this.options, function (option) {
          return _.startsWith(option.label.toUpperCase(), _this3.searchKeys);
        });

        if (opt) {
          if (this.dropdownVisible) {
            // dropdown open version of search
            angular.element('#' + opt.value + '__option').focus();
          } else {
            // dropdown closed search
            this.selectOption(opt);
          }
        } else {
          this.stopTimer();
        }
      }
    };

    this.resetTimer = function () {
      var _this4 = this;

      $timeout.cancel(this.timer);
      this.timer = $timeout(function () {
        _this4.searchKeys = '';
      }, SEARCH_TIME);
    };

    this.stopTimer = function () {
      $timeout.cancel(this.timer);
      this.searchKeys = '';
    };
  }]
});
})();