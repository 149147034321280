(function(){
'use strict';

/**
 * @kind factory
 * @name scImg
 *
 * @description
 * Represents an instance of an image asset in the theme.
 */

scImgFactory.$inject = ["$q", "$rootScope", "$compile", "scImgAsset", "scImgAssetsService"];
function scImgFactory($q, $rootScope, $compile, scImgAsset, scImgAssetsService) {
  // Constructor. There are many ways to instantiate an scImg.
  function scImg(source, options) {
    var myAsset = void 0,
        myOverrides = void 0;

    if (scImg.isInstance(source)) {
      return source.clone();
    } else if (Modernizr.filereader && source instanceof Blob) {
      myAsset = scImgAssetsService.build(source);
    } else if (scImg.isAsset(source)) {
      myAsset = source;
    } else if (_.isObject(source) && _.isFunction(source.then)) {
      myAsset = scImgAssetsService.build(source, options);
    } else if (scImg.isDefinition(source)) {
      myAsset = scImgAssetsService.get(source.assetId, source.origin);
      myOverrides = source;
    } else if (_.isString(source)) {
      var origin = /^\d.*/.test(source) ? 'upload' : 'static';
      myAsset = scImgAssetsService.get(source, origin);
    } else if (_.isNumber(source)) {
      myAsset = scImgAssetsService.get(source, 'upload');
    } else {
      myAsset = scImgAssetsService.NULL_ASSET;
    }

    return scImg.instantiate(myAsset, myOverrides);
  }

  // Return an instantiated scImg object.
  scImg.instantiate = function (myAsset) {
    var myOverrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    // Mongo returns empty objects as arrays, which if uncorrected will anger
    // DeepDiff as it will conflict with the result of angular.toJson(myImg).
    if (_.isEmpty(myOverrides.options)) {
      myOverrides.options = {};
    }

    if (_.isEmpty(myOverrides.meta)) {
      myOverrides.meta = {};
    }

    // Constructor that inherits from the imageAsset instance (not class).
    function scImgInstance() {
      // A static asset my define some default options and meta.
      this.options = _.cloneDeep(_.get(myAsset, 'options', {}));
      this.meta = _.cloneDeep(_.get(myAsset, 'meta', {}));
    }

    scImgInstance.prototype = Object.create(myAsset);
    scImgInstance.prototype.constructor = scImg;

    // Instance-specific properties.
    scImgInstance.prototype.asset = myAsset;

    // Instance-specific method references.
    scImgInstance.prototype.clone = scImgClone;
    scImgInstance.prototype.is = scImgIs;
    scImgInstance.prototype.on = scImgOn;
    scImgInstance.prototype.tn = scImgTn;
    scImgInstance.prototype.download = scImgDownload;
    scImgInstance.prototype.getDimensions = scImgGetDimensions;
    scImgInstance.prototype.crop = scImgCrop;
    scImgInstance.prototype.toJSON = scImgToJSON;

    // FRS-2724: Need to override the override of .asset :P
    var overridden = _.assign(new scImgInstance(), myOverrides);
    overridden.asset = myAsset;
    return overridden;
  };

  // Test if a value is an scImg instance. NOTE:
  // myImage instanceof scImg        => false! (we changed proto chain)
  // myImage.constructor === scImg   => true (we have set this manually)
  scImg.isInstance = function (obj) {
    return obj && _.isObject(obj) && obj.constructor === scImg;
  };

  // Test if an object is an scImg definition object.
  scImg.isDefinition = function (obj) {
    return obj && _.isPlainObject(obj) && _.get(obj, '__media') === 'image';
  };

  // Test if an object is an scImgAsset instance.
  scImg.isAsset = function (obj) {
    return obj && _.isObject(obj) && obj.constructor === scImgAsset;
  };

  // Convert an array of scImg instances (and/or other cruft) into an array
  // of (optionally cloned) unique scImgs.
  scImg.dedupe = function (arr, clone) {
    var out = [];
    var instances = _.filter(arr, function (val) {
      return scImg.isInstance(val);
    });

    var uniques = _.uniq(instances, function (val) {
      return val.assetId;
    });

    if (clone) {
      var clones = _.forEach(uniques, function (val) {
        out.push(val.clone());
      });

      return clones;
    }
    return uniques;
  };

  // Extract all scImg instances in an object or array.
  scImg.search = function (collection) {
    var instances = [];

    // Helper function for aggregating save/src/load events
    instances.all = function (event, callback) {
      var allDeferred = $q.defer();

      if (callback) {
        allDeferred.promise.then(callback);
      }

      if (!this.length) {
        allDeferred.resolve();
      } else {
        var promises = [];
        _.forEach(this, function (instance) {
          promises.push(instance.on(event));
        });

        $q.all(promises).then(function () {
          allDeferred.resolve();
        }, function (error) {
          allDeferred.reject(error);
        });
      }

      return allDeferred.promise;
    };

    function walk(iterable) {
      _.forEach(iterable, function (value) {
        if (scImg.isInstance(value)) {
          instances.push(value);
        } else if (_.isPlainObject(value) || _.isArray(value)) {
          walk(value);
        }
      });
    }

    walk(collection);

    return instances;
  };

  scImg.fromURL = function (url, name) {
    var deferred = $q.defer();
    var xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.response) {
        xhr.response.name = name || 'Downloaded Image';
        deferred.resolve(xhr.response);
      } else {
        deferred.reject();
      }
    };
    xhr.send();
    return new scImg(deferred.promise);
  };

  // Create a new scImg inheriting from the same asset and with the same
  // options, meta, and overrides. If you don't want to copy over options,
  // meta, and overrides you can just do new scImg(sourceImage.asset).
  function scImgClone() {
    var overrides = _.cloneDeep(this);
    return scImg.instantiate(this.asset, overrides);
  }

  // Comparison function to tell if two images share an asset.
  function scImgIs(obj) {
    return scImg.isInstance(obj) && obj.asset === this.asset;
  }

  // Proxy on() to the asset to ensure we're using its promise set.
  function scImgOn(property, callback) {
    return this.asset.on(property, callback);
  }

  // Proxy tn() to the asset to ensure we don't overwrite local properties.
  function scImgTn(width) {
    return this.asset.tn(width);
  }

  // Proxy download() to the asset to ensure the master cache is used.
  function scImgDownload(size, cache) {
    return this.asset.download(size, cache);
  }

  // getDimensions() should always set and fetch the full dimensions, so we
  // proxy this as well.
  function scImgGetDimensions() {
    return this.asset.getDimensions();
  }

  // Launch and respond to the crop modal.
  function scImgCrop(config) {
    var _this = this;

    var cropped = void 0,
        template = void 0,
        $modalScope = void 0,
        stopListeningTo = void 0;

    function cancelCrop() {
      cropped.reject();
      cleanup();
    }

    // eslint-disable-next-line no-unused-vars
    function queueCrop(e, cropData) {
      cleanup();
      var childAsset = scImgAssetsService.transform(this.asset, cropData.transformQueue, cropData.preview);
      var overrides = _.cloneDeep(this);
      var childImage = scImg.instantiate(childAsset, overrides);

      childAsset.deferred.src.promise.then(function () {
        cropped.resolve(childImage);
      }, function () {
        cancelCrop();
      });
    }

    function cleanup() {
      if (document.body.contains(template)) {
        document.body.removeChild(template);
      }
      stopListeningTo.cancel();
      stopListeningTo.save();
      $modalScope.$destroy();
    }

    cropped = $q.defer();

    template = jQuery("<div data-sc-crop-modal='cropConfig'></div>")[0];

    $modalScope = $rootScope.$new();
    $modalScope.cropConfig = config || {};
    $modalScope.cropConfig.image = null;

    stopListeningTo = {
      cancel: $modalScope.$on('scCropCancel', cancelCrop),
      save: $modalScope.$on('scCropSave', queueCrop.bind(this))
    };

    this.download().then(function () {
      $modalScope.cropConfig.image = _this.cache.full;
    });

    $compile(template)($modalScope);
    document.body.appendChild(template);
    return cropped.promise;
  }

  // toJSON replacement for squashing an scImg into a definition object.
  function scImgToJSON() {
    return {
      __media: 'image',
      origin: this.origin,
      assetId: this.assetId,
      title: this.title,
      alt: this.alt,
      width: this.width,
      height: this.height,
      options: _.get(this, 'options', null),
      meta: _.get(this, 'meta', null),
      cropData: _.get(this, 'cropData', null)
    };
  }

  return scImg;
}

angular.module('classy').factory('scImg', scImgFactory);
})();