(function(){
'use strict';

ezModalDirective.$inject = ["$log", "$rootScope", "scFlowModalService"];
function ezModalDirective($log, $rootScope, scFlowModalService) {
  var ezModals = {};

  $rootScope.ezModal = function (id) {
    var opts = ezModals[id];
    if (!opts) {
      $log.warn('No modal with id "' + id + '" found.');
      return;
    }
    scFlowModalService.open(id, opts);
  };

  return {
    compile: function compile(tElement) {
      var views = [];

      var sections = tElement.find('section');
      sections.each(function () {
        views.push(angular.element(this));
      });

      if (!views.length) {
        views.push(tElement);
      }

      tElement.remove();

      // eslint-disable-next-line no-unused-vars
      return function (scope, element, attrs) {
        var id = attrs.ezModal;

        var slides = views.map(function (el) {
          return {
            id: el.attr('id'),
            context: scope,
            template: el.html(),
            maxWidth: +el.attr('max-width') || +attrs.maxWidth || 480
          };
        });

        scFlowModalService.register(id, scope, slides);

        var options = {
          closable: attrs.closable !== 'false',
          animate: attrs.animate !== 'false'
        };

        ezModals[id] = options;
      };
    }
  };
}

angular.module('classy').directive('ezModal', ezModalDirective);
})();