(function(){
'use strict';

function scGoalSelect() {
  return {
    scope: {
      goals: '=scGoalSelect',
      goalAmount: '=ngModel'
    },

    templateUrl: 'global/directives/scGoalSelect/tpl/scGoalSelect',

    link: function link(scope) {
      scope.selected = function (goal) {
        var selected = goal ? goal.amount == scope.goalAmount : true;

        return {
          selected: selected
        };
      };

      scope.selectGoal = function (goal) {
        scope.goalAmount = goal.amount;
      };
    }
  };
}

angular.module('classy').directive('scGoalSelect', scGoalSelect);
})();