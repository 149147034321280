(function(){
'use strict';

/**
 * @kind directive
 * @name scErrorField
 *
 * @description
 * Displays an error. Often used within another sc{X}Field directive.
 *
 * @param {expression} condition If truthy, error message will be displayed
 * @param {string} message Message to display when condition is truthy
 */

scErrorField.$inject = ["scField"];
function scErrorField(scField) {
  return scField('scErrorField');
}

angular.module('classy').directive('scErrorField', scErrorField);
})();