(function(){
'use strict';

angular.module('classy').constant('EG_EVENTS', {
  donation: {
    modal: 'embedded-giving:modal:root',
    inline: 'embedded-giving:inline:root'
  },
  donationOptions: {
    modal: 'embedded-giving:modal:donation-options',
    inline: 'embedded-giving:inline:donation-options'
  },
  donationList: {
    modal: 'embedded-giving:modal:payment-methods',
    inline: 'embedded-giving:inline:payment-methods'
  },
  donationDetails: {
    modal: 'embedded-giving:modal:payment-method-details',
    inline: 'embedded-giving:inline:payment-method-details'
  },
  contact: {
    modal: 'embedded-giving:modal:contact',
    inline: 'embedded-giving:inline:contact'
  },
  fullAddress: {
    modal: 'embedded-giving:modal:contact-address',
    inline: 'embedded-giving:inline:contact-address'
  },
  recurringNudge: {
    modal: 'embedded-giving:modal:recurring-nudge',
    inline: 'embedded-giving:inline:recurring-nudge'
  },
  donationConfirmation: {
    modal: 'embedded-giving:modal:donation-confirmation',
    inline: 'embedded-giving:inline:donation-confirmation'
  },
  thankYou: {
    modal: 'embedded-giving:modal:thank-you',
    inline: 'embedded-giving:inline:thank-you'
  },
  faq: {
    modal: 'embedded-giving:modal:faq',
    inline: 'embedded-giving:inline:faq'
  },
  // Below is added for GTM, appv2 never sends these events itself (they come from the SDK)
  open: {
    modal: 'embedded-giving:modal:track-event:open',
    inline: 'embedded-giving:inline:track-event:open'
  },
  close: {
    modal: 'embedded-giving:modal:track-event:close',
    inline: 'embedded-giving:inline:track-event:close'
  },
  nudgeTray: {
    click: 'embedded-giving:nudge-tray:track-event:click',
    open: 'embedded-giving:nudge-tray:track-event:open',
    close: 'embedded-giving:nudge-tray:track-event:close'
  }
}).constant('EG_STATUSES', {
  disabled: 'disabled',
  modal: 'modal',
  inline: 'inline'
});
})();