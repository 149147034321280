(function(){
'use strict';

angular.module('classy').constant('scFundraisersBlockDefaults', {
  title: 'Fundraising Leaderboard',
  type: 'fundraisers',
  disable: false,
  showTeams: true,
  showLeaderboard: true,
  showIndividuals: true,
  recruitingMode: false,
  recruitingCta: 'Sign up in support of this campaign!',
  joinButtonText: 'Start Fundraising',
  supportersHeadline: '<span>Support a Fundraiser</span>',
  remove: false
});
})();