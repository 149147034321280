(function(){
'use strict';

scValidityModelFactory.$inject = ["scValidityFormReporter", "scValidityFieldReporter"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function scValidityModelFactory(scValidityFormReporter, scValidityFieldReporter) {
  return function () {
    function scValidityModel() {
      _classCallCheck(this, scValidityModel);

      this.reporters = {};
    }

    _createClass(scValidityModel, [{
      key: 'register',
      value: function register(type, alias, parentAlias) {
        if (type === 'form') {
          this.reporters[alias] = new scValidityFormReporter(alias);
        } else {
          this.reporters[alias] = new scValidityFieldReporter(alias);
        }

        if (parentAlias) {
          this.reporters[parentAlias].addChild(this.reporters[alias]);
          this.reporters[alias].setParent(this.reporters[parentAlias]);
        }

        return this.reporters[alias];
      }
    }, {
      key: 'deregister',
      value: function deregister(reporter) {
        var _this = this;

        _.forEach(this.reporters, function (registered, alias) {
          registered.removeChild(reporter);
          if (registered === reporter) {
            delete _this.reporters[alias];
          }
        });
      }
    }, {
      key: 'clear',
      value: function clear() {
        this.reporters = {};
        return this;
      }
    }, {
      key: 'get',
      value: function get(alias) {
        return this.reporters[alias];
      }
    }]);

    return scValidityModel;
  }();
}

angular.module('classy').factory('scValidityModel', scValidityModelFactory);
})();