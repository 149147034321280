(function(){
'use strict';

scMap.$inject = ["loadGoogleMapsAPI"];
function scMap(loadGoogleMapsAPI) {
  return {
    scope: {
      scMap: '=',
      model: '=ngModel',
      mapOptions: '=?',
      scMapCampaign: '='
    },
    replace: true,
    templateUrl: 'global/directives/scMap/scMap',
    link: function link(scope, element) {
      var mapElement = element.find('.sc-map_map')[0],
          autocompleteElement = element.find('.sc-map_autocomplete')[0];

      var usedAddressParts = {
        streetNumber: 'street_number',
        street: 'route',
        city: 'locality',
        state: 'administrative_area_level_1',
        country: 'country',
        postalCode: 'postal_code',
        postalCodeSuffix: 'postal_code_suffix'
      };

      var inputValue = angular.element(autocompleteElement).val();

      scope.scMap = _.merge({}, scope.scMap);

      function initMap(geometry) {
        var map = new google.maps.Map(mapElement, scope.mapOptions);

        var marker = new google.maps.Marker({
          map: map,
          anchorPoint: new google.maps.Point(0, -29)
        });

        var input = autocompleteElement,
            autocomplete = new google.maps.places.Autocomplete(input, {
          types: []
        });

        if (geometry) {
          map.setCenter(geometry.location);
          map.fitBounds(geometry.viewport);
          marker.setPosition(geometry.location);
          marker.setVisible(true);
        } else {
          map.fitBounds(worldBounds());
          var listener = google.maps.event.addListener(map, 'idle', function () {
            map.setZoom(1);
            google.maps.event.removeListener(listener);
          });
        }

        autocomplete.bindTo('bounds', map);

        autocomplete.addListener('place_changed', function () {
          marker.setVisible(false);

          var place = autocomplete.getPlace();

          if (!place.geometry) {
            return;
          }

          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(15);
          }

          marker.setPosition(place.geometry.location);
          marker.setVisible(true);

          scope.$apply(function () {
            inputValue = angular.element(autocompleteElement).val();

            var addressParts = getAddressParts(place.address_components);

            scope.scMap = {
              parsed: addressParts,
              entered: angular.element(autocompleteElement).val()
            };
          });
        });

        angular.element(autocompleteElement).on('keydown', function (event) {
          if (event.keyCode == 13) {
            event.preventDefault();
          }
        });

        angular.element(autocompleteElement).on('keyup', function () {
          if (inputValue !== angular.element(autocompleteElement).val()) {
            marker.setVisible(false);
          }

          inputValue = angular.element(autocompleteElement).val();

          if (inputValue !== scope.scMap.entered) {
            scope.$apply(function () {
              scope.scMap = {
                parsed: null,
                entered: angular.element(autocompleteElement).val()
              };

              map.fitBounds(worldBounds());
              map.setZoom(1);
            });
          }
        });
      }

      function getAddressParts(components) {
        var address = {};

        angular.forEach(components, function (component) {
          angular.forEach(usedAddressParts, function (googleKey, localKey) {
            if (_.indexOf(component.types, googleKey) > -1) {
              if (localKey == 'country' || localKey == 'state') {
                address[localKey] = component.short_name;
              } else {
                address[localKey] = component.long_name;
              }
            }
          });
        });

        return address;
      }

      function worldBounds() {
        var allowedBounds = new google.maps.LatLngBounds(new google.maps.LatLng(85, -180), // top left corner of map
        new google.maps.LatLng(-85, 180) // bottom right corner
        ),
            k = 5.0,
            n = allowedBounds.getNorthEast().lat() - k,
            e = allowedBounds.getNorthEast().lng() - k,
            s = allowedBounds.getSouthWest().lat() + k,
            w = allowedBounds.getSouthWest().lng() + k,
            neNew = new google.maps.LatLng(n, e),
            swNew = new google.maps.LatLng(s, w);
        return new google.maps.LatLngBounds(swNew, neNew);
      }

      loadGoogleMapsAPI.then(function () {
        if (scope.model) {
          var geocoder = new google.maps.Geocoder();
          var campaign = scope.scMapCampaign;

          geocoder.geocode({
            address: campaign.venue
          }, function (result) {
            if (result && result.length) {
              initMap(result[0].geometry);
            } else {
              geocoder.geocode({
                address: scope.model
              }, function (modelResult) {
                if (modelResult && modelResult.length) {
                  initMap(modelResult[0].geometry);
                } else {
                  initMap();
                }
              });
            }
          });
        } else {
          initMap();
        }
      });
    }
  };
}

angular.module('classy').directive('scMap', scMap);
})();