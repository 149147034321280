(function(){
'use strict';

recipientPickerFlowFactory.$inject = ["$http", "$log", "$rootScope", "$timeout", "scFlowModalService", "scFundraisingPagesService", "scFundraisingTeamsService", "scThemesService"];
function recipientPickerFlowFactory($http, $log, $rootScope, $timeout, scFlowModalService, scFundraisingPagesService, scFundraisingTeamsService, scThemesService) {
  return function recipientPickerFlow() {
    var $scope = $rootScope.$new();

    /* ---------------------------------------------------------------------- *
     * State
     * ---------------------------------------------------------------------- */

    $scope.state = {
      query: {
        type: 'name',
        types: [{
          name: 'name',
          label: 'Donors'
        }, {
          name: 'email',
          label: 'Email'
        }],
        name: '',
        emailAddress: ''
      },
      entityType: null,
      entityId: null,
      selectAll: false,
      currentPage: 0,
      totalPages: 0,
      loading: false,
      debouncer: null,
      primaryColor: scThemesService.active.current.styles.primaryColor
    };

    /* ---------------------------------------------------------------------- *
     * Setup
     * ---------------------------------------------------------------------- */

    $scope.setup = {
      entity: function entity() {
        if (_.get(scFundraisingPagesService, 'active.current.id')) {
          $scope.state.entityType = 'page';
          $scope.state.entityId = scFundraisingPagesService.active.current.id;
        } else {
          $scope.state.entityType = 'team';
          $scope.state.entityId = scFundraisingTeamsService.active.current.id;
        }
      }
    };

    /* ---------------------------------------------------------------------- *
     * Constructed Values
     * ---------------------------------------------------------------------- */

    $scope.build = {
      filter: function filter() {
        var filter = 'is_anonymous=false,status=success,';
        if ($scope.state.entityType === 'page') {
          filter += 'fundraising_page_id=' + $scope.state.entityId;
        } else {
          filter += 'fundraising_team_id=' + $scope.state.entityId;
        }
        if ($scope.state.query.type === 'name') {
          if ($scope.state.query.name) {
            filter += ',member_name*=' + $scope.state.query.name;
          }
        } else if ($scope.state.query.emailAddress) {
          filter += ',member_email_address*=' + $scope.state.query.emailAddress;
        }
        return filter;
      },
      updateCount: function updateCount() {
        $scope.selectCount = $scope.list.items.filter(function (item) {
          return item.selected;
        }).length;
      },
      emailList: function emailList() {
        return $scope.list.items.filter(function (item) {
          return item.selected;
        }).map(function (item) {
          return item.emailAddress;
        }).join(', ').trim();
      }
    };

    $scope.selectCount = 0;

    /* ---------------------------------------------------------------------- *
     * Collections
     * ---------------------------------------------------------------------- */

    $scope.list = {
      items: [],
      emails: []
    };

    /* ---------------------------------------------------------------------- *
     * Display Flags
     * ---------------------------------------------------------------------- */

    $scope.show = {
      nameQuery: function nameQuery() {
        return $scope.state.query.type === 'name';
      }
    };

    /* ---------------------------------------------------------------------- *
     * API
     * ---------------------------------------------------------------------- */

    $scope.nextPage = function () {
      $scope.state.currentPage += 1;

      $scope.state.locked = true;

      $http.get('/frs-api/fundraising-' + $scope.state.entityType + 's/' + $scope.state.entityId + '/feed-item-donations', {
        params: {
          page: $scope.state.currentPage,
          sort: 'created_at:desc',
          per_page: 100,
          filter: 'linkable_type=donation',
          with: 'linkable'
        }
      }).then(function (result) {
        $scope.state.totalPages = result.data.last_page;
        _.forEach(result.data.data, function (transaction) {
          if (transaction.linkable && transaction.linkable.member_email_address && !transaction.linkable.is_anonymous && transaction.linkable.status == 'success') {
            $scope.list.items.push({
              amount: transaction.linkable.donation_gross_amount,
              name: transaction.linkable.member_name,
              emailAddress: transaction.linkable.member_email_address
            });
          }
        });
      }).catch(function (err) {
        $log.error(err);
      });
    };

    $scope.debounce = function (cb) {
      $timeout.cancel($scope.state.debouncer);
      $scope.state.debouncer = $timeout(cb, 500);
    };

    $scope.handleQuery = function () {
      $scope.list.items = [];
      $scope.state.currentPage = 0;
      $scope.state.totalPages = 0;
      $scope.state.loading = true;
      $scope.debounce($scope.nextPage);
    };

    $scope.clear = function () {
      $scope.list.items.forEach(function (item) {
        return delete item.selected;
      });
      $scope.selectCount = 0;
    };

    $scope.assemble = function () {
      scFlowModalService.to('recipient-picker.copy', 'slideLeft');
    };

    /* ---------------------------------------------------------------------- *
     * Flow
     * ---------------------------------------------------------------------- */

    var views = [];

    views.push({
      id: 'recipient-picker.search',
      templateUrl: 'global/flows/recipient-picker/views/search/template',
      maxWidth: 663,
      context: $scope
    });

    views.push({
      id: 'recipient-picker.wait',
      templateUrl: 'global/flows/recipient-picker/views/wait/template',
      maxWidth: 300,
      context: $scope
    });

    views.push({
      id: 'recipient-picker.copy',
      templateUrl: 'global/flows/recipient-picker/views/copy/template',
      maxWidth: 456,
      context: $scope
    });

    /* ---------------------------------------------------------------------- *
     * Init
     * ---------------------------------------------------------------------- */

    $scope.setup.entity();

    $scope.$watch('state.query', $scope.handleQuery, true);

    // Register views and keys
    scFlowModalService.register('recipientPickerFlow', $scope, views);

    scFlowModalService.open('recipientPickerFlow', {
      startingView: 'recipient-picker.search',
      animate: true,
      matte: '#f5f5f5'
    });
  };
}

angular.module('classy').factory('recipientPickerFlow', recipientPickerFlowFactory);
})();