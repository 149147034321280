(function(){
'use strict';

frsDonationMatchingCtrl.$inject = ["$scope"];
function frsDonationMatchingCtrl($scope) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */

  $scope.CONSTANTS = {
    EMAIL_ADDRESS_REQUIRED_ERROR_TEXT: 'Please enter your employee email.',
    EMAIL_ADDRESS_FORMAT_ERROR_TEXT: 'Please enter in the format: name@domain.com.',
    TYPE_EMPLOYER_REQUIRED_ERROR_TEXT: 'Please type in your employer name.'
  };

  /* ---------------------------------------------------------------------- *
   * UI State
   * ---------------------------------------------------------------------- */

  $scope.state = {
    matchingFormEnabled: false
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    matchingForm: function matchingForm() {
      return $scope.state.matchingFormEnabled;
    }
  };

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/matching/partials/' + name;
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  var matchingModel = {
    employer_name: ''
  };

  $scope.$watch('state.matchingFormEnabled', function (enabled) {
    if (enabled) {
      $scope.FORM.$addControl($scope.matchingForm);
      $scope.MODEL.employer_match = matchingModel;
    } else {
      $scope.FORM.$removeControl($scope.matchingForm);
      $scope.MODEL.employer_match = null;
    }
  });
}

angular.module('classy').controller('frsDonationMatchingCtrl', frsDonationMatchingCtrl);
})();