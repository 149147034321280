(function(){
'use strict';

scIntlService.$inject = ["$log"];
function scIntlService($log) {
  // This service provides a fallback for when Intl.NumberFormat is not supported by the browser.
  // Historical issue with web crawlers not supporting polyfill or Intl: https://classydev.atlassian.net/browse/FRS-6087
  // Modern browsers have supported Intl for a very long time, so we don't expect the fallback to be used often, if ever.
  return {
    NumberFormat: function NumberFormat(language, options) {
      if (!Intl || !Intl.NumberFormat) {
        $log.log('Intl library was unavailable, falling back to rendering bare currency code');
        // return an object shaped like the Intl object would return so that code calling
        // this method will work the same regardless of whether Intl is present or not
        return { format: function format(value) {
            return '' + options.currency + value;
          } };
      }

      return new Intl.NumberFormat(language, options);
    }
  };
}

angular.module('classy').service('scIntlService', scIntlService);
})();