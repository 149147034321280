(function(){
'use strict';

/*
  Basic Tooltip
*/

function scTip() {
  // eslint-disable-next-line no-unused-vars
  return function (scope, element, attrs) {
    if (!angular.isUndefined(attrs.title)) {
      var title = attrs.title;
      element.append('<div class="balloon">' + title + '</div>');
    }
  };
}

angular.module('classy').directive('scTip', scTip);
})();