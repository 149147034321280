(function(){
'use strict';

angular.module('classy').component('dropdownInputField', {
  template: '\n    <form-label\n    required="$ctrl.required"\n    ng-if="$ctrl.label"\n    _spec="label">\n      {{ $ctrl.label }}\n    </form-label>\n\n    <div class="dropdown-input__wrapper" name="{{:: $ctrl.name }}">\n      <number-field\n        model="$ctrl.model"\n        min="$ctrl.min"\n        max="$ctrl.max"\n        required="$ctrl.required"\n        on-change="$ctrl.inputModelChange()"\n        no-spacer="true"\n        data-disable-flag="$ctrl.disableFlag"\n        placeholder="::$ctrl.placeholder"\n        aria-label="\'Select promo code discount type\'">\n      </number-field>\n      <select class="dropdown-input__select"\n        ng-model="$ctrl.selectedModel"\n        ng-change="$ctrl.dropdownModelChange()"\n        data-ng-disabled="$ctrl.disableFlag"\n\n        ng-options="option as option.label for option in $ctrl.dropdownOptions"\n        _spec="select"\n        aria-label="{{:: $ctrl.ariaLabel }}">\n      </select>\n    </div>\n\n    <form-error\n    name="$ctrl.name"\n    messages="$ctrl.errorMessages"\n    _spec="errors"></form-error>\n  ',

  bindings: {
    model: '=',
    disableFlag: '<',
    dropdownModel: '=',
    dropdownOptions: '<',
    min: '<?',
    max: '<?',
    name: '<',
    required: '<?',
    placeholder: '<?',
    label: '<?',
    onDropdownModelChange: '&?',
    onInputModelChange: '&?',
    ariaLabel: '@?'
  },

  controller: ["$timeout", function controller($timeout) {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      }
    };

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);
      _this.index = _.findIndex(_this.dropdownOptions, { value: _this.dropdownModel }) || 0;
      _this.selectedModel = _this.dropdownOptions[_this.index];
    };

    this.dropdownModelChange = function () {
      _this.currDropdownLabel = _this.selectedModel.label;
      _this.dropdownModel = _this.selectedModel.value;

      $timeout(function () {
        _this.onDropdownModelChange();
      });
    };

    this.inputModelChange = function () {
      $timeout(function () {
        _this.onInputModelChange();
      });
    };
  }]
});
})();