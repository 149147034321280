(function(){
'use strict';

scBlockModelFactory.$inject = ["scObjectModel", "scBlockDefaults", "scCloneDeep"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * @kind factory
 * @name scBlockModel
 *
 * @description
 * A block constructor with some common
 * prototype methods.
 */

function scBlockModelFactory(scObjectModel, scBlockDefaults, scCloneDeep) {
  function scBlockModel() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (data.queries_log) {
      delete data.queries_log;
    }
    if (data.mongo_queries_log) {
      delete data.mongo_queries_log;
    }
    if (data.updated_at) {
      delete data.updated_at;
    }

    // Conversion of recurring-level from string to array on removal of 'prevent-recurring-frequencies' org tag.
    if (data.type == 'donation' && data['recurring-level'] && typeof data['recurring-level'] === 'string' && !(SC.organization.tags || []).includes('prevent-recurring-frequencies')) {
      // Here, we will fetching all amount from existing campaign if any user has pre-defined amount of thier own choice for respective campaign.
      var donationLevels = data['donation-levels'].map(function (item) {
        return item.amount;
      });

      // If frequency is forced one-time(6) or forced monthly(3) or set as default from existing amount
      // then only accept donation-default.
      data['donation-default'] = ['3', '6'].includes(data['recurring-level']) || donationLevels.includes(data['donation-default']) ? data['donation-default'] : null;

      // Return true if donation-default contains amount (Prefilled amount from old campaign) other than array containing amount;
      var isPrefilledAmount = ![].concat(_toConsumableArray(donationLevels), [null]).includes(data['donation-default']);

      var isOneTime = ['1', '5', '6'].includes(data['recurring-level']);
      data['recurring-default'] = isOneTime ? 'one-time' : 'monthly';

      data['recurring-level'] = [{
        type: 'one-time',
        name: 'One-time',
        display_on_page: isPrefilledAmount ? isOneTime : true
      }, {
        type: 'daily',
        name: 'Daily',
        display_on_page: false
      }, {
        type: 'weekly',
        name: 'Weekly',
        display_on_page: false
      }, {
        type: 'bi-weekly',
        name: 'Bi-weekly (every 2 weeks)',
        display_on_page: false
      }, {
        type: 'monthly',
        name: 'Monthly',
        display_on_page: isPrefilledAmount ? !isOneTime : true
      }, {
        type: 'quarterly',
        name: 'Quarterly (every 3 months)',
        display_on_page: !isPrefilledAmount
      }, {
        type: 'semi-annually',
        name: 'Semi-annually (every 6 months)',
        display_on_page: !isPrefilledAmount
      }, {
        type: 'yearly',
        name: 'Yearly (every 12 months)',
        display_on_page: !isPrefilledAmount
      }];
      data['donation-levels'] = [{
        amount: isPrefilledAmount ? data['donation-default'] : donationLevels[0],
        display_on_page: true
      }, {
        amount: donationLevels[1],
        display_on_page: !isPrefilledAmount
      }, {
        amount: donationLevels[2],
        display_on_page: !isPrefilledAmount
      }, {
        amount: donationLevels[3],
        display_on_page: !isPrefilledAmount
      }, {
        amount: 'Custom',
        display_on_page: !isPrefilledAmount
      }];
    }

    if (data.type == 'donation' && data['donation-levels'] && (!data['recurring-donation-levels'] || data['recurring-donation-levels'].length === 0) && !(SC.organization.tags || []).includes('prevent-recurring-frequencies') && !(SC.organization.tags || []).includes('prevent-oldrf-for-deque')) {
      data['recurring-donation-levels'] = _.cloneDeep(data['donation-levels']);
      data['recurring-donation-default'] = data['donation-default'];
    }

    scObjectModel.call(this);

    this.type = 'block';
    this.defaults = scBlockDefaults(data.type);
    this.saved = scCloneDeep(data);
    this.current = scCloneDeep(data);

    // Avoid duplication of data and give prefrence to backend data
    if (data.type == 'donation' && data['recurring-level']) {
      this.defaults['recurring-level'] = data['recurring-level'];
    }

    this.setupBackwardsCompatability(this.saved);
    this.setupBackwardsCompatability(this.current);
  }

  scBlockModel.prototype = _.create(scObjectModel.prototype, {
    constructor: scBlockModel
  });

  scBlockModel.prototype.setupBackwardsCompatability = setupBackwardsCompatability;
  scBlockModel.prototype.getThumbUrl = getThumbUrl;

  /*
   * Backwards comptability: merging defaults into current
   */
  function setupBackwardsCompatability() {
    var dataset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (_.isUndefined(dataset.background)) {
      dataset.background = {};
    }

    // 2016 02 19: Map old opacity property to imageOpacity
    if (_.isUndefined(dataset.background.imageOpacity) && !_.isUndefined(dataset.background.opacity)) {
      dataset.background.imageOpacity = dataset.background.opacity;
    }

    // 2016 02 22: Map old hideHeadline property to enableHeadline for CF (hero) block
    if (dataset.type === 'crowdfunding' && _.isUndefined(dataset.enableHeadline) && !_.isUndefined(dataset.hideHeadline)) {
      dataset.enableHeadline = !dataset.hideHeadline;
    }

    _.defaultsDeep(dataset, this.defaults);

    // [Remove w/ FRS-8049] All fundraiser and team blocks should be sortable.
    if ((dataset.type === 'team' || dataset.type === 'fundraiser') && dataset.unsortable) {
      dataset.unsortable = false;
    }

    if (dataset.type == 'donation' || dataset.type == 'thank-you' || dataset.type == 'about') {
      var fontSize = '40px';
      var selector = dataset.headline;

      if (dataset.type == 'about') {
        selector = dataset.content;
        fontSize = '22px';
      }

      var includesHtmlTag = /<[a-z][\s\S]*>/i.test(selector);

      if (includesHtmlTag) {
        // [FRS-7996] We need to these strings as HTML in order
        // to create DOM elements on the fly here (http://api.jquery.com/jquery/#jQuery2),
        // not find existing elements in the DOM.
        // There are any numbers of HTML + text combinations our `content` and
        // `headline` properties might include, and each of these will be handled
        // in different ways by $() unless we explicitly pass HTML.
        //
        // Take these examples:
        // 1. $("This is our <strong><a>page</a></strong>")
        // 2. $("<strong><a>page</a></strong> <p>test</p>")
        //
        // The first will whitescreen when jQuery throws an `unrecognized expression` error
        // The second will work, it's recognized as HTML
        var newSelector = $.parseHTML(selector);
        var newText = $(newSelector).toArray()
        // remove HTML comments, which would otherwise cause problems
        // remove any other element which lacks a style tag, as that would cause problems
        // when rendering
        // See https://classydev.atlassian.net/browse/FRS-7210 for an example of text pasted from
        // MSWord which caused this field to contain HTML comments and caused problems
        .filter(function (node) {
          return node.nodeName && node.nodeName.toLowerCase() !== '#comment' && (node.nodeName.toLowerCase() === '#text' || node.style);
        }).map(function (node) {
          if (node.nodeName.toLowerCase() === '#text') {
            return node.textContent;
          }

          if (!node.style.fontSize) {
            node.style.fontSize = fontSize;
          }

          return $(node).prop('outerHTML');
        }).join('');

        if (dataset.type == 'about') {
          dataset.content = newText;
        } else {
          dataset.headline = newText;
        }
      } else if (dataset.type == 'about') {
        dataset.content = '<span style="font-size: ' + fontSize + ';">' + selector + '</span>';
      } else {
        dataset.headline = '<span style="font-size: ' + fontSize + ';">' + selector + '</span>';
      }
    }

    if (dataset.featuredType === 'video') {
      if (!_.isArray(dataset.featuredImg)) {
        dataset.featuredImg = [dataset.featuredImg];
      }

      var video = {
        url: dataset.featuredVideo,
        type: 'video'
      };

      if (_.indexOf(dataset.featuredImg, video) == -1 && dataset.featuredVideo) {
        dataset.featuredImg.push(video);
        dataset.featuredVideo = null;
      }
    }
  }

  function getThumbUrl() {
    return 'global/objects/scBlock/types/' + this.type + '/thumbnail';
  }

  return scBlockModel;
}

angular.module('classy').factory('scBlockModel', scBlockModelFactory);
})();