(function(){
'use strict';

angular.module('classy').service('CryptoGivingCampaignUtil', ["CG_DONATION_AMOUNTS", "scBlocksService", function (CG_DONATION_AMOUNTS, scBlocksService) {
  // the campaign model in general has way to many nested abstractions
  // these props are direct pointers to important (nested) models
  this.campaign = null;
  this.blocks = null;
  this.donationBlock = null;
  this.donationAmounts = null; // set to default?

  // donationBlock

  // init requires campaign model.
  this.init = function (campaign) {
    if (!campaign || _.isEmpty(campaign)) {
      return;
    }

    this.campaign = campaign;

    // TODO: Should we use default blocks as fallback?
    if (this.campaign) {
      this.blocks = scBlocksService.blocks;

      // [Option #1] for Donation Block
      // the keys for the blocks object are unique IDs, so we need to loop over and fine when current.type == donation
      if (!_.isEmpty(this.blocks)) {
        var dBlock = _.find(this.blocks, function (block) {
          return block.current && block.current.type === 'donation';
        });
        if (dBlock) {
          this.donationBlock = dBlock.current;
        }
      }

      // [Option #2] for Donation Block
      // if no donation block, then get it from the defaultBlocks
      if (!this.donationBlock) {
        this.blocks = this.campaign.defaultBlocks; // change blocks ptr to default
        this.donationBlock = this.blocks.donation.current;
        // There are 3 high-level wrapping props on donation:
        // 'current', 'saved', and 'defaults'
        // I referenced code to see which to use

        // [Option #3] for Donation Block
        // For sanity's sake.. if 'current' isn't there fall back to default
        // Other code I referenced does not do this... But rather be safe than sorry?
        if (!this.donationBlock) {
          this.donationBlock = this.blocks.donation.defaults;
        }
      }
    }

    this.setDonationAmounts(this.donationBlock);
  };

  // TODO: need to filter out 'display_on_page': false
  // TODO: if amounts < 4 then add default amounts
  // TODO: after ^ and subsequently amounts.length == 4, then sort
  // TODO: do we want to sort??? the default does not

  // We have a setter here because we want to remove the 'custom' obj
  // AND because we want to always make sure to fall back to constant if need
  this.setDonationAmounts = function (donationBlock) {
    var amounts = void 0;

    // 'donationBlock' should always be here... but just in case it isn't...
    // I made copied over 'defaults' from a prod campaign as a fall back
    if (donationBlock && donationBlock['donation-levels']) {
      amounts = donationBlock['donation-levels'].filter(function (l) {
        return l.amount !== 'Custom';
      }); // Remove 'custom' level
      // .sort(l => -l.amount); // Sort from least to greatest TODO might not want to sort, see above

      // Note: there is also 'recurring-donation-levels'
      // However, since crypto giving is one-time only we don't care
    }

    this.donationAmounts = amounts || CG_DONATION_AMOUNTS; // fall back if need
  };
}]);
})();