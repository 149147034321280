(function(){
'use strict';

/**
 * Add this directive to an input field. The
 * input will gain focus when the binded variable
 * is set to true. The directive will then set the variable
 * back to false.
 *
 * @param scFocus - boolean variable to trigger focus
 * @name scFocus
 * @kind directive
 */

/**
 * @private
 * @returns {Object}
 */
scFocus.$inject = ["$timeout"];
function scFocus($timeout) {
  return {
    link: function linkFn(scope, element, attrs) {
      scope.$watch(attrs.scFocus, function (value) {
        if (value === true) {
          $timeout(function () {
            element[0].focus();
            scope[attrs.scFocus] = false;
          });
        }
      });
    }
  };
}

angular.module('classy').directive('scFocus', scFocus);
})();