(function(){
'use strict';

// TODO diff between processor and payment method?
angular.module('classy').factory('PaymentMethod', ["$log", "cpConstants", function ($log, cpConstants) {
  var METHODS = cpConstants.PAYMENT_METHODS;

  function PaymentMethod(data) {
    var d = data || {};
    this.key = d.key;
    this.name = d.name || '';
    this.status = d.status || 'inactive'; // TODO 'INACTIVE' case?
    this.currency = d.currency || 'USD';

    this.setPaymentProcessor(d.paymentProcessor);
    this.$$initLabel();
  }

  PaymentMethod.prototype.serialize = function () {
    if (!this.paymentProcessor) {
      $log.warn('No payment processor present');
      return null;
    }

    // TODO
    var payload = {
      status: this.status
      // currency: this.curency
      // processor stuff?
    };

    return payload;
  };

  PaymentMethod.prototype.setStatus = function (status) {
    this.status = status || 'inactive';
  };

  PaymentMethod.prototype.setPaymentProcessor = function (paymentProcessor) {
    this.paymentProcessor = paymentProcessor;
  };

  PaymentMethod.prototype.$$initLabel = function () {
    var label = 'Payment';

    switch (this.name) {
      case METHODS.STRIPE:
      case METHODS.CC:
        {
          label = 'Card Number';
          break;
        }
      case METHODS.ACH:
        {
          label = 'Bank Info';
          break;
        }
      case METHODS.PAYPAL_COMMERCE:
      case METHODS.PAYPAL:
        {
          label = 'PayPal Account';
          break;
        }
      default:
        label = 'Payment';
        break;
    }

    this.$$label = label;
  };

  return PaymentMethod;
}]);
})();