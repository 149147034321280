(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy').component('entitySwitchModal', {
  template: '\n      <modal-frame>\n\n        <modal-title tabindex="0">{{:: $ctrl.title }}</modal-title>\n\n        <div class="entity-switch-modal__container">\n          <a class="entity-switch-modal__button" \n          role="button"\n          href=""\n          ng-if="!entity.hide"\n          ng-repeat="entity in $ctrl.entities"\n          ng-click="entity.onSelect()"\n          ng-keydown="$ctrl.select(entity, $event)"\n          ng-class="{ disabled: $ctrl.isDisabled }">\n            <i class="{{:: entity.icon }} entity-switch-modal__icon"></i>\n            <span class="entity-switch-modal__message">\n              {{:: entity.message }}\n            </span>\n          </a>\n        </div>\n        \n      </modal-frame>\n    ',
  bindings: {
    entities: '<',
    title: '<'
  },
  controller: function () {
    EntitySwitchModalCtrl.$inject = ["accessibilityService"];
    _createClass(EntitySwitchModalCtrl, null, [{
      key: '$inject',
      get: function get() {
        return ['accessibilityService'];
      }
    }]);

    function EntitySwitchModalCtrl(accessibilityService) {
      _classCallCheck(this, EntitySwitchModalCtrl);

      this.accessibilityService = accessibilityService;
    }

    // Buttons start enabled.


    _createClass(EntitySwitchModalCtrl, [{
      key: '$onInit',
      value: function $onInit() {
        this.disableButtons = false;
      }

      // Disable all buttons and then fire callback in entity definition.

    }, {
      key: 'select',
      value: function select(entity, $event) {
        if ($event && !this.accessibilityService.isValidKeyBoardEvent($event)) return;

        this.disableButtons = true;
        _.find(this.entities, entity).onSelect();
      }
    }]);

    return EntitySwitchModalCtrl;
  }()
});
})();