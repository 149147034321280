(function(){
'use strict';

angular.module('classy').constant('fundraisersBlockDemoData', {
  teams: [{
    team_lead_id: 2483664,
    status: 'active',
    city: '',
    state: '',
    country: '',
    campaign_id: 54562,
    total_donations: 0,
    total_fundraisers: 1,
    total_donors: 0,
    total_raised: 300,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 52261,
    organization_id: 20657,
    name: 'Fundraising Team 1',
    goal: '400.00',
    created_at: '2016-02-05 12:41:43',
    postal_code: ''
  }, {
    team_lead_id: 2483664,
    status: 'active',
    city: '',
    state: '',
    country: '',
    campaign_id: 54562,
    total_donations: 0,
    total_fundraisers: 11,
    total_donors: 150,
    total_raised: 100,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 52262,
    organization_id: 20657,
    name: 'Fundraising Team 2',
    goal: '710.00',
    created_at: '2016-02-05 12:47:27',
    postal_code: ''
  }, {
    team_lead_id: 2483664,
    status: 'active',
    city: '',
    state: '',
    country: '',
    campaign_id: 54562,
    total_donations: 0,
    total_fundraisers: 0,
    total_donors: 0,
    total_raised: 56,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 52263,
    organization_id: 20657,
    name: 'Fundraising Team 3',
    goal: '150.00',
    created_at: '2016-02-05 13:37:30',
    postal_code: ''
  }, {
    team_lead_id: 2483664,
    status: 'active',
    city: '',
    state: '',
    country: '',
    campaign_id: 54562,
    total_donations: 0,
    total_fundraisers: 1,
    total_donors: 0,
    total_raised: 54,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 52264,
    organization_id: 20657,
    name: 'Fundraising Team 4',
    goal: '110.00',
    created_at: '2016-02-05 13:37:32',
    postal_code: ''
  }, {
    team_lead_id: 1403661,
    status: 'active',
    city: '',
    state: '',
    country: '',
    campaign_id: 54562,
    total_donations: 0,
    total_fundraisers: 0,
    total_donors: 0,
    total_raised: 4,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 52266,
    organization_id: 34,
    name: 'Fundraising Team 5',
    goal: '10.00',
    created_at: '2016-02-05 13:37:32',
    postal_code: ''
  }],
  fundraisers: [{
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 990,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468146,
    member_id: 1403661,
    organization_id: 20688,
    designation_id: null,
    title: 'Fundraising Page 1',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Fundraising Page 1'
  }, {
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 90,
    total_raised: 500,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468147,
    member_id: 2483805,
    organization_id: 20688,
    designation_id: null,
    title: 'Fundraising Page 2',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Fundraising Page 2'
  }, {
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 400,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468148,
    member_id: 2483805,
    organization_id: 20688,
    designation_id: null,
    title: 'Fundraising Page 3',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Fundraising Page 3'
  }, {
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 300,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468149,
    member_id: 2483805,
    organization_id: 20688,
    designation_id: null,
    title: 'Fundraising Page 4',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Fundraising Page 4'
  }, {
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 10,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468150,
    member_id: 2483805,
    organization_id: 20688,
    designation_id: null,
    title: 'Fundraising Page 5',
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Fundraising Page 5'
  }],
  leaders: [{
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 0,
    total_raised: 990,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468146,
    member_id: 1403661,
    organization_id: 20688,
    designation_id: null,
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Fundraising Page 1',
    order: 1,
    title: 'Top Fundraiser',
    type: 'fundraiser',
    measure: '$',
    metric: 'raised'
  }, {
    order: 2,
    title: 'Most Donors',
    type: 'fundraiser',
    measure: '',
    metric: 'donors',
    status: 'active',
    fundraising_team_id: 52262,
    campaign_id: 54562,
    total_donations: 0,
    total_donors: 90,
    total_raised: 500,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 468147,
    member_id: 2483805,
    organization_id: 20688,
    designation_id: null,
    thankyou_email_text: null,
    member_email_text: null,
    goal: '10000.00',
    started_at: '2016-02-11 08:00:00',
    ended_at: null,
    alias: 'Fundraising Page 2'
  }, {
    order: 3,
    title: 'Top Team',
    type: 'team',
    measure: '$',
    metric: 'raised',
    team_lead_id: 2483664,
    status: 'active',
    city: '',
    state: '',
    country: '',
    campaign_id: 54562,
    total_donations: 10,
    total_fundraisers: 1,
    total_donors: 0,
    total_raised: 300,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 52261,
    organization_id: 20657,
    name: 'Fundraising Team 1',
    goal: '400.00',
    created_at: '2016-02-05 12:41:43',
    postal_code: ''
  }, {
    order: 4,
    title: 'Most Members',
    type: 'team',
    measure: '',
    metric: 'members',
    team_lead_id: 2483664,
    status: 'active',
    city: '',
    state: '',
    country: '',
    campaign_id: 54562,
    total_donations: 0,
    total_fundraisers: 11,
    total_donors: 150,
    total_raised: 100,
    average_donation: 0,
    largest_donation: 0,
    percent_to_goal: 0,
    id: 52262,
    organization_id: 20657,
    name: 'Fundraising Team 2',
    goal: '710.00',
    created_at: '2016-02-05 12:47:27',
    postal_code: ''
  }]
});
})();