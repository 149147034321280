(function(){
'use strict';

/*
 * type reactEditorOptions = {
 *   aspect-ratio: number,
 *   circle: boolean,
 *   fileName: string,
 *   handleUpload: Function,
 *   height: number,
 *   heroFullHeight: boolean,
 *   maxFileSize: number,
 *   mode: 'basic' | 'full',
 *   onCancel: Function,
 *   outputMaxWidth: number,
 *   src: string,
 *   visible: boolean
 * }
 */

angular.module('classy').component('mediaManagerEditor', {
  bindings: {
    aspectRatio: '<',
    circle: '<',
    fileName: '<?',
    handleUpload: '&',
    height: '<',
    heroFullHeight: '<?',
    maxFileSize: '<?',
    mode: '<?',
    onCancel: '&?',
    outputMaxWidth: '<?',
    src: '<',
    visible: '<'
  },

  template: '\n  <div class="media-manager_editor">\n\n    <image-editor\n      aspect-ratio="$ctrl.aspectRatio"\n      canvas-data="$ctrl.canvasData"\n      circle="$ctrl.circle"\n      file-name="$ctrl.fileName"\n      height="$ctrl.height"\n      hero-full-height="$ctrl.heroFullHeight"\n      max-file-size="$crtl.maxFileSize"\n      mode="$ctrl.mode"\n      on-cancel="$ctrl.onCancel"\n      output-max-width="$ctrl.outputMaxWidth"\n      position="$ctrl.position"\n      save-function="$ctrl.saveFunction"\n      src="$ctrl.src"\n      toggle-visible="$ctrl.toggleVisible"\n      visible="$ctrl.visible"\n    />\n  ',

  controller: function controller() {
    var _this = this;

    this.$onInit = function () {
      // Only use a valid React ImageEditor mode: 'full' or 'basic'
      if (!['full', 'basic'].includes(_this.mode)) {
        _this.mode = 'full';
      }

      var DEFAULTS = {
        mode: 'full',
        circle: false,
        aspectRatio: 16 / 9,
        src: '',
        visible: true,
        toggleVisible: function toggleVisible(val) {
          _this.visible = val;
        },
        saveFunction: function saveFunction(imgFile, imageData) {
          _this.handleUpload({ imgFile: imgFile, imageData: imageData });
          _this.toggleVisible(false);
        }
      };

      _.defaultsDeep(_this, DEFAULTS);
    };
  }
});
})();