(function(){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

// Fork of component 'cpCreditCardWidget'
angular.module('classy').component('egPayCreditCard', {
  templateUrl: 'global/embedded-giving/pay/components/credit-card/eg.pay.components.credit-card', // same folder as this
  bindings: {
    onToken: '&',
    onCard: '&',
    payment: '<',
    label: '<?',
    primaryColor: '<?',
    hidePostal: '<?',
    hideLabel: '<'
  },
  controller: ["$sce", "$scope", "$rootScope", "$templateCache", "$timeout", "scDigitalWalletsService", "scFeeOnTopService", function controller($sce, $scope, $rootScope, $templateCache, $timeout, scDigitalWalletsService, scFeeOnTopService) {
    var _this2 = this;

    /* -------------------------------------------------------------------- *
     * Lifecycle
     * -------------------------------------------------------------------- */

    this.$onInit = function () {
      this.label = this.label || 'Card number';
      this.mode = 'number';
      this.modeChanged = false;
      this.formName = 'cpCreditCardWidget';
      this.numberInvalid = false;
      this.detailsInvalid = false;
      this.expirationDateInvalid = false;
      this.securityCodeInvalid = false;
      this.postalOrZipInvalid = false;
      this.errorVisible = false;

      $scope.$on('scValidity.update', updateErrorMessage.bind(this));
    };

    /* -------------------------------------------------------------------- *
     * View management
     * -------------------------------------------------------------------- */

    // When a valid credit card is first entered, automatically progress to
    // the next view.
    this.handleValidCard = function (brand, lastFour) {
      // This fixes an error where sometimes 'form' is undefined when this function is called
      if (!this.form) return;

      this.payment.brand = brand;
      this.payment.isAmex = brand === 'americanExpress';
      scFeeOnTopService.setAmex(this.payment.isAmex);
      $rootScope.$broadcast('donationPage:recalculateFees');
      this.payment.lastFour = lastFour;
      if (!this.modeChanged) {
        this.form.token.$blur();
        if (brand) {
          this.modeChanged = true;
          this.form.$autofocus('expirationMonth');
        }
      }
      this.onCard({ brand: brand, lastFour: lastFour });
    };

    this.handleToken = function (token) {
      this.onToken(token);
    };

    this.changeMode = function (mode, animate) {
      var _this = this;

      this.mode = mode;
      if (animate) {
        if (mode === 'details') {
          $timeout(function () {
            _this.modeChanged = true;
            _this.form.$autofocus('expirationMonth');
          }, 400);
        } else {
          $timeout(function () {
            _this.form.$autofocus('token');
          }, 400);
        }
      }
    };

    this.handleReset = function () {
      this.modeChanged = false;
      this.errorVisible = false;
      this.errorMessage = '';
      this.numberInvalid = false;
      this.detailsInvalid = false;
      this.expirationDateInvalid = false;
      this.securityCodeInvalid = false;
      this.postalOrZipInvalid = false;
    };

    /* -------------------------------------------------------------------- *
     * View generators
     * -------------------------------------------------------------------- */

    this.brandIcon = function () {
      var html = void 0;
      switch (this.payment.brand) {
        case 'visa':
          html = $templateCache.get('global/classy-pay/svg/visa');
          break;
        case 'americanExpress':
          html = $templateCache.get('global/classy-pay/svg/amex');
          break;
        case 'discover':
          html = $templateCache.get('global/classy-pay/svg/discover');
          break;
        case 'masterCard':
          html = $templateCache.get('global/classy-pay/svg/mastercard');
          break;
        case 'diners':
          html = $templateCache.get('global/classy-pay/svg/diners-club');
          break;
        default:
          html = "<i class='cp-credit-card__brand-icon ci ci-credit-card'></i>";
          break;
      }
      return $sce.trustAsHtml(html);
    };

    this.codeName = function () {
      switch (this.payment.brand) {
        case 'masterCard':
        case 'maestro':
          return 'CVC';
        case 'americanExpress':
        case 'discover':
          return 'CID';
        default:
          return 'CVV';
      }
    };

    this.codePattern = function () {
      switch (this.payment.brand) {
        case 'americanExpress':
          return '^[0-9]{4}$';
        default:
          return '^[0-9]{3}$';
      }
    };

    this.zipName = function () {
      return this.payment.country === 'US' ? 'Zip' : 'Postal';
    };

    /* -------------------------------------------------------------------- *
     * Errors
     * -------------------------------------------------------------------- */

    $scope.$watch(function () {
      return _this2.payment.country;
    }, function () {
      _this2.form.$scValidityReporter.update();
    });

    /* Private
      ========================================================= */
    function updateErrorMessage(_event, alias, data) {
      if (alias !== this.formName) {
        return;
      }

      $rootScope.$broadcast('eg.donation-payment.is-valid', { isValid: data.valid });

      this.errorVisible = false;
      this.errorMessage = '';
      this.numberInvalid = false;
      this.detailsInvalid = false;
      this.expirationDateInvalid = false;
      this.securityCodeInvalid = false;
      this.postalOrZipInvalid = false;

      if (!this.shouldShowErrorMessage(data)) return;

      var uiError = data.error;

      this.errorVisible = true;

      // Card number is invalid, ensure we stay there
      if (_.size(uiError.token) || !this.payment.lastFour) {
        this.numberInvalid = true;
        this.errorMessage = 'Please enter a valid credit card number.';

        // It's possible to enter another number in the 400ms it takes to
        // transition to the details view, thus invalidating the card number,
        // So we transition to the number just to be sure.
        this.changeMode('number', false);

        return;
      }

      // Details are invalid, ensure we don't nav away
      this.detailsInvalid = true;

      if (_.get(uiError, 'expirationMonth.required') && _.get(uiError, 'expirationYear.required')) {
        this.errorMessage = 'Please enter an expiration date.';
        this.expirationDateInvalid = true;
      } else if (_.get(uiError, 'expirationMonth.scPattern') || _.get(uiError, 'expirationYear.scPattern')) {
        this.errorMessage = 'Please enter in MM/YY format.';
        this.expirationDateInvalid = true;
      } else if (_.get(uiError, 'expirationMonth.cpExpirationDate') || _.get(uiError, 'expirationYear.cpExpirationDate')) {
        this.errorMessage = 'Please enter a valid expiration date.';
        this.expirationDateInvalid = true;
      } else if (_.size(uiError.securityCode)) {
        this.errorMessage = 'Please enter a valid security code.';
        this.securityCodeInvalid = true;
      } else if (_.get(uiError, 'postalCode.required')) {
        if (this.payment.country === 'US') {
          this.errorMessage = 'Please enter a zip code.';
        } else {
          this.errorMessage = 'Please enter a postal code.';
        }
        this.postalOrZipInvalid = true;
      } else if (_.get(uiError, 'postalCode.minlength') || _.get(uiError, 'postalCode.maxlength')) {
        this.errorMessage = 'Please enter between 2 and 10 characters.';
        this.postalOrZipInvalid = true;
      }
    }

    this.shouldShowErrorMessage = function (validityData) {
      if (scDigitalWalletsService.isDigitalWalletSubmission) {
        return false;
      }
      var errorKeys = Object.keys(validityData.error);

      // if the form is not fully rendered, we won't show error messages yet
      if (errorKeys.length !== this.form.$$controls.length) return false;

      // reduce the error message objects into an array of keys indicating
      // how many individual errors there are
      var erroredFields = errorKeys.reduce(function (accum, key) {
        if (_.isEmpty(validityData.error[key])) return accum;
        return [].concat(_toConsumableArray(accum), [key]);
      }, []);

      // if no fields have any errors, just kick it out.
      if (!erroredFields.length) return false;

      // When the focus jumps from the token iframe to the expiration month we lose it for a
      // second, thus we don't show the error if we have one less errored field than the total
      // number of fields (discounting the token field, which handles it's own errors)
      if (validityData.uiValid && erroredFields.length >= errorKeys.length - 1) return false;

      // if they are still focused on a field, then don't show any error message at all
      for (var i = 0; i < errorKeys.length; i += 1) {
        var key = errorKeys[i];
        var $element = _.get(this.form, '[' + key + '].$$element');
        if ($element && $element.is(':focus')) return false;
      }

      return true;
    };
  }]
});
})();