(function(){
'use strict';

/**
 * @ngDoc service
 * @name scSvgUtils
 *
 * @description
 * General utilities for working with SVG and images.
 */

function scSvgUtils() {
  this.resizeRelative = function (itemDim, contDim, resizeMode, alignX, alignY) {
    // Vars: our goals
    var x = void 0,
        y = void 0,
        width = void 0,
        height = void 0;

    // Vars: friendlier item values
    var itemHeight = parseInt(itemDim.height, 10);
    var itemWidth = parseInt(itemDim.width, 10);

    // Vars: friendlier container values
    var contHeight = parseInt(contDim.height, 10);
    var contWidth = parseInt(contDim.width, 10);

    // 1. Determine aspect ratio for each block.
    var itemAspect = itemHeight / itemWidth;
    var contAspect = contHeight / contWidth;

    // 2. Determine if item is more portrait than container.
    var itemTaller = itemAspect > contAspect;

    // 3. Generate size based on mode.
    switch (resizeMode) {
      case 'cover':
        if (itemTaller) {
          width = contWidth;
          height = parseInt(width * itemAspect, 10);
        } else {
          height = contHeight;
          width = parseInt(height / itemAspect, 10);
        }

        break;
      case 'natural':
        height = parseInt(itemHeight * 0.107, 10);
        width = parseInt(itemWidth * 0.107, 10);
        break;
      case 'contain':
      default:
        if (itemTaller) {
          height = contHeight;
          width = parseInt(height / itemAspect, 10);
        } else {
          width = contWidth;
          height = parseInt(width * itemAspect, 10);
        }

        break;
    }

    // 4. Determine x positioning.
    switch (alignX) {
      case 'left':
        x = 0;
        break;
      case 'right':
        x = contWidth - width;
        break;
      case 'center':
      default:
        x = parseInt((contWidth - width) / 2, 10);
    }

    // 5. Determine y positioning.
    switch (alignY) {
      case 'top':
        y = 0;
        break;
      case 'bottom':
        y = contHeight - height;
        break;
      case 'middle':
      default:
        y = parseInt((contHeight - height) / 2, 10);
    }

    return {
      x: x,
      y: y,
      width: width,
      height: height
    };
  };
}

angular.module('classy').service('scSvgUtils', scSvgUtils);
})();