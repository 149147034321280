(function(){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/* EmbeddedGivingUtil: houses misc business logic */
angular.module('classy').service('EmbeddedGivingUtil', ["EG_EVENTS", "$window", function (EG_EVENTS, $window) {
  var _this = this;

  this.parseFroalaText = function (text) {
    return text ? text.split('<p>').join('').split('</p>').join('') : text;
  };

  this.getPrimaryColorFromTheme = function (theme) {
    var color = _.get(theme, 'current.styles.primaryColor');
    if (!color) {
      color = 'rgba(0,0,0,1.0)'; // default to black
    }
    return color;
  };

  /* Primary color can be in 2 diff formats: 'rgba' or 'rgb' (string)
   * Background color is calculated by changing opacity of primary color to .12 */
  this.getBackgroundColorFromTheme = function (theme) {
    var primaryColor = _this.getPrimaryColorFromTheme(theme);
    var bgOpacity = '.12';
    var bgColor = 'rgba(0,0,0,' + bgOpacity + ')'; // set opacity to .12

    if (primaryColor) {
      // if format is rgba, then 4 params present, need to replace last ('a')
      if (primaryColor.indexOf('rgba') !== -1) {
        var colorTokens = primaryColor.split(',');
        colorTokens[colorTokens.length - 1] = bgOpacity + ')';
        bgColor = colorTokens.join(',');
      } else if (primaryColor.indexOf('rgb') !== -1) {
        // else check if rgb is present.
        // if is but no 4th param, then we add 4th param (instead of replace)
        var _colorTokens = primaryColor.split(')');
        _colorTokens[_colorTokens.length - 1] = bgOpacity + ')';
        bgColor = _colorTokens.join(',');
      }
    }
    return bgColor;
  };

  // all these nested properties are a nightmare...
  this.getLogoUrl = function (page, theme) {
    var logo = null;
    var headerBlock = _.find(page.block_sections, { name: 'header' });

    if (headerBlock && headerBlock.blocks) {
      headerBlock = headerBlock.blocks[0];
    }

    // first try to get from header block
    if (_.get(headerBlock, 'logo.src') || _.get(headerBlock, 'logo.asset.src')) {
      logo = _.get(headerBlock, 'logo.src') || _.get(headerBlock, 'logo.asset.src');
    } else if (_.get(theme, 'current.styles.headerLogo.src')) {
      // keep for reverse compatibility
      logo = theme.current.styles.headerLogo.src;
    }

    return logo;
  };

  this.getPaymentTypeFromCheckoutResponse = function (res) {
    var type = '';

    if ((res.payment_gateway === 'Dwolla' || res.payment_gateway === 'Stripe') && type.account_number) {
      type = 'ACH';
    } else if (res.payment_gateway === 'BrainTree' && !res.card_last_four) {
      type = 'PayPal';
    } else if (res.card_last_four) {
      type = 'Credit Card';
    } else if (res.payment_gateway === 'Classy Pay' && res.payment_source === 'PAYPAL') {
      type = 'PayPal Commerce';
    }

    return type;
  };

  this.getDonationAmountMax = function (block) {
    var levels = block && block['donation-levels'];
    var maxLevelAmount = 0;

    if (levels && levels.length) {
      _.forEach(levels, function (l) {
        if (l.display_on_page && l.amount != 'Custom' && l.amount > maxLevelAmount) {
          maxLevelAmount = l.amount;
        }
      });
    }

    return maxLevelAmount;
  };

  this.getDonationAmountMin = function (block) {
    var levels = block && block['donation-levels'];
    var minLevelAmount = 999999999999999999999;

    if (levels && levels.length) {
      _.forEach(levels, function (l) {
        if (l.display_on_page && l.amount != 'Custom' && l.amount < minLevelAmount) {
          minLevelAmount = l.amount;
        }
      });
    }

    return minLevelAmount;
  };

  // This is very defensive. We don't trust any of this data
  // TODO: refactor and simplify this. Also migrate to to cpIntelligentAsk.js
  this.getIntelligentAskEventData = function (campaign, model) {
    var savedState = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : { oneTime: [], recurring: [] };
    var iaaAmounts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

    var data = {};
    if (_.isEmpty(campaign)) {
      return data;
    } else {
      // Set base data properties
      data = {
        organizationId: SC.organization.id,
        campaignId: SC.campaign.id,
        campaignType: SC.campaign.type,
        eg: campaign.getEmbeddedGiving()
      };

      // Get $scope.MODEL data
      if (!_.isEmpty(model)) {
        // default donation amount
        if (model.items && model.items.length) {
          data.default_value = model.items[0].raw_final_price;
        }
        // currency code
        if (model.payment && model.payment.raw_currency_code) {
          data.currencyCode = model.payment.raw_currency_code;
        }
        // dcf enabled
        data.dcf_enabled = !!model.fee_on_top;
      }
      // Get data from "saved" state (before iaa was initialized and took over the donation amounts grid)
      // hmm. this won't be correct for thank-you
      if (savedState.defaultFrequency) {
        data.default_frequency = savedState.defaultFrequency;
      }
      if (savedState.urlAmount) {
        data.url_amount_used = savedState.urlAmount;
      }
      // Get other optional state params
      // donation amount, on "checkout" and "thank-you" events
      if (savedState.amount) {
        data.amount = savedState.amount;
      }
      if (savedState.transactionId) {
        data.transactionId = savedState.transactionId;
      }
      if (savedState.frequency) {
        data.frequency = savedState.frequency;
      }
      // Onetime amounts
      if (savedState.oneTime && savedState.oneTime.length) {
        var amounts = [];
        angular.forEach(savedState.oneTime, function (level) {
          amounts.push(level.amount);
        });
        if (amounts && amounts.length) {
          data.amounts_onetime = amounts.toString();
        }
      }
      // Recurring amounts
      if (savedState.recurring && savedState.recurring.length) {
        var rAmounts = [];
        angular.forEach(savedState.recurring, function (level) {
          rAmounts.push(level.amount);
        });
        if (rAmounts && rAmounts.length) {
          data.amounts_recurring = rAmounts.toString();
        }
      }

      // Get Intelligent Ask Amounts event data
      data.iaa_enabled_onetime = campaign.hasIntelligentAskOneTime(); // is onetime enabled
      data.iaa_enabled_recurring = campaign.hasIntelligentAskRecurring(); // is recurring enabled
      data.iaa_amount_onetime = iaaAmounts.oneTime; // onetime fallback
      data.iaa_amount_recurring = iaaAmounts.recurring; // recurring fallback
      // Parse IAA amounts (used for donation grid) safely
      iaaAmounts.grid = iaaAmounts.grid || {};
      data.iaa_amounts_onetime = (iaaAmounts.grid.oneTime || []).toString();
      data.iaa_amounts_recurring = (iaaAmounts.grid.recurring || []).toString();

      return data;
    }
  };

  this.getAnalyticsEventData = function (opts) {
    var analyticsData = {
      eg: true,
      organizationId: SC.organization.id,
      // paymentProcessor, set to crypto? TODO
      campaignType: SC.campaign.type,
      campaignId: SC.campaign.id
      // donationTarget??
    };

    if (!_.isEmpty(opts)) {
      // donation amount
      if (opts.items && opts.items.length) {
        analyticsData.donationAmount = opts.items[0].raw_final_price;
      }

      if (opts.payment && opts.payment.raw_currency_code) {
        analyticsData.currencyCode = opts.payment.raw_currency_code;
      }
    }

    return analyticsData;
  };

  this.getGTMEventLabelMapper = function () {
    var egEvents = _.values(EG_EVENTS);
    var res = {};

    var egEvent = void 0;
    for (var i = 0; i < egEvents.length; i += 1) {
      egEvent = egEvents[i];

      _.forEach(egEvent, function (eventName) {
        res[eventName] = eventName;
      });
    }

    return res;
  };

  this.getFormErrorCount = function (form) {
    var errorList = [];
    if (form && form.$$controls && form.$$controls.length) {
      form.$$controls.forEach(function (control) {
        if (control.$$controls && control.$$controls.length) {
          // if a control is another form, let's get the errors in that form
          errorList.push.apply(errorList, _toConsumableArray(_this.getFormErrorCount(control)));
        } else if (control.$invalid) {
          errorList.push({
            $name: control.$name.replace(/_/g, ' '),
            $element: control.$$element
          });
        }
      });
    }
    return errorList;
  };

  this.hasDoubleTheDonation = function () {
    return SC && SC.organization && SC.organization.has_double_the_donation_employer_match;
  };

  /**
   * When submitting a digital wallets donation, we need to ignore
   * invlaid credit card field errors, and address autocomplete errors.
   *
   * @param {Array} errList list of errors
   * @returns {Boolean} whether all errors can be ignored for DW donation
   */
  this.hasValidDigitalWalletErrors = function (errList) {
    var numOfErrsToIgnore = 0;

    if (errList.find(function (e) {
      return e.$name === 'credit card';
    })) {
      numOfErrsToIgnore += 1;
    }

    if (errList.find(function (e) {
      return e.$name === 'billing address auto';
    })) {
      numOfErrsToIgnore += 1;
    }

    if (errList.find(function (e) {
      return e.$name === 'honoree first name';
    })) {
      numOfErrsToIgnore += 1;
    }

    if (errList.find(function (e) {
      return e.$name === 'honoree last name';
    })) {
      numOfErrsToIgnore += 1;
    }

    return errList.length === numOfErrsToIgnore;
  };

  this.isInIframe = function () {
    var isIframed = $window.parent && $window.location !== $window.parent.location;
    return isIframed;
  };

  this.isIframeWhitelisted = function (campaign) {
    if (!campaign || !campaign.hasWhitelistedUrl()) {
      return false;
    }

    if (!_this.isInIframe()) {
      return false;
    }

    var parentOrigin = $window.parent.location && $window.parent.location.origin;
    return campaign.hasUrlWhitelisted(parentOrigin);
  };
}]);
})();