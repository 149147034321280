(function(){
'use strict';

angular.module('classy').component('formError', {
  template: '\n      <p class="form__error" aria-live="polite" role="alert"\n      sc-validity-message="::$ctrl.name"\n      messages="::$ctrl.messages"></p>\n    ',
  bindings: {
    name: '<',
    messages: '<'
  }
});
})();