(function(){
'use strict';

/**
 * @kind directive
 * @name scDateTimezoneField
 *
 * @description
 * Displays a date field with a time.
 *
 * NOTE: Only displays validation errors for the date field.
 *
 * @param {string} label Field label
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {boolean|expression} required Determines whether the field is required
 * @param {boolean|expression} disabled Determines whether the field is disabled
 * @param {expression} dateOptions Set of options for pick-a-date and pick-a-time
 *
 * @param {object} check Object describing the 'check' component:
 *
 *  | Property | Type | Description |
 *  |--|--|--|
 *  | enabled | boolean | Show an 'OK' checkmark if check.condition is met. |
 *  | condition | expression | Condition which must be met for the check to appear. |
 *
 * @param {object} date Object describing the 'date' component:
 *
 *  | Property | Type | Description |
 *  |--|--|--|
 *  | model | expression | Model for the date control |
 *  | fieldName | string | 'name' attribute for the date control |
 *  | viewModel | expression | (Optional) A 'dummy model' passed to
 *    ng-model for purposes of validation |
 *
 * @param {object} time Object describing the 'time' component:
 *
 *  | Property | Type | Description |
 *  |--|--|--|
 *  | model | expression | Model for the time control |
 *  | fieldName | string | 'name' attribute for the time control |
 *
 * @param {object} compare Object describing date comparison behavior:
 *
 *  | Property | Type | Description |
 *  |--|--|--|
 *  | enabled | boolean | If true, scAfterDate will be used compare the provided date values.
 *    If compare.start is greater than compare.end, the 'afterStart' validator will be false.
 *  | start | expression | The minimum datetime. |
 *  | end | expression | The maximum datetime. |
 *
 * @param {array} checkboxes Checkboxes to be rendered. See checkboxes documentation for more
 * @param {array} errors Errors to be rendered with the field. See errors documentation for more
 */

scDateTimezoneField.$inject = ["scField", "accessibilityService"];
function scDateTimezoneField(scField, accessibilityService) {
  return scField('scDateTimezoneField', {
    link: function link(scope) {
      scope.$watch('options.timezone.startCondensed', function (newVal) {
        scope.ui = {
          collection: newVal ? 'condensed' : 'all'
        };
      });
      scope.onKeyDown = function (event) {
        if (event && accessibilityService.isValidKeyBoardEvent(event)) {
          if (scope.ui.collection === 'condensed') scope.ui.collection = 'all';else if (scope.ui.collection === 'all') scope.ui.collection = 'condensed';
        }
      };
    }
  });
}

angular.module('classy').directive('scDateTimezoneField', scDateTimezoneField);
})();