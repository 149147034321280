(function(){
'use strict';

angular.module('classy').component('facebookFundraiser', {
  template: '\n  \n  <div class="supporter-dashboard_overview-cards" data-ng-if="$ctrl.isFBPageCreated">\n  <div class="fundraiser-dashboard_facebook-fundraiser-block">\n    <div\n      class="fundraiser-dashboard_facebook-background"\n      data-sc-block-bg="pageBlock.current.background"\n      data-public="true"\n      data-fallback-color="theme.current.styles.background.color"\n      data-fallback-img="theme.current.styles.background.image"\n      data-theme="theme"\n      data-fallback-img="theme.current.styles.background.image">\n    </div>\n    <div class="fundraiser-dashboard_facebook-fundraiser-block-detail">\n      <div class="fundraiser-dashboard_facebook-fundraiser-block-title">\n        {{$ctrl.fundraisingPageData.alias}}\n      </div>\n      <div\n        class="fundraiser-dashboard_facebook-fundraiser-block-description"\n        data-ng-bind="$ctrl.intro_text">\n      </div>\n      <div class="fundraiser-dashboard_facebook-fundraiser-block-activity-detail">\n        <div class="fundraiser-dashboard_facebook-fundraiser-block-activity">\n          <div>Last Activity</div>\n          <div>{{$ctrl.fbFundraisingPageData.updated_at | date : "y-MM-dd"}}</div>\n        </div>\n        <div>\n          <a ng-href="{{$ctrl.viewURL}}"\n            target="_blank"\n            class="fundraiser-dashboard_facebook-fundraiser-block-view"\n            aria-label="View Facebook fundraising page">\n            View\n          </a>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n',
  controller: ["scFundraisingPagesService", "$scope", "scBlocksService", "scThemesService", "CHANNEL", function controller(scFundraisingPagesService, $scope, scBlocksService, scThemesService, CHANNEL) {
    this.$onInit = function () {
      var _this = this;

      this.isFBPageCreated = false;
      if (SC.organization.facebookChannel.status) {
        scFundraisingPagesService.active.getChannelFundraisingPage(CHANNEL.FB).then(function (response) {
          _this.fbFundraisingPageData = response.data[0];
          _this.fundraisingPageData = response.data[1];
          _this.isFBPageCreated = true;
          _this.viewURL = 'https://www.facebook.com/donate/' + _this.fbFundraisingPageData.external_fundraising_page_id;
          _this.intro_text = _this.fundraisingPageData.intro_text.replace(/<\/?p>/g, '');
          $scope.pageBlock = scBlocksService.getBlockByType('fundraiser');
          $scope.theme = scThemesService.active;
        }).catch(function (_error) {
          _this.isFBPageCreated = false;
        });
      }
    };
  }]
});
})();