(function(){
'use strict';

/**
 * @kind directive
 * @name scSpectrum
 * @scope
 *
 * @description
 * Renders a color swatch and color picker that saves to the scope property
 * passed to the directive.
 *
 * Used in:
 * Appv2
 *
 * @param {number} scSpectrum The value to test against.
 */

scSpectrum.$inject = ["$window", "scQaId", "$stateParams", "scCampaignsTemplateService", "accessibilityService"];
function scSpectrum($window, scQaId, $stateParams, scCampaignsTemplateService, accessibilityService) {
  'use strict';

  return {
    replace: true,
    template: "<div class='sc-spectrum' data-ng-class='{visible: pickerVisible}' data-ng-model='color'>" + '<span class=\'sc-spectrum_swatch\' role="button" tabindex="0" qa-id=\'{{ scQaId.colorPicker }}\' data-ng-class=\'{"admin-body__lock-field":lockField}\' data-ng-style=\'{ "background-color": backgroundColor, "width": swatchSize + "px", "height": swatchSize + "px" }\' data-ng-click=\'lockField ? $event.preventDefault() : pickerVisible = !pickerVisible\' data-ng-keydown=\'onKeyDown($event)\'>' + '<div style="top:0" data-ng-if="lockField" data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg" data-sc-tooltip-position="center" data-sc-tooltip-position-tip="bottom center" fixed-tooltip="true" show-event="\'mouseenter\'" hide-event="\'mouseout\'" data-sc-tooltip="\'This field is locked at the template level.\'" class="admin-body__tooltip-element">{{lockField}}</div>' + '</span>' + '<div class=\'sc-spectrum_container\' data-ng-style=\'::{ "top": containerTop + "px" }\'>' + "<div class='sc-spectrum_picker'>" + '</div>' + '</div>' + '</div>',
    scope: {
      color: '=scSpectrum',
      fallbackColor: '=',
      footerBgColor: '=?',
      aboutBgColor: '=?',
      donateButtonColor: '=?',
      locationBgColor: '=?',
      lockField: '=?'
    },

    link: function link(scope, element, attrs) {
      // NgModelController of the ng-model on the element.
      // Needed for triggering form events and flags such as $dirty by calling
      // $setViewValue when the color changes.
      var model = element.controller('ngModel');
      scope.scQaId = scQaId;
      scope.backgroundColor = scope.color || scope.fallbackColor;

      this.getLockedField = function (type) {
        var lockedBlock = scCampaignsTemplateService.getBlockByType(type);
        var fieldName = attrs.scSpectrum.split('.').splice(-1)[0];
        var lock = void 0;
        if (attrs.scSpectrum.includes('block.background.color')) {
          lock = lockedBlock.item_attributes.background.color && lockedBlock.item_attributes.background.color.locked;
          // Also check if it is locked at background level.
          lock = lock || lockedBlock.item_attributes.background.locked;
        } else {
          lock = lockedBlock.item_attributes[fieldName] && lockedBlock.item_attributes[fieldName].locked;
        }
        return lock;
      };

      if (typeof scope.lockField === 'undefined' && SC.campaign.campaignTemplateData) {
        scope.lockField = false;
        var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
        if (blockObj && blockObj.type !== 'text') {
          scope.lockField = this.getLockedField(blockObj.type);
        }
      }

      if (_.isUndefined(attrs.colorFormat)) {
        attrs.colorFormat = 'hex';
      }

      if (_.isUndefined(attrs.showAlpha)) {
        attrs.showAlpha = false;
      } else {
        attrs.showAlpha = attrs.showAlpha == 'true';
      }

      if (_.isUndefined(attrs.removeButton)) {
        attrs.removeButton = false;
      } else {
        attrs.removeButton = attrs.removeButton == 'true';
      }

      var $picker = element.find('.sc-spectrum_picker');

      scope.swatchSize = parseInt(attrs.swatchSize, 10) || 50;
      scope.containerTop = Math.floor(scope.swatchSize / 2) - 24;

      scope.pickerVisible = false;

      function updateModel(color) {
        if (scope.pickerVisible) {
          model.$setViewValue(color);
          scope.$apply(function () {
            if (color) {
              scope.color = color.toRgbString();
              scope.footerBgColor = color.toRgbString();
              scope.aboutBgColor = color.toRgbString();
              scope.donateButtonColor = color.toRgbString();
              scope.locationBgColor = color.toRgbString();
            }
            scope.backgroundColor = scope.color || scope.fallbackColor;
          });
        }
      }

      var spectrumOptions = {
        allowEmpty: attrs.removeButton,
        appendTo: element.parent(),
        showButtons: false,
        showInput: true,
        preferredFormat: attrs.colorFormat,
        showAlpha: attrs.showAlpha,
        flat: true,
        move: updateModel,
        change: updateModel,
        color: scope.backgroundColor
      };

      $picker.spectrum(spectrumOptions);
      if (attrs.removeButton) {
        var $container = element.find('.sp-picker-container');
        $container.addClass('remove-enabled');
        var $input = element.find('.sp-input-container');
        $input.after("<div class='sp-remove-button'>" + "<i class='ci ci-close-x sp-button-icon' data-ng-click='true'></i>" + '</div>');
      }

      scope.$watch('pickerVisible', function () {
        if (scope.pickerVisible) {
          $picker.spectrum('reflow');
        }
      });

      /* -------------------------------------------------------------- *
       * Close on outside click
       * -------------------------------------------------------------- */

      function watchClick(e) {
        if (scope.pickerVisible && !jQuery(e.target).closest(element).length) {
          scope.$apply(function () {
            scope.pickerVisible = false;
          });
        }
      }

      function watchEnter(e) {
        if (e.keyCode === 13 && scope.pickerVisible && jQuery(e.target).closest(element).length) {
          scope.$apply(function () {
            scope.pickerVisible = false;
          });
          e.stopPropagation();
          e.preventDefault();
          return false;
        }

        return undefined;
      }

      $window.addEventListener('click', watchClick);
      $window.addEventListener('keypress', watchEnter);

      scope.$on('$destroy', function () {
        $window.removeEventListener('click', watchClick);
        $window.removeEventListener('keypress', watchEnter);
      });

      scope.$watch('color', function () {
        scope.backgroundColor = scope.color || scope.fallbackColor;
      });

      scope.onKeyDown = function ($event) {
        if ($event && accessibilityService.isValidKeyBoardEvent($event)) scope.pickerVisible = !scope.pickerVisible;else if ($event.keyCode === 27) scope.pickerVisible = false;
      };
    }
  };
}

angular.module('classy').directive('scSpectrum', scSpectrum);
})();