(function(){
'use strict';

donationAsideCtrl.$inject = ["$scope", "scCampaignsService"];
function donationAsideCtrl($scope, scCampaignsService) {
  $scope.campaign = scCampaignsService.active.current;
  $scope.faqs = _.get(scCampaignsService, 'active.current.faqs.data', []);
}

angular.module('classy').controller('donationAsideCtrl', donationAsideCtrl);
})();