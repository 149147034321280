(function(){
'use strict';

/**
 * @name scMatches
 * @kind directive
 * @requires ngModel
 *
 * @description
 * Validator that passes if the value of the control on which it is placed
 * matches exactly the value of the control passed to the directive. If either
 * value is null, the validator returns true.
 */

/**
 * @private
 * @returns {Object}
 */
function scMatchesDirective() {
  return {
    require: 'ngModel',
    scope: {
      comparee: '=scMatches'
    },
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.match = function (modelValue, viewValue) {
        var value = modelValue || viewValue;
        var compareeValue = scope.comparee.$modelValue || scope.comparee.$viewValue;
        return value && compareeValue ? value === compareeValue : true;
      };
      scope.$watch('comparee.$viewValue', function () {
        ngModelCtrl.$validate();
      });
    }
  };
}

angular.module('classy').directive('scMatches', scMatchesDirective);
})();