(function(){
'use strict';

donationMainCtrl.$inject = ["$scope", "$location", "DonationService", "$timeout", "scCampaignsService", "scFundraisingTeamsService", "scMembersService", "scOrganizationsService", "$window", "$rootScope", "$state", "$stateParams", "scGetQueryParams", "scFroalaOptions", "scCampaignModel", "scQaId"];
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function donationMainCtrl($scope, $location, DonationService, $timeout, scCampaignsService, scFundraisingTeamsService, scMembersService, scOrganizationsService, $window, $rootScope, $state, $stateParams, scGetQueryParams, scFroalaOptions, scCampaignModel, scQaId) {
  // Check if edit/design mode
  var currentState = _.get($state, 'current.name', '');
  $scope.edit = false;
  if (currentState.indexOf('design') > -1 || currentState.indexOf('manage') > -1) {
    $scope.edit = true;
  }

  $scope.scQaId = scQaId;

  $scope.froalaOptionsPreview = scFroalaOptions({ type: 'edit-inline' });

  // Default for edit/design modes
  SC.donation = SC.donation || {};

  // Adwords
  if (!$scope.demo) {
    var optionalQs = _.get(scCampaignsService.active.current.questions, 'optional', []);
    $scope.optionalQs = {};
    optionalQs.forEach(function (q) {
      if (q.location == 'donation_page') {
        $scope.optionalQs[q.tag] = q;
      }
    });

    $scope.primaryPhone = { required: _.get($scope.optionalQs, 'homephone.is_required', false) };

    var organization = scOrganizationsService.active;
    $scope.show = {
      mgive: function mgive() {
        return !!organization.current.mgive_settings && $scope.optionalQs.cellphone && $scope.optionalQs.text_opt_in;
      }
    };
  }

  $scope.donateDoubleLoaded = false;
  $scope.feeCalculating = false;

  if (typeof scCampaignsService.active === 'undefined' && typeof SC.campaign !== 'undefined') {
    scCampaignsService.add(SC.campaign);
    scCampaignsService.setActive($stateParams.campaignId);
    $scope.campaign = scCampaignsService.active;
  } else if (typeof scCampaignsService.active === 'undefined') {
    $scope.campaign = new scCampaignModel({
      type: $stateParams.campaignType,
      organization_id: $state.params.organizationId
    });
  } else {
    $scope.campaign = scCampaignsService.active;
  }

  $scope.members = scMembersService;
  $scope.you = scMembersService.you;

  $scope.$watch('campaign.current.classy_mode_enabled', function (newVal) {
    $scope.campaign.current.classy_mode_enabled = newVal;
  });

  $scope.organization = scOrganizationsService.active.current;
  $scope.organization.mgive = _.get($scope.organization, 'mgive_settings', false);

  var mergeDonationSettings = function mergeDonationSettings() {
    $scope.you = scMembersService.you;

    // By default, opt in to receiving contact emails on donation page
    return _.merge(SC.donation, {
      isPayPal: SC.organization.payment_provider_name === null || SC.organization.payment_provider_name === 'Paypal External',
      isWePay: SC.organization.payment_provider_name === 'WePay',
      isAuthorize: SC.organization.payment_provider_name === 'Authorize.net DPM',
      name: {
        first: $scope.you.current.first_name,
        last: $scope.you.current.last_name
      },
      phone: $scope.you.current.phone,
      email: $scope.you.current.email_address,
      payment: {
        name: {},
        address: $scope.you.current.address1,
        address2: $scope.you.current.address2,
        city: $scope.you.current.city,
        zip: $scope.you.current.postal_code,
        country: $scope.you.current.country,
        state: $scope.you.current.state
      },
      dedication: {},
      optIn: $scope.campaign.current.opt_in_checked_by_default,
      canadian: SC.organization.country == 'CA'
    });
  };

  SC.donation = mergeDonationSettings();

  // Rebuild menu when member ID/login status changes

  $scope.$watch(function () {
    return _.get(scMembersService, 'active.current.id');
  }, function (newVal, oldVal) {
    if (newVal === oldVal) return;
    var loggedIn = !!newVal;

    if (loggedIn) {
      $scope.Donation = mergeDonationSettings();
    }
  });

  SC.zipOnly = false;

  $scope.emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Stubs for dev

  if ($location.host() === 'classy.local' || $location.host() === 'localhost') {
    _.merge(SC.donation, {
      name: {
        first: 'Dev',
        last: 'Eloper'
      },
      email: 'deloper@classy.org',
      payment: {
        card: {
          num: '4444333322221111',
          cvv: '123'
        },
        address: '123',
        city: 'San Diego',
        state: 'CA',
        zip: '92101'
      }
    });
  }

  SC.availableCardTypes = ['Visa', 'MasterCard'];

  if (SC.organization.currency != 'CAD') {
    SC.availableCardTypes.push('American Express');
    SC.availableCardTypes.push('Discover');
  }

  // end stubs

  $scope.SC = SC;
  $scope.Donation = SC.donation;
  $scope.Fundraiser = SC.fundraiser || {};
  $scope.Team = SC.team || {};
  $scope.Charity = SC.organization;

  $scope.Donation.fee_on_top = $scope.campaign.current.classy_mode_checked_by_default;

  if (!_.isEmpty(SC.team) && _.isEmpty($scope.team)) {
    scFundraisingTeamsService.add(SC.team);
    scFundraisingTeamsService.setActive(SC.team.id);
    $scope.team = scFundraisingTeamsService.active.current;
  }

  $scope.Donation.payType = 'direct';

  /*
   * Canadian defaults
   */
  if ($scope.Charity.currency && $scope.Charity.currency.toUpperCase() == 'CAD') {
    $scope.Donation.dedication.country = 'CA';
    $scope.Donation.payment.country = 'CA';
  }

  $scope.Donation.dedication.emailImg = null;

  /*
   * Set defaults based on URL params
   */
  var urlParams = scGetQueryParams();
  var returnUrl = urlParams.return_url ? urlParams.return_url.replace(/~2F/g, '/') : '';

  $scope.Donation.amount = parseFloat(urlParams.amount || $scope.Donation.amount);
  $scope.Donation.recurring = '0';
  $scope.Donation.name.first = urlParams.first || $scope.Donation.name.first;
  $scope.Donation.name.last = urlParams.last || $scope.Donation.name.last;
  $scope.Donation.email = urlParams.email || $scope.Donation.email;
  $scope.Donation.phone = urlParams.phone || $scope.Donation.phone;
  $scope.Donation.payment.address = urlParams.street || $scope.Donation.payment.address;
  $scope.Donation.payment.city = urlParams.city || $scope.Donation.payment.city;
  $scope.Donation.payment.state = urlParams.state || $scope.Donation.payment.state;
  $scope.Donation.payment.zip = urlParams.zip || $scope.Donation.payment.zip;
  $scope.returnUrl = returnUrl || $scope.returnUrl;

  $scope.buttonPresets = {
    preset1: urlParams.preset1,
    preset2: urlParams.preset2,
    preset3: urlParams.preset3,
    preset4: urlParams.preset4
  };

  var presetCleaner = void 0;
  _.forEach($scope.buttonPresets, function (preset, key) {
    presetCleaner = preset;
    if (presetCleaner instanceof Array) {
      presetCleaner = presetCleaner[0];
    }
    if (presetCleaner) {
      presetCleaner = presetCleaner.replace(/[^\d]/g, '');
      presetCleaner = parseInt(presetCleaner, 10);
      if (presetCleaner < 1) {
        presetCleaner = undefined;
      }
      if (presetCleaner > 10000000) {
        presetCleaner = 10000000;
      }
    }
    $scope.buttonPresets[key] = presetCleaner;
  });

  $scope.displayFundraiserDetails = false;
  $scope.fundraisers = [];
  $scope.selectedFundraiser = {
    obj: null
  };

  $scope.invalidSearch = false;

  $scope.isIndividualCampaign = function () {
    if (!_.isEmpty($scope.Fundraiser)) {
      $scope.selectedFundraiser.obj = $scope.Fundraiser;
      $scope.Fundraiser.photo = _.get($scope.Fundraiser, 'logo.cdn_url', '/static/global/images/individual_default_image.png');
      $scope.displayFundraiserDetails = true;
      return true;
    }

    return false;
  };

  $scope.isTeamCampaign = function () {
    if (!_.isEmpty($scope.Team)) {
      $scope.displayFundraiserDetails = true;
      $scope.Team.photo = _.get($scope.Team, 'logo.cdn_url', '/static/global/images/team_default_image.png');
      return true;
    }

    return false;
  };

  /*
   * Initialize selectedFundraiser for team page
   */
  $scope.initFundraiser = function () {
    if ($scope.isTeamCampaign()) {
      $scope.selectedFundraiser.obj = $scope.Team;
      $scope.updated_percent_to_goal = $scope.Team.percent_to_goal;
    }
  };

  // These only account for campaign donation pages or team donation pages;
  // individual fundraiser donation pages should not display the UI to
  // select a creditee.
  $scope.crediteeSearchDefault = function () {
    if ($scope.isTeamCampaign()) {
      return $scope.Team;
    }
    return null;
  };

  $scope.searchTerms = [{
    service: 'scFundraisingPageSearchModel',
    fields: ['alias'],
    queryParams: {
      aggregates: true,
      filter: 'status=active'
    }
  }];
  if (!$scope.isTeamCampaign()) {
    $scope.searchTerms.push({
      service: 'scFundraisingTeamSearchModel',
      fields: ['name'],
      queryParams: {
        aggregates: true,
        filter: 'status=active'
      }
    });
  }

  $scope.searchContext = function () {
    var context = {};
    if (!$scope.isTeamCampaign()) {
      context.campaign_id = $scope.campaign.current.id;
    } else {
      context.fundraising_team_id = $scope.Team.id;
    }
    return context;
  }();

  $scope.crediteeSearchTeamId = function () {
    if ($scope.isTeamCampaign()) {
      return $scope.Team.id;
    }
    return null;
  };

  $scope.newTargetDonation = {
    obj: null
  };

  $scope.Donation.urlParam = {
    cid: scOrganizationsService.active.current.id, // SC.organization.id,
    eid: scCampaignsService.active.current.id, // SC.eid,
    fcid: $scope.Fundraiser.id,
    ftid: $scope.Team.id // SC.ftid
  };

  // Change the entity we are donating to on team/general campaign pages
  $scope.$watch('newTargetDonation.obj', function () {
    if (_.isObject($scope.newTargetDonation.obj)) {
      $scope.selectedFundraiser = _.cloneDeep($scope.newTargetDonation);

      if ($scope.newTargetDonation.obj.team_lead_id) {
        $scope.selectedFundraiser.obj.photo = _.get($scope.newTargetDonation.obj, 'logo.cdn_url', '/static/global/images/individual_default_image.png');
      } else {
        $scope.selectedFundraiser.obj.photo = _.get($scope.newTargetDonation.obj, 'logo.cdn_url', '/static/global/images/team_default_image.png');
      }

      if ($scope.Donation.amount) {
        var newRaised = $scope.Donation.amount + $scope.selectedFundraiser.obj.total_raised;
        $scope.updated_percent_to_goal = newRaised / $scope.selectedFundraiser.obj.goal * 100;
      } else {
        $scope.updated_percent_to_goal = $scope.selectedFundraiser.obj.percent_to_goal;
      }
    }

    // Remove fundraiser details from general campaign page if user
    // does not want to target donation to specific team/fundraiser
    if (_typeof($scope.selectedFundraiser.obj) === 'object' && _typeof($scope.newTargetDonation.obj) === 'object') {
      $scope.displayFundraiserDetails = true;
    }

    if (_.isObject($scope.newTargetDonation.obj)) {
      $scope.selectedFundraiser = _.cloneDeep($scope.newTargetDonation);

      if (_.isEmpty($scope.newTargetDonation.obj)) {
        $scope.displayFundraiserDetails = false;
        if (!_.isEmpty($scope.Team)) {
          $scope.selectedFundraiser.obj = $scope.Team;
        }
      }
    }

    var teamSelected = _.get($scope.selectedFundraiser, 'obj.team_lead_id', false);

    if (_.isEmpty($scope.newTargetDonation.obj)) {
      $scope.displayFundraiserDetails = false;
      $scope.Donation.urlParam = {
        cid: SC.organization.id, // SC.organization.id,
        eid: $scope.campaign.current.id, // SC.eid,
        fcid: $scope.Fundraiser.id,
        ftid: $scope.Team.id // SC.ftid
      };
    }

    // Remove fundraiser details from general campaign page if user
    // does not want to target donation to specific team/fundraiser
    if (_.isObject($scope.selectedFundraiser.obj)) {
      if (_.isObject($scope.newTargetDonation.obj) || teamSelected && $scope.selectedFundraiser.obj.id === $scope.Team.id || !teamSelected && $scope.selectedFundraiser.obj.id === $scope.Fundraiser.id) {
        $scope.displayFundraiserDetails = true;
      }
    }

    // Donate to individual fundraiser
    if (_.isObject($scope.newTargetDonation.obj) && _.isEmpty($scope.Fundraiser) && !_.get($scope.newTargetDonation, 'obj.team_lead_id')) {
      $scope.Donation.urlParam = {
        cid: undefined,
        eid: undefined,
        fcid: $scope.newTargetDonation.obj.id,
        ftid: undefined
      };
    } else if (_.isObject($scope.newTargetDonation.obj) && _.isEmpty($scope.Fundraiser) && _.get($scope.newTargetDonation, 'obj.team_lead_id')) {
      // Donate to team
      $scope.Donation.urlParam = {
        cid: undefined,
        eid: undefined,
        fcid: undefined,
        ftid: $scope.newTargetDonation.obj.id
      };
    }
  }, true);

  $scope.willReachGoal = function () {
    return $scope.updated_percent_to_goal >= 100;
  };

  $scope.goalReached = function () {
    return $scope.selectedFundraiser.obj.percent_to_goal >= 100;
  };

  /*
   * Amount changed
   */
  $scope.amountChanged = function () {
    if (!angular.isUndefined($scope.Donation.amount)) {
      $scope.Donation.amountFloat = parseFloat($scope.Donation.amount);
      $scope.Donation.amountChanged = true;

      // Change progress bar display depending on donation amount selected
      //  (only if specific fundraiser or team targeted)
      if ($scope.selectedFundraiser.obj !== null) {
        var raised = $scope.selectedFundraiser.obj.total_raised;
        var newTotal = raised + $scope.Donation.amount;
        var goal = $scope.selectedFundraiser.obj.goal;
        var exactPercent = newTotal / goal;

        // FRS-2117: 99.9% != goal reached
        if (exactPercent > 0.99 && exactPercent < 1) {
          exactPercent = 0.99;
        }

        $scope.updated_percent_to_goal = _.round(exactPercent, 2) * 100;
      } else {
        // calculate estimated "total raised" in case user selects creditee,
        // this ensures the right element is displayed.
        $scope.updated_percent_to_goal = 1;
      }
    }
  };

  /*
   * Submit paypal with a timeout so validation can update
   */
  $scope.submitPaypal = function () {
    $scope.paypalErrors = true;

    $scope.Donation.payType = 'paypal';

    var requiredFields = [$scope.Donation.amount, $scope.Donation.name.first, $scope.Donation.name.last, $scope.Donation.email];

    var allFieldsValid = requiredFields.every(function (field) {
      return field && !field.$invalid;
    });

    if (!allFieldsValid) {
      var errorOffsetTop = angular.element('.donation_form').find('.ng-pending, .ng-invalid:first').offset() ? angular.element('.donation_form').find('.ng-pending, .ng-invalid:first').offset().top : false;
      $window.scrollTo(0, errorOffsetTop - 100);
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Oops! Please complete the required fields.'
      };
    } else {
      $scope.submit();
    }
  };

  /*
   * Only display donation attribution if supporters > 0
   */
  $scope.hasSupporters = 0;
  if ($scope.isTeamCampaign()) {
    $scope.team.getSupporterCounts().then(function (counts) {
      $scope.hasSupporters = counts.childPages;
    });
  } else if (!$scope.isIndividualCampaign()) {
    $scope.campaign.getSupporterCounts().then(function (counts) {
      $scope.hasSupporters = counts.fundraiserCount + counts.captainCount;
    });
  }

  /*
   * Submit Donation Form
   */
  $scope.submit = function () {
    $(window).scrollTop(0);

    // Honeypot
    if ($scope.Donation.z && $scope.Donation.z.length > 0) {
      window.location.href = '/checkout/error';
      return false;
    }

    $scope.submitErrorMsg = null;

    if (!$scope.block.processing) {
      $scope.block.processing = true;

      if ($scope.Donation.payType != 'paypal') {
        if ($scope.Donation.isWePay) {
          DonationService.processWePay({
            block: $scope.block,
            returnUrl: $scope.returnUrl
          });

          return false;
        } else if ($scope.Donation.isAuthorize) {
          DonationService.processAuthorize($scope.Donation.payment.card, $scope.Donation.payment.cvv);

          return false;
        }
      }

      DonationService.processDonation({
        block: $scope.block,
        returnUrl: $scope.returnUrl
      });
    }

    return undefined;
  };

  $timeout(function () {
    if ($stateParams.scroll) {
      $('html, body').animate({
        scrollTop: $('[name=' + $stateParams.scroll + ']').offset().top
      }, 1000);
    }
  });

  $scope.defaultClassyModeAppeal = '<p>I\'d like to cover the processing fee so 100% of my donation goes to\n   ' + scOrganizationsService.active.current.name + '.</p>';
}

angular.module('classy').controller('donationMainCtrl', donationMainCtrl);
})();