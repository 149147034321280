(function(){
'use strict';

/**
 * @name scFormatDate
 * @kind directive
 *
 * @deprecated Use ui-datepicker to define model format, or use $parsers to
 * convert value on input. But since it's included in customFields, we're
 * keeping it for now.
 *
 * @description
 * Replace ngModel value (which should represent a date) on change with a string
 * formatted to MM/DD/YYYY.
 */

scFormatDate.$inject = ["$parse"];
function scFormatDate($parse) {
  'use strict';

  return {
    require: '?ngModel',

    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs) {
      scope.$watch(attrs.ngModel, function (val) {
        // using the example model from the datepicker docs
        if (val) {
          var d = new Date(val);
          var currDate = d.getDate();
          var currMonth = d.getMonth() + 1; // Months are zero based
          var currYear = d.getFullYear();
          var modDate = currMonth + '/' + currDate + '/' + currYear;

          $parse(attrs.ngModel).assign(scope, modDate);
        }
      });
    }
  };
}

angular.module('classy').directive('scFormatDate', scFormatDate);
})();