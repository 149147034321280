(function(){
'use strict';

/**
 * @name nullBase
 * @kind filter
 */

function nullBase() {
  return function (input) {
    return angular.isUndefined(input) || input === null ? '' : '' + input;
  };
}

angular.module('classy').filter('nullBase', nullBase);
})();