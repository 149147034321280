(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scRegistrationModel
 *
 */

scCommentModel.$inject = ["scObjectModel", "scCloneDeep"];
function scCommentModel(scObjectModel, scCloneDeep) {
  function CommentModel(data, demo) {
    scObjectModel.call(this);

    this.isNew = !demo && !data;
    this.isDemo = demo || !data;

    this.current = scCloneDeep(data || {});
    this.defaults = scCloneDeep({});
    this.isExample = false;
    this.saved = scCloneDeep(data || {});
    this.type = 'comments';
  }

  var model = CommentModel;

  // Constructor
  model.prototype = _.create(scObjectModel.prototype, {
    constructor: CommentModel
  });

  // Methods

  return CommentModel;

  /* ---------------------------------------------------------------- *
   * Implementation
   * ---------------------------------------------------------------- */
}

angular.module('classy').factory('scCommentModel', scCommentModel);
})();