(function(){
'use strict';

angular.module('classy').component('donationLevelsControl', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n      <div class="form__donation-levels-container">\n\n        <button class="form__donation-levels-button"\n        ng-repeat="level in $ctrl.levels"\n        ng-click="$ctrl.setValue(level)"\n        ng-style="{ \'background-color\': $ctrl.primaryColor }"\n        type="button"\n        _spec="level">\n          {{ level | scFormatCurrency:$ctrl.raw_currency_code:0 }}\n        </button>\n\n        <div class="form__donation-levels-button--custom"\n        ng-click="$ctrl.setCustom()"\n        ng-style="{ \'background-color\': $ctrl.primaryColor }"\n        type="button"\n        _spec="other">\n          Other\n        </div>\n\n      </div>\n    ',
  bindings: {
    levels: '<',
    primaryColor: '<?'
  },
  controller: ["$rootScope", "scCampaignsService", "scCurrencyService", function DonationLevelsControl($rootScope, scCampaignsService, scCurrencyService) {
    this.setValue = function (value) {
      this.ngModelCtrl.$setViewValue(value);
    };

    this.raw_currency_code = scCampaignsService.active.current.raw_currency_code || scCurrencyService.getDefaultCurrency();

    this.setCustom = function () {
      $rootScope.$broadcast('donationLevelsControl.setCustomAmount');
    };
  }]
});
})();