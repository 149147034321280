(function(){
'use strict';

angular.module('classy').component('ticketTypesSelectField', {
  template: '\n    <form-label\n      required="$ctrl.required"\n      data-ng-if="$ctrl.label"\n      _spec="label">\n      {{:: $ctrl.label }}\n    </form-label>\n\n    <dropdown-control\n      data-ng-model="$ctrl.model"\n      data-ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      data-options="$ctrl.options">\n    </dropdown-control>\n    ',
  controller: ["scCampaignsService", function TicketTypesSelectFieldCtrl(scCampaignsService) {
    var _this = this;

    this.options = [];
    this.model = {};

    scCampaignsService.active.getTicketTypes().then(function (response) {
      var tickets = response.data.data;

      _.forEach(tickets, function (ticket) {
        _this.options.push({
          value: ticket.id,
          label: ticket.name
        });
      });
    });
  }],

  bindings: {
    model: '=',
    options: '<',
    name: '<',
    label: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    width: '<?'
  }
});
})();