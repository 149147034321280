(function(){
'use strict';

/**
 * @name userLinks
 * @kind filter
 */

userLinksFilter.$inject = ["$window"];
function userLinksFilter($window) {
  return function (input) {
    if (input) {
      var anchorRegex = /(<\s*a\s[^>]*>)/gi;
      var beginningAnchor = /(<\s*a\s)/i;
      var matches = input.match(anchorRegex);

      // if user supplied link to page on same domain, add target=self
      var result = input;
      if (matches) {
        _.forEach(matches, function (link) {
          if (link.indexOf($window.location.hostname) >= 0 && link.indexOf('_blank') == -1) {
            var newLink = link.replace(beginningAnchor, '<a target="_self" ');
            result = result.replace(link, newLink);
          }
        });
      }

      return result;
    }

    return undefined;
  };
}

angular.module('classy').filter('userLinks', userLinksFilter);
})();