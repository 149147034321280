(function(){
'use strict';

scFundraisingTeamsService.$inject = ["$http", "$q", "$window", "scFundraisingTeamModel", "scFundraisingTeamDemo", "scMembersService", "scBlocksService", "scOrganizationsService"];
var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/**
 * @ngDoc service
 * @name scFundraisingTeamsService
 *
 * @description
 * Stores an object full o' teams
 */

function scFundraisingTeamsService($http, $q, $window, scFundraisingTeamModel, scFundraisingTeamDemo, scMembersService, scBlocksService, scOrganizationsService) {
  var _this2 = this;

  var SC = $window.SC || {};

  this.teams = {};
  this.userTeams = {};

  this.comments = null;
  this.active = null;

  this.add = function (teamData) {
    this.teams[teamData.id] = new scFundraisingTeamModel(teamData);
    var ownerId = _.get(this.teams[teamData.id], 'current.team_lead_id');
    var userId = _.get(scMembersService, 'you.current.id');
    if (userId == ownerId || teamData.captainCase) {
      this.userTeams[teamData.id] = this.teams[teamData.id];
    }
  };

  this.remove = function (id) {
    delete this.userTeams[id];
    delete this.teams[id];
  };

  this.get = function (id) {
    return _.get(this, 'teams.' + id, false);
  };

  this.setCommentsNumber = function (commentsNumber) {
    this.comments = commentsNumber;
  };

  this.useExample = function () {
    this.active = new scFundraisingTeamModel(scFundraisingTeamDemo(), true);
    return this.active;
  };

  this.setActive = function (id) {
    this.active = this.get(id);
  };

  this.getTeamPhoto = function (team) {
    var teamBlockId = scBlocksService.getIdByType('team');
    var teamBlock = scBlocksService.get(teamBlockId);
    var img = void 0;

    if (_.get(team, 'logo.cdn_url')) {
      img = team.logo.cdn_url;
    } else if (_.get(team, 'team_profile_image')) {
      // ClassyQL field for profile image
      img = team.team_profile_image;
    } else if (_.get(team, 'logo.assetId')) {
      img = _.get(team, 'logo.src');
    }

    if (!_.isNull(img) && !_.isUndefined(img)) {
      return img;
    } else if (teamBlock.current.defaultTeamLogo) {
      img = teamBlock.current.defaultTeamLogo.src;
    } else {
      img = '/static/global/images/team_default_image.png';
    }

    return img;
  };

  this.init = function (teams) {
    var _this = this;

    _.forEach(teams, function (teamData) {
      _this.add(teamData);
    });
  };

  this.fetch = function (teamId, forceUpdate) {
    if (_.get(_this2, 'teams.' + teamId, false) && !forceUpdate) {
      $q.resolve(_this2.teams[teamId]);
    }

    return _this2.fetchData(teamId).then(function (team) {
      _this2.add(team);
      return _this2.teams[teamId];
    });
  };

  this.fetchData = function (teamId, withAnswers) {
    var promises = [$http({
      method: 'GET',
      url: '/frs-api/fundraising-teams/' + teamId
    })];

    if (withAnswers) {
      promises.push($http({
        method: 'GET',
        url: '/frs-api/fundraising-teams/' + teamId + '/answers'
      }));
    }

    return $q.all(promises).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          team = _ref2[0].data,
          answers = _ref2[1];

      var answerResponse = _.get(answers, 'data.data', undefined);
      Object.assign(team, { answers: answerResponse });

      // the api may omit the org id depending on permissions,
      // but we will always need it for navigation
      return Object.assign({}, team, {
        organization_id: _.get(scOrganizationsService, 'active.current.id', team.organization_id)
      });
    });
  };

  this.sync = function (id, isLoad) {
    var _ref3 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        forceSync = _ref3.forceSync,
        withAnswers = _ref3.withAnswers;

    if (isLoad && SC.team) {
      var logoId = SC.team.logo ? SC.team.logo.id : SC.team.logo_id;

      if (logoId) {
        return $http({
          method: 'GET',
          url: '/frs-api/asset/' + logoId
        }).then(function (assetResponse) {
          SC.team.logo = assetResponse.data;
          _this2.init([SC.team]);
          _this2.setActive(SC.team.id);
          return SC.team;
        });
      }

      _this2.init([SC.team]);
      _this2.setActive(SC.team.id);
      return $q.resolve(SC.team);
    } else if (_.get(_this2, 'active.current.id') == id && !forceSync) {
      _this2.active.refreshData();
      return $q.resolve(_this2.active.current);
    }

    return _this2.fetchData(id, withAnswers).then(function (team) {
      _this2.add(team);
      _this2.setActive(id);
      return _this2.teams[id].current;
    });
  };
}

angular.module('classy').service('scFundraisingTeamsService', scFundraisingTeamsService);
})();