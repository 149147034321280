(function(){
'use strict';

scTeamBlockSettings.$inject = ["scFroalaOptions"];
function scTeamBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/team/settings',
    controller: ['$scope', function ($scope) {
      $scope.block.sectionOpen = null;

      $scope.headlineOptions = scFroalaOptions({
        type: 'bare-headline'
      }, {
        heightMin: null,
        charCounterCount: true,
        charCounterMax: 200
      });

      $scope.titleOptions = scFroalaOptions({
        type: 'bare-headline'
      }, {
        heightMin: null,
        charCounterCount: true,
        charCounterMax: 180
      });

      $scope.postBodyOptions = scFroalaOptions({ type: 'update-paragraph' }, {
        ariaLabel: 'Default story body',
        heightMin: 200,
        heightMax: 200,
        scrollableContainer: '.edit-design_aside'
      });

      $scope.teamPhotoMediaManagerOptions = {
        title: 'Select a Team Default Photo',
        image: {
          crop: true,
          guide: {
            title: 'Team Default Photo',
            message: 'Brand your campaign with a unique logo.',
            size: '320 x 320 pixels'
          }
        }
      };

      $scope.teamCoverPhotoMediaManagerOptions = {
        title: 'Select a Cover Photo',
        image: {
          guide: {
            title: 'Team Cover Photo',
            message: 'Brand your campaign with a unique cover photo.',
            size: '1400 x 460 pixels'
          }
        }
      };
      if (SC.campaign && SC.campaign.campaignTemplateData) {
        var componentItems = _.get(SC.campaign.campaignTemplateData, 'components.block.component_items', []);

        if (componentItems.length) {
          var teamPage = componentItems.find(function (item) {
            return item.value.type === 'team';
          });
          if (teamPage) {
            $scope.lockBackground = teamPage.item_attributes.background.locked;
          }
        }
      }
    }],
    link: function link() {}
  };
}

angular.module('classy').directive('scTeamBlockSettings', scTeamBlockSettings);
})();