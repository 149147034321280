(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingPayUtil', ["cpConstants", function (cpConstants) {
  var PAYMENT_METHODS = cpConstants.PAYMENT_METHODS;
  // also extend to include lowercase
  var lowerKey = void 0;
  _.forEach(PAYMENT_METHODS, function (pm, key) {
    lowerKey = key.toLowerCase();
    PAYMENT_METHODS[lowerKey] = pm;
  });

  this.isValidPaymentMethod = function (key) {
    return key && !!PAYMENT_METHODS[key];
  };

  this.getValidPaymentMethods = function (pms) {
    var _this = this;

    var validPaymentMethods = {};

    if (_.isObject(pms)) {
      _.forEach(pms, function (pm, key) {
        if (_this.isValidPaymentMethod(key)) {
          validPaymentMethods[key] = pm;
        }
      });
    }

    return validPaymentMethods;
  };

  this.getPaymentMethodsByStatus = function (pms, status) {
    var paymentMethods = {};
    if (_.isObject(pms)) {
      _.forEach(pms, function (pm, key) {
        if (pm.status && pm.status === status) {
          paymentMethods[key] = pm;
        }
      });
    }
    return paymentMethods;
  };
}]);
})();