(function(){
'use strict';

angular.module('classy').value('CA_PROVINCE_LIST', [{
  value: 'AB',
  label: 'Alberta (AB)'
}, {
  value: 'BC',
  label: 'British Columbia (BC)'
}, {
  value: 'MB',
  label: 'Manitoba (MB)'
}, {
  value: 'NB',
  label: 'New Brunswick (NB)'
}, {
  value: 'NL',
  label: 'Newfoundland and Labrador (NL)'
}, {
  value: 'NT',
  label: 'Northwest Territories (NT)'
}, {
  value: 'NS',
  label: 'Nova Scotia (NS)'
}, {
  value: 'NU',
  label: 'Nunavut (NU)'
}, {
  value: 'PE',
  label: 'Prince Edward Island (PE)'
}, {
  value: 'SK',
  label: 'Saskatchewan (SK)'
}, {
  value: 'ON',
  label: 'Ontario (ON)'
}, {
  value: 'QC',
  label: 'Quebec (QC)'
}, {
  value: 'YT',
  label: 'Yukon (YT)'
}]);
})();