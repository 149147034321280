(function(){
'use strict';

angular.module('classy').component('modalSubmit', {
  transclude: true,
  require: {
    form: '^form'
  },
  template: '\n      <button class="modal__submit"\n      type="submit"\n      ng-disabled="!$ctrl.form.$valid"\n      ng-style="{ \'background-color\': $ctrl.primaryColor }"\n      ng-transclude></button>\n    ',
  bindings: {
    primaryColor: '<'
  }
});
})();