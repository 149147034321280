(function(){
'use strict';

frsDonationShippingCtrl.$inject = ["$scope"];
function frsDonationShippingCtrl($scope) {
  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */
  $scope.CONSTANTS = {
    ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a shipping address.',
    COUNTRY_REQUIRED_ERROR_TEXT: 'Please select your country.',
    CITY_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a shipping city.'
  };

  /* ---------------------------------------------------------------------- *
   * UI State
   * ---------------------------------------------------------------------- */

  $scope.state = {
    useBilling: false
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    error: $scope.UTIL.showError
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    stateZipError: function stateZipError() {
      var state = $scope.show.error('shipping.state', 'required') ? 'state' : '';
      var zip = $scope.show.error('shipping.postal_code', 'required') ? 'ZIP code' : '';
      var and = state && zip ? ' and ' : '';

      return 'Don\'t forget to include a shipping ' + state + and + zip + '.';
    }
  };

  /* ------------------------------------------------------------------ *
   * API
   * ------------------------------------------------------------------ */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/shipping/partials/' + name;
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  _.merge($scope.MODEL, {
    shipping: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      postal_code: null
    }
  });

  $scope.PREFLIGHT.copyBillingToShipping = function () {
    if ($scope.state.useBilling) {
      $scope.MODEL.shipping.address1 = $scope.MODEL.billing_address1;
      $scope.MODEL.shipping.address2 = $scope.MODEL.billing_address2;
      $scope.MODEL.shipping.city = $scope.MODEL.billing_city;
      $scope.MODEL.shipping.state = $scope.MODEL.billing_state;
      $scope.MODEL.shipping.postal_code = $scope.MODEL.billing_postal_code;
      $scope.MODEL.shipping.country = $scope.MODEL.billing_country;
    }
  };
}

angular.module('classy').controller('frsDonationShippingCtrl', frsDonationShippingCtrl);
})();