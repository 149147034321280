(function(){
'use strict';

function scSpotlight() {
  return {
    link: function link(scope, element, attrs) {
      // eslint-disable-next-line no-unused-vars
      scope.$on('spotlightOn', function (e, id) {
        angular.element(window).scrollTop(0);
        if (id === attrs.scSpotlight && element.is(':visible')) {
          var rect = element[0].getBoundingClientRect();
          var div = angular.element('<div id="spotlight-placeholder"></div>');
          div.css({
            position: element.css('position'),
            display: element.css('display'),
            width: element.width(),
            height: element.height()
          });
          div.insertBefore(element);
          element.addClass('spotlight').css({
            position: 'fixed',
            top: rect.top + 'px',
            left: rect.left + 'px',
            width: rect.width + 'px',
            height: rect.height + 'px',
            'z-index': 1000
          }).appendTo('body');
        }
      });

      // eslint-disable-next-line no-unused-vars
      scope.$on('spotlightOff', function (e, id) {
        if (id === attrs.scSpotlight && angular.element('#spotlight-placeholder')) {
          element.insertBefore('#spotlight-placeholder');
          angular.element('#spotlight-placeholder').remove();
          element.css({
            position: '',
            top: '',
            left: '',
            width: '',
            height: '',
            'z-index': ''
          });
        }
      });
    }
  };
}

angular.module('classy').directive('scSpotlight', scSpotlight);
})();