(function(){
'use strict';

/**
 * @name scBlurFocus
 * @kind directive
 * @restrict element
 * @requires requires ng-model.
 *
 * @description
 * Adds has-focus and has-visited classes to the source element.
 *
 * Can potentially be replaced using ngFocus/ngBlur and a custom function,
 * but would probably be impractical in this case.
 *
 * Used in:
 * - classyapp/application/views/scripts/reports/fundraising-page.phtml
 * - classyapp/application/views/scripts/checkout/donation.phtml
 * - classyapp/application/views/scripts/reports/edit-donation.phtml
 */

scBlurFocus.$inject = ["$rootScope"];
function scBlurFocus($rootScope) {
  'use strict';

  return {
    require: '?ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ctrl) {
      if (!ctrl) {
        return;
      }

      element.on('focus', function () {
        element.addClass('has-focus');

        if (!$rootScope.$$phase) {
          scope.$apply(function () {
            ctrl.hasFocus = true;
          });
        }
      });

      element.on('blur', function () {
        element.removeClass('has-focus');
        element.addClass('has-visited');

        scope.$apply(function () {
          ctrl.hasFocus = false;
          ctrl.hasVisited = true;
        });
      });
    }
  };
}

angular.module('classy').directive('scBlurFocus', scBlurFocus);
})();