(function(){
'use strict';

FeedController.$inject = ["$log", "$scope", "$http", "$rootScope", "$state", "scMembersService", "scFundraisingTeamActivityDemo", "scFundraisingActivityDemo", "scFundraisingPagesService", "scFundraisingTeamsService", "scCampaignsService", "scBlocksService"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * Directive that encapsulates an activity feed (list of feed items).
 * Currently used on the campaign landing page (activity block),
 * fundraising pages, and fundraising teams.
 *
 * @param scFeed   - the model associated with the feed (campaign, org, fundraising page/team)
 * @param resource - string specifying the resource type. Accepted values:
 *   - campaign
 *   - organization
 *   - fundraising-page
 *   - fundraising-team
 * @param allowCommenting - If user can make a comment on the feed. Defaults to true.
 * @param block           - the block context (currently only used for highlight color)
 * @param theme           - the theme context (currently only used for its primary color)
 *
 * @kind directive
 */

/**
 * The directive
 */
function scFeed() {
  return {
    scope: {
      model: '=scFeed',
      type: '@',
      allowCommenting: '=?',
      theme: '=?',
      demo: '=?',
      hideActivityTabs: '=?',
      lock: '=?'
    },
    templateUrl: 'global/objects/scBlock/shared/feed/template',
    controller: FeedController
  };
}

/**
 * The controller
 */
function FeedController($log, $scope, $http, $rootScope, $state, scMembersService, scFundraisingTeamActivityDemo, scFundraisingActivityDemo, scFundraisingPagesService, scFundraisingTeamsService, scCampaignsService, scBlocksService) {
  var vm = $scope;
  var config = getConfig();

  switch (vm.type) {
    case 'campaign':
      vm.modelTitle = 'Campaign';
      break;
    case 'fundraising-page':
      vm.modelTitle = 'Fundraising';
      vm.service = scFundraisingPagesService;
      break;
    case 'fundraising-team':
      vm.modelTitle = 'Team';
      vm.service = scFundraisingTeamsService;
      break;
    case 'organization':
      vm.modelTitle = 'Campaign';
      break;
    case 'update':
      break;
    default:
      $log.warn('scFeed: the type "' + vm.type + '" is not supported for having a feed.');
      break;
  }

  vm.allowCommenting = _.isUndefined(vm.allowCommenting) ? true : vm.allowCommenting;

  // Private Properties
  var endpoint = void 0;
  if (vm.type == 'update') {
    endpoint = '/frs-api/' + vm.type + 's/' + vm.model.id + '/comments';
  } else {
    endpoint = '/frs-api/' + vm.type + 's/' + vm.model.id + '/feed-items';
  }

  var withParam = '';
  if (vm.type == 'fundraising-team') {
    withParam = ',feedable';
  }

  var query = '?with=member,linkable' + withParam + '&per_page=5'; // created_at<' + (new Date()).toISOString();
  if (vm.type === 'update') {
    // The feed for updates consists of only comments. Comments can best be sorted by using `created_at` as `linkable_effective_at`
    // and `linkable_id` do not exist as fields on comments
    query += '&sort=created_at:desc';
  } else {
    //  CL-11211 Added linkable_id secondary sort to consistently sort entities with equal timestamps. Using `id`
    //  would be better but is hard to get through the frs -> apiv2 -> eloquent piping because id is ambiguous so `link_items`.`id`
    // is needed (but seemingly impossible to pass). legacy_activity_id was another option, but it seems better not to rely on
    // fields seemingly deprecated. id, linkable_id, and legacy_activity_id seem to have consistent ordering, but
    // linkable_id has no guarantee of uniqueness, so collisions leading to inconsistent sorting are still hypothetically possible,
    // though highly unlikely given that linkables for simultaneous events are unlikely to point at the same entity.
    query += '&sort=linkable_effective_at:desc,linkable_id:desc';
  }

  // Scope Properties
  vm.activeType = 'all';
  vm.displayLoadMore = false;
  vm.feed = [];
  vm.feedTotal = 0;
  vm.form = {};
  vm.form.comment = '';
  vm.me = scMembersService.you.current; // You (Me) (and Irene)
  vm.pageToRetrieve = 1;
  vm.searchQuery = '';
  vm.types = config.types;
  vm.campaign = scCampaignsService.active.current;

  // overall activity data
  vm.activityFeed = [];
  vm.activityQuery = '';
  vm.activityPage = 1;
  vm.activityMore = false;

  // comment data
  vm.commentFeed = [];
  vm.commentQuery = '&filter=type=comment';
  vm.commentPage = 0;
  vm.commentMore = false;

  // donors data
  vm.donorFeed = [];
  vm.donorQuery = '&filter=linkable_type~*donation,offline_donation,commitment_charge';
  vm.donorPage = 0;
  vm.donorMore = false;

  // Scope Methods
  vm.loadMoreFeedItems = loadAdditionalFeedItems;
  vm.matchType = matchType;
  vm.submitComment = submitComment;
  vm.switchType = switchType;
  vm.getDonationLink = getDonationLink;

  initializeFeed();

  function arrayRemoveIndex(arr, arrIndex) {
    return arr.slice(0, arrIndex).concat(arr.slice(arrIndex + 1));
  }

  switch ($scope.type) {
    case 'campaign':
      $scope.block = _.filter(scBlocksService.blocks, function (block) {
        return block.current.type == 'activity';
      })[0].current;
      break;
    case 'fundraising-team':
      $scope.block = _.filter(scBlocksService.blocks, function (block) {
        return block.current.type == 'team';
      })[0].current;
      break;
    case 'fundraising-page':
      $scope.block = _.filter(scBlocksService.blocks, function (block) {
        return block.current.type == 'fundraiser';
      })[0].current;
      break;
    case 'update':
      {
        var entity = $state.current.name.indexOf('team') > -1 ? 'team' : 'fundraiser';
        $scope.block = _.filter(scBlocksService.blocks, function (block) {
          return block.current.type == entity;
        })[0].current;
        break;
      }
    default:
      break;
  }

  $scope.$watch('block', function (newVal) {
    if (!_.get(newVal, 'allowCommenting', false) && _.filter(vm.feed, function (feed) {
      return feed.type === 'comment';
    }).length < 1) {
      vm.types.comments.disable = true;
    } else {
      vm.types.comments.disable = false;
    }
  }, true);

  $scope.$on('removeFeedItem', function (e) {
    var commentId = e.targetScope.comment;
    var index = _.findIndex(vm.feed, function (o) {
      return o.id == commentId;
    });

    vm.feed = arrayRemoveIndex(vm.feed, index);
    vm.types[vm.activeType].total -= 1;

    // remove item from activity feed
    if (vm.activeType !== 'all') {
      index = _.findIndex(vm.activityFeed, function (o) {
        return o.id == commentId;
      });

      vm.activityFeed = arrayRemoveIndex(vm.activityFeed, index);
      vm.types.all.total -= 1;
    } else {
      // when removing item from activity feed,
      // remove it from the respective feed as well
      var type = e.targetScope.feedItem.type;

      switch (type) {
        case 'comment':
          index = _.findIndex(vm.commentFeed, function (o) {
            return o.id == commentId;
          });

          vm.commentFeed = arrayRemoveIndex(vm.commentFeed, index);
          break;
        case 'donation':
          index = _.findIndex(vm.donorFeed, function (o) {
            return o.id == commentId;
          });

          vm.donorFeed = arrayRemoveIndex(vm.donorFeed, index);
          break;
        default:
        // do nothing
      }

      vm.types[type + 's'].total -= 1;
    }
  });

  /**
   * Create a feed item comment.
   * Note: a top-level feed comment is a feed item with type 'comment',
   * a comment on a feed item is considered an actual quote-on-quote comment
   */
  function submitComment() {
    if (!vm.form.comment) {
      return;
    }

    var content = vm.form.comment;

    vm.form.comment = null;

    var feedItem = {
      comment: content,
      feedable_type: vm.type,
      member_name: vm.me.first_name + ' ' + vm.me.last_name,
      member: vm.me,
      provisional: true,
      type: 'comment'
    };

    var newActivityLength = void 0;
    var newCommentLength = void 0;

    // feed length will be > 0 when we've already loaded that tab.
    // we need to update the relevant inactive tabs with our new comment
    // so that it is displayed when user switches between tabs.
    if (vm.activeType == 'comments' && vm.activityFeed.length !== 0) {
      newActivityLength = vm.activityFeed.unshift(feedItem);
    } else if (vm.activeType == 'all' && vm.commentFeed.length !== 0) {
      newCommentLength = vm.commentFeed.unshift(feedItem);
    }

    var newLength = vm.feed.unshift(feedItem);
    vm.types.all.total += 1;
    vm.types.comments.total += 1;

    $http.post(endpoint, {
      comment: content,
      body: content
    }).then(function (response) {
      _.merge(feedItem, response.data);
      feedItem.provisional = false;
    }, function () {
      vm.form.comment = content;
      vm.feed.splice(newLength - 1, 1);

      if (newActivityLength) {
        vm.activityFeed.splice(newActivityLength - 1, 1);
      } else if (newCommentLength) {
        vm.commentFeed.splice(newCommentLength - 1, 1);
      }

      vm.types.all.total -= 1;
      vm.types.comments.total -= 1;

      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Sorry, your comment could not be posted. Please try again later.'
      };
    });
  }

  // Watch for change in member when logging in
  $scope.$watch(function () {
    return _.get(scMembersService, 'active.current');
  }, function (newVal, oldVal) {
    if (newVal !== oldVal) {
      vm.me = newVal;
    }
  });

  /**
   * Change the feed tab
   */
  function switchType(typeKey) {
    switch (vm.activeType) {
      case 'all':
        vm.activityPage = vm.pageToRetrieve;
        vm.activityFeed = vm.feed;
        vm.activityMore = vm.displayLoadMore;
        break;

      case 'donations':
        vm.donorPage = vm.pageToRetrieve;
        vm.donorFeed = vm.feed;
        vm.donorMore = vm.displayLoadMore;
        break;

      case 'comments':
        vm.commentPage = vm.pageToRetrieve;
        vm.commentFeed = vm.feed;
        vm.commentMore = vm.displayLoadMore;
        break;

      default:
      // do nothing
    }

    vm.activeType = typeKey;

    switch (vm.activeType) {
      case 'all':
        vm.pageToRetrieve = vm.activityPage;
        vm.feed = vm.activityFeed;
        vm.searchQuery = vm.activityQuery;
        vm.displayLoadMore = vm.activityMore;
        break;

      case 'donations':
        vm.pageToRetrieve = vm.donorPage;
        vm.feed = vm.donorFeed;
        vm.searchQuery = vm.donorQuery;
        vm.displayLoadMore = vm.donorMore;
        break;

      case 'comments':
        vm.pageToRetrieve = vm.commentPage;
        vm.feed = vm.commentFeed;
        vm.searchQuery = vm.commentQuery;
        vm.displayLoadMore = vm.commentMore;
        break;
      default:
      // do nothing
    }

    if (vm.pageToRetrieve === 0) {
      loadAdditionalFeedItems();
    }
  }

  // ALL FILTER
  // Filters out all feedItems that are of type 'association', but nothing else
  function allFilter(activeFilter, feedItem) {
    return activeFilter === 'all' && feedItem.type !== 'association';
  }

  // DONATIONS FILTER
  // Filters out all feedItems that are not donations or offline_donations or commitment_charge
  function donationsFilter(activeFilter, feedItem) {
    return activeFilter === 'donations' && (feedItem.linkable_type === 'donation' || feedItem.linkable_type === 'offline_donation' || feedItem.linkable_type === 'commitment_charge');
  }

  // COMMENTS FILTER
  // Filters out all feedItems that are not comments
  function commentsFilter(activeFilter, feedItem) {
    return activeFilter === 'comments' && feedItem.type === 'comment';
  }

  // Determines which feedItems to show the user based on the following:
  // - activeFilter: all, donations, comments
  // - entityType: campaign, fundraising page, fundraising team
  // - feedItem.type: the type of action that the feedItem represents
  //    (e.g., comment, association, creation)
  // - feedItem.linkable_type: the type of item the feedItem action represents
  //    (e.g., donation, offline donation, soft_credit)
  function matchType(feedItem) {
    var activeFilter = vm.activeType;
    var entity = vm.type;
    return allFilter(activeFilter, feedItem, entity) || donationsFilter(activeFilter, feedItem, entity) || commentsFilter(activeFilter, feedItem, entity);
  }

  /**
   * Give me moar feed items!
   */
  function loadAdditionalFeedItems() {
    vm.pageToRetrieve += 1;
    $http.get(endpoint + query + vm.searchQuery + '&page=' + vm.pageToRetrieve).then(function (response) {
      var collection = response.data;
      vm.displayLoadMore = !!collection.next_page_url;

      addFeedItemsToFeed(collection.data);
    });
  }

  /**
   * Add array of feed items to the current feed.
   */
  function addFeedItemsToFeed(feedItems) {
    var _vm$feed;

    if (!feedItems) {
      return;
    }

    // we do not want to show any commitment activities (except for charges) to the public
    var filteredItems = feedItems.filter(function (fi) {
      return fi.linkable_type !== 'commitment' && fi.linkable_value ? !fi.linkable_value.includes('Payment_error') : true;
    });

    (_vm$feed = vm.feed).push.apply(_vm$feed, _toConsumableArray(filteredItems));
  }

  /**
   * Initialize the feed with the most recent feed items.
   */
  function initializeFeed() {
    if (vm.model.id && !$state.is('admin.manage.campaign.step.setup') && !vm.demo && !$state.is('admin.create.exists.step') &&
    // If a campaign has been published, but we are looking at a preview of the team page,
    // making this request will cause an error to be thrown in the backend.
    !(_.get($state, 'params.preview', '') === 'true')) {
      if (vm.type === 'update') {
        $http.get('/frs-api/updates/' + vm.model.id + '/comments' + query).then(function (response) {
          var collection = response.data;

          vm.displayLoadMore = !!collection.next_page_url;

          vm.types.all.total = collection.total;
          vm.types.comments.total = collection.comment_count;
          vm.types.donations.total = collection.donor_count;

          // hide comments tab if disabled and no comments
          if (!vm.types.comments.total && !vm.allowCommenting) {
            vm.types.comments.disable = true;
          }

          addFeedItemsToFeed(collection.data);
        }, function (error) {
          $log.log(error);
        });
      } else {
        query += '&campaignId=' + vm.campaign.id;

        $http({
          method: 'GET',
          url: '/frs-api/' + vm.type + 's/' + vm.model.id + '/initial-feed' + query
        }).then(function (response) {
          var collection = [];

          if (vm.campaign.status == 'active' && _.get(response, 'data.data.length', false)) {
            // This will filter out the canceled and test transactions.
            // We have to do this because when you deleted offline transactions in the
            // past they were marked as 'test' transactions and would show in activity feed
            var feedItems = _.filter(response.data.data, function (item) {
              if (item.linkable && _.indexOf(item.linkable_type, 'donation') > -1) {
                return item.linkable.status == 'success';
              }
              return true;
            });
            collection = response.data;
            collection.data = feedItems;
          } else {
            collection = response.data;
          }

          if (vm.type == 'fundraising-team' || vm.type == 'fundraising-page') {
            var comments = _.filter(collection.data, function (item) {
              return item.type == 'comment';
            });

            vm.service.setCommentsNumber(comments.length);
          }

          // display the 'create' activity for blank state if page is newly created
          if (response.data.total) {
            vm.displayLoadMore = !!collection.next_page_url;
            vm.types.all.total = collection.total;
            vm.types.comments.total = collection.comment_count;
            vm.types.donations.total = collection.donor_count;
          } else {
            var demoData = {};

            if (vm.type == 'fundraising-team') {
              demoData = scFundraisingTeamActivityDemo();
            } else if (vm.type == 'fundraising-page') {
              demoData = scFundraisingActivityDemo();
            }

            collection.data = demoData.data;
          }

          // hide comments tab if disabled and no comments
          if (!vm.types.comments.total && !vm.allowCommenting) {
            vm.types.comments.disable = true;
          }

          addFeedItemsToFeed(collection.data);
        }, function (error) {
          $log.log(error);
        });
      }
    } else if (vm.type == 'fundraising-team') {
      addFeedItemsToFeed(scFundraisingTeamActivityDemo().data);
    } else if (vm.type == 'fundraising-page') {
      addFeedItemsToFeed(scFundraisingActivityDemo().data);
    }
  }

  function getDonationLink() {
    if (vm.type == 'fundraising-page') {
      return '/give/f' + scFundraisingPagesService.active.current.id + '/#!/donation/checkout';
    }

    if (vm.type == 'fundraising-team') {
      return '/give/t' + scFundraisingTeamsService.active.current.id + '/#!/donation/checkout';
    }

    return '/give/' + vm.campaign.id + '/#!/donation/checkout';
  }
}

function getConfig() {
  return {
    types: {
      all: {
        tabName: 'All',
        total: 0
      },
      comments: {
        tabName: 'Comments',
        total: 0
      },
      donations: {
        tabName: 'Donors',
        total: 0
      }
    }
  };
}

angular.module('classy').directive('scFeed', scFeed);
})();