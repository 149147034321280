(function(){
'use strict';

angular.module('classy').constant('scDonationBlockDefaultsRf', {
  title: 'Donation',
  type: 'donation',
  loaded: true,
  headline: '<span style="font-size: 40px">You\'re making a difference</span>',
  description: '',
  donation_amount_appeal: '',
  questions: [],
  'recurring-level': [{
    type: 'one-time',
    name: 'One-time',
    display_on_page: true
  }, {
    type: 'daily',
    name: 'Daily',
    display_on_page: false
  }, {
    type: 'weekly',
    name: 'Weekly',
    display_on_page: false
  }, {
    type: 'bi-weekly',
    name: 'Bi-weekly (every 2 weeks)',
    display_on_page: false
  }, {
    type: 'monthly',
    name: 'Monthly',
    display_on_page: true
  }, {
    type: 'quarterly',
    name: 'Quarterly (every 3 months)',
    display_on_page: false
  }, {
    type: 'semi-annually',
    name: 'Semi-annually (every 6 months)',
    display_on_page: false
  }, {
    type: 'yearly',
    name: 'Yearly (every 12 months)',
    display_on_page: false
  }],
  'recurring-default': 'one-time',
  'recurring-type': 'default',
  'donation-levels': [{
    amount: 500,
    display_on_page: true
  }, {
    amount: 100,
    display_on_page: true
  }, {
    amount: 50,
    display_on_page: true
  }, {
    amount: 25,
    display_on_page: true
  }, {
    amount: 'Custom',
    display_on_page: true
  }],
  'recurring-donation-levels': [{
    amount: 500,
    display_on_page: true
  }, {
    amount: 100,
    display_on_page: true
  }, {
    amount: 50,
    display_on_page: true
  }, {
    amount: 25,
    display_on_page: true
  }, {
    amount: 'Custom',
    display_on_page: true
  }],
  'donation-default': null,
  'recurring-donation-default': null,
  'submit-button-text': 'Give now!',

  billing_address_autocomplete: false,

  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: true,
    preset: 'coverfixed'
  },

  unsortable: true,
  recurring_donation_experiment: false
});
})();