(function(){
'use strict';

// Register factory

progressbarFactory.$inject = ["$window"];
function progressbarFactory($window) {
  if (!$window.ProgressBar) {
    // If ProgressBar is not available you can now provide a
    // mock service, try to load it from somewhere else,
    // redirect the user to a dedicated error page, ...
  }
  return $window.ProgressBar;
}

angular.module('classy').factory('ProgressBar', progressbarFactory);
})();