(function(){
'use strict';

SlideshowAPIFactory.$inject = ["$compile"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function SlideshowAPIFactory($compile) {
  return function () {
    /* ---------------------------------------------------------------------- *
     * Constructor
     * ---------------------------------------------------------------------- */

    function SlideshowAPI(slides) {
      _classCallCheck(this, SlideshowAPI);

      this.activeIndex = -1;
      this.activeSlide = null;
      this.initialized = false;
      this.listeners = {
        init: [],
        slide_added: [],
        slide_removed: [],
        slides_updated: []
      };
      this.slides = [];
      if (slides) {
        this.init(slides);
      }
    }

    /* ---------------------------------------------------------------------- *
     * Init
     *
     * Installs a new collection of slides.
     * ---------------------------------------------------------------------- */

    _createClass(SlideshowAPI, [{
      key: 'init',
      value: function init(slides) {
        this.slides = slides;
        this.initialized = true;
        this.emit('init', slides);
      }

      /* ---------------------------------------------------------------------- *
       * On
       *
       * Attach a callback to an event. If attaching to the 'init' event after
       * initialization has occured, the event is replayed.
       * ---------------------------------------------------------------------- */

    }, {
      key: 'on',
      value: function on(event, cb) {
        var eventListeners = this.listeners[event] || [];
        eventListeners.push(cb);
        this.listeners[event] = eventListeners;
        // Replay initialization if applicable
        if (event === 'init' && this.initialized) {
          cb.call(this, this.slides);
        }
      }

      /* ---------------------------------------------------------------------- *
       * Emit
       *
       * Fire listeners attached to an event.
       * ---------------------------------------------------------------------- */

    }, {
      key: 'emit',
      value: function emit(event) {
        var _this = this;

        for (var _len = arguments.length, data = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          data[_key - 1] = arguments[_key];
        }

        var eventListeners = this.listeners[event] || [];
        _.forEach(eventListeners, function (cb) {
          return cb.apply(_this, data);
        });
      }

      /* ---------------------------------------------------------------------- *
       * Compile
       *
       * Return a compiled DOM representation of a slide definition.
       * ---------------------------------------------------------------------- */

    }, {
      key: 'compile',
      value: function compile(which) {
        var index = void 0;
        switch (which) {
          case 'next':
            index = this.nextIndex;
            break;
          case 'prev':
            index = this.prevIndex;
            break;
          default:
            index = which;
        }
        var def = this.slides[index];
        var content = $compile(def.tpl)(def.ctx);
        return content.wrapAll('<div class="slideshow_slide"></div>').parent();
      }

      /* ---------------------------------------------------------------------- *
       * Update
       *
       * Replaces the slide collection with a new one. Slides are compared by
       * key (rather than identity) and appropriate events emitted.
       *
       * For now, it is assumed that only one slide is added or removed at a
       * time.
       * ---------------------------------------------------------------------- */

    }, {
      key: 'update',
      value: function update(newColl) {
        var oldColl = this.slides;

        this.slides = newColl;

        this.emit('slides_updated', newColl, oldColl);

        var added = [];
        _.forEach(newColl, function (newDef) {
          var key = newDef.key;
          var accountedFor = false;
          _.forEach(oldColl, function (oldDef) {
            if (oldDef.key === key) accountedFor = true;
          });
          if (!accountedFor) {
            added.push(newDef);
          }
        });
        if (added.length) {
          this.emit('slide_added', added[0]);
        }

        var removed = [];
        _.forEach(oldColl, function (oldDef, i) {
          var key = oldDef.key;
          var accountedFor = false;
          _.forEach(newColl, function (newDef) {
            if (newDef.key === key) accountedFor = true;
          });
          if (!accountedFor) {
            removed.push(i);
          }
        });
        if (removed.length) {
          this.emit('slide_removed', removed[0]);
        }
      }

      /* ---------------------------------------------------------------------- *
       * Setters for use by the scSlideshow directive.
       *
       * This data is kept in the SlideshowAPI because it represents state info
       * that would likely be useful if shared, but it should only be modified
       * by the directive, as only the directive has knowledge of which index
       * or slide appears active to the user.
       * ---------------------------------------------------------------------- */

    }, {
      key: 'setActiveIndex',
      value: function setActiveIndex(index) {
        this.activeIndex = index;
      }
    }, {
      key: 'setActiveSlide',
      value: function setActiveSlide(slide) {
        this.activeSlide = slide;
      }

      /* ---------------------------------------------------------------------- *
       * Dynamic getters
       * ---------------------------------------------------------------------- */

    }, {
      key: 'finalIndex',
      get: function get() {
        return this.slides.length - 1;
      }
    }, {
      key: 'nextIndex',
      get: function get() {
        return this.activeIndex === this.finalIndex ? 0 : this.activeIndex + 1;
      }
    }, {
      key: 'prevIndex',
      get: function get() {
        return this.activeIndex === 0 ? this.finalIndex : this.activeIndex - 1;
      }
    }]);

    return SlideshowAPI;
  }();
}

angular.module('classy').factory('SlideshowAPI', SlideshowAPIFactory);
})();