(function(){
'use strict';

/* Donate Button (displayed on EG's root view) */
angular.module('classy').component('egRootDonateButton', {
  templateUrl: 'global/embedded-giving/components/root-donate-button/eg.components.root-donate-button', // same folder as this
  bindings: {
    amountGetter: '&',
    globalState: '=',
    parentModel: '=',
    isDisabled: '&',
    onClickHandler: '&'
  },
  controller: ["$log", function controller($log) {
    var _this = this;

    this.processingStyles = {
      color: '#ccc',
      fadeColor: 'transparent',
      top: '-14px',
      left: '63%'
    };

    this.$onInit = function () {
      if (!_this.parentModel) {
        $log.warn('[egDonateButton:$onInit] binding @parentModel required');
      }
    };

    this.onClick = function () {
      if (_.isFunction(_this.onClickHandler)) {
        _this.onClickHandler();
      } else {
        $log.debug('[egDonateButton:onClick] no on click handler provided');
      }
    };

    this.getDonationAmount = function () {
      return _.isFunction(_this.amountGetter) ? _this.amountGetter() : 0;
    };

    // Is the button enabled or disabled?
    this.isEnabled = function () {
      return _.isFunction(_this.isDisabled) ? !_this.isDisabled() : false;
    }; // disabled by default

    this.shouldUseDonateButton = function () {
      return SC.organization && SC.organization.tags && SC.organization.tags.includes('early-access:eg-donate-button');
    };
  }]
});
})();