(function(){
'use strict';

angular.module('classy').component('pageTile', {
  template: '\n      <div class="page-tile__block" ng-click="$ctrl.clickTile()">\n       <img class="page-logo" ng-src="{{::$ctrl.logo}}">\n       <div class="page-info__column">\n        <div class="page-info__alias">{{::$ctrl.page.alias}}</div>\n        <div class="page-info__row">\n          <i class="page-info__check fa fa-check-circle" ng-if="::$ctrl.page.completed"></i>\n          <div class="page-info__status">{{::$ctrl.currencyText}}</div>\n        </div>\n       </div>\n       <div class="page-chevron"></div>\n      </div>\n    ',
  bindings: {
    page: '=',
    modalAction: '&'
  },
  controller: ["$filter", "scCurrencyService", "scFundraisingPagesService", function pageTileCtrl($filter, scCurrencyService, scFundraisingPagesService) {
    this.formatCurrency = function (displayAmount) {
      this.currencyText = $filter('scFormatCurrency')(displayAmount, this.page.raw_currency_code, 0) + ' Raised';

      if (this.page.completed) {
        this.currencyText = 'Complete \xB7 ' + this.currencyText;
      }
    };

    this.$onInit = function () {
      var _this = this;

      scCurrencyService.convertValue(this.page.total_raised, scCurrencyService.getDefaultCurrency(), this.page.raw_currency_code).then(function (response) {
        _this.formatCurrency(response);
      });

      this.logo = scFundraisingPagesService.getPagePhoto(this.page);
    };

    this.clickTile = function () {
      var options = {
        state: 'frs.fundraiser.index',
        params: { fundraisingPageId: this.page.id }
      };

      this.modalAction({ options: options });
    };
  }]
});
})();