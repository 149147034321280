(function(){
'use strict';

/**
 * @kind directive
 * @name scCompileSass
 *
 * @description
 * Wrap content
 *
 * Used in:
 * Appv2
 *
 */

scCompileSass.$inject = ["$log", "sass"];
function scCompileSass($log, sass) {
  return {
    scope: {
      content: '=scCompileSass',
      to: '=',
      wrapWith: '@?',
      output: '@?'
    },
    link: function link(scope, element) {
      function compileContent() {
        var content = scope.wrapWith ? scope.wrapWith + '{' + scope.content + '}' : scope.content;

        sass.compile(content, function (result) {
          // $log.log(content, result);
          if (result.message) {
            $log.error(result.formatted);
          }
          scope.$apply(function () {
            scope.to = result.text;
          });

          if (scope.output) {
            element.html(result.text);
          }
        });
      }

      var debounced = _.debounce(function (newVal, oldVal) {
        if (newVal !== oldVal) {
          compileContent();
        }
      }, 1000);
      scope.$watch('content', debounced);
    }
  };
}

angular.module('classy').directive('scCompileSass', scCompileSass);
})();