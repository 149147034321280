(function(){
'use strict';

/**
 * @kind factory
 * @name scThemeModel
 *
 * @description
 * A theme constructor with some common
 * prototype methods.
 */

scThemeModelFactory.$inject = ["scObjectModel", "scThemeDefaults", "scCloneDeep", "ticketTerms"];
function scThemeModelFactory(scObjectModel, scThemeDefaults, scCloneDeep, ticketTerms) {
  function scThemeModel(data) {
    if (data.queries_log) {
      delete data.queries_log;
    }

    if (data.mongo_queries_log) {
      delete data.mongo_queries_log;
    }
    if (data.updated_at) {
      delete data.updated_at;
    }

    scObjectModel.call(this);

    this.type = 'theme';
    this.defaults = scThemeDefaults(data.type);
    this.saved = scCloneDeep(data);
    this.current = scCloneDeep(data);

    var getBlockContent = function getBlockContent(section, type, target) {
      if (!section) return null;
      var sectionBody = section.find(function (item) {
        return item.name === 'body';
      });
      var blockType = sectionBody.blocks.find(function (block) {
        return block.type === type;
      });
      return _.get(blockType, target, null);
    };

    // Here, we are fetching primary color for existing campaign
    // if any user has pre-defined primary color of their own choice for respective campaign.
    if (data && data.styles && !Object.prototype.hasOwnProperty.call(data.styles, 'footerBgColor') && !Object.prototype.hasOwnProperty.call(data.styles, 'aboutBgColor') && !Object.prototype.hasOwnProperty.call(data.styles, 'donateButtonColor') && !Object.prototype.hasOwnProperty.call(data.styles, 'aboutContentColor') && !Object.prototype.hasOwnProperty.call(data.styles, 'locationBgColor') && !Object.prototype.hasOwnProperty.call(data.styles, 'headlineColor')) {
      this.defaults.styles = this.defaults.styles || {};
      this.defaults.styles.footerBgColor = data.styles.primaryColor;
      this.defaults.styles.aboutBgColor = data.styles.primaryColor;
      this.defaults.styles.donateButtonColor = data.styles.primaryColor;
      this.defaults.styles.locationBgColor = data.styles.primaryColor;

      // Provided hard code data for about content color,as for all existing campaigns
      // we need to show about content color as white
      this.defaults.styles.aboutContentColor = 'rgba(255,255,255,1)';

      var themeBlock = _.get(data, 'pages.landing.block_sections', null);

      var themeBlockHeadline = getBlockContent(themeBlock, 'crowdfunding', 'headlineColor');
      this.defaults.styles.headlineColor = themeBlockHeadline;
    }

    this.setupBackwardsCompatability(this.saved);
    this.setupBackwardsCompatability(this.current);
  }

  scThemeModel.prototype = _.create(scObjectModel.prototype, {
    constructor: scThemeModel
  });

  scThemeModel.prototype.getEventStyles = function () {
    var defaultStyles = void 0;

    switch (this.current.type) {
      case 'reg_w_fund':
        defaultStyles = {
          ticketedEvent: {
            ticketTerm: 'registrations',
            additionalDonation: true
          }
        };
        break;

      case 'ticketed':
        defaultStyles = {
          ticketedEvent: {
            ticketTerm: 'tickets',
            additionalDonation: true
          }
        };
        break;

      default:
        defaultStyles = {};
    }

    // We're merging existing theme styles with default styles for backwards compatibility.
    // This will add any default styles for the event type that didn't already exist, while
    // keeping the settings of the fields which have been set.
    return _.merge(defaultStyles, this.current.styles);
  };

  scThemeModel.prototype.getTicketTerm = function () {
    var form = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'plural';

    if (!['reg_w_fund', 'ticketed'].includes(this.current.type)) {
      return undefined;
    }

    var defaultTerm = this.current.type == 'reg_w_fund' ? 'registrations' : 'tickets';
    var term = this.current.styles.ticketedEvent.ticketTerm || defaultTerm;

    return ticketTerms[term][form];
  };

  /*
   * Backwards comptability: merging defaults into current
   */
  scThemeModel.prototype.setupBackwardsCompatability = function (dataset) {
    var defaultObj = _.cloneDeep(this.defaults);

    // Delete block sections off defaults prior to merging
    _.forEach(defaultObj.pages, function (val) {
      delete val.block_sections;
    });

    _.defaultsDeep(dataset, defaultObj);
  };

  return scThemeModel;
}

angular.module('classy').factory('scThemeModel', scThemeModelFactory);
})();