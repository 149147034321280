(function(){
'use strict';

scMediaManagerImageLibraryCtrl.$inject = ["$log", "$scope", "$timeout", "remoteAssetsService", "scMason"];
angular.module('classy').controller('scMediaManagerImageLibraryCtrl', scMediaManagerImageLibraryCtrl);

function scMediaManagerImageLibraryCtrl($log, $scope, $timeout, remoteAssetsService, scMason) {
  /* ---------------------------------------------------------------------- *
   * Set up environment and scope
   * ---------------------------------------------------------------------- */
  $scope.mason = new scMason($scope);

  var search = {
    initialized: false,
    query: '',
    querySuffix: '',
    processing: false,
    currentPage: 1,
    totalPages: 1,
    results: [],
    loaded: 0,
    empty: false,
    complete: false
  };

  $scope.ui.image.search = search;

  /* ---------------------------------------------------------------------- *
   * Library API
   * ---------------------------------------------------------------------- */

  $scope.fetchImages = function () {
    $scope.mason.clear();

    search.processing = true;
    search.currentPage = 1;
    search.totalPages = 1;
    search.results = [];
    search.loaded = 0;
    search.empty = false;
    search.complete = false;

    var query = (search.query + ' ' + search.querySuffix).trim();

    remoteAssetsService.search(query, search.currentPage).then(function (result) {
      if (result.data.error) {
        search.totalPages = search.currentPage;
        $log.error('Failed to fetch images from Pixabay:', result.data.error);
        return;
      }

      if (!result.data.hits.length) {
        search.empty = true;
        return;
      }

      search.results = result.data.hits;
      search.totalPages = Math.ceil(result.data.totalHits / 100);
      $scope.loadImages();
    }).catch(function (err) {
      $log.error('Failed to fetch images from Pixabay:', err);
    }).finally(function () {
      search.processing = false;
    });
  };

  $scope.loadImages = function () {
    var startIndex = search.loaded;
    var stopIndex = search.loaded + 10;
    for (var i = startIndex; i < stopIndex; i += 1) {
      if (search.results[i]) {
        $scope.mason.push(search.results[i]);
        search.loaded += 1;
      } else {
        $scope.fetchMoreImages();
        break;
      }
    }
  };

  $scope.fetchMoreImages = function () {
    // Bail if processing
    if (search.processing) {
      return;
    }

    // Bail if we're at the end of the search results
    if (search.currentPage >= search.totalPages) {
      search.complete = true;
      return;
    }

    // Increment current page
    search.currentPage += 1;

    // Block other search requests
    $scope.ui.image.search.processing = true;

    // Construct query
    var query = (search.query + ' ' + search.querySuffix).trim();

    // Search
    remoteAssetsService.search(query, search.currentPage).then(function (result) {
      if (result.data.error) {
        search.totalPages = search.currentPage;
        $log.error('Failed to fetch images from Pixabay: ' + result.data.error);
        return;
      }

      // Dedupe new listings

      _.forEach(result.data.hits, function (newListing) {
        var isDupe = false;
        _.forEach(search.results, function (existingListing) {
          if (newListing.id_hash === existingListing.id_hash) {
            isDupe = true;
          }
        });
        if (!isDupe) {
          search.results.push(newListing);
        }
      });

      $scope.loadImages();
    }).catch(function (err) {
      $log.error('Failed to fetch images from Pixabay:' + err);
    }).finally(function () {
      search.processing = false;
    });
  };

  $scope.$watch('ui.image.search.querySuffix', function (newVal, oldVal) {
    if (newVal !== oldVal) {
      $scope.fetchImages();
    }
  });

  $scope.$watch('ui.image.source', function (newVal) {
    if (newVal === 'library' && !search.initialized) {
      $timeout(function () {
        $scope.mason.init();
        search.initialized = true;
      });
    }
  });

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */
  if ($scope.userAccess) {
    $scope.userAccess.then(function () {
      $scope.fetchImages();
    });
  } else {
    $scope.fetchImages();
  }
}
})();