(function(){
'use strict';

angular.module('classy').component('egPayAch', {
  templateUrl: 'global/embedded-giving/pay/components/ach/eg.pay.components.ach', // same folder as this
  bindings: {
    payment: '<'
  },
  controller: ["scThemesService", function controller(scThemesService) {
    var _this = this;

    this.accountNumberFocus = false;
    this.accountNumberHover = false;
    this.routingNumberFocus = false;
    this.routingNumberHover = false;

    this.$onInit = function () {
      _this.showManualACH = false;
      _this.primaryColor = scThemesService.active.current.styles.primaryColor;
    };

    this.onAccountNumberFocus = function (isFocused) {
      _this.accountNumberFocus = isFocused;
    };

    this.show = {
      checkDefault: function checkDefault() {
        return !_this.routingNumberHover && !_this.accountNumberHover && !_this.routingNumberFocus && !_this.accountNumberFocus;
      },
      checkRouting: function checkRouting() {
        return (_this.routingNumberHover || _this.routingNumberFocus) && !_this.accountNumberHover;
      },
      checkAccount: function checkAccount() {
        return (_this.accountNumberHover || _this.accountNumberFocus) && !_this.routingNumberHover;
      }
    };
  }]
});
})();