(function(){
'use strict';

/**
 * @name scBirthday
 * @kind directive
 * @guide ./scBirthday.guide.md
 *
 * @description
 * Replaces the source element with separate selects for month, day, and
 * year; on change the input is converted to a date and saved in the
 * expression referenced by sc-birthday. ng-model is NOT required.
 *
 * There are angular directives (like input[date]) that can serve a similiar
 * function, but they don't allow 3 different inputs for each field. Instead,
 * it brings down a calendar where you select the date. You can also manually
 * input the date.
 *
 * Used in:
 * - classyapp/public/scripts/global/directives/Form.js
 * - classyapp/application/views/scripts/checkout/donation.phtml
 * - classyapp/application/views/scripts/reports/edit-donation.phtml
 * - classyapp/application/views/scripts/reports/fundraising-page.phtml
 */

function scBirthday() {
  'use strict';

  return {
    replace: true,

    template: '<span class="birthday">' + '<select class="form_select desktop-quarter mobile-ls-third month" ng-model="birthMonth" ng-options="month for month in months"><option value="" selected>Birthday Month</option></select>' + '<select class="form_select desktop-quarter mobile-ls-third" ng-model="birthDate" ng-options="date for date in dates"><option value="" selected>Birthday Day</option></select>' + '<select class="form_select desktop-quarter mobile-ls-third" ng-model="birthYear" ng-options="year for year in years"><option value="" selected>Birthday Year</option></select>' + '</span>',

    scope: {
      scBirthday: '='
    },

    link: function link(scope) {
      scope.months = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      scope.dates = [];
      for (var x = 1, y = 31; x <= y; x += 1) {
        scope.dates.push(x);
      }

      scope.years = [];

      for (var a = 2014, b = 2014 - 110; a >= b; a -= 1) {
        scope.years.push(a);
      }

      scope.$watch('birthMonth', tabulateBirthdate);
      scope.$watch('birthDate', tabulateBirthdate);
      scope.$watch('birthYear', tabulateBirthdate);

      if (scope.scBirthday && scope.scBirthday.length > 0) {
        //              $log.log(scope.scBirthday);

        if (scope.scBirthday.indexOf('-') > 0) {
          var bdayArray = scope.scBirthday.split('-');

          scope.birthYear = parseInt(bdayArray[0], 10);
          scope.birthDate = parseInt(bdayArray[2], 10);
          scope.birthMonth = scope.months[bdayArray[1] - 1];
        }
      }

      function tabulateBirthdate() {
        scope.scBirthday = new Date(scope.birthMonth + ' ' + scope.birthDate + ',' + scope.birthYear);

        if (scope.scBirthday == 'Invalid Date') {
          delete scope.scBirthday;
        }
      }
    }
  };
}

angular.module('classy').directive('scBirthday', scBirthday);
})();