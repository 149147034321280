(function(){
'use strict';

scVideoSvc.$inject = ["$http"];
function scVideoSvc($http) {
  var youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/,
      vimeoRegex = /^.*(?:vimeo\.com\/)(?:(?:channels\/[A-z]+\/)|(?:groups\/[A-z]+\/videos\/))?([0-9]+)/;

  function isValidYTubeUrl(url) {
    return angular.isDefined(url) && youtubeRegex.test(url.split(' ').join(''));
  }

  function isValidVimeoUrl(url) {
    return angular.isDefined(url) && vimeoRegex.test(url.split(' ').join(''));
  }

  function getVideoId(url) {
    if (typeof url !== 'undefined') {
      var trimmedUrl = url.split(' ').join('');
      var ytMatch = trimmedUrl.match(youtubeRegex);
      var vimeoMatch = trimmedUrl.match(vimeoRegex);

      if (ytMatch && ytMatch.length > 1) {
        return ytMatch[1];
      } else if (vimeoMatch && vimeoMatch.length > 1) {
        return vimeoMatch[1];
      }

      return undefined;
    }

    return undefined;
  }

  function getVimeoThumbnail(url) {
    return $http({
      url: 'https://vimeo.com/api/v2/video/' + getVideoId(url) + '.json',
      method: 'GET',
      headers: {
        'csrf-token': undefined
      }
    });
  }

  function getYoutubeThumbnail(url) {
    return 'https://i1.ytimg.com/vi/' + getVideoId(url) + '/hqdefault.jpg';
  }

  function getVideoType(url) {
    if (isValidYTubeUrl(url)) {
      return 'youtube';
    } else if (isValidVimeoUrl(url)) {
      return 'vimeo';
    }

    return null;
  }

  function getVideoUrl(url) {
    var type = getVideoType(url);
    var id = getVideoId(url);

    if (id && type) {
      if (type == 'youtube') {
        return 'https://www.youtube.com/v/' + id;
      } else if (type == 'vimeo') {
        return 'https://vimeo.com/' + id;
      }
    }

    return false;
  }

  // Video API
  return {
    validate: function validate(url) {
      return isValidYTubeUrl(url) || isValidVimeoUrl(url);
    },

    getVideoId: getVideoId,
    getType: getVideoType,
    getVideoUrl: getVideoUrl,
    getVimeoThumbnail: getVimeoThumbnail,
    getYoutubeThumbnail: getYoutubeThumbnail
  };
}

angular.module('classy').factory('scVideoSvc', scVideoSvc);
})();