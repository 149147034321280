(function(){
'use strict';

/**
 * @kind service
 * @name scBlockBackgroundPresets
 *
 * @description
 * Creates a style object with an rgba()
 * background color when passed a hex
 * and opacity.
 */
angular.module('classy').constant('scBlockBackgroundPresets', {
  adaptive: {
    label: 'Adaptive Cover',
    styles: {
      position: 'center center',
      size: 'initial',
      repeat: 'no-repeat',
      fixed: false
    }
  },
  center: {
    label: 'Center',
    styles: {
      position: 'center center',
      size: 'auto auto',
      repeat: 'no-repeat',
      fixed: false
    }
  },
  tile: {
    label: 'Tile',
    styles: {
      position: 'top left',
      size: 'auto auto',
      repeat: 'repeat',
      fixed: false
    }
  },
  stretch: {
    label: 'Stretch',
    styles: {
      position: 'center center',
      size: 'cover',
      repeat: 'no-repeat',
      fixed: false
    }
  },
  coverfixed: {
    label: 'Cover & Fixed',
    styles: {
      position: 'center center',
      size: 'cover',
      repeat: 'no-repeat',
      fixed: true
    }
  }
});
})();