(function(){
'use strict';

angular.module('classy').service('CryptoGivingDAL', ["$http", "$q", "scCampaignsService", "scOrganizationsService", "CryptoGiving", function ($http, $q, scCampaignsService, scOrganizationsService, CryptoGiving) {
  var _this = this;

  // DAL cache declaration
  this.cache = {
    checkout: {
      // TODO default values?
      symbol: null,
      amount: null,
      opt_in: null,
      opt_in_wording: null,
      member_first_name: null,
      member_last_name: null,
      member_email_address: null,
      billing_postal_code: null,
      application_id: null
    },
    depositAddresses: {}
  };
  // [TODO]
  // designation id
  // is anon
  // hide donation amount

  this.updateCache = function (cache) {
    _this.cache = _.extend({}, _this.cache, cache); // dereference pointer
  };

  this.getMarketData = function (coin) {
    if (!coin) {
      return $q.reject('[CryptoGivingDAL:getMarketData] @param "coin" required.');
    }

    return coin.getMarketData();
  };

  this.checkout = function (payload) {
    if (_.isEmpty(payload)) {
      return $q.reject('[crypto-giving.services.dal:checkout] Payload required.');
    }

    // reject if coin is not valid
    var activeCoin = CryptoGiving.getCoin(payload.symbol);
    if (!activeCoin) {
      return $q.reject('[crypto-giving.services.dal:checkout] Invalid coin.');
    }

    var self = _this;
    var path = '/frs-api/campaign/' + scCampaignsService.active.current.id + '/cg-checkout';
    var load = _.extend({ application_id: scOrganizationsService.getTag('classyPayApplicationId') }, payload);

    // if already checked out with coin in current session, do not checkout again
    // need this check because a user can go back in navigation
    if (_this.cache.depositAddresses[load.symbol]) {
      return $q.when(_this.cache.depositAddresses[load.symbol]); // TODO empty?
    }

    _this.updateCache({ checkout: load });

    return $http.post(path, load).then(function (res) {
      var data = res.data || {};
      var depositAddress = data.depositAddress;

      // only set cache if valid
      if (depositAddress) {
        self.cache.depositAddresses[load.symbol] = depositAddress; // set cache for given symbol to true
        activeCoin.depositAddress = depositAddress;
        return depositAddress;
      }
      // reject if no deposit address
      else {
          return $q.reject();
        }
    }).then(function (err) {
      return err;
    });
  };
}]);

// [TODO]
// - POST create deposit address
// - is formData good naming?
// - improve param validation

// @params
// - coin: CryptoCoin
// - formData: user inputs on forms, eg email
// @return promise
// this.createDepositAddress = (coin, formData) => {
//   // First: validate function requirements
//   if (!coin) {
//     return $q.reject('[CryptoGivingDAL:createDepositAddress] @param "coin" required.');
//   }

//   // Next: if coin has deposit address, skip request and return coin
//   if (coin.depositAddress) {
//     return $q.when(coin);
//   }

//   // Finally: if still here go ahead and create the deposit address
//   return coin.createDepositAddress(formData);
// };
})();