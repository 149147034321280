(function(){
'use strict';

scCurrencyPicker.$inject = ["$state", "$filter", "$location", "scCurrencyService", "scCampaignsService", "scThemesService", "scOrganizationsService"];
var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

function scCurrencyPicker($state, $filter, $location, // Ensured $location is used correctly
scCurrencyService, scCampaignsService, scThemesService, scOrganizationsService) {
  return {
    templateUrl: 'global/directives/scCurrencyPicker/scCurrencyPicker',
    scope: {
      entity: '=?currencyEntity',
      model: '=?currencyModel',
      editable: '<?',
      disabled: '<?',
      selected: '<?'
    },
    // **Included 'element' in the link function parameters to fix ESLint error**
    link: function link(scope, element, $rootScope) {
      var LOG_PREFIX = '[scCurrencyPicker]';
      var DEFAULT_CURRENCY_PICKER_COLOR = 'rgba(0,0,0,0.6)';
      var blackListedCurrency = ['RUB', 'BYN', 'CLF'];

      // **************************************************
      // Helper Functions
      //

      // Additional function used to check multi-currency state
      scope.isCurrencySelectionDisabled = function () {
        return scCampaignsService.active.current.type === 'reg_w_fund' || scCampaignsService.active.current.type === 'ticketed' || scCampaignsService.active.multiCurrencyDisabled() || scOrganizationsService.active.multiCurrencyDisabled();
      };

      /**
       * Extracts function names and file references from the stack trace.
       * @param {string} stack - The stack trace string.
       * @returns {string} - A formatted string of function names and file references.
       */
      function extractStackDetails(stack) {
        if (!stack) return 'No stack trace available';
        return stack.split('\n').slice(1, 5) // Limit to first 4 function calls for clarity
        .map(function (line) {
          var match = line.trim().match(/at (\S+) \(([^)]+)\)/);
          if (match && match.length === 3) {
            return match[1] + ' (' + match[2] + ')';
          }
          return 'Anonymous';
        }).join(' -> ');
      }

      /**
       * Sends log data to New Relic.
       * @param {string} eventName - The name of the event.
       * @param {object} details - The details to send.
       */
      function sendToNewRelic(eventName, details) {
        if (window.newrelic && typeof window.newrelic.addPageAction === 'function') {
          window.newrelic.addPageAction(eventName, Object.assign({}, details, { level: 'trace' }));
        }
      }

      /**
       * Logs informational messages with a consistent prefix.
       * @param {string} message - The message to log.
       * @param {object} [details] - Additional details to log.
       */
      function logInfo(message) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        /* eslint-disable no-console */
        console.log(LOG_PREFIX + ' ' + message, details);
        /* eslint-enable no-console */
      }

      /**
       * Logs warning messages with a consistent prefix.
       * @param {string} message - The message to log.
       * @param {object} [details] - Additional details to log.
       */
      function logWarn(message) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        /* eslint-disable no-console */
        console.warn(LOG_PREFIX + ' ' + message, details);
        /* eslint-enable no-console */
      }

      /**
       * Logs error messages with a consistent prefix.
       * @param {string} message - The message to log.
       * @param {object} [details] - Additional details to log.
       */
      function logError(message) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        /* eslint-disable no-console */
        console.error(LOG_PREFIX + ' ' + message, details);
        /* eslint-enable no-console */
      }

      /**
       * Logs debug information and sends it to New Relic.
       * @param {string} event - The event name.
       * @param {object} [details] - Additional details.
       */
      function logDebugInfo(event) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        /* eslint-disable no-console */
        console.trace('[Currency Debug] ' + event + ':', details);
        /* eslint-enable no-console */
        if (window.newrelic) {
          window.newrelic.log('currency_' + event.toLowerCase().replace(/\s+/g, '_'), {
            level: 'trace',
            customAttributes: Object.assign({}, details, {
              stack: extractStackDetails(new Error().stack),
              url: window.location.href
            })
          });
        }
      }

      // **************************************************
      // Initialize currencyParam
      //
      var currencyParam = $state.params.currency;

      // **************************************************
      // Debugging: Watch `currency` for changes
      //
      scope.$watch('currency', function (newValue, oldValue) {
        if (!oldValue || !newValue) return; // Prevent logging undefined values

        var oldCurrency = oldValue.currency || 'undefined';
        var newCurrency = newValue.currency || 'undefined';
        var stackTrace = new Error().stack;
        var sourceTrace = extractStackDetails(stackTrace);

        // **Grouped Log: Currency Change with Stack Trace**
        /* eslint-disable no-console */
        console.group(LOG_PREFIX + ' Currency changed from ' + oldCurrency + ' to ' + newCurrency);
        console.log('Change Source: ' + sourceTrace);
        console.trace('Change Source Trace:');
        console.groupEnd();
        /* eslint-enable no-console */

        // **Detailed Log: Include Stack Trace Function Names**
        logInfo('Currency changed from ' + oldCurrency + ' to ' + newCurrency, {
          changeSource: sourceTrace,
          stack: stackTrace
        });

        // Send structured log to New Relic
        sendToNewRelic('currency_change_detected', {
          old_currency: oldCurrency,
          new_currency: newCurrency,
          source_trace: sourceTrace,
          stack: stackTrace,
          url: window.location.href,
          session_id: window.heap ? window.heap.userId : undefined,
          referrer: document.referrer
        });
      });

      // **************************************************
      // Debugging: Watch `showDropdown` & `disabled`
      //
      scope.$watchGroup(['showDropdown', 'disabled'], function (newValues) {
        var _newValues = _slicedToArray(newValues, 2),
            showDropdown = _newValues[0],
            disabled = _newValues[1];

        logInfo('Dropdown visibility: ' + showDropdown + ' Disabled state: ' + disabled);
      });

      // **************************************************
      // Scope Functions
      //

      /**
       * Option Swap Handler
       */
      scope.optionSwap = function () {
        if (scope.isCurrencySelectionDisabled() && window.newrelic) {
          window.newrelic.log('currency_donor_selection', {
            level: 'trace',
            customAttributes: {
              currency: scope.currency.currency,
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            }
          });
        }

        // **Grouped Log: Option Swap Triggered**
        /* eslint-disable no-console */
        console.group(LOG_PREFIX + ' Option Swap Triggered');
        console.log('Setting model to ' + scope.currency.currency);
        console.trace('Option Swap Trace:');
        console.groupEnd();
        /* eslint-enable no-console */

        // **Detailed Log: Option Swap**
        logInfo('Option Swap Triggered', {
          newModel: scope.currency.currency,
          stack: new Error().stack
        });

        scope.model = scope.currency.currency;
      };

      /**
       * Picked Currency Handler
       */
      scope.pickedCurrency = function () {
        scope.currency = _.filter(scope.currencyOptions, function (option) {
          return option.currency == scope.entityCurrency;
        })[0] || scope.currencyOptions[0];

        if (!_.get(scope, 'currency.fullSymbol', false)) {
          scope.currency.fullSymbol = scCurrencyService.getCurrencySymbol(scope.entityCurrency);
        }

        // **Grouped Log: Picked Currency**
        /* eslint-disable no-console */
        console.group(LOG_PREFIX + ' Picked Currency');
        console.log('Currency set to ' + scope.currency.currency);
        console.trace('Picked Currency Trace:');
        console.groupEnd();
        /* eslint-enable no-console */

        // **Detailed Log: Picked Currency**
        logInfo('Picked Currency', {
          selectedCurrency: scope.currency.currency,
          stack: new Error().stack
        });

        scope.model = scope.currency.currency;
      };

      /**
       * Change Span Style
       * @returns {object} - Styles object
       */
      scope.changeSpanStyle = function () {
        var styles = {
          color: '#fff',
          'background-color': scThemesService.active.current.styles.primaryColor,
          'background-image': scope.showDropdown ? 'url(/static/global/images/dropdown-arrow.svg)' : '',
          'border-radius': 0
        };
        return styles;
      };

      /**
       * Default Style
       * @param {boolean} [span=false] - Whether to apply span styles
       * @returns {object} - Styles object
       */
      scope.defaultStyle = function () {
        var span = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var color = $filter('adjustColorOpacity')(scThemesService.active ? scThemesService.active.current.styles.primaryColor : DEFAULT_CURRENCY_PICKER_COLOR, 0.12);
        var styles = {
          'border-color': color
        };

        if (span) {
          styles['background-color'] = color;
        }
        return styles;
      };

      /**
       * Change Wrapper Style
       * @returns {object} - Styles object
       */
      scope.changeWrapperStyle = function () {
        var styles = {
          'border-color': scThemesService.active.current.styles.primaryColor
        };
        return styles;
      };

      // **************************************************
      // Initialization and Setup
      //

      /**
       * Initialize Currency Picker
       */
      function initCurrencyPicker() {
        scope.currencyOptions = scCurrencyService.currencies;

        logDebugInfo('Currency Picker Initialization', {
          entityCurrency: scope.entityCurrency,
          currentModel: scope.model,
          defaultCurrency: scCurrencyService.getDefaultCurrency(),
          currencyOptionsCount: _.size(scope, 'currencyOptions')
        });

        try {
          // **Made $location usage explicit by assigning to a variable**
          var currentPath = $location.path();

          if (scCampaignsService.active && scCampaignsService.active.current && scCampaignsService.active.current.type !== 'reg_w_fund' && scCampaignsService.active.current.type !== 'ticketed' && currentPath.includes('manage')) {
            scCampaignsService.active.getOverview().then(function (overviewData) {
              if (overviewData.transactions_count > 0) {
                scope.disabled = true;
                scope.tooltipText = 'Goal currency cannot be changed after a campaign has received its first donation.';
                logInfo('Currency selection disabled due to transactions_count > 0', {
                  transactions_count: overviewData.transactions_count
                });
              }
            });
          }

          // Handle blacklisted currencies
          var currency = scope.model || scCurrencyService.localeCurrency;
          scope.currencyOptions = scCurrencyService.currencies;

          if (blackListedCurrency.includes(currency)) {
            if (window.newrelic) {
              window.newrelic.log('unset_blacklisted_currency', {
                level: 'trace',
                customAttributes: {
                  blacklisted_currency: currency,
                  urlParam: currencyParam ? currencyParam.toLocaleUpperCase() : 'N/A',
                  url: window.location.href,
                  heapUserId: window.heap ? window.heap.userId : undefined,
                  referrer: document.referrer
                }
              });
            }
            logWarn('Blacklisted currency detected: ' + currency, {
              attemptedCurrency: currency,
              fallbackCurrency: 'USD'
            });
            currency = 'USD';
          }

          // Filter currency options for donations
          if (scope.entity === 'donation') {
            scope.currencyOptions = scope.currencyOptions.filter(function (currObj) {
              return !blackListedCurrency.includes(currObj.currency);
            });
            logInfo('Filtered blacklisted currencies for donation entity', {
              currencyOptions: scope.currencyOptions.map(function (c) {
                return c.currency;
              })
            });
          }

          // Handle currencyParam
          if (!!currencyParam && scope.isCurrencySelectionDisabled()) {
            if (window.newrelic) {
              window.newrelic.log('currency_url_param', {
                level: 'trace',
                customAttributes: {
                  urlParam: currencyParam.toLocaleUpperCase(),
                  url: window.location.href,
                  heapUserId: window.heap ? window.heap.userId : undefined,
                  referrer: document.referrer
                }
              });
            }
            logInfo('Currency URL param is present but selection is disabled: ' + currencyParam.toLocaleUpperCase());
          }

          // Set entityCurrency based on currencyParam if applicable
          if (!scope.isCurrencySelectionDisabled() && currencyParam && scope.currencyOptions.length && scope.currencyOptions.find(function (co) {
            return co.currency === currencyParam.toLocaleUpperCase();
          })) {
            scope.entityCurrency = currencyParam.toLocaleUpperCase();
            logInfo('Setting entityCurrency based on currencyParam', {
              currencyParam: scope.entityCurrency
            });
            scope.pickedCurrency();
            return;
          }

          var defaultCurrency = scCurrencyService.getDefaultCurrency();

          if (scope.showDropdown) {
            if (scope.entity == 'campaign' && !scope.model && !_.get(scCampaignsService, 'active', false)) {
              scope.entityCurrency = currency || scCurrencyService.getDefaultCurrency();
            } else if (scope.entity != 'donation') {
              if (!scope.model && !_.get(scope, 'entity.id', false)) {
                scope.entityCurrency = currency || scCurrencyService.getEntityCurrency(scope.entity);
              } else if (!scope.model) {
                scope.entityCurrency = scCurrencyService.getEntityCurrency(scope.entity);
              } else {
                scope.entityCurrency = scope.model;
              }
            } else if ($state.current.name.indexOf('setup') > -1 || $state.current.name.indexOf('create') > -1 || $state.current.name.indexOf('design') > -1 || SC.preview) {
              // if previewing donation page, instead of displaying
              // localized currency, display the campaign default currency
              // Note that we also have to check SC.preview because the hashbangs in the path to the
              // donation page during preview mode cause the preview stateParam not to be properly
              // registered
              scope.entityCurrency = scCurrencyService.getEntityCurrency(scCampaignsService.active.current);
              logInfo('Setting entityCurrency based on campaign default currency', {
                entityCurrency: scope.entityCurrency
              });
            } else {
              if (!scope.currencyOptions.length) {
                scope.showDropdown = false;
                scope.currencyOptions = [{
                  currency: defaultCurrency,
                  meta: {
                    code: defaultCurrency,
                    symbol: scCurrencyService.getCurrencySymbol(defaultCurrency)
                  }
                }];
                logWarn('No currency options available, setting to default currency', {
                  defaultCurrency: defaultCurrency
                });
              }
              scope.entityCurrency = currency || defaultCurrency;
              logInfo('Setting entityCurrency to model or default currency', {
                entityCurrency: scope.entityCurrency
              });
            }
            scope.pickedCurrency();
          } else {
            scope.entityCurrency = scope.model || defaultCurrency;
            logInfo('Setting entityCurrency to model or default currency (no dropdown)', {
              entityCurrency: scope.entityCurrency
            });
            scope.pickedCurrency();
          }

          logInfo('Currency Picker Initialized', {
            entityCurrency: scope.entityCurrency,
            model: scope.model,
            showDropdown: scope.showDropdown
          });
        } catch (error) {
          logError('Currency Picker Initialization Error', {
            error: error.message,
            stack: extractStackDetails(error.stack)
          });
        }
      }

      // **************************************************
      // Watchers and Event Listeners
      //

      // Watch for changes in `editable` to adjust `showDropdown`
      scope.$watch('editable', function (newVal) {
        if (!_.isUndefined(newVal)) {
          scope.showDropdown = newVal;
          logInfo('Editable changed: ' + newVal, {
            stack: new Error().stack
          });
        }
      });

      // Initialize `showDropdown` based on bindings and services
      if (!_.isUndefined(scope.editable)) {
        scope.showDropdown = scope.editable;
      } else if (scCampaignsService.active) {
        scope.showDropdown = !scCampaignsService.active.multiCurrencyDisabled();
      } else {
        // campaign model is not yet available when creating a new campaign,
        // so check for campaign type via the state params
        scope.showDropdown = ['crowdfunding', 'p2p', 'donation'].includes($state.params.campaignType) && !scOrganizationsService.active.multiCurrencyDisabled();
      }

      logInfo('showDropdown: ' + scope.showDropdown, {
        stack: new Error().stack
      });

      // **************************************************
      // Initialization and Setup
      //

      // Initialize Currency Picker
      initCurrencyPicker();

      // **************************************************
      // Logging: Listen for Global Events That Might Affect Currency
      //

      var deregisterEvents = [$rootScope.$on('$stateChangeSuccess', function () {
        logDebugInfo('Route Change Detected');
      }), $rootScope.$on('currencyUpdate', function (_event, data) {
        logDebugInfo('Currency Update Event', { data: data });
      })];

      // **************************************************
      // Cleanup Event Listeners
      //
      scope.$on('$destroy', function () {
        deregisterEvents.forEach(function (deregister) {
          return deregister();
        });
        logDebugInfo('Directive Destroyed');
      });

      // **************************************************
      // Additional Logging: Monitor DOM Events on the Select Element
      //

      var selectElement = element.find('select');
      if (scope.isCurrencySelectionDisabled() && selectElement.length) {
        selectElement.on('change keydown focus blur', function (event) {
          logDebugInfo('Select Event Triggered', {
            type: event.type,
            targetValue: event.target.value,
            isVisible: $(event.target).is(':visible')
          });
        });
      }
    }
  };
}

angular.module('classy').directive('scCurrencyPicker', scCurrencyPicker);
})();