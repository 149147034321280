(function(){
'use strict';

// bug in paypal.. img not clossed
angular.module('classy').component('egPayPaypal', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'global/embedded-giving/pay/components/paypal/eg.pay.components.paypal', // same folder as this
  bindings: {
    model: '<',
    onReady: '&',
    onSuccess: '&',
    onCancel: '&',
    currency: '<'
  },
  controller: ["$window", "cpAPI", "cpConstants", "scDigitalWalletsService", "$rootScope", function cpPaypalWidget($window, cpAPI, cpConstants, scDigitalWalletsService, $rootScope) {
    var _this2 = this;

    this.constants = cpConstants;
    this.config = {};

    this.$onChanges = function () {
      if (this.currency) {
        this.setupPaypal();
      }
    };

    this.setupPaypal = function () {
      var _this = this;

      cpAPI.getBraintreeToken(this.currency).then(function (token) {
        $window.braintree.setup(token, 'custom', {
          paypal: {
            headless: true,
            onCancelled: _this.onPaypalCancel
          },
          onPaymentMethodReceived: function onPaymentMethodReceived(params) {
            _this.model.status = cpConstants.STATUS.SUCCESS;
            _this.model.nonce = params.nonce;
            _this.onSuccess({ params: params });

            _this.ngModelCtrl.$setValidity('required', true);
            _this.ngModelCtrl.$setDirty();
          },
          onReady: function onReady(params) {
            _this.paypal = params.paypal;
            _this.teardown = params.teardown;
            _this.onReady();
          }
        });
      });

      this.onPaypalCancel = function () {
        _this.model.status = cpConstants.STATUS.READY;
        _this.model.nonce = null;
        _this.onCancel();

        _this.ngModelCtrl.$setViewValue(null);
        _this.ngModelCtrl.$setPristine();
        _this.ngModelCtrl.$setUntouched();
      };

      scDigitalWalletsService.addOnDigitalWalletsSubmitListener(function () {
        if (_this.model.status === cpConstants.STATUS.SUCCESS) {
          _this.onPaypalCancel();
        }
      });
    };

    this.openModal = function () {
      _this2.onCancel();
      if (!_this2.paypal) return;
      _this2.paypal.initAuthFlow();
    };

    $rootScope.$on('openPaymentModal', function (_event, paymentMethod) {
      if (paymentMethod === _this2.constants.PAYMENT_METHODS.PAYPAL) {
        _this2.openModal();
      }
    });
  }]
});
})();