(function(){
'use strict';

function scValidatePhone() {
  return {
    require: '?ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.phoneNumber = function (modelVal, viewVal) {
        var val = modelVal || viewVal;
        if (!val) return true;
        return val.replace(/[^\d]/g, '').length >= 9;
      };
    }
  };
}

angular.module('classy').directive('scValidatePhone', scValidatePhone);
})();