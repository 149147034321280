(function(){
'use strict';

/**
 * @kind directive
 * @name scInfiniteScrollContent
 *
 * @requires scInfiniteScrollViewport
 *
 * @description
 * Used in conjunction with scInfiniteScrollViewport. Placed on the content
 * that should infinite-scroll, inside an scInfiniteScrollViewport container.
 */

function scInfiniteScrollContent() {
  return {
    require: '^scInfiniteScrollViewport',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ctrl) {
      ctrl.setContent(element);
    }
  };
}

angular.module('classy').directive('scInfiniteScrollContent', scInfiniteScrollContent);
})();