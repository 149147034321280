(function(){
'use strict';

/**
 * @name isFuture
 * @kind filter
 */

function isFuture() {
  return function (items, dateFieldName) {
    if (typeof items !== 'undefined') {
      return items.filter(function (item) {
        return moment(item[dateFieldName || 'date']).isAfter(new Date());
      });
    }

    return undefined;
  };
}

angular.module('classy').filter('isFuture', isFuture);
})();