(function(){
'use strict';

faqService.$inject = ["$http"];
function faqService($http) {
  return {
    index: function index(campaignId) {
      return $http({
        method: 'GET',
        url: '/frs-api/campaign/' + campaignId + '/faqs',
        loadingScope: true
      });
    },
    post: function post(campaignId, faq) {
      return $http({
        method: 'POST',
        url: '/frs-api/campaign/' + campaignId + '/faqs',
        data: faq,
        loadingScope: true
      });
    },
    defaultFaq: function defaultFaq(organizationName) {
      return {
        question: 'Is this donation tax-deductible?',
        answer: organizationName + ' is a 501(c)3 tax-exempt ' + 'organization and your donation is tax-deductible within the ' + 'guidelines of U.S. law. To claim a donation as a deduction on ' + 'your U.S. taxes, please keep your email donation receipt as your ' + "official record. We'll send it to you upon successful completion " + 'of your donation.',
        type: 'checkout',
        tag: 'tax',
        weight: '0'
      };
    },
    put: function put(faq) {
      return $http({
        method: 'PUT',
        url: '/frs-api/faqs/' + faq.id,
        data: faq,
        loadingScope: true
      });
    }
  };
}

angular.module('classy').service('faqService', faqService);
})();