(function(){
'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/* eslint-disable no-console */
angular.module('classy').directive('donationGridCurrency', ["$log", "$rootScope", "$state", "EmbeddedGivingColors", "scCurrencyService", "scCampaignsService", "scOrganizationsService", function ($log, $rootScope, $state, EmbeddedGivingColors, scCurrencyService, scCampaignsService, scOrganizationsService) {
  return {
    templateUrl: 'global/components/fields/donationGridCurrency/donation-currency',
    scope: {
      entity: '=?currencyEntity',
      model: '=?currencyModel',
      editable: '<?',
      disabled: '<?',
      selected: '<?'
    },
    link: function link(scope, element) {
      // ----------------------------------------------------------------------
      // SETUP LOGGING
      // ----------------------------------------------------------------------
      var LOG_PREFIX = '[donationGridCurrency]';

      function extractStackDetails(stack) {
        if (!stack) return 'No stack trace available';
        return stack.split('\n').slice(1, 5).map(function (line) {
          var match = line.trim().match(/at (\S+) \(([^)]+)\)/);
          if (match && match.length === 3) {
            return match[1] + ' (' + match[2] + ')';
          }
          return 'Anonymous';
        }).join(' -> ');
      }

      function sendToNewRelic(eventName, details) {
        if (window.newrelic && typeof window.newrelic.addPageAction === 'function') {
          $log.log('Logging to newrelic');
          window.newrelic.log(eventName, Object.assign({
            default_currency: scCurrencyService.getDefaultCurrency(),
            locale_currency: scCurrencyService.localeCurrency,
            multiCurrencyDisabled: scope.isCurrencySelectionDisabled,
            userAgent: window.UAParser().ua
          }, details, { level: 'trace' }));
        }
      }

      function logInfo(message) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        $log.log(LOG_PREFIX + ' ' + message, details);
      }

      function logWarn(message) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        $log.warn(LOG_PREFIX + ' ' + message, details);
      }

      function logError(message) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        $log.error(LOG_PREFIX + ' ' + message, details);
      }

      // We'll make this accessible on scope if `link` or the template needs it
      scope.logDebugInfo = function (event) {
        var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        console.trace('[Currency Debug] ' + event + ':', details);
        if (window.newrelic) {
          window.newrelic.log('currency_' + event.toLowerCase().replace(/\s+/g, '_'), {
            level: 'trace',
            customAttributes: Object.assign({}, details, {
              stack: extractStackDetails(new Error().stack),
              url: window.location.href
            })
          });
        }
      };

      // ----------------------------------------------------------------------
      // INITIALIZE COLORS / OTHER SETTINGS
      // ----------------------------------------------------------------------
      var colorPrimary = EmbeddedGivingColors.getPrimary();
      var colorAccentOpaque = EmbeddedGivingColors.getAccentOpaque();

      // ----------------------------------------------------------------------
      // SCOPE FUNCTIONS
      // ----------------------------------------------------------------------
      scope.isDisabled = function () {
        return scope.disabled || !scope.showDropdown;
      };

      // Additional function used to check multi-currency state
      scope.isCurrencySelectionDisabled = function () {
        return scCampaignsService.active.current.type === 'reg_w_fund' || scCampaignsService.active.current.type === 'ticketed' || scCampaignsService.active.multiCurrencyDisabled() || scOrganizationsService.active.multiCurrencyDisabled();
      };

      scope.getCurrencyWrapperStyles = function (selected) {
        var styles = void 0;
        if (selected) {
          styles = {
            color: '#fff',
            background: colorPrimary,
            'border-color': colorPrimary,
            'background-color': colorPrimary
          };
        } else {
          styles = {
            'border-color': colorAccentOpaque,
            'background-color': colorAccentOpaque
          };
        }
        if (scope.isDisabled()) {
          styles.cursor = 'default';
        }
        return styles;
      };

      scope.getCurrencyLabelStyles = function (selected) {
        var styles = void 0;
        if (selected) {
          styles = {
            color: '#fff',
            'background-color': colorPrimary,
            'border-radius': '3px 0px 0px 3px',
            'background-image': 'unset'
          };
        } else {
          styles = {
            'border-color': colorAccentOpaque,
            'background-color': colorAccentOpaque,
            'border-right': '1px solid WHITE',
            'background-image': 'unset'
          };
        }
        return styles;
      };

      scope.onCurrencySelect = function () {
        logInfo('onCurrencySelect() triggered');
        if (scope.isCurrencySelectionDisabled()) {
          logWarn('Currency selection is disabled', {
            model_currency: scope.model,
            currency: scope.currency.currency,
            url: window.location.href,
            heapUserId: window.heap ? window.heap.userId : undefined,
            referrer: document.referrer
          });
          // Send structured log to New Relic
          sendToNewRelic('currency_selection_disabled', {
            model_currency: scope.model,
            currency: scope.currency.currency,
            url: window.location.href,
            heapUserId: window.heap ? window.heap.userId : undefined,
            referrer: document.referrer
          });
          // Set currency to default before syncing model
          scope.currency.currency = scCurrencyService.getDefaultCurrency() || 'USD';
        }
        scope.syncCurrencyModel();
      };

      scope.syncCurrencyModel = function () {
        logInfo('syncCurrencyModel() called: Setting model to ' + scope.currency.currency);
        scope.model = scope.currency.currency;
      };

      // ----------------------------------------------------------------------
      // WATCHERS / EVENTS: Move them all to the controller
      // ----------------------------------------------------------------------

      // UPDATED: to only log if difference
      // Example: Watch group for showDropdown & disabled
      scope.$watchGroup(['showDropdown', 'disabled'], function (newValues, oldValues) {
        var _newValues = _slicedToArray(newValues, 2),
            newDropdown = _newValues[0],
            newDisabled = _newValues[1];

        var _oldValues = _slicedToArray(oldValues, 2),
            oldDropdown = _oldValues[0],
            oldDisabled = _oldValues[1];

        // Only log if at least one actually changed


        if (newDropdown !== oldDropdown || newDisabled !== oldDisabled) {
          $log.log('Dropdown changed from ' + oldDropdown + ' to ' + newDropdown + ', \n          Disabled changed from ' + oldDisabled + ' to ' + newDisabled);
        }
      });

      // UPDATED: We had two currency watchers. And the second is a deep watch which is very expensive.
      // Also, we looking at the code we don't need to watch the entire currency object, just the currency property.
      // So I made a new watcher and commented the two old ones out. Below is the new:
      scope.$watch('currency.currency', function (newVal, oldVal) {
        // If there's no actual change, exit early
        if (!oldVal || !newVal || newVal === oldVal) return;

        // Fallback to 'undefined' if oldVal/newVal are null-ish
        var oldCurrency = oldVal || 'undefined';
        var newCurrency = newVal || 'undefined';
        // const stackTrace = new Error().stack; // UPDATED: this will be an expensve operation. I removed
        // const sourceTrace = extractStackDetails(stackTrace);

        console.group(LOG_PREFIX + ' Currency changed from ' + oldCurrency + ' to ' + newCurrency);
        // console.log(`Change Source: ${sourceTrace}`);
        // console.trace('Change Source Trace:');
        console.groupEnd();

        sendToNewRelic('currency_change_detected', {
          old_currency: oldCurrency,
          new_currency: newCurrency,
          // source_trace: sourceTrace,
          // stack: stackTrace,
          url: window.location.href,
          session_id: window.heap ? window.heap.userId : undefined,
          referrer: document.referrer
        });
      });

      // UPDATED: getting stack trace is expensive and model changes happen a lot.
      // Watch the model for changes
      scope.$watch('model', function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // Just log minimal info
          scope.logDebugInfo('Model Changed', {
            oldVal: oldVal,
            newVal: newVal
            // No stack trace
          });
        }
      });

      // Watch parent scope (if needed)
      if (scope.$parent) {
        scope.$parent.$watch('currencyModel', function (newVal, oldVal) {
          if (newVal !== oldVal) {
            scope.logDebugInfo('Parent Scope Change', { oldVal: oldVal, newVal: newVal });
          }
        });
      }

      // Listen for global events
      var deregisterEvents = [$rootScope.$on('$stateChangeSuccess', function () {
        scope.logDebugInfo('Route Change Detected');
      }), $rootScope.$on('currencyUpdate', function (_event, data) {
        scope.logDebugInfo('Currency Update Event', { data: data });
      })];

      // ----------------------------------------------------------------------
      // PATCH scCurrencyService.fetchRate
      // ----------------------------------------------------------------------
      var originalFetchRate = scCurrencyService.fetchRate;
      scCurrencyService.fetchRate = function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        scope.logDebugInfo('Rate Fetch Initiated', { args: args });

        return originalFetchRate.apply(this, args).then(function (result) {
          scope.logDebugInfo('Rate Fetch Success', { result: result });
          return result;
        }).catch(function (error) {
          logError('Rate Fetch Error', {
            error: error.message,
            stack: extractStackDetails(error.stack)
          });
          throw error;
        });
      };

      // ----------------------------------------------------------------------
      // INIT LOGIC
      // ----------------------------------------------------------------------
      logInfo('Setting up donationGridCurrency directive');

      // Initialize `showDropdown` based on bindings and services
      if (!_.isUndefined(scope.editable)) {
        scope.showDropdown = scope.editable;
      } else if (scCampaignsService.active) {
        scope.showDropdown = !scCampaignsService.active.multiCurrencyDisabled();
      } else {
        scope.showDropdown = ['crowdfunding', 'p2p', 'donation'].includes($state.params.campaignType) && !scOrganizationsService.active.multiCurrencyDisabled();
      }

      logInfo('showDropdown: ' + scope.showDropdown);

      var currencyParam = $state.params.currency;
      var currency = scope.model || scCurrencyService.localeCurrency;
      scope.currencyOptions = scCurrencyService.currencies;

      var blackListedCurrency = ['RUB', 'BYN', 'CLF'];

      // Handle blacklisted currencies
      if (blackListedCurrency.includes(currency)) {
        if (window.newrelic) {
          window.newrelic.log('unset_blacklisted_currency', {
            level: 'info',
            customAttributes: {
              blacklisted_currency: currency,
              urlParam: currencyParam ? currencyParam.toLocaleUpperCase() : 'N/A',
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            }
          });
        }
        logWarn('Blacklisted currency detected: ' + currency);
        currency = 'USD';
      }

      // Filter currency options for donations
      if (scope.entity === 'donation') {
        scope.currencyOptions = scope.currencyOptions.filter(function (currObj) {
          return !blackListedCurrency.includes(currObj.currency);
        });
      }

      // Log if currency param is present but selection is disabled
      if (!!currencyParam && scope.isCurrencySelectionDisabled()) {
        if (window.newrelic) {
          window.newrelic.log('currency_url_param', {
            level: 'info',
            customAttributes: {
              urlParam: currencyParam.toLocaleUpperCase(),
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            }
          });
        }
        logInfo('Currency URL param is present but selection is disabled: ' + currencyParam.toLocaleUpperCase());
      }

      // Helper to do final currency setup
      function initCurrency() {
        scope.logDebugInfo('Currency Initialization', {
          entityCurrency: scope.entityCurrency,
          currentCurrency: scope.currency ? scope.currency.currency : undefined,
          defaultCurrency: scCurrencyService.getDefaultCurrency(),
          cacheState: window.localStorage.getItem('currencyPreference')
        });

        try {
          scope.currency = _.filter(scope.currencyOptions, function (option) {
            return option.currency == scope.entityCurrency;
          })[0] || scope.currencyOptions[0];

          if (scope.isCurrencySelectionDisabled()) {
            logWarn('Currency selection is disabled at init', {
              model_currency: scope.model,
              currency: scope.currency.currency,
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            });
            sendToNewRelic('currency_select_init', {
              model_currency: scope.model,
              currency: scope.currency.currency,
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            });
          }

          scope.syncCurrencyModel();

          scope.logDebugInfo('Currency Initialization Success', {
            resultCurrency: scope.currency ? scope.currency.currency : undefined
          });
        } catch (error) {
          logError('Currency Initialization Error', {
            error: error.message,
            stack: extractStackDetails(error.stack)
          });
        }
      }

      // If currencyParam is valid and selection not disabled, try to use it
      if (!scope.isCurrencySelectionDisabled() && currencyParam && scope.currencyOptions.length && scope.currencyOptions.find(function (co) {
        return co.currency === currencyParam.toLocaleUpperCase();
      })) {
        scope.entityCurrency = currencyParam.toLocaleUpperCase();
        initCurrency();
      } else {
        var defaultCurrency = scCurrencyService.getDefaultCurrency();
        if (scope.showDropdown) {
          if (scope.entity === 'campaign' && !scope.model && !_.get(scCampaignsService, 'active', false)) {
            scope.entityCurrency = currency || defaultCurrency;
          } else if (scope.entity !== 'donation') {
            if (!scope.model && !_.get(scope, 'entity.id', false)) {
              scope.entityCurrency = currency || scCurrencyService.getEntityCurrency(scope.entity);
            } else if (!scope.model) {
              scope.entityCurrency = scCurrencyService.getEntityCurrency(scope.entity);
            } else {
              scope.entityCurrency = scope.model;
            }
          } else if ($state.current.name.indexOf('setup') > -1 || $state.current.name.indexOf('create') > -1 || $state.current.name.indexOf('design') > -1 || SC.preview) {
            scope.entityCurrency = scCurrencyService.getEntityCurrency(scCampaignsService.active.current);
          } else {
            if (!scope.currencyOptions.length) {
              scope.showDropdown = false;
              scope.currencyOptions = [{
                currency: defaultCurrency,
                meta: {
                  code: defaultCurrency,
                  symbol: scCurrencyService.getCurrencySymbol(defaultCurrency)
                }
              }];
            }
            scope.entityCurrency = currency || defaultCurrency;
          }
          initCurrency();
        } else {
          scope.entityCurrency = scope.model || defaultCurrency;
          initCurrency();
        }
      }

      // ----------------------------------------------------------------------
      // CLEANUP
      // ----------------------------------------------------------------------
      scope.$on('$destroy', function () {
        // Unpatch scCurrencyService
        scCurrencyService.fetchRate = originalFetchRate;
        // Deregister rootScope events
        deregisterEvents.forEach(function (deregister) {
          return deregister();
        });
        scope.logDebugInfo('Directive Destroyed');
      });

      var selectElement = element.find('select');
      if (scope.isCurrencySelectionDisabled() && selectElement.length) {
        selectElement.on('change keydown focus blur', function (event) {
          scope.logDebugInfo('Select Event Triggered', {
            type: event.type,
            targetValue: event.target.value,
            isVisible: $(event.target).is(':visible')
          });
        });
      }
    }
  };
}]);
})();