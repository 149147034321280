(function(){
'use strict';

frsDonationCreditCtrl.$inject = ["$filter", "$sce", "$http", "$scope", "scCampaignsService", "scCurrencyService", "scProgressDataFactory", "scBlocksService", "scFundraisingPagesService", "scFundraisingTeamsService"];
function frsDonationCreditCtrl($filter, $sce, $http, $scope, scCampaignsService, scCurrencyService, scProgressDataFactory, scBlocksService, scFundraisingPagesService, scFundraisingTeamsService) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */

  $scope.setup = {
    designation: function designation() {
      if ($scope.CONTEXT.autofill.designation) {
        $scope.MODEL.designation_id = $scope.CONTEXT.autofill.designation;
      } else if (!$scope.CONTEXT.isFundraisingPage) {
        $scope.MODEL.designation_id = $scope.campaign.current.designation_id;
      }
    },
    creditee: function creditee() {
      if ($scope.CONTEXT.isFundraisingPage) {
        return $scope.fundraiser.current;
      } else if ($scope.CONTEXT.isFundraisingTeam) {
        return $scope.team.current;
      }

      return undefined;
    },
    crediteeSearchTargets: function crediteeSearchTargets() {
      var search = [{
        service: 'scFundraisingPageSearchModel',
        fields: ['alias'],
        queryParams: {
          aggregates: true,
          filter: 'status=active'
        }
      }];
      if ($scope.CONTEXT.isCampaign) {
        search.push({
          service: 'scFundraisingTeamSearchModel',
          fields: ['name'],
          queryParams: {
            aggregates: true,
            filter: 'status=active'
          }
        });
      }

      return search;
    },
    getType: function getType() {
      if ($scope.CONTEXT.isFundraisingPage) {
        return 'fundraiser';
      } else if ($scope.CONTEXT.isFundraisingTeam) {
        return 'team';
      }
      return 'campaign';
    }
  };

  /* ---------------------------------------------------------------------- *
   * UI State
   * ---------------------------------------------------------------------- */
  $scope.state = {
    hasReachedGoal: function hasReachedGoal() {
      return scProgressDataFactory.getEntityProgress($scope.META.creditee).percentToGoal >= 1;
    },
    willReachGoal: function willReachGoal() {
      return $scope.build.revisedPTG() > 99;
    },


    crediteeSearchTargets: null
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    creditee: function creditee() {
      if (_.get($scope, 'META.creditee._type') === 'fundraising-pages') {
        var block = scBlocksService.getBlockByType('fundraiser');
        return block.current.showProgressBar;
      } else if (_.get($scope, 'META.creditee._type') === 'fundraising-teams') {
        var _block = scBlocksService.getBlockByType('team');
        return _block.current.showProgressBar;
      }
      return !!$scope.META.creditee;
    },
    designation: function designation() {
      // do not display designation if donating to a fundraiser with a set designation
      var creditToFundraiser = _.get($scope, 'META.creditee._type') === 'fundraising-pages' && _.get($scope, 'META.creditee.designation.campaign_designation_is_active');

      return $scope.campaign.DESIGNATIONS_ENABLED && $scope.campaign.current.designations_count > 1 && !creditToFundraiser;
    },
    search: function search() {
      return !$scope.CONTEXT.isFundraisingPage;
    },


    // Amount not entered / Hasn't reached goal / (May or may not reach goal)
    appealStatus: function appealStatus() {
      return !$scope.MODEL.items[0].raw_final_price && !$scope.state.hasReachedGoal();
    },


    // Amount not entered / Has reached goal / (Will exceed goal)
    successStatus: function successStatus() {
      return !$scope.MODEL.items[0].raw_final_price && $scope.state.hasReachedGoal();
    },
    progressBar: function progressBar() {
      if (!$scope.setup.creditee()) return true;
      var blockType = $scope.setup.getType();
      var block = scBlocksService.getBlockByType(blockType);
      return block.current.showProgressBar;
    },


    // Amount entered / Hasn't reached goal / Won't reach goal
    progressStatus: function progressStatus() {
      return $scope.MODEL.items[0].raw_final_price && !$scope.state.hasReachedGoal() && !$scope.state.willReachGoal();
    },


    // Amount entered / Hasn't reached goal / Will reach goal
    heroStatus: function heroStatus() {
      return $scope.MODEL.items[0].raw_final_price && !$scope.state.hasReachedGoal() && $scope.state.willReachGoal();
    },


    // Amount entered / Has reached goal / (Will exceed goal)
    extraCreditStatus: function extraCreditStatus() {
      return $scope.MODEL.items[0].raw_final_price && $scope.state.hasReachedGoal();
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    crediteeSearchDefault: function crediteeSearchDefault() {
      return $scope.setup.creditee();
    },
    crediteeSearchTeamId: function crediteeSearchTeamId() {
      return $scope.CONTEXT.isFundraisingTeam ? $scope.team.current.id : null;
    },
    searchLabel: function searchLabel() {
      if ($scope.CONTEXT.isCampaign) {
        return 'Would you like to credit this donation to a specific fundraiser or team (optional)?';
      }

      return 'Would you like to credit this donation to a specific fundraiser (optional)?';
    },
    recipientName: function recipientName() {
      var creditee = $scope.META.creditee;
      return creditee.alias || creditee.title || creditee.name;
    },
    logoSrc: function logoSrc() {
      if ($scope.META.creditee.logo) {
        return $scope.META.creditee.logo.sizes.full;
      } else if (_.get($scope, 'META.creditee._type') === 'fundraising-pages') {
        return scFundraisingPagesService.getPagePhoto($scope.META.creditee);
      } else if (_.isUndefined($scope.META.creditee.team_lead_id)) {
        return '/static/global/images/individual_default_image.png';
      } else if (_.get($scope, 'META.creditee._type') === 'fundraising-teams') {
        return scFundraisingTeamsService.getTeamPhoto($scope.META.creditee);
      }

      return '/static/global/images/team_default_image.png';
    },
    revisedTotal: function revisedTotal() {
      var amount = $scope.MODEL.items[0].raw_final_price || 0;

      var fromCurrency = $scope.MODEL.payment.raw_currency_code;
      if (fromCurrency != scCurrencyService.getDefaultCurrency()) {
        var rateToNormalized = scCurrencyService.rates[fromCurrency];
        var amountNormalized = amount / rateToNormalized;
        if (!_.isNumber(amountNormalized) || _.isNaN(amountNormalized)) {
          amountNormalized = 0;
        }
        amount = amountNormalized;
      }

      var entityProgress = scProgressDataFactory.getEntityProgress($scope.META.creditee);

      // Use goal normalized to the org currency
      var goal = $scope.META.creditee.goal;
      var percent = (entityProgress.percentToGoal + amount / goal) * 100;
      return percent;
    },
    revisedPTG: function revisedPTG() {
      var rawPercent = $scope.build.revisedTotal();
      var roundedPercent = Math.round(rawPercent);
      return rawPercent < 100 && roundedPercent === 100 ? 99 : roundedPercent;
    },
    impactStatus: function impactStatus() {
      var html = '';
      var possessive = $scope.META.creditee.team_lead_id ? 'our' : 'my';
      var pronoun = $scope.META.creditee.team_lead_id ? 'us' : 'me';

      var crediteeProgress = scProgressDataFactory.getEntityProgress($scope.META.creditee);
      var supporterRaised = Math.round(crediteeProgress.percentToGoal * 100);
      var supporterGoal = crediteeProgress.goal;
      var currency = crediteeProgress.currency;

      if ($scope.show.appealStatus()) {
        var compoundWord = $scope.META.creditee.team_lead_id ? 'We\'re' : 'I\'m';
        html = compoundWord + ' <strong>' + supporterRaised + '%</strong> of the way toward ' + possessive + ' goal of <strong>' + $filter('scFormatCurrency')(supporterGoal, currency, 0) + '!</strong>';
      } else if ($scope.show.successStatus()) {
        var who = $scope.META.creditee.team_lead_id ? 'We' : 'I';
        html = 'Thank you for your support! ' + who + ' have reached ' + possessive + ' goal of <strong>' + $filter('scFormatCurrency')(supporterGoal, currency, 0) + '!</strong>';
      } else if ($scope.show.progressStatus()) {
        html = 'You\'ll put ' + pronoun + ' <strong>' + $scope.build.revisedPTG() + '%</strong> of the way toward ' + possessive + ' goal of <strong>' + $filter('scFormatCurrency')(supporterGoal, currency, 0) + '!</strong>';
      } else if ($scope.show.heroStatus() || $scope.show.extraCreditStatus()) {
        html = 'Thank you! You\'ll have helped ' + pronoun + ' reach ' + possessive + ' goal of <strong>' + $filter('scFormatCurrency')(supporterGoal, currency, 0) + '!</strong>';
      }

      // aria attr needs to be specified here rather than in the template
      // due to ng-bind-html cuasing screen reader to ignore existing text on update
      html = '<span aria-live="polite">' + html + '</span>';

      return $sce.trustAsHtml(html);
    }
  };

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/credit/partials/' + name;
  };

  /* ------------------------------------------------------------------ *
   * Preflight
   * ------------------------------------------------------------------ */

  // If donation is credited to a fundraising page, and that fundraising page
  // has a team, we include the team ID in the metadata for Classy Pay.
  $scope.PREFLIGHT.crediteeTeam = function () {
    if ($scope.MODEL.fundraising_page_id) {
      $scope.MODEL.payment.creditee_team_id = $scope.META.creditee.fundraising_team_id;
    } else {
      $scope.MODEL.payment.creditee_team_id = null;
    }
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  _.merge($scope.MODEL, {
    fundraising_page_id: null,
    fundraising_team_id: null
  });

  _.merge($scope.META, {
    creditee: $scope.setup.creditee()
  });

  $scope.state.crediteeSearchTargets = $scope.setup.crediteeSearchTargets();
  $scope.state.searchContext = {
    campaign_id: scCampaignsService.active.current.id,
    fundraising_team_id: $scope.build.crediteeSearchTeamId()
  };

  $scope.setup.designation();

  $scope.designationOptions = {
    check: {
      enabled: false
    },
    containerClass: 'full',
    fieldName: 'designation',
    sublabel: 'What would you like your donation to support?',
    valueKey: 'id',
    labelKey: 'name'
  };

  $scope.$watch('META.creditee', function (creditee) {
    $scope.MODEL.fundraising_page_id = null;
    $scope.MODEL.fundraising_team_id = null;

    if (!creditee && !$scope.CONTEXT.autofill.designation) {
      $scope.MODEL.designation_id = $scope.campaign.current.designation_id;
      return;
    }

    if (_.isUndefined(creditee.team_lead_id)) {
      $scope.MODEL.fundraising_page_id = creditee.id;
      creditee._type = 'fundraising-pages'; // eslint-disable-line no-underscore-dangle
    } else {
      $scope.MODEL.fundraising_team_id = creditee.id;
      creditee._type = 'fundraising-teams'; // eslint-disable-line no-underscore-dangle
    }

    // IF creditee is (campaign or team) OR
    //    creditee is fundraiser w/ no designation, use campaign as default.
    // ELSE IF creditee is fundraiser with an existing designation, use fundraiser's designation.
    // ELSE IF creditee is fundraiser with an existing INACTIVE desig, use campaign's designation.
    // ELSE creditee is fundraiser chosen via search bar and designation information doesn't
    //    exist, so retrieve it to see if designation is still active.

    // eslint-disable-next-line no-underscore-dangle
    if ((creditee._type !== 'fundraising-pages' || !creditee.designation_id) && !$scope.CONTEXT.autofill.designation) {
      $scope.MODEL.designation_id = $scope.campaign.current.designation_id;
    } else if (
    // creditee's designation is active
    !_.isEmpty(creditee.designation) && creditee.designation.campaign_designation_is_active && !$scope.CONTEXT.autofill.designation) {
      $scope.MODEL.designation_id = creditee.designation_id;
    } else if (
    // creditee's designation is not active
    !_.isEmpty(creditee.designation) && !creditee.designation.campaign_designation_is_active && !$scope.CONTEXT.autofill.designation) {
      $scope.MODEL.designation_id = $scope.campaign.current.designation_id;
    } else {
      $http({
        method: 'GET',
        url: '/frs-api/campaign/' + $scope.campaign.current.id + '/designations',
        params: {
          filter: 'id=' + creditee.designation_id
        }
      }).then(function (_ref) {
        var data = _ref.data;

        var desg = data.data[0];

        // only set designation information on creditee if it's active. otherwise, use campaign.
        if (desg && desg.campaign_designation_is_active) {
          creditee.designation = desg;
          $scope.MODEL.designation_id = desg.id;
        } else {
          $scope.MODEL.designation_id = $scope.campaign.current.designation_id;
        }
      });
    }
  });
}

angular.module('classy').controller('frsDonationCreditCtrl', frsDonationCreditCtrl);
})();