(function(){
'use strict';

/**
 * @kind directive
 * @name scColorpickerField
 *
 * @description
 * Displays a colorpicker field.
 *
 * @param {string} label Field label
 * @param {expression} model Scope property containing selected color value
 * @param {string} description Helper text
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {expression} fallback Fallback color for the picker
 */

scColorpickerField.$inject = ["scField"];
function scColorpickerField(scField) {
  return scField('scColorpickerField');
}

angular.module('classy').directive('scColorpickerField', scColorpickerField);
})();