(function(){
'use strict';

angular.module('classy').constant('scRegistrationBlockDefaults', {
  title: 'Registration',
  type: 'registration',
  disable: false,
  headline: 'Registration Info',
  showHeadline: true,
  subheading: 'All attendees will be given the opportunity to fundraise',
  showSubheading: true,
  headlineColor: 'rgb(0,0,0)',
  registerButtonText: 'Register',

  // Registration info visibility settings
  showRegistrationTitle: true,
  showRegistrationPrice: true,
  showRegistrationDetails: true,

  background: {
    color: 'rgb(248, 248, 248)', // alabaster
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  registerButtonStyle: {
    preset: 'rounded',
    'border-radius': '4px',
    color: null
  },

  registerButtonColor: null,

  mobileTemplates: false,

  remove: false
});
})();