(function(){
'use strict';

/**
 * @name encodeURIComponent
 * @kind filter
 */

function encodeURIComponentFilter() {
  return function (input) {
    return encodeURIComponent(input);
  };
}

angular.module('classy').filter('encodeURIComponent', encodeURIComponentFilter);
})();