(function(){
'use strict';

/**
 * @kind directive
 */

scActivityBlock.$inject = ["scFroalaOptions", "scBlock"];
function scActivityBlock(scFroalaOptions, scBlock) {
  return scBlock({
    link: function link(scope) {
      scope.froalaOptionsPreviewHeadline = scFroalaOptions({
        type: 'edit-inline-about-title'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'Activity Headline'
      });
      scope.froalaOptionsPreviewContent = scFroalaOptions({
        type: 'edit-inline-about-title'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'Activity Content'
      });
    }
  });
}

angular.module('classy').directive('scActivityBlock', scActivityBlock);
})();