(function(){
'use strict';

angular.module('classy').component('numberField', {
  template: '\n      <form-label\n      ng-if="$ctrl.label"\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n      <div class="form__text-input__outer" data-ng-class="{ \'admin-body__lock-field\': $ctrl.lockField }">\n\n        <div ng-if="$ctrl.lockField"\n          data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n          data-sc-tooltip-position="center"\n          data-sc-tooltip-position-tip="bottom center"\n          show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n          fixed-tooltip="true"\n          data-sc-tooltip="\'This field is locked at the template level.\'"\n          class="admin-body__tooltip-element"\n        ></div>\n\n        <input class="form__text-input--dark"\n          aria-label="{{:: $ctrl.label || $ctrl.ariaLabel }}"\n          ng-disabled="$ctrl.disableFlag || false"\n          ng-model="$ctrl.model"\n          ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n          ng-change="$ctrl.onChange()"\n          ng-required="$ctrl.required"\n          ng-max="$ctrl.max"\n          ng-min="$ctrl.min"\n          sc-retain-focus-validity\n          sc-validity-reporter\n          sc-validity-classes\n          type="number"\n          name="{{:: $ctrl.name }}"\n          placeholder="{{:: $ctrl.placeholder }}"\n          _spec="input"\n        />\n          <form-error\n          name="$ctrl.name"\n          messages="$ctrl.errorMessages"\n          _spec="errors"\n        ></form-error>\n        \n      </div>\n    ',
  bindings: {
    model: '=',
    disableFlag: '=',
    label: '<?',
    name: '<',
    min: '<?',
    max: '<?',
    onChange: '&?',
    errorMessages: '<?',
    getterSetter: '<?',
    placeholder: '<?',
    required: '<?',
    ariaLabel: '@?',
    lockField: '<?'
  },
  controller: function NumberFieldCtrl() {
    function DEFAULTS() {
      return {
        errorMessages: {
          required: 'This field is required.',
          number: 'Please enter a number.',
          min: 'Please enter a number greater than or equal to ' + this.min + '.',
          max: 'Please enter a number smaller than or equal to ' + this.max + '.'
        },
        getterSetter: false,
        placeholder: '',
        required: false
      };
    }
    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS.call(this));
    };
  }
});
})();