(function(){
'use strict';

/**
 * @name scImgList
 * @kind directive
 * @package media
 *
 * @description
 * Provides a highly configurable user interface for uploading, removing,
 * selecting, arranging, and customizing images.
 *
 * scImgList has three main usage modes:
 *
 * * *multiple* (default): Displays and populates model with an array of multiple images.
 * * *select:* Displays multiple images, but forces the user to select
 *  only one with which to populate the model.
 * * *single:* Displays and populates model with a single image.
 */

scImgList.$inject = ["$animate", "$log", "$parse", "$q", "launchMediaManager", "scImg", "scQaId", "scCampaignsTemplateService", "$stateParams", "accessibilityService"];
function scImgList($animate, $log, $parse, $q, launchMediaManager, scImg, scQaId, scCampaignsTemplateService, $stateParams, accessibilityService) {
  'use strict';

  return {
    templateUrl: 'global/media/scImgList/template',

    replace: true,

    require: 'ngModel', // Added by the directive itself in the template

    transclude: true,

    scope: {
      userModel: '=scImgList',
      showCount: '=?',
      sortable: '=?',
      reUpload: '=?',
      options: '=?',
      preview: '=?',
      customHeight: '=?',
      preset: '=?',
      bc: '=?blockClass',
      mmConfig: '=?',
      description: '@?',
      persistControls: '=?',
      addVideo: '=?',
      lockField: '<?'
    },

    controller: ['$scope', '$attrs', function ($scope, $attrs) {
      $scope.addVideo = $scope.addVideo || false;
      $scope.scQaId = scQaId;
      if (typeof $scope.lockField === 'undefined' && SC.campaign.campaignTemplateData) {
        /* Setup tab for all steps */
        var getLockedFieldForSetUp = function getLockedFieldForSetUp(step) {
          if (step === 'landing') {
            var lockHeaderBlock = scCampaignsTemplateService.getBlockByType('header');
            if (lockHeaderBlock && $attrs.scImgList === 'headerBlock.current.logo') {
              $scope.lockField = lockHeaderBlock.item_attributes.logo.locked;
            }
          }
          if (step === 'team') {
            var lockTeamBlock = scCampaignsTemplateService.getBlockByType('team');
            if (lockTeamBlock && $attrs.scImgList === 'teamBlock.current.background.image') {
              $scope.lockField = lockTeamBlock.item_attributes.background.image.locked;
            }
          }
          if (step === 'fundraiser') {
            var lockFundraiserBlock = scCampaignsTemplateService.getBlockByType('fundraiser');
            if (lockFundraiserBlock && $attrs.scImgList === 'fundraiserBlock.current.background.image') {
              $scope.lockField = lockFundraiserBlock.item_attributes.background.locked || lockFundraiserBlock.item_attributes.background.image.locked;
            }
          }
          if (step === 'donation') {
            var lockDonationBlock = scCampaignsTemplateService.getBlockByType('donation');
            if (lockDonationBlock && $attrs.scImgList === 'DonationBlock.current.background.image') {
              $scope.lockField = lockDonationBlock.item_attributes.background.image.locked;
            }
          }
          if (step === 'thank-you') {
            var lockThankYouBlock = scCampaignsTemplateService.getBlockByType('thank-you');
            if (lockThankYouBlock && $attrs.scImgList === 'ThankYouBlock.current.background.image') {
              $scope.lockField = lockThankYouBlock.item_attributes.background.image.locked;
            }
          }
        };
        /* Design tab for all and common blocks */
        var getLockedField = function getLockedField(type) {
          if (typeof $scope.lockField !== 'undefined') {
            return $scope.lockField;
          }
          var lockedBlock = scCampaignsTemplateService.getBlockByType(type);
          if (lockedBlock) {
            var field = lockedBlock.item_attributes;
            if ($attrs.scImgList.includes('image')) {
              $scope.lockField = field.background.locked || field.background.image.locked;
            } else if ($attrs.scImgList.includes('logo')) {
              $scope.lockField = field.logo && field.logo.locked;
            } else if ($attrs.scImgList.includes('defaultTeamLogo')) {
              $scope.lockField = field.defaultTeamLogo.locked;
            } else if ($attrs.scImgList.includes('defaultFundraiserLogo')) {
              $scope.lockField = field.defaultFundraiserLogo.locked;
            }
          }
          return null;
        };
        var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
        if (blockObj) {
          getLockedField(blockObj.type);
        } else if ($stateParams.step) {
          getLockedFieldForSetUp($stateParams.step);
        }
      }
      /* ------------------------------------------------------------------ *
       * Utils
       * ------------------------------------------------------------------ */

      $scope.util = {
        initImg: function initImg(file, isNew) {
          var isPreset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

          var img = void 0;

          if (typeof Blob !== 'undefined' && file instanceof Blob && /^image\/*/.test(file.type)) {
            img = new scImg(file);
          } else if (scImg.isInstance(file) || file && file.type == 'video') {
            img = file;
          } else {
            return null;
          }

          if ($attrs.scImgInit) {
            $parse($attrs.scImgInit)($scope.$parent, {
              $scImg: img,
              $isNew: isNew,
              $isPreset: isPreset
            });
          }

          return img;
        }
      };

      /* ------------------------------------------------------------------ *
       * Public Methods
       * ------------------------------------------------------------------ */

      /**
       * @kind method
       * @name add
       * @parent scImgList
       *
       * @description
       * Add one or more images to the list. Will only add up to the limit
       * specified by the list.
       *
       * @param {Array} $newFiles Array of scImg or File objects to add.
       */
      this.add = function (newFiles, options) {
        var addOptions = _.isPlainObject(options) ? options : {};

        if (!newFiles) {
          return;
        }

        var $newFiles = !_.isArray(newFiles) ? [newFiles] : newFiles;

        var maxAllowed = $scope.limit - $scope.imgBucket.length;
        var incomingCount = Math.min(maxAllowed, $newFiles.length);

        for (var i = 0; i < incomingCount; i += 1) {
          var newIndex = $scope.imgBucket.length;
          var img = $scope.util.initImg($newFiles[i], true, false);
          if (img) {
            $scope.imgBucket[newIndex] = img;
          } else {
            incomingCount += 1;
          }
        }

        switch ($scope.mode) {
          case 'multiple':
            if (!_.isArray($scope.userModel)) {
              $scope.userModel = [];
            }

            for (var j = $scope.userModel.length; j < $scope.imgBucket.length; j += 1) {
              $scope.userModel[j] = $scope.imgBucket[j];
            }

            break;

          case 'select':
            if ($attrs.selectOnAdd && addOptions.select !== false) {
              $scope.select($scope.imgBucket.length - 1);
            }

            break;

          case 'single':
            $scope.userModel = $scope.imgBucket[0];
            break;

          default:
          // do nothing
        }
      };

      $scope.add = this.add;

      /**
       * @kind method
       * @name remove
       * @parent scImgList
       *
       * @description
       * Remove an image from the list.
       *
       * @param {number} index The index of the image to remove.
       */
      this.remove = function (index) {
        $scope.imgBucket.splice(index, 1);

        switch ($scope.mode) {
          case 'multiple':
            // god only knows why we are manually plumbing each element into userModel instead of
            // just setting them equal, but I'm too afraid to change this
            $scope.userModel = $scope.imgBucket.map(function (_) {
              return _;
            });
            break;

          case 'select':
            // Modifying selection will trigger assignment via watch
            if ($scope.selection.active === index) {
              $scope.selection.active = null;
            } else if ($scope.selection.active > index) {
              $scope.selection.active -= 1;
            }

            break;

          case 'single':
            $scope.userModel = null;
            break;

          default:
          // do nothing
        }
      };

      $scope.remove = this.remove;

      /**
       * @kind method
       * @name change
       * @parent scImgList
       *
       * @description
       * Swap out an image in the list with a new one.
       *
       * @param {number} index The index of the image to swap.
       * @param {File|scImg} $newFile Single new image or file.
       */
      this.change = function (index, newFile) {
        var $newFile = newFile;
        if (_.isArray(newFile)) {
          $log.warn('scImgList.change should not be called with an array');
          $newFile = newFile[0];
        }

        if (index >= $scope.imgBucket.length) {
          return;
        }

        var img = $scope.util.initImg($newFile, true, false);

        if (!img) {
          return;
        }

        $scope.imgBucket[index] = img;

        switch ($scope.mode) {
          case 'multiple':
            $scope.userModel[index] = img;
            break;

          case 'select':
            if (index === $scope.selection.active) {
              $scope.userModel = img;
            }

            break;

          case 'single':
            $scope.userModel = img;
            break;

          default:
          // do nothing
        }
      };

      $scope.change = this.change;

      /**
       * @kind method
       * @name select
       * @parent scImgList
       *
       * @description
       * Select an image.
       *
       * @param {number} index The index of the image to select.
       */
      this.select = function (index) {
        $scope.selection.active = index;
      };

      $scope.select = this.select;
    }],

    link: function link($scope, $element, $attrs, ngModel) {
      $animate.enabled($element, false);

      /* ------------------------------------------------------------------ *
       * Set up scope
       * ------------------------------------------------------------------ */

      // There are three modes available.
      // 'multiple'  : (Default) Upload multiple, save multiple.
      // 'select'    : Upload multiple, save one.
      // 'single'    : Upload one, save one.
      $scope.mode = $attrs.mode || 'multiple';
      $scope.limit = $attrs.limit || 1;

      // These currently only affect Pixabay download sizes.
      $scope.minWidth = $attrs.minWidth || null;
      $scope.minHeight = $attrs.minHeight || null;

      $scope.customPreviewHeight = '';
      if ($scope.customHeight) {
        $scope.customPreviewHeight = '{ "max-height":"' + $scope.customHeight + 'px"}';
      }

      // Setup and overrides based on mode
      switch ($scope.mode) {
        case 'multiple':
          // Model
          $scope.userModel = _.isArray($scope.userModel) ? $scope.userModel : [];
          $scope.imgBucket = $scope.userModel.slice(0, $scope.limit) // only use the max number allowed
          .map(function (model) {
            return $scope.util.initImg(model, false, false);
          }) // init the images
          .filter(function (_) {
            return _;
          }); // filter out anything that came back falsy

          break;

        case 'select':
          {
            // Model
            var modelImg = $scope.util.initImg($scope.userModel, false);
            $scope.imgBucket = modelImg ? [modelImg] : [];
            $scope.selection = {};
            $scope.selection.active = modelImg ? 0 : null;

            // Presets
            $scope.uniquePresets = scImg.dedupe($scope.preset, true);

            $scope.limit += $scope.uniquePresets.length;

            // filter our presets so that we only have the ones which aren't
            // the modelImg
            var presets = $scope.uniquePresets.map(function (preset) {
              return $scope.util.initImg(preset, false, true);
            }).filter(function (preset) {
              return !modelImg || !modelImg.is(preset);
            })
            // since we're unshifting, we need to reverse the array
            // in order to ensure that the order is the same as if
            // we'd been unshifting in a for loop.
            // example: [1, 2, 3, 4] would unshift into [4, 3, 2, 1]
            // if the unshift were done in each iteration of a for-loop.
            .reverse();

            // add all of the presets to the front of the imgBucket array
            Array.prototype.unshift.apply($scope.imgBucket, presets);

            // if we have a model img, update the active count to include our presets
            if (modelImg) {
              $scope.selection.active += presets.length;
            }

            // Watch active selection and assign
            $scope.$watch('selection.active', function (activeIndex) {
              if (activeIndex === null) {
                $scope.userModel = null;
              } else {
                $scope.userModel = $scope.imgBucket[activeIndex];
              }
            });

            // Overrides
            $scope.sortable = false;
            break;
          }

        case 'single':
          {
            // Model
            var _modelImg = $scope.util.initImg($scope.userModel, false);
            $scope.imgBucket = _modelImg ? [_modelImg] : [];

            // Overrides
            $scope.limit = 1;
            $scope.showCount = false;
            $scope.sortable = false;
            break;
          }
        default:
        // do nothing
      }

      // In the event that the user model is modified externally, we need to
      // bring the imgBucket up to date.
      switch ($scope.mode) {
        case 'multiple':
          $scope.$watchCollection('userModel', function (newModel, oldModel) {
            if (newModel === oldModel) {
              return;
            }

            var model = _.isArray(newModel) ? newModel : [];
            $scope.imgBucket = model
            // only use the maximum number of models
            .slice(0, $scope.limit)
            // filter out any falsy models
            .filter(function (_) {
              return _;
            });
          });

          break;

        case 'select':
          $scope.$watch('userModel', function (newModel, oldModel) {
            if (newModel === oldModel) {
              return;
            }

            if (newModel) {
              var replaced = false;
              _.forEach($scope.imgBucket, function (img, index) {
                if (img.is(newModel)) {
                  // scImg.is() doesn't test identity, so we want to ensure
                  // that imgBucket has the same reference as the model.
                  $scope.imgBucket[index] = newModel;
                  $scope.selection.active = index;
                  replaced = true;
                }
              });

              // TODO: Restricting static images should be an option
              // TODO: What if this exceeds the limit?
              if (!replaced && newModel.origin !== 'static') {
                $scope.imgBucket.push(newModel);
                $scope.selection.active = $scope.imgBucket.length - 1;
              }
            } else {
              $scope.selection.active = null;
            }
          });

          break;

        case 'single':
          $scope.$watch('userModel', function (newModel, oldModel) {
            if (newModel === oldModel) {
              return;
            }

            // TODO: Restricting static images should be an option
            // if (newModel && newModel.origin !== 'static') {
            if (newModel) {
              $scope.imgBucket[0] = newModel;
            } else if (!newModel) {
              $scope.imgBucket.splice(0, 1);
            }
          });

          break;

        default:
        // do nothing
      }

      $scope.bc = $scope.bc || 'img-list';

      $scope.mmConfig = $scope.mmConfig || {};

      $scope.previewSize = _.isFinite($scope.preview) ? $scope.preview : 550;

      /* ------------------------------------------------------------------ *
       * Select-only utilities
       * ------------------------------------------------------------------ */

      $scope.isPreset = function (img) {
        var isPreset = false;
        _.forEach($scope.uniquePresets, function (preset) {
          if (img.is(preset)) {
            isPreset = true;
          }
        });

        return isPreset;
      };

      $scope.toggleSelect = function ($event, index) {
        if ($scope.mode !== 'select') {
          return;
        }

        if ($event.target.tagName === 'INPUT' || $event.target.tagName === 'I') {
          return;
        }

        if ($scope.selection.active === index) {
          $scope.selection.active = null;
        } else {
          $scope.selection.active = index;
        }
      };

      /* ------------------------------------------------------------------ *
       * Media Manager integration
       * ------------------------------------------------------------------ */

      $scope.ui = {
        add: function add() {
          var mediaManagerOptions = Object.assign({
            title: 'Add an image',
            image: {
              minWidth: $scope.minWidth,
              minHeight: $scope.minHeight
            }
          }, $scope.mmConfig);

          launchMediaManager({ scope: $scope, config: mediaManagerOptions }).then(function (data) {
            if (!data || !data.images.length && !data.video) {
              return;
            }

            var newVideo = data.video;
            var newImgArray = [];

            newImgArray.push(data.images[0]);

            if (!newImgArray[0] && !newVideo) {
              $log.warn('Media manager returned a null asset');
              return;
            }

            if (newVideo) {
              var videoObj = [{
                url: newVideo,
                type: 'video'
              }];

              $scope.add(videoObj);
            } else {
              $scope.add(newImgArray, {});
            }

            ngModel.$setDirty();
          });
        },
        change: function change(index) {
          var mediaManagerOptions = Object.assign({
            title: 'Replace image',
            image: {
              model: 'imgBucket[' + index + ']',
              minWidth: $scope.minWidth,
              minHeight: $scope.minHeight
            }
          }, $scope.mmConfig);

          launchMediaManager({ scope: $scope, options: mediaManagerOptions }).then(function (data) {
            if (!data.images.length) {
              $scope.remove(index);
              return;
            }

            var newImg = data.images[0];

            if (!newImg) {
              $log.warn('Media manager returned a null image');
              return;
            }

            $scope.change(index, newImg);
            ngModel.$setDirty();
          });
        },
        remove: function remove(index) {
          $scope.remove(index);
          ngModel.$setDirty();
        },
        select: function select() {
          ngModel.$setDirty();
        }
      };

      $scope.$on('themeUploadButton', function () {
        $scope.ui.add();
      });

      /* ------------------------------------------------------------------ *
       * Setup sortable mode
       * ------------------------------------------------------------------ */

      var oldIndex = void 0;
      $scope.sortableOptions = {
        handle: '.fa-bars',
        // eslint-disable-next-line no-unused-vars
        start: function start(e, ui) {
          oldIndex = ui.item.index();
        },


        // eslint-disable-next-line no-unused-vars
        stop: function stop(e, ui) {
          var newIndex = ui.item.index();
          if (oldIndex == newIndex) {
            return;
          }

          $scope.$apply(function () {
            // $scope.imgBucket is transformed directly by ui-sortable ...
            // god only knows why we are manually plumbing each element into userModel instead of
            // just setting them equal, but I'm too afraid to change this
            $scope.userModel = $scope.imgBucket.map(function (_) {
              return _;
            });
          });
        }
      };

      $scope.setOption = function (img, optKey, optValue, $event) {
        $event.stopPropagation();
        img.options[optKey] = optValue;
      };

      /* ------------------------------------------------------------------ *
       * Validation
       * ------------------------------------------------------------------ */

      ngModel.$asyncValidators.uploaded = function (modelValue) {
        if (!modelValue) {
          var stupidDeferred = $q.defer();
          var stupidPromise = stupidDeferred.promise;
          stupidDeferred.resolve();
          return stupidPromise;
        } else if ($scope.mode === 'multiple') {
          var promises = [];

          _.forEach(modelValue, function (asset) {
            if (!asset.saved) {
              if (asset.type == 'video') {
                asset.saved = true;
                promises.push(asset);
              } else {
                promises.push(asset.on('save'));
              }
            }
          });

          return $q.all(promises);
        }
        return modelValue.on('save');
      };

      switch ($scope.mode) {
        case 'multiple':
          // Override $isEmpty to ensure required is false for an empty array
          ngModel.$isEmpty = function (value) {
            return _.isEmpty(value);
          };

          // Validate on change
          $scope.$watchCollection('userModel', function () {
            ngModel.$validate();
          });

          break;

        default:
          // Validate on change
          $scope.$watch('userModel', function () {
            ngModel.$validate();
          });
      }

      /* ------------------------------------------------------------------ *
       * Init
       * ------------------------------------------------------------------ */

      if ($attrs.scImgListInit) {
        $parse($attrs.scImgListInit)($scope.$parent, {
          $list: $scope.imgBucket,
          $add: $scope.add,
          $remove: $scope.remove,
          $change: $scope.change,
          $select: $scope.select
        });
      }

      $scope.onKeyDown = function ($event) {
        if ($event && accessibilityService.isValidKeyBoardEvent($event)) $scope.ui.add();
      };

      $scope.onkeydownClose = function (event, index) {
        if (event && accessibilityService.isValidKeyBoardEvent(event)) $scope.ui.remove(index);
      };
    }
  };
}

angular.module('classy').directive('scImgList', scImgList);
})();