(function(){
'use strict';

// eslint-disable-next-line no-unused-vars
debugUIRouter.$inject = ["$rootScope"];
function debugUIRouter($rootScope) {
  // $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
  //   console.log('$stateChangeStart to ' + toState.name +
  //     '- fired when the transition begins. toState,toParams : \n',
  //     fromState, fromParams, toState, toParams
  //   );
  // });
  // $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams) => {
  //   console.log('$stateChangeError - fired when an error occurs during transition.');
  //   console.log([$rootScope, ...args]);
  // });
  // $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
  //   console.log('$stateChangeSuccess to ' + toState.name +
  //     '- fired once the state transition is complete.'
  //   );
  // });
  // $rootScope.$on('$viewContentLoaded', (event) => {
  //   console.log('$viewContentLoaded - fired after dom rendered', event);
  // });
  // $rootScope.$on('$stateNotFound', (event, unfoundState, fromState, fromParams) => {
  //   console.log('$stateNotFound ' + unfoundState.to +
  //     '  - fired when a state cannot be found by its name.'
  //   );
  //   console.log(unfoundState, fromState, fromParams);
  // });
}

angular.module('classy').run(debugUIRouter);
})();