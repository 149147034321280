(function(){
'use strict';

/**
 * @kind constant
 * @name scBlockActivityThumbnailPresets
 *
 * @description
 * Preset styles for the thumbnail picture
 * of a user under Activity
 */
angular.module('classy').constant('scBlockActivityThumbnailPresets', {
  circle: {
    label: 'Circle',
    styles: {
      'border-radius': '50px'
    }
  },
  square: {
    label: 'Square',
    styles: {
      'border-radius': '0px'
    }
  },
  roundedsqaure: {
    label: 'Rounded Square',
    styles: {
      'border-radius': '4px'
    }
  }
});
})();