(function(){
'use strict';

/**
 * @kind directive
 * @name scLocationField
 *
 * @description
 * Displays a Google maps search input, as well as a map of the current value.
 *
 * @param {string} label Field label
 * @param {expression} model Model for the control
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {expression} location Location model passed to sc-map
 * @param {expression} campaign Campaign model passed to sc-map
 */

scLocationField.$inject = ["scField"];
function scLocationField(scField) {
  return scField('scLocationField');
}

angular.module('classy').directive('scLocationField', scLocationField);
})();