(function(){
'use strict';

scResourceSearchModel.$inject = ["$http", "$q"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function scResourceSearchModel($http, $q) {
  return function () {
    function ResourceSearchModel(type, fields, queryParams) {
      _classCallCheck(this, ResourceSearchModel);

      this.type = type;
      this.fields = fields;
      this.queryParams = queryParams;
    }

    /**
     * Returns the resource's name
     *
     * @abstract
     * @param {resource} resource
     * @returns {string}
     */


    _createClass(ResourceSearchModel, [{
      key: 'getName',
      value: function getName() {
        throw new Error('ResourceSearchModel#get must be implemented by child class.');
      }

      /**
       * Returns the human readable type name
       *
       * @abstract
       * @param {resource} resource
       * @returns {string}
       */

    }, {
      key: 'getReadableTypeName',
      value: function getReadableTypeName() {
        throw new Error('ResourceSearchModel#getReadableTypeName must be implemented by child class.');
      }

      /**
       * Finds all resources that match the search term.
       * Adds property _service which references `this` so each resource
       * has access to its own ResourceSearchModel subclass
       *
       * @param   {string} term The search term
       * @param   {Object} context Hash of scoping variables, e.g.
       *    {'campaign_id': 1232, 'fundraising_team_id': 4354}
       * @returns {Promise<Array<resource>>} The results
       */

    }, {
      key: 'search',
      value: function search(term, context) {
        var _this = this;

        return this.query(term, context).then(function (results) {
          _.forEach(results, function (resource) {
            resource._service = _this; // eslint-disable-line no-underscore-dangle
          });

          return results;
        });
      }

      /**
       * Returns result that match the search term
       *
       * @protected
       *
       * @param   {string} term The search term
       * @param   {Object|null} context
       * @returns {Promise<Array<resource>>} The results
       */

    }, {
      key: 'query',
      value: function query(term, context) {
        var scope = this.buildScope(context);
        var endpoint = scope + '/' + this.type;

        return this.executeQueries(endpoint, term);
      }

      /**
       * Build the API scope for the resource search.
       * The smaller the entity in the hierarchy, the higher the priority.
       * For example, a fundraising_page_id will take precedence over an organization_id
       *
       * @final
       *
       * @param  {Object} context
       * @returns {string} The API scope
       */

    }, {
      key: 'buildScope',
      value: function buildScope(context) {
        if (!context) return '/frs-api';

        var oid = context.organization_id;
        var cid = context.campaign_id;
        var tid = context.fundraising_team_id;
        var pid = context.fundraising_page_id;
        var mid = context.member_id;

        var scope = void 0;
        if (mid) {
          scope = '/members/' + mid;
        } else if (pid) {
          scope = '/fundraising-pages/' + pid;
        } else if (tid) {
          scope = '/fundraising-teams/' + tid;
        } else if (cid) {
          scope = '/campaigns/' + cid;
        } else if (oid) {
          scope = '/organizations/' + oid;
        } else {
          scope = '';
        }

        return '/frs-api' + scope;
      }

      /**
       * Given an endpoint, call the endpoint for each field
       * and merge the results. Override this method if the subclass
       * requires special search logic.
       *
       * @protected
       *
       * @param   {string} endpoint
       * @param   {string} searchTerm
       * @returns {Promise<Array<resource>>}
       */

    }, {
      key: 'executeQueries',
      value: function executeQueries(endpoint, searchTerm) {
        var _this2 = this;

        var searches = [];
        var fields = _.clone(this.fields);
        var defaultFilter = _.get(this.queryParams, 'filter', null) ? ',' + this.queryParams.filter : '';
        var lastNameIndex = _.findIndex(fields, function (field) {
          return field.indexOf('last_name') > -1;
        });
        var firstNameIndex = _.findIndex(fields, function (field) {
          return field.indexOf('first_name') > -1;
        });
        var searchHasSpace = _.indexOf(searchTerm, ' ') > -1;

        // Edge case: need to do a special search if this search bar is querying both
        // the first and last name of a member.
        if (lastNameIndex > -1 && firstNameIndex > -1 && searchHasSpace) {
          var spaceIndex = searchTerm.indexOf(' ');
          var firstName = searchTerm.substr(0, spaceIndex).trim();
          var lastName = searchTerm.substr(spaceIndex + 1).trim();

          var fNameField = fields.splice(firstNameIndex, 1)[0];
          var lNameField = fields.splice(lastNameIndex - (firstNameIndex < lastNameIndex ? 1 : 0), 1)[0];

          var filter = fNameField + '*=' + encodeURIComponent(firstName) + ',' + lNameField + '*=' + encodeURIComponent(lastName);
          var memberNameSearch = $http.get(endpoint, {
            params: _.defaults({ filter: filter + defaultFilter }, this.queryParams)
          }).then(function (res) {
            return res.data.data;
          });

          searches.push(memberNameSearch);
        }

        // If we are searching the id, and it's not a number or mongodb id, return
        if (fields && fields.length && fields.indexOf('id') !== -1 && _.isNaN(+searchTerm) && !searchTerm.match(/^[0-9a-fA-F]{24}$/)) {
          return $q.resolve([]);
        }

        _.forEach(fields, function (field) {
          var operator = void 0;

          if (field === 'id') {
            operator = '=';
          } else {
            operator = '*=';
          }

          var filter = '' + field + operator + encodeURIComponent(searchTerm);

          var search = $http.get(endpoint, {
            params: _.defaults({
              filter: filter + defaultFilter
            }, _this2.queryParams)
          }).then(function (response) {
            return response.data.data;
          });

          searches.push(search);
        });

        return $q.all(searches).then(function (res) {
          return _.flatten(res);
        });
      }

      /**
       * Retrieves the resource's description. Override
       * if the resource's description property is not 'description'.
       *
       * @param   {resource} resource
       * @returns {string}
       */

    }, {
      key: 'getDescription',
      value: function getDescription(resource) {
        return _.get(resource, 'description', '').replace(/<\/?(.*?)>|&nbsp;/g, '').replace(/&amp;/g, '&');
      }

      /**
       * Finds a resource by its id
       *
       * @abstract
       * @public
       *
       * @param {number|string} id The resource id
       * @returns {Promise<resource>}
       */

    }, {
      key: 'getResourceById',
      value: function getResourceById(id) {
        return $http.get('/frs-api/' + this.type + '/' + id).then(function (resource) {
          return resource.data;
        });
      }

      /**
       * Returns the resource's logo asset
       *
       * @param {resource} resource
       * @returns {string}
       */

    }, {
      key: 'getLogo',
      value: function getLogo(resource) {
        var logo = _.get(resource, 'logo.asset.src', _.get(resource, 'logo.cdn_url', _.get(resource, 'thumbnail_medium')));

        return logo || '/static/global/images/individual_default_image.png';
      }

      /**
       * Returns the type of the search model (e.g. 'fundraiser', 'campaign', etc)
       *
       * @returns {string}
       */

    }, {
      key: 'getType',
      value: function getType() {
        return this.type;
      }
    }]);

    return ResourceSearchModel;
  }();
}

angular.module('classy').factory('scResourceSearchModel', scResourceSearchModel);
})();