(function(){
'use strict';

angular.module('classy').component('infiniteScroller', {
  template: '\n      <div class="infinite-scroller">\n        <div class="infinite-scroller__content" ng-transclude>\n        </div>\n      </div>\n    ',
  bindings: {
    fetchFunction: '&',
    offset: '<?',
    stopGap: '<'
  },
  transclude: true,
  controller: ["$element", "$log", function infiniteScrollerCtrl($element, $log) {
    this.fetch = false;

    this.handleFetching = function () {
      var _this = this;

      if (!this.fetch) {
        return;
      }

      // how many pixels you can scroll + viewable height === total content height
      // means you've made it to the bottom
      // offset recommendation: about 450
      if (this.scrollingElement[0].scrollTop + this.scrollingElement[0].clientHeight >= this.scrollingElement[0].scrollHeight - this.offset) {
        this.fetch = false;

        this.fetchFunction().then(function () {
          _this.fetch = !_this.stopGap;
        }).catch(function (err) {
          $log.error(err);
        });
      }
    };

    this.$onInit = function () {
      var _this2 = this;

      if (!this.offset) {
        this.offset = 0;
      }

      this.fetch = !this.stopGap;

      this.scrollingElement = angular.element($element.find('.infinite-scroller__content')[0]);

      this.scrollingElement.on('scroll', _.throttle(function () {
        _this2.handleFetching();
      }, 100));
    };
  }]
});
})();