(function(){
'use strict';

scMediaManagerImageUploadCtrl.$inject = ["$rootScope", "$scope", "scImg", "$q", "$timeout"];
function scMediaManagerImageUploadCtrl($rootScope, $scope, scImg, $q, $timeout) {
  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */

  $scope.el.$input = $scope.el.main.find('.media-manager_dropper-input');

  // Note: we very specifically want to use `function () {}` here instead of using
  // fat-arrow syntax. We need the `this` in this function to reference the element
  // instead of this controller.
  //
  // There's some kind of jQuery/browser bug where doing `$scope.el.$input[0].files` was returning
  // an empty file list, but using `this.files` would have the actual file names. I imagine
  // there's some kind of caching going on or something else weird, even though the two appear
  // to reference the same node.
  //
  // Note that that hitting `cancel` before selecting will cause this to trigger properly, as will
  // refreshing the page. So this is definitely a weird one.
  $scope.el.$input.on('change', function () {
    var files = this.files;
    if (!files.length) {
      return;
    }

    $timeout(function () {
      $scope.handleUpload(files);
    });
  });

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.handleUpload = function (files) {
    var file = files[0];
    var maxFileSize = $scope.options.useReactEditor ? 4 : 2;

    if (!/image\/[jpeg|gif|png]/.test(file.type)) {
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Please upload a JPEG, GIF, or PNG file.'
      };
      return;
    }

    if (file.size > maxFileSize * 1024 * 1024) {
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Please upload a file under ' + maxFileSize + 'MB.'
      };
      return;
    }

    $scope.isImgValid(file).then(function () {
      var img = new scImg(file);
      $scope.stage({ image: img, libraryLoaded: false });
      $scope.ui.image.uploaded = [img];
    }, function () {
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Image is corrupted, please upload a valid image.'
      };
    });
  };

  $scope.isImgValid = function (file) {
    var deferred = $q.defer();
    var imgread = new FileReader();
    imgread.onload = function () {
      var imgtest = new Image();
      imgtest.onload = function () {
        deferred.resolve(true);
      };
      imgtest.onerror = function () {
        deferred.reject(false);
      };
      imgtest.src = imgread.result;
    };
    imgread.readAsDataURL(file);
    return deferred.promise;
  };

  /* ---------------------------------------------------------------------- *
   * Cleanup
   * ---------------------------------------------------------------------- */

  $scope.$on('$destroy', function () {
    $scope.el.$input.remove();
  });
}

angular.module('classy').controller('scMediaManagerImageUploadCtrl', scMediaManagerImageUploadCtrl);
})();