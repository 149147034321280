(function(){
'use strict';

angular.module('classy').constant('scImpactBlockDefaults', {
  title: 'Impact',
  type: 'impact',
  disable: false,
  headline: 'How To Help',
  hideHeadline: false,
  headlineColor: 'rgb(44,52,59)',
  textColor: 'rgb(44,52,59)',
  remove: false,
  background: {
    color: 'rgb(248,248,248)',
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  }
});
})();