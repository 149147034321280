(function(){
'use strict';

angular.module('classy').factory('$exceptionHandler', ["bugsnagClient", "$log", function (bugsnagClient, $log) {
  return function (exception, cause) {
    $log.error(exception);
    bugsnagClient.notify(exception, {
      beforeSend: function beforeSend(report) {
        report.updateMetaData('angular', { cause: cause });
      }
    });
  };
}]);
})();