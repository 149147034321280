(function(){
'use strict';

angular.module('classy').constant('globalExperimentSwitch', {
  // This controls the recurring donation experiment.
  // When true, the experiment is running and available for certain campaigns.
  // When false, the experiment is not running and is unavailable to all campaigns.
  // This setting should override (but not change) per-campaign configurations
  // regarding the usage of the experiment.
  RECURRING_EXPERIMENT: true
});
})();