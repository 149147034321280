(function(){
'use strict';

// This directive, used in the advanced designer, creates a dropdown list of background
// presets based on the presets defined in scBlockBackgroundPresets.js. We allow the
// option to omit certain presets from the dropdown if desired.
//
// Upon change, this directive changes the "preset" property in the block's "background" object.

scBlockBgSelect.$inject = ["scBlockBackgroundPresets", "scQaId", "scCampaignsTemplateService", "$stateParams"];
function scBlockBgSelect(scBlockBackgroundPresets, scQaId, scCampaignsTemplateService, $stateParams) {
  return {
    scope: {
      bg: '=scBlockBgSelect',
      omit: '=?',
      lockField: '<?'
    },

    template: '<div ng-class="{\'admin-body__lock-field\':lockField}">' + '<div ng-if="lockField"' + 'data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"' + 'data-sc-tooltip-position="center"' + 'data-sc-tooltip-position-tip="bottom center"' + 'show-event="\'mouseenter\'" hide-event="\'mouseout\'" fixed-tooltip="true"' + 'data-sc-tooltip="\'This field is locked at the template level.\'"' + 'class="admin-body__tooltip-element"' + '></div>' + '<select _spec="scBlockBgSelect" class="edit-design-form_select" data-ng-model="bg.preset" data-ng-disabled="lockField" qa-id="{{ scQaId.selectField }}" data-ng-options="name as option.label for (name,option) in bgPresets"></select>' + '</div>',
    replace: true,

    link: function link(scope) {
      // In certain blocks we don't allow users to select certain background
      // preset options. The one we omit the most is 'coverfixed'.
      var omitArray = [];

      omitArray = [scope.omit];

      scope.bgPresets = {};
      scope.scQaId = scQaId;

      scope.getLockedField = function (type) {
        var lockedBlock = scCampaignsTemplateService.getBlockByType(type);
        return lockedBlock && lockedBlock.item_attributes.background.preset && lockedBlock.item_attributes.background.preset.locked;
      };

      if (typeof scope.lockField === 'undefined' && SC.campaign.campaignTemplateData) {
        var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
        if (blockObj) {
          scope.lockField = scope.getLockedField(blockObj.type);
        }
      }

      // Add background presets if they're not omitted
      _.forEach(scBlockBackgroundPresets, function (preset, key) {
        if (_.indexOf(omitArray, key) < 0) {
          scope.bgPresets[key] = preset;
        }
      });

      scope.$watch('bg.preset', function (newVal, oldVal) {
        // Don't sync on initial watch - need to make sure we only change
        // the model on a user action to avoid unexpected deepDiff errors.
        if (newVal === oldVal) {
          return;
        }

        // Store the preset in the image so we can modify it from the image
        // options list in the detail pages. (Those pages watch the option
        // and apply the changes to the block background.)
        if (scope.bg.image) {
          scope.bg.image.options.position = newVal;
        }

        // Apply the actual styles to the block background.
        var preset = scope.bgPresets[newVal];
        _.merge(scope.bg, preset.styles);
      });
    }
  };
}

angular.module('classy').directive('scBlockBgSelect', scBlockBgSelect);
})();