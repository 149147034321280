(function(){
'use strict';

angular.module('classy').controller('frsDonationDonorCtrl', ["$scope", "$timeout", "scCurrencyService", "scMembersService", "scOrganizationsService", "scFroalaOptions", "CHAR_LIMITS", function ($scope, $timeout, scCurrencyService, scMembersService, scOrganizationsService, scFroalaOptions, CHAR_LIMITS) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */

  $scope.setup = {
    optionalQuestions: function optionalQuestions() {
      var optionalQuestions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      _.forEach(optionalQuestions, function (qDef) {
        if (qDef.location === 'donation_page' && qDef.is_deleted === false) {
          var answerModel = {
            question_id: qDef.id,
            answer: undefined
          };
          $scope.list.optionalQuestions[qDef.tag] = qDef;
          $scope.MODEL.answers.push(answerModel);
          $scope.META.answers[qDef.tag] = function () {
            // Note: We check args.length here rather than whether the first value is undefined
            // because we may need to be able to set the answer to undefined
            if (arguments.length) {
              answerModel.answer = arguments.length <= 0 ? undefined : arguments[0];
            }

            return answerModel.answer;
          };
        }
      });

      // add legacy defaults to prefix/title question when enum options are missing from response
      if ($scope.list.optionalQuestions.prefix && !($scope.list.optionalQuestions.prefix.enum_options || []).length) {
        $scope.list.optionalQuestions.prefix.enum_options = ['Mr.', 'Ms.', 'Mrs.', 'Miss', 'Dr.'].map(function (option) {
          return {
            label: option
          };
        });
      }

      // add legacy defaults to gender question when enum options are missing from response
      if ($scope.list.optionalQuestions.gender && !($scope.list.optionalQuestions.gender.enum_options || []).length) {
        $scope.list.optionalQuestions.gender.enum_options = ['Male', 'Female', 'Prefer not to state/Other'].map(function (option) {
          return {
            label: option
          };
        });
      }
    },
    customQuestions: function customQuestions() {
      var customQuestions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      _.forEach(customQuestions, function (qDef) {
        if (qDef.location === 'donation_page' && qDef.is_deleted === false) {
          var answerModel = {
            question_id: qDef.id,
            question_type: qDef.type,
            answer: undefined
          };
          $scope.list.customQuestions.push(qDef);
          $scope.MODEL.answers.push(answerModel);
          $scope.META.answers[qDef.id] = function () {
            // Note: We check args.length here rather than whether the first value is undefined
            // because we may need to be able to set the answer to undefined
            if (arguments.length) {
              answerModel.answer = arguments.length <= 0 ? undefined : arguments[0];
            }

            return answerModel.answer;
          };
        }
      });
      $scope.list.customQuestions.sort(function (a, b) {
        return a.weight - b.weight;
      });
    },
    memberFirstName: function memberFirstName() {
      return $scope.CONTEXT.autofill.first || _.get(scMembersService, 'active.current.first_name') || '';
    },
    memberLastName: function memberLastName() {
      return $scope.CONTEXT.autofill.last || _.get(scMembersService, 'active.current.last_name') || '';
    },
    memberEmailAddress: function memberEmailAddress() {
      return $scope.CONTEXT.autofill.email || _.get(scMembersService, 'active.current.email_address') || '';
    },
    memberOptIn: function memberOptIn() {
      return $scope.campaign.current.opt_in_checked_by_default;
    },
    memberPhone: function memberPhone() {
      return $scope.CONTEXT.autofill.phone || '';
    },
    country: function country() {
      if ($scope.organization.multiCurrencyDisabled()) {
        return $scope.organization.current.country === 'CA' || $scope.organization.current.country === 'CAN' ? 'CA' : 'US';
      }

      return scCurrencyService.getBrowserLocale() || ($scope.CURRENCY.code === 'CAD' ? 'CA' : 'US');
    },
    address: function address() {
      return $scope.CONTEXT.autofill.street || '';
    },
    city: function city() {
      return $scope.CONTEXT.autofill.city || '';
    },
    state: function state() {
      return $scope.CONTEXT.autofill.state || '';
    },
    postalCode: function postalCode() {
      return $scope.CONTEXT.autofill.zip || '';
    },
    showAddressLine2: function showAddressLine2() {
      return false;
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */
  $scope.CONSTANTS = {
    ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide an address.',
    ANONYMOUS_DONATION_TOOLTIP_TEXT: 'We will only use your name to send you a receipt with tax information.',
    ANONYMOUS_DONATION_CHECKBOX_LABEL: 'Hide my name from the public.',
    ANONYMOUS_DONATION_AMOUNTS_LABEL: 'Hide my donation amount from the public.',
    BLOG_ADDRESS_REQUIRED_ERROR_TEXT: 'Please enter a blog address.',
    CELL_NUMBER_REQUIRED_ERROR_TEXT: 'Please enter your cell number.',
    CHARACTER_LIMIT_ERROR_TEXT: 'Please enter 1000 characters or less.',
    STATE_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a state.',
    PROVINCE_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a province.',
    REGION_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a region.',
    CITY_REQUIRED_ERROR_TEXT: 'Don\'t forget to provide a city.',
    COMPANY_REQUIRED_ERROR_TEXT: 'Please enter a company.',
    COUNTRY_REQUIRED_ERROR_TEXT: 'Please select your country.',
    DOLLAR_AMOUNT_VALID_ERROR_TEXT: 'Please enter a valid dollar amount.',
    EMAIL_ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget your email address.',
    EMAIL_ADDRESS_FORMAT_ERROR_TEXT: 'Please enter in the format: name@domain.com.',
    FIELD_REQUIRED_ERROR_TEXT: 'This field is required.',
    GENDER_REQUIRED_ERROR_TEXT: 'Please select a gender.',
    PRONOUN_REQUIRED_ERROR_TEXT: 'Please select a pronoun.',
    NUMBERS_ONLY_ERROR_TEXT: 'Please enter numbers only.',
    PHONE_NUMBER_VALID_ERROR_TEXT: 'Please enter a valid phone number.',
    PHONE_NUMBER_REQUIRED_ERROR_TEXT: 'Don\'t forget your phone number.',
    TITLE_REQUIRED_ERROR_TEXT: 'Please select a title.',
    SUFFIX_REQUIRED_ERROR_TEXT: 'Please select a suffix.',
    WEBSITE_REQUIRED_ERROR_TEXT: 'Please enter a website.',
    CHARACTER_LIMIT_255_MESSAGE: 'Please limit your answer to 255 or less characters.'
  };

  $scope.countryResitrictedText = _.get(scOrganizationsService, 'active.current.restricted_country_information.drop_down_text');

  $scope.phoneValidationRegex = /^[0-9]*$/;

  /* ---------------------------------------------------------------------- *
   * Helper functions
   * ---------------------------------------------------------------------- */

  $scope.helpers = {
    isZipValid: function isZipValid(value) {
      if (!value) {
        var formCtrl = angular.element('[id=donation-page_checkout_donor-information-form]').controller('form');

        if (!formCtrl) {
          return true;
        }

        var field = formCtrl.billing_postal_code;

        if (!field || !field.$dirty) {
          return true;
        } else if (field && field.$dirty && !field.$modelValue) {
          return false;
        }
      }

      // If the country is not supported by i18n-zipcodes, it will throw an exception.
      // We will not validate the zip code and assume it is valid.
      try {
        // eslint-disable-next-line no-undef
        return i18nZipcodes($scope.MODEL.billing_country, value || $scope.MODEL.billing_postal_code);
      } catch (e) {
        return true;
      }
    }
  };

  $scope.parseBillingAddressAutocomplete = function (response) {
    $timeout(function () {
      // Update billing fields.
      _.merge($scope.MODEL, {
        billing_address1: response.address,
        billing_address2: response.subpremise,
        billing_postal_code: response.postalCode,
        billing_city: response.city,
        billing_state: response.state,
        billing_country: response.country
      });

      $scope.showAddressLine2 = !!response.subpremise;
    });
  };

  /* ---------------------------------------------------------------------- *
   * Collections
   * ---------------------------------------------------------------------- */

  $scope.list = {
    optionalQuestions: {},
    customQuestions: []
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    anonymous: function anonymous() {
      return !$scope.campaign.current.hide_anonymous_donations;
    },
    anonymousDonationAmounts: function anonymousDonationAmounts() {
      return $scope.campaign.current.hide_donation_amounts;
    },
    prefix: function prefix() {
      return $scope.list.optionalQuestions.prefix;
    },
    middleName: function middleName() {
      return $scope.list.optionalQuestions.middle_name;
    },
    suffix: function suffix() {
      return $scope.list.optionalQuestions.suffix;
    },
    optIn: function optIn() {
      return !$scope.campaign.current.hide_donor_opt_in;
    },
    comment: function comment() {
      return !$scope.campaign.current.hide_donation_comments;
    },
    cellPhone: function cellPhone() {
      return $scope.list.optionalQuestions.cellphone;
    },
    homePhone: function homePhone() {
      return $scope.list.optionalQuestions.homephone;
    },
    mgive: function mgive() {
      return !!$scope.organization.current.mgive_settings && $scope.list.optionalQuestions.cellphone && $scope.list.optionalQuestions.text_opt_in;
    },
    company: function company() {
      return $scope.list.optionalQuestions.company_name;
    },
    blog: function blog() {
      return $scope.list.optionalQuestions.blog;
    },
    website: function website() {
      return $scope.list.optionalQuestions.website;
    },
    gender: function gender() {
      return $scope.list.optionalQuestions.gender;
    },
    pronoun: function pronoun() {
      return $scope.list.optionalQuestions.pronoun;
    },
    birthdate: function birthdate() {
      return $scope.list.optionalQuestions.birthdate;
    },
    customQuestions: function customQuestions() {
      return $scope.list.customQuestions.length;
    },
    states: function states() {
      return $scope.MODEL.billing_country === 'US';
    },
    provinces: function provinces() {
      return $scope.MODEL.billing_country === 'CA';
    },
    genericLevel1: function genericLevel1() {
      return !_.includes(['US', 'CA'], $scope.MODEL.billing_country);
    }
  };

  $scope.get = {
    homephone: function homephone(field) {
      return _.get($scope.list.optionalQuestions, 'homephone.' + field, false);
    }
  };

  /* ---------------------------------------------------------------------- *
   * Events
   * ---------------------------------------------------------------------- */

  $scope.handleAddLine2Click = function () {
    $scope.showAddressLine2 = true;
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    mgiveMessage: function mgiveMessage() {
      return $scope.organization.current.mgive_settings.messaging;
    },
    nameError: function nameError() {
      // This will be fun to unit test
      var first = $scope.UTIL.showError('member_first_name', 'required') ? 'first' : '',
          middle = $scope.UTIL.showError('optional_middle_name', 'required') ? 'middle' : '',
          last = $scope.UTIL.showError('member_last_name', 'required') ? 'last' : '',
          comma = first && middle && last ? ', ' : '',
          and1 = first && (middle || last) && !comma ? ' and ' : '',
          and2 = middle && last ? ' and ' : '';

      return 'Be sure to provide your ' + first + comma + and1 + middle + comma + and2 + last + ' name.';
    },
    birthdateError: function birthdateError() {
      var month = $scope.UTIL.showError('optional_birthdate', 'monthRequired') ? 'month' : '',
          day = $scope.UTIL.showError('optional_birthdate', 'dayRequired') ? 'day' : '',
          year = $scope.UTIL.showError('optional_birthdate', 'yearRequired') ? 'year' : '',
          and1 = month && (day || year) ? ' and ' : '',
          and2 = day && year ? ' and ' : '',
          birthday = !month && !day && !year ? 'birthdate' : '';

      return 'Please select a ' + (birthday || month + and1 + day + and2 + year) + '.';
    },
    regionLabel: function regionLabel() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'State & Zip';
      } else if ($scope.MODEL.billing_country === 'CA') {
        return 'Province & Postal Code';
      }

      return 'Region & Postal Code';
    },
    zipPlaceholder: function zipPlaceholder() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'ZIP';
      }

      return 'Postal Code';
    },
    regionType: function regionType() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'state';
      } else if ($scope.MODEL.billing_country === 'CA') {
        return 'province';
      }

      return '';
    },
    zipType: function zipType() {
      if ($scope.MODEL.billing_country === 'US') {
        return 'ZIP code';
      }

      return 'postal code';
    },
    zipError: function zipError() {
      var zipTerm = $scope.build.zipType();
      var isZipValid = $scope.helpers.isZipValid();

      if ($scope.UTIL.showError('billing_postal_code', 'required')) {
        return 'Don\'t forget to include a ' + zipTerm + '.';
      } else if (!isZipValid) {
        return 'Please enter a valid ' + zipTerm + '.';
      }

      return undefined;
    }
  };

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/donor/partials/' + name;
  };

  /* ---------------------------------------------------------------------- *
   * Preflight
   * ---------------------------------------------------------------------- */

  $scope.PREFLIGHT.parseMemberName = function () {
    $scope.MODEL.member_first_name = $scope.META.member_first_name;
    $scope.MODEL.member_last_name = $scope.META.member_last_name;
    $scope.MODEL.member_name = $scope.META.member_first_name + ' ' + $scope.META.member_last_name;
  };

  $scope.PREFLIGHT.filterAnswers = function () {
    var acceptableFalsyValues = [0, false];
    $scope.MODEL.answers = _.filter($scope.MODEL.answers, function (obj) {
      return obj.answer || acceptableFalsyValues.includes(obj.answer);
    });
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  _.merge($scope.MODEL, {
    answers: [],
    billing_address1: $scope.setup.address(),
    billing_address2: '',
    billing_city: $scope.setup.city(),
    billing_state: $scope.setup.state(),
    billing_postal_code: $scope.setup.postalCode(),
    billing_country: $scope.setup.country(),
    comment: null,
    member_name: null,
    member_email_address: $scope.setup.memberEmailAddress(),
    member_phone: $scope.setup.memberPhone(),
    is_anonymous: false,
    opt_in: $scope.setup.memberOptIn(),
    opt_in_wording: _.get(scOrganizationsService, 'active.current.opt_in_wording') || undefined
  });

  _.merge($scope.META, {
    answers: {},
    member_first_name: $scope.setup.memberFirstName(),
    member_last_name: $scope.setup.memberLastName()
  });

  $scope.setup.optionalQuestions(_.get($scope, 'campaign.current.questions.optional'));
  $scope.setup.customQuestions(_.get($scope, 'campaign.current.questions.custom'));
  $scope.froalaCommentOptions = scFroalaOptions({ type: 'textarea-counter' }, {
    charCounterCount: true,
    charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_COMMENT,
    htmlAllowedAttrs: ['aria-.*'],
    ariaLabel: 'Leave a comment'
  });

  $scope.froalaSingleLineOptions = scFroalaOptions({ type: 'single-line-counter' }, {
    multiLine: false,
    charCounterCount: true,
    charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_CUSTOM_QUESTION
  });

  $scope.froalaCustomQuestionsOptions = scFroalaOptions({ type: 'textarea-counter' }, {
    multiLine: false,
    charCounterCount: true,
    charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_CUSTOM_QUESTION
  });

  $scope.applyZipCodeValidation = function (value) {
    var isZipValid = $scope.helpers.isZipValid(value);

    var form = document.getElementById('donation-page_checkout_donor-information-form');

    // Get the element named billing_postal_code_label
    var label = form ? form.querySelector('span[name="billing_postal_code_label"]') : null;

    // Get the element named billing_postal_code
    var input = form ? form.querySelector('input[name="billing_postal_code"]') : null;

    // Get the element named billing_postal_code_error
    var error = form ? form.querySelector('span[name="billing_postal_code_error"]') : null;

    if (!label || !input || !error) {
      return;
    }

    $timeout(function () {
      if (isZipValid) {
        label.classList.remove('sc-form-error');

        input.classList.remove('sc-form-error');
        input.setAttribute('aria-invalid', false);

        error.classList.remove('sc-form-error');
      } else {
        label.classList.add('sc-form-error');

        input.classList.add('sc-form-error');
        input.setAttribute('aria-invalid', true);

        error.classList.add('sc-form-error');
      }
    });
  };

  $scope.$watch('MODEL.billing_postal_code', function (newVal) {
    $scope.applyZipCodeValidation(newVal);
  });

  // Watch for the country to change
  $scope.$watch('MODEL.billing_country', function () {
    $scope.applyZipCodeValidation($scope.MODEL.billing_postal_code);
  });
}]);
})();