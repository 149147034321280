(function(){
'use strict';

/**
 * @kind service
 * @name scPreflight
 *
 * @description
 * Runs a preflight checklist on block data before persisting. Other services
 * can hook into this service to add their own checks to the checklist.
 */

scPreflight.$inject = ["$q"];
function scPreflight($q) {
  var _this = this;

  this.checks = [];

  this.add = function (callback) {
    this.checks.push(callback);
  };

  this.prepare = function (data) {
    var deferred = $q.defer();

    var promises = [];

    _.forEach(_this.checks, function (check) {
      // Transform data
      var result = check(data);

      // If check returned a promise, wait for it
      if (result && _.isFunction(result.then)) {
        // check if promise
        promises.push(result);
      }
    });

    if (!promises.length) {
      deferred.resolve(data);
    } else {
      $q.all(promises).then(function () {
        deferred.resolve(data);
      }, function (err) {
        deferred.reject(err);
      });
    }

    return deferred.promise;
  };
}

angular.module('classy').service('scPreflight', scPreflight);
})();