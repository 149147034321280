(function(){
'use strict';

scRegWFundCampaignDefaultBlocks.$inject = ["scBlockModel"];
angular.module('classy').factory('scRegWFundCampaignDefaultBlocks', scRegWFundCampaignDefaultBlocks);

function scRegWFundCampaignDefaultBlocks(scBlockModel) {
  return {
    header: new scBlockModel({ type: 'header' }),
    crowdfunding: new scBlockModel({
      type: 'crowdfunding',
      layout: 'rwf',
      headline: '<p class="heading">Name of Your Event</p>',
      useFeatured: false,
      joinButtonStyle: {
        borderRadius: 3,
        preset: 'rounded'
      },
      background: {
        preset: 'adaptive'
      }
    }),
    about: new scBlockModel({
      type: 'about',
      background: {
        preset: 'adaptive'
      }
    }),
    donation: new scBlockModel({
      type: 'donation',
      background: {
        preset: 'coverfixed'
      }
    }),
    thankYou: new scBlockModel({
      type: 'thank-you',
      background: {
        preset: 'coverfixed'
      }
    }),
    'progress-circle': new scBlockModel({
      type: 'progress-circle',
      background: {
        preset: 'adaptive'
      }
    }),
    activity: new scBlockModel({
      type: 'activity',
      background: {
        preset: 'adaptive'
      }
    }),
    footer: new scBlockModel({ type: 'footer' }),
    team: new scBlockModel({
      type: 'team',
      donateButtonStyle: {
        borderRadius: 3,
        preset: 'rounded',
        color: 'white'
      },
      joinButtonStyle: {
        border: '3px solid',
        borderRadius: 3,
        preset: 'roundedWire'
      },
      background: {
        preset: 'adaptive'
      }
    }),
    fundraiser: new scBlockModel({
      type: 'fundraiser',
      donateButtonStyle: {
        borderRadius: 3,
        preset: 'rounded',
        color: 'white'
      },
      background: {
        preset: 'adaptive'
      }
    }),
    fundraisers: new scBlockModel({ type: 'fundraisers' }),
    location: new scBlockModel({ type: 'location' }),
    registration: new scBlockModel({
      type: 'registration',
      background: {
        preset: 'adaptive'
      }
    })
  };
}
})();