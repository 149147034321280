(function(){
'use strict';

angular.module('classy').component('teamQuestionsModal', {
  template: '\n      <modal-frame>\n\n        <modal-title>{{:: $ctrl.title }}</modal-title>\n        <modal-subtitle>{{:: $ctrl.subtitle }}</modal-subtitle>\n\n        <form class="team-questions-modal__form"\n        novalidate\n        name="create_team_questions"\n        data-sc-submit="$ctrl.onNext()">\n        \n          <form-table>\n\n            <custom-fields \n            questions="$ctrl.questions"\n            answers="$ctrl.answers"></custom-fields>\n\n          </form-table>\n\n          <modal-submit primary-color="$ctrl.primaryColor">\n            {{:: $ctrl.submitText }}\n          </modal-submit>\n\n        </form>\n\n      </div>\n    ',
  bindings: {
    campaignId: '<',
    primaryColor: '<',
    title: '<',
    subtitle: '<',
    submitText: '<',
    questions: '<',
    answers: '<',
    onNext: '&'
  }
});
})();