(function(){
'use strict';

angular.module('classy').component('campaignTemplateLock', {
  template: '<span>:{{$ctrl.sampleField.name}}</span>',
  bindings: {
    disableFlag: '='
  },
  controller: function CampaignTemplateLockCtrl() {
    this.disableFlag = false;
  }
});
})();