(function(){
'use strict';

scMobileMenuDirective.$inject = ["$animate", "mobileMenuAPI", "scMembersService"];
function scMobileMenuDirective($animate, mobileMenuAPI, scMembersService) {
  return {
    replace: true,
    templateUrl: 'global/directives/scMobileMenu/template',
    controller: ["$scope", function controller($scope) {
      $scope.build = {
        userImage: function userImage() {
          return scMembersService.active.current.id ? scMembersService.active.current.thumbnail_large : '/static/global/images/user-icon.png';
        },
        userGreeting: function userGreeting() {
          return scMembersService.active.current.id ? scMembersService.active.current.first_name + ' ' + scMembersService.active.current.last_name : 'Hey there!';
        }
      };

      $scope.api = mobileMenuAPI.init();
    }],
    link: function link(scope, element) {
      $animate.enabled(element, false);

      var $overlay = angular.element('\n        <div class="mobile-menu_overlay"></div>\n      ');

      $overlay.on('touchstart touchmove', function (e) {
        if (e.target === $overlay[0]) {
          scope.$apply(scope.api.close);
        }
      });

      element.prepend($overlay);
    }
  };
}

angular.module('classy').directive('scMobileMenu', scMobileMenuDirective);
})();