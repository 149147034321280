(function(){
'use strict';

/**
 * @kind constant
 * @name scQaId
 *
 * @description
 * Houses all QA id constants. QA references these ids in their tests.
 */

angular.module('classy').constant('scQaId', {
  formField: 'form-field',
  errorMessage: 'error-message',
  setupPageTitle: 'setup-admin-page-title',

  // P2P only
  impactLevels: 'impact-levels',

  // Text areas or froala fields
  textAreaField: 'text-area-field',

  // Text inputs
  singleLineTextInput: 'text-input',
  dateField: 'date-field',

  // Dropdowns
  selectField: 'select-field',

  toggleField: 'toggle-field',
  checkboxField: 'checkbox-label',
  radioField: 'radio-field',
  colorPicker: 'color-picker',
  rangeSlider: 'range-slider-num',
  imageUpload: 'image-upload',
  resourceSearch: 'resource-search'
});
})();