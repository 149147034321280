(function(){
'use strict';

/* -------------------------------------------------------------------------- *
 * Load the Google Maps API
 * -------------------------------------------------------------------------- */

angular.module('classy').provider('loadRecaptcha', function () {
  var siteKey = null;

  this.setSiteKey = function (key) {
    if (!key) {
      return;
    }
    siteKey = key.toString();
  };

  this.$get = ['$q', '$window', function ($q, $window) {
    if (!siteKey) {
      return $q.reject();
    }

    var isRecaptchaEnabled = SC.campaign.effective_recaptcha_settings === 'ENABLED';

    if (!isRecaptchaEnabled) {
      return $q.resolve(undefined);
    }

    var apiLoaded = $q.defer();

    $window.initializeRecaptcha = function () {
      apiLoaded.resolve($window.grecaptcha.enterprise);
    };

    // Adapted from https://developers.google.com/maps/documentation/javascript/tutorial#asynch
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = 'async';
    script.defer = 'defer';
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=' + siteKey;
    script.onload = $window.initializeRecaptcha;
    document.body.appendChild(script);

    return apiLoaded.promise;
  }];
});
})();