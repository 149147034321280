(function(){
'use strict';

/**
 * @name isPast
 * @kind filter
 */

function isPast() {
  return function (items, dateFieldName) {
    if (typeof items !== 'undefined') {
      return items.filter(function (item) {
        return moment(item[dateFieldName || 'date']).isBefore(new Date());
      });
    }

    return undefined;
  };
}

angular.module('classy').filter('isPast', isPast);
})();