(function(){
'use strict';

angular.module('classy').component('panel', {
  template: '\n    <div class="panel"\n      ng-transclude\n      ng-style="$ctrl.style">\n    </div>',
  transclude: true,
  bindings: {
    customStyle: '=?'
  },
  controller: function controller() {
    this.style = this.customStyle ? this.customStyle : {};
  }
});
})();