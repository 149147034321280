(function(){
'use strict';

scSubscriptionPlan.$inject = ["$http", "$log", "$q"];
function scSubscriptionPlan($http, $log, $q) {
  return {
    isFreeOrTrialPlan: isFreeOrTrialPlan,
    getCustomFeatures: getCustomFeatures
  };

  function isFreeOrTrialPlan(organization) {
    // "old db" lists trial/free as 0/8
    // "new db" lists trial/free as 4/5
    // a custom_plan_features_id of 1,2 or null means a trial/free or non customized plan
    // limitation/benefit of this function is:
    //    any customization to the plan at all will get to be indexed.
    if (organization) {
      if (_.isNull(organization.classy_subscription_plan_id)) {
        return (organization.subscription_plan == 0 || organization.subscription_plan == 8) && (organization.custom_plan_features_id == 1 || organization.custom_plan_features_id == 2 || _.isNull(organization.custom_plan_features_id));
      }
      return (organization.classy_subscription_plan_id == 4 || organization.classy_subscription_plan_id == 5) && (organization.custom_plan_features_id == 1 || organization.custom_plan_features_id == 2 || _.isNull(organization.custom_plan_features_id));
    }

    return undefined;
  }

  function getCustomFeatures(paramObject) {
    var deferred = $q.defer();
    $http({
      method: 'GET',
      url: '/frs-api/organizations/' + paramObject.organization.id + '/custom-plan-features'
    }).then(function (resp) {
      if (paramObject.field) {
        deferred.resolve(_.get(resp, 'data.' + paramObject.field, 'error: problem with the field'));
      } else {
        deferred.resolve(resp.data);
      }
    }).catch(function (err) {
      $log.log(err);
      deferred.reject(err);
    });

    return deferred.promise;
  }
}

angular.module('classy').factory('scSubscriptionPlan', scSubscriptionPlan);
})();