(function(){
'use strict';

angular.module('classy').controller('EmbeddedGivingThankYouCtrl', ["$scope", "cpIntelligentAsk", "EmbeddedGivingDAL", "scSocialShareLinks", function ($scope, cpIntelligentAsk, EmbeddedGivingDAL, scSocialShareLinks) {
  $scope.ActiveSocials = {
    facebook: null,
    twitter: null,
    email: null
  };

  $scope.openSocialFacebook = function () {
    if ($scope.ActiveSocials.facebook) {
      $scope.ActiveSocials.facebook();
    }
  };

  $scope.openSocialTwitter = function () {
    $scope.openLink($scope.ActiveSocials.twitter, 'Share with Twitter');
  };

  $scope.openSocialEmail = function () {
    $scope.openLink($scope.ActiveSocials.email, 'Share with email');
  };

  // It may seem overkill to have a setter here, but a pattern I like is to always interface with variables through setters
  // This way, I can throw a debugger within the setter and hunt down any mutation. In the future someone will thank me, maybe.
  $scope.setActiveSocials = function (socials) {
    if (!_.isEmpty(socials)) {
      $scope.ActiveSocials.facebook = socials.facebook;
      $scope.ActiveSocials.twitter = socials.twitter;
      $scope.ActiveSocials.email = socials.email;
    }
  };

  scSocialShareLinks.getSocialData().then(function (data) {
    $scope.setActiveSocials({
      facebook: data.facebook_share,
      twitter: data.twitter_link,
      email: data.email_link
    });
  });

  // Send IAA heap event if init
  if (cpIntelligentAsk.isInit) {
    cpIntelligentAsk.sendHeapEvent('thank-you', $scope.MODEL, {
      transactionId: EmbeddedGivingDAL.getCached('transactionId'),
      amount: EmbeddedGivingDAL.getFullCachedAmount(),
      frequency: EmbeddedGivingDAL.getCached('frequency')
    });
  }
}]);
})();