(function(){
'use strict';

angular.module('classy').component('countryBlockModal', {
  template: '\n    <div class="country-block-modal">\n      <div class="country-block-modal__icon-container">\n        <div class="country-block-modal__icon__frame">\n          <i class="ci ci-icon-popup-warning country-block-modal__icon__frame__warning-icon"></i>\n        </div>\n      </div>\n      <div tabindex="0">\n        <modal-title>{{::$ctrl.data.headline}}</modal-title>\n        <modal-subtitle>{{::$ctrl.data.body_text}}</modal-subtitle>\n      </div>\n      <div class="country-block-modal__btn-container">\n        <button\n          class="country-block-modal__btn-container__continue-btn"\n          ng-click="$ctrl.onApproval()">\n          Continue\n        </button>\n      </div>\n    </div>\n  ',
  bindings: {
    data: '<',
    handleContinue: '&',
    cookieKey: '<',
    activeElement: '<'
  },
  controller: ["scFlowModalService", "ipCookie", function countryBlockCtrl(scFlowModalService, ipCookie) {
    var _this = this;

    this.onApproval = function () {
      ipCookie(_this.cookieKey, true, { expires: 1, path: '/' });
      scFlowModalService.clearFocus();
      scFlowModalService.close();
      _this.handleContinue();
    };
  }]
});
})();