(function(){
'use strict';

// This is a fork of 'cpPaymentDetails'
// IMO this should be refactored to a service/controller architecture
// Because there will never be a case where there is >1 paymentDetails
// Components are intended for reusability
angular.module('classy').component('egPayPaymentDetails', {
  templateUrl: 'global/embedded-giving/pay/views/payment-details/eg.pay.views.payment-details', // same folder as this
  bindings: {
    label: '<',
    show: '<',
    details: '=',
    paymentMethod: '<',
    payment: '<',
    onToken: '&',
    onCard: '&',
    primaryColor: '<',
    hidePostal: '<',
    currency: '<'
  },
  controller: ["$log", "$rootScope", function controller($log, $rootScope) {
    var _this = this;

    this.$onChanges = function (_ref) {
      var paymentMethod = _ref.paymentMethod;

      $log.debug('[egPayPaymentDetails:$onChanges]: ', paymentMethod);
      if (paymentMethod && paymentMethod.currentValue) {
        _this.waitingChange = false;
      }
    };

    this.changeAccount = function (paymentMethod) {
      _this.details = '';
      _this.waitingChange = true;
      _this.changeAccountTo = paymentMethod;
    };

    this.openModal = function () {
      _this.waitingChange = false;
      $rootScope.$broadcast('openPaymentModal', _this.paymentMethod);
    };
  }]
});
})();