(function(){
'use strict';

angular.module('classy').component('registrationEntityTeamQuestions', {
  template: '\n      <team-questions-modal\n      title="\'Team Questions\'"\n      subtitle="\'Tell us a little more about yourself.\'"\n      submit-text="\'Register\'"\n      questions="$ctrl.questions"\n      answers="$ctrl.model.team_answers"\n      primary-color="$ctrl.primaryColor"\n      on-next="$ctrl.goToCheckout()">\n      </team-details-modal>\n    ',
  bindings: {
    model: '<',
    questions: '<',
    goToCheckout: '&',
    primaryColor: '<',
    campaignId: '<'
  }
});
})();