(function(){
'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

angular.module('classy')
// Provider to resolve Heap's SDK
.provider('$heap', function () {
  this.isFetching = false;
  var CLASSY_SESSION_ID = 'classy-session-id';

  var addClassySessionIdToHeap = function addClassySessionIdToHeap() {
    var cookieString = document.cookie.split('; ').find(function (row) {
      return row.startsWith(CLASSY_SESSION_ID + '=');
    });
    var sessionId = cookieString ? cookieString.split('=')[1] : null;

    if (sessionId && typeof window.heap.addEventProperties === 'function' && typeof window.heap.addUserProperties === 'function') {
      window.heap.addEventProperties(_defineProperty({}, CLASSY_SESSION_ID, sessionId));
      window.heap.addUserProperties(_defineProperty({}, CLASSY_SESSION_ID, sessionId));
      // eslint-disable-next-line no-console
      console.log('[$heap:addClassySessionIdToHeap] added "' + CLASSY_SESSION_ID + '" to event and user properties:', sessionId);
      return true;
    }

    return false; // Classy Session ID not available yet
  };

  this.$get = function () {
    // If already loaded, immediately return (but check for classy-session-id)
    if (SC.heapLoaded) {
      // eslint-disable-next-line no-console
      console.log('[$heap:$get] heap already present, no need to fetch');
      addClassySessionIdToHeap();
      return window.heap;
    }

    if (!this.isFetching && !SC.heapLoaded) {
      // eslint-disable-next-line no-console
      console.log('[$heap:$get] starting...');

      this.isFetching = true;
      window.heap = window.heap || [];

      heap.load = function (appId, config) {
        // eslint-disable-next-line no-console
        console.log('[$heap:$get] loading heap... appId - ', appId);
        window.heap.appid = appId;
        window.heap.config = config || {};

        // Try adding Classy Session ID immediately; otherwise, set interval to check
        if (!addClassySessionIdToHeap()) {
          var MAX_CHECKS = 20; // Max number of checks before giving up
          var checkCount = 0;

          // Periodically check for classy-session-id until found
          var sessionIdCheckInterval = setInterval(function () {
            checkCount += 1;

            if (addClassySessionIdToHeap() || checkCount >= MAX_CHECKS) {
              clearInterval(sessionIdCheckInterval);
              if (checkCount >= MAX_CHECKS) {
                // eslint-disable-next-line no-console
                console.warn('[$heap:addClassySessionIdToHeap] Max checks reached without finding "' + CLASSY_SESSION_ID + '".');
              }
            }
          }, 200); // Check every 200ms
        }

        var r = config.forceSSL || document.location.protocol === 'https:';
        var script = document.createElement('script');
        script.onload = config.loaded;
        script.type = 'text/javascript';
        script.async = !0;
        script.defer = !0;
        script.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + appId + '.js';

        var n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(script, n);

        for (var o = function o(f) {
          return function () {
            heap.push([f].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }, methods = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'resetIdentity', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], c = 0; c < methods.length; c += 1) {
          heap[methods[c]] = o(methods[c]);
        }this.isFetching = false;
      };
    }

    return window.heap;
  };
}).service('heapService', ["$injector", "$log", "$q", "$rootScope", "IA_HEAP_PREFIX", "EG_EVENTS", "scAnalyticsUtils", "scOrganizationsService", "scThemesService", function ($injector, $log, $q, $rootScope, IA_HEAP_PREFIX, EG_EVENTS, scAnalyticsUtils, scOrganizationsService, scThemesService) {
  var _this = this;

  var $heap = void 0;
  var self = this;
  this.name = 'heap';
  this.isFetching = false;

  var debugLog = scAnalyticsUtils.debugLog,
      getCampaign = scAnalyticsUtils.getCampaign,
      isEmbeddedGivingEvent = scAnalyticsUtils.isEmbeddedGivingEvent,
      isFunnelConversionEvent = scAnalyticsUtils.isFunnelConversionEvent,
      isDigitalWalletsLoadedEvent = scAnalyticsUtils.isDigitalWalletsLoadedEvent,
      isPublicPage = scAnalyticsUtils.isPublicPage;

  var loadingPromise = void 0;
  var loadingInterval = void 0;

  this.init = function () {
    if (!SC.heapId) {
      $log.warn('[heapService:init] SC.heapId not present');
      return undefined;
    }

    if ($heap) {
      $log.debug('[heapService:init] heap already initialized');
      return $heap;
    }

    if (!_this.isFetching) {
      $log.debug('[heapService:init] loading...');
      _this.isFetching = true;
      $heap = $injector.get('$heap');
      $heap.load(SC.heapId, {
        forceSSL: true,
        secureCookie: true,
        // Custom loaded function to execute after Heap script loads.
        // Note: this function is not part of Heap's official documentation.
        loaded: function loaded() {
          $log.log('[heapService:init] loaded!');
          SC.heapLoaded = true;
          self.isFetching = false;
          $rootScope.$broadcast('heapService:load');
        }
      });
      return $heap;
    } else {
      $log.warn('[heapService:init] already ' + (_this.isLoading ? 'loading...' : 'loaded'));
      return $heap;
    }
  };

  this.loaded = function () {
    if (!SC.heapId) {
      $log.warn('[heapService:loaded] heapId not present');
      return $q.reject();
    }

    if (SC.heapLoaded) {
      return $q.resolve();
    }

    if (loadingPromise) {
      return loadingPromise;
    }

    // Checks if heap is loaded on a 200ms loop. TODO: this loop should be tighter...
    loadingPromise = $q(function (resolve, reject) {
      var intervalCount = 0;

      loadingInterval = setInterval(function () {
        intervalCount += 1;

        if (intervalCount > 200) {
          clearInterval(loadingInterval);
          reject();
        }

        if (SC.heapLoaded) {
          clearInterval(loadingInterval);
          resolve();
        }
      }, 100);
    });

    return loadingPromise;
  };

  this.recordPageView = function (data) {
    // Debug logs:
    // $log.debug('[heapService:track] window.heap instance:', window.heap);
    // $log.debug('[heapService:track] this.isFetching: ', this.isFetching);
    // $log.debug('[heapService:track] SC.heapLoaded: ', SC.heapLoaded);
    //
    if (!SC.heapLoaded) {
      $log.warn('[heapService:recordPageView] $heap not present');
      return;
    }

    window.heap.track('Page View', Object.assign({}, data, {
      environment: SC.env,
      is_public: isPublicPage()
    }));
  };

  this.track = function (event, data) {
    // Debug logs:
    // $log.debug('[heapService:track] window.heap instance:', window.heap);
    // $log.debug('[heapService:track] this.isFetching: ', this.isFetching);
    // $log.debug('[heapService:track] SC.heapLoaded: ', SC.heapLoaded);
    //
    $log.debug('[heapService:track] start event... ', event, JSON.stringify(data));

    if (!SC.heapLoaded) {
      $log.warn('[heapService:track] $heap not present', event, JSON.stringify(data));
      return;
    }
    /* 
    TODO:
    move logic to eg.services.messenger after all services are lazy injected
    and we no longer rely on the open event as a virtual page view event
    https://classydev.atlassian.net/browse/CL-25698 */
    if (event === EG_EVENTS.open.modal || event === EG_EVENTS.open.inline) {
      $log.debug('[heapService:track] open event');
      return;
    }

    if (isFunnelConversionEvent(event)) {
      trackTransactionEvent(event, data);
    } else if (isDigitalWalletsLoadedEvent(event)) {
      trackDigitalWalletsLoadedEvent(data);
    } else if (isEmbeddedGivingEvent(event)) {
      trackEmbeddedGivingEvent(event, data);
    }
  };

  this.identifyMember = function (id, data) {
    if (!SC.heapLoaded) {
      $log.warn('[heapService:identifyMember] $heap not present');
      return;
    }

    window.heap.identify(id.toString());
    window.heap.addUserProperties(data);
  };

  this.eventBeacon = function (data) {
    if (!SC.heapLoaded) {
      $log.warn('[heapService:eventBeacon] $heap not present');
      return;
    }

    window.heap.track(data.label, data);
  };

  this.registerSuperProperties = function () {
    if (!SC.heapLoaded) {
      $log.warn('[heapService:registerSuperProperties] $heap not present');
      return;
    }

    var campaign = getCampaign();
    var properties = {
      environment: SC.env,
      organization_id: scOrganizationsService.active.current.id,
      payment_processor: scOrganizationsService.active.current.payment_provider_name,
      campaign: campaign.current.id,
      campaign_type: campaign.current.type,
      duplicate_fundraisers: campaign.current.allow_duplicate_fundraisers,
      additional_donation_enabled: _.get(scThemesService.active.current.styles, 'ticketedEvent.additionalDonation', undefined),
      // This property helps us identify existing fundraisers in the create fundraiser flow.
      // We set this to false just in case a member is coming from another campaign
      // in which they were an active fundraiser but might not be on the current campaign
      existing_fundraiser: false
    };

    window.heap.addEventProperties(properties);
  };

  // For Intelligent Ask
  this.trackIntelligentAskEvent = function (event) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!SC.heapLoaded) {
      $log.warn('[heapService:trackIntelligentAskEvent] $heap not present');
      return;
    }

    if (!event || !event.length || !event.indexOf(IA_HEAP_PREFIX) === 0) {
      $log.warn('[heapService:trackIntelligentAskEvent] IA event name prefix required');
      return;
    }

    $log.debug('[heapService:trackIntelligentAskEvent]: ', event, data);
    window.heap.track(event, data);
  };

  this.destroy = function () {
    $log.warn('[heapService] destroying...');
    $heap = null;
    _this.isFetching = false;
    SC.heapLoaded = false;
    $rootScope.$broadcast('heapService:loaded');
    delete window.heap;
  };

  /* Private Methods
  ========================================================= */
  function trackTransactionEvent(event) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!SC.heapLoaded) {
      $log.warn('[heapService:trackTransactionEvent] heap not present');
      return;
    }

    debugLog('heap conversion', data);

    var amount = data.amount || 0;
    var rawCurrencyCode = data.raw_currency_code;
    var donationAmount = data.donation_amount;
    var rwfCompletionEvent = event === 'registration/create/complete';
    var transactionId = rwfCompletionEvent ? data.registration_transaction_id : data.transactionId;
    var eventName = rwfCompletionEvent ? 'Event Cart - Successful Payment' : 'Donation Page - Successful Payment';

    // Adding this for RWF is currently out of scope
    var digitalWalletsPresent = rwfCompletionEvent ? false : data.digitalWalletsPresent;
    var paymentRequestButton = rwfCompletionEvent ? '' : data.paymentRequestButton;
    var paymentSource = rwfCompletionEvent ? '' : data.paymentSource;

    window.heap.track(eventName, {
      id: transactionId,
      revenue: amount,
      donation_amount: donationAmount || undefined,
      num_attendees: data.items ? data.items.length : 0,
      raw_currency_code: rawCurrencyCode,
      digitalWalletsPresent: digitalWalletsPresent.toString(),
      paymentRequestButton: paymentRequestButton,
      paymentSource: paymentSource
    });
  }

  function trackDigitalWalletsLoadedEvent() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (!SC.heapLoaded) {
      $log.warn('[heapService:trackDigitalWalletsLoadedEvent] $heap not present');
      return;
    }

    var eventName = data.digitalWalletsLoaded ? 'Donation Page - View Page - Digital Wallet' : 'Donation Page - View Page - Standard';
    window.heap.track(eventName, data);
  }

  function trackEmbeddedGivingEvent(event) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!SC.heapLoaded) {
      $log.warn('[heapService:trackEmbeddedGivingEvent] $heap not present');
      return;
    }

    $log.debug('[heapService:trackEmbeddedGivingEvent]: ', event, JSON.stringify(data));
    window.heap.track(event, data);
  }
}]);
})();