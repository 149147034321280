(function(){
'use strict';

// TODO:
// - add lazyInject() flow (lazy init)
// - refactor to service recipe
// --- this is factory with only static methods, which is an anti-pattern
angular.module('classy').service('scAnalytics', ["$q", "$log", "scUserAgent", "scAnalyticsUtils", "bugsnagClient", "ga4Service", "gtmService", "adwordsService", "fbqService", "heapService", "capiService", "Optimizely", function ($q, $log, scUserAgent, scAnalyticsUtils, bugsnagClient, ga4Service, gtmService, adwordsService, fbqService, heapService, capiService, Optimizely) {
  /**
   * ensureAnalyzable
   *
   * Runtime interface-like utility method. It ensures that any analytics service that wishes to tap into
   * scAnalytics follows a strict pattern. If any of the methods listed in the requiredMethods array are
   * missing from the service, an error will be thrown. Never should code make it to production that does
   * not obey this interface.
   */
  function ensureAnalyzable(services) {
    if (SC.env !== 'development') return;

    var requiredMethods = ['loaded', // MUST RETURN A PROMISE
    'recordPageView', 'track', 'registerSuperProperties', 'identifyMember', 'eventBeacon'];

    services.forEach(function (service) {
      if (typeof service.name !== 'string') throw new Error('Service missing name field');
      requiredMethods.forEach(function (method) {
        if (typeof service[method] !== 'function') {
          throw new Error(service.name + ' missing required method: ' + method);
        }
      });
    });
  }

  /* Setup
  ========================================================= */
  var onPageViewRecordedCallbacks = [];
  var services = [ga4Service, gtmService, heapService, adwordsService, fbqService, capiService];
  ensureAnalyzable(services);

  /*
  * Public Interface Methods
  *
  * All services need to have these methods defined or they will throw an error during setup
  ========================================================================================== */
  /* Optimizely init (used for lazy inject with EG) */
  this.initOptimizely = function () {
    Optimizely.init();
  };

  /* Heap init (used for lazy inject with EG) */
  this.initHeap = function () {
    heapService.init();
  };

  this.identifyMember = function (id, data) {
    if (!id || scUserAgent.isBot()) return;
    services.forEach(function (service) {
      service.loaded().then(function () {
        $log.debug('[scAnalytics:identifyMember] ' + service.name + ': ' + id + ' ' + JSON.stringify(data));
        return service.identifyMember(id, data);
      }).catch(handleError('identifyMember', service.name));
    });
  };

  this.eventBeacon = function () {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (scUserAgent.isBot()) return;
    services.forEach(function (service) {
      service.loaded().then(function () {
        $log.debug('[scAnalytics:eventBeacon] ' + service.name + ': ' + JSON.stringify(data));
        return service.eventBeacon(data);
      }).catch(handleError('eventBeacon', service.name));
    });
  };

  this.recordPageView = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (scUserAgent.isBot()) return;
    var data = scAnalyticsUtils.buildPageViewData(options);
    data.event_id = scAnalyticsUtils.generateEventId();
    var promises = services.map(function (service) {
      return service.loaded().then(function () {
        $log.debug('[scAnalytics:recordPageView] ' + service.name + ': ' + JSON.stringify(options));
        return service.recordPageView(data);
      }).catch(handleError('recordPageView', service.name));
    });

    $q.all(promises).finally(function () {
      onPageViewRecordedCallbacks.forEach(function (callback) {
        return callback();
      });
    });
  };

  this.registerSuperProperties = function () {
    if (scUserAgent.isBot()) return;
    services.forEach(function (service) {
      service.loaded().then(function () {
        $log.log('[scAnalytics:registerSuperProperties] ' + service.name);
        return service.registerSuperProperties();
      }).catch(handleError('registerSuperProperties', service.name));
    });
  };

  this.track = function (event, data) {
    if (scUserAgent.isBot()) return;

    if (_.has(data, 'new')) data.new = JSON.stringify(data.new);
    if (_.has(data, 'old')) data.old = JSON.stringify(data.old);
    if (data) data.event_id = scAnalyticsUtils.generateEventId();

    services.forEach(function (service) {
      service.loaded().then(function () {
        $log.debug('[scAnalytics:track] ' + service.name + ': ' + event + ' ' + JSON.stringify(data));
        return service.track(event, data);
      }).catch(handleError('track', service.name));
    });

    if (Optimizely.isInit) {
      Optimizely.track(event, data);
    }
  };

  /* Public (non-interface) Methods
  ========================================================= */
  this.registerPageViewCallback = function (cb) {
    onPageViewRecordedCallbacks.push(cb);
  };

  this.setCampaign = function (campaign) {
    scAnalyticsUtils.setCampaign(campaign);
  };

  this.destroy = function () {
    heapService.destroy();
  };

  /* Private Methods
  ========================================================= */
  function handleError(methodName, serviceName) {
    // $log.debug(`[scAnalytics:handleError] ${methodName} ${serviceName}`);

    return function (error) {
      if (error) {
        $log.error(error);
        bugsnagClient.notify(error, {
          service: serviceName,
          method: methodName
        });
      }
    };
  }
}]);
})();