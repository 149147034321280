(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy').service('scPromoCodeService', ["$http", "scCampaignsService", "$timeout", function ($http, scCampaignsService, $timeout) {
  return new scPromoCodeService($http, scCampaignsService, $timeout);
}]);

var scPromoCodeService = function () {
  function scPromoCodeService($http, scCampaignsService, $timeout) {
    _classCallCheck(this, scPromoCodeService);

    this.$http = $http;
    this.scCampaignsService = scCampaignsService;
    this.$timeout = $timeout;
    this.requests = {};
  }

  _createClass(scPromoCodeService, [{
    key: 'currentActiveId',
    value: function currentActiveId() {
      return this.scCampaignsService.active.current.id;
    }
  }, {
    key: 'checkCodeAvailability',
    value: function checkCodeAvailability(_ref) {
      var promoCode = _ref.promoCode,
          _ref$campaignId = _ref.campaignId,
          campaignId = _ref$campaignId === undefined ? this.currentActiveId() : _ref$campaignId;

      return this.$http({
        method: 'GET',
        url: '/frs-api/campaigns/' + campaignId + '/check-promo-code-validity',
        params: { promoCode: promoCode }
      }).then(function (_ref2) {
        var data = _ref2.data;
        return data;
      });
    }
  }, {
    key: 'hasAvailableCodesSync',
    value: function hasAvailableCodesSync() {
      var campaignId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentActiveId();

      // Return whatever our cached value is so that we can display UI immediately based on our local
      // data
      return this.scCampaignsService.get(campaignId).promo_codes_available;
    }

    // we should also be calling the server to see if anything has changed since we got our data

  }, {
    key: 'hasAvailableCodesAsync',
    value: function hasAvailableCodesAsync() {
      var _this = this;

      var campaignId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentActiveId();

      if (!this.requests[campaignId]) {
        this.requests[campaignId] = this.$http({
          method: 'GET',
          url: '/frs-api/campaigns/' + campaignId + '/has-promo-codes-available'
        }).then(function (_ref3) {
          var hasAvailableCodes = _ref3.data.hasAvailableCodes;

          // update the cached value for the specified campaign
          _this.scCampaignsService.get(campaignId).promo_codes_available = hasAvailableCodes;
          return hasAvailableCodes;
        })
        // if we failed, just use the cached value
        .catch(function () {
          return _this.scCampaignsService.get(campaignId).promo_codes_available;
        }).finally(function () {
          // rather than constantly spamming the server for updates, prevent new requests from being
          // sent until at least 2s after the last one, whether we succeeded or failed
          _this.$timeout(function () {
            _this.requests[campaignId] = undefined;
          }, 2000);
        });
      }

      return this.requests[campaignId];
    }
  }]);

  return scPromoCodeService;
}();
})();