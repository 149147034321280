(function(){
'use strict';

/**
 * @kind constant
 * @name scBlockHeroDonateButtonPresets
 *
 * @description
 * Style options for donate button in the header block
 */
angular.module('classy').constant('scBlockHeroDonateButtonPresets', {
  rectangle: {
    label: 'Rectangle',
    styles: {
      border: 'solid 3px',
      'border-radius': '0px'
    }
  },
  rectangleWire: {
    label: 'Rectangle Wire',
    styles: {
      border: 'solid 3px',
      'border-radius': '0px',
      'background-color': 'transparent'
    }
  },
  rounded: {
    label: 'Rounded',
    styles: {
      border: 'solid 3px',
      'border-radius': '4px'
    }
  },
  roundedWire: {
    label: 'Rounded Wire',
    styles: {
      border: 'solid 3px',
      'border-radius': '4px',
      'background-color': 'transparent'
    }
  },
  pill: {
    label: 'Pill',
    styles: {
      border: 'solid 3px',
      'border-radius': '60px'
    }
  },
  pillWire: {
    label: 'Pill Wire',
    styles: {
      border: 'solid 3px',
      'border-radius': '60px',
      'background-color': 'transparent'
    }
  }
});
})();