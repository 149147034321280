(function(){
'use strict';

/**
 * @kind directive
 * @name scSelectTimezones
 * @requires ngModel
 *
 * @description
 * Renders a dropdown populated with PHP-supported timezones.
 *
 * WARNING: ngModel must be present on the original element, or else Angular
 * will not compile the select options!
 *
 * Used in:
 * appv2/event/common/event-types/crowdfunding/campaign/index.ng.html
 *
 */

function scSelectTimezones() {
  return {
    replace: true,
    scope: {
      timezones: '=?timezones',
      subset: '=?scSelectTimezones',
      ngModel: '='
    },
    template: '<select ng-options="timezone.val as timezone.name for timezone in timezones | filter:buildSubset" role="listbox" aria-label="timezone" tabindex="0" aria-haspopup="listbox" aria-expanded="false">' + '<option value="">Please Select</option></select>',
    link: function link(scope) {
      scope.buildSubset = function (value) {
        if (!scope.subset || scope.subset === 'all') {
          return true;
        }

        return _.isArray(value.tags) && value.tags.indexOf(scope.subset) > -1;
      };

      /** Note
       * This hard coded list needs to be replaced with either a service call to fetch a list of
       * timezones used on the BE or standardize to using Moment.js timezones (moment.tz.names)
       */

      scope.timezones = [{
        name: 'Midway Island',
        val: 'Pacific/Midway'
      }, {
        name: 'Samoa',
        val: 'Pacific/Samoa'
      }, {
        name: 'Hawaii',
        val: 'Pacific/Honolulu',
        tags: ['condensed']
      }, {
        name: 'Alaska',
        val: 'America/Anchorage',
        tags: ['condensed']
      }, {
        name: 'Pacific Time (US & Canada)',
        val: 'America/Los_Angeles',
        tags: ['condensed']
      }, {
        name: 'Tijuana',
        val: 'America/Tijuana'
      }, {
        name: 'Arizona',
        val: 'America/Phoenix',
        tags: ['condensed']
      }, {
        name: 'Chihuahua',
        val: 'America/Chihuahua'
      }, {
        name: 'Mazatlan',
        val: 'America/Mazatlan'
      }, {
        name: 'Mountain Time (US & Canada)',
        val: 'America/Denver',
        tags: ['condensed']
      }, {
        name: 'Central America',
        val: 'America/Managua'
      }, {
        name: 'Central Time (US)',
        val: 'America/Chicago',
        tags: ['condensed']
      }, {
        name: 'Mexico City',
        val: 'America/Mexico_City'
      }, {
        name: 'Monterrey',
        val: 'America/Monterrey'
      }, {
        name: 'Saskatchewan',
        val: 'America/Regina'
      }, {
        name: 'Bogota',
        val: 'America/Bogota'
      }, {
        name: 'Eastern Time (US & Canada)',
        val: 'America/New_York',
        tags: ['condensed']
      }, {
        name: 'Indiana (East)',
        val: 'America/Indiana/Indianapolis'
      }, {
        name: 'Lima',
        val: 'America/Lima'
      }, {
        name: 'Atlantic Time (Canada)',
        val: 'America/Halifax'
      }, {
        name: 'Caracas',
        val: 'America/Caracas'
      }, {
        name: 'La Paz',
        val: 'America/La_Paz'
      }, {
        name: 'Santiago',
        val: 'America/Santiago'
      }, {
        name: 'Newfoundland',
        val: 'America/St_Johns'
      }, {
        name: 'Brasilia',
        val: 'America/Sao_Paulo'
      }, {
        name: 'Buenos Aires',
        val: 'America/Argentina/Buenos_Aires'
      }, {
        name: 'Greenland',
        val: 'America/Godthab'
      }, {
        name: 'Mid-Atlantic',
        val: 'America/Noronha'
      }, {
        name: 'Azores',
        val: 'Atlantic/Azores'
      }, {
        name: 'Cape Verde Is.',
        val: 'Atlantic/Cape_Verde'
      }, {
        name: 'Casablanca',
        val: 'Africa/Casablanca'
      }, {
        name: 'Greenwich Mean Time : Dublin',
        val: 'Etc/GMT'
      }, {
        name: 'Lisbon',
        val: 'Europe/Lisbon'
      }, {
        name: 'London',
        val: 'Europe/London'
      }, {
        name: 'Monrovia',
        val: 'Africa/Monrovia'
      }, {
        name: 'UTC',
        val: 'UTC'
      }, {
        name: 'Amsterdam',
        val: 'Europe/Amsterdam'
      }, {
        name: 'Belgrade',
        val: 'Europe/Belgrade'
      }, {
        name: 'Berlin',
        val: 'Europe/Berlin'
      }, {
        name: 'Bratislava',
        val: 'Europe/Bratislava'
      }, {
        name: 'Brussels',
        val: 'Europe/Brussels'
      }, {
        name: 'Budapest',
        val: 'Europe/Budapest'
      }, {
        name: 'Copenhagen',
        val: 'Europe/Copenhagen'
      }, {
        name: 'Ljubljana',
        val: 'Europe/Ljubljana'
      }, {
        name: 'Madrid',
        val: 'Europe/Madrid'
      }, {
        name: 'Paris',
        val: 'Europe/Paris'
      }, {
        name: 'Prague',
        val: 'Europe/Prague'
      }, {
        name: 'Rome',
        val: 'Europe/Rome'
      }, {
        name: 'Sarajevo',
        val: 'Europe/Sarajevo'
      }, {
        name: 'Skopje',
        val: 'Europe/Skopje'
      }, {
        name: 'Stockholm',
        val: 'Europe/Stockholm'
      }, {
        name: 'Vienna',
        val: 'Europe/Vienna'
      }, {
        name: 'Warsaw',
        val: 'Europe/Warsaw'
      }, {
        name: 'West Central Africa',
        val: 'Africa/Lagos'
      }, {
        name: 'Zagreb',
        val: 'Europe/Zagreb'
      }, {
        name: 'Athens',
        val: 'Europe/Athens'
      }, {
        name: 'Bucharest',
        val: 'Europe/Bucharest'
      }, {
        name: 'Cairo',
        val: 'Africa/Cairo'
      }, {
        name: 'Harare',
        val: 'Africa/Harare'
      }, {
        name: 'Helsinki',
        val: 'Europe/Helsinki'
      }, {
        name: 'Istanbul',
        val: 'Europe/Istanbul'
      }, {
        name: 'Jerusalem',
        val: 'Asia/Jerusalem'
      }, {
        name: 'Pretoria',
        val: 'Africa/Johannesburg'
      }, {
        name: 'Riga',
        val: 'Europe/Riga'
      }, {
        name: 'Sofia',
        val: 'Europe/Sofia'
      }, {
        name: 'Tallinn',
        val: 'Europe/Tallinn'
      }, {
        name: 'Vilnius',
        val: 'Europe/Vilnius'
      }, {
        name: 'Baghdad',
        val: 'Asia/Baghdad'
      }, {
        name: 'Kuwait',
        val: 'Asia/Kuwait'
      }, {
        name: 'Minsk',
        val: 'Europe/Minsk'
      }, {
        name: 'Nairobi',
        val: 'Africa/Nairobi'
      }, {
        name: 'Riyadh',
        val: 'Asia/Riyadh'
      }, {
        name: 'Volgograd',
        val: 'Europe/Volgograd'
      }, {
        name: 'Tehran',
        val: 'Asia/Tehran'
      }, {
        name: 'Baku',
        val: 'Asia/Baku'
      }, {
        name: 'Moscow',
        val: 'Europe/Moscow'
      }, {
        name: 'Muscat',
        val: 'Asia/Muscat'
      }, {
        name: 'Tbilisi',
        val: 'Asia/Tbilisi'
      }, {
        name: 'Yerevan',
        val: 'Asia/Yerevan'
      }, {
        name: 'Kabul',
        val: 'Asia/Kabul'
      }, {
        name: 'Karachi',
        val: 'Asia/Karachi'
      }, {
        name: 'Tashkent',
        val: 'Asia/Tashkent'
      }, {
        name: 'Kolkata',
        val: 'Asia/Kolkata'
      }, {
        name: 'Mumbai',
        val: 'Asia/Calcutta'
      }, {
        name: 'Kathmandu',
        val: 'Asia/Katmandu'
      }, {
        name: 'Almaty',
        val: 'Asia/Almaty'
      }, {
        name: 'Dhaka',
        val: 'Asia/Dhaka'
      }, {
        name: 'Ekaterinburg',
        val: 'Asia/Yekaterinburg'
      }, {
        name: 'Rangoon',
        val: 'Asia/Rangoon'
      }, {
        name: 'Bangkok',
        val: 'Asia/Bangkok'
      }, {
        name: 'Jakarta',
        val: 'Asia/Jakarta'
      }, {
        name: 'Novosibirsk',
        val: 'Asia/Novosibirsk'
      }, {
        name: 'Chongqing',
        val: 'Asia/Chongqing'
      }, {
        name: 'Hong Kong',
        val: 'Asia/Hong_Kong'
      }, {
        name: 'Krasnoyarsk',
        val: 'Asia/Krasnoyarsk'
      }, {
        name: 'Kuala Lumpur',
        val: 'Asia/Kuala_Lumpur'
      }, {
        name: 'Perth',
        val: 'Australia/Perth'
      }, {
        name: 'Singapore',
        val: 'Asia/Singapore'
      }, {
        name: 'Taipei',
        val: 'Asia/Taipei'
      }, {
        name: 'Ulaan Bataar',
        val: 'Asia/Ulan_Bator'
      }, {
        name: 'Urumqi',
        val: 'Asia/Urumqi'
      }, {
        name: 'Irkutsk',
        val: 'Asia/Irkutsk'
      }, {
        name: 'Seoul',
        val: 'Asia/Seoul'
      }, {
        name: 'Tokyo',
        val: 'Asia/Tokyo'
      }, {
        name: 'Adelaide',
        val: 'Australia/Adelaide'
      }, {
        name: 'Darwin',
        val: 'Australia/Darwin'
      }, {
        name: 'Brisbane',
        val: 'Australia/Brisbane'
      }, {
        name: 'Canberra',
        val: 'Australia/Canberra'
      }, {
        name: 'Guam',
        val: 'Pacific/Guam'
      }, {
        name: 'Hobart',
        val: 'Australia/Hobart'
      }, {
        name: 'Melbourne',
        val: 'Australia/Melbourne'
      }, {
        name: 'Port Moresby',
        val: 'Pacific/Port_Moresby'
      }, {
        name: 'Sydney',
        val: 'Australia/Sydney'
      }, {
        name: 'Yakutsk',
        val: 'Asia/Yakutsk'
      }, {
        name: 'Vladivostok',
        val: 'Asia/Vladivostok'
      }, {
        name: 'Auckland',
        val: 'Pacific/Auckland'
      }, {
        name: 'Fiji',
        val: 'Pacific/Fiji'
      }, {
        name: 'International Date Line West',
        val: 'Pacific/Kwajalein'
      }, {
        name: 'Kamchatka',
        val: 'Asia/Kamchatka'
      }, {
        name: 'Magadan',
        val: 'Asia/Magadan'
      }, {
        name: "Nuku'alofa",
        val: 'Pacific/Tongatapu'
      }];

      if (scope.ngModel) {
        /** Note
         * actually there is three possible cases depending on user timezone:
         * 1 - exists in condensed list
         * 2 - exists in full list
         * 3 - doesn't exists
         */
        // look for user timezone in available timezones
        var userTZ = _.filter(scope.timezones, function (value) {
          return value.val === scope.ngModel;
        });
        if (userTZ.length) {
          // user timezone exists on avl timezones
          if (_.isUndefined(userTZ[0].tags)) {
            // if not showed in condensed list, add it so that it's displayed
            userTZ[0].tags = ['condensed'];
          }
        } else {
          // if default user timezone doesn't exists on available timezones,
          // set timezone to null to force form validation to ask user for it
          scope.ngModel = null;
        }
      }
    }
  };
}

angular.module('classy').directive('scSelectTimezones', scSelectTimezones);
})();