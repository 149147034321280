(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scRegistrationModel
 *
 */

scRegistrationModel.$inject = ["$http", "$q", "scObjectModel", "scCloneDeep", "scDeepDiff"];
function scRegistrationModel($http, $q, scObjectModel, scCloneDeep, scDeepDiff) {
  function RegistrationModel(data, demo) {
    scObjectModel.call(this);

    this.isNew = !demo && !data;
    this.isDemo = demo || !data;

    this.current = scCloneDeep(data || {});
    this.defaults = scCloneDeep({});
    this.isExample = false;
    this.saved = scCloneDeep(data || {});
    this.type = 'registrations';
  }

  var model = RegistrationModel;

  // Constructor
  model.prototype = _.create(scObjectModel.prototype, {
    constructor: RegistrationModel
  });

  // Methods
  model.prototype.create = create;
  model.prototype.update = update;

  return RegistrationModel;

  /* ---------------------------------------------------------------- *
   * Implementation
   * ---------------------------------------------------------------- */

  function create() {
    // TODO
  }

  function update() {
    var _this = this;

    return scObjectModel.prototype.update.call(this).then(function () {
      var current = _this.current;
      var saved = _this.saved;
      var promises = [];

      if (current.fundraising_page && current.fundraising_page.id === saved.fundraising_page.id && scDeepDiff(current.fundraising_page, saved.fundraising_page)) {
        promises.push($http({
          method: 'PUT',
          url: '/frs-api/fundraising-pages/' + current.fundraising_page.id,
          data: current.fundraising_page
        }).then(function (res) {
          _this.current.fundraising_page = scCloneDeep(res.data);
          _this.saved.fundraising_page = scCloneDeep(res.data);
        }));
      }

      if (current.transaction_item && current.transaction_item.id === saved.transaction_item.id && scDeepDiff(current.transaction_item, saved.transaction_item)) {
        promises.push($http({
          method: 'PUT',
          url: '/frs-api/transaction-items/' + current.transaction_item.id,
          data: current.transaction_item
        }).then(function (res) {
          _this.saved.transaction_item = scCloneDeep(res.data);
          _this.current.transaction_item = scCloneDeep(res.data);
        }));
      }

      return $q.all(promises);
    });
  }
}

angular.module('classy').factory('scRegistrationModel', scRegistrationModel);
})();