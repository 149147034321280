(function(){
'use strict';

/*
 * launchMediaManager: initializes an scFlowModal flow w/ media manager.
 *
 * @param {Object} scope: Context in which the media manager is operating in
 * @param {Object} config: config object passed into scMediaManager
 * @param {Object} flowOptions: scFlowModalService options, like transitions.
 */
launchMediaManager.$inject = ["$q", "scFlowModalService"];
function launchMediaManager($q, scFlowModalService) {
  return function (_ref) {
    var scope = _ref.scope,
        config = _ref.config,
        _ref$flowOptions = _ref.flowOptions,
        flowOptions = _ref$flowOptions === undefined ? {} : _ref$flowOptions;

    var context = scope.$new();

    context.mediaManagerOptions = config;

    var transitionFrom = flowOptions.transitionFrom || 'slideUp';
    var transitionTo = flowOptions.transitionTo || 'slideDown';

    if (scFlowModalService.ui.visible) {
      var returnView = scFlowModalService.activeView;
      var deferred = $q.defer();

      // When canceling out of media manager, redirect user back to previous flow modal.
      if (config.buttons.cancel) {
        context.mediaManagerOptions.buttons.cancel.action = function () {
          deferred.resolve(true);
          scFlowModalService.to(returnView, transitionFrom);
        };
      }

      // Callback that is executed after images have been uploaded.
      context.resolveMediaManager = function ($images) {
        deferred.resolve({
          images: $images
        });
        scFlowModalService.to(returnView, transitionFrom);
      };

      _.set(context, 'mediaManagerOptions.buttons.submit.action', 'resolveMediaManager($images)');

      scFlowModalService.to({
        template: "<div data-sc-media-manager='mediaManagerOptions'></div>",
        maxWidth: 980,
        context: context
      }, transitionTo);

      return deferred.promise;
    }

    return scFlowModalService.open({
      template: "<div data-sc-media-manager='mediaManagerOptions'></div>",
      maxWidth: 980,
      context: context
    });
  };
}

angular.module('classy').factory('launchMediaManager', launchMediaManager);
})();