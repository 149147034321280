(function(){
'use strict';

scRegistrationBlock.$inject = ["registrationEntityFlow", "scBlock", "scCampaignsService", "scCartService", "scFroalaOptions", "scButtonStyleUpdate", "registrationNavigator", "accessibilityService", "countryBlockService"];
function scRegistrationBlock(registrationEntityFlow, scBlock, scCampaignsService, scCartService, scFroalaOptions, scButtonStyleUpdate, registrationNavigator, accessibilityService, countryBlockService) {
  return scBlock({
    link: function link(scope) {
      scope.registrations = _(scCampaignsService.active.current.ticketTypes).filter(function (type) {
        return moment(type.ended_at) > moment() && moment(type.started_at) <= moment();
      }).filter(function (type) {
        // Unlimited
        if (type.quantity_available === null) return true;
        // Count remaining
        var quantityUnavailable = type.quantity_reserved + type.quantity_sold;
        var quantityRemaining = type.quantity_available - quantityUnavailable;
        return quantityRemaining > 0;
      }).sortBy(['weight', 'started_at']).value();

      scope.handleRegistrationSwitch = function (event) {
        countryBlockService.checkOrganizationRestrictions(function () {
          return scope.registrationSwitch(event);
        });
      };

      scope.registrationSwitch = function (event) {
        if (event && event.type !== 'click' && !accessibilityService.isValidKeyBoardEvent(event)) return;

        // capture event trigger to refocus on after modal is closed
        var eventTarget = _.get(event, 'currentTarget', null);

        if (scope.editable) {
          scope.scrollToElement('sc-target-reg-button');
          return;
        }
        if (scCampaignsService.active.IS_TICKETED && _.get(scCartService, 'active.isReserved', false)) {
          registrationNavigator.goToLastState();
        } else {
          registrationEntityFlow(eventTarget);
        }
      };

      scope.froalaOptions = {
        headline: scFroalaOptions({
          type: 'edit-inline-about-title'
        }, {
          charCounterCount: true,
          charCounterMax: 180,
          editorClass: 'no-counter',
          ariaLabel: 'Registration Heading'
        }),
        subheader: {}
      };
    },


    controller: ['$scope', function ($scope) {
      $scope.rwfCampaignInactive = scCampaignsService.active.isInactive();
      $scope.displayRegisterBtn = scCampaignsService.active.current.type === 'ticketed' || scCampaignsService.active.current.allow_fundraising_pages;

      $scope.regBtnStyle = function () {
        if ($scope.rwfCampaignInactive) {
          return {};
        }
        var style = {};
        _.merge(style, $scope.block.registerButtonStyle);

        var colorOrTheme = $scope.block.registerButtonColor || $scope.theme.styles.primaryColor;
        style.color = colorOrTheme;

        return scButtonStyleUpdate(style, colorOrTheme);
      };
    }]
  });
}

angular.module('classy').directive('scRegistrationBlock', scRegistrationBlock);
})();