(function(){
'use strict';

/**
 * Directive that displays a list of subteams.
 * Currently used on the fundraising team page.
 *
 * @param scSubteams - the list of subteams for the feed
 * @param parent - the parent team id for this list of subteams
 *
 * @kind directive
 */

/**
 * The directive
 */
SubteamsController.$inject = ["$scope", "scResponsiveWidth"];
function scSubteams() {
  return {
    scope: {
      subteams: '=scSubteams',
      parent: '=',
      hideMemberCount: '=?'
    },
    templateUrl: 'global/objects/scBlock/shared/subteams/template',
    controller: SubteamsController
  };
}

/**
 * The controller
 */
function SubteamsController($scope, scResponsiveWidth) {
  $scope.getSubteamsEndpoint = '/fundraising-teams/' + $scope.parent + '/subteams?with=team_lead';

  $scope.query = { queryString: '' };
  $scope.filter = {};
  $scope.mobile = scResponsiveWidth.isMobile;

  // async filter query for paginated list of subteams
  $scope.$watch('query.queryString', function (newVal) {
    if (newVal !== '') {
      $scope.filter = {
        name: [{
          operand: '*=',
          value: newVal
        }]
      };
    } else {
      $scope.filter = {};
    }
  });

  $scope.prefilter = function (subteams) {
    $scope.defaultSubteams = _.cloneDeep(subteams);
    return subteams;
  };

  $scope.logoSrc = function (subteam) {
    var src = _.get(subteam, 'logo.cdn_url', '/static/global/images/individual_default_image.png');
    return src;
  };

  $scope.$watch('pagedSubteams', function (newVal) {
    $scope.loading = _.isUndefined(newVal);
  });
}

angular.module('classy').directive('scSubteams', scSubteams);
})();