(function(){
'use strict';

angular.module('classy').constant('scCrowdfundingBlockDefaults', {
  title: 'Hero',
  type: 'crowdfunding',

  disable: false,

  /** General Styles */
  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 75,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  /** Headline */
  headline: '<p class="heading">Your Headline Goes Here</p><p class="subheading">Your subheadline goes here</p>',
  enableHeadline: true,
  hideHeadline: false,
  replaceHeadline: false,
  headlineColor: 'rgb(255,255,255)',
  dateAndTime: '',
  showDateAndTime: true,
  venueLocation: '',
  showVenueLocation: true,
  layoutPosition: 'center',
  headlineImage: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 75,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  /* Progress Bar */
  enableProgressBar: true,
  progressBarColor: null, // '#43CDFD'
  progressBarBgColor: null, // '#000000'

  /* Progress Metrics */
  enableProgressMetrics: true,
  enablePercentOfGoal: true,
  enableNumSupporters: true,
  enableDaysLeft: true,

  dynamicHeight: false,

  /* Donate Button */
  donateButtonColor: null,
  donateButtonStyle: {
    preset: 'rounded',
    'border-radius': '4px',
    color: null
  },

  /* Join Button */
  joinButtonColor: null,
  joinButtonStyle: {
    preset: 'rounded',
    'border-radius': '4px',
    color: null
  },
  /** Featured Content */
  featuredType: 'image',
  featuredVideo: '',
  featuredImg: [],
  useFeatured: true,
  mobileTemplates: false,
  hideFeaturedCtrls: false,

  /** Donate Button */
  donateButtonText: 'Donate Now',

  /** Join Button */
  joinButtonText: null,

  unsortable: true,
  remove: false
});
})();