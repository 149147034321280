(function(){
'use strict';

angular.module('classy').component('intelligentAskLearnMore', {
  templateUrl: 'global/components/intelligent-ask/learn-more/global.components.intelligent-ask.learn-more', // same folder as this
  bindings: {
    header: '@',
    description: '@',
    href: '@'
  }
});
})();