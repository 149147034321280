(function(){
'use strict';

// Filter out duplicates in an array.

function uniqueFilter() {
  return function (arr, filterBy) {
    return _.uniqBy(arr, function (item) {
      return item[filterBy];
    });
  };
}

angular.module('classy').filter('unique', uniqueFilter);
})();