(function(){
'use strict';

/**
  * @name scDollar
  * @kind directive
  * @requires ngModel
  *
  * @description
  * Allows only numeric numbers to be inputed.
  * Semi-depracated. Angular doesn't provide anything as
  * specific as this. input[number] isn't as strict (allows
  * more chars after decimal point and multuple decimal points)
  * Can probably be used in conjunction with ngCurrency to do
  * this though.
  *
  * Used in:
  * - classyapp/public/partials/discount-codes/edit.html
  * - classyapp/public/partials/reports/recurring-modal.html
  * - classyapp/application/views/scripts/checkout/donation.phtml
  * - classyapp/public/classy/src/common/test/unit/directives/form/scDollar.spec.js

  */

function scDollar() {
  'use strict';

  return {
    require: '?ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ctrl) {
      attrs.$set('pattern', '[0-9]*');

      // Validation

      ctrl.$parsers.unshift(function (viewValue) {
        if (viewValue !== '' && !angular.isUndefined(viewValue)) {
          var regexInt = /^(\d*\.\d{1,2}|\d+)$/;

          if (regexInt.test(viewValue)) {
            ctrl.$setValidity('dollar', true);
            return viewValue;
          }
          ctrl.$setValidity('dollar', false);
          return undefined;
        }
        ctrl.$setValidity('dollar', true);
        return viewValue;
      });

      // Prevent non numeric key from being pressed

      element.on('keydown', function (event) {
        var val = element.val();

        if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
        // Allow: Ctrl+A and Ctrl+V
        (event.keyCode == 65 || event.keyCode == 86) && (event.ctrlKey === true || event.metaKey === true) ||
        // Allow: home, end, left, right
        event.keyCode >= 35 && event.keyCode <= 39 ||
        // Allow '.' for donation amount
        (event.keyCode == 190 || event.keyCode == 110) && val.indexOf('.') < 0) {
          // do nothing
          return;
        }

        // Ensure that it is a number and stop the keypress
        if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) ||
        // Allow only one '.' and two decimal spaces
        !angular.isUndefined(val.split('.')[1]) && val.split('.')[1].length > 1) {
          event.preventDefault();
        }
      });
    }
  };
}

angular.module('classy').directive('scDollar', scDollar);
})();