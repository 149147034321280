(function(){
'use strict';

angular.module('classy').constant('COPY', {
  PERMISSIONS: {
    permissionDenied: 'You do not have permission to access this feature. <a href="https://support.classy.org/s/article/roles-and-permissions" target="_blank" rel="noreferrer noopener">Learn more about roles and permissions</a>.',
    permissionDeniedHeader: 'You do not have permission to access this data.',
    permissionDeniedSubheader: 'Learn more about roles and permissions <a href="https://support.classy.org/s/article/roles-and-permissions" target="_blank" rel="noreferrer noopener">here</a>.'
  }
});
})();