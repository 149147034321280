(function(){
'use strict';

scPublish.$inject = ["$timeout", "scPublishMessageService", "scFlowModalService", "scAppealSetService", "scOrganizationsService", "scBanner", "scCampaignsService", "CHANNEL", "COPY"];
function scPublish($timeout, scPublishMessageService, scFlowModalService, scAppealSetService, scOrganizationsService, scBanner, scCampaignsService, CHANNEL, COPY) {
  return {
    restrict: 'A',
    scope: {
      campaign: '='
    },
    templateUrl: 'global/directives/scPublish/scPublish',
    link: function link(scope) {
      var campaignType = scope.campaign.current.type;
      scope.publishEnabled = scOrganizationsService.publishable(campaignType);
      scope.tooltipStyle = {
        mimic: 'top center',
        width: 12
      };
      scope.tooltipMessage = scPublishMessageService(campaignType);

      scope.permissionDeniedMessage = COPY.PERMISSIONS.permissionDenied;

      scope.publishModal = function () {
        scFlowModalService.open({
          templateUrl: 'global/directives/scPublish/modal-confirm-publish',
          context: scope,
          maxWidth: 520
        }, {
          animate: true
        });
      };

      scope.publishCampaign = function () {
        if (!scope.campaign.current.timezone_identifier) {
          scBanner('Please provide a timezone value.', 'error');
          return;
        }
        if (_.isUndefined(scope.pendingPublish) || scope.pendingPublish.$$state.status !== 0) {
          scope.pendingPublish = scope.campaign.publish().then(function () {
            // @HACK:
            // Remove this call when and if publish allows the FRAPI channel to be set on publish.
            if (scCampaignsService && scCampaignsService.active && scCampaignsService.active.getCampaignChannel) {
              scCampaignsService.active.getCampaignChannel(CHANNEL.FRAPI).then(function (result) {
                var currentMetaSharingStatus = _.get(result, '[0].status', false);
                var currentMetaSharingId = _.get(result, '[0].id', null);

                var externalFundraiserId = _.get(result, '[0].metadata.external_fundraiser_id');
                if (currentMetaSharingStatus && !externalFundraiserId) {
                  scCampaignsService.active.putCampaignChannel(currentMetaSharingStatus, currentMetaSharingId).then(function (putCampaignChannelResponse) {
                    // eslint-disable-next-line no-console
                    console.log('putCampaignChannelResponse: ', putCampaignChannelResponse);
                  }, function (putCampaignChannelError) {
                    // eslint-disable-next-line no-console
                    console.log('putCampaignChannelResponse error: ', putCampaignChannelError);
                  });
                }
              });
            }

            scope.publishNotify = true;
            scFlowModalService.close();

            // rescrape & cache page
            scAppealSetService.rescrape('campaign', true);

            $timeout(function () {
              scope.publishNotify = false;
            }, 3000);
          }, function () {
            scFlowModalService.close();
          });
        }
      };

      scope.unpublishModal = function () {
        scFlowModalService.open({
          templateUrl: 'global/directives/scPublish/modal-confirm-unpublish',
          context: scope,
          maxWidth: 520
        }, {
          animate: true
        });
      };

      scope.unpublishCampaign = function () {
        if (_.isUndefined(scope.pendingUnpublish) || scope.pendingUnpublish.$$state.status !== 0) {
          scope.pendingUnpublish = scope.campaign.unpublish().then(function () {
            scope.unpublishNotify = true;
            scFlowModalService.close();
            $timeout(function () {
              scope.unpublishNotify = false;
            }, 3000);
          }, function () {
            scFlowModalService.close();
          });
        }
      };
    }
  };
}

angular.module('classy').directive('scPublish', scPublish);
})();