(function(){
'use strict';

/**
 * @name scStatusBanner
 * @kind directive
 *
 * @description
 * Shows a status banner with a given message when $rootScope.SC.status.banner
 * is updated.
 */

scStatusBanner.$inject = ["$timeout", "$rootScope", "$anchorScroll"];
function scStatusBanner($timeout, $rootScope, $anchorScroll) {
  return {
    template: '\n      <div class="status-banner animate-show" ng-show="visible" ng-class="banner.type" ng-style="banner.color && {\'background-color\': banner.color}" aria-live="assertive">\n        <div role="alert" aria-atomic="true">\n          <span class="status-banner_msg" ng-bind-html="banner.msg | scTrustedHtml"></span>\n          <span class="sr-only" ng-bind-html="banner.msgAccessibility | scTrustedHtml"></span>\n        </div>\n        <button class="status-banner_close {{banner.type}}"\n        type="button"\n        data-ng-click="close()"\n        data-ng-if="banner && banner.type!==\'success\'">\n          <i class="fa fa-times status-banner_icon"></i>\n          <span class="status-banner_close-msg">Close</span>\n        </button>\n      </div>\n    ',

    scope: {},
    replace: true,

    link: function link(scope) {
      var defaultObj = {
        status: {
          banner: {
            type: '', // error or success
            msg: '', // any message
            msgAccessibility: '', // a message specifically for visually impaired users. Will be hidden from DOM
            timeout: true,
            timeoutLength: 3000,
            color: null
          }
        }
      };

      $rootScope.SC = $rootScope.SC ? _.merge($rootScope.SC, defaultObj) : defaultObj;

      scope.banner = $rootScope.SC.status.banner;

      scope.visible = false;

      scope.close = function () {
        if ($rootScope.SC.status.banner.onClose) {
          $rootScope.SC.status.banner.onClose();
        }
        scope.visible = false;
        $rootScope.SC.status.banner = {};
      };

      $rootScope.$watch('SC.status.banner', function (val, oldVal) {
        if (_.isEqual(val, {})) {
          scope.close();
        }
        if (val != oldVal && _.size(val) > 0) {
          if (_.isUndefined($rootScope.SC.status.banner.timeout)) {
            $rootScope.SC.status.banner.timeout = true;
          }

          if (_.isUndefined($rootScope.SC.status.banner.timeoutLength)) {
            $rootScope.SC.status.banner.timeoutLength = 3000;
          }

          scope.visible = true;
          scope.banner = $rootScope.SC.status.banner;

          if (val.type === 'success') {
            $anchorScroll();
          } else if (val.type === 'error') {
            if (_.isUndefined($rootScope.SC.status.banner.timeout)) {
              $rootScope.SC.status.banner.timeout = false;
            }
            // $log.log('Error: ', scope.banner);
          }

          if (scope.banner.timeout) {
            $timeout(function () {
              scope.close();
            }, scope.banner.timeoutLength);
          }
        }
      }, true);
    }
  };
}

angular.module('classy').directive('scStatusBanner', scStatusBanner);
})();