(function(){
'use strict';

angular.module('classy').constant('scTicketedThemeDefaults', {
  type: 'ticketed',
  start: {
    date: new Date(),
    time: new Date()
  },
  styles: {
    headerLogo: null,
    primaryColor: 'rgba(66, 92, 205, 1)',
    aboutBgColor: 'rgba(255,255,255,1)',
    footerBgColor: 'rgba(26,26,26,1)',
    aboutContentColor: 'rgba(26,26,26,1)',
    locationBgColor: 'rgba(255, 255, 255, 1)',
    headlineColor: 'rgba(26,26,26,1)',
    background: {
      preset: 'adaptive',
      color: 'rgba(248,248,248,1)',
      image: null,
      repeat: 'no-repeat',
      imageOpacity: 100,
      colorOpacity: 100,
      blur: 0,
      position: 'center center',
      size: 'initial',
      fixed: false
    },
    registerBtnText: 'Reserve',
    scss: null,
    css: null,
    ticketedEvent: {
      ticketTerm: 'tickets'
    }
  },
  pages: {
    // Landing Page
    landing: {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['crowdfunding', 'about', 'progress-circle', 'location', 'registration', 'activity'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    },

    // Donation Page
    donation: {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['donation'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    },

    // Thank You Page
    'thank-you': {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['thank-you'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    }
  }
});
})();