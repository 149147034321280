(function(){
'use strict';

angular.module('classy').component('exitCartModal', {
  template: '\n      <modal-frame theme="\'dark\'">\n\n        <modal-title ng-if="!$ctrl.isReserved">Your cart is not yet reserved!</modal-title>\n        <modal-subtitle>Are you sure you\'d like to leave?</modal-subtitle>\n        <div class="exit-cart-modal__btn-container">\n          <button class="exit-cart-modal__btn"\n          ng-click="$ctrl.exitAction()"\n          ng-style="{ \'background-color\': $ctrl.primaryColor }">\n            Yes, leave cart.\n          </button>\n          <p class="exit-cart-modal__cancel"\n          ng-click="$ctrl.stayAction()"\n          tabindex="0">Stay on this page.</p>\n        </div>\n\n      </modal-frame>\n    ',

  bindings: {
    exitAction: '&',
    stayAction: '&?',
    primaryColor: '&?',
    isReserved: '<?'
  },

  controller: ["scFlowModalService", function ConfirmExitCartCtrl(scFlowModalService) {
    var _this = this;

    var DEFAULTS = {
      stayAction: scFlowModalService.close,
      isReserved: true
    };

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);
    };
  }]
});
})();