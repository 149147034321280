(function(){
'use strict';

scModalGroupDisplay.$inject = ["$timeout"];
function scModalGroupDisplay($timeout) {
  return {
    restrict: 'A',
    require: ['^?scModalGroup', 'scModalGroupDisplay'],
    controller: [function () {}],
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ctrl) {
      scope.open = function () {
        var modalLink = element.find('[sc-modal-link] > a, [sc-classy-modal-link] > a, ' + '[data-sc-modal-link] > a, [data-sc-classy-modal-link] > a'),
            modal = element.find('.sc-modal, .classy-modal');

        $timeout(function () {
          angular.element(modal).removeClass('ng-cloak');
          angular.element(modalLink).trigger('click');
        });
      };

      scope.close = function () {
        $rootScope.$broadcast('sc-close-modal');
      };

      scope.next = function () {
        if (scope.nextElem) {
          scope.nextElem.trigger('click');
        }
      };

      scope.prev = function () {
        if (scope.prevElem) {
          scope.prevElem.trigger('click');
        }
      };

      // Add modal view
      ctrl[0].addModal(scope);
    }
  };
}

angular.module('classy').directive('scModalGroupDisplay', scModalGroupDisplay);
})();