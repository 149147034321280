(function(){
'use strict';

// Fork of component 'cpAchTokenizer'
angular.module('classy').component('egPayAchTokenizer', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'global/embedded-giving/pay/components/ach/eg.pay.components.ach.tokenizer', // same folder as this
  bindings: {
    onFocus: '<'
  },
  controller: ["cpAPI", "$rootScope", "$window", "$scope", "$timeout", "scCampaignsService", "scScriptLoaderService", "bugsnagClient", function controller(cpAPI, $rootScope, $window, $scope, $timeout, scCampaignsService, scScriptLoaderService, bugsnagClient) {
    var _this = this;

    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    this.$onInit = function () {
      _this.id = 'account-number-iframe-container';
      _this.isLoading = true;
      _this.showErrorMsg = false;
      _this.errorMsgs = {
        required: 'Account number is required.',
        format: 'Account number should be between 4 and 17 digits.',
        customRegEx: 'Account number should be between 4 and 17 digits.'
      };

      // Use the scScriptLoaderService to load the TokenEx script
      scScriptLoaderService.loadScript(SC.tokenex.tokenex_url + '/iframe/iframe-v3.min.js').then(function () {
        _this.buildIframe();
      }).catch(function (error) {
        bugsnagClient.notify(error);
      });

      $scope.$on('tokenizer', function (_event, data) {
        if (data === 'reset') {
          _this.iframe.reset();
        }
      });
    };

    this.updateErrorMessage = function (validation) {
      _this.errorMessage = _this.errorMsgs[validation.validator];
      _this.showErrorMsg = !validation.isValid && _this.ngModelCtrl.$dirty;
    };

    /** -------------------------------------------------------------------- *
     * Setup
     * -------------------------------------------------------------------- */

    this.buildIframe = function () {
      _this.ngModelCtrl.$setViewValue(null);
      _this.ngModelCtrl.$setPristine();
      _this.ngModelCtrl.$setUntouched();
      var origin = $window.location.origin;

      // append whitelisted url(s) if exist
      var whitelistUrl = _.get(scCampaignsService, 'active.current.whitelist_url');
      whitelistUrl = whitelistUrl.split(' ').join(',');
      if (whitelistUrl && whitelistUrl.length > 0) {
        origin += ',' + whitelistUrl;
      }

      cpAPI.getAccountRoutingTokenExConfig(origin).then(function (data) {
        data.styles = {
          base: '\n                width:85%;\n                height:38px;\n                background:transparent;\n                border:0;\n                line-height:38px;\n                vertical-align:middle;\n                font-size:15px;\n                font-family:\'Mulish\',Arial,Helvetica,sans-serif;\n                color: #444b55;\n                outline:none;\n                padding:0 10px;\n                font-weight:400;\n              ',
          placeholder: '\n                color: #444b55;\n                opacity:0.5;\n                font-family:"Mulish",Arial,Helvetica,sans-serif;\n                font-weight:400;\n              '
        };
        data.font = 'Mulish';
        data.placeholder = '000123456789';

        // eslint-disable-next-line no-undef
        _this.iframe = new TokenEx.Iframe(_this.id, data);
        _this.iframe.on('focus', _this.handleFocus);
        _this.iframe.on('blur', _this.handleBlur);
        _this.iframe.on('validate', _this.handleValidate);
        _this.iframe.on('tokenize', _this.handleToken);
        _this.iframe.on('change', _this.handleChange);
        _this.iframe.on('load', _this.handleLoad);

        _this.iframe.load();
      });
    };

    /** -------------------------------------------------------------------- *
     * Event Handlers
     * -------------------------------------------------------------------- */
    this.handleToken = function (tokenData) {
      _this.ngModelCtrl.$setViewValue(tokenData.token);
      _this.ngModelCtrl.$setValidity('required', true);
    };

    this.handleValidate = function (validation) {
      _this.ngModelCtrl.$setValidity('customRegEx', true);
      _this.ngModelCtrl.$setValidity('required', true);
      _this.ngModelCtrl.$setValidity('format', true);
      if (isIE11) {
        // This was a fix for CL-10063 (an ie11 bug).
        // I decided to code defensively here and only execute when ie11
        _this.ngModelCtrl.$setValidity('origin', true);
      }
      if (!validation.isValid) {
        _this.ngModelCtrl.$setValidity(validation.validator, validation.isValid);
      }
      _this.ngModelCtrl.$syncValidity();
      _this.updateErrorMessage(validation);

      $rootScope.$broadcast('eg.donation-payment.is-valid', { isValid: validation.isValid });
    };

    this.handleBlur = function () {
      _this.onFocus(false);
    };

    this.handleFocus = function () {
      _this.onFocus(true);
    };

    this.handleChange = function () {
      _this.ngModelCtrl.$setDirty();
      _this.iframe.tokenize();
      _this.iframe.validate();
    };

    this.handleLoad = function () {
      // Using $timeout here to execute isLoading on the >current digest cycle.
      // This was done because on ie11 (sometimes) the iframe loader stayed visible.
      // Based on my experience with AngularJS, I believe this is a synchronization
      // between the browser (ie11 here) and AngularJS digest cycle.
      $timeout(function () {
        _this.isLoading = false;
      });
    };
  }]
});
})();