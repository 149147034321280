(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingPayPaths', function () {
  var rootPath = 'global/embedded-giving/pay/';

  this.views = {
    root: rootPath + 'views/eg.pay',
    payments: rootPath + 'views/payments/eg.pay.views.payments',
    paymentDetails: rootPath + 'views/payment-details/eg.pay.views.payment-details'
  };
});
})();