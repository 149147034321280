(function(){
'use strict';

/**
 * @kind service
 * @name scErrorHandler
 *
 * @description
 * helper methods for parsing the ua, relying on ua-parser.js
 */

scErrorHandler.$inject = ["$log", "bugsnagClient", "scBanner"];
function scErrorHandler($log, bugsnagClient, scBanner) {
  function handle(_ref) {
    var message = _ref.message,
        error = _ref.error;

    $log.error(error);
    scBanner(message);
    bugsnagClient.notify(error);
  }

  return handle;
}

angular.module('classy').service('scErrorHandler', scErrorHandler);
})();