(function(){
'use strict';

scDonationBlock.$inject = ["scBlock"];
function scDonationBlock(scBlock) {
  return scBlock({
    responsiveSettings: {
      'mobile-ls': 'mobile-ls'
    }
  });
}

angular.module('classy').directive('scDonationBlock', scDonationBlock);
})();