(function(){
'use strict';

/**
 * @kind directive
 * @name scToggle
 * @scope
 *
 * @description
 * Renders a styled "toggle" switch that serves as a proxy for a hidden checkbox
 * input. Rendered label element will have the class "on" when ngModel value is
 * true. If the original element has the class 'disabled', the underlying
 * checkbox will be disabled as well.
 *
 * Used in:
 * application/views/scripts/events/questions.phtml
 *
 * @param {expression} scToggle The property to be used as the ngModel of the
 * new input.

 */

scToggle.$inject = ["scQaId", "accessibilityService"];
function scToggle(scQaId, accessibilityService) {
  'use strict';

  return {
    replace: true,

    template: '\n    <span role="button" aria-label="{{ scLabel }}">\n      <input id="toggle_{{scName}}"\n        name="toggle_{{scName}}"\n        class="form_toggle"\n        type="checkbox"\n        ng-model="scToggle"\n        ng-disabled="disabled"\n        data-ng-change="toggle()"\n        data-ng-true-value="{{ scFlipped ? false : true }}" data-ng-false-value="{{ scFlipped ? true : false }}">\n        <label for="toggle_{{scName}}"\n          class="form_toggle-label"\n          qa-id="{{ scQaId.toggleField }}"\n          tabindex="0" ng-keydown="!disabled && onKeyDown($event)"></label>\n      </span>\n    ',

    scope: {
      scToggle: '=',
      scName: '@',
      scDisabled: '=',
      scFlipped: '=?',
      scLabel: '@',
      disabled: '<?',
      callbackFn: '&?'
    },

    link: function link(scope, element, attrs) {
      scope.scQaId = scQaId;
      var formCtrl = angular.element('[name=editForm]').controller('form');

      if (_.isUndefined(attrs.name)) {
        attrs.scName = new Date().getTime();
      }

      // coerce truthy to true
      scope.scToggle = !!scope.scToggle;

      scope.toggle = function () {
        if (scope.callbackFn) {
          scope.callbackFn();
        }
      };

      scope.disabled = element.hasClass('disabled') || scope.scDisabled;

      scope.onKeyDown = function (event) {
        if (event && accessibilityService.isValidKeyBoardEvent(event)) {
          scope.scToggle = !scope.scToggle;
          if (formCtrl) formCtrl.$setDirty();
          scope.toggle();
        }
      };
    }
  };
}

angular.module('classy').directive('scToggle', scToggle);
})();