(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingPaths', ["EmbeddedGivingPayPaths", function (EmbeddedGivingPayPaths) {
  var egPath = 'global/embedded-giving/';
  var componentsPath = egPath + 'components/';
  var partialsPath = egPath + 'views/partials/';
  var staticImagesPath = '/static/global/images/embedded-giving/';

  this.root = egPath + 'views/eg.views';

  this.views = {
    donatingMask: egPath + 'views/eg.views.donating-mask',
    donation: egPath + 'views/eg.views.donation', // donation consists of [1...M] partials ng-included
    faq: egPath + 'views/eg.views.faq',
    preview: egPath + 'views/eg.views.preview', // essentially a disabled version of the first donation view (used in campiagn builder)
    thankYou: egPath + 'views/eg.views.thank-you'
  };

  this.partials = {
    donationRoot: partialsPath + 'eg.views.partials.donation-root',
    donationOptions: partialsPath + 'eg.views.partials.donation-options',
    donationPaymentMethods: partialsPath + 'eg.views.partials.donation-payment-methods',
    donationRecurringNudge: partialsPath + 'eg.views.partials.donation-recurring-nudge', // optional step
    donationBillingAddress: partialsPath + 'eg.views.partials.donation-billing-address', // partial on payment details
    donationContact: partialsPath + 'eg.views.partials.donation-contact',
    donationDtD: partialsPath + 'eg.views.partials.donation-dtd', // option partial for "double the donation" (included in contact view)
    donationConfirmation: partialsPath + 'eg.views.partials.donation-confirmation', // Donation confirmation view, has "Donate" button
    comment: partialsPath + 'eg.views.partials.donation-comment',
    faqOverlay: partialsPath + 'eg.views.partials.faq-overlay' // Nests the FAQ view in an overlay (without modifying params)
  };

  this.pay = EmbeddedGivingPayPaths.views;

  this.images = {
    recurringNudge: staticImagesPath + 'embedded-giving-recurring-nudge.svg',
    achLogo: staticImagesPath + 'embedded-giving-logo-ach.svg',
    amexLogo: staticImagesPath + 'embedded-giving-logo-amex.svg',
    discoverLogo: staticImagesPath + 'embedded-giving-logo-discover.svg',
    mastercardLogo: staticImagesPath + 'embedded-giving-logo-mastercard.svg',
    visaLogo: staticImagesPath + 'embedded-giving-logo-visa.svg',
    shieldIcon: staticImagesPath + 'embedded-giving-shield-icon.svg',
    comment: staticImagesPath + 'embedded-giving-comment.svg',
    dedication: staticImagesPath + 'embedded-giving-dedication.svg',
    designation: staticImagesPath + 'embedded-giving-designation.svg',
    employeeMatching: staticImagesPath + 'embedded-giving-employee-matching.svg'
  };

  this.components = {
    campaignWhitelisting: componentsPath + 'campaign-whitelisting/eg.components.campaign-whitelisting',
    donationCurrency: componentsPath + 'donation-currency/eg.components.donation-currency',
    donationDedication: componentsPath + 'donation-dedication/eg.components.donation-dedication'
  };
}]);
})();