(function(){
'use strict';

scHtmlBlockSettings.$inject = ["$stateParams", "scThemesService", "scQaId", "scCampaignsTemplateService"];
function scHtmlBlockSettings($stateParams, scThemesService, scQaId, scCampaignsTemplateService) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/html/settings',
    controller: ['$scope', function ($scope) {
      $scope.htmlEditorOptions = {
        useWrapMode: true,
        theme: 'monokai',
        mode: 'html'
      };

      $scope.cssEditorOptions = {
        useWrapMode: true,
        theme: 'monokai',
        mode: 'css'
      };

      $scope.page = scThemesService.active.current.pages[$stateParams.step];
      $scope.scQaId = scQaId;
      $scope.disableCustomHtmlBlock = false;
      $scope.disableCSSBlock = false;
      if (SC.campaign && SC.campaign.campaignTemplateData) {
        $scope.disableHtmlBlock = scCampaignsTemplateService.getAddContentToPageBlock($stateParams.blockId);
        if ($scope.block.type === 'html') {
          var activeBlock = null;
          var foundBlockPayload = scCampaignsTemplateService.getBlockComponent($stateParams.blockId);
          activeBlock = scCampaignsTemplateService.getActiveBlock(foundBlockPayload);
          $scope.disableCustomHtmlBlock = _.get(activeBlock, 'item_attributes.content.locked', false);
          $scope.disableCSSBlock = _.get(SC.campaign.campaignTemplateData, 'components.theme.component_items[0].item_attributes.styles.pages.landing.css.locked', false);
        }
      }
    }],
    link: function link() {}
  };
}

angular.module('classy').directive('scHtmlBlockSettings', scHtmlBlockSettings);
})();