(function(){
'use strict';

angular.module('classy').component('waiverField', {
  template: '\n      <form-label \n        required="$ctrl.required"\n        _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <div class="form__waiver"\n        ng-bind-html="$ctrl.waiver | nl2br"\n        _spec="waiver"\n      ></div>\n\n      <checkbox-control\n        ng-model="$ctrl.model"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        ng-required="$ctrl.required"\n        sc-validity-reporter\n        sc-validity-classes\n        name="{{:: $ctrl.name }}"\n        _spec="input">\n        {{:: $ctrl.checkboxLabel }}\n      </checkbox-control>\n\n      <form-error \n        name="$ctrl.name" \n        messages="$ctrl.errorMessages"\n        _spec="errors"\n      ></form-error>\n    ',
  bindings: {
    model: '=',
    waiver: '<',
    name: '<',
    checkboxLabel: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    label: '<?',
    required: '<?'
  },
  controller: function WaiverFieldCtrl() {
    var DEFAULTS = {
      checkboxLabel: 'I accept the waiver agreement.',
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      label: 'Event Waiver',
      required: false
    };

    this.$onInit = function () {
      _.defaultsDeep(this, DEFAULTS);
    };
  }
});
})();