(function(){
'use strict';

angular.module('classy').constant('scCrowdfundingThemeDefaults', {
  type: 'crowdfunding',
  start: {
    date: new Date(),
    time: new Date()
  },
  styles: {
    headerLogo: null,
    primaryColor: 'rgba(66, 92, 205, 1)',
    aboutBgColor: 'rgba(255,255,255,1)',
    footerBgColor: 'rgba(26,26,26,1)',
    aboutContentColor: 'rgba(26,26,26,1)',
    headlineColor: 'rgba(255,255,255,1)',
    background: {
      color: 'rgba(64, 72, 78, 1)',
      image: null,
      position: 'top center',
      size: 'cover',
      repeat: 'no-repeat',
      imageOpacity: 100,
      colorOpacity: 100,
      blur: 0
    },
    scss: null,
    css: null
  },
  background: {},
  pages: {
    landing: {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['crowdfunding', 'impact', 'about', 'activity'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    },
    donation: {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['donation'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    },
    'thank-you': {
      background: {
        color: null,
        image: null,
        position: 'top center',
        size: 'cover',
        repeat: 'no-repeat',
        imageOpacity: 100,
        colorOpacity: 100,
        blur: 0
      },
      block_sections: [{
        blocks: ['header'],
        name: 'header',
        position: 'top'
      }, {
        blocks: ['thank-you'],
        name: 'body'
      }, {
        blocks: ['footer'],
        name: 'footer',
        position: 'bottom'
      }]
    }
  }
});
})();