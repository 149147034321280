(function(){
'use strict';

/**
 * @ngDoc directive
 * @name scSvgBlockBg
 *
 * @description
 * Sync an SVG area to a block background.
 */

scSvgBlockBg.$inject = ["$location", "$q", "$timeout", "scImg", "scSvgUtils"];
function scSvgBlockBg($location, $q, $timeout, scImg, scSvgUtils) {
  return function (scope, element) {
    // Constants
    var TN_HEIGHT = 90;
    var TN_WIDTH = 150;
    var BG_ID = 'sc-svg_bg-' + Date.now() + scope.$id;
    var PATTERN_ID = 'sc-svg_pattern-' + Date.now() + scope.$id;
    // Util: Expand the element to the full thumbnail width and height.
    var expandAttrs = {
      x: 0,
      y: 0,
      width: TN_WIDTH,
      height: TN_HEIGHT
    };

    /*
     * Background node structure:
     *
     * defs
     * └ g#{BG_ID}
     *   └ rect.sc-svg_bg-color
     *   └ image.sc-svg_bg-image
     *   └ rect.sc-svg_bg-pattern
     * └ pattern#{PATTERN_ID}
     */

    var canvas = Chartist.Svg(element[0]);
    var defs = Chartist.Svg(element.closest('svg')[0]).elem('defs', {}, '', true);
    var group = defs.elem('g', { id: BG_ID });

    // Link node to defined group
    var node = canvas.attr({
      'xlink:href': $location.absUrl() + '#' + BG_ID
    }).attr(expandAttrs);

    // Watch background, send data to loops.
    scope.$watchCollection('block.current.background', function (newBg) {
      // Color is always defined; kick off render loop.
      renderColor(newBg);

      // Image and Pattern require some work.
      renderImagePanes(newBg);
    });

    // Watch image thumbnail specifically, so that we can replace image with
    // thumbnail when it becomes available.
    scope.$watch(function () {
      if (!scImg.isInstance(scope.block.current.background.image)) {
        return null;
      }
      return scope.block.current.background.image.tn(300);
    }, function () {
      renderImagePanes(scope.block.current.background);
    });

    // Watch for state change and update links
    scope.$on('$stateChangeSuccess', function () {
      $timeout(function () {
        node.attr({ 'xlink:href': $location.absUrl() + '#' + BG_ID });
        renderImagePanes(scope.block.current.background);
      });
    });

    // Determine whether and what to send to image and pattern loops.
    function renderImagePanes(bg) {
      var imageData = [];
      var patternData = [];
      if (bg.image) {
        switch (bg.preset) {
          case 'tile':
            patternData.push(bg);
            break;
          default:
            imageData.push(bg);
        }
      }

      renderImage(imageData);
      renderPattern(patternData);
    }

    // Color render loop
    function renderColor(bg) {
      var oldColor = group.querySelectorAll('.sc-svg_bg-color');

      if (bg) {
        group.elem('rect', _.merge({ fill: bg.color, opacity: bg.colorOpacity / 100 }, expandAttrs), 'sc-svg_bg-color');
      }

      if (oldColor) {
        oldColor.svgElements.forEach(function (el) {
          el.remove();
        });
      }
    }

    // Image render loop
    // This function takes an array which is either one or zero elements in length.
    // It will *always* remove the existing images. If there is an element in the array, it will
    // also add a new image to represent the new image asset.
    function renderImage(bgArray) {
      var oldImage = group.querySelectorAll('.sc-svg_bg-image');

      bgArray.map(formatImage).forEach(group.append.bind(group));

      if (oldImage) {
        oldImage.svgElements.forEach(function (svgImg) {
          svgImg.remove();
        });
      }
    }

    // Pattern render loop
    function renderPattern(bgArray) {
      var oldPatterns = group.querySelectorAll('.sc-svg_bg-pattern');
      var patternDefs = defs.querySelectorAll('#' + PATTERN_ID);

      bgArray.forEach(function (bg) {
        group.elem('rect', _.merge({ fill: 'url(' + $location.absUrl() + '#' + PATTERN_ID + ')' }, expandAttrs), 'sc-svg_bg-pattern');

        group.append(formatPattern(bg));
      });

      if (oldPatterns) {
        oldPatterns.svgElements.forEach(function (svgElement) {
          svgElement.remove();
        });
      }

      if (patternDefs) {
        patternDefs.svgElements.forEach(function (svgElement) {
          svgElement.remove();
        });
      }
    }

    function resizeFields(_ref) {
      var preset = _ref.preset;

      switch (preset) {
        case 'center':
          return { resizeMode: 'natural', alignX: 'center', alignY: 'middle' };
        case 'tile':
          return { resizeMode: 'natural', alignX: 'left', alignY: 'top' };
        default:
          return { resizeMode: 'cover', alignX: 'center', alignY: 'middle' };
      }
    }

    // Image formatter
    function formatImage(scImgInstanceData) {
      // Create a new svg image and add the proper class for selection later
      var svgImg = Chartist.Svg('image', {}, 'sc-svg_bg-image');

      // Listen to the image data callback to ensure that the image data has been saved, then
      // use the available data to generate the proper attributes for the svgImg.
      $q.all([scImgInstanceData.image.getDimensions(), scImgInstanceData.image.on('save')]).then(function () {
        var imgDim = {
          width: scImgInstanceData.image.asset.width,
          height: scImgInstanceData.image.asset.height
        };
        var contDim = {
          width: TN_WIDTH,
          height: TN_HEIGHT
        };
        var fields = resizeFields(scImgInstanceData);
        var resized = scSvgUtils.resizeRelative(imgDim, contDim, fields.resizeMode, fields.alignX, fields.alignY);

        svgImg.attr({
          'xlink:href': scImgInstanceData.image.tn(300),
          width: resized.width,
          height: resized.height,
          x: resized.x,
          y: resized.y,
          opacity: scImgInstanceData.imageOpacity / 100
        });
      });

      return svgImg;
    }

    // Format pattern and pattern image
    function formatPattern(bg) {
      var svgElement = Chartist.Svg('pattern', {
        id: PATTERN_ID,
        patternUnits: 'userSpaceOnUse',
        x: 0,
        y: 0
      });

      var svgImage = svgElement.elem('image');

      $q.all([bg.image.getDimensions(), bg.image.on('save')]).then(function () {
        var imgDim = {
          width: bg.image.asset.width,
          height: bg.image.asset.height
        };
        var contDim = {
          width: TN_WIDTH,
          height: TN_HEIGHT
        };
        var resized = scSvgUtils.resizeRelative(imgDim, contDim, 'natural', 'left', 'top');

        svgElement.attr({
          width: resized.width,
          height: resized.height
        });

        svgImage.attr({
          'xlink:href': bg.image.tn(300),
          width: resized.width,
          height: resized.height,
          x: resized.x,
          y: resized.y,
          opacity: bg.imageOpacity / 100
        });
      });

      return svgElement;
    }
  };
}

angular.module('classy').directive('scSvgBlockBg', scSvgBlockBg);
})();