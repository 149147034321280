(function(){
'use strict';

angular.module('classy').component('stateProvinceField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <select-field\n        ng-if="$ctrl.countryValue == \'US\' && !$ctrl.customState"\n        aria-label="state select"\n        class-prop="form__select--light"\n        options="$ctrl.stateOptions"\n        model="$ctrl.model"\n        placeholder="\'Select a state\'"\n        getter-setter="$ctrl.getterSetter"\n      ></select-field>\n\n      <select-field\n        ng-if="$ctrl.countryValue == \'CA\' && !$ctrl.customState"\n        aria-label="province select"\n        class-prop="form__select--light"\n        options="$ctrl.provinceOptions"\n        model="$ctrl.model"\n        placeholder="\'Select a province\'"\n        getter-setter="$ctrl.getterSetter"\n      ></select-field>\n\n      <input-field\n        ng-if="($ctrl.countryValue != \'CA\' && $ctrl.countryValue != \'US\') || $ctrl.customState"\n        placeholder="\'State/region/district\'"\n        getter-setter="true"\n        no-spacer="true"\n        model="$ctrl.model"\n        aria-label="State/Province"></input-field>\n\n      <!-- hidden input for final model -->\n      <input class="form__dropdown--hidden"\n        ng-model="$ctrl.model"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        sc-validity-reporter\n        ng-required="$ctrl.required"\n        type="text"\n        name="{{:: $ctrl.name }}">\n\n      <form-error\n        name="$ctrl.name"\n        messages="$ctrl.errorMessages"\n        _spec="errors"></form-error>\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    country: '<',
    countryGetterSetter: '<?',
    placeholder: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    type: '<?',
    onlyStates: '<?'
  },
  controller: ["US_STATE_LIST", "CA_PROVINCE_LIST", function stateProvinceFieldCtrl(US_STATE_LIST, CA_PROVINCE_LIST) {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      countryGetterSetter: false,
      required: false,
      type: 'enum'
    };

    var getCountry = function getCountry() {
      if (_.isNull(_this.country)) {
        return null;
      }
      if (_this.countryGetterSetter) {
        return _this.country();
      }
      return _this.country;
    };

    // Handle both models. getter-setter or direct value
    var modelAdapter = function modelAdapter(value) {
      if (value) {
        // setter
        if (_this.getterSetter) _this.model(value);else _this.model = value;
        return null;
      } else {
        // getter
        return _this.getterSetter ? _this.model() : _this.model;
      }
    };

    if (this.placeholder) {
      this.model = '';
    }

    this.stateOptions = _.cloneDeep(US_STATE_LIST);
    this.provinceOptions = _.cloneDeep(CA_PROVINCE_LIST);

    if (this.onlyStates) {
      this.stateOptions = _.filter(this.stateOptions, function (option) {
        return !option.canadian;
      });
    }

    this.isListedStateOrProvince = function () {
      return modelAdapter() && (_.filter(_this.stateOptions, { value: modelAdapter() }).length || _.filter(_this.provinceOptions, { value: modelAdapter() }).length);
    };

    this.$onInit = function () {
      if (_this.onlyStates) {
        _this.stateOptions = _.filter(_this.stateOptions, function (option) {
          return !option.canadian;
        });
      }
      _.defaultsDeep(_this, DEFAULTS);

      _this.countryValue = getCountry();

      if (modelAdapter()) {
        if (!_this.isListedStateOrProvince()) {
          _this.customState = true;
        }
      }
    };

    this.$doCheck = function () {
      var newCountry = getCountry();
      if (_this.countryValue !== newCountry) {
        // Reset model if country change
        modelAdapter('');
        _this.countryValue = newCountry;
      }
    };
  }]
});
})();