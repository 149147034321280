(function(){
'use strict';

/**
 * @name scIsExpired
 * @kind directive
 * @requires ngModel
 *
 * @description
 * Invalidates the current field if the referenced expiration date has passed.
 *
 * WARNING: Requires ngModel, even though directive marks it as optional.
 *
 * MODIFIED: ParseInts with radices added, both for general rigor and because
 * otherwise PhantomJS chokes on '08' and '09'.
 * MODIFIED: Added validation for year occurring in the past.
 *
 * Used in:
 * application/views/scripts/checkout/donation.phtml
 * public/partials/reports/recurring-modal.html
 *
 * @param {expression} scIsExpired The field holding the expiration date, which
 * should be an object with format { month: <MM>, year: <YYYY> }.
 */

scIsExpired.$inject = ["$filter"];
function scIsExpired($filter) {
  'use strict';

  return {
    require: '?ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ctrl) {
      scope.$watch(attrs.scIsExpired, function (exp) {
        var month = parseInt($filter('date')(new Date(), 'MM'), 10),
            year = parseInt($filter('date')(new Date(), 'yyyy'), 10);

        if (parseInt(exp.year, 10) < year) {
          ctrl.$setValidity('isExpired', false);
        } else if (parseInt(exp.year, 10) == year) {
          if (parseInt(exp.month, 10) < month) {
            ctrl.$setValidity('isExpired', false);
          } else {
            ctrl.$setValidity('isExpired', true);
          }
        } else {
          ctrl.$setValidity('isExpired', true);
        }
      }, true);
    }
  };
}

angular.module('classy').directive('scIsExpired', scIsExpired);
})();