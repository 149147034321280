(function(){
'use strict';

/**
 * @ngDoc service
 * @name scCampaignGroupService
 *
 * @description
 * Stores an object full o' campaigns
 */

scCampaignGroupService.$inject = ["$http", "$q", "scOrganizationsService", "scCampaignsService", "scThemesService", "scBlocksService", "faqService", "scThemeModel", "scPreflight", "scCartService", "scCloneDeep", "scAnalytics", "scMembersService", "scFeeOnTopService"];
function scCampaignGroupService($http, $q, scOrganizationsService, scCampaignsService, scThemesService, scBlocksService, faqService, scThemeModel, scPreflight, scCartService, scCloneDeep, scAnalytics, scMembersService, scFeeOnTopService) {
  this.create = function (campaign) {
    var deferred = $q.defer();
    var theme = new scThemeModel({ type: campaign.current.type });
    var blocks = _.mapValues(campaign.defaultBlocks, 'current');
    var t = _.get(theme, 'defaults');

    // temporarily store registration url on theme object
    if (campaign.current.registration_url) {
      t.registration_url = campaign.current.registration_url;
      delete campaign.current.registration_url;
    }

    /**
     * FRS-6170 - m11y: Orgs with multi-currency enabled will NOT see
     * multicurrency options for registration with fundraising, because we
     * haven't built that yet. Adding an exception for IS_TICKETED here ensures
     * the goal is always sent for that campaign type, not deleted in favor
     * of a raw goal.
     *
     * TODO: Remove once RwF/Ticketed are multicurrency-ready
     */
    if (scOrganizationsService.active.multiCurrencyDisabled() || campaign.IS_TICKETED) {
      // can't send a raw_goal and goal
      delete campaign.current.raw_currency_code;
    } else {
      delete campaign.current.goal;
    }

    $http({
      method: 'POST',
      url: '/frs-api/campaign-group',
      data: {
        campaign: campaign.current,
        theme: t,
        blocks: blocks,
        faq: faqService.defaultFaq(scOrganizationsService.active.current.name)
      }
    }).then(function (response) {
      scCampaignsService.add(response.data.campaign);
      scCampaignsService.setActive(response.data.campaign.id);
      scBlocksService.init(response.data.blocks);
      scThemesService.add(response.data.theme);
      scThemesService.setActive(response.data.theme.id);

      deferred.resolve(response.data);
    }, function (error) {
      deferred.reject(error);
    });

    return deferred.promise;
  };

  this.detectChanges = function () {
    var changedBlocks = _.filter(scBlocksService.blocks, function (block) {
      var blockDiff = block.mongoDiff();

      // If temp block (new block) trigger diff for saving
      if (typeof blockDiff !== 'undefined' || block.current.id.indexOf('temp_') === 0) {
        return true;
      }
      return false;
    });

    var changedCampaign = scCampaignsService.active.mysqlDiff();
    var changedTheme = typeof scThemesService.active.mongoDiff() !== 'undefined' ? scThemesService.active.mongoDiff() : [];

    return changedBlocks.length + changedCampaign.length + changedTheme.length;
  };

  this.update = function () {
    var deferred = $q.defer(),
        changedBlocks = _.filter(scBlocksService.blocks, function (block) {
      var blockDiff = block.mongoDiff();

      // If temp block (new block) trigger diff for saving
      if (typeof blockDiff !== 'undefined' || block.current.id.indexOf('temp_') === 0) {
        if (block.current.id.indexOf('temp_') === 0) {
          scAnalytics.track('campaign/updated', {
            campaign_id: scCampaignsService.active.current.id,
            organization_id: scOrganizationsService.active.current.id,
            property: 'block ' + block.current.type,
            new: 'created',
            old: null
          });
        }

        return true;
      }
      return false;
    }),
        changedCampaign = scCampaignsService.active.mysqlDiff(),
        changedTheme = typeof scThemesService.active.mongoDiff() !== 'undefined' ? scThemesService.active.mongoDiff() : [];
    var putData = {};

    if (changedBlocks.length) {
      var rawBlocks = [];

      changedBlocks.forEach(function (block) {
        rawBlocks.push(block.current);

        // track changes to the number of impact blocks
        if (_.get(block, 'saved.impactLevels', false) && block.current.impactLevels.length != block.saved.impactLevels.length) {
          var newValue = block.current.impactLevels.length;
          var oldValue = block.saved.impactLevels.length;

          scAnalytics.track('campaign/updated', {
            campaign_id: scCampaignsService.active.current.id,
            organization_id: scOrganizationsService.active.current.id,
            property: 'impact block count',
            new: newValue,
            old: oldValue
          });
        }

        // track changes to the amounts of the impact blocks
        if (_.get(block, 'saved.impactLevels', false) && !_.isEqual(block.saved.impactLevels, block.current.impactLevels)) {
          var _newValue = _.map(block.current.impactLevels, function (level) {
            return level.amount;
          });
          var _oldValue = _.map(block.saved.impactLevels, function (level) {
            return level.amount;
          });

          if (!_.isEqual(_newValue, _oldValue)) {
            scAnalytics.track('campaign/updated', {
              campaign_id: scCampaignsService.active.current.id,
              organization_id: scOrganizationsService.active.current.id,
              property: 'impact block amounts',
              new: _newValue,
              old: _oldValue
            });
          }
        }

        // track changes to the default donation level
        if (_.get(block, 'saved["donation-default"]', false) && !_.isEqual(block.saved['donation-default'], block.current['donation-default'])) {
          var _newValue2 = block.current['donation-default'];
          var _oldValue2 = block.saved['donation-default'];

          scAnalytics.track('campaign/updated', {
            campaign_id: scCampaignsService.active.current.id,
            organization_id: scOrganizationsService.active.current.id,
            property: 'default donation amount',
            new: _newValue2,
            old: _oldValue2
          });
        }

        // track changes to the donation page buttonslevels
        if (_.get(block, 'saved["donation-levels"]') && !_.isEqual(block.saved['donation-levels'], block.current['donation-levels'])) {
          var _newValue3 = _.map(block.current['donation-levels'], function (level) {
            return level.amount;
          });
          var _oldValue3 = _.map(block.saved['donation-levels'], function (level) {
            return level.amount;
          });

          scAnalytics.track('campaign/updated', {
            campaign_id: scCampaignsService.active.current.id,
            organization_id: scOrganizationsService.active.current.id,
            property: 'donation levels',
            new: _newValue3,
            old: _oldValue3
          });
        }

        // track blocks getting disabled/enabled on save
        if (block.current.disable != block.saved.disable) {
          var _newValue4 = block.current.disable ? 'disabled' : 'enabled';
          var _oldValue4 = !block.current.disable ? 'disabled' : 'enabled';

          scAnalytics.track('campaign/updated', {
            campaign_id: scCampaignsService.active.current.id,
            organization_id: scOrganizationsService.active.current.id,
            property: 'block ' + block.current.type,
            new: _newValue4,
            old: _oldValue4
          });
        }

        // track blocks getting deleted on save
        if (block.current.deleted && !block.saved.deleted && block.current.id.indexOf('temp_') != 0) {
          scAnalytics.track('campaign/updated', {
            campaign_id: scCampaignsService.active.current.id,
            organization_id: scOrganizationsService.active.current.id,
            property: block.current.type + ' block',
            new: 'deleted',
            old: 'created'
          });
        }
      });

      putData.blocks = rawBlocks;
    }

    if (changedCampaign.length) {
      putData.campaign = {
        id: scCampaignsService.active.current.id
      };

      _.forEach(changedCampaign, function (value) {
        putData.campaign[value] = scCampaignsService.active.current[value];

        // track changes to the campaign goal
        if (value == 'goal' || value == 'raw_goal') {
          scAnalytics.track('campaign/updated', {
            property: 'goal',
            campaign_id: scCampaignsService.active.current.id,
            organization_id: scOrganizationsService.active.current.id,
            new: parseInt(scCampaignsService.active.current[value], 10),
            old: parseInt(scCampaignsService.active.saved[value], 10)
          });
        }
      });
    }

    if (changedTheme.length) {
      putData.theme = scThemesService.active.current;
    }

    if (!_.isEmpty(putData)) {
      putData.member = scMembersService.active.current;
      scPreflight.prepare(putData).then(function (payload) {
        // blocks can be updated alone but are part of the theme technically;
        // as such, we need to pass a campaign id to the request so that the
        // rediscache for themes successfully is deleted and regrabbed
        if (_.get(payload, 'blocks[0]')) {
          payload.cid = scCampaignsService.active.current.id;
        }

        $http({
          method: 'PUT',
          url: '/frs-api/campaign-group',
          data: payload
        }).then(function (response) {
          if (typeof response.data.campaign !== 'undefined') {
            scCampaignsService.active.saved = scCloneDeep(scCampaignsService.active.current);
          }

          if (typeof response.data.blocks !== 'undefined') {
            // eslint-disable-next-line no-unused-vars
            _.forEach(scBlocksService.blocks, function (block, key) {
              // Remove temp blocks from scBlocksService
              if (key.indexOf('temp_') > -1) {
                delete scBlocksService.blocks[key];
              }
            });

            // Create block models for any new blocks we created
            _.forEach(response.data.blocks, function (block) {
              if (!scBlocksService.get(block.id)) {
                scBlocksService.add(block);
              }
            });

            // Once all blocks are in scBlocksService, ensure saved data
            // matches current.
            _.forEach(response.data.blocks, function (block) {
              delete block.mongo_queries_log;
              delete block.queries_log;
              delete block.updated_at;
              var blocks = scBlocksService.blocks;
              blocks[block.id].saved = scCloneDeep(blocks[block.id].current);
            });
          }

          if (typeof response.data.theme !== 'undefined') {
            // Remove temp blocks from current theme
            var theme = scThemesService.active.current;
            _.forEach(theme.pages, function (page) {
              _.forEach(page.block_sections, function (blockSection) {
                _.forEach(blockSection.blocks, function (block, blockKey) {
                  if (block.id.indexOf('temp_') === 0) {
                    delete blockSection.blocks[blockKey];
                  }
                });
              });
            });

            _.merge(scThemesService.active.current, scCloneDeep(response.data.theme));
            scThemesService.active.saved = scCloneDeep(scThemesService.active.current);
          }

          deferred.resolve(response.data);
        }).catch(function () {});
      }).catch(function () {});
    } else {
      deferred.resolve();
    }

    return deferred.promise;
  };

  this.sync = function (id, isLoad) {
    if (isLoad && SC.campaign && SC.blocks && SC.theme) {
      scCampaignsService.init([SC.campaign]);
      scCampaignsService.setActive(SC.campaign.id);

      // This allows us to get around the circular dependency errors when using
      // the campaign settings in scAnalytics.js
      scAnalytics.setCampaign(scCampaignsService.get(SC.campaign.id));

      scBlocksService.init(SC.blocks);

      scThemesService.init([SC.theme]);
      scThemesService.setActive(SC.theme.id);

      // init scFeeOnTop service which relies on the campaign being available
      scFeeOnTopService.init();

      if (scCampaignsService.active.IS_TICKETED) {
        return scCartService.sync(id, isLoad).then(function () {
          return scCampaignsService.active.current;
        });
      }
    }

    return $q.resolve(scCampaignsService.active.current);
  };
}

angular.module('classy').service('scCampaignGroupService', scCampaignGroupService);
})();