(function(){
'use strict';

function scLocationBlockDefaults() {
  return {
    title: 'Location',
    type: 'location',
    headline: 'Name of Venue Would go Here',
    showHeadline: true,
    venueAddress: '',
    showVenueAddress: true,
    disable: false,
    remove: false,
    background: {
      color: null,
      image: null,
      colorOpacity: 100,
      imageOpacity: 100,
      blur: 0,
      position: 'center center',
      size: 'cover',
      repeat: 'no-repeat',
      fixed: false,
      preset: 'stretch'
    },
    map: {
      display: 'map',
      link: null,
      zoomLevel: 12,
      customMarker: {
        lat: null,
        lng: null
      }
    },
    overlay: {
      color: null,
      opacity: 12
    },
    content: 'Include a quick description or directions about the venue here.',
    showContent: true
  };
}

angular.module('classy').factory('scLocationBlockDefaults', scLocationBlockDefaults);
})();