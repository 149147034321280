(function(){
'use strict';

/**
 * @kind directive
 * @name scDonationSettingsField
 *
 * @description
 * Displays the donation settings module, including amount settings, prefilled amount settings,
 * and the default donation type.
 *
 * @param {string} label Field label
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {expression} levels Array of donation levels from the parent scope
 * @param {expression} levelDefault Default donation level from the parent scope
 * @param {expression} frequencyDefault Default frequency from the parent scope
 * @param {object} givingMode Object describing the giving mode (frequency):
 */

angular.module('classy').directive('scDonationSettingsFieldRf', ["$stateParams", "$rootScope", "accessibilityService", "scField", "scBlocksService", "scCampaignsTemplateService", "scCampaignsService", "scOrganizationsService", "scThemesService", function ($stateParams, $rootScope, accessibilityService, scField, scBlocksService, scCampaignsTemplateService, scCampaignsService, scOrganizationsService, scThemesService) {
  return scField('scDonationSettingsFieldRf', {
    link: function link(scope) {
      var DonationBlock = scBlocksService.getBlockByType('donation');
      var donationLevels = DonationBlock.current['donation-levels']; // donation amounts for one-time
      var recurringDonationLevels = DonationBlock.current['recurring-donation-levels']; // donation amounts for recurring
      var recurringLevels = DonationBlock.current['recurring-level']; // 'one-time' 'daily' 'weekly' etc
      var campaign = scCampaignsService.active;

      // Check if IAA is not restricted for the current organization
      scope.hasIntelligentAsk = campaign.current.type === 'donation' && !scOrganizationsService.getTag('data-use-restriction', true) && _.get(scOrganizationsService, 'active.current.plan.intelligent_ask_amounts', false);

      scope.isIaaActive = false;
      scope.iaa = {
        isOneTimeEnabled: false,
        isRecurringEnabled: false
      };

      scope.showAskAmounts = true;

      scope.theme = scThemesService.active.current;
      scope.preventOldrfForDeque = scOrganizationsService.active.preventOldrfForDeque();
      scope.showRecurringDonationArray = !scope.preventOldrfForDeque && DonationBlock.current['recurring-default'] !== 'one-time';

      scope.frequencyError = false;
      scope.donationAmtError = false;
      scope.recurringDonationAmtError = false;

      scope.handleIaaChange = function (_ref) {
        var oneTime = _ref.oneTime,
            recurring = _ref.recurring;

        var defaultFrequency = DonationBlock.current['recurring-default'];
        scope.iaa.isOneTimeEnabled = oneTime;
        scope.iaa.isRecurringEnabled = recurring;
        scope.isIaaActive = oneTime || recurring;

        if (scope.onlyOneTimeFrequency()) {
          scope.showAskAmounts = !scope.iaa.isOneTimeEnabled;
        } else if (scope.onlyReccurringFrequency()) {
          scope.showAskAmounts = !scope.iaa.isRecurringEnabled;
        } else {
          scope.showAskAmounts = !(scope.iaa.isOneTimeEnabled && scope.iaa.isRecurringEnabled);
        }

        // set the donation mounts to show (one-time or recurring)
        scope.selectDonationArray(scope.iaa.isOneTimeEnabled && !scope.iaa.isRecurringEnabled);

        if (scope.iaa.isOneTimeEnabled) {
          var oneTimeIndex = recurringLevels.findIndex(function (level) {
            return level.type === 'one-time';
          });
          recurringLevels[oneTimeIndex].display_on_page = true;
          DonationBlock.current['donation-default'] = null;
        }

        // Disable all donation amounts exept for monthly if recurring iaa is active
        if (scope.iaa.isRecurringEnabled) {
          recurringLevels.forEach(function (level) {
            if (level.type === 'one-time') {
              return;
            }
            if (level.type === 'monthly') {
              level.display_on_page = true;
              return;
            }
            level.display_on_page = false;
          });

          if (defaultFrequency !== 'one-time' && defaultFrequency !== 'monthly') {
            DonationBlock.current['recurring-default'] = 'monthly';
          }
          DonationBlock.current['recurring-donation-default'] = null;
        }

        scCampaignsService.active.setIntelligentAsk({
          oneTime: !!(scope.iaa && scope.iaa.isOneTimeEnabled),
          recurring: !!(scope.iaa && scope.iaa.isRecurringEnabled)
        });
      };

      scope.hideFrequency = function (frequency) {
        /* EmbeddedGiving only supports one-time and monthly */
        if (campaign && campaign.hasEmbeddedGiving() || scope.iaa.isRecurringEnabled) {
          return frequency !== 'one-time' && frequency !== 'monthly';
        } else {
          return scope.preventOldrfForDeque && ['daily', 'weekly', 'bi-weekly'].includes(frequency);
        }
      };

      scope.frequencyToggle = function () {
        var numberOfDisplayedElements = recurringLevels.filter(function (element) {
          return element.display_on_page;
        });

        if (numberOfDisplayedElements.length < 1) {
          DonationBlock.current['recurring-default'] = recurringLevels[0].type;
          scope.frequencyError = true;
        } else if (numberOfDisplayedElements.length === 1) {
          DonationBlock.current['recurring-default'] = numberOfDisplayedElements[0].type;
          scope.showRecurringDonationArray = !scope.preventOldrfForDeque && DonationBlock.current['recurring-default'] !== 'one-time';
          scope.frequencyError = false;
        } else {
          // check if default frequency is exist in filtered array,
          // if not exist assign set_as_default to next frequency
          var isExistInFilteredArray = numberOfDisplayedElements.some(function (item) {
            return item.type === DonationBlock.current['recurring-default'];
          });

          if (!isExistInFilteredArray) {
            DonationBlock.current['recurring-default'] = numberOfDisplayedElements[0].type;
          }

          if (scope.onlyReccurringFrequency()) {
            scope.showRecurringDonationArray = !scope.preventOldrfForDeque && true;
          }

          scope.frequencyError = false;
        }

        /** NEW RF CHANGES: Broadcast frequency on toggle ON/OFF to render DOM on preview page */
        if (!scope.preventOldrfForDeque) {
          $rootScope.$broadcast('updateFrequencyInPreview', DonationBlock.current['recurring-default']);
        }
      };

      // select donation array
      scope.selectDonationArray = function (settings, keyboardEvent) {
        if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;

        scope.showRecurringDonationArray = !scope.preventOldrfForDeque && settings;
      };

      // get all recurring levels displayed on page
      scope.getRecurringLevels = function () {
        return recurringLevels.filter(function (level) {
          return level.type !== 'one-time' && level.display_on_page;
        });
      };

      // check if only recurring frequencies selected
      scope.onlyReccurringFrequency = function () {
        return !recurringLevels.some(function (level) {
          return level.type === 'one-time' && level.display_on_page;
        });
      };

      // check if only one time frequency selected
      scope.onlyOneTimeFrequency = function () {
        var filteredLevels = recurringLevels.filter(function (level) {
          return level.display_on_page;
        });
        return filteredLevels.length === 1 && filteredLevels[0].type === 'one-time';
      };

      // render recurring button text conditionally
      scope.recurringBtnText = function () {
        return scope.getRecurringLevels().length > 1 ? 'recurring' : scope.getRecurringLevels()[0].type;
      };

      scope.oneTimeBtnStyle = function () {
        // Show as disabled if intelligent ask amount is enabled for one time amounts
        var styles = scope.iaa.isOneTimeEnabled ? {
          'background-color': 'white',
          color: 'rgba(0, 0, 0, 0.4)',
          border: '2px solid rgba(0, 0, 0, 0.4)'
        } : {
          'background-color': !scope.showRecurringDonationArray ? scope.theme.styles.primaryColor : '#fff',
          color: !scope.showRecurringDonationArray ? '#fff' : scope.theme.styles.primaryColor,
          border: '2px solid ' + scope.theme.styles.primaryColor
        };

        return styles;
      };

      scope.recurringBtnStyle = function () {
        // Show as disabled if intelligent ask amount is enabled for recurring amounts
        var styles = scope.iaa.isRecurringEnabled ? {
          'background-color': 'white',
          color: 'rgba(0, 0, 0, 0.4)',
          border: '2px solid rgba(0, 0, 0, 0.4)'
        } : {
          'background-color': scope.showRecurringDonationArray ? scope.theme.styles.primaryColor : '#fff',
          color: scope.showRecurringDonationArray ? '#fff' : scope.theme.styles.primaryColor,
          border: '2px solid ' + scope.theme.styles.primaryColor
        };
        return styles;
      };

      scope.donationAmountToggle = function (level) {
        level.display_on_page = !level.display_on_page;
        scope.donationToggle();
      };

      scope.recurringAmountToggle = function (level) {
        level.display_on_page = !level.display_on_page;
        scope.recurringDonationToggle();
      };

      scope.frequencyTypeToggle = function (level) {
        level.display_on_page = !level.display_on_page;
        scope.frequencyToggle();
      };

      scope.donationToggle = function () {
        var displayedElements = donationLevels.filter(function (level) {
          return level.display_on_page;
        });

        if (displayedElements.length < 1) {
          DonationBlock.current['donation-default'] = null;
          scope.donationAmtError = true;
        } else if (displayedElements.length === 1) {
          DonationBlock.current['donation-default'] = displayedElements[0].amount;
          scope.donationAmtError = false;
        } else {
          // check if default amount is exist in filtered array,
          // if not exist assign set_as_default to next amount
          var isExistInFilteredArray = displayedElements.some(function (item) {
            return item.amount === DonationBlock.current['donation-default'];
          });

          if (!isExistInFilteredArray) {
            DonationBlock.current['donation-default'] = DonationBlock.current['donation-default'] !== null ? displayedElements[0].amount : null;
          }

          scope.donationAmtError = false;
        }
      };

      scope.recurringDonationToggle = function () {
        var displayedElements = recurringDonationLevels.filter(function (level) {
          return level.display_on_page;
        });

        if (displayedElements.length < 1) {
          DonationBlock.current['recurring-donation-default'] = null;
          scope.recurringDonationAmtError = true;
        } else if (displayedElements.length === 1) {
          DonationBlock.current['recurring-donation-default'] = displayedElements[0].amount;
          scope.recurringDonationAmtError = false;
        } else {
          // check if default amount is exist in filtered array,
          // if not exist assign set_as_default to next amount
          var isExistInFilteredArray = displayedElements.some(function (item) {
            return item.amount === DonationBlock.current['recurring-donation-default'];
          });

          if (!isExistInFilteredArray) {
            DonationBlock.current['recurring-donation-default'] = DonationBlock.current['recurring-donation-default'] !== null ? displayedElements[0].amount : null;
          }

          scope.recurringDonationAmtError = false;
        }
      };

      scope.clearDefault = function (evt) {
        var donationAmountArray = scope.showRecurringDonationArray ? recurringDonationLevels : donationLevels;

        if (donationAmountArray.filter(function (level) {
          return level.display_on_page;
        }).length === 1) {
          evt.preventDefault();
        } else if (scope.showRecurringDonationArray) {
          DonationBlock.current['recurring-donation-default'] = null;
        } else {
          DonationBlock.current['donation-default'] = null;
        }
      };

      /** If donation default amount is having value and if value of input amount is changing
      then set the donation default amount with updated value. */
      scope.onChangeAmount = function (amount, idx) {
        if (DonationBlock.current['donation-default'] !== null && idx === scope.defaultAmountIdx && DonationBlock.current['donation-default'] !== amount) {
          DonationBlock.current['donation-default'] = amount;
        }
      };

      /** If recurring donation default amount is having value and if value of input amount is changing
      then set the recurring donation default amount with updated value. */
      scope.onChangeRecurringDonationAmount = function (amount, idx) {
        if (DonationBlock.current['recurring-donation-default'] !== null && idx === scope.defaultRecurringAmountIdx && DonationBlock.current['recurring-donation-default'] !== amount) {
          DonationBlock.current['recurring-donation-default'] = amount;
        }
      };

      scope.disableClearDefault = function () {
        var donationAmountArray = scope.showRecurringDonationArray ? recurringDonationLevels : donationLevels;

        return donationAmountArray.filter(function (level) {
          return level.display_on_page;
        }).length === 1;
      };

      // Templating locking
      if (SC.campaign.campaignTemplateData && $stateParams.step === 'donation') {
        var lockDonationBlock = scCampaignsTemplateService.getBlockByType('donation');
        if (lockDonationBlock && scope.options.givingMode.model === "DonationBlock.current['recurring-level']") {
          scope.lockFieldFrequency = lockDonationBlock.item_attributes['recurring-level'].locked;
        }

        if (lockDonationBlock && scope.options.levels === "DonationBlock.current['donation-levels']") {
          scope.lockFieldDonation = lockDonationBlock.item_attributes['donation-levels'].locked;
        }
      }

      /* EG modifications for this admin component
       * - Allowed frequencies: 'one-time' and 'monthly'
       * - New campaigns have these on by default, this code ensures non-allowed are false ALWAYS
       * - If recurring-level is set to something not allowed, then defaults to first in allowed
       * - Also, naturally handles errors if all
       * - NOTE: also modified hideFrequency to hide non-allowed.
       */
      if (scCampaignsService.active && scCampaignsService.active.hasEmbeddedGiving()) {
        (function (rLevels) {
          var didUpdate = false;

          _.forEach(rLevels, function (lvl) {
            if (lvl.type !== 'one-time' && lvl.type !== 'monthly') {
              lvl.display_on_page = false;
              didUpdate = true;
            }
          });

          if (didUpdate) {
            scope.frequencyToggle();
          }
        })(recurringLevels);
      }

      // TODO unsubscribe on scope destroy
      /** Store defaultAmount index */
      scope.$watch('DonationBlock.current["donation-default"]', function (newVal) {
        if (newVal) {
          scope.defaultAmountIdx = donationLevels.findIndex(function (_ref2) {
            var amount = _ref2.amount;
            return amount === DonationBlock.current['donation-default'];
          });
        }
      });

      /** Store defaultRecurringAmount index */
      scope.$watch('DonationBlock.current["recurring-donation-default"]', function (newVal) {
        if (newVal) {
          scope.defaultRecurringAmountIdx = recurringDonationLevels.findIndex(function (_ref3) {
            var amount = _ref3.amount;
            return amount === DonationBlock.current['recurring-donation-default'];
          });
        }
      });

      // To display donation array, which has error on submit
      scope.$on('DonationAmountErrorEvent', function (event, recurringDonationAmountError) {
        event.preventDefault();
        scope.showRecurringDonationArray = !scope.preventOldrfForDeque && recurringDonationAmountError;
      });
    }
  });
}]);
})();