(function(){
'use strict';

angular.module('classy').component('promoCodeInputField', {
  template: '\n    <div class="form__text-input__outer form__text-input--appended">\n      <input class="form__text-input--dark"\n        name="{{:: $ctrl.name }}"\n        placeholder="{{::$ctrl.placeholder}}"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        ng-model="$ctrl.model"\n        name="{{::$ctrl.name}}"\n        sc-validate-promo-code-input\n        sc-validity-reporter\n        sc-validity-classes\n        promo-code-error="$ctrl.promoError"\n        ng-focus="$ctrl.resetForm()"\n        type="text"\n        _spec="input">\n      <input type="submit"\n        class="submit"\n        ng-disabled="!$ctrl.form[$ctrl.name].$viewValue"\n        ng-style="$ctrl.applyPrimaryColor(!$ctrl.model)"\n        value="Apply" />\n    </div>\n\n    <form-error\n    ng-show="$ctrl.promoError.state == \'invalid\'"\n    name="$ctrl.name"\n    messages="$ctrl.errorMessagesInvalid"\n    _spec="errors"></form-error>\n\n    <form-error\n    ng-show="$ctrl.promoError.state == \'expired\'"\n    name="$ctrl.name"\n    messages="$ctrl.errorMessagesExpired"\n    _spec="errors"></form-error>\n  ',

  bindings: {
    model: '=',
    errorMessages: '<?',
    label: '<?',
    getterSetter: '<?',
    name: '<?',
    required: '<?',
    disableFlag: '=?'
  },

  require: {
    form: '^form'
  },

  controller: ["scThemesService", function controller(scThemesService) {
    var _this = this;

    var DEFAULTS = {
      promoStatus: 'success',
      errorMessagesInvalid: {
        promoCodeValid: 'The promo code is not valid'
      },
      errorMessagesExpired: {
        promoCodeValid: 'The promo code is not valid'
      },
      getterSetter: false,
      promoError: {
        state: null
      }
    };

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);
    };

    this.primaryColor = scThemesService.active.current.styles.primaryColor;

    this.applyPrimaryColor = function () {
      if (!_this.form[_this.name].$viewValue) {
        return {};
      }
      return {
        'background-color': _this.primaryColor,
        'border-color': _this.primaryColor
      };
    };

    // This prevents the input from validating on every keyup change
    this.resetForm = function () {
      if (!_.get(_this.form, [_this.name, '$pending', 'promoCodeValid']) && !_.get(_this.form, [_this.name, '$pristine'])) {
        _this.form.$setPristine();
        _this.form.$setUntouched();
      }
    };
  }]
});
})();