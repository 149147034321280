(function(){
'use strict';

// TODO diff between processor and payment method?
angular.module('classy').factory('PaymentProcessor', function () {
  // payment methods can be shared across different processors
  function PaymentProcessor(data) {
    var d = data || {};
    this.id = d.id || '';
    this.key = d.key;
    this.name = d.name || '';
    this.status = d.status || 'inactive';
    this.currency = d.currency || 'USD';
  }

  PaymentProcessor.prototype.setStatus = function (status) {
    this.status = status || 'inactive';
  };

  return PaymentProcessor;
});
})();