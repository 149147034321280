(function(){
'use strict';

function scMediaLibraryListings() {
  'use strict';

  return {
    scope: { mason: '=scMediaLibraryListings' },
    link: function link(scope, element) {
      scope.mason.setElement(element);
    }
  };
}

angular.module('classy').directive('scMediaLibraryListings', scMediaLibraryListings);
})();