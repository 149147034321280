(function(){
'use strict';

angular.module('classy').component('permissionDeniedMessage', {
  template: '<div class="permission-denied-message">\n    <img class="permission-denied-message__hero-img" src="/static/global/images/teamwork.svg"/>\n    <h1 class="permission-denied-message__header">{{ $ctrl.header}}</h1>\n    <p class="permission-denied-message__subheader" data-ng-bind-html="$ctrl.subheader"></p>\n    </div>',
  bindings: {
    header: '<?',
    subheader: '<?'
  },
  controller: ["COPY", function controller(COPY) {
    if (!this.header) {
      this.header = COPY.PERMISSIONS.permissionDeniedHeader;
    }

    if (!this.subheader) {
      this.subheader = COPY.PERMISSIONS.permissionDeniedSubheader;
    }
  }]
});
})();