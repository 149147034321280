(function(){
'use strict';

angular.module('classy').component('countrySelectField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <select-field\n        name="$ctrl.name"\n        ng-if="!$ctrl.customCountry"\n        aria-label="country select"\n        class-prop="form__select--light"\n        options="$ctrl.countryOptions"\n        model="$ctrl.countrySelectModel"\n        placeholder="\'Select a country\'"\n        required="$ctrl.required"\n      ></select-field>\n      <div class="form__helper" \n        ng-if="$ctrl.countryResitrictedText">\n        {{:: $ctrl.countryResitrictedText}}\n      </div>\n\n      <!-- hidden input for final model -->\n      <input class="form__dropdown--hidden"\n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      sc-validity-reporter\n      ng-required="$ctrl.required"\n      type="text"\n      name="{{:: $ctrl.name }}">\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    placeholder: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    type: '<?'
  },
  controller: ["scCurrencyService", "scOrganizationsService", "countryBlockService", function countryFieldCtrl(scCurrencyService, scOrganizationsService, countryBlockService) {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      required: false,
      type: 'enum'
    };

    var setModel = function setModel(value) {
      if (_this.getterSetter) {
        _this.model(value);
      } else {
        _this.model = value;
      }
    };

    var getModel = function getModel() {
      if (_this.getterSetter) {
        return _this.model();
      }
      return _this.model;
    };

    this.countryResitrictedText = _.get(scOrganizationsService, 'active.current.restricted_country_information.drop_down_text');

    this.isListedCountry = function () {
      return getModel() && _.filter(_this.countryOptions, { value: getModel() }).length;
    };

    this.$onInit = function () {
      if (_this.placeholder) {
        _this.model = '';
      }

      _.defaultsDeep(_this, DEFAULTS);

      countryBlockService.getCountryBlockedList().then(function (response) {
        _this.countryOptions = response;
        _this.setCountry();
      });
    };

    this.$doCheck = function () {
      if (_this.countrySelectModel) {
        setModel(_this.countrySelectModel);
      }
    };

    this.setCountry = function () {
      _this.customCountry = false;
      var org = scOrganizationsService.active;

      if (getModel() && !_this.isListedCountry()) {
        _this.customCountry = true;
        _this.countrySelectModel = getModel();
      } else if (!getModel()) {
        if (org.multiCurrencyDisabled()) {
          _this.countrySelectModel = org.current.country == 'CA' || org.current.country == 'CAN' ? 'CA' : 'US';
        } else {
          _this.countrySelectModel = scCurrencyService.getBrowserLocale() || 'US';
        }
      } else {
        _this.countrySelectModel = getModel();
      }
    };
  }]
});
})();