(function(){
'use strict';

/**
 * @kind service
 * @name scCampaignsService
 *
 * @description
 * Stores an object full o' campaigns
 */

scCampaignsService.$inject = ["scCampaignModel"];
function scCampaignsService(scCampaignModel) {
  this.campaigns = {};

  this.add = function (campaignData) {
    this.campaigns[campaignData.id] = new scCampaignModel(campaignData);
  };

  this.get = function (id) {
    return _.get(this, 'campaigns.' + id, false);
  };

  this.setActive = function (id) {
    this.active = this.get(id);
  };

  this.isDcfAllowed = function () {
    return this.active.current.dcf_allowed;
  };

  this.init = function (campaigns) {
    var _this = this;

    _.forEach(campaigns, function (campaignData) {
      _this.add(campaignData);
    });
  };
}

angular.module('classy').service('scCampaignsService', scCampaignsService);
})();