(function(){
'use strict';

scDateTimezoneTime.$inject = ["mySqlDateTime", "$timeout", "scCampaignsService"];
function scDateTimezoneTime(mySqlDateTime, $timeout, scCampaignsService) {
  return {
    scope: {
      scDateTimezoneTime: '=',
      model: '=',
      tz: '=?',
      dateFormat: '@?',
      noNullPrepopulate: '=?' // true if the date/time should be left empty for a null model on load
    },

    link: function link(scope) {
      setupDTTModel();

      // we only want this to run if new campaign creation?
      scope.$watch('scDateTimezoneTime', updateUTCModel, true);

      scope.$watch('tz', function () {
        if (scope.tz) {
          scope.scDateTimezoneTime.timezone = scope.tz;
        }
      }, true);

      function setupDTTModel() {
        if (scope.noNullPrepopulate && !scope.model) {
          scope.scDateTimezoneTime = {};
          return;
        }

        var timezone = scope.tz || _.get(scCampaignsService, 'active.current.timezone_identifier', moment.tz.guess());

        var datetime = scope.model ? mySqlDateTime.convertUtcToTimezone(scope.model, timezone) : mySqlDateTime.convertClientTimetoTimezone(new Date().getTime(), timezone);

        var dtt = scope.scDateTimezoneTime || {};

        $timeout(function () {
          scope.scDateTimezoneTime = {
            datetime: dtt.datetime || datetime,
            timezone: dtt.timezone || timezone
          };
        });
      }

      function updateUTCModel() {
        var newDTT = scope.scDateTimezoneTime;

        if (newDTT && newDTT.datetime) {
          var shared = new Date();
          var hour = newDTT.datetime.getHours(),
              minute = newDTT.datetime.getMinutes(),
              seconds = newDTT.datetime.getSeconds(),
              year = newDTT.datetime.getFullYear(),
              month = newDTT.datetime.getMonth(),
              day = newDTT.datetime.getDate();

          shared.setFullYear(year);
          shared.setMonth(month, day);
          shared.setHours(hour);
          shared.setMinutes(minute);
          shared.setSeconds(seconds);
          scope.model = mySqlDateTime.convertTimezoneToUtc(shared, newDTT.timezone, scope.dateFormat);
        }
      }
    }
  };
}

angular.module('classy').directive('scDateTimezoneTime', scDateTimezoneTime);
})();