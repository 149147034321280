(function(){
'use strict';

function metaService() {
  var _this = this;

  this.initMeta = function (meta) {
    _this.$meta = meta;
  };

  this.setMetaParam = function (param, value) {
    _this.$meta[param] = value;
  };

  this.getMetaParam = function (param) {
    return _this.$meta[param];
  };
}

angular.module('classy').service('metaService', metaService);
})();