(function(){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function chartistDataFactory(_ref) {
  var title = _ref.title,
      _ref$loading = _ref.loading,
      loading = _ref$loading === undefined ? false : _ref$loading,
      _ref$data = _ref.data,
      data = _ref$data === undefined ? [] : _ref$data,
      _ref$labels = _ref.labels,
      labels = _ref$labels === undefined ? [] : _ref$labels,
      _ref$chartColor = _ref.chartColor,
      chartColor = _ref$chartColor === undefined ? undefined : _ref$chartColor,
      _ref$labelInterpolati = _ref.labelInterpolationFnc,
      labelInterpolationFnc = _ref$labelInterpolati === undefined ? Chartist.noop : _ref$labelInterpolati,
      _ref$showArea = _ref.showArea,
      showArea = _ref$showArea === undefined ? true : _ref$showArea,
      _ref$withPointLabels = _ref.withPointLabels,
      withPointLabels = _ref$withPointLabels === undefined ? false : _ref$withPointLabels,
      _ref$label = _ref.label;
  _ref$label = _ref$label === undefined ? {} : _ref$label;
  var _ref$label$xOffset = _ref$label.xOffset,
      xOffset = _ref$label$xOffset === undefined ? 0 : _ref$label$xOffset,
      _ref$label$yOffset = _ref$label.yOffset,
      yOffset = _ref$label$yOffset === undefined ? -10 : _ref$label$yOffset;

  // If we're loading, don't render any data, because that would show flat lines at zero
  var graphData = loading ? [] : data;

  return {
    title: title,
    data: {
      // We could include any number of arrays of data here and each one would generate their own
      // line on the graph. However, we can only set up one y-axis per graph, so in general, we
      // will only want to display one line per graph.
      series: [graphData],
      labels: labels
    },
    options: {
      chartColor: chartColor,
      showArea: showArea,
      withPointLabels: withPointLabels,
      chartPadding: {
        // add padding to the top of the graph to ensure that point labels are not cut off
        top: 30,
        // add padding to the left of the graph to ensure that even with 13 characters in a y-axis
        // label, we don't get cut off
        left: 45
      },
      // force the graph to always start from zero
      // if this value is not specified, Chartist will pick its own value based on the given data
      low: 0,
      // set the top value for the y-axis
      high: getGraphHighAxis(graphData),
      axisY: {
        // in case we need to format the y-axis labels at all, for things like m11y support
        labelInterpolationFnc: labelInterpolationFnc,
        // don't show the labels while we're still loading data in, because they default to going
        // from [0, 1]. Wait until we have things to show before rendering.
        showLabel: !loading,
        onlyInteger: true
      },
      label: {
        xOffset: xOffset,
        yOffset: yOffset
      }
    }
  };
}

function getGraphHighAxis(data) {
  // 1) Find the largest number in our data array, supplying a 1 as the minimum
  // 2) Math.log(x) / Math.LN10 === Math.log10(x), but older browsers do not yet support log10,
  //    so we calculatea it this way
  // 3) Round the number up (with ceil) to an integer N. Using "round" would not always produce
  //    the correct result, as we always want to bias to something higher
  // 4) return 10^N
  // This will give us a "top" of the y-axis which is scaled up for the data. If everything is
  // empty, the top will be 1. If the number is [1, 10], the result will be 10. If the number is
  // [11, 100], the result will be 100. If the number is [101, 1000], the result will be 1000, etc.
  // We add an `|| 1` at the end because if data is full of undefined values, the result will be
  // a NaN (which we want to avoid by falling back to 1).
  return Math.pow(10, Math.ceil(Math.log(Math.max.apply(Math, [1].concat(_toConsumableArray(data)))) / Math.LN10)) || 1;
}

angular.module('classy').factory('ChartistDataFactory', function () {
  return chartistDataFactory;
});
})();