(function(){
'use strict';

/**
 * @kind service
 * @name scOrganizationsService
 *
 * @description
 * Stores an object full o' organizations
 */

scOrganizationsService.$inject = ["$http", "$q", "$window", "scOrganizationModel"];
function scOrganizationsService($http, $q, $window, scOrganizationModel) {
  var _this2 = this;

  this.organizations = {};

  this.add = function (organizationData) {
    this.organizations[organizationData.id] = new scOrganizationModel(organizationData);
  };

  this.get = function (id) {
    return _.get(this, 'organizations.' + id, false);
  };

  this.list = function () {
    return this.organizations;
  };

  this.init = function (organizations) {
    var _this = this;

    _.forEach(organizations, function (organizationData) {
      _this.add(organizationData);
    });
  };

  this.setActive = function (id) {
    this.active = this.get(id);
  };

  this.getAdminData = function () {
    if ($window.SC.public) return $q.resolve();

    // add whether org has a payment method to the organization object
    // this needs to occur after the current org is set
    // but before any publishing methods are called
    return _this2.getHasPaymentMethod();
  };

  this.sync = function (id, isLoad) {
    var orgPromise = $q.resolve();

    // If the org is already currently set, we have already retreived relevant
    // peripheral data (e.g. publishable information) so we can just return it.
    if (_.get(_this2, 'active.current.id') == id) {
      return $q.resolve(_this2.active.current);
    } else if (isLoad && SC.organization) {
      _this2.init([SC.organization]);
      _this2.setActive(SC.organization.id);
    } else {
      orgPromise = $http({
        method: 'GET',
        url: '/frs-api/organizations/' + id
      }).then(function (_ref) {
        var data = _ref.data;

        _this2.add(data);
        _this2.setActive(id);
        return data;
      });
    }

    return orgPromise.then(function () {
      return _this2.getAdminData().then(function () {
        return _this2.active.current;
      });
    });
  };

  // 6.2.21 added @param 'ignoreKebab'due to CL-9479 having a colon in key
  this.getTag = function (key, ignoreKebab) {
    var keybab = ignoreKebab ? key : _.kebabCase(key);
    var tags = _.get(this, 'active.current.tags', []);
    var entry = _.find(tags, function (val) {
      return _.startsWith(val, keybab);
    });
    if (!entry) return null;
    return entry.replace(keybab + ':', '');
  };

  this.hasPay = function () {
    var orgProviders = _.get(this, 'active.current.providers', []);
    return _.includes(orgProviders, 'Classy Pay');
  };

  this.getOrgStatus = function () {
    return _.get(this, 'active.current.status', '');
  };

  // If an org does not have one of these statuses, they are some form of
  // "don't let them transact". In these cases, we should not allow the org to attempt publishing,
  // because the API will prevent them and (even if they were to publish) the 'published' campaign
  // would 404 and things would get pretty weird.
  // See https://classydev.atlassian.net/browse/FRS-7346 and linked tickets for context.
  this.hasActiveStatus = function () {
    return ['active', 'past_due', 'grandfathered', 'org_profile'].includes(this.getOrgStatus());
  };

  this.getHasPaymentMethod = function () {
    var deferred = $q.defer();

    // 05/01/19 zuora release hotfix
    // added to allow PAYG orgs without a payment method to publish
    // need to follow up with a fix to check for PAYG going forward
    this.active.current.hasPaymentMethod = true;
    deferred.resolve();
    return deferred.promise;

    // this.active
    //   .getPaymentMethod()
    //   .then(({ data }) => {
    //     const paymentMethod = _.get(data, 'data', []);
    //     this.active.current.hasPaymentMethod = Boolean(paymentMethod.length);
    //     deferred.resolve();
    //   })
    //   .catch(err => {
    //     deferred.reject(err);
    //   });

    // return deferred.promise;
  };

  this.isZuoraOrg = function () {
    // const billingProvider = _.get(this, 'active.current.billing_provider_name', false);
    // const isZuoraOrg = billingProvider === 'zuora';
    // return isZuoraOrg;

    // 05/01/19 zuora release hotfix
    // added to allow PAYG orgs without a payment method to publish
    // need to follow up with a fix to check for PAYG going forward
    return false;
  };

  this.publishable = function (campaignType) {
    var hasPay = this.hasPay();
    var isExceptionOrg = this.getTag('pay-exception-org');
    var hasProcessor = _.get(this, 'active.current.hasProcessor', false);
    // if this isn't a zuora org, we don't want to restrict them based on payment method
    var hasPaymentMethod = !this.isZuoraOrg() || _.get(this, 'active.current.hasPaymentMethod', false);

    // hasProcessor - we're using this way to check because although an
    // org might not have a payment_provider_id attached, it can
    // somehow still have a valid provider, GET via the payment-
    // provider-configurations endpoint populated during xp.___.js

    // exception orgs without pay can only publish
    // non pay dependent campaigns and needs a provider or to be created
    // before 2013-05-13 (grandfathered in, probably a Classy PayPal user)
    var exceptionPublishable = isExceptionOrg && campaignType !== 'reg_w_fund' && campaignType !== 'donation' && campaignType !== 'ticketed' && (hasProcessor || moment(this.active.current.created_at).isBefore(moment('2013-05-13')));

    // to publish, you need to either have Pay set up, or fulfill the
    // above exception requirements
    // if this is a zuora org, they must have a payment method set up to publish
    // Note that if the org is not considered "active" in status, no amount of setting up pay or
    // other exceptions should allow them to publish.
    return this.hasActiveStatus() && (hasPay && hasProcessor && hasPaymentMethod || exceptionPublishable);
  };
}

angular.module('classy').service('scOrganizationsService', scOrganizationsService);
})();