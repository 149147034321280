(function(){
'use strict';

scTicketedEventDefaultBlocks.$inject = ["scBlockModel"];
function scTicketedEventDefaultBlocks(scBlockModel) {
  return {
    header: new scBlockModel({ type: 'header' }),
    crowdfunding: new scBlockModel({
      type: 'crowdfunding',
      layout: 'rwf',
      headline: '<p class="heading">Name of Your Event</p>',
      useFeatured: false,
      joinButtonStyle: {
        borderRadius: 3,
        preset: 'rounded'
      },
      background: {
        preset: 'adaptive'
      }
    }),
    about: new scBlockModel({
      type: 'about',
      background: {
        preset: 'adaptive'
      },
      headline: 'About the Event',
      content: "<p style='font-size: 22px'>We're addressing an important issue that affects many people. Our mission is to spread awareness and raise money  for solutions we have identified.</p><p>We created this event for everyone to contribute at various impact levels.  Be sure to review the ways you can make an impact and please share this page with your family and friends.</p><p>Your involvement is critical to our success and solving this issue. Thank you again for your help.</p>"
    }),
    donation: new scBlockModel({
      type: 'donation',
      background: {
        preset: 'coverfixed'
      }
    }),
    thankYou: new scBlockModel({
      type: 'thank-you',
      background: {
        preset: 'coverfixed'
      }
    }),
    'progress-circle': new scBlockModel({
      type: 'progress-circle',
      background: {
        preset: 'adaptive'
      },
      registerButtonText: 'Get Tickets',
      headline: "We're raising money for the cause to make a difference!",
      subheadline: 'Help the cause by attending the event or making a donation'
    }),
    activity: new scBlockModel({
      type: 'activity',
      background: {
        preset: 'adaptive'
      },
      headline: '<span>Activity</span>',
      cta: 'Share a message of support for the cause'
    }),
    footer: new scBlockModel({ type: 'footer' }),
    location: new scBlockModel({ type: 'location' }),
    registration: new scBlockModel({
      type: 'registration',
      background: {
        preset: 'adaptive'
      },
      title: 'Tickets',
      headline: 'Tickets',
      subheading: 'Purchase your tickets before the event',
      registerButtonText: 'Get Tickets'
    })
  };
}

angular.module('classy').factory('scTicketedEventDefaultBlocks', scTicketedEventDefaultBlocks);
})();