(function(){
'use strict';

scDomainsService.$inject = ["$http", "$q", "scOrganizationsService", "scCampaignsService"];
function scDomainsService($http, $q, scOrganizationsService, scCampaignsService) {
  this.check = function (slug) {
    return this.getOrganizationDomainId().then(function (resp) {
      return $http({
        method: 'GET',
        url: '/frs-api/domain-slugs/availability-check/' + slug,
        params: { domain_id: resp || null }
      });
    });
  };

  /*
   * checkOwnership (slug, entityId)
   * usage: scDomainsService.check only checks if a slug is reserved or not;
   *        however, that will fail validation when a slugged entity
   *        (with id = entityId) selects its own, current, slug
   */
  this.checkOwnership = function (slug, entityId) {
    return $http({
      method: 'GET',
      url: '/frs-api/domain-slugs/check/' + slug
    }).then(function (resp) {
      return (
        // only return true if this slug exists and is owned by the entity
        $q.resolve(resp.data.exists && entityId == resp.data.fundraising_entity_id)
      );
    });
  };

  this.getOrganizationDomainId = function () {
    var q = $q.defer();

    var domainId = _.get(scOrganizationsService, 'active.current.domain_mask.id', false);

    if (!domainId) {
      $http({
        method: 'GET',
        url: '/frs-api/organizations/' + scOrganizationsService.active.current.id + '/domains'
      }).then(function (response) {
        q.resolve(_.get(response, 'data.data[0].id', false));
      }, function () {
        q.resolve(false);
      });
    } else {
      q.resolve(domainId);
    }

    return q.promise;
  };

  this.findFullRoute = function (slug) {
    return $http({
      method: 'GET',
      url: '/frs-api/vanity/' + slug
    });
  };

  this.updateSlug = function (slugId, slug) {
    var data = { value: slug };
    if (scCampaignsService.active.current.type == 'donation') {
      data.links_to_donation_page = true;
    }
    return $http({
      method: 'PUT',
      url: '/frs-api/domain-slugs/' + slugId,
      data: data
    });
  };

  this.deleteSlug = function (slugId) {
    return $http({
      method: 'DELETE',
      url: '/frs-api/domain-slugs/' + slugId
    });
  };

  this.postSlug = function (entityType, entityId, slug) {
    return this.getOrganizationDomainId().then(function (response) {
      var data = { value: slug };
      if (scCampaignsService.active.current.type == 'donation') {
        data.links_to_donation_page = true;
      }
      if (response) {
        data.domain_id = response;
      }
      return $http({
        method: 'POST',
        url: '/frs-api/' + entityType + '/' + entityId + '/domain-slugs',
        data: data
      });
    });
  };

  this.getSlug = function (entityType, entityId) {
    return $http({
      method: 'GET',
      url: '/frs-api/' + entityType + '/' + entityId + '/domain-slugs'
    });
  };

  // This intelligently will decide whether to update or create a slug, or delete
  this.saveChanges = function (oldSlugObj, newSlugVal, hadSlug, type, id) {
    // neglecting to check here because it should fail, and the validator
    // scVanity uses the check
    if (hadSlug && newSlugVal == '' && oldSlugObj.id) {
      return this.deleteSlug(oldSlugObj.id);
    } else if (hadSlug && oldSlugObj.value && oldSlugObj.value != newSlugVal) {
      return this.updateSlug(oldSlugObj.id, newSlugVal);
    } else if (!hadSlug && newSlugVal) {
      return this.postSlug(type, id, newSlugVal);
    }
    return $q.reject();
  };
}

angular.module('classy').service('scDomainsService', scDomainsService);
})();