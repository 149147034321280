(function(){
'use strict';

/*
 * EmbeddedGivingBridgeGA4
 * Houses all logic required to support GA4 in a cross-domain context
 *
 * On a standard donation page, gtag/ga4 is initialized within the xp.layout.html file.
 * Appv2/EG, in 99% of production use cases, operates in a cross-domain context.
 *
 * However, gtag/ga4 does not work in a cross-domain context. Our solution for this problem is
 * to leverage window.parent.postMessage with EG's SDK, which is bootrapped on a host site.
 *
 *
 * This "Bridge" sends two types of messages via postMessage
 *    1) init (key = 'embedded-giving:bridge:ga4:init')
 *    2) event (key = 'embedded-giving:bridge:ga4:event')
 *
 * 1) INIT MESSAGE
 * Used to configure the <script> block that is injected into the host site's DOM. This <script>
 * block is what initializes and configures gtag/GA4.
 *
 *
 * SDK GA4 singleton:
 * - subs to postMessage itself, parses JSON object literal
 * - proxies custom events to gtag (if initialized)
 *
 *
 * To call init, certain requirements must be met, which can be seen @canInit().
 * postMessage({
 *      key: embedded-giving:bridge:ga4:init,
 *      config: {
 *        id: SC.clientGA4Id,     // init requirement, set in appv2's express layer
 *        domainMask: SC.organization.domain_mask.parent_domain       // optional
 *      }
 * })
 *
 * 2) EVENT MESSAGE
 * Example:
 * window.gtag('event', event, data);
 *
 * Translates to:
 * postMessage({
 *      key: embedded-giving:bridge:ga4:event,
 *      event,
 *      data
 * })
 *
 */
angular.module('classy').service('EmbeddedGivingBridgeGA4', ["$log", "$stateParams", "$window", "EmbeddedGivingUtil", function ($log, $stateParams, $window, EmbeddedGivingUtil) {
  var _this = this;

  var ORG_TAG = 'early-access:eg2-ga4';
  this.INIT_KEY = 'embedded-giving:bridge:ga4:init';
  this.EVENT_KEY = 'embedded-giving:bridge:ga4:event';

  var isInit = false;

  this.init = function () {
    if (_this.canInit()) {
      if (!_this.hasDomainMask()) {
        __postMessage({
          key: _this.INIT_KEY,
          config: {
            id: SC.clientGA4Id
          }
        });
      } else {
        __postMessage({
          key: _this.INIT_KEY,
          config: {
            id: SC.clientGA4Id,
            domainMask: SC.organization.domain_mask.parent_domain
          }
        });
      }
      isInit = true;
    }
  };

  this.isInit = function () {
    return isInit;
  };

  this.canInit = function () {
    if (!_this.hasClientGA4Id()) {
      $log.debug('[EGBridge:GA4:canInit] SC.clientGA4Id required');
      return false;
    }
    if (!_this.hasOrgTag()) {
      $log.debug('[EGBridge:GA4:canInit] org tag ' + ORG_TAG + ' required');
      return false;
    }
    if (!_this.hasUrlParam()) {
      $log.debug('[EGBridge:GA4:canInit] url param "egga4" required');
      return false;
    }
    if (!EmbeddedGivingUtil.isInIframe()) {
      $log.debug('[EGBridge:GA4:canInit] not in an iframe');
      return false;
    }

    return true;
  };

  this.hasClientGA4Id = function () {
    return SC && SC.clientGA4Id;
  };

  this.hasDomainMask = function () {
    return SC && SC.organization && SC.organization.domain_mask && SC.organization.domain_mask.parent_domain;
  };

  this.hasOrgTag = function () {
    return SC && SC.organization && SC.organization.tags && SC.organization.tags.length && SC.organization.tags.includes(ORG_TAG);
  };

  this.hasUrlParam = function () {
    return !!$stateParams.egga4;
  };

  this.track = function (eventName, eventData) {
    if (!eventName) {
      $log.debug('[EGBridge:GA4:track] param eventName required');
      return;
    }

    __postMessage({
      key: _this.EVENT_KEY,
      event: eventName,
      data: eventData
    });
  };

  function __postMessage(msg) {
    if (!$window.parent) {
      $log.warn('[EGBridge:GA4:postMessage] no parent window');
      return;
    }
    // TODO: expand validation. require schema in comment above
    if (!msg || _.isEmpty(msg) || !msg.key) {
      $log.debug('[EGBridge:GA4:postMessage] invalid msg');
      return;
    }

    $log.log('[EGBridge:GA4:postMessage] posting!!! ', msg);
    $window.parent.postMessage(msg, '*');
  }
}]);

// NOTES:
// - cannot SYN/ACK with SDK on init because multi-threaded.
// - - we can, but it would be complicated on the SDK and not synchronous
// - should init be formatted differently?
// - do we need window.gtag('event', 'appv2_default2'); that is in layout? send on SDK?? will only be sent once...
// - re-init on destroy?
// - do we need to include campaignId?
// - remove from xp.layout file if EG campaign?
// - make generic to all scAnalytics services?
// - restrict canInit to in whitelisted iframe?
// - in init(), send default appv2 event in xp.layout file?
})();