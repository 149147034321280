(function(){
'use strict';

/**
 * @kind factory
 * @name scBlockDefaults
 *
 * @description
 * Aggregates the default block settings
 * from each block type.
 */

scBlockDefaults.$inject = ["scCrowdfundingBlockDefaults", "scHeaderBlockDefaults", "scAboutBlockDefaults", "scDonationBlockDefaults", "scDonationBlockDefaultsRf", "scImpactBlockDefaults", "scThankYouBlockDefaults", "scActivityBlockDefaults", "scFooterBlockDefaults", "scHtmlBlockDefaults", "scTeamBlockDefaults", "scTextBlockDefaults", "scFundraiserBlockDefaults", "scFundraisersBlockDefaults", "scRegistrationBlockDefaults", "scFeaturedBlockDefaults", "scLocationBlockDefaults", "scCustomBlockDefaults", "scGlobalCssBlockDefaults", "scProgressCircleBlockDefaults"];
function scBlockDefaults(scCrowdfundingBlockDefaults, scHeaderBlockDefaults, scAboutBlockDefaults, scDonationBlockDefaults, scDonationBlockDefaultsRf, scImpactBlockDefaults, scThankYouBlockDefaults, scActivityBlockDefaults, scFooterBlockDefaults, scHtmlBlockDefaults, scTeamBlockDefaults, scTextBlockDefaults, scFundraiserBlockDefaults, scFundraisersBlockDefaults, scRegistrationBlockDefaults, scFeaturedBlockDefaults, scLocationBlockDefaults, scCustomBlockDefaults, scGlobalCssBlockDefaults, scProgressCircleBlockDefaults) {
  return function (type) {
    var preventRecurringFrequency = false;
    var blockDefaults = {};

    if (SC && SC.organization && SC.organization.tags) {
      preventRecurringFrequency = SC.organization.tags.includes('prevent-recurring-frequencies');
    }

    switch (type) {
      case 'crowdfunding':
        blockDefaults = scCrowdfundingBlockDefaults;
        break;

      case 'header':
        blockDefaults = scHeaderBlockDefaults;
        break;

      case 'about':
        blockDefaults = scAboutBlockDefaults;
        break;

      case 'donation':
        blockDefaults = preventRecurringFrequency ? scDonationBlockDefaults : scDonationBlockDefaultsRf;
        break;

      case 'impact':
        blockDefaults = scImpactBlockDefaults;
        break;

      case 'thank-you':
        blockDefaults = scThankYouBlockDefaults;
        break;

      case 'activity':
        blockDefaults = scActivityBlockDefaults;
        break;

      case 'progress-circle':
        blockDefaults = scProgressCircleBlockDefaults;
        break;

      case 'footer':
        blockDefaults = scFooterBlockDefaults;
        break;

      case 'html':
        blockDefaults = scHtmlBlockDefaults;
        break;

      case 'global-css':
        blockDefaults = scGlobalCssBlockDefaults;
        break;

      case 'team':
        blockDefaults = scTeamBlockDefaults;
        break;

      case 'text':
        blockDefaults = scTextBlockDefaults;
        break;

      case 'fundraiser':
        blockDefaults = scFundraiserBlockDefaults;
        break;

      case 'fundraisers':
        blockDefaults = scFundraisersBlockDefaults;
        break;

      case 'featured':
        blockDefaults = scFeaturedBlockDefaults;
        break;

      case 'location':
        blockDefaults = scLocationBlockDefaults;
        break;

      case 'registration':
        blockDefaults = scRegistrationBlockDefaults;
        break;

      case 'custom':
        blockDefaults = scCustomBlockDefaults;
        break;

      default:
        blockDefaults = {};
        break;
    }

    return blockDefaults;
  };
}

angular.module('classy').factory('scBlockDefaults', scBlockDefaults);
})();