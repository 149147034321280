(function(){
'use strict';

CFPDetailsViewFactory.$inject = ["scFroalaOptions", "scFundraisingTeamsService", "scMembersService", "scAnalytics", "CHAR_LIMITS"];
function CFPDetailsViewFactory(scFroalaOptions, scFundraisingTeamsService, scMembersService, scAnalytics, CHAR_LIMITS) {
  return function CFPDetailsView(parentScope) {
    var $scope = parentScope.$new();

    $scope.member = scMembersService.active;

    $scope.froalaOptions = scFroalaOptions({ type: 'textarea' }, { charCounterCount: true, charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_APPEAL });

    $scope.today = new Date();

    // Show dark outline around scCurrencyPicker input on focus, hide on blur
    $scope.show = {
      inputFocus: false
    };

    $scope.designationOptions = {
      fieldName: 'program',
      valueKey: 'id',
      labelKey: 'name',
      required: false,
      check: {
        enabled: true,
        condition: '$scope.form.program.$dirty && $scope.form.program.$valid && $scope.form.program.$touched'
      }
    };

    $scope.getTeamName = function () {
      return $scope.ui.hasTeam ? scFundraisingTeamsService.active.current.name : null;
    };

    $scope.getTeamLogo = function () {
      return $scope.ui.hasTeam ? _.get(scFundraisingTeamsService, 'active.current.logo.src', '/static/global/images/team_default_image.png') : null;
    };

    $scope.eventBeacon = function () {
      scAnalytics.eventBeacon({
        category: 'frs ' + $scope.campaign.current.type,
        action: 'triggers',
        label: 'Create Your Page'
      });
    };

    /**
     * Tribute Campaign
     */
    $scope.fundraiser.current.is_tribute = false;
    // Alias is leveraging an existing and unused property that's already part of the data model for the tribute name.
    $scope.fundraiser.current.alias = '';
    var defaultGoal = null;
    var defaultLogo = null;

    $scope.tributeCheckboxOnChange = function () {
      /**
       * If original goal and logo haven't been stored, then store
       * current goal & logo so they aren't lost.
       */
      if (defaultGoal === null) {
        defaultGoal = parentScope.fundraiser.current.goal;
        defaultLogo = parentScope.fundraiser.current.logo;
      }

      /**
       * Handle toggle of tribute checkbox. If checked and then unchecked, load
       * original value
       */
      if ($scope.fundraiser.current.is_tribute) {
        $scope.$parent.fundraiser.current.goal = 0;
        $scope.$parent.fundraiser.current.logo = null;
      } else {
        $scope.$parent.fundraiser.current.goal = defaultGoal;
        $scope.$parent.fundraiser.current.logo = defaultLogo;
      }
    };

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.details',
      templateUrl: $scope.flow.template('details'),
      maxWidth: 400,
      context: $scope,
      eventBeacon: $scope.eventBeacon
    };
  };
}

angular.module('classy').factory('CFPDetailsView', CFPDetailsViewFactory);
})();