(function(){
'use strict';

/**
 * @kind directive
 * @name scValidateEmailAddress
 * @requires ngModel
 *
 * @description
 * Applies an email matching pattern to the input and throws an "emailAddress"
 * error if it does not match. Returns valid if the field is empty (this case
 * should be handled by a required validator).
 */

function scValidateEmailAddress() {
  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModelCtrl) {
      var EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      ngModelCtrl.$validators.emailAddress = function (modelVal, viewVal) {
        var val = modelVal || viewVal;
        return val ? EMAIL_VALIDATION_REGEX.test(val) : true;
      };
    }
  };
}

angular.module('classy').directive('scValidateEmailAddress', scValidateEmailAddress);
})();