(function(){
'use strict';

angular.module('classy').component('emailField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      ng-if="$ctrl.label"\n      _spec="label"\n      id="{{:: $ctrl.name }}_id">{{:: $ctrl.label }}</form-label>\n\n      <div ng-if="$ctrl.hasToolTip" style="height: 50%;"\n      data-sc-tooltip-class="admin-campaign-tooltip"\n      data-sc-tooltip-position="center"\n      data-sc-tooltip-position-tip="bottom center"\n      show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n      data-sc-tooltip="\'This field is locked at the template level\'"\n      class="admin-body__tooltip-element"\n      class="admin-body__tooltip-element locked-field-overlay"\n      ></div>\n\n      <div class="form__text-input__outer" data-ng-class="{ \'admin-body__lock-field\': $ctrl.lockField }">\n        <div ng-if="$ctrl.lockField"\n        data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n        data-sc-tooltip-position="center"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        fixed-tooltip="true"\n        data-sc-tooltip="\'This field is locked at the template level.\'"\n        class="admin-body__tooltip-element"\n        ></div>\n\n      <input class="form__text-input--dark"\n      ng-model="$ctrl.model"\n      ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n      ng-disabled="$ctrl.disabled"\n      ng-required="$ctrl.required"\n      sc-validity-reporter\n      sc-validity-classes\n      type="text"\n      name="{{:: $ctrl.name }}"\n      ng-pattern="$ctrl.emailValidationPattern"\n      placeholder="{{::$ctrl.placeholder}}"\n      autocomplete="{{::$ctrl.autocomplete}}"\n      _spec="input"\n      aria-labelledby="{{:: $ctrl.name }}_id"/>\n\n      <form-error\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    ',
  bindings: {
    model: '=',
    errorMessages: '<?',
    getterSetter: '<?',
    label: '<?',
    name: '<?',
    disabled: '<?',
    placeholder: '<?',
    required: '<?',
    autocomplete: '@?'
  },
  controller: ["$attrs", function EmailFieldCtrl($attrs) {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.',
        pattern: 'Please enter in the format: name@domain.com'
      },
      getterSetter: false,
      placeholder: 'Email',
      required: false,
      emailValidationPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    };

    if (SC.campaign && SC.campaign.campaignTemplateData) {
      var urlParams = window.location.pathname.split('/');
      var urlParamId = urlParams[urlParams.length - 2];
      var componentSetCampaign = 0;

      SC.campaign.campaignTemplateData.component_set_instances.forEach(function (item, idx) {
        if (item.campaign_id === SC.campaign.id) {
          componentSetCampaign = idx;
        }
      });

      var foundEmailId = _.filter(SC.campaign.campaignTemplateData.component_set_instances[componentSetCampaign].component_instances.message, function (data) {
        return data.id === urlParamId;
      });

      if (foundEmailId && foundEmailId[0] && foundEmailId[0].component_item_id) {
        var foundCurrentEmail = _.filter(SC.campaign.campaignTemplateData.components.message.component_items, function (data) {
          return data._id === foundEmailId[0].component_item_id.$oid;
        });

        if ($attrs.model === 'message.reply_to[0]') {
          this.lockField = _.get(foundCurrentEmail[0], 'item_attributes.reply_to.locked', false);
        }
      }

      if (SC.campaign.campaignTemplateData && $attrs.model === 'campaign.current.contact_email') {
        var isCampaignContactEmailLocked = _.get(SC.campaign.campaignTemplateData, 'components.campaign.component_items[0].item_attributes.contact_email.locked', false);
        if (isCampaignContactEmailLocked) {
          this.disabled = true;
          this.hasToolTip = true;
        }
      }
    }

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);
    };
  }]
});
})();