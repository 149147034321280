(function(){
'use strict';

scPost.$inject = ["scBrandingService", "scPagesService", "scFacebook", "scThemesService", "scFundraisingPagesService", "scFundraisingTeamsService", "scUserAgent", "truncateHtml"];
function scPost(scBrandingService, scPagesService, scFacebook, scThemesService, scFundraisingPagesService, scFundraisingTeamsService, scUserAgent, truncateHtml) {
  return {
    replace: true,
    scope: {
      post: '=scPost',
      mode: '=',
      preview: '=',
      previewBody: '@',
      previewTitle: '@',
      toPostAction: '&?',
      toListAction: '&?',
      story: '=?',
      demo: '=?',
      excerpt: '=?',
      latestPost: '=?',
      postType: '=?',
      commentsAction: '&?',
      commentsCount: '=?'
    },
    templateUrl: 'global/directives/interaction/scPost/template',
    link: function link(scope) {
      scBrandingService.getPrimaryColor().then(function (response) {
        scope.primaryColor = response;
      });

      scope.isMobile = scUserAgent.isMobile();

      scope.fundraiser = _.get(scFundraisingPagesService, 'active.current', false);
      scope.team = _.get(scFundraisingTeamsService, 'active.current', false);
      scope.theme = scThemesService.active.current;

      scope.$watch('commentsCount', function (v) {
        if (v && !_.isEmpty(scope.post)) {
          scope.post.comments_count = v;
        }
      });

      scope.getAuthorPhoto = function () {
        if (scope.fundraiser && _.get(scope.fundraiser, 'logo.asset', false)) {
          return scope.fundraiser.logo.asset.src;
        }

        var img = '';
        if (scope.team) {
          img = scFundraisingTeamsService.getTeamPhoto();
        } else {
          img = '/static/global/images/individual_default_image.png';
        }
        return img;
      };

      var postBody = scope.post.body;

      if (scope.excerpt && scope.mode == 'list') {
        scope.postBody = truncateHtml(postBody, { limit: 300 }).html;
      } else {
        scope.postBody = postBody;
      }

      scope.share = function () {
        var pageUrl = scPagesService.getShareableUrl('post');
        scFacebook.share(pageUrl);
      };
    }
  };
}

angular.module('classy').directive('scPost', scPost);
})();