(function(){
'use strict';

angular.module('classy').component('scConsent', {
  template: '\n    <div\n      data-ng-if="$ctrl.show"\n      data-ng-hide="$ctrl.hide"\n      class="sc-consent"\n      >\n        <span class="sc-checkbox_top"\n          data-sc-checkbox\n          data-ng-model="$ctrl.optIn"\n          aria-label="{{ $ctrl.language }}"\n          name="opt_in">\n          {{$ctrl.language}}\n        </span>\n    </div>\n  ',

  bindings: {
    show: '<',
    hide: '<',
    language: '=',
    optIn: '='
  },
  controller: function controller() {
    this.$postLink = function () {
      this.language = this.language || "It's okay to contact me in the future.";
    };

    this.$onChanges = function (changes) {
      var _changes$hide = changes.hide;
      _changes$hide = _changes$hide === undefined ? {} : _changes$hide;
      var isHidden = _changes$hide.currentValue;

      // if the checkbox is hidden we do not want the user to be opted-in without realizing it
      // so we uncheck th box

      if (isHidden) {
        this.optIn = false;
      }
    };
  }
});
})();