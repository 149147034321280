(function(){
'use strict';

/* Custom Questions */
angular.module('classy').component('egCustomQuestions', {
  templateUrl: 'global/embedded-giving/components/custom-questions/eg.components.custom-questions', // same folder as this
  bindings: {
    parentForm: '<'
  },
  controller: ["scOrganizationsService", "scCampaignsService", "scFroalaOptions", "CHAR_LIMITS", function controller(scOrganizationsService, scCampaignsService, scFroalaOptions, CHAR_LIMITS) {
    var _this = this;

    this.organization = scOrganizationsService.active;
    this.campaign = scCampaignsService.active.current;
    this.currency = this.organization.currency();

    // fn used to sort questions by weight asc
    var byWeightAscSortCompareFn = function byWeightAscSortCompareFn(q1, q2) {
      if (q1.weight === q2.weight) {
        return 0;
      }

      return q1.weight > q2.weight ? 1 : -1;
    };

    var questions = this.campaign.questions;
    var oQuestions = [];
    var cQuestions = [];
    if (!_.isEmpty(questions)) {
      if (questions.optional && questions.optional.length) {
        oQuestions = questions.optional.filter(function (oQuestion) {
          return oQuestion.tag !== 'homephone';
        });
        oQuestions.sort(byWeightAscSortCompareFn);
      }
      if (questions.custom && questions.custom.length) {
        cQuestions = questions.custom;
        cQuestions.sort(byWeightAscSortCompareFn);
      }
    }

    this.optionalQuestions = oQuestions;
    this.customQuestions = cQuestions;

    this.$onInit = function () {
      _this.parentForm = _this.parentForm || {};

      _this.froalaCustomQuestionsOptions = scFroalaOptions({ type: 'textarea-counter' }, {
        charCounterCount: true,
        charCounterMax: CHAR_LIMITS.DEFAULT_PAGE_CUSTOM_QUESTION
      });

      _this.isInit = true;
    };

    // TODO @Leo update these partials to follow EG conventions: `eg.components.custom-questions.optional.${fieldType}`
    this.optionalFieldPartial = function (fieldType) {
      return 'global/embedded-giving/components/custom-questions/fields/optional/' + fieldType;
    };

    // TODO @Leo update these partials to follow EG conventions: `eg.components.custom-questions.custom.${fieldType}`
    this.customFieldPartial = function (fieldType) {
      return 'global/embedded-giving/components/custom-questions/fields/custom/' + fieldType;
    };

    this.ERROR_MESSAGES = {
      ANONYMOUS_DONATION_TOOLTIP_TEXT: 'We will only use your name to send you a receipt with tax information.',
      ANONYMOUS_DONATION_CHECKBOX_LABEL: 'Hide my name from the public.',
      ANONYMOUS_DONATION_AMOUNTS_LABEL: 'Hide my donation amount from the public.',
      BLOG_ADDRESS_REQUIRED_ERROR_TEXT: 'Please enter a blog address.',
      CELL_NUMBER_REQUIRED_ERROR_TEXT: 'Please enter your cell number.',
      CHARACTER_LIMIT_ERROR_TEXT: 'Please enter 1000 characters or less.',
      COMPANY_REQUIRED_ERROR_TEXT: 'Please enter a company.',
      DOLLAR_AMOUNT_VALID_ERROR_TEXT: 'Please enter a valid dollar amount.',
      DATE_VALID_ERROR_TEXT: 'Please enter a valid date (MM/DD/YYYY)',
      EMAIL_ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget your email address.',
      EMAIL_ADDRESS_FORMAT_ERROR_TEXT: 'Please enter in the format: name@domain.com.',
      FIELD_REQUIRED_ERROR_TEXT: 'This field is required.',
      GENDER_REQUIRED_ERROR_TEXT: 'Please select a gender.',
      PRONOUN_REQUIRED_ERROR_TEXT: 'Please select a pronoun.',
      NUMBERS_ONLY_ERROR_TEXT: 'Please enter numbers only.',
      PHONE_NUMBER_VALID_ERROR_TEXT: 'Please enter a valid phone number.',
      PHONE_NUMBER_REQUIRED_ERROR_TEXT: 'Don\'t forget your phone number.',
      TITLE_REQUIRED_ERROR_TEXT: 'Please select a title.',
      SUFFIX_REQUIRED_ERROR_TEXT: 'Please select a suffix.',
      WEBSITE_REQUIRED_ERROR_TEXT: 'Please enter a website.',
      CHARACTER_LIMIT_255_MESSAGE: 'Please limit your answer to 255 or less characters.'
    };
  }]
});
})();