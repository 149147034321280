(function(){
'use strict';

angular.module('classy').service('scScriptLoaderService', ["$document", "$q", function ($document, $q) {
  var loadedScripts = {};

  this.loadScript = function (src) {
    var deferred = $q.defer();

    if (loadedScripts[src]) {
      // If the script is already loaded, resolve the promise immediately
      deferred.resolve();
    } else {
      var script = $document[0].createElement('script');
      script.src = src;
      script.async = true;

      script.onload = function () {
        loadedScripts[src] = true;
        deferred.resolve();
      };

      script.onerror = function () {
        deferred.reject('Failed to load script: ' + src);
      };

      $document[0].body.appendChild(script);
    }

    return deferred.promise;
  };
}]);
})();