(function(){
'use strict';

/**
 * @name modelParams
 * @kind service
 *
 * @description
 * Ensures that the correct model is active in any given state. Listens for
 * $stateChangeStart, captures the destination state, defers the state change,
 * compares existing models to state params, and if/when everything is synced,
 * continues with the state change.
 */

modelParams.$inject = ["$log", "$q", "$state", "$window", "scFundraisingPagesService", "scFundraisingTeamsService", "scCampaignGroupService", "scOrganizationsService", "scPageAccessService", "scMembersService"];
function modelParams($log, $q, $state, $window, scFundraisingPagesService, scFundraisingTeamsService, scCampaignGroupService, scOrganizationsService, scPageAccessService, scMembersService) {
  $window.DEBUG_SYNC = false;

  this.currentTransition = 0;

  this.isLoad = true;

  /* ---------------------------------------------------------------------- *
   * Utils
   * ---------------------------------------------------------------------- */

  function maybeLog(content) {
    if ($window.DEBUG_SYNC) {
      $log.log(content);
    }
  }

  function maybeLogSuccess(content) {
    if ($window.DEBUG_SYNC) {
      $log.log('%c\u2713 ' + content, 'color: green');
    }
  }

  function maybeWarn(content) {
    if ($window.DEBUG_SYNC) {
      $log.warn(content);
    }
  }

  function getKnownId(toParams) {
    var keys = ['fundraisingPageId', 'fundraisingTeamId', 'campaignId', 'organizationId'];

    for (var i = 0; i < keys.length; i += 1) {
      var key = keys[i];
      if (toParams[key]) {
        return {
          type: key,
          id: toParams[key]
        };
      }
    }

    return false;
  }

  function transitionSequence(_ref, isLoad) {
    var id = _ref.id,
        type = _ref.type;

    var ids = {
      fundraisingPageId: $q.defer(),
      fundraisingTeamId: $q.defer(),
      campaignId: $q.defer(),
      organizationId: $q.defer()
    };

    ids[type].resolve(id);

    var fundraisingPagePromise = ids.fundraisingPageId.promise.then(function (pageId) {
      maybeLog('Syncing fundraising page ...');
      return scFundraisingPagesService.sync(pageId, isLoad, {
        withAnswers: true,
        withDesignation: true,
        withCommitment: true
      });
    }).then(function (data) {
      maybeLogSuccess('Fundraising page is synced');
      ids.fundraisingTeamId.resolve(data.fundraising_team_id);
      ids.campaignId.resolve(data.campaign_id);
      ids.organizationId.resolve(data.organization_id);
    }).catch(function () {
      return maybeLog('No fundraising page to sync.');
    });

    var fundraisingTeamPromise = ids.fundraisingTeamId.promise.then(function (teamId) {
      return teamId || $q.reject();
    }).then(function (teamId) {
      maybeLog('Syncing fundraising team ...');
      return scFundraisingTeamsService.sync(teamId, isLoad, { withAnswers: true });
    }).then(function (data) {
      maybeLogSuccess('Fundraising team is synced');
      ids.campaignId.resolve(data.campaign_id);
      ids.organizationId.resolve(data.organization_id);
    }).catch(function () {
      return maybeLog('No fundraising team to sync.');
    });

    var campaignPromise = ids.campaignId.promise.then(function (campaignId) {
      maybeLog('Syncing campaign ...');
      return scCampaignGroupService.sync(campaignId, isLoad);
    }).then(function (data) {
      maybeLogSuccess('Campaign is synced');
      ids.organizationId.resolve(data.organization_id);
    }).catch(function () {
      return maybeLog('No campaign to sync.');
    });

    var organizationPromise = ids.organizationId.promise.then(function (orgId) {
      maybeLog('Syncing organization ...');
      return scOrganizationsService.sync(orgId, isLoad);
    }).then(function () {
      maybeLogSuccess('Organization is synced');
      _.forEach(ids, function (promise) {
        return promise.reject();
      });
    });

    maybeLog('Syncing member ...');
    var memberPromise = scMembersService.sync(isLoad).then(function () {
      return maybeLogSuccess('Member is synced');
    });

    maybeLog('Syncing campaign access ...');
    var campaignAccessPromise = $q.all([campaignPromise, memberPromise]).then(function () {
      scPageAccessService.sync(isLoad).then(function () {
        return maybeLogSuccess('Campaign access status is synced');
      });
    });

    return $q.all([fundraisingPagePromise, fundraisingTeamPromise, campaignPromise, organizationPromise, memberPromise, campaignAccessPromise]);
  }

  this.sync = function (e, toState, toParams) {
    var _this = this;

    this.currentTransition = _.uniqueId();

    var localTransition = this.currentTransition;

    if (toState.name === this.cachedState && _.isEqual(toParams, this.cachedParams)) {
      return;
    }

    var known = getKnownId(toParams);

    // If there is no valid parameter, we might be in a subapp like Donation,
    // where fundraiser/team/campaign info is populated by Express and UI
    // Router is unaware of it.

    if (!known && this.isLoad) {
      if (SC.fundraiser) {
        known = { type: 'fundraisingPageId', id: SC.fundraiser.id };
      } else if (SC.team) {
        known = { type: 'fundraisingTeamId', id: SC.team.id };
      } else if (SC.campaign) {
        known = { type: 'campaignId', id: SC.campaign.id };
      } else if (SC.organization) {
        known = { type: 'organizationId', id: SC.organization.id };
      }
    }

    // If there's still nothing, something strange indeed has occurred.

    if (!known) {
      maybeWarn('No sync parameter found in path or SC. Where are you?');
      return;
    }

    maybeLog('------------------------------------------');
    maybeLog('STATE: ' + toState.name);
    maybeLog('PARAMS: ');
    maybeLog(toParams);

    this.cachedState = toState.name;
    this.cachedParams = toParams;

    if (e) {
      e.preventDefault();
    }

    maybeLog('Known:');
    maybeLog(known);

    transitionSequence(known, this.isLoad).then(function () {
      if (_this.currentTransition === localTransition) {
        $state.go(toState.name, toParams);
      } else {
        maybeWarn('Transition no longer matches');
      }
    }).catch(maybeWarn);

    this.isLoad = false;
  };
}

angular.module('classy').service('modelParams', modelParams);
})();