(function(){
'use strict';

angular.module('classy').constant('scResponsive', [{
  name: 'mobile-ls',
  width: 568
}, {
  name: 'tablet',
  width: 768
}, {
  name: 'tablet-ls',
  width: 1024
}]);
})();