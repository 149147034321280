(function(){
'use strict';

scButtonAmount.$inject = ["RGB"];
function scButtonAmount(RGB) {
  return {
    scope: {
      amountPresets: '=scButtonAmount',
      buttonSettings: '='
    },
    templateUrl: 'global/directives/scButtonAmount/index',
    link: function link(scope) {
      scope.buttonStyles = function () {
        return {
          'background-color': SC.campaign.theme.primaryColor
        };
      };

      scope.dropdownStyles = function () {
        return {
          'background-color': new RGB(SC.campaign.theme.primaryColor).darkerColor(0.2)
        };
      };

      scope.selectedPreset = scope.amountPresets[0];
    }
  };
}

angular.module('classy').directive('scButtonAmount', scButtonAmount);
})();