(function(){
'use strict';

/**
 * @name scFlowModal
 * @kind directive
 * @package FlowModal
 *
 * @guide ./FlowModal.guide.md
 *
 * @description
 * Directive that renders the flowModal template and passes element references
 * to scFlowModalElements. This directive should only be used once per app,
 * ideally outside of any ui-view containers.
 */

scFlowModal.$inject = ["scFlowModalElements", "scUserAgent", "scFlowModalService"];
function scFlowModal(scFlowModalElements, scUserAgent, scFlowModalService) {
  return {
    scope: {},
    replace: true,
    templateUrl: 'global/directives/scFlowModal/template',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element) {
      scFlowModalElements.$overlay = element.find('.sc-flow-modal_overlay');
      scFlowModalElements.$viewport = element.find('.sc-flow-modal_viewport');
      scFlowModalElements.$views = element.find('.sc-flow-modal_views');
      scFlowModalElements.$close = element.find('.sc-flow-modal_close');
      scFlowModalElements.$staging = element.find('.sc-flow-modal_staging');

      // FRS-7931: iOS 11+ displaces input fields on many modals;
      // one way to fix it is to force position: absolute instead of fixed
      if (scUserAgent.isIOS11()) {
        element.find('.sc-flow-modal_overlay').css({ position: 'absolute', 'min-height': '100vh' });

        // the abosulute positioning will clip the modal off the page if the user
        // is scrolled down any amount, so we force them to the top.
        scFlowModalService.onOpen(function () {
          return window.scrollTo(0, 0);
        });
      }
    }
  };
}

angular.module('classy').directive('scFlowModal', scFlowModal);
})();