(function(){
'use strict';

/**
 * @kind directive
 * @name scColorpickerMiniField
 *
 * @description
 * Displays a small colorpicker to be used within a field label.
 *
 * @param {string} label Field label
 * @param {expression} model Scope property containing selected color
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {expression} fallback Fallback color for the picker
 * @param {number} swatchSize Size of the swatch in pixels; default is 25
 */

scColorpickerMiniField.$inject = ["scField"];
function scColorpickerMiniField(scField) {
  return scField('scColorpickerMiniField');
}

angular.module('classy').directive('scColorpickerMiniField', scColorpickerMiniField);
})();