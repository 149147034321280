(function(){
'use strict';

angular.module('classy').component('donationGrid', {
  templateUrl: 'global/components/fields/donationGrid/donationGrid',
  bindings: {
    donationContext: '=',
    frequencyLevels: '=',
    settingPageFrequency: '<',
    frequency: '=',
    recurUntil: '=',
    levels: '=',
    recurringDonationLevels: '=',
    amount: '=',
    previousFrequencyPrice: '=',
    newAmount: '<',
    currency: '=',
    preview: '<',
    globalState: '=',
    iaa: '=',
    iaaState: '='
  },
  require: {
    form: '^form'
  },
  controller: ["$state", "$scope", "$timeout", "accessibilityService", "cpIntelligentAsk", "EmbeddedGivingColors", "scAnalytics", "scBlocksService", "scCampaignsService", "scCurrencyService", "scThemesService", function controller($state, $scope, $timeout, accessibilityService, cpIntelligentAsk, EmbeddedGivingColors, scAnalytics, scBlocksService, scCampaignsService, scCurrencyService, scThemesService) {
    var _this = this;

    var self = this;
    var COLOR_BRAND_DARKEST = 'rgba(0,0,0,0.9)';

    function syncIAA() {
      if (self.preview) {
        self.amount = 0;
        self.otherAmount = 0;
        return;
      }

      var campaign = scCampaignsService.active;

      $timeout(function () {
        if (campaign.hasIntelligentAsk() && cpIntelligentAsk.isInit && cpIntelligentAsk.hasAccessAmounts()) {
          // Custom amount input field
          // Sometimes we get values back even though it's not enabled on the campaign
          var frequency = self.isOneTime() ? 'oneTime' : 'recurring';
          var isFrequencyEnabled = self.isOneTime() ? campaign.hasIntelligentAskOneTime() : campaign.hasIntelligentAskRecurring();

          if (isFrequencyEnabled && // If the active frequency is IAA enabled
          self.iaaState[frequency] // and if there is a default IAA amount for the frequency
          ) {
              // Update the other amount field and select it
              self.showCustomAmtSelected = true;
              self.otherAmount = self.iaaState[frequency];
              self.amount = self.iaaState[frequency];
            }
        }
      });
    }
    // Call the syncIAA once the amounts are updated
    $scope.$on('cpIntelligentAsk:amountsUpdated', syncIAA);

    /* If we pass through the amount params, make sure the correct button is selected, and if the
     * preset amount is not in the preset donation levels, make sure the 'Custom' button is selected
     * TODO: this logic can be reduced and simplified by ~75%, make task
     */
    function initAmounts() {
      var presetAmt = _.get($state, 'params.amount', false);

      // If query paramter is at the wrong place in the URL, then it is not populated in `params.amount`
      // For example, /give/{orgId}?amount=26#!/donation/checkout
      if (!presetAmt && self.amount !== 0) {
        presetAmt = self.amount;
      }

      self.showCustomAmtSelected = self.amount === 'Custom';
      // If custom is selected as default
      if (self.showCustomAmtSelected) {
        self.amount = '';
      }

      // If custom is selected as default
      if (self.previousFrequencyPrice === 'Custom') {
        self.previousFrequencyPrice = '';
      }

      self.showRecurringDonationArray = self.frequency !== 'one-time';

      var donationLevels = self.showRecurringDonationArray ? self.recurringDonationLevels : self.levels;

      var filteredLevels = _.filter(donationLevels, function (level) {
        return level.display_on_page === true;
      });

      if (presetAmt && !filteredLevels.some(function (i) {
        return i.amount == presetAmt;
      })) {
        if (filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        })) {
          self.showCustomAmtSelected = true;
          self.otherAmount = isNaN(presetAmt) ? 0 : presetAmt;
        } else if (!filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        }) && filteredLevels.length && filteredLevels.length !== 1) {
          self.amount = self.showRecurringDonationArray ? self.DonationBlock.current['recurring-donation-default'] || 0 : self.DonationBlock.current['donation-default'] || 0;
        } else {
          self.amount = isNaN(presetAmt) ? 0 : presetAmt;
        }
      }
    }

    function initStyles() {
      self.theme = scThemesService.active.current;
      EmbeddedGivingColors.init(scThemesService.active);

      self.colorPrimary = self.theme.styles.primaryColor;
      self.colorAccent = getRGBValue(self.theme.styles.primaryColor);

      // parse the primary color for its RGB value -- we'll be customizing the opacity
      self.ctaStyle = {
        'background-color': self.colorAccent
      };

      self.toggleHover = function (radioButtonValue, isHover) {
        if (isHover) {
          angular.element('.hover-state_' + radioButtonValue).addClass('hover');
        } else {
          angular.element('.hover-state_' + radioButtonValue).removeClass('hover');
        }
      };
    }

    this.showRecurringEndDate = scCampaignsService.active.current.hide_recurring_end_date;
    this.recurringFrequencyArray = ['monthly', 'yearly', 'quarterly', 'semi-annually', 'daily', 'weekly', 'bi-weekly'];
    // Object with recurring preset & respective frequency text value
    this.recurringLevelsWithPreset = {
      0: 'one-time',
      1: 'monthly',
      2: 'quarterly',
      3: 'semi-annually',
      4: 'yearly',
      5: 'daily',
      6: 'weekly',
      7: 'bi-weekly'
    };

    this.isOneTime = function () {
      return _this.frequency === 'one-time';
    };

    this.$onInit = function () {
      _this.DonationBlock = scBlocksService.getBlockByType('donation');
      var recurringValue = parseInt(_.get($state, 'params.recurring', false), 10);

      _this.defaultFrequency = !isNaN(recurringValue) && _this.recurringLevelsWithPreset[recurringValue] ? _this.recurringLevelsWithPreset[recurringValue] : _this.DonationBlock.current['recurring-default'];

      if (!scCampaignsService.active.multiCurrencyDisabled()) {
        if (window.newrelic) {
          window.newrelic.log('init_locale_currency', {
            level: 'trace',
            customAttributes: {
              locale_currency: scCurrencyService.localeCurrency,
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            }
          });
        }
        _this.currency = scCurrencyService.localeCurrency;
      }

      _this.today = new Date();
      _this.today.setDate(_this.today.getDate() + 1);
      _this.visible = false;
      _this.show = {
        // on scCurrencyPicker focus show dark outline
        inputFocus: false
      };

      initAmounts();
      initStyles();
      _this.analyticsInitialized = true;
    };

    this.showFrequencyForDeque = function (frequency) {
      return !['daily', 'weekly', 'bi-weekly'].includes(frequency);
    };

    /** Show frequency dropdown in preview mode (campaign setting page) */
    this.showDropdownOnPreview = function (data) {
      _this.frequency = data;
      _this.isFreqDropdown = data !== 'one-time' && _this.recurringLevels(_this.frequencyLevels).length > 1;
      _this.showRecurringDonationArray = _this.frequency !== 'one-time';
    };

    /** On change of toggle Show frequency dropdown in preview mode (campaign setting page) */
    /** __________________________________________________________ */

    this.$onChanges = function (options) {
      if (options.newAmount && !!options.newAmount.currentValue) {
        _this.value = options.newAmount.currentValue;
      }

      if (options.settingPageFrequency) {
        _this.freqValue = options.settingPageFrequency.currentValue;
        if (_this.analyticsInitialized) {
          scAnalytics.eventBeacon({
            category: 'frs ' + campaignType,
            action: 'click',
            label: 'Donation Frequency \u2013 ' + options.settingPageFrequency.currentValue + ' (non-text link)'
          });
        }
      }

      _this.isDonationPage = $state.params.step === 'donation';

      // Since there is 2 preview mode: one is in campaign setting donation page and second one is in donation checkout page
      // following condition is made for campaign setting donation preview mode.
      if (_this.preview && _this.isDonationPage) {
        if (_this.freqValue) {
          _this.showDropdownOnPreview(_this.freqValue);
        }
      }

      if (options.iaaState && !options.iaaState.isFirstChange()) {
        var oldValue = options.iaaState.previousValue;
        var newValue = options.iaaState.currentValue;

        // Trigger logic when iaaState changes
        if (oldValue !== newValue) {
          syncIAA();
        }
      }
    };

    this.recurringLevels = function (levels) {
      return levels.filter(function (level) {
        return level.type !== 'one-time' && level.display_on_page;
      });
    };

    this.onlyReccurringFrequency = function (levels) {
      return !levels.some(function (level) {
        return level.type === 'one-time' && level.display_on_page;
      });
    };

    this.recurringBtnText = function () {
      return _this.recurringLevels(_this.frequencyLevels).length > 1 ? 'Recurring' : _this.recurringLevels(_this.frequencyLevels)[0].type;
    };

    this.recurringBtnSelected = function (frequency) {
      return _this.recurringFrequencyArray.includes(frequency);
    };

    this.showFreqDropdown = function (freqType) {
      var levels = _this.frequencyLevels;
      if (freqType === 'one-time') {
        _this.isFreqDropdown = false;
        _this.frequency = 'one-time';
        _this.recurUntil = null;
      } else if (_this.recurringLevels(levels).length <= 1) {
        _this.isFreqDropdown = false;
        _this.frequency = freqType ? _this.recurringLevels(levels)[0].type : _this.frequency;
      } else {
        _this.isFreqDropdown = !!(freqType || _this.recurringBtnSelected(_this.frequency));

        if (_this.defaultFrequency === 'one-time' && freqType) {
          _this.frequency = _this.recurringLevels(levels)[0].type;
        } else {
          _this.frequency = _this.defaultFrequency;
        }
      }
    };
    /** ______________________________________________________ */

    this.isRecurringFrequency = function (event) {
      if (event.currentTarget && event.currentTarget.classList.contains('selected')) return;

      _this.showRecurringDonationArray = _this.frequency !== 'one-time';

      var donationLevels = _this.showRecurringDonationArray ? _this.recurringDonationLevels : _this.levels;

      var filteredLevels = _.filter(donationLevels, function (level) {
        return level.display_on_page === true;
      });

      // toggle internal raw_final_price & previous_frequency_price
      var temp = _this.previousFrequencyPrice;
      _this.previousFrequencyPrice = _this.amount;
      _this.amount = temp === undefined ? '' : temp;

      // while toggle check if amount not present in array
      // also check wether other input present or not
      // and assign value accordingly
      if (!filteredLevels.some(function (i) {
        return i.displayAmount == _this.amount;
      })) {
        if (filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        })) {
          if (_this.amount === 'preset' || _this.amount === 'clear') {
            _this.showCustomAmtSelected = false;
            _this.otherAmount = '';
          } else if (_this.amount === 0) {
            _this.showCustomAmtSelected = false;
            _this.otherAmount = _this.showCustomAmtSelected ? _this.amount || '' : _this.otherAmount;
          } else {
            _this.showCustomAmtSelected = true;
            _this.otherAmount = _this.showCustomAmtSelected ? _this.amount || '' : _this.otherAmount;
          }
        } else {
          _this.showCustomAmtSelected = false;
          _this.otherAmount = '';
        }
      } else {
        _this.showCustomAmtSelected = false;
        _this.otherAmount = '';
      }
    };

    this.noOfFrequencyLevel = function (frequencyLevels) {
      return frequencyLevels.filter(function (level) {
        return level.display_on_page;
      }).length;
    };

    this.noOfDonationAmount = function (donationAmounts) {
      return donationAmounts.filter(function (item) {
        return item.display_on_page;
      }).length;
    };

    this.showCustomAmtField = function (levels) {
      var displayed = levels.some(function (cLevel) {
        return cLevel.display_on_page && cLevel.amount === 'Custom';
      });

      if (!displayed) {
        _this.showCustomAmtSelected = false;
      }
      return displayed;
    };

    function getRGBValue(color) {
      var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0.12';

      // make sure we're matching an "rgb..." or "rgba..." string,
      // and assume that whitespaces can happen anywhere.
      // capture the three groups of color values and ignore the opacity value if an RGBA value
      // is fed into this function.
      var rgbaRegex = /(?!rgba?)\s*(\d{1,3}\s*,\s*)(\d{1,3}\s*,\s*)(\d{1,3}\s*\s*)/g;
      var ogColor = color.match(rgbaRegex);

      // CL-31278, some campaigns use named color strings instead of an rgb string, leading to unhandled exceptions
      if (!ogColor) {
        switch (color) {
          case 'red':
            ogColor = 'rgb(255, 0, 0)';
            break;
          case 'green':
            ogColor = 'rgb(0, 255, 0)';
            break;
          case 'blue':
            ogColor = 'rgb(0, 0, 255)';
            break;
          default:
            // might cause things to fail later, but current behavior is to throw a nullpointer exception in this case...
            // eslint-disable-next-line no-console
            console.warn('received color: ' + color + ', but expected RGB string');
            return color;
        }
      }

      // use 0.12 opacity on the client's primary color and stringify
      var newColor = ogColor.concat(' ' + opacity + ')').join();
      return 'rgba('.concat(newColor);
    }

    this.getAriaLabel = function (frequency) {
      var ariaLabel = void 0;
      switch (frequency) {
        case 'one-time':
        case 'monthly':
        case 'yearly':
        case 'weekly':
        case 'daily':
          ariaLabel = 'Make a ' + frequency + ' gift';
          break;
        case 'quarterly':
          ariaLabel = 'Make a gift every three months';
          break;
        case 'semi-annually':
          ariaLabel = 'Make a gift every six months';
          break;
        case 'bi-weekly':
          ariaLabel = 'Make a gift every two weeks';
          break;
        default:
          break;
      }
      return ariaLabel;
    };

    // If other than custom amount is selected as default
    this.donationLevelStyle = function (displayAmt, index) {
      var isHover = angular.element('.hover-state_' + index).hasClass('hover');
      var isSelected = !_this.showCustomAmtSelected && _this.amount === displayAmt;

      var styles = {
        color: isSelected ? '#fff' : 'rgba(0, 0, 0, 0.87)',
        'background-color': isSelected ? _this.theme.styles.primaryColor : getRGBValue(_this.theme.styles.primaryColor),
        border: 'none',
        'max-width': '50%',
        'text-shadow': isSelected ? '0px 2px 2px rgba(0,0,0,0.12)' : 'none'
      };

      var boxShadow = _this.getHoverStles(isSelected, isHover);

      styles['box-shadow'] = boxShadow;

      return styles;
    };

    this.getStylesFrequencyOneTimeBtn = function (setting) {
      var isSelected = _this.frequency == setting;
      var isHover = angular.element('.hover-state_' + setting).hasClass('hover');

      return _this.getFrequencyBtnStyles(isSelected, isHover);
    };

    this.getStylesFrequencyRecurringBtn = function (setting) {
      var isActive = _this.recurringFrequencyArray.includes(setting);
      var isHover = angular.element('.hover-state_recurring').hasClass('hover');

      return _this.getFrequencyBtnStyles(isActive, isHover);
    };

    this.getFrequencyBtnStyles = function (isSelected, isHover) {
      var styles = {
        transition: 'all 0.2s ease-in-out',
        border: '2px solid ' + _this.colorAccentOpaque,
        color: isSelected ? '#fff' : COLOR_BRAND_DARKEST,
        'background-color': isSelected ? _this.colorPrimary : _this.colorAccentOpaque,
        'border-radius': '24px'
      };

      var boxShadow = _this.getHoverStles(isSelected, isHover);

      styles['box-shadow'] = boxShadow;

      return styles;
    };

    this.getHoverStles = function (isSelected, isHover) {
      var boxShadow = 'inherit';

      if (isHover) {
        boxShadow = 'inset 0px 0px 0px 1px white, 0px 0px 0px 2px ' + getRGBValue(self.theme.styles.primaryColor, '0.4');
      }

      if (isSelected && isHover) {
        boxShadow += ', inset 0px 0px 0px 1px rgba(0,0,0,0.2), inset 1px 2px 12px rgba(0,0,0,0.2), inset 1px 2px 2px rgba(0,0,0,0.2)';
      } else if (isSelected && !isHover) {
        boxShadow = 'inset 0px 0px 0px 1px rgba(0,0,0,0.2), inset 1px 2px 12px rgba(0,0,0,0.2), inset 1px 2px 2px rgba(0,0,0,0.2)';
      }

      return boxShadow;
    };

    this.getOtherFieldStyles = function (fieldName) {
      var isHover = angular.element('.hover-state_' + fieldName).hasClass('hover');
      if (isHover) {
        return {
          'box-shadow': _this.getHoverStles(false, isHover)
        };
      } else {
        return {};
      }
    };

    var campaignType = scCampaignsService.active.current.type;

    this.selectFrequency = function (frequency, keyboardEvent, element) {
      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;
      /** NEW RF CHANGES: On tab and enter key press select the toggle frequency */
      if (keyboardEvent && (keyboardEvent.keyCode === 13 || keyboardEvent.keyCode === 32)) {
        if (element == 'selectDropdown') {
          keyboardEvent.preventDefault();
        } else if (frequency === 'one-time') {
          _this.frequency = frequency;
          _this.isFreqDropdown = false;
        } else {
          _this.frequency = _this.defaultFrequency === 'one-time' ? _this.recurringLevels(_this.frequencyLevels)[0].type : _this.defaultFrequency;
          _this.isFreqDropdown = frequency !== 'one-time' && _this.recurringLevels(_this.frequencyLevels).length > 1;
        }
      }

      syncIAA();
    };

    this.canShowLevel = function (level) {
      if (_this.preview) {
        return true;
      }

      if (_.isEmpty(level)) {
        return false;
      }

      if (level.loading) {
        return false;
      }

      // Determine if the campaign has IAA
      // - If IAA exists and is enabled for the campaign:
      //   - If `isInit` is false, return `false` (not initialized).
      //   - If `isFetching` is true, return `false` (still fetching).
      //   - If `triedToInit` is false, return `false` (initialization not attempted).
      if (_this.iaa && cpIntelligentAsk.isEnabled(SC.campaign)) {
        if (!_this.iaa.isInit) {
          return false;
        }

        if (_this.iaa.isFetching) {
          return false;
        }

        if (!_this.iaa.triedToInit) {
          return false;
        }
      }

      return true;
    };

    this.setPreventDefault = function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    this.selectAmount = function (amount, keyboardEvent) {
      var selectedDonationLevels = _this.frequency !== 'one-time' ? _this.recurringDonationLevels : _this.levels;

      _this.isOtherInputPresent = selectedDonationLevels.some(function (level) {
        return level.amount === 'Custom' && level.display_on_page;
      });

      if (_this.isOtherInputPresent) {
        if (amount !== 'Custom') {
          _this.otherAmount = '';

          $timeout(function () {
            _this.form.amount.$setValidity('required', true);
            _this.form.amount.$setValidity('min', true);
            _this.form.amount.$setValidity('max', true);
          });
        }
      }

      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;
      _this.showCustomAmtSelected = amount === 'Custom';

      if (!_this.showCustomAmtSelected) {
        _this.amount = amount;
      } else {
        _this.amount = _this.otherAmount || 0;
      }
    };

    $scope.$on('updateFrequencyInPreview', function (_evt, data) {
      _this.showDropdownOnPreview(data);
    });
  }]
});
})();