(function(){
'use strict';

scTargetSelf.$inject = ["$sce"];
function scTargetSelf($sce) {
  return function (input) {
    var html = $sce.getTrustedHtml(input);
    var dom = void 0;
    try {
      dom = angular.element(html);
    } catch (err) {
      return '';
    }
    var wrapped = dom.wrapAll('<div>').parent();
    var links = wrapped.find('a');
    _.forEach(links, function (link) {
      var $link = angular.element(link);
      if ($link.attr('target')) return;
      $link.attr('target', '_self');
    });
    // we need to re-trust this innerHtml because it is being read out of a DOM
    // element and thus is no longer the safe string we trusted before this filter.
    return wrapped[0] ? $sce.trustAsHtml(wrapped[0].innerHTML) : '';
  };
}

angular.module('classy').filter('scTargetSelf', scTargetSelf);
})();