(function(){
'use strict';

angular.module('classy').value('whitelistedProperties', {
  DONATION_WHITELIST: ['campaign_id', 'charged_total_gross_amount', 'charged_currency_code', 'charged_fees_amount', 'charged_classy_fees_amount', 'charged_pp_fees_amount', 'company_name', 'created_at', 'designation_id', 'designation_name', 'designation_external_reference_id', 'donation_gross_amount', 'donation_net_amount', 'email', 'fee_on_top', 'first_name', 'fundraising_page_id', 'fundraising_team_id', 'has_dedication', 'id', 'institution', 'is_anonymous', 'is_gift_aid', 'last_name', 'member_id', 'organization_id', 'overhead_net_amount', 'promo_code_code', 'purchased_at', 'raw_donation_gross_amount', 'raw_currency_code', 'raw_overhead_net_amount', 'raw_total_gross_amount', 'total_gross_amount', 'recurring_donation_plan_id', 'refunded_at', 'status', 'tax_entity_id', 'payment_source'],

  FUNDRAISER_WHITELIST: ['alias', 'campaign_id', 'created_at', 'normalized_currency_code', 'designation_id', 'designation_name', 'fundraising_team_id', 'normalized_goal', 'id', 'member_id', 'organization_id', 'raw_currency_code', 'raw_goal', 'currency_code', 'goal', 'status', 'team_role', 'title'],

  TEAM_WHITELIST: ['average_donation', 'normalized_currency_code', 'normalized_goal', 'id', 'name', 'goal', 'currency_code', 'raw_goal', 'raw_currency_code', 'normalized_currency_code', 'normalized_goal', 'team_lead_id', 'total_donations', 'total_donors', 'total_fundraisers', 'total_raised', 'percent_to_goal'],

  CART_WHITELIST: ['campaign_id', 'charged_currency_code', 'donation_included', 'donation_fee_on_top', 'donation_frequency', 'donation_total', 'id', 'member_id', 'raw_currency_code', 'registration_fee_on_top', 'registration_fees', 'registration_total', 'total_fees', 'transaction_total', 'items'],

  CART_ITEM_WHITELIST: ['adjustment_amount', 'cart_id', 'entries', 'id', 'overhead_amount', 'price', 'product_id', 'promo_code_id', 'registrants'],

  CART_REGISTRANT_WHITELIST: ['cart_item_id', 'donation_share', 'email', 'first_name', 'id', 'last_name', 'registration_id'],

  // Zip codes that are restricted due to having a population of 20,000 or fewer people
  ZIPCODE_BLACKLIST: ['036', '692', '878', '059', '790', '879', '063', '821', '884', '102', '823', '890', '830', '893', '556', '831']
});
})();