(function(){
'use strict';

angular.module('classy').constant('scFeaturedBlockDefaults', {
  title: 'Featured',
  type: 'featured',
  disable: false,
  headline: 'Featured',
  hideHeadline: false,
  subheading: "Use a second line of text to describe what's featured",
  hideSubheading: false,
  headlineColor: 'rgb(0,0,0)',

  features: [{
    name: 'Register as Individual',
    action: 'register',
    description: 'Write a brief description of this feature and why it matters.',
    btnLabel: 'Register',
    btnColor: 'rgb(54, 191, 247)' // $brightBlue
  }, {
    name: 'Create a Team',
    action: 'create_team',
    description: 'Write a brief description of this feature and why it matters.',
    btnLabel: 'Create a Team',
    btnColor: 'rgb(54, 191, 247)' // $brightBlue
  }, {
    name: 'Join a Team',
    action: 'join_team',
    description: 'Write a brief description of this feature and why it matters.',
    btnLabel: 'Join a Team',
    btnColor: 'rgb(54, 191, 247)' // $brightBlue
  }],

  background: {
    color: 'rgb(248, 248, 248)', // $alabaster
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },

  mobileTemplates: false,

  remove: true
});
})();