(function(){
'use strict';

/**
 * @kind directive
 * @name scSelectStates
 * @requires ngModel
 *
 * @description
 * Renders a dropdown populated with U.S. states and territories.
 *
 * WARNING: ngModel must be present on the original element, or else Angular
 * will not compile the select options!
 *
 * Used in:
 * application/views/scripts/checkout/donation.phtml
 * application/views/scripts/npo/cancelled-account.phtml
 * application/views/scripts/npo/offline-registration.phtml
 * application/views/scripts/npo/offline-registrations.phtml
 * application/views/scripts/npo/payment-info.phtml
 * application/views/scripts/npo/upgrade-account.phtml
 * application/views/scripts/reports/edit-donation.phtml
 * application/views/scripts/reports/fundraising-page.phtml
 * application/views/scripts/sales/payment.phtml
 * public/partials-build/reports.js
 * public/partials/reports/recurring-modal.html
 *
 * @todo Isolate scope or namespace states property; right now this directive
 * will overwrite a scope property with the name 'states'.
 */

scSelectStates.$inject = ["US_STATE_LIST", "scQaId"];
function scSelectStates(US_STATE_LIST, scQaId) {
  return {
    replace: true,
    template: '<select ng-options="state.value as state.label for state in states" qa-id="{{ scQaId.selectField }}" aria-label="state">' + '<option value="">Please Select</option></select>',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs) {
      scope.scQaId = scQaId;
      scope.states = _.cloneDeep(US_STATE_LIST);
      if (attrs.onlyStates) {
        scope.states = _.filter(scope.states, function (option) {
          return !option.canadian;
        });
      }
      /*
       * State dropdown ng-options adds first blank element with value “?” by default,
       * causing screen reader to read all drop down values of State.
       * We used timeout as we need DOM to be ready first to remove the element.
       * Removing “?” element with a timeout fixes the issue as it only reads element “Please Select” as expected.
       */
      setTimeout(function () {
        if (element.children()[0].innerText !== 'Please Select') $(element.children()[0]).remove();
      }, 0);
    }
  };
}

angular.module('classy').directive('scSelectStates', scSelectStates);
})();