(function(){
'use strict';

/**
 * @kind directive
 * @name scImgCropPreviewField
 *
 * @description
 * Displays a field which allows a user to upload and crop an image. Withholds
 * the image from the provided model until the cropped child has been saved.
 *
 * @param {string} label The field label.
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {number} width The cropped width of the image (and the width of the preview/upload box)
 * @param {number} height The cropped height of the image (and the height of the preview/upload box)
 * @param {expression} model The model for the control.
 * @param {string} buttonText The text that will appear on the upload button.
 * @param {string} description Additional helper text for the user.
 * cropped child image is saved. The child scImg is provided as an argument.
 * @param {object} imgModel Settings to pass to all three scImgModel instances.
 * @param {object} mmConfig Media manager config
 */

scImgCropPreviewField.$inject = ["scField"];
function scImgCropPreviewField(scField) {
  return scField('scImgCropPreviewField', {
    controller: ['$scope', 'scCampaignsTemplateService', '$stateParams', function ($scope, scCampaignsTemplateService, $stateParams) {
      $scope.lockField = false;
      if (SC.campaign.campaignTemplateData) {
        if ($stateParams.step === 'team') {
          var lockTeamBlock = scCampaignsTemplateService.getBlockByType('team');
          if (lockTeamBlock && $scope.options.model === 'teamBlock.current.defaultTeamLogo') {
            $scope.lockField = lockTeamBlock.item_attributes.defaultTeamLogo.locked;
          }
        }
        if ($stateParams.step === 'fundraiser') {
          var lockFundraiserBlock = scCampaignsTemplateService.getBlockByType('fundraiser');
          if (lockFundraiserBlock && $scope.options.model === 'fundraiserBlock.current.defaultFundraiserLogo') {
            $scope.lockField = lockFundraiserBlock.item_attributes.defaultFundraiserLogo.locked;
          }
        }
      }
    }],

    link: function link(scope) {
      scope.ui = {
        loading: false,
        previewStyle: function previewStyle() {
          var previewWidth = scope.options.previewWidth,
              previewHeight = scope.options.previewHeight,
              width = scope.options.width,
              height = scope.options.height;

          return {
            width: (previewWidth || width) + 'px',
            height: (previewHeight || height) + 'px',
            lineHeight: (previewHeight || height) + 'px'
          };
        },

        mmConfig: _.merge(scope[scope.options.mmConfig] || {}, {
          image: {
            crop: {
              width: scope.options.width || 250,
              height: scope.options.height || 250
            }
          }
        })
      };
    }
  });
}

angular.module('classy').directive('scImgCropPreviewField', scImgCropPreviewField);
})();