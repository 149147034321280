(function(){
'use strict';

createGA4.$inject = ["$q", "$window", "scAnalyticsUtils", "EmbeddedGivingBridgeGA4"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function createGA4($q, $window, scAnalyticsUtils, EmbeddedGivingBridgeGA4) {
  /* Setup
  ========================================================= */
  var ga4Service = {
    name: 'google_analytics_4',
    hasBridge: function hasBridge() {
      return EmbeddedGivingBridgeGA4.isInit();
    }
  };

  var debugLog = scAnalyticsUtils.debugLog,
      isPublicPage = scAnalyticsUtils.isPublicPage,
      isFunnelConversionEvent = scAnalyticsUtils.isFunnelConversionEvent,
      isEmbeddedGivingEvent = scAnalyticsUtils.isEmbeddedGivingEvent,
      getCampaign = scAnalyticsUtils.getCampaign,
      getRegistrationQuantity = scAnalyticsUtils.getRegistrationQuantity;

  /* Interface Methods
  ========================================================= */

  ga4Service.registerSuperProperties = function () {};
  ga4Service.identifyMember = function () {};

  ga4Service.loaded = function () {
    // will be sent once for every iframe, but the SDK only needs to bootstrap once
    if (EmbeddedGivingBridgeGA4.canInit()) {
      if (!EmbeddedGivingBridgeGA4.isInit()) {
        EmbeddedGivingBridgeGA4.init();
      }
      return $q.when();
    } else {
      return $q(function (resolve) {
        return SC.onGA4Loaded(function () {
          return resolve();
        });
      });
    }
  };

  ga4Service.recordPageView = function (data) {
    if (isPublicPage() && clientGA4Integration()) {
      debugLog('GA4 Client PageView');
      ga4PageView(_.extend(_.cloneDeep(data), { integration: 'client' }));
    }
  };

  ga4Service.track = function (event, data) {
    if (clientGA4Integration()) {
      if (isFunnelConversionEvent(event)) {
        debugLog('GA4 Client Track');
        if (EmbeddedGivingBridgeGA4.isInit()) {
          EmbeddedGivingBridgeGA4.track(event, getTransactionEventData(_.extend(_.cloneDeep(data), { integration: 'client' })));
        } else {
          trackTransactionEvent(event, _.extend(_.cloneDeep(data), { integration: 'client' }));
        }
      }

      if (isEmbeddedGivingEvent(event)) {
        debugLog('GA4 Client Track - Embedded Giving');
        if (EmbeddedGivingBridgeGA4.isInit()) {
          EmbeddedGivingBridgeGA4.track(event, _.extend(_.cloneDeep(data), { category: 'embedded-giving', integration: 'client' }));
        } else {
          trackEmbeddedGivingEvent(event, _.extend(_.cloneDeep(data), { category: 'embedded-giving', integration: 'client' }));
        }
      }
    }
  };

  ga4Service.eventBeacon = function (data) {
    if (!clientGA4Integration()) {
      return;
    }

    debugLog('GA4 Client Event Beacon');
    var trackingId = clientGA4Integration();
    window.gtag('config', trackingId, { send_page_view: false });

    var eventLabel = data.label || '';
    eventLabel = eventLabel.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(function (x) {
      return x.toLowerCase();
    }).join('_');

    var mappedData = mapEventData(eventLabel, data);
    $window.gtag('event', eventLabel, mappedData);
  };

  return ga4Service;

  /* Private Methods
  ========================================================= */
  function ga4PageView(options) {
    var trackingId = getTrackingId(options.integration);
    if (!trackingId) {
      return;
    }

    if (EmbeddedGivingBridgeGA4.isInit()) {
      EmbeddedGivingBridgeGA4.track({
        eventName: 'page_view',
        eventData: options
      });
    } else {
      $window.gtag('event', 'page_view', options);
    }
  }

  function clientGA4Integration() {
    return SC.clientGA4Id;
  }

  function getTrackingId() {
    return clientGA4Integration();
  }

  function buildTransactionItems(options) {
    var registrations = options.items || [];
    var campaign = getCampaign();
    var allCampaignRegistrations = campaign.current.ticketTypes || [];
    var items = [];

    _.forEach(registrations, function (registration) {
      var registrationTypeMatch = _.find(allCampaignRegistrations, function (ticket) {
        return ticket.id === registration.product_id;
      });

      var registrationName = _.get(registrationTypeMatch, 'name', null);
      var registrationPrice = _.get(registrationTypeMatch, 'price', 0);
      var registrationQuantity = getRegistrationQuantity(registration);

      var dataObject = {
        id: registration.id.toString(),
        name: registrationName,
        category: 'Ticket',
        price: registrationPrice,
        quantity: registrationQuantity
      };

      items.push(dataObject);
    });

    return items;
  }

  function calculateValue(items) {
    return _.reduce(items, function (sum, item) {
      return sum + parseFloat(item.price);
    }, 0);
  }

  function getTransactionEventData(event) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var amount = options.amount || 0;
    var rwfCompletionEvent = event === 'registration/create/complete';
    var rawCurrencyCode = options.raw_currency_code;
    var donationAmount = options.donation_amount;

    var campaignName = options.campaignName,
        designationId = options.designationId,
        frequency = options.frequency,
        country = options.country,
        promoCode = options.promoCode;


    var transactionId = rwfCompletionEvent ? options.registration_transaction_id : options.transactionId;

    var ecommerceItems = [];

    if (!rwfCompletionEvent || donationAmount > 0) {
      ecommerceItems.push({
        item_id: '1',
        item_name: 'Donation',
        affiliation: 'Classy',
        currency: rawCurrencyCode,
        item_category: rwfCompletionEvent ? 'Additional Donation' : 'Direct Donation',
        price: rwfCompletionEvent ? donationAmount : amount,
        quantity: 1
      });
    }

    if (rwfCompletionEvent) {
      var items = buildTransactionItems(Object.assign(options, { transactionId: transactionId }));
      ecommerceItems = [].concat(_toConsumableArray(ecommerceItems), _toConsumableArray(items));
    }

    return {
      transaction_id: transactionId,
      affiliation: 'Classy',
      value: amount > 0 ? amount : calculateValue(ecommerceItems),
      tax: 0,
      shipping: 0,
      currency: rawCurrencyCode,
      items: ecommerceItems,
      item_category2: campaignName,
      item_category3: frequency,
      item_category4: country,
      item_category5: designationId,
      coupon: promoCode
    };
  }

  function trackTransactionEvent(event) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    window.gtag('event', 'purchase', getTransactionEventData(event, options));
  }

  // eslint-disable-next-line no-unused-vars
  function trackEmbeddedGivingEvent(event) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    window.gtag('event', event, data);
  }

  /* Event Mapper
  ========================================================= */
  function mapEventData(eventLabel, parsedData) {
    var eventMapper = {
      add_to_cart: function add_to_cart(data) {
        return {
          items: [{
            item_id: data.ticket_id,
            item_name: data.product_name,
            currency: data.currency || 'USD',
            item_category: data.product_name === 'Additional Donation' ? 'Additional Donation' : 'Ticket',
            quantity: data.quantity || 1,
            price: data.price
          }]
        };
      },
      remove_from_cart: function remove_from_cart(data) {
        return {
          items: [{
            item_id: data.ticket_id,
            item_name: data.product_name,
            currency: data.currency || 'USD',
            item_category: data.product_name === 'Additional Donation' ? 'Additional Donation' : 'Ticket',
            quantity: data.quantity || 1,
            price: data.price
          }]
        };
      },
      new_fundraising_page: function new_fundraising_page(data) {
        return {
          entity_id: data.entity_id,
          category: data.category,
          action: data.action,
          fundraiser: {
            alias: data.fundraiser.alias,
            campaign_id: data.fundraiser.campaign_id,
            created_at: data.fundraiser.created_at,
            normalized_currency_code: data.fundraiser.normalized_currency_code,
            designation_id: data.fundraiser.designation_id,
            designation_name: data.fundraiser.designation_name,
            fundraising_team_id: data.fundraiser.fundraising_team_id,
            normalized_goal: data.fundraiser.normalized_goal,
            id: data.fundraiser.id,
            member_id: data.fundraiser.member_id,
            organization_id: data.fundraiser.organization_id,
            raw_currency_code: data.fundraiser.raw_currency_code,
            raw_goal: data.fundraiser.raw_goal,
            currency_code: data.fundraiser.currency_code,
            goal: data.fundraiser.goal,
            status: data.fundraiser.status,
            team_role: data.fundraiser.team_role,
            title: data.fundraiser.title
          }
        };
      }
    };

    if (!eventMapper[eventLabel]) {
      return parsedData;
    }

    try {
      var mapper = eventMapper[eventLabel];
      return mapper(parsedData);
    } catch (e) {
      debugLog('Failed to parse event data for ' + eventLabel);
      return parsedData;
    }
  }
}

angular.module('classy').factory('ga4Service', createGA4);
})();