(function(){
'use strict';

angular.module('classy').constant('scCustomBlockDefaults', {
  title: 'Custom',
  type: 'custom',
  remove: true,
  mobileTemplates: false,
  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },
  unsafeHtml: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec finibus finibus elit in ullamcorper.</p>',
  html: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec finibus finibus elit in ullamcorper.</p>',
  unsafeCss: '',
  css: ''
});
})();