(function(){
'use strict';

// scFeeOnTopService will be the single source of truth for fee on top status,
// calculations, etc.
scFeeOnTopService.$inject = ["$q", "$http", "$timeout", "$rootScope", "scCurrencyService", "scCampaignsService"];
function scFeeOnTopService($q, $http, $timeout, $rootScope, scCurrencyService, scCampaignsService) {
  var _this = this;

  // init service vars for clarity
  this.donorCoveredFeesEnabled = false;

  // TODO: remove once classymode no longer supported
  this.fixedFotEnabled = false;
  this.fixedFotPercent = null;

  /*
   * setAmex
   * American Express flag setter
   * */
  this.setAmex = function (isAmex) {
    _this.isAmex = isAmex;
  };

  /*
   * setInternational
   * International flag setter
   * */
  this.setInternational = function (isInternational) {
    _this.isInternational = isInternational;
  };

  /*
   * setPaymentMethod
   * paymentMethod setter
   * */
  this.setPaymentMethod = function (paymentMethod) {
    _this.paymentMethod = paymentMethod;
  };

  /*
   * setProcessorName
   * processorName setter
   * */
  this.setProcessorName = function (processor) {
    _this.processorName = processor;
  };

  /*
   * setIsCalculating
   * isCalculating setter (for type)
   * */
  this.setIsCalculating = function (type, isCalculating) {
    _this.fees[type].isCalculating = isCalculating;
    if (!isCalculating) {
      $rootScope.$broadcast('feesCalculated', type);
    }
  };

  /* ---------------------------------------------------------------------- *
   * totalFees
   *
   * Returns the total fees.
   * ---------------------------------------------------------------------- */
  this.totalFees = function () {
    var donationFee = this.getFeesByType('donation');
    var registrationFee = this.getFeesByType('registration');
    return donationFee + registrationFee;
  };

  /* ---------------------------------------------------------------------- *
   * totalFees
   *
   * Returns the donation|registration fees
   * ---------------------------------------------------------------------- */
  this.getFeesByType = function (type) {
    return parseFloat(this.fees[type].feeSum);
  };

  /*
   * isCalculating
   * Returns true if fees are calculating for the cart.
   * */
  this.isCalculating = function () {
    return this.fees.registration.isCalculating || this.fees.donation.isCalculating;
  };

  /* ---------------------------------------------------------------------- *
   * init
   * ---------------------------------------------------------------------- */
  this.init = function () {
    _this.feeRequests = {};
    _this.feeDebouncers = {};

    // TODO: replace with factory
    _this.fees = {
      registration: {
        feeSum: null,
        grossAdjustment: {},
        isCalculating: false
      },
      donation: {
        feeSum: null,
        grossAdjustment: {},
        isCalculating: false
      }
    };

    _this.isAmex = false;
    _this.isInternational = false;
    _this.paymentMethod = null;
    _this.processorName = null;
    setFotSettings();
  };

  // sets the fee on top settings, relies on the current active campaign
  // so it must be called from the init function
  var setFotSettings = function setFotSettings() {
    var campaign = scCampaignsService.active.current;

    var dcfEnabled = campaign.dcf_enabled,
        campaignClassyModeEnabled = campaign.classy_mode_enabled,
        campaignFixedFotPercent = campaign.effective_fixed_fot_percent,
        campaignTicketPassOnFees = campaign.ticket_pass_on_fees;


    if (dcfEnabled) {
      _this.donorCoveredFeesEnabled = dcfEnabled;
      return;
    }

    // TODO: remove once classymode no longer supported
    var hasPrcent = !_.isNull(campaignFixedFotPercent) && !_.isUndefined(campaignFixedFotPercent);
    var classyModeEnabled = campaignClassyModeEnabled || campaignTicketPassOnFees;

    // optional override of the campaign setting for testing purposes on non-prod
    _this.fixedFotEnabled = hasPrcent && classyModeEnabled;

    // set the fixed fee on top percent based on previous values, defaults to 0 if missing
    _this.fixedFotPercent = _this.fixedFotEnabled ? parseFloat(campaignFixedFotPercent || 0) : 0;
  };

  // setter for the fixedFotPercent, used in the event that a donor is on the page
  // while the campaign updates it's fixed fot percent and the server sends
  // down a new updated value. Emits a broadcast for components to watch.
  this.updateFixedFotPercent = function (newFixedFotPercent) {
    this.fixedFotPercent = parseFloat(newFixedFotPercent);
    $rootScope.$broadcast('fixedFotPercent:updated', this.fixedFotPercent);
  };

  /* ---------------------------------------------------------------------- *
   * unsetFees()
   *
   * Zero out fee data for a fee type and cancel pending requests.
   * ---------------------------------------------------------------------- */
  this.unsetFees = function (type) {
    this.cancelFeeRequests(type);
    this.setIsCalculating(type, false);
    this.fees[type].feeSum = 0;
    this.fees[type].grossAdjustment = {};
  };

  /* ---------------------------------------------------------------------- *
   * cancelFeeRequests()
   *
   * Cancel pending fee requests (those waiting for a debounce interval to
   * elapse and those waiting for a response).
   * ---------------------------------------------------------------------- */
  this.cancelFeeRequests = function (type) {
    if (_.get(this, ['feeDebouncers', type])) {
      this.feeDebouncers[type].reject();
    }
    if (_.get(this, ['feeRequests', type])) {
      this.feeRequests[type].reject();
    }
  };

  /* ---------------------------------------------------------------------- *
   * calculateFees()
   *
   * calculates fees based on a specific type
   * ---------------------------------------------------------------------- */
  this.calculateFees = function (_ref) {
    var cartItems = _ref.cartItems,
        type = _ref.type,
        fot = _ref.fot,
        amount = _ref.amount,
        frequency = _ref.frequency,
        overrides = _ref.overrides;

    this.fees[type].feeSum = 0;
    this.fees[type].grossAdjustment = {};
    if (fot && amount) {
      if (this.donorCoveredFeesEnabled) {
        this.fetchFees(type, amount, overrides, frequency);
      } else {
        this.handleClassyMode(type, cartItems, amount); // remove once classymode no longer supported
      }
    } else {
      this.unsetFees(type);
    }
  };

  this.handleClassyMode = function (type, cartItems, subtotal) {
    // for classymode all we have to do is calculate the fixed fot from the
    // percent set by the campaign.
    if (this.fixedFotEnabled) {
      this.fees[type].feeSum = type === 'donation' ? this.calculateFixedFeeOnTop(subtotal) : this.itemwiseServiceFeeCalculation(cartItems);

      $rootScope.$broadcast('feesCalculated', type);
    }
  };

  /* ---------------------------------------------------------------------- *
   * calculateSingleFee
   *
   * calculates a single registrantion's fee,
   * mostly for the details/registrations individual price previews
   * ---------------------------------------------------------------------- */

  this.calculateSingleFee = function (_ref2) {
    var subtotal = _ref2.subtotal,
        useFot = _ref2.useFot,
        overrides = _ref2.overrides;

    // if we are using FoT and have fixedFot enabled, just do that lol
    if (this.fixedFotEnabled && useFot) {
      return $q.resolve({
        donationLessFees: parseFloat(subtotal),
        feeSum: this.calculateFixedFeeOnTop(subtotal)
      });
    }

    // otherwise we need to estimate the fees accurately regardless of org tag or if campaign itself is DCF
    // see ticketEditor.component.js
    return this.transactionEstimate(subtotal, overrides).then(function (_ref3) {
      var data = _ref3.data;

      var totalCharge = _.get(data, 'charge_summary.total_charge');
      var processorFee = _.get(data, 'gross_adjustment.raw_processor_fee_amount');
      var applicationFee = _.get(data, 'gross_adjustment.raw_application_fee_amount');
      var feeSum = parseFloat(processorFee) + parseFloat(applicationFee);

      return {
        // Deduct the fee from the price only if the fee_on_top is true when totalCharge in returned with fee_on_top_amount added
        donationLessFees: _.get(data, 'charge_summary.fee_on_top') ? parseFloat(totalCharge) - parseFloat(feeSum) : parseFloat(totalCharge),
        feeSum: feeSum
      };
    });
  };

  /* ---------------------------------------------------------------------- *
   * fetchFees()
   *
   * Request transaction estimates.
   * ---------------------------------------------------------------------- */

  this.fetchFees = function (type, subtotal) {
    var _this2 = this;

    var overrides = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var frequency = arguments[3];

    this.setIsCalculating(type, true);
    this.cancelFeeRequests(type);

    this.feeDebouncers[type] = $q.defer();

    overrides.fot = true;

    this.feeDebouncers[type].promise.then(function () {
      _this2.feeRequests[type] = $q.defer();
      _this2.transactionEstimate(subtotal, overrides, {
        timeout: _this2.feeRequests[type].promise
      }, frequency).then(function (_ref4) {
        var data = _ref4.data;

        var feeSum = _.get(data, 'charge_summary.fee_on_top_amount');
        var grossAdjustment = _.get(data, 'gross_adjustment');

        _this2.fees[type].feeSum = parseFloat(feeSum);
        _this2.fees[type].grossAdjustment = grossAdjustment;
        _this2.setIsCalculating(type, false);
      });
    }).catch(function (err) {
      if (err) {
        console.log(err); // eslint-disable-line no-console
        _this2.setIsCalculating(type, false);
      }
    });

    // Debounce
    $timeout(function () {
      return _this2.feeDebouncers[type].resolve();
    }, 500);
  };

  this.transactionEstimate = function (subtotal, overrides) {
    var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var frequency = arguments[3];
    var campaignId = scCampaignsService.active.current.id;

    var defaults = this.getTransactionEstimateDefaults(subtotal);
    var params = Object.assign(defaults, overrides);
    config.params = params;
    if (frequency && frequency !== 'one-time') {
      return $http.get('/frs-api/campaign/' + campaignId + '/transaction-estimates?recurring=true', config);
    } else {
      return $http.get('/frs-api/campaign/' + campaignId + '/transaction-estimates', config);
    }
  };

  this.getTransactionEstimateDefaults = function (amount) {
    return {
      currency: scCurrencyService.getEntityCampaignCurrency(scCampaignsService.active.current),
      fot: _.get(scCampaignsService, 'active.current.ticket_pass_on_fees', false),
      amex: this.isAmex,
      international: this.isInternational,
      payment_method: this.paymentMethod,
      processor_name: this.processorName,
      amount: amount
    };
  };

  /* ---------------------------------------------------------------------- *
   * itemwiseServiceFeeCalculation
   *
   * Internal helper function for calculating sum of serivice fee for cart items.
   *
   * NOTE: The logic in this function should remain consistent with the logic on
   * the express layer when creating the pay body (src/server/pay/xp.buildBody.js)
   * ---------------------------------------------------------------------- */
  this.itemwiseServiceFeeCalculation = function (cartItems) {
    var _this3 = this;

    return cartItems.map(function (_ref5) {
      var price = _ref5.price,
          adjustment_amount = _ref5.adjustment_amount;
      return roundToTwo(Math.max(parseFloat(price) - (parseFloat(adjustment_amount) || 0), 0));
    }).reduce(function (sum, value) {
      return roundToTwo(parseFloat(sum + roundToTwo(_this3.fixedFotPercent / 100 * value)));
    }, 0);
  };

  // calculate the fixed fee on top value based on the campaign's
  // effective fixed fee on top percent, this will default to the org
  // if not set at the campaign level, A 5% fixed fot percent will come
  // from the API as 5
  this.calculateFixedFeeOnTop = function (total) {
    return _this.fixedFotPercent / 100 * total;
  };

  /* Round upto two digit */
  function roundToTwo(num) {
    return +(Math.round(num + 'e+2') + 'e-2');
  }
}

angular.module('classy').service('scFeeOnTopService', scFeeOnTopService);
})();