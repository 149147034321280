(function(){
'use strict';

angular.module('classy').component('selectField', {
  template: '\n      <form-label\n        required="$ctrl.required"\n        ng-if="$ctrl.label"\n        _spec="label"\n        id="form_label_id">\n          {{:: $ctrl.label }}\n      </form-label>\n\n      <div ng-if="$ctrl.lockField" data-ng-style="{ width: customStyle, height: customHeight }"\n        data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n        data-sc-tooltip-position="{{customStyle ? \'right\' : \'center\'}}"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        fixed-tooltip="true"\n        data-sc-tooltip="\'This field is locked at the template level.\'"\n        class="admin-body__tooltip-element fundraisingSelectLock"\n      ></div>\n\n      <select class="{{:: $ctrl.classProp }}"\n        ng-model="$ctrl.model"\n        ng-model-options="{ getterSetter: $ctrl.getterSetter }"\n        ng-options="{{ $ctrl.customOptions || \'opt.value as opt.label disable when opt.disable for opt in $ctrl.options\' }}"\n        sc-validity-reporter\n        sc-validity-classes\n        ng-required="$ctrl.required"\n        ng-class="$ctrl.classes"\n        ng-disabled="$ctrl.disabled"\n        name="{{:: $ctrl.name }}"\n        aria-label="{{:: $ctrl.ariaLabel }}{{:: $ctrl.differentiatorForMultipleFields}}"\n        role="listbox"\n        aria-haspopup="listbox"\n        aria-expanded="false"\n        _spec="input"\n        aria-labelledby="form_label_id">\n          <option ng-if="$ctrl.placeholder" value="" ng-selected="$ctrl.getterSetter ? !($ctrl.model && $ctrl.model()) : !this.model" disabled>{{::$ctrl.placeholder}}</option>\n      </select>\n\n      <form-error\n        name="$ctrl.name"\n        messages="$ctrl.errorMessages"\n        _spec="errors">\n      </form-error>\n    ',
  bindings: {
    model: '=',
    options: '<',
    label: '<',
    name: '<',
    classes: '@?',
    customOptions: '<?',
    placeholder: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    disabled: '<?',
    classProp: '@?',
    differentiatorForMultipleFields: '<?',
    ariaLabel: '@?'
  },
  controller: ["$attrs", "$window", "scCampaignsTemplateService", function SelectFieldCtrl($attrs, $window, scCampaignsTemplateService) {
    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      required: false,
      classProp: 'form__select--dark'
    };

    this.$onInit = function () {
      var _this = this;

      _.defaultsDeep(this, DEFAULTS);

      if (SC.campaign && SC.campaign.campaignTemplateData) {
        var isRegistrationStatusLock = _.get(SC.campaign.campaignTemplateData, 'components.campaign.component_items[0].item_attributes.default_team_appeal_email.locked', false);

        if ($attrs.model === '$ctrl.setting' && isRegistrationStatusLock) {
          this.disabled = true;
          this.lockField = true;
          this.customHeight = '10%';
        }

        if ($attrs.model === 'thisBlock.properties.textAlign') {
          var chosenParamId = scCampaignsTemplateService.fetchCurrentBlockId($window);

          var activeEmail = null;
          var foundEmailPayload = scCampaignsTemplateService.getEmailComponent(chosenParamId);
          activeEmail = scCampaignsTemplateService.getActiveEmail(foundEmailPayload);
          if (activeEmail) {
            var combinedBlocks = scCampaignsTemplateService.combineBlocks(activeEmail);
            _.forEach(combinedBlocks, function (value) {
              if (value.blockProp && value.blockProp.name === 'text') {
                _this.lockField = value.locked;
                _this.disabled = value.locked;
              }
              if (value.blockProp && value.blockProp.name === 'heading') {
                _this.lockField = value.locked;
                _this.disabled = value.locked;
              }
            });
          }
        }
      }
      if (this.placeholder && !this.model) {
        this.model = null;
      }
    };
  }]
});
})();