(function(){
'use strict';

angular.module('classy').component('restartCartModal', {
  template: '\n      <div class="restart-cart-modal" role="dialog" aria-labelledby="dialog_start_over_warning">\n        <div id="dialog_start_over_warning">\n          <modal-title>Are you sure you want to start over?</modal-title>\n          <modal-subtitle>This will release your {{::$ctrl.ticketTerm}}.</modal-subtitle>\n        </div>\n        <div class="restart-cart-modal__btns">\n          <button class="restart-cart-modal__btn--confirm"\n          ng-style="{\'background-color\': $ctrl.primaryColor}"\n          ng-click="$ctrl.startOver($ctrl.item)">Yes, start over.</button>\n          <button\n          class="restart-cart-modal__btn--cancel"\n          ng-click="$ctrl.cancel()">Stay on this page.</button>\n        </div>\n      </div>\n    ',
  bindings: {
    cart: '=',
    item: '=?'
  },
  controller: ["$state", "scFlowModalService", "scCartService", "scCampaignsService", "scThemesService", "scCurrencyService", "ticketTerms", "registrationRoutes", "scAnalytics", function controller($state, scFlowModalService, scCartService, scCampaignsService, scThemesService, scCurrencyService, ticketTerms, registrationRoutes, scAnalytics) {
    var TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE,
        CAMPAIGN_ROUTE = registrationRoutes.CAMPAIGN_ROUTE;


    this.primaryColor = scThemesService.active.current.styles.primaryColor;
    this.ticketTerm = ticketTerms[scThemesService.active.getTicketTerm()].plural;

    this.startOver = function (item) {
      scCartService.create().then(function () {
        if (scCampaignsService.active.current.team_membership_policy == 'required') {
          $state.go(CAMPAIGN_ROUTE);
        } else {
          $state.go(TICKETS_ROUTE);
        }
      });

      if (item && Object.prototype.hasOwnProperty.call(item, 'product_id')) {
        var currency = 'USD';
        if (scCampaignsService.active && scCampaignsService.active.current) {
          currency = scCurrencyService.getEntityCampaignCurrency(scCampaignsService.active.current);
        }
        // If removing an item triggered the restart send an analytics event
        scAnalytics.eventBeacon({
          category: 'frs ' + scCampaignsService.active.current.type,
          action: 'remove',
          label: 'Remove from Cart',
          ticket_id: item.product_id,
          product_name: item.name,
          currency: currency
        });
      }
    };

    this.cancel = function () {
      scFlowModalService.close();
    };
  }]
});
})();