(function(){
'use strict';

angular.module('classy').constant('scHeaderBlockDefaults', {
  title: 'Header',
  type: 'header',
  textColor: 'rgb(44, 52, 59)',
  logo: null,
  background: {
    color: 'rgb(255,255,255)',
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'tile'
  },
  remove: false,
  unsortable: true
});
})();