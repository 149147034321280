(function(){
'use strict';

globalRun.$inject = ["$http", "$rootScope", "$state", "$window", "ipCookie", "loadTwitterAPI", "MetaTags", "modelParams", "scImgAssetsService", "loadYoutubeAPI", "scCurrencyService", "scSourceCodes", "CHAR_LIMITS"];
function globalRun($http, $rootScope, $state, $window, ipCookie, loadTwitterAPI, MetaTags, modelParams, scImgAssetsService, loadYoutubeAPI, scCurrencyService, scSourceCodes, CHAR_LIMITS) {
  if (SC && SC.campaign) {
    scSourceCodes.record(SC.campaign.id);
  }

  // Add the char limits constant to the rootScope so it can be accessed by any template
  // in the module. Not advisable to put too much logic on the rootScope, but this is
  // an appropriate addition, since these have such a broad presence.
  $rootScope.CHAR_LIMITS = CHAR_LIMITS;

  if (iOS()) {
    // Fix for CL-9572 Fastclick breaks select lists on iOS 14.5+
  } else {
    // ngTouch replacement
    FastClick.attach(document.body);
  }

  function iOS() {
    return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || navigator.userAgent.includes('Mac') && 'ontouchend' in document;
  }

  $http.defaults.headers.common['csrf-token'] = ipCookie('CSRF-TOKEN');

  $rootScope.MetaTags = MetaTags;

  // Froala License
  window.$.FroalaEditor.DEFAULTS.key = 'JFOFSAGLUh1AVKb1SN==';

  /* Setup scImgAssetsService */
  if (typeof SC !== 'undefined') {
    scImgAssetsService.init(SC.assets);
  }

  /* extend the currencies array to include additional information */
  scCurrencyService.extendPayCurrencies();

  $rootScope.$on('$stateChangeStart', function (evt, to, params) {
    if (to.redirectTo) {
      evt.preventDefault();
      $state.go(to.redirectTo, params);
    }
  });

  $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
    modelParams.sync(event, toState, toParams);
  });

  loadTwitterAPI();
  loadYoutubeAPI();

  if (_.get($window, 'navigator.userAgent', '').match(/MSIE|Trident/)) {
    angular.element('html').addClass('ie');
  }

  setTimeout(function () {
    // load fonts asynchronously to avoid delaying initial page render
    WebFont.load({
      google: {
        families: ['Mulish:400italic,700italic,400,300,600,700,800']
      }
    });
  });
}

angular.module('classy').run(globalRun);
})();