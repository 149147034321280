(function(){
'use strict';

// The express version of these constants exists at src/server/frs-api/permissions/xp.constants.js
angular.module('classy').constant('PERMISSIONS', {
  campaign: {
    create_fundraising_team_admin: {
      action: 'create_fundraising_team_admin',
      entity: 'campaign'
    },
    create_transaction_offline: {
      action: 'create_transaction_offline',
      entity: 'campaign'
    },
    fetch_admin: {
      action: 'fetch_admin',
      entity: 'campaign'
    },
    update_settings: {
      action: 'update_settings',
      entity: 'campaign'
    },
    list_transactions: {
      action: 'list_transactions',
      entity: 'campaign'
    },
    list_registrations: {
      action: 'list_registrations',
      entity: 'campaign'
    },
    list_recurring_donation_plans: {
      action: 'list_recurring_donation_plans',
      entity: 'campaign'
    },
    list_fundraising_teams: {
      action: 'list_fundraising_teams',
      entity: 'campaign'
    },
    list_fundraising_pages: {
      action: 'list_fundraising_pages',
      entity: 'campaign'
    },
    update_custom_code: {
      action: 'update_custom_code',
      entity: 'campaign'
    },
    view_reporting: {
      action: 'view_reporting',
      entity: 'campaign'
    }
  },
  cart: {
    confirm_offline: {
      action: 'confirm_offline',
      entity: 'cart'
    }
  },
  classy_admin: {
    access: {
      action: 'access',
      entity: 'classy-admin'
    }
  },
  email_settings: {
    update: {
      action: 'update',
      entity: 'email-settings'
    }
  },
  transaction: {
    update: {
      action: 'update',
      entity: 'transaction'
    }
  },
  fundraising_team: {
    update: {
      action: 'update',
      entity: 'fundraising-team'
    }
  },
  fundraising_page: {
    update: {
      action: 'update',
      entity: 'fundraising-page'
    }
  },
  theme: {
    update: {
      action: 'update',
      entity: 'theme'
    }
  },
  recurring_donation_plan: {
    update: {
      action: 'update',
      entity: 'recurring-donation-plan'
    }
  }
});
})();