(function(){
'use strict';

angular.module('classy').component('formLabel', {
  template: '\n      <div class="form__label" \n      ng-class="{ required: $ctrl.required }"\n      ng-transclude></div>\n    ',
  transclude: true,
  bindings: {
    required: '<?'
  }
});
})();