(function(){
'use strict';

angular.module('classy').service('EmbeddedGivingPay', ["EmbeddedGivingPayDAL", function (EmbeddedGivingPayDAL) {
  var self = this;

  this.paymentGateway = {}; // "processor" chosen by user
  this.paymentProcessors = {};
  this.paymentMethods = {};

  // raw currency code?
  // payment?

  function serializePaymentMethods() {
    var serialize = {};
    _.forEach(this.paymentMethod, function (pm, key) {
      serialize[key] = pm.serialize();
    });
    return serialize;
  }

  this.init = function () {
    this.paymentMethods = EmbeddedGivingPayDAL.getPaymentMethods();

    return EmbeddedGivingPayDAL.getPaymentProcessors().then(function (paymentProcessors) {
      self.paymentProcessors = paymentProcessors;
      return self;
    });
  };

  this.getPaymentProcessors = function () {
    return EmbeddedGivingPayDAL.getPaymentProcessors();
  };

  this.serializePayment = function () {
    var gatewaySerialize = this.paymentGateway.serialize();
    var paymentMethods = serializePaymentMethods();

    var payload = {
      raw_currency_code: 'USD', // TODO
      gateway: gatewaySerialize
    };

    return _.extend({}, payload, paymentMethods);
  };

  // if not present, then set status to inactive
  this.syncPaymentMethodStatuses = function (statuses) {
    _.forEach(this.paymentMethods, function (pm, key) {
      // TODO casing logic
      var u = statuses[key.toLowerCase()];
      if (u && u.status) {
        pm.setStatus(u.status);
      } else {
        pm.setStatus('inactive');
      }
    });
    return this.paymentMethods;
  };
}]);
})();