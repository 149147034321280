(function(){
'use strict';

angular.module('classy').component('suffixField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n      \n      <select-field\n        aria-label="suffix"\n        name="$ctrl.name"\n        class-prop="form__select--light"\n        options="$ctrl.suffixOptions"\n        model="$ctrl.model"\n        placeholder="\'Select a suffix\'"\n        getter-setter="$ctrl.getterSetter"\n        required="$ctrl.required"\n      ></select-field>\n    ',
  bindings: {
    model: '=',
    label: '<',
    name: '<',
    placeholder: '<?',
    errorMessages: '<?',
    getterSetter: '<?',
    required: '<?',
    options: '<?'
  },
  controller: function InputFieldCtrl() {
    var _this = this;

    var DEFAULTS = {
      errorMessages: {
        required: 'This field is required.'
      },
      getterSetter: false,
      required: false,
      label: 'Suffix (Jr., Sr., II, etc.)'
    };

    this.suffixOptions = [{
      label: 'Sr.',
      value: 'Sr.'
    }, {
      label: 'Jr.',
      value: 'Jr.'
    }, {
      label: 'II',
      value: 'II'
    }, {
      label: 'III',
      value: 'III'
    }, {
      label: 'IV',
      value: 'IV'
    }, {
      label: 'V',
      value: 'V'
    }];

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS);
    };
  }
});
})();