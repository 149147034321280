(function(){
'use strict';

/*
 * This file/pattern exists so we can easily move around html file(s)
 * without having to globally search and update a bunch hardcoded paths.
 */
angular.module('classy').service('CryptoGivingPaths', function () {
  var basePath = 'global/crypto-giving/views/';
  var directivesPath = 'global/crypto-giving/views/directives/';
  var frsComponentsPath = 'global/objects/scBlock/types/frs-donation/components/';
  var staticImagesPath = '/static/global/images/crypto-giving/';

  this.views = {
    toggle: basePath + 'crypto-giving.views.toggle',
    checkout: basePath + 'crypto-giving.views.checkout',
    checkoutContact: basePath + 'crypto-giving.views.checkout-contact', // Crypto Giving Checkout Step 1
    checkoutDonation: basePath + 'crypto-giving.views.checkout-donation', // Step 2
    checkoutWallet: basePath + 'crypto-giving.views.checkout-wallet', // Step 3
    // partial for headline, aka the "welcome" view
    welcome: frsComponentsPath + 'welcome/template'
  };

  this.images = {
    arrows: staticImagesPath + 'crypto-giving-arrows.svg',
    coins: {
      bitcoin: staticImagesPath + 'crypto-giving-coin-bitcoin.svg',
      ethereum: staticImagesPath + 'crypto-giving-coin-ethereum.svg',
      usdc: staticImagesPath + 'crypto-giving-coin-usdc.svg'
    }
  };

  // Directive partials
  this.directives = {
    qrCode: directivesPath + 'crypto-giving.views.directives.qr-code'
  };

  // Shorthand for core view paths
  this.checkout = this.views.checkout; // for cleaner code in other places... less.dots
  this.toggle = this.views.toggle; // "
});
})();