(function(){
'use strict';

scThankYouBlockDefaults.$inject = ["scOrganizationsService"];
function scThankYouBlockDefaults(scOrganizationsService) {
  var org = {};

  if (_.get(scOrganizationsService, 'active.current.id')) {
    org = scOrganizationsService.active;
  }

  var name = _.get(org, 'current.name', 'our organization');

  return {
    title: 'Thank You',
    type: 'thank-you',
    headline: '<span style="font-size: 40px">Thank you for your gift</span>',
    'supporting-message': '<p>Thank you for your donation to ' + name + '. You will soon receive a confirmation email ' + ('to the account provided.</p><p>You\'ve added your voice to millions of ' + name + ' supporters committed to defending and preserving these precious ') + "resources when you join the nation's largest and most effective " + 'grassroots movement today.</p>',
    background: {
      color: null,
      image: null,
      colorOpacity: 100,
      imageOpacity: 100,
      blur: 0,
      position: 'center center',
      size: 'cover',
      repeat: 'no-repeat',
      fixed: true,
      preset: 'coverfixed'
    },

    unsortable: true
  };
}

angular.module('classy').factory('scThankYouBlockDefaults', scThankYouBlockDefaults);
})();