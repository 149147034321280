(function(){
'use strict';

scP2PCampaignDefaultBlocks.$inject = ["scBlockModel"];
function scP2PCampaignDefaultBlocks(scBlockModel) {
  return {
    header: new scBlockModel({ type: 'header' }),
    crowdfunding: new scBlockModel({
      type: 'crowdfunding',
      layout: 'p2p',
      useFeatured: false,
      joinButtonText: null,
      donateButtonText: 'Donate Now',
      donateButtonStyle: {
        border: 'solid 3px rgb(161, 168, 172)',
        backgroundColor: 'transparent',
        color: 'rgb(161, 168, 172)',
        borderRadius: 4,
        preset: 'roundedWire'
      },
      joinButtonStyle: {
        border: '3px solid',
        borderRadius: 4,
        preset: 'rounded'
      }
    }),
    about: new scBlockModel({ type: 'about' }),
    donation: new scBlockModel({
      type: 'donation',
      background: {
        preset: 'coverfixed'
      }
    }),
    thankYou: new scBlockModel({
      type: 'thank-you',
      background: {
        preset: 'coverfixed'
      }
    }),
    impact: new scBlockModel({
      type: 'impact',
      headline: 'Start Fundraising',
      impactType: 'fundraise'
    }),
    activity: new scBlockModel({ type: 'activity' }),
    footer: new scBlockModel({ type: 'footer' }),
    team: new scBlockModel({
      type: 'team',
      donateButtonStyle: {
        borderRadius: 4,
        preset: 'rounded',
        color: 'white'
      },
      joinButtonStyle: {
        border: '3px solid',
        borderRadius: 4,
        preset: 'roundedWire'
      }
    }),
    fundraiser: new scBlockModel({
      type: 'fundraiser',
      donateButtonStyle: {
        borderRadius: 4,
        preset: 'rounded',
        color: 'white'
      }
    }),
    fundraisers: new scBlockModel({ type: 'fundraisers' })
  };
}

angular.module('classy').factory('scP2PCampaignDefaultBlocks', scP2PCampaignDefaultBlocks);
})();