(function(){
'use strict';

scVanity.$inject = ["$q", "scDomainsService", "scCampaignsService", "scFundraisingTeamsService", "scFundraisingPagesService", "$filter"];
function scVanity($q, scDomainsService, scCampaignsService, scFundraisingTeamsService, scFundraisingPagesService, $filter) {
  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      var initialized = false;
      scope.entityId = 0;

      switch (attrs.scVanityType) {
        case 'fundraising-pages':
          scope.entityId = _.get(scFundraisingPagesService, 'active.current.id', 0);
          break;
        case 'fundraising-teams':
          scope.entityId = _.get(scFundraisingTeamsService, 'active.current.id', 0);
          break;
        case 'campaigns':
          scope.entityId = _.get(scCampaignsService, 'active.current.id', 0);
          break;
        default:
        // do nothing
      }

      // if there is an entity id, get current domain slug to store as original
      function init() {
        var deferred = $q.defer();
        if (!initialized && scope.entityId) {
          scDomainsService.getSlug(attrs.scVanityType, scope.entityId).then(function (response) {
            if (_.get(response, 'data.total') > 0) {
              scope.originalSlug = _.get(response, 'data.data[0].value');
            }
            initialized = true;
            deferred.resolve(true);
          });
        } else {
          initialized = true;
          deferred.resolve(true);
        }
        return deferred.promise;
      }

      function checkSlug() {
        var deferred = $q.defer();
        if (_.isUndefined(scope.currentSlug) || scope.currentSlug === '' || scope.currentSlug === scope.originalSlug) {
          deferred.resolve(true);
        } else {
          scDomainsService.check(scope.currentSlug).then(function (response) {
            if (response.data.result) {
              deferred.resolve(true);
            } else {
              scDomainsService.checkOwnership(scope.currentSlug, scope.entityId).then(deferred.resolve);
            }
          });
        }
        return deferred.promise;
      }

      function formatValue(val) {
        var buff = $filter('scSlugify')(val);
        buff = buff === '-' ? '' : buff;
        if (buff.length > 100) {
          buff = buff.substring(0, 100);
        }
        return buff;
      }

      ngModel.$parsers.push(function () {
        return formatValue(ngModel.$viewValue);
      });

      ngModel.$formatters.push(function () {
        return formatValue(ngModel.$modelValue);
      });

      ngModel.$asyncValidators.slugAvailable = function (modelValue) {
        var buffer = formatValue(modelValue);
        scope.currentSlug = buffer;
        ngModel.$setViewValue(buffer);
        ngModel.$render();

        return init().then(function () {
          return checkSlug();
        }).then(function (response) {
          if (response) {
            return true;
          }
          return $q.reject();
        }, function () {
          return $q.reject;
        });
      };
    }
  };
}

angular.module('classy').directive('scVanity', scVanity);
})();