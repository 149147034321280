(function(){
'use strict';

/* -------------------------------------------------------------------------- *
 * Load the Google Maps API
 * -------------------------------------------------------------------------- */

angular.module('classy').provider('loadGoogleMapsAPI', function () {
  var apiKey = null;

  this.setKey = function (key) {
    if (!key) {
      return;
    }
    apiKey = key.toString();
  };

  this.$get = ['$q', '$window', function ($q, $window) {
    if (!apiKey) {
      return $q.reject();
    }

    var apiLoaded = $q.defer();

    $window.initializeGoogleMapsAPI = function () {
      apiLoaded.resolve($window.google.maps);
    };

    // Adapted from https://developers.google.com/maps/documentation/javascript/tutorial#asynch
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = 'async';
    script.defer = 'defer';
    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&libraries=places&callback=initializeGoogleMapsAPI';
    document.body.appendChild(script);

    return apiLoaded.promise;
  }];
});
})();