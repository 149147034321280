(function(){
'use strict';

angular.module('classy').component('percentageField', {
  template: '\n      <form-label\n      required="$ctrl.required"\n      ng-if="$ctrl.label"\n      _spec="label">\n        {{:: $ctrl.label }}\n      </form-label>\n\n      <div class="form__text-input__outer" data-ng-class="{ \'admin-body__lock-field\': $ctrl.lockField }">\n        <div ng-if="$ctrl.lockField"\n        data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n        data-sc-tooltip-position="center"\n        data-sc-tooltip-position-tip="bottom center"\n        show-event="\'mouseenter\'" hide-event="\'mouseout\'"\n        fixed-tooltip="true"\n        data-sc-tooltip="\'This field is locked at the template level.\'"\n        class="admin-body__tooltip-element"\n        ></div>\n\n      <input class="form__text-input--dark"\n        ng-model="$ctrl.model"\n        ng-model-options="{getterSetter: $ctrl.getterSetter}"\n        ng-change="$ctrl.onChange()"\n        ng-required="$ctrl.required"\n        sc-validity-reporter="$ctrl.name"\n        sc-validity-classes\n        sc-percentage-display\n        ng-disabled="$ctrl.disableFlag"\n        max="$ctrl.max"\n        min="$ctrl.min"\n        type="text"\n        name="{{:: $ctrl.name }}"\n        placeholder="{{:: $ctrl.placeholder }}"\n        ng-focus="$ctrl.onFocus = true"\n        ng-blur="$ctrl.onFocus = false"\n        qa-id="{{:: $ctrl.scQaId.singleLineTextInput }}"\n        _spec="input"\n        aria-label="Percentage field" />\n\n      <form-error\n      ng-if="!$ctrl.customErrors"\n      name="$ctrl.name"\n      messages="$ctrl.errorMessages"\n      _spec="errors"></form-error>\n    </div>\n    ',
  bindings: {
    model: '=',
    getterSetter: '<?',
    disableFlag: '=?',
    name: '<',
    label: '<',
    onChange: '&?',
    placeholder: '<?',
    errorMessages: '<?',
    customErrors: '<?',
    max: '<?',
    min: '<?',
    required: '<?',
    onFocus: '=?',
    lockField: '=?'
  },
  controller: ["scQaId", function PercentageFieldCtrl(scQaId) {
    var _this = this;

    function DEFAULTS() {
      return {
        disabled: false,
        min: this.name === 'classyMode' ? 1 : 0,
        max: 100,
        errorMessages: {
          min: this.name === 'classyMode' ? 'Please enter a number greater than or equal to ' + (this.min || 1) : 'Please enter a number greater than or equal to ' + (this.min || 0),
          max: 'Please enter a number smaller than or equal to ' + (this.max || 100) + '.',
          required: 'Please enter an amount.',
          parse: 'Please enter a valid percentage amount.'
        },
        customErrors: false,
        required: false
      };
    }

    this.scQaId = scQaId;

    this.$onInit = function () {
      _.defaultsDeep(_this, DEFAULTS.call(_this));
    };
  }]
});
})();