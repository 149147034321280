(function(){
'use strict';

angular.module('classy').factory('CryptoGivingCoin', ["$http", "$q", "$filter", "CG_COIN_DIGITS", function ($http, $q, $filter, CG_COIN_DIGITS) {
  function CryptoGivingCoin(data) {
    this.symbol = data.symbol;
    this.name = data.name;
    // The next 3 properties are undefined on load, set async w/ API
    this.usdValue = data.usdValue;
    this.usdValueTimeStamp = data.usdValueTimeStamp;
    this.depositAddress = data.depositAddress;

    // Thread locking properties ('$$' prefix)
    // NOTE: '$$' prefix in angular is automatically stripped by $http service
    this.$$isCreatingDepositAddress = false;
    this.$$isFetchingMarketData = false;
  }

  CryptoGivingCoin.prototype.getMarketData = function () {
    if (this.usdValue) {
      return $q.when(this);
    } else {
      return this.fetchMarketData();
    }
  };

  CryptoGivingCoin.prototype.convertUSD = function (amountUSD) {
    if (!this.usdValue) {
      return '';
    } else {
      return Math.round(amountUSD / this.usdValue * CG_COIN_DIGITS) / CG_COIN_DIGITS;
    }
  };

  CryptoGivingCoin.prototype.convertCoin = function (amountCoin) {
    if (!this.usdValue) {
      return ''; // because we are filtering to string currency below in the else
    } else {
      return $filter('currency')(Math.round(amountCoin * this.usdValue * CG_COIN_DIGITS) / CG_COIN_DIGITS);
    }
  };

  CryptoGivingCoin.prototype.convertCoinRaw = function (amountCoin) {
    if (!this.usdValue) {
      return 0; // because we are filtering to string currency below in the else
    } else {
      return Math.round(amountCoin * this.usdValue * 100) / 100; // AB.XY (hundredths place)
    }
  };

  CryptoGivingCoin.prototype.fetchMarketData = function () {
    var defer = $q.defer();

    if (!this.$$isFetchingMarketData) {
      this.$$isFetchingMarketData = true;
      var self = this;
      var path = '/frs-api/crypto-giving/' + this.symbol + '/USD/market-data'; // TODO update

      $http.get(path).then(function (res) {
        var data = res.data || {};
        self.usdValue = data.amount;
        self.usdValueTimeStamp = +new Date(); // convert to milliseconds (int)
        defer.resolve(self);
      }).finally(function () {
        self.$$isFetchingMarketData = false;
      });
    }

    return defer.promise;
  };

  return CryptoGivingCoin;
}]);
})();