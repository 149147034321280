(function(){
'use strict';

angular.module('classy').component('feeOnTop', {
  template: '<div class="donation-table" ng-if="$ctrl.showFOT()" style="margin-top:0">\n                    <div class="donation-table_row">\n                    <div class="donation-table_columns donation-table_columns_without_margin">\n                        <div class="donation-table_column">\n                \n                        <span class="fot-checkbox"\n                        data-sc-checkbox\n                        data-ng-model="$ctrl.fot"\n                        name="donation_fot"\n                        data-id="DCF-check"\n                        aria-label="{{$ctrl.stripTagsFromAppeal()}}">\n                            <span data-sc-ctx-bind-html="$ctrl.classyModeAppeal() | scTrustedHtml"></span>\n                        </span>\n                \n                        </div>\n                    </div>\n                    </div>\n                </div>\n            ',
  bindings: {
    fot: '='
  },
  controller: ["scCampaignsService", "scOrganizationsService", function controller(scCampaignsService, scOrganizationsService) {
    var _this = this;

    this.showFOT = function () {
      return scCampaignsService.active.current.classy_mode_enabled || scCampaignsService.active.current.dcf_enabled;
    };

    this.classyModeAppeal = function () {
      return scCampaignsService.active.current.classy_mode_appeal || '\n              <p>I\'d like to cover the processing fee so 100% of my donation goes to ' + scOrganizationsService.active.current.name + '.</p>\n            ';
    };

    this.stripTagsFromAppeal = function () {
      // remove any HTML tags from classy mode appeal
      var appeal = _this.classyModeAppeal();
      return appeal.replace(/(<([^>]+)>)/gi, '');
    };
  }]
});
})();