(function(){
'use strict';

/**
 * @name remoteAssetsService
 * @kind service
 *
 * @description
 * Fetches remote assets (currently, assets from Pixabay).
 */

remoteAssetsService.$inject = ["$http"];
function remoteAssetsService($http) {
  this.search = function (pixQuery, page) {
    // Pixabay wants + rather than %20
    var query = pixQuery ? pixQuery.replace(/\s/g, '+') : '';

    return $http({
      method: 'GET',
      url: '/frs-api/asset/remote/search',
      params: {
        search: query,
        page: page || 1
      },
      loadingScope: true
    });
  };

  this.getHD = function (id) {
    return $http({
      method: 'GET',
      url: '/frs-api/asset/remote/full',
      params: {
        id: id
      },
      loadingScope: true
    });
  };
}

angular.module('classy').service('remoteAssetsService', remoteAssetsService);
})();