(function(){
'use strict';

angular.module('classy').component('egDonationCustomAmount', {
  templateUrl: 'global/embedded-giving/components/donation-custom-amount/eg.components.donation-custom-amount', // same folder as this
  bindings: {
    model: '=',
    disableFlag: '=?',
    name: '<',
    currencyCode: '<?',
    label: '<',
    placeholder: '<',
    errorMessages: '<?',
    getterSetter: '<?',
    roundCurrency: '<?',
    currencyAriaLabel: '@?',
    modalBindValue: '=?',
    selected: '<?',
    inputDataId: '@?'
  },
  require: {
    form: '^form'
  },
  controller: ["$element", "$scope", "EmbeddedGivingColors", "scCurrencyService", "scQaId", "scThemesService", "EmbeddedGiving", function CurrencyFieldCtrl($element, $scope, EmbeddedGivingColors, scCurrencyService, scQaId, scThemesService, EmbeddedGiving) {
    var _this = this;

    this.isPreviewing = EmbeddedGiving.isPreviewing;
    this.scQaId = scQaId;
    this.getterSetter = false;
    this.required = false;
    this.disabled = false;
    this.roundCurrency = true;
    this.min = 1;
    this.max = 10000000;
    this.errorMessages = {
      min: 'Please enter an amount greater than or equal to 1.',
      max: 'Please enter an amount smaller than or equal to 10,000,000.',
      required: 'Please enter an amount.',
      parse: 'Please enter a valid currency amount.'
    };
    this.colorPrimary = EmbeddedGivingColors.getPrimary(scThemesService.active);
    this.colorAccent = EmbeddedGivingColors.getAccent(scThemesService.active);
    this.colorAccentOpaque = EmbeddedGivingColors.getAccentOpaque(scThemesService.active);

    // TODO refactor to check for isPreview
    this.getColorPrimary = function () {
      return EmbeddedGivingColors.getPrimary(scThemesService.active);
    };
    this.getColorAccent = function () {
      return EmbeddedGivingColors.getAccent(scThemesService.active);
    };
    this.getColorAccentOpaque = function () {
      return EmbeddedGivingColors.getAccentOpaque(scThemesService.active);
    };

    this.$onInit = function () {
      if (_this.modalBindValue > 0) {
        setTimeout(function () {
          _this.form.amount.$setValidity('min', true);
          _this.form.amount.$setValidity('max', true);
        }, 50);
      }
      // _.defaultsDeep(this, DEFAULTS.call(this));
      _this.setupSelectListener();

      if (_this.currencyCode) {
        _this.symbol = scCurrencyService.getCurrencySymbol(_this.currencyCode);
      }
    };

    this.onChange = function () {
      _this.modalBindValue = _this.model;

      if (_this.model > 0) {
        _this.form.amount.$setValidity('required', true);
        _this.form.amount.$setValidity('max', true);
        _this.form.amount.$setValidity('min', true);
      } else if (_this.model === undefined) {
        _this.form.amount.$setValidity('required', false);
      }
    };

    this.setupSelectListener = function () {
      if (_this.selectOn) {
        $scope.$on(_this.selectOn, function () {
          $element.find('input').focus();
          $element.find('input').select();
        });
      }
    };

    this.getStylesDefault = function () {
      return {
        'background-color': _this.getColorAccentOpaque(),
        'border-color': _this.getColorAccentOpaque()
      };
    };

    this.getStylesSelected = function () {
      return {
        'border-color': _this.getColorPrimary(),
        'box-shadow': 'inset 1px 2px 12px rgba(0,0,0,0.2), inset 1px 2px 2px rgba(0, 0, 0, 0.2)'
      };
    };

    $scope.$on('selectedAmountChange', function (_evt, convertedAmount) {
      _this.model = convertedAmount > 0 ? convertedAmount : '';
    });
  }]
});
})();