(function(){
'use strict';

cpAPI.$inject = ["$http", "scOrganizationsService"];
function cpAPI($http, scOrganizationsService) {
  var _this = this;

  this.getTokenexData = function (origin) {
    return payGetToken('tokenexCC', { origin: origin }).then(function (response) {
      return response.data;
    });
  };

  this.getBraintreeToken = function (currency) {
    var applicationId = this.getApplicationId();
    return payGetToken('braintree', {
      applicationId: applicationId,
      currency: currency
    }).then(function (response) {
      return response.data;
    });
  };

  this.getBitpayInvoice = function (amount, email) {
    var applicationId = this.getApplicationId();
    return payGetToken('bitpay', {
      applicationId: applicationId,
      amount: amount,
      email: email
    }).then(function (response) {
      return response.data;
    });
  };

  this.getStripeData = function (currency) {
    var applicationId = this.getApplicationId();
    return payGetToken('stripe', {
      applicationId: applicationId,
      currency: currency
    }).then(function (response) {
      return response.data;
    });
  };

  this.getStripePublishableKey = function () {
    return payGetToken('stripePublishableKey').then(function (response) {
      return response.data;
    }).catch(bugsnagClient.notfiy);
  };

  this.getPlaidToken = function (clientUserId) {
    return payGetToken('plaid/createLinkToken', {}, {
      clientUserId: clientUserId
    }, 'POST').then(function (response) {
      return response.data;
    });
  };

  this.getPayPalCommerceData = function () {
    var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'USD';

    var applicationId = this.getApplicationId();
    return payGetToken('paypal', {
      applicationId: applicationId,
      currency: currency
    }).then(function (response) {
      return response.data;
    });
  };

  this.createPayPalOrder = function (amount, currency, paymentMethod) {
    var payHost = SC.pay;
    var body = {
      applicationId: _this.getApplicationId(),
      amount: amount,
      currency: currency,
      paymentMethod: paymentMethod,
      type: 'DONATION'
    };
    return $http.post(payHost + '/paypal/order', body,
    // Note that Classy Pay has no concept of CSRF, but if we don't include
    // this header, then Angular will automatically add it, and the API
    // call will fail.
    {
      headers: {
        'csrf-token': undefined
      }
    }).then(function (response) {
      return response.data;
    });
  };

  this.getPayPalBillingAgreementToken = function () {
    var applicationId = this.getApplicationId();
    return payGetToken('paypalBillingAgreement', {
      applicationId: applicationId
    }).then(function (response) {
      return response.data;
    });
  };

  this.getAccountRoutingTokenExConfig = function (origin) {
    return payGetToken('tokenexAch', { origin: origin }).then(function (response) {
      return response.data;
    });
  };

  this.getApplicationId = function () {
    // DO NOT CONVERT TO NUMBER - it is too large and JS will round it.
    return scOrganizationsService.getTag('classyPayApplicationId');
  };

  /* Pay Api Helpers
  ========================================================= */
  function payGetToken(paymentMethod) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'GET';

    var payHost = SC.pay;
    return $http({
      url: payHost + '/token/' + paymentMethod,
      method: method,
      params: params,
      data: data,
      // Note that Classy Pay has no concept of CSRF, but if we don't include
      // this header, then Angular will automatically add it, and the API
      // call will fail.
      headers: {
        'csrf-token': undefined
      }
    });
  }
}

angular.module('classy').service('cpAPI', cpAPI);
})();