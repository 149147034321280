(function(){
'use strict';

angular.module('classy').component('mobileCartModal', {
  template: '\n      <div class="mobile-cart__container"\n      ng-class="{\'empty\': !$ctrl.cart.stagedItems.length}">\n\n        <modal-title ng-if="$ctrl.cart.stagedItems.length">Your order</modal-title>\n        <modal-title ng-if="!$ctrl.cart.stagedItems.length">Your cart is currently empty.</modal-title>\n        <div class="mobile-cart">\n          <registration-cart-list data-cart="$ctrl.cart"></registration-cart-list>\n        </div>\n      </div>\n    ',

  bindings: {
    cart: '='
  }
});
})();