(function(){
'use strict';

progressCircle.$inject = ["$window", "$timeout", "ProgressBar", "scUserAgent"];
function progressCircle($window, $timeout, ProgressBar, scUserAgent) {
  return {
    restrict: 'AE',
    scope: {
      amount: '=',
      trailColor: '=?',
      color: '=?',
      animation: '=?'
    },
    transclude: true,
    replace: true,
    template: '\n      <div class="progress-circle">\n        <ng-transclude class="progress-circle__transcluded-content"></ng-transclude>\n      </div>\n    ',

    link: function link(scope, element, attrs) {
      var progressBarInit = false;

      var circle = new ProgressBar.Circle(element[0], {
        color: scope.color || '#555',
        trailColor: scope.trailColor || '#f4f4f4',
        strokeWidth: scUserAgent.isIE() && attrs.strokeWidth > 6 ? 6 : attrs.strokeWidth || 1,
        fill: attrs.fill || 'transparent',
        trailWidth: scUserAgent.isIE() && attrs.trailWidth > 6 ? 6 : attrs.trailWidth || 1,
        text: attrs.text || null,
        easing: attrs.easing || 'easeInOut',
        duration: attrs.duration || 1400,
        svgStyle: attrs.svgStyle || null
      });

      // Added to handle situations where the progress-circle was not properly
      // detecting that a color had "changed" the first time it is set.
      // This was occurring when the data-color attribute was a method,
      // rather than a simple field. In this case, the "color" field started
      // as undefined, but the first time scope.$watch was triggered,
      // newVal and oldVal were the same. The result was that the
      // color would update on subsequent changes, but not pick
      // up the initial change.
      var hasChangedTrailColor = !!scope.trailColor;
      scope.$watch('trailColor', function (newVal, oldVal) {
        if (newVal != oldVal || hasChangedTrailColor) {
          hasChangedTrailColor = true;
          $('.progress-circle svg').attr('aria-hidden', 'true');
          $('svg path:first-child').attr('stroke', newVal);
        }
      });

      // Added to handle situations where the progress-circle was not properly
      // detecting that a color had "changed" the first time it is set.
      // This was occurring when the data-color attribute was a method,
      // rather than a simple field. In this case, the "color" field started
      // as undefined, but the first time scope.$watch was triggered,
      // newVal and oldVal were the same. The result was that the
      // color would update on subsequent changes, but not pick
      // up the initial change.
      var hasChangedColor = !!scope.color;
      scope.$watch('color', function (newVal, oldVal) {
        if (newVal != oldVal || !hasChangedColor) {
          hasChangedColor = true;

          $('svg path:nth-child(2)').attr('stroke', newVal);
        }
      });

      scope.$watch('amount', function (newVal, oldVal) {
        if (newVal !== oldVal) {
          circle.animate(Math.min(100, newVal) / 100);
          progressBarInit = true;
        }
      });

      if (!scope.animation) {
        circle.animate(Math.min(100, scope.amount) / 100);
        progressBarInit = true;
      }

      var $windowDiv = angular.element($window),
          $element = angular.element(element),
          windowHeight = $windowDiv.height() / 1.2;

      function maybeTrigger() {
        // this was triggering some weird jQuery 3 message when changing states from
        // landing to checkout; basically throwing the height check in there to see
        // if the element is actually on the page or just a ghost of landing's past
        if ($.contains(document.documentElement, $element.get(0)) && $windowDiv.scrollTop() > $element.offset().top - windowHeight && !progressBarInit) {
          circle.animate(Math.min(100, scope.amount) / 100);
          progressBarInit = true;
        }
      }

      $windowDiv.on('scroll wheel', function () {
        var triggered = maybeTrigger();

        if (!triggered) {
          // If you scroll too rapidly, you may not emit enough scroll
          // events to capture one late enough to trigger the callback, so we
          // schedule a promise to check once scrolling is complete.
          $timeout(maybeTrigger, 10);
        }
      });
    }
  };
}

angular.module('classy').directive('progressCircle', progressCircle);
})();