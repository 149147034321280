(function(){
'use strict';

/*
 * A CryptoGivingWallet (i.e. "wallet") is comprised of 0...M CryptoGivingCoins (i.e. "coins").
 *
 * The coins a wallet has is dependent on an Org's CryptoGiving configuration. On release, the only
 * available exchange for Org's will be Coinbase.
 *
 * Thus, the coins here will be <= the coins Coinbase's API supports.
 *
 * On release, we will support 3 coins: Bitcoin, Ethereum, and USDC. Because there is exactly 1
 * exchange and 3 coins, we will opt to hardcode here to speed up release and opt to migrate this logic
 * to exclusively BE logic at a later date (when we support multiple exchanges and/or >3 coins).
 */
angular.module('classy').service('CryptoGivingWallet', ["$log", "$q", "CryptoGivingCoin", function ($log, $q, CryptoGivingCoin) {
  // 2 data structures for Big(O) gains
  this.coins = [];
  this.__coins = {};

  this.init = function (data) {
    var d = data || {};
    this.__initCoins(d.coins);
    return this; // piping saves a line of code elsewhere ;)
  };

  this.__initCoins = function (rawCoins) {
    if (rawCoins && rawCoins.length) {
      // clear coins for new
      this.coins = [];
      this.__coins = {};

      var newCoin = void 0;
      for (var i = 0; i < rawCoins.length; i += 1) {
        newCoin = new CryptoGivingCoin(rawCoins[i]);
        this.coins.push(newCoin);
        this.__coins[newCoin.symbol] = newCoin;
      }
    } else {
      $log.warn('[CryptoGivingWallet:__initCoins] required coins not provided.');
    }
  };

  this.getCoin = function (symbol) {
    return this.__coins && this.__coins[symbol];
  };

  this.getCoinMarketData = function (symbol) {
    var coin = this.getCoin(symbol);

    if (coin) {
      return coin.getMarketData();
    } else {
      $log.warn('[CryptoGivingWallet:getMarketDataBySymbol] coin [', symbol, '] is not supported.');
      return $q.reject();
    }
  };

  this.createCoinDepositAddress = function (symbol) {
    var coin = this.getCoin(symbol);

    if (coin) {
      return coin.getMarketData();
    } else {
      $log.warn('[CryptoGivingWallet:getMarketDataBySymbol] coin [', symbol, '] is not supported.');
      return $q.reject();
    }
  };
}]);

// TODO
// - make own service when time is appropriate and im jumping files less
// - should wallet contain user information?
// - makes sense to me but could be tough to model, maybe just map over the properties?
})();