(function(){
'use strict';

frsDonationBlockSettings.$inject = ["scFroalaOptions"];
function frsDonationBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/donation/settings',
    controller: ['$scope', function ($scope) {
      $scope.appealOptions = scFroalaOptions({ type: 'inline-with-video' }, {
        ariaLabel: 'Appeal'
      });
      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        charCounterCount: true,
        charCounterMax: 120,
        ariaLabel: 'Headline'
      });
    }],
    link: function link() {}
  };
}

angular.module('classy').directive('frsDonationBlockSettings', frsDonationBlockSettings);
})();