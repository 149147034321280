(function(){
'use strict';

angular.module('classy').component('cpCartPaypalWidget', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n    <div class="cp-paypal-widget">\n      <div\n        ng-class="{\'logged-in\': $ctrl.model.status === $ctrl.constants.STATUS.SUCCESS}"\n        class="donation-payment_paypal-button"\n        id="btPaypal"\n        ng-show="[$ctrl.constants.STATUS.READY, $ctrl.constants.STATUS.SUCCESS].includes($ctrl.model.status)"\n      ></div>\n    </div>\n  ',
  bindings: {
    model: '<',
    onReady: '&',
    onSuccess: '&',
    onCancel: '&',
    currency: '<'
  },
  controller: ["$window", "cpAPI", "cpConstants", "scDigitalWalletsService", function cpPaypalWidget($window, cpAPI, cpConstants, scDigitalWalletsService) {
    this.constants = cpConstants;
    this.config = {};

    this.$onChanges = function () {
      if (this.currency) {
        angular.element(document.querySelector('#btPaypal')).html('');
        this.setupPaypal();
      }
    };

    this.setupPaypal = function () {
      var _this = this;

      cpAPI.getBraintreeToken(this.currency).then(function (token) {
        $window.braintree.setup(token, 'custom', {
          paypal: {
            container: 'btPaypal',
            onCancelled: _this.onPaypalCancel
          },
          onPaymentMethodReceived: function onPaymentMethodReceived(params) {
            _this.model.status = cpConstants.STATUS.SUCCESS;
            _this.model.nonce = params.nonce;
            _this.onSuccess({ params: params });

            _this.ngModelCtrl.$setValidity('required', true);
            _this.ngModelCtrl.$setDirty();
          },
          onReady: function onReady(params) {
            _this.teardown = params.teardown;
            _this.model.status = cpConstants.STATUS.READY;
            _this.onReady();
          }
        });
      });

      this.onPaypalCancel = function () {
        _this.model.status = cpConstants.STATUS.READY;
        _this.model.nonce = null;
        _this.onCancel();

        _this.ngModelCtrl.$setViewValue(null);
        _this.ngModelCtrl.$setPristine();
        _this.ngModelCtrl.$setUntouched();
      };

      scDigitalWalletsService.addOnDigitalWalletsSubmitListener(function () {
        if (_this.model.status === cpConstants.STATUS.SUCCESS) {
          _this.onPaypalCancel();
        }
      });
    };
  }]
});
})();