(function(){
'use strict';

angular.module('classy').component('featuredBlockFeature', {
  bindings: {
    featured: '='
  },
  template: '\n    <div class="featured-block__feature">\n      <div class="featured-block__feature-image">\n        <img class="featured-block__feature-image-src"\n        ng-src=""></img>\n      </div>\n      \n      <h1 class="featured-block__feature-name">{{$ctrl.featured.name}}</h1>\n      <p class="featured-block__feature-description">{{$ctrl.featured.description}}</p>\n      \n      <button class="featured-block__feature-btn" ng-if="$ctrl.featured.btnLabel" \n      data-ng-style="$ctrl.btnStyle()"\n      data-ng-click="$ctrl.action()">\n        {{$ctrl.featured.btnLabel}}\n      </button>\n    </div>\n  ',
  controller: ["$log", "createFundraisingTeamFlow", "createFundraisingPageFlow", "fundraiseSwitchFlow", function controller($log, createFundraisingTeamFlow, createFundraisingPageFlow, fundraiseSwitchFlow) {
    var _this = this;

    this.btnStyle = function () {
      var style = {};

      style['background-color'] = _this.featured.btnColor;
      style['border-color'] = _this.featured.btnColor;

      return style;
    };

    this.action = function () {
      switch (_this.featured.action) {
        case 'create_team':
          createFundraisingTeamFlow();
          break;

        case 'join_team':
          fundraiseSwitchFlow({ browseTeams: true });
          break;

        case 'register':
          createFundraisingPageFlow();
          break;
        default:
          $log.log('no action defined');
          break;
      }
    };
  }]
});
})();