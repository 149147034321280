(function(){
'use strict';

/**
 * @kind run
 * @name scImgPreflight
 *
 * @description
 * Hooks into the block preflight service to delay blocks from updating until
 * all images are saved.
 */

scImgPreflightRun.$inject = ["$q", "scImg", "scPreflight"];
function scImgPreflightRun($q, scImg, scPreflight) {
  scPreflight.add(function (data) {
    var deferred = $q.defer();

    scImg.search(data).all('save', function () {
      deferred.resolve();
    });

    return deferred.promise;
  });
}

angular.module('classy').run(scImgPreflightRun);
})();