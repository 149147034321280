(function(){
'use strict';

function scPattern() {
  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.scPattern = function (modelValue, viewValue) {
        var pattern = new RegExp(attrs.scPattern);
        var value = modelValue || viewValue;
        return pattern.test(value);
      };
    }
  };
}

angular.module('classy').directive('scPattern', scPattern);
})();