(function(){
'use strict';

/* Dedicate Donation */
angular.module('classy').component('egDonationDedication', {
  templateUrl: 'global/embedded-giving/components/donation-dedication/eg.components.donation-dedication', // same folder as this
  bindings: {
    parentModel: '<'
  },
  controller: ["scCampaignsService", "EMAIL_VALIDATION_REGEX", "EmbeddedGivingPaths", function controller(scCampaignsService, EMAIL_VALIDATION_REGEX, EmbeddedGivingPaths) {
    var _this = this;

    this.isOpen = false;
    this.isEnabled = !__getCampaignAttr('hide_dedications');
    this.eCards = __getCampaignAttr('ecards');
    this.isECardEnabled = __getCampaignAttr('allow_ecards');
    this.isEmailEnabled = __getCampaignAttr('send_dedication_emails');
    this.isPrintedCardEnabled = __getCampaignAttr('offer_dedication_postal_notifications');
    this.isPrintedCardChecked = false;

    this.toggleIcon = EmbeddedGivingPaths.images.dedication;

    // component's local dedication state
    this.dedication = {};

    this.$onInit = function () {
      _this.dedication = __getInitialDedication();
    };

    this.onClose = function () {
      _this.isOpen = false;
      if (!_this.isPrintedCardValid()) {
        _this.isPrintedCardChecked = false;
      }
    };

    this.onOpen = function () {
      _this.isOpen = true;

      if (__isParentModelDedicationSet(_this.parentModel)) {
        /**
         * PS. Creating copy coz we don't want changes to the local dedication
         * to affect parentModel.dedication until the changes are explicitly saved
         * by clicking the save button.
         */
        _this.dedication = Object.assign({}, _this.parentModel.dedication);
      }
    };

    this.onSave = function () {
      if (_this.parentModel) {
        /**
         * Creating copy coz dedication and parentModel.dedication
         * must be independent objects.
         */
        _this.parentModel.dedication = Object.assign({}, _this.dedication);
        _this.onClose();
      }
    };

    this.onRemove = function () {
      if (_this.parentModel) {
        _this.parentModel.dedication = null;
        _this.dedication = __getInitialDedication();
        _this.onClose();
      }
    };

    this.onSelectECard = function (eCardId) {
      _this.dedication.ecard_id = eCardId;
    };

    this.isRemoveVisible = function () {
      return __isParentModelDedicationSet(_this.parentModel);
    };

    this.isSaveDisabled = function () {
      var disabled = false;

      if (_this.dedication.email_address || _this.dedication.ecard_id) {
        var isEmailValid = EMAIL_VALIDATION_REGEX.test(_this.dedication.email_address);
        disabled = !isEmailValid || !_this.dedication.first_name || !_this.dedication.last_name;
      }

      if (!_this.isPrintedCardValid()) {
        disabled = true;
      }

      if (!_this.dedication.honoree_first_name || !_this.dedication.honoree_last_name) {
        disabled = true;
      }

      return disabled;
    };

    this.isPrintedCardValid = function () {
      if (_this.isPrintedCardChecked) {
        var isUS = _this.dedication.country === 'US';
        var isCA = _this.dedication.country === 'CA';
        // state is required only if country is US or CA
        var isStateValid = isUS || isCA ? !!_this.dedication.state : true;

        if (!_this.dedication.first_name || !_this.dedication.last_name || !_this.dedication.country || !_this.dedication.address || !_this.dedication.city || !_this.dedication.postal_code || !isStateValid) {
          return false;
        }
      }

      return true;
    };

    this.stateField = function () {
      var isUS = _this.dedication.country === 'US';
      var isCA = _this.dedication.country === 'CA';

      return {
        isUS: isUS,
        isCA: isCA,
        show: isUS || isCA, // show state field only if country is US or CA
        label: isUS ? 'State' : 'Province',
        reset: function reset() {
          _this.dedication.state = null;
        }
      };
    };

    this.isRecipientVisible = function () {
      return _this.isEmailEnabled || _this.isPrintedCardChecked;
    };

    this.isRecipientEmailRequired = function () {
      return !!_this.dedication.email_address || !!_this.dedication.ecard_id;
    };

    /**
     * PS. The recipient's name is required if the donor enters the recipient's email
     * or checks to send a printed card.
     */
    this.isRecipientNameRequired = function () {
      return _this.dedication.email_address || _this.isPrintedCardChecked || !!_this.dedication.ecard_id;
    };

    this.isECardMessageVisible = function () {
      return _this.isECardEnabled || _this.isEmailEnabled || _this.isPrintedCardChecked;
    };

    this.getToggleValue = function () {
      var dedication = _this.parentModel.dedication;

      if (__isParentModelDedicationSet(_this.parentModel)) {
        return 'In ' + dedication.type + ' of ' + dedication.honoree_first_name + ' ' + dedication.honoree_last_name;
      }

      return '';
    };

    this.getToggleButtonLabel = function () {
      return __isParentModelDedicationSet(_this.parentModel) ? 'Edit' : 'Add';
    };

    function __getInitialDedication() {
      var initialDedication = {
        type: 'honor',

        // honoreer fields
        honoree_first_name: null,
        honoree_last_name: null,

        // email fields
        email_address: null,
        first_name: null,
        last_name: null,

        // ecard fields
        ecard_message: null,
        ecard_id: null,

        // address fields (for printed card)
        country: null,
        address: null,
        city: null,
        state: null,
        postal_code: null
      };

      return initialDedication;
    }

    function __getCampaignAttr(attr) {
      return scCampaignsService.active && scCampaignsService.active.current && scCampaignsService.active.current[attr];
    }

    function __isParentModelDedicationSet(parentModel) {
      return !!(parentModel && parentModel.dedication && parentModel.dedication.honoree_first_name && parentModel.dedication.honoree_last_name);
    }
  }]
});
})();