(function(){
'use strict';

angular.module('classy').constant('scActivityBlockDefaults', {
  title: 'Activity',
  type: 'activity',

  cta: 'See who supports your cause and what they have to say about it!',
  allowCommenting: true,
  thumbnail: {
    preset: 'circle',
    'border-radius': '50px'
  },
  disable: false,
  hideHeadline: false,
  background: {
    color: null,
    image: null,
    colorOpacity: 100,
    imageOpacity: 100,
    blur: 0,
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    fixed: false,
    preset: 'stretch'
  },
  headline: '<span>Campaign Activity</span>',
  remove: false
});
})();