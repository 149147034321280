(function(){
'use strict';

/**
 * @name scPublishMessageService
 * @kind factory
 *
 * @description
 * Gives you a message to display on banners and tooltips and stuff
 */

scPublishMessageService.$inject = ["$filter", "scOrganizationsService"];
function scPublishMessageService($filter, scOrganizationsService) {
  return function getMessage(campaignType) {
    var orgId = scOrganizationsService.active.current.id;
    var status = scOrganizationsService.getOrgStatus();
    var isException = scOrganizationsService.getTag('pay-exception-org');
    var hasPay = scOrganizationsService.hasPay();
    var hasProcessor = _.get(scOrganizationsService, 'active.current.hasProcessor', false);
    var hasPaymentMethod = _.get(scOrganizationsService, 'active.current.hasPaymentMethod', false);

    if ((status === 'trial' || !hasProcessor) && !hasPaymentMethod) {
      // case:zuora org without a billing configuration and without a payment processor
      return 'You can publish a campaign once you complete your account setup. <a href="https://support.classy.org/s/article/why-cant-i-publish-my-campaign" target="_blank">See what\u2019s left.</a>';
    }

    if (!hasPaymentMethod) {
      // case: org is a zuora org without a billing configuration
      return 'You can publish your campaign once you have configured your billing settings. <a href="/admin/' + orgId + '/settings/billing" target="_blank">Go to Billing Settings</a>';
    }

    if (status === 'trial' || !hasProcessor) {
      // case: org is a trial org
      return 'You can publish a campaign once you complete your account setup. <a href="https://support.classy.org/s/article/why-cant-i-publish-my-campaign" target="_blank">See what\u2019s left.</a>';
    }

    // case: unless you're an exception org GET ON PAY
    if (!isException && !hasPay) {
      return $filter('linky')('You can publish your campaign once your payment processor has been verified. This typically happens the next business day. For more help contact mailto:support@classy.org');
    }

    // case: exception org needs to GET ON PAY for rwf and donation pages
    if (isException && (campaignType === 'reg_w_fund' || campaignType === 'donation' || campaignType === 'ticketed') && !hasPay) {
      return 'This campaign requires the latest payment technology. To publish, you need to add a Classy Pay processor. Compare <a href="https://support.classy.org/customer/en/portal/articles/2302319-payment-processor-comparison" target="_blank">available processors</a> and reach out to <a href="https://www.classy.org/support/contact" target="_blank">support</a> to get started.';
    }

    return 'We\'re sorry, but you are currently unable to publish this campaign. For assistance, please contact <a href="https://www.classy.org/help" target="_blank" rel="noreferrer noopener">customer support</a>.';
  };
}

angular.module('classy').factory('scPublishMessageService', scPublishMessageService);
})();