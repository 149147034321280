(function(){
'use strict';

/**
 * @name nullToZero
 * @kind filter
 *
 * @description
 * Converts undefined and null values to the number zero.
 */

function nullToZero() {
  return function (input) {
    if (_.isUndefined(input) || input === null) {
      return 0;
    }
    return input;
  };
}

angular.module('classy').filter('nullToZero', nullToZero);
})();